/* eslint-disable-next-line */
const preset = require("../../packages/status-page-ui/tailwind-shared.preset.js");

/** @type {import("@types/tailwindcss/tailwind-config").TailwindConfig } */
module.exports = {
  presets: [preset],
  content: [
    ...preset.content,
    "./src/**/*.{js,jsx,ts,tsx,mdx}",
    "./public/index.html",
  ],
  media: false, // or 'media' or 'class'
  plugins: [
    // tailwind-scrollbar gives us control over our scrollbar styling: https://adoxography.github.io/tailwind-scrollbar/
    // we set no-compatible to give us access to some more configurable things, which won't apply to all browsers.
    require("tailwind-scrollbar")({ nocompatible: true }),
  ]
};
