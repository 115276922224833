import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { PopoverSingleSelectV2 } from "@incident-shared/forms/v2/inputs/PopoverSelectV2";
import { RadioButtonGroupV2 } from "@incident-shared/forms/v2/inputs/RadioButtonGroupV2";
import { Button, ButtonSize, ButtonTheme, Icon, IconEnum } from "@incident-ui";
import { InputType } from "@incident-ui/Input/Input";
import { PopoverTitleBar } from "@incident-ui/Popover/Popover";
import { RadioButtonGroupOption } from "@incident-ui/RadioButtonGroup/RadioButtonGroup";
import { useForm, useFormContext } from "react-hook-form";
import { Form } from "src/components/@shared/forms";

import {
  EscalationPathFormData,
  EscalationPathTargetSelectionMode,
} from "../common/types";

export const RoundRobinForm = ({
  id,
  timeToAckMinutes,
}: {
  id: string;
  timeToAckMinutes?: number;
}) => {
  const formMethods = useFormContext<EscalationPathFormData>();

  const rotateAfterMinutes = formMethods.watch(
    `nodes.${id}.data.level.targetRotateAfterMinutes`,
  );
  const selectionMode = formMethods.watch(
    `nodes.${id}.data.level.targetSelectionMode`,
  );

  const targetSelectionModeOptions = [
    {
      value: EscalationPathTargetSelectionMode.AllAtOnce,
      label: "All at once",
      icon: IconEnum.ArrowThreeUp,
    },
    {
      value: EscalationPathTargetSelectionMode.RoundRobin,
      label: "Round robin",
      icon: IconEnum.ArrowSpin,
      onSelectRender: ({ onBack, onClose, onChange }) => {
        return (
          <RoundRobinRotateAfterForm
            onBack={onBack}
            onClose={onClose}
            onSubmit={(rotateAfterMinutes?: number) => {
              onChange(EscalationPathTargetSelectionMode.RoundRobin);
              formMethods.setValue(
                `nodes.${id}.data.level.targetRotateAfterMinutes`,
                rotateAfterMinutes,
              );
            }}
            defaultValue={rotateAfterMinutes}
            timeToAckMinutes={timeToAckMinutes}
          />
        );
      },
    },
  ];

  return (
    <PopoverSingleSelectV2
      options={targetSelectionModeOptions}
      sideOffset={1}
      name={`nodes.${id}.data.level.targetSelectionMode`}
      formMethods={formMethods}
      tooltipContent={
        selectionMode === EscalationPathTargetSelectionMode.RoundRobin ? (
          <>
            We&apos;ll pick a single user to page, starting with the person who
            was paged longest ago for this escalation path
          </>
        ) : (
          <>We&apos;ll notify all users and schedules at the same time</>
        )
      }
      tooltipSide="bottom"
      popoverClassName="min-w-[270px] max-w-[270px]"
      renderTriggerNode={({ onClick, selectedOption }) => {
        return (
          <Button
            onClick={onClick}
            theme={ButtonTheme.Tertiary}
            analyticsTrackingId={null}
            title={selectedOption?.label}
            size={ButtonSize.Small}
            icon={selectedOption?.icon}
          >
            <div className="flex">
              {selectedOption?.label}
              {selectedOption?.value ===
              EscalationPathTargetSelectionMode.RoundRobin ? (
                rotateAfterMinutes ? (
                  <>
                    <Icon
                      id={IconEnum.ChevronRight}
                      className="text-content-tertiary"
                    />
                    <>Every {rotateAfterMinutes}m</>
                  </>
                ) : null
              ) : null}
            </div>
          </Button>
        );
      }}
    />
  );
};

enum RoundRobinShouldRotateOptions {
  NextResponderOnly = "next_responder_only",
  CycleResponders = "cycle_responders",
}

const RoundRobinRotateAfterForm = ({
  onBack,
  onClose,
  onSubmit,
  defaultValue,
  timeToAckMinutes,
}: {
  onBack: () => void;
  onClose: () => void;
  onSubmit: (value?: number) => void;
  defaultValue?: number;
  timeToAckMinutes?: number;
}) => {
  const formMethods = useForm<{
    shouldRotateOption: RoundRobinShouldRotateOptions;
    rotateAfterMinutes: number;
  }>({
    defaultValues: {
      shouldRotateOption: defaultValue
        ? RoundRobinShouldRotateOptions.CycleResponders
        : RoundRobinShouldRotateOptions.NextResponderOnly,
      rotateAfterMinutes: defaultValue,
    },
  });

  const rotateAfterOptions: RadioButtonGroupOption[] = [
    {
      value: RoundRobinShouldRotateOptions.NextResponderOnly,
      label: "Single user",
      description: "Move to next level if they don't acknowledge",
      icon: IconEnum.ArrowSpin,
    },
    {
      value: RoundRobinShouldRotateOptions.CycleResponders,
      label: "Cycle users",
      description: "Rotate through users until someone acknowledges",
      icon: IconEnum.ArrowSpin,
    },
  ];

  const shouldRotateOption = formMethods.watch("shouldRotateOption");
  const rotateAfterMinutes = formMethods.watch("rotateAfterMinutes");

  return (
    <Form.Root
      formMethods={formMethods}
      onSubmit={(data) => {
        data.shouldRotateOption ===
        RoundRobinShouldRotateOptions.CycleResponders
          ? onSubmit(data.rotateAfterMinutes)
          : onSubmit();
        onClose();
      }}
      id="round-robin-rotate-after-form"
    >
      <>
        <PopoverTitleBar title="Round robin" handleBack={onBack} />
        <div className="px-4 pb-3 flex flex-col space-y-4 !m-0 pt-1">
          <div className="space-y-2">
            <RadioButtonGroupV2
              srLabel="Should we cycle through responders?"
              options={rotateAfterOptions}
              name="shouldRotateOption"
              formMethods={formMethods}
              onValueChange={(val) => {
                if (
                  val === RoundRobinShouldRotateOptions.CycleResponders &&
                  !rotateAfterMinutes
                ) {
                  formMethods.setValue("rotateAfterMinutes", 2);
                }
              }}
            />
            {shouldRotateOption ===
            RoundRobinShouldRotateOptions.CycleResponders ? (
              <InputV2
                type={InputType.Number}
                formMethods={formMethods}
                name="rotateAfterMinutes"
                inputClassName="w-[60px] !grow-0"
                className="!grow-0 ml-6"
                required
                disableGrowInput
                prefixNode={
                  <div className="mr-1 text-xs-med text-content-primary">
                    Every
                  </div>
                }
                suffixNode={
                  <div className="ml-1 text-xs-med text-content-primary">
                    minutes
                  </div>
                }
                rules={{
                  validate: (value) => {
                    if (value && value < 1) {
                      return "Value must be greater than 0";
                    }

                    if (
                      value &&
                      timeToAckMinutes &&
                      value >= timeToAckMinutes
                    ) {
                      return `Value must be less than the time to acknowledge window for this level. Level will expire after the time to acknowledge window of ${timeToAckMinutes}m.`;
                    }

                    return undefined;
                  },
                }}
              />
            ) : null}
          </div>
          <Button
            type="submit"
            className="grow"
            theme={ButtonTheme.Primary}
            analyticsTrackingId={null}
            title="Save"
            disabled={
              shouldRotateOption ===
              RoundRobinShouldRotateOptions.CycleResponders
                ? !rotateAfterMinutes
                : false
            }
          >
            Save
          </Button>
        </div>
      </>
    </Form.Root>
  );
};
