import {
  useIncidentCallsServiceIncidentCallsGetForLatestForIncident,
  useIncidentCallsServiceIncidentCallsListCallSessionsWithSummaries,
} from "@incident-io/query-api";

export const useLatestIncidentCall = (incidentId: string) => {
  const {
    data: callData,
    isLoading: callIsLoading,
    error: callError,
  } = useIncidentCallsServiceIncidentCallsGetForLatestForIncident({
    incidentId,
  });

  return {
    callData,
    isLoading: callIsLoading,
    error: callError,
  };
};

export const useIncidentCallSessionsWithSummaries = (incidentId: string) => {
  const {
    data: callData,
    isLoading: callIsLoading,
    error: callError,
  } = useIncidentCallsServiceIncidentCallsGetForLatestForIncident({
    incidentId,
  });

  const {
    data: callSessionsWithSummariesData,
    isLoading: callSessionsWithSummariesIsLoading,
    error: callSessionsWithSummariesError,
  } = useIncidentCallsServiceIncidentCallsListCallSessionsWithSummaries(
    {
      incidentCallId: callData?.incident_call?.id || "",
    },
    undefined,
    {
      refetchInterval: 15000, // 15 seconds
      refetchOnWindowFocus: true,
    },
  );

  return {
    callSessionsWithSummaries:
      callSessionsWithSummariesData?.call_sessions_with_transcripts,
    isLoading: callSessionsWithSummariesIsLoading || callIsLoading,
    error: callSessionsWithSummariesError || callError,
  };
};
