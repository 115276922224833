import { PostIncidentTask } from "@incident-io/api";
import { Button } from "@incident-ui";
import { useAPIMutation, useAPIRefetch } from "src/utils/swr";

export const CompletePostIncidentTaskButton = ({
  incidentTask,
  onTaskComplete,
  onError,
}: {
  incidentTask: PostIncidentTask;
  onTaskComplete: () => void;
  onError: (error: string) => void;
}): React.ReactElement | null => {
  // We may have triggered moving to a new incident status, so refresh the incident
  const refetchIncident = useAPIRefetch("incidentsShow", {
    id: incidentTask.incident_id,
  });

  const { trigger: completeTask, isMutating: updatingTaskCompletionStatus } =
    useAPIMutation(
      "postIncidentFlowListTasks",
      { incidentId: incidentTask.incident_id },
      async (apiClient) => {
        await apiClient.postIncidentFlowCompleteTask({
          id: incidentTask.id,
        });

        await refetchIncident();
      },
      {
        onSuccess: () => {
          onTaskComplete();
        },
        onError: (error: Error) => {
          onError(error.message);
        },
      },
    );

  return (
    <Button
      onClick={() => {
        completeTask({});
      }}
      analyticsTrackingId="post-incident-task-complete"
      analyticsTrackingMetadata={{
        incident_task_id: incidentTask.id,
      }}
      loading={updatingTaskCompletionStatus}
    >
      Complete
    </Button>
  );
};
