import { Icon, IconEnum, Link } from "@incident-ui";
import { IconProps, IconSize } from "@incident-ui/Icon/Icon";
import { LinkProps } from "@incident-ui/Link/Link";
import { tcx } from "src/utils/tailwind-classes";

export const ExternalLink = ({
  icon,
  label,
  iconProps,
  className,
  onEdit,
  ...linkProps
}: {
  label: React.ReactNode;
  icon: IconEnum;
  iconProps?: Omit<IconProps, "id">;
  className?: string;
  onEdit?: () => void;
} & Pick<
  LinkProps,
  "href" | "to" | "analyticsTrackingId" | "analyticsTrackingMetadata"
>) => {
  return (
    <div className="flex group">
      <Link
        {...(linkProps as LinkProps)}
        className={tcx(
          "no-underline flex items-center gap-1 hover:!text-content-primary hover:underline",
          className,
        )}
        openInNewTab={linkProps.href !== undefined}
      >
        <Icon id={icon} size={IconSize.Small} {...iconProps} />
        {label}
      </Link>
      {onEdit && (
        <Icon
          id={IconEnum.Edit}
          className="ml-2 opacity-0 group-hover:opacity-100 transition cursor-pointer text-slate-600 hover:text-content-primary"
          onClick={onEdit}
        />
      )}
    </div>
  );
};
