import {
  AddFilterButton,
  ToggleIncludePrivateIncidentsFilter,
  useFiltersContext,
} from "@incident-shared/filters";
import { useGetIncidentsWithSyntheticFilters } from "@incident-shared/incidents";
import { HeaderBar } from "@incident-shared/layout/HeaderBar/HeaderBar";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import {
  Button,
  IconEnum,
  Loader,
  Modal,
  ModalContent,
  ModalFooter,
  RadioButtonGroup,
  Toggle,
} from "@incident-ui";
import _ from "lodash";
import React, { useRef, useState } from "react";
import { Form } from "src/components/@shared/forms";
import {
  Incident,
  IncidentVisibilityEnum,
  Settings,
} from "src/contexts/ClientContext";
import { v4 as uuid } from "uuid";

import { downloadObjsAsCsv } from "../../../utils/csv";
import { presentIncidentObject } from "../../../utils/presenters";
import { useQueryParams } from "../../../utils/query-params";
import { CreateOrUpdateSavedViewButtonModal } from "../../saved-views/CreateOrUpdateSavedViewButtonModal";
import { SavedViewHeading } from "../../saved-views/SavedViewHeading";
import { DeclareIncidentButton } from "../incident/DeclareIncidentButton";
import {
  IncidentCreateModal,
  IncidentModeQueryParam,
} from "../incident/IncidentCreateModal";

const pickFieldsForCSV = (settings: Settings, incidents: Incident[]) => {
  const objs = _.clone(incidents)
    .reverse()
    .map((inc) => {
      return presentIncidentObject(settings, inc);
    });
  return objs;
};

export const IncidentsListHeader = ({
  settings,
  selectedIncidentIDs,
  onlyShowDeclareIncidentButton,
}: {
  settings: Settings | null;
  selectedIncidentIDs: string[];
  onlyShowDeclareIncidentButton: boolean;
}): React.ReactElement => {
  const [exportModalOpen, setExportModalOpen] = useState(false);
  const [showIncidentCreateModal, setShowIncidentCreateModal] = useState(false);
  const urlParams = useQueryParams();
  const navigate = useOrgAwareNavigate();
  const initialMode = useRef<IncidentModeQueryParam | undefined>(undefined);
  if (urlParams.has("createIncident")) {
    urlParams.delete("createIncident");
    const mode = urlParams.get("mode");
    if (
      mode &&
      Object.values(IncidentModeQueryParam).includes(
        mode as IncidentModeQueryParam,
      )
    ) {
      urlParams.delete("mode");
      initialMode.current = mode as IncidentModeQueryParam;
    }
    const url = new URL(
      `${location.origin}${location.pathname}?${urlParams.toString()}`,
    );
    setShowIncidentCreateModal(true);
    navigate(url, { replace: true });
  }

  return (
    <>
      <HeaderBar
        icon={IconEnum.Incident}
        title="Incidents"
        titleNode={
          <SavedViewHeading
            pageName="Incidents"
            headingSize="2xl"
            hideIcon={onlyShowDeclareIncidentButton}
          />
        }
        accessory={
          <div className="flex flex-wrap gap-4">
            {!onlyShowDeclareIncidentButton && (
              <ToggleIncludePrivateIncidentsFilter />
            )}

            <div className="flex flex-wrap gap-2">
              {!onlyShowDeclareIncidentButton && (
                <>
                  <Button
                    analyticsTrackingId="export-incident-csv-modal-open"
                    icon={IconEnum.Export}
                    onClick={() => setExportModalOpen(true)}
                  >
                    <span className="mobile-hidden">Export CSV</span>
                  </Button>
                  <AddFilterButton />
                  <CreateOrUpdateSavedViewButtonModal />
                </>
              )}
              <DeclareIncidentButton
                onClick={() => setShowIncidentCreateModal(true)}
              />
            </div>
          </div>
        }
      />
      {exportModalOpen && (
        <ExportIncidentsModal
          settings={settings}
          selectedIncidentIDs={selectedIncidentIDs}
          onClose={() => setExportModalOpen(false)}
        />
      )}
      {showIncidentCreateModal && (
        <IncidentCreateModal
          initialMode={initialMode.current}
          onClose={() => setShowIncidentCreateModal(false)}
        />
      )}
    </>
  );
};

const ExportIncidentsModal = ({
  settings,
  onClose,
  selectedIncidentIDs,
}: {
  onClose: () => void;
  settings: Settings | null;
  selectedIncidentIDs: string[];
}): React.ReactElement => {
  const [exportPrivate, setExportPrivate] = useState(false);
  const [separator, setSeparator] = useState<string>(",");

  // Use the same filters as the outer context, but override the choice of
  // visibility to match the toggle in the export window.
  const { filters } = useFiltersContext();
  const filtersWithoutVisibility = filters.filter((filter) => {
    return filter.field_id !== "include_private";
  });
  const filtersWithVisibility = [
    ...filtersWithoutVisibility,
    {
      key: uuid(),
      field_key: "include_private",
      field_id: "include_private",
      filter_id: "include_private",
      operator: "is",
      bool_value: exportPrivate,
    },
  ];

  const { incidents, error, allIncidentsLoaded } =
    useGetIncidentsWithSyntheticFilters({
      filters: filtersWithVisibility,
      eagerLoad: true,
    });
  if (error) {
    throw error;
  }

  // Loader until we get our settings, otherwise the onDownload function gets a
  // bit weird.
  if (!settings) {
    return <Loader />;
  }

  const onDownload = (): void => {
    const data = pickFieldsForCSV(
      settings,
      incidents.filter(
        (inc) =>
          (selectedIncidentIDs.length === 0 ||
            selectedIncidentIDs.includes(inc.id)) &&
          (exportPrivate || inc.visibility === IncidentVisibilityEnum.Public),
      ),
    );
    downloadObjsAsCsv("incidents.csv", data, separator);
    onClose();
  };

  return (
    <Modal
      title="Export to CSV"
      analyticsTrackingId={"export-incidents"}
      isOpen={true}
      onClose={onClose}
    >
      <ModalContent>
        <div className="flex flex-col gap-2 text-sm text-slate-700">
          <p>
            This will download a CSV containing{" "}
            {selectedIncidentIDs.length === 0
              ? `all the incidents which match your filter criteria.`
              : `the ${selectedIncidentIDs.length} selected incidents.`}
          </p>

          <Toggle
            label="Include private incidents"
            id="exportPrivate"
            onToggle={() => setExportPrivate(!exportPrivate)}
            on={exportPrivate}
          />

          <div className="flex flex-col gap-1">
            <Form.Label htmlFor={"separator"}>Separator</Form.Label>
            <RadioButtonGroup
              options={[
                {
                  label: "Comma",
                  value: ",",
                },
                {
                  label: "Pipe",
                  value: "|",
                },
                {
                  label: "Semicolon",
                  value: ";",
                },
              ]}
              name={"separator"}
              srLabel={"Separator"}
              onChange={(e) => setSeparator(e)}
              value={separator}
              boxed
            />
          </div>
        </div>
      </ModalContent>
      <ModalFooter
        confirmButtonText={allIncidentsLoaded ? "Download" : "Loading..."}
        confirmButtonType="button"
        onConfirm={onDownload}
        onClose={onClose}
        disabled={!allIncidentsLoaded}
      />
    </Modal>
  );
};
