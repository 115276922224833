import { Card, IconEnum } from "@incident-ui";

export type AboutThisIntegrationProps = {
  features: IntegrationFeature[];
  hexColor: string;
  providerLabel: string;
};

export const AboutThisIntegration = ({
  features,
  hexColor,
  providerLabel,
}: AboutThisIntegrationProps) => {
  return (
    <div className="flex flex-col gap-3">
      <div className="text-base-bold">About this integration</div>
      <div className="grid grid-cols-2 gap-4">
        {features.map((feature) => (
          <AboutThisIntegrationCard
            key={feature}
            feature={feature}
            hexColor={hexColor}
            providerLabel={providerLabel}
          />
        ))}
      </div>
    </div>
  );
};

export type AboutThisIntegrationCardProps = {
  feature: IntegrationFeature;
  hexColor: string;
  providerLabel: string;
};

export const AboutThisIntegrationCard = ({
  feature,
  hexColor,
  providerLabel,
}: AboutThisIntegrationCardProps) => {
  const config = PRODUCT_MARKETING_CARDS[feature];
  return (
    <Card
      icon={config.icon}
      clickable={false}
      title={config.title}
      hexColor={hexColor}
      description={
        <span className="text-content-secondary">
          {config.description(providerLabel)}
        </span>
      }
      className="bg-surface-secondary border-none"
    />
  );
};

export enum IntegrationFeature {
  AlertAttributes = "alert_attributes",
  AlertSource = "alert_source",
  AttachPullRequests = "attach_pull_requests",
  AutoCall = "auto_call",
  AutoCallTwo = "auto_call_two",
  AutoEscalate = "auto_escalate",
  EscalateRightPerson = "escalate_right_person",
  ExportFollowUps = "export_follow_ups",
  ExportPostMortems = "export_post_mortems",
  OrganisePostMortems = "organise_post_mortems",
  PowerAutomations = "power_automations",
  PowerCustomFields = "power_custom_fields",
  SyncFollowUps = "sync_follow_ups",
  ControlPermissions = "control_permissions",
  ManageTeamMembership = "manage_team_membership",
  AtlassianStatuspageOne = "atlassian_statuspage_one",
  AtlassianStatuspageTwo = "atlassian_statuspage_two",
  CalendarFeedsOne = "calendar_feeds_one",
  CalendarFeedsTwo = "calendar_feeds_two",
  GCalOne = "gcal_one",
  GCalTwo = "gcal_two",
  VantaOne = "vanta_one",
  VantaTwo = "vanta_two",
  ZapierOne = "zapier_one",
  ZapierTwo = "zapier_two",
  ZendeskOne = "zendesk_one",
  ZendeskTwo = "zendesk_two",
}

const PRODUCT_MARKETING_CARDS: {
  [key in IntegrationFeature]: {
    icon: IconEnum;
    title: string;
    description: (providerLabel: string) => string;
  };
} = {
  [IntegrationFeature.AttachPullRequests]: {
    icon: IconEnum.GitBranch,
    title: "Attach pull requests",
    description: (provider) =>
      `Attach ${provider} PRs to your incidents so you can keep the channel up-to-date as it's reviewed and merged.`,
  },
  [IntegrationFeature.ExportFollowUps]: {
    icon: IconEnum.FollowUps,
    title: "Export follow-ups",
    description: (provider) =>
      `Keep your ticketing tracking up to date by exporting follow-ups from your incident into ${provider}.`,
  },
  [IntegrationFeature.SyncFollowUps]: {
    icon: IconEnum.Sync,
    title: "Sync ticket status updates",
    description: (provider) =>
      `Work directly in ${provider} without having to manually update actions in incident.io using automatic syncing.`,
  },
  [IntegrationFeature.PowerCustomFields]: {
    icon: IconEnum.CustomField,
    title: "Maintain custom fields",
    description: (provider) =>
      `Stop manually maintaining Custom Fields in incident.io. Instead, populate them with data from ${provider}.`,
  },
  [IntegrationFeature.PowerAutomations]: {
    icon: IconEnum.Workflows,
    title: "Powerful automations",
    description: (provider) =>
      `Build workflows to pull in the right people based on data from ${provider}.`,
  },
  [IntegrationFeature.ExportPostMortems]: {
    icon: IconEnum.Doc,
    title: "Export post-mortems",
    description: (provider) =>
      `Export post-mortems from incident.io to ${provider} to keep your team up-to-date.`,
  },
  [IntegrationFeature.OrganisePostMortems]: {
    icon: IconEnum.Folder,
    title: "Organise documents",
    description: () =>
      `Keep your post-mortems organised by exporting them to a consistent destination.`,
  },
  [IntegrationFeature.EscalateRightPerson]: {
    icon: IconEnum.Escalate,
    title: "Escalate to the right person",
    description: (provider) =>
      `No need to log into ${provider}, simply use /incident escalate to directly page from an incident channel.`,
  },
  [IntegrationFeature.AutoEscalate]: {
    icon: IconEnum.Workflows,
    title: "Auto-escalate",
    description: () =>
      `Configure workflows to automatically escalate an incident to the right person when you need them, based on factors like incident type or severity.`,
  },
  [IntegrationFeature.AlertSource]: {
    icon: IconEnum.Alert,
    title: "Create incidents",
    description: (provider) =>
      `Create incidents automatically from alerts coming from ${provider}`,
  },
  [IntegrationFeature.AlertAttributes]: {
    icon: IconEnum.Tag,
    title: "Alert attributes",
    description: () =>
      `Use our powerful JSON parser to enrich your alerts with organizational context like services, teams and customers`,
  },
  [IntegrationFeature.AutoCall]: {
    icon: IconEnum.Call,
    title: "Calls automatically created",
    description: (provider) =>
      `Automatically create an individual ${provider} call whenever an incident is declared, so you can get started.`,
  },
  [IntegrationFeature.AutoCallTwo]: {
    icon: IconEnum.Attachment,
    title: "Bring together your team",
    description: (provider) =>
      `We'll attach your ${provider} call to an incident, and help your team join in one click.`,
  },
  [IntegrationFeature.AtlassianStatuspageOne]: {
    icon: IconEnum.Announcement,
    title: "Publish updates from Slack",
    description: () =>
      `Update your Atlassian Statuspage directly from the incident channel, without leaving Slack.`,
  },
  [IntegrationFeature.AtlassianStatuspageTwo]: {
    icon: IconEnum.Nudge,
    title: "Reminders to provide updates",
    description: () =>
      `User nudges to remind your team to update your Statuspage at the right time, to make sure customers get the updates they need.`,
  },
  [IntegrationFeature.ControlPermissions]: {
    icon: IconEnum.LockClosed,
    title: "Control permissions",
    description: () =>
      `Automatically provision users and configure their roles to make sure that the right people have the right access.`,
  },
  [IntegrationFeature.ManageTeamMembership]: {
    icon: IconEnum.Book,
    title: "Manage team membership",
    description: () =>
      `Manage your teams and use them to power automations across the platform.`,
  },
  [IntegrationFeature.VantaOne]: {
    icon: IconEnum.LockClosed,
    title: "Audit user permissions",
    description: () =>
      `Track users with access to your incident.io account, to check that you're being careful with admin & owner privileges.`,
  },
  [IntegrationFeature.VantaTwo]: {
    icon: IconEnum.FollowUps,
    title: "Track follow-ups as 'security tasks'",
    description: () =>
      `Track follow-ups as 'security tasks' inside Vanta, so security teams can provide evidence that they're meeting any SLAs on them.`,
  },
  [IntegrationFeature.CalendarFeedsOne]: {
    icon: IconEnum.Users,
    title: "View holidays in your On-call schedules and arrange cover",
    description: () =>
      `View your employees' holidays alongside your On-call schedules, and quickly create overrides to cover their time off.`,
  },
  [IntegrationFeature.CalendarFeedsTwo]: {
    icon: IconEnum.Cog,
    title: "Works with common HR systems",
    description: () =>
      `Bring in your calendar feeds from common HRIS like BambooHR, HiBob, Personio, Rippling, and more.`,
  },
  [IntegrationFeature.GCalOne]: {
    icon: IconEnum.Calendar,
    title: "Attach debrief events to incidents",
    description: () =>
      `Attach debrief events to an incident, and pull through relevant information about when it is and who is attending.`,
  },
  [IntegrationFeature.GCalTwo]: {
    icon: IconEnum.Clock,
    title: "Debrief placeholders",
    description: () =>
      `Set up debrief placeholders, so you can make sure you're learning from every incident at a convenient time.`,
  },

  [IntegrationFeature.ZendeskOne]: {
    icon: IconEnum.Attachment,
    title: "Attach tickets",
    description: () =>
      `Link Zendesk tickets directly with your incident, relaying comms between the two places and ensuring everything is well connected.`,
  },
  [IntegrationFeature.ZendeskTwo]: {
    icon: IconEnum.Announcement,
    title: "2-way communication",
    description: () =>
      `Engineers will have visibility on communications coming from your customers and support agents will be kept up to date on ongoing issues.`,
  },
  [IntegrationFeature.ZapierOne]: {
    icon: IconEnum.Incident,
    title: "Create incidents",
    description: () =>
      `Create incidents from the other tools that you know and love.`,
  },
  [IntegrationFeature.ZapierTwo]: {
    icon: IconEnum.Announcement,
    title: "Keep your tools in sync",
    description: () =>
      `Share updates from incidents out to your various other tools, so your team can stay in the loop.`,
  },
};
