import { Txt } from "@incident-ui";
import { BaseEdge, EdgeLabelRenderer, getBezierPath } from "reactflow";
import { tcx } from "src/utils/tailwind-classes";

import { PathEdge } from "../common/types";

// CustomEdge is a custom edge component used in ReactFlow to render edges between
// nodes.
export const CustomEdge = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  data,
}: {
  id: string;
  sourceX: number;
  sourceY: number;
  targetX: number;
  targetY: number;
  data?: PathEdge;
}) => {
  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    targetX,
    targetY,
  });

  return (
    <>
      <BaseEdge
        id={id}
        path={edgePath}
        style={{ stroke: "slate-300", strokeWidth: "2px" }}
      />
      <EdgeLabelRenderer>
        <EdgeLabel
          label={data?.label ?? "Then"}
          conditionResult={data?.conditionResult}
          labelX={labelX}
          labelY={labelY}
        />
      </EdgeLabelRenderer>
    </>
  );
};

const EdgeLabel = ({
  label,
  conditionResult,
  labelX,
  labelY,
}: {
  label: string;
  conditionResult?: boolean;
  labelX: number;
  labelY: number;
}) => {
  const colours = {
    Then: "bg-slate-400",
    True: "bg-green-500",
    False: "bg-red-600",
  };

  return (
    <div
      style={{
        position: "absolute",
        transform: `translate(-50%, -50%) translate(${labelX}px, ${labelY}px)`,
      }}
      className={tcx(
        "w-fit rounded py-0.5 px-1 border-2 border-slate-50",
        "nodrag nopan",
        conditionResult === undefined
          ? colours.Then
          : conditionResult
          ? colours.True
          : colours.False,
      )}
    >
      <Txt className="text-xs text-white font-semibold">{label}</Txt>
    </div>
  );
};
