import { Icon, IconEnum, IconSize } from "@incident-ui";
import { useState } from "react";
import { tcx } from "src/utils/tailwind-classes";

type SectionWrapperProps = {
  id?: string;
  sectionName: string;
  children: React.ReactNode;
  hideBorder?: boolean;
  enableExpansion?: boolean;
  headerAccessory?: React.ReactNode;
  className?: string;
};

export const SectionWrapper = ({
  id,
  sectionName,
  children,
  hideBorder = false,
  enableExpansion = false,
  headerAccessory,
  className,
}: SectionWrapperProps) => {
  const [expanded, setExpanded] = useState(true);

  const onHeaderClick = (e: React.MouseEvent) => {
    if (!enableExpansion) {
      return;
    }
    e.stopPropagation();
    e.preventDefault();

    setExpanded(!expanded);
  };

  const isExpanded = expanded || !enableExpansion;

  return (
    <div
      id={id}
      className={tcx(
        "gap-4 flex flex-col w-full p-6 group/section",
        !hideBorder && "shadow-sm border border-stroke-primary rounded-lg",
        className,
      )}
    >
      {/* Header */}
      <div className="justify-between flex">
        <div
          className={tcx(
            "flex flex-row gap-2 items-center",
            enableExpansion && "cursor-pointer select-none",
          )}
          onClick={onHeaderClick}
        >
          <div className="text-base-bold">{sectionName}</div>
          {enableExpansion && (
            <Icon
              className="hidden group-hover/section:block"
              id={expanded ? IconEnum.ChevronUp : IconEnum.ChevronDown}
              size={IconSize.Medium}
            />
          )}
        </div>
        {headerAccessory}
      </div>
      {/* Children */}
      {isExpanded && children}
    </div>
  );
};
