import {
  CustomField,
  CustomFieldTypeInfo,
  CustomFieldTypeInfoFieldTypeEnum,
  EnrichedImage,
  Incident,
  PostmortemTemplateSection,
  PostmortemTemplateSectionConfig,
  PostmortemTemplateSectionResponse,
} from "@incident-io/api";
import { renderCustomFieldValues } from "@incident-shared/custom-fields/renderCustomFieldValues";
import {
  TemplatedTextDisplay,
  TemplatedTextDisplayStyle,
} from "@incident-shared/forms/v1/TemplatedText";
import {
  Badge,
  BadgeSize,
  BadgeTheme,
  Button,
  ButtonTheme,
  Icon,
  IconEnum,
  IconSize,
  Modal,
  ModalContent,
  ModalFooter,
  Tooltip,
} from "@incident-ui";
import { EditableSectionWrapper } from "@incident-ui/EditableSectionWrapper/EditableSectionWrapper";
import { useState } from "react";
import { useIsWithinMicrosoftTeamsTab } from "src/contexts/MicrosoftTeamsTabContext";
import { tcx } from "src/utils/tailwind-classes";

import { SectionWrapper } from "../body/SectionWrapper";
import { PostmortemSectionEditor } from "./PostmortemSectionEditor";

export const PostmortemCustomSection = ({
  incident,
  templateId,
  section,
  existingResponse,
  allCustomFields,
}: {
  incident: Incident;
  templateId: string;
  section: PostmortemTemplateSection;
  existingResponse?: PostmortemTemplateSectionResponse;
  allCustomFields: CustomField[];
}): React.ReactElement => {
  const [isEditing, setIsEditing] = useState(false);

  if (!section.config) {
    // There should always be a config for a custom section.
    return <></>;
  }

  const { config } = section;

  const customFieldsToShow = allCustomFields.filter(
    ({ id }) => config.custom_field_ids?.includes(id),
  );

  return (
    <EditableSectionWrapper>
      {isEditing ? (
        <PostmortemSectionEditor
          incident={incident}
          templateId={templateId}
          section={section}
          config={config}
          existingResponse={existingResponse}
          onSave={() => {
            setIsEditing(false);
          }}
          customFieldsToShow={customFieldsToShow}
        />
      ) : (
        <PostmortemSectionDisplay
          id={section.id}
          existingResponse={existingResponse}
          setIsEditing={() => setIsEditing(true)}
          config={config}
          incident={incident}
        />
      )}
    </EditableSectionWrapper>
  );
};

const PostmortemSectionDisplay = ({
  id,
  existingResponse,
  setIsEditing,
  config,
  incident,
}: {
  id: string;
  existingResponse?: PostmortemTemplateSectionResponse;
  setIsEditing: () => void;
  config: PostmortemTemplateSectionConfig;
  incident: Incident;
}): React.ReactElement => {
  return (
    <Button
      analyticsTrackingId={null}
      theme={ButtonTheme.Unstyled}
      onClick={() => {
        setIsEditing();
      }}
      title="Edit section"
      // We want this styled like a text input you can click
      className="text-left cursor-text w-full"
    >
      <SectionWrapper
        id={id}
        sectionName={config.name}
        hideBorder
        enableExpansion
      >
        <PostmortemSectionCustomFields incident={incident} config={config} />
        <div className="flex-row bg-stroke-primary h-[1px]"></div>
        {existingResponse ? (
          <>
            <TemplatedTextDisplay
              className="grow"
              value={existingResponse.response}
              style={TemplatedTextDisplayStyle.Compact}
            />
            <PostmortemSectionImages images={existingResponse.images} />
          </>
        ) : (
          <div className="text-content-tertiary">Click to start writing</div>
        )}
      </SectionWrapper>
    </Button>
  );
};

const PostmortemSectionImages = ({ images }: { images: EnrichedImage[] }) => {
  const [zoomImageSource, setZoomImageSource] = useState<string | undefined>(
    undefined,
  );

  return (
    <>
      <Modal
        title="No title shown"
        isOpen={!!zoomImageSource}
        analyticsTrackingId={null}
        onClose={() => setZoomImageSource(undefined)}
        hideHeader
        maximised
      >
        <ModalContent>
          <img src={zoomImageSource} alt="" />
        </ModalContent>
        <ModalFooter
          hideConfirmButton={true}
          confirmButtonType="button"
          onConfirm={() => setZoomImageSource(undefined)}
          onClose={() => setZoomImageSource(undefined)}
          cancelButtonText={"Close"}
        />
      </Modal>
      {images.map((image) => (
        <PostmortemSectionImage
          key={image.id}
          caption={image.caption}
          url={image.url}
          onClick={(e) => {
            // Prevent the click from bubbling up to the parent
            // and triggering the edit section behaviour
            e.stopPropagation();
            e.preventDefault();

            setZoomImageSource(image.url);
          }}
        />
      ))}
    </>
  );
};

const PostmortemSectionImage = ({
  caption,
  url,
  onClick,
}: {
  caption?: string;
  url: string;
  onClick: (e: React.MouseEvent) => void;
}) => {
  return (
    <div
      className="rounded-2 p-4 bg-surface-secondary flex flex-col items-center gap-3 cursor-pointer"
      onClick={(e) => onClick(e)}
    >
      <div className="flex justify-center">
        <img
          src={url}
          className="rounded-1 shadow max-w-[80%] max-h-[500px]"
          alt={caption ?? "Image attached to the section response"}
        />
      </div>
      {caption && (
        <div className="text-xs-med text-content-tertiary">{caption}</div>
      )}
    </div>
  );
};

const PostmortemSectionCustomFields = ({
  incident,
  config,
}: {
  incident: Incident;
  config: PostmortemTemplateSectionConfig;
}): React.ReactElement => {
  const isWithinTeamsTab = useIsWithinMicrosoftTeamsTab();

  const customFieldEntriesToShow = incident.custom_field_entries.filter(
    ({ custom_field }) => {
      return (config.custom_field_ids || []).includes(custom_field.id);
    },
  );

  if (customFieldEntriesToShow.length === 0) {
    return <></>;
  }

  return (
    <div className="space-y-1">
      {customFieldEntriesToShow.map(({ custom_field: customField, values }) => {
        const value = renderCustomFieldValues(
          customField,
          values,
          isWithinTeamsTab,
          BadgeSize.Small, // render the catlaog entry badge with small size
        );
        const allowWrap =
          customField.field_type === CustomFieldTypeInfoFieldTypeEnum.Text;

        return (
          <PostmortemSectionCustomFieldWrapper
            customField={customField}
            key={customField.id}
            allowWrap={allowWrap}
            isEditing={false}
          >
            {value}
          </PostmortemSectionCustomFieldWrapper>
        );
      })}
    </div>
  );
};

export const PostmortemSectionCustomFieldWrapper = ({
  customField,
  tooltipContent,
  children,
  allowWrap,
  isEditing,
}: {
  customField: CustomFieldTypeInfo | CustomField;
  tooltipContent?: React.ReactNode;
  children: React.ReactNode;
  allowWrap?: boolean;
  isEditing: boolean;
}): React.ReactElement => {
  const tooltip = tooltipContent ? (
    <Tooltip light content={tooltipContent}>
      <Badge theme={BadgeTheme.Naked}>
        <Icon
          size={IconSize.Small}
          className={tcx("ml-1")}
          id={IconEnum.Info}
        />
      </Badge>
    </Tooltip>
  ) : null;

  return (
    <div
      className={"flex-center-y text-sm min-h-6"}
      key={`${customField.name}`}
    >
      <span
        className={tcx(
          "text-content-tertiary mr-2 min-w-[155px] flex-none flex-center-y",
          !isEditing && "text-xs-med",
        )}
      >
        {customField.name}
        {tooltip}
      </span>
      <div className="flex-center-y w-full">
        <span
          className={tcx(
            "w-full",
            allowWrap && "truncate",
            !isEditing && "text-xs-med",
          )}
        >
          {children}
        </span>
      </div>
    </div>
  );
};
