import { Button, ButtonTheme, Icon, IconEnum, IconSize } from "@incident-ui";
import { tcx } from "src/utils/tailwind-classes";

export const IncidentSidebarEntry = ({
  label,
  value,
  onClick,
  align = "center",
  invalid,
  allowWrap,
}: {
  label: React.ReactNode;
  value: React.ReactNode;
  icon?: IconEnum;
  onClick?: () => void;
  align?: "center" | "baseline" | "start" | "end" | "stretch";
  invalid?: boolean;
  allowWrap?: boolean;
}): React.ReactElement => {
  return (
    <div
      className={tcx("flex text-sm justify-between", {
        "items-center": align === "center",
        "items-baseline": align === "baseline",
        "items-start": align === "start",
        "items-end": align === "end",
        "items-stretch": align === "stretch",
      })}
      key={`${label}|${value}`}
    >
      <span className="text-content-tertiary mr-2 w-[40%] flex-none">
        {label}
      </span>
      <div className="flex-center-y max-w-[calc(60%-1rem)]">
        {onClick ? (
          <Button
            theme={ButtonTheme.Naked}
            onClick={onClick}
            analyticsTrackingId={null}
            className={`text-right max-w-full truncate`}
          >
            <span>
              {invalid && (
                <div className="inline-flex align-bottom">
                  <Icon
                    id={IconEnum.Warning}
                    className={
                      "mr-0.5 text-alarmalade-600 child:stroke-[1.5px] self-center"
                    }
                    size={IconSize.Medium}
                  />
                </div>
              )}
              {value}
            </span>
          </Button>
        ) : (
          <span
            className={tcx(
              "text-right max-w-full",
              allowWrap ? undefined : "truncate",
            )}
          >
            {value}
          </span>
        )}
      </div>
    </div>
  );
};
