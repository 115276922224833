import {
  PostIncidentTaskSlim,
  RemindersCreateRequestBodyReminderTypeEnum,
} from "@incident-io/api";
import { Button, ButtonTheme, IconEnum } from "@incident-ui";
import { ToastSideEnum, ToastTheme } from "@incident-ui/Toast/Toast";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { useClient } from "src/contexts/ClientContext";
import { useMutation } from "src/utils/fetchData";

export const RemindPostIncidentTaskButton = ({
  task,
  buttonClassName,
  iconProps,
}: {
  task: PostIncidentTaskSlim;
  buttonClassName?: string;
  iconProps?: { className: string };
}) => {
  const apiClient = useClient();
  const showToast = useToast();

  const [sendReminder, { saving: sendingReminder }] = useMutation(
    async () => {
      await apiClient.remindersCreate({
        createRequestBody: {
          reminder_type:
            RemindersCreateRequestBodyReminderTypeEnum.PostIncidentTask,
          resource_ids: [task.id],
          targets: ["post_incident_task.assignee"],
        },
      });
    },
    {
      onSuccess: async () => {
        showToast({
          theme: ToastTheme.Success,
          title: "Reminder sent",
          toastSide: ToastSideEnum.TopRight,
        });
      },
    },
  );

  const taskPending = !task.completed_at && !task.rejected_at;

  if (!task.assignee || !taskPending) {
    return null;
  }

  return (
    <Button
      theme={ButtonTheme.Ghost}
      onClick={(e) => {
        e.stopPropagation();
        sendReminder({});
      }}
      analyticsTrackingId="post-incident-task-reminder-send"
      analyticsTrackingMetadata={{
        incident_task_id: task.id,
      }}
      loading={sendingReminder}
      icon={IconEnum.SpeechImportant}
      className={buttonClassName}
      iconProps={iconProps}
    >
      Send reminder
    </Button>
  );
};
