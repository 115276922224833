/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EscalationsCreateRequestBody
 */
export interface EscalationsCreateRequestBody {
    /**
     * ID of the alert that triggered this escalation - only used for auto-triggered escalations
     * @type {string}
     * @memberof EscalationsCreateRequestBody
     */
    alert_id?: string;
    /**
     * ID of the alert route that triggered this escalation - only used for auto-triggered escalations
     * @type {string}
     * @memberof EscalationsCreateRequestBody
     */
    alert_route_id?: string;
    /**
     * More extensive message that is provided with this escalation, default to incident summary
     * @type {string}
     * @memberof EscalationsCreateRequestBody
     */
    description?: string;
    /**
     * ID of the native escalation path
     * @type {string}
     * @memberof EscalationsCreateRequestBody
     */
    escalation_path_id?: string;
    /**
     * Unique key that prevents creating duplicate escalations
     * @type {string}
     * @memberof EscalationsCreateRequestBody
     */
    idempotency_key: string;
    /**
     * ID of the incident that this escalation applies for - only used for manual escalations
     * @type {string}
     * @memberof EscalationsCreateRequestBody
     */
    incident_id?: string;
    /**
     * The priority of this escalation
     * @type {string}
     * @memberof EscalationsCreateRequestBody
     */
    priority_id?: string;
    /**
     * Which ticker version did this escalation execute with
     * @type {string}
     * @memberof EscalationsCreateRequestBody
     */
    ticker_version?: string;
    /**
     * Human readable title of this escalation, defaulting to incident name
     * @type {string}
     * @memberof EscalationsCreateRequestBody
     */
    title?: string;
    /**
     * IDs of the users being escalated to
     * @type {Array<string>}
     * @memberof EscalationsCreateRequestBody
     */
    user_ids?: Array<string>;
}

export function EscalationsCreateRequestBodyFromJSON(json: any): EscalationsCreateRequestBody {
    return EscalationsCreateRequestBodyFromJSONTyped(json, false);
}

export function EscalationsCreateRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): EscalationsCreateRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'alert_id': !exists(json, 'alert_id') ? undefined : json['alert_id'],
        'alert_route_id': !exists(json, 'alert_route_id') ? undefined : json['alert_route_id'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'escalation_path_id': !exists(json, 'escalation_path_id') ? undefined : json['escalation_path_id'],
        'idempotency_key': json['idempotency_key'],
        'incident_id': !exists(json, 'incident_id') ? undefined : json['incident_id'],
        'priority_id': !exists(json, 'priority_id') ? undefined : json['priority_id'],
        'ticker_version': !exists(json, 'ticker_version') ? undefined : json['ticker_version'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'user_ids': !exists(json, 'user_ids') ? undefined : json['user_ids'],
    };
}

export function EscalationsCreateRequestBodyToJSON(value?: EscalationsCreateRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'alert_id': value.alert_id,
        'alert_route_id': value.alert_route_id,
        'description': value.description,
        'escalation_path_id': value.escalation_path_id,
        'idempotency_key': value.idempotency_key,
        'incident_id': value.incident_id,
        'priority_id': value.priority_id,
        'ticker_version': value.ticker_version,
        'title': value.title,
        'user_ids': value.user_ids,
    };
}

