import {
  Alert,
  AlertSchema,
  AlertsEvaluateResponseBody,
  AlertSourceConfig,
  CatalogType,
  EngineParamBinding,
  Expression,
  TextDocument,
} from "@incident-io/api";
import { isPrimitive } from "@incident-shared/attribute";
import { getPrimitiveIcon } from "@incident-shared/catalog";
import { slugForCatalogType } from "@incident-shared/catalog/helpers";
import {
  TemplatedTextDisplay,
  TemplatedTextDisplayStyle,
} from "@incident-shared/forms/v1/TemplatedText";
import {
  ColorPaletteEnum,
  getColorPalette,
} from "@incident-shared/utils/ColorPalettes";
import {
  Button,
  ButtonSize,
  ButtonTheme,
  Callout,
  CalloutTheme,
  GenericErrorMessage,
  Icon,
  IconEnum,
  IconSize,
  Loader,
  StackedList,
  Txt,
} from "@incident-ui";
import { Drawer, DrawerProps, DrawerTitle } from "@incident-ui/Drawer/Drawer";
import { JSONPreview } from "@incident-ui/JSONPreview/JSONPreview";
import { ToastTheme } from "@incident-ui/Toast/Toast";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { useState } from "react";
import { tcx } from "src/utils/tailwind-classes";

import { useAPI, useAPIMutation } from "../../../utils/swr";
import { useClipboard } from "../../../utils/useClipboard";

export const AlertDebugDrawer = ({
  onClose,
  alert,
  schema,
  sourceConfig,
}: {
  onClose: () => void;
  alert: Alert;
  schema: AlertSchema;
  sourceConfig: AlertSourceConfig;
}) => {
  const drawerProps: Omit<DrawerProps, "children"> = {
    onClose,
    width: "medium",
  };
  const { copyTextToClipboard, hasCopied } = useClipboard();
  const showToast = useToast();

  if (!alert.evaluation_failures) {
    alert.evaluation_failures = {};
  }

  const {
    data: alertPayloadResp,
    isLoading: alertPayloadIsLoading,
    error: alertPayloadError,
  } = useAPI("alertsShowAlert", {
    id: alert.id,
    includePayload: true,
  });

  const { trigger: onReevaluate, isMutating: saving } = useAPIMutation(
    "alertsShowAlert",
    {
      id: alert.id,
      includePayload: true,
    },
    async (apiClient) => {
      await apiClient.alertsEvaluate({
        id: alert.id,
      });
    },
    {
      onSuccess: (resp: AlertsEvaluateResponseBody) => {
        // When we re-evaluate attributes, we want to refresh the contents of the drawer.
        // We can't do this using revalidate because SWR reloads the whole page due to the
        // parent page depending on the same alert. This does the trick, and on drawer
        // close SWR refreshes the main alert details.
        alert.attribute_values = resp.alert.attribute_values;

        if (
          resp.alert.evaluation_failures &&
          Object.keys(resp.alert.evaluation_failures).length === 0
        ) {
          showToast({
            title: "All attributes evaluated",
            theme: ToastTheme.Success,
          });
        } else {
          showToast({
            title: "Some attributes still missing",
            theme: ToastTheme.Warning,
          });
        }
      },
      onError: () => {
        showToast({
          title: "Evaluation errored",
          theme: ToastTheme.Error,
        });
      },
    },
  );

  const {
    data: { catalog_types: catalogTypes },
    isLoading: catalogTypesLoading,
  } = useAPI("catalogListTypes", {}, { fallbackData: { catalog_types: [] } });

  if (alertPayloadError) {
    return (
      <Drawer {...drawerProps}>
        <DrawerTitle
          icon={IconEnum.CodeBlock}
          title={"Inspect alert"}
          onClose={onClose}
          closeIcon={IconEnum.Close}
        />
        <GenericErrorMessage />;
      </Drawer>
    );
  }

  if (alertPayloadIsLoading || !alertPayloadResp || catalogTypesLoading) {
    return (
      <Drawer {...drawerProps}>
        <DrawerTitle
          icon={IconEnum.CodeBlock}
          title={"Inspect alert"}
          onClose={onClose}
          closeIcon={IconEnum.Close}
        />
        <Loader />
      </Drawer>
    );
  }

  return (
    <Drawer {...drawerProps}>
      <div className={"flex flex-col h-full"}>
        <DrawerTitle
          icon={IconEnum.CodeBlock}
          title={"Inspect alert"}
          onClose={onClose}
          closeIcon={IconEnum.Close}
        />
        <div
          className={
            "flex flex-col h-full p-6 space-y-6 overflow-y-auto scrollbar-none"
          }
        >
          {Object.keys(alert.evaluation_failures).length > 0 ? (
            <AlertEvaluationFailuresCallout
              alertSourceConfigId={alert.alert_source_config_id}
            />
          ) : (
            <Callout
              theme={CalloutTheme.Info}
              showIcon={false}
              className={"!border-0"}
            >
              <div className={"p-3 flex flex-row shrink-0 gap-3"}>
                <Txt bold className={"text-blue-content w-6 min-w-6"}>
                  &#123; &#125;
                </Txt>
                <div className={"flex flex-col gap-1"}>
                  <Txt bold>Everything looks good</Txt>
                  <Txt>
                    We successfully parsed data for all configured attributes we
                    found in the event payload, but you can inspect it here if
                    needed.
                  </Txt>
                  {alert.alert_source_config_id && (
                    <div className="flex w-auto pt-2">
                      <Button
                        analyticsTrackingId={"debug-alert.view-alert-source"}
                        href={`/alerts/sources/${alert.alert_source_config_id}/edit`}
                        theme={ButtonTheme.Secondary}
                        openInNewTab
                      >
                        View alert source
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            </Callout>
          )}

          <div>
            <Txt bold>Attributes</Txt>
            <StackedList className={"w-full border-stroke flex flex-col mt-2"}>
              {/*Title and Description are special*/}
              <AlertAttributeTypeRow
                label={"Title"}
                type={"String"}
                value={alert.title}
                failure={alert.evaluation_failures["title"]}
              />
              {alert.description && (
                <AlertAttributeTypeRow
                  label={"Description"}
                  type={"Text"}
                  value={alert.description}
                  failure={alert.evaluation_failures["description"]}
                />
              )}

              {/*Go through each schema attribute, and display ones that have been configured*/}
              {schema.attributes.map((attr) => {
                const templateBinding = sourceConfig.template.bindings[attr.id];
                if (!templateBinding) {
                  return null;
                }
                const failure = alert.evaluation_failures[attr.id];

                const binding = alert.attribute_values[attr.id];
                let value = "";
                // We don't need to actually map the binding to its associated catalog resource here, we want the
                // textual value we grabbed from the payload.
                if (binding?.value) {
                  value = binding.value.literal || binding.value.label;
                }
                if (binding?.array_value) {
                  value = binding.array_value
                    .map((v) => {
                      return v.literal || v.label;
                    })
                    .join(", ");
                }
                const catalogType = catalogTypes.find((r) => {
                  return (
                    `CatalogEntry["${r.type_name}"]` === attr.type ||
                    `CatalogEntry["${r.id}"]` === attr.type
                  );
                });
                return (
                  <AlertAttributeTypeRow
                    key={attr.id}
                    label={attr.name}
                    type={attr.type}
                    catalogType={catalogType}
                    failure={failure}
                    value={value}
                    source={getSource(
                      templateBinding,
                      sourceConfig.template.expressions,
                    )}
                  />
                );
              })}
            </StackedList>
          </div>

          {/*Show the event payload we received*/}
          <JSONPreview
            payload={JSON.parse(alertPayloadResp.alert?.payload || "{}")}
            defaultExpanded={false}
            containerClassName={"rounded-2"}
            titleElement={
              <div
                className={
                  "flex flex-row items-center justify-between w-full px-4 py-2 bg-surface-invert mono rounded-t-lg"
                }
              >
                <Txt className={"text-white"}>
                  {sourceConfig.alert_source?.name} event payload
                </Txt>
                {!hasCopied ? (
                  <Icon
                    id={IconEnum.Clipboard}
                    className="inline text-slate-400 hover:cursor-pointer"
                    onClick={() =>
                      copyTextToClipboard(
                        (alertPayloadResp.alert?.payload || "{}") as string,
                      )
                    }
                  />
                ) : (
                  <Txt xs className={"text-content-invert"}>
                    Copied
                  </Txt>
                )}
              </div>
            }
          />
        </div>

        {/*Let people evaluate again manually*/}
        <div className="flex w-full sticky bottom-0 z-[50] px-6 py-4 bg-white border-t border-stroke">
          <Button
            analyticsTrackingId="alert-debug-drawer.evaluate"
            theme={ButtonTheme.Primary}
            className="ml-auto"
            onClick={() => onReevaluate({})}
            loading={saving}
          >
            Re-evaluate attributes
          </Button>
        </div>
      </div>
    </Drawer>
  );
};

const AlertAttributeTypeRow = ({
  label,
  failure,
  type,
  catalogType,
  value,
  source,
}: {
  label: string;
  failure: string;
  type: string;
  catalogType?: CatalogType;
  value?: string | TextDocument;
  source?: string;
}) => {
  const [collapsed, setCollapsed] = useState<boolean>(true);
  const iconClasses = getColorPalette(
    catalogType?.color || ColorPaletteEnum.Slate,
  );

  return (
    <div className={"flex flex-col p-4 space-y-4"}>
      <div
        className="flex flex-row flex-center-x items-center space-x-2 hover:cursor-pointer"
        onClick={() => setCollapsed(!collapsed)}
      >
        {failure ? (
          <Icon
            id={IconEnum.Warning}
            className={"mr-0.5 text-red-600 child:stroke-[1.5px] self-center"}
          />
        ) : !value ? (
          <Icon
            id={IconEnum.DottedCircle}
            className={
              // The padding shenanigans are to make the icon the same size as the others.
              "p-1 mr-0.5 text-slate-600 child:stroke-[1.5px] self-center"
            }
          />
        ) : (
          <Icon
            id={IconEnum.Success}
            className={"mr-0.5 text-green-600 child:stroke-[1.5px] self-center"}
          />
        )}
        <div
          className={tcx(
            iconClasses.background,
            iconClasses.border,
            iconClasses.icon,
            "mr-1.5 rounded",
          )}
        >
          {catalogType && <Icon id={catalogType.icon} />}
          {isPrimitive(type) && (
            <Icon
              id={getPrimitiveIcon(type)}
              className="text-content-secondary"
            />
          )}
        </div>
        <Txt className={"grow"}>{label}</Txt>
        <div className={"flex-end"}>
          {collapsed ? (
            <Icon
              id={IconEnum.Expand}
              size={IconSize.Large}
              className="text-content-tertiary"
            />
          ) : (
            <Icon
              id={IconEnum.Collapse}
              size={IconSize.Large}
              className="text-content-tertiary"
            />
          )}
        </div>
      </div>
      {!collapsed && (
        <div
          className={tcx("bg-surface-invert rounded-2 p-2 flex flex-col", {
            "gap-2": failure,
          })}
        >
          {source && (
            <div className="flex flex-row p-3 w-full bg-surface-invert rounded-[6px] mono text-sm font-normal overflow-x-auto scrollbar-none">
              {source.startsWith("$") && (
                <Txt className={"text-green-400"}>$</Txt>
              )}
              <Txt className={"text-white"}>{source.replace("$", "")}</Txt>
            </div>
          )}
          {value && (
            <div className={"space-y-1"}>
              <code className="w-full text-sm block text-left text-slate-300 p-3 break-words">
                {typeof value === "object" ? (
                  <TemplatedTextDisplay
                    className="font-normal text-sm flex flex-col gap-3 leading-6 grow"
                    value={value.text_node}
                    style={TemplatedTextDisplayStyle.Compact}
                  />
                ) : (
                  value
                )}
              </code>

              <AlertDebugButtons catalogType={catalogType} />
            </div>
          )}
          {failure && (
            <div
              className={
                "rounded-2 p-5 border-red-500 border bg-red-500 bg-opacity-20 space-y-5"
              }
            >
              <div className={"space-y-1"}>
                <Txt
                  xs
                  className={
                    "font-medium uppercase tracking-widest text-red-500"
                  }
                >
                  Error
                </Txt>
                <Txt className={"text-white"}>{failure}</Txt>
              </div>
              <AlertDebugButtons catalogType={catalogType} />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const AlertDebugButtons = ({ catalogType }: { catalogType?: CatalogType }) => {
  if (!catalogType) {
    return null;
  }

  return (
    <div className={"flex flex-row gap-3 px-2 pb-2"}>
      <Button
        analyticsTrackingId={"debug-alert.view-catalog-type"}
        href={`/catalog/${slugForCatalogType(catalogType)}`}
        className="text-white border !border-slate-700 hover:border-white"
        theme={ButtonTheme.UnstyledPill}
        openInNewTab
      >
        View Catalog type
      </Button>
    </div>
  );
};

export const AlertEvaluationFailuresCallout = ({
  alertSourceConfigId,
  showDebug = false,
  onClick,
}: {
  alertSourceConfigId?: string;
  showDebug?: boolean;
  onClick?: () => void;
}) => {
  return (
    <Callout
      theme={CalloutTheme.Warning}
      showIcon={false}
      className={"!border-0"}
    >
      <div className={"flex flex-row justify-between items-center p-3"}>
        <div className={"flex flex-row gap-3 shrink-0"}>
          <Txt bold className={"text-amber-content"}>
            {/* These are the unicode values for { and }, eslint doesn't like the rcub versions! */}
            &#123; &#125;
          </Txt>
          <div className={"flex flex-col gap-1"}>
            <Txt bold className={"text-amber-900"}>
              Some data may be missing from this alert
            </Txt>
            <Txt className={"text-amber-800"}>
              Review the attributes and payload to debug what might have caused
              an error.
            </Txt>
          </div>
        </div>
        <div className={"flex flex-row gap-3"}>
          {showDebug && onClick && (
            <Button
              analyticsTrackingId={"debug-alert"}
              onClick={onClick}
              size={ButtonSize.Small}
            >
              Inspect
            </Button>
          )}
          {alertSourceConfigId && (
            <Button
              analyticsTrackingId={"debug-alert.view-alert-source"}
              href={`/alerts/sources/${alertSourceConfigId}/edit`}
              theme={ButtonTheme.UnstyledPill}
              className="text-white border !border-slate-700 hover:border-white"
              openInNewTab
            >
              View alert source
            </Button>
          )}
        </div>
      </div>
    </Callout>
  );
};

// getSource finds the JavaScript expression that was used to fetch an attribute's binding value.
const getSource = (
  binding: EngineParamBinding | undefined,
  expressions: Expression[],
) => {
  if (!binding) {
    return undefined;
  }
  let ref: string | undefined;
  if (binding.value && binding.value.reference) {
    ref = binding.value.reference;
  }
  if (
    binding.array_value &&
    binding.array_value.length > 0 &&
    binding.array_value[0].reference
  ) {
    ref = binding.array_value[0].reference;
  }
  if (!ref) {
    return undefined;
  }

  const expr = expressions.find(
    (expr) => ref === `expressions["${expr.reference}"]`,
  );
  if (
    expr?.root_reference === "payload" &&
    (expr.operations || []).length > 0
  ) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore this definitely has parse.
    return expr.operations[0].parse.source;
  }

  return undefined;
};
