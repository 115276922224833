import { Icon, IconEnum } from "@incident-ui";

export const CompletedPostIncidentTaskTag = (): React.ReactElement => {
  return (
    <div className="!bg-green-surface !text-green-content !rounded-2 px-2 py-2 space-x-1 flex-center-y text-sm">
      <Icon id={IconEnum.Checkmark} className="text-green-content" />
      <span className={"pr-1"}>Completed</span>
    </div>
  );
};

export const RejectedPostIncidentTaskTag = (): React.ReactElement => {
  return (
    <div className="!bg-surface-secondary !text-slate-700 !rounded-2 px-2 py-2 space-x-1 flex-center-y text-sm">
      <Icon id={IconEnum.Close} className="text-content-tertiary" />
      <span className={"pr-1"}>Skipped</span>
    </div>
  );
};
