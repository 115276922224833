/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AvailableCatalogType,
    AvailableCatalogTypeFromJSON,
    AvailableCatalogTypeFromJSONTyped,
    AvailableCatalogTypeToJSON,
} from './AvailableCatalogType';

/**
 * 
 * @export
 * @interface IntegrationSettings
 */
export interface IntegrationSettings {
    /**
     * url to use when trying to install this integration (either an OAuth URL or a local form url)
     * @type {string}
     * @memberof IntegrationSettings
     */
    add_integration_url?: string;
    /**
     * Which catalog types are available for this integration
     * @type {Array<AvailableCatalogType>}
     * @memberof IntegrationSettings
     */
    available_catalog_types: Array<AvailableCatalogType>;
    /**
     * is the integration installed
     * @type {boolean}
     * @memberof IntegrationSettings
     */
    installed: boolean;
    /**
     * unique key describing the integration (not for display in the UI)
     * @type {string}
     * @memberof IntegrationSettings
     */
    provider: IntegrationSettingsProviderEnum;
    /**
     * name of the provider, which can be used for display purposes
     * @type {string}
     * @memberof IntegrationSettings
     */
    provider_name: string;
    /**
     * Information on what needs to happen to regain full use of the integration.
     * @type {string}
     * @memberof IntegrationSettings
     */
    reconnection_reason: IntegrationSettingsReconnectionReasonEnum;
    /**
     * does the integration require talking to sales
     * @type {boolean}
     * @memberof IntegrationSettings
     */
    requires_upgrade: boolean;
}

/**
* @export
* @enum {string}
*/
export enum IntegrationSettingsProviderEnum {
    Asana = 'asana',
    Statuspage = 'statuspage',
    ClickUp = 'click_up',
    Confluence = 'confluence',
    Cortex = 'cortex',
    Datadog = 'datadog',
    Github = 'github',
    Gitlab = 'gitlab',
    GoogleDocs = 'google_docs',
    GoogleMeet = 'google_meet',
    GoogleCalendar = 'google_calendar',
    GoogleWorkspace = 'google_workspace',
    Jira = 'jira',
    JiraServer = 'jira_server',
    Linear = 'linear',
    MicrosoftTeams = 'microsoft_teams',
    Notion = 'notion',
    Opsgenie = 'opsgenie',
    Opslevel = 'opslevel',
    Pagerduty = 'pagerduty',
    Salesforce = 'salesforce',
    Sentry = 'sentry',
    ServiceNow = 'service_now',
    Shortcut = 'shortcut',
    Scim = 'scim',
    Slack = 'slack',
    SplunkOnCall = 'splunk_on_call',
    IncidentIoStatusPages = 'incident_io_status_pages',
    IncidentIoOnCall = 'incident_io_on_call',
    Vanta = 'vanta',
    Webhooks = 'webhooks',
    Zendesk = 'zendesk',
    Zoom = 'zoom'
}/**
* @export
* @enum {string}
*/
export enum IntegrationSettingsReconnectionReasonEnum {
    Empty = '',
    PagerdutyLimitedUser = 'pagerduty_limited_user',
    GithubMissingScopes = 'github_missing_scopes',
    TokenExpired = 'token_expired',
    InvalidConfig = 'invalid_config',
    InsufficientPermissions = 'insufficient_permissions',
    IncompleteConfig = 'incomplete_config',
    SalesforceTooManyAccounts = 'salesforce_too_many_accounts',
    MultipleAccounts = 'multiple_accounts',
    NotACalendarUser = 'not_a_calendar_user'
}

export function IntegrationSettingsFromJSON(json: any): IntegrationSettings {
    return IntegrationSettingsFromJSONTyped(json, false);
}

export function IntegrationSettingsFromJSONTyped(json: any, ignoreDiscriminator: boolean): IntegrationSettings {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'add_integration_url': !exists(json, 'add_integration_url') ? undefined : json['add_integration_url'],
        'available_catalog_types': ((json['available_catalog_types'] as Array<any>).map(AvailableCatalogTypeFromJSON)),
        'installed': json['installed'],
        'provider': json['provider'],
        'provider_name': json['provider_name'],
        'reconnection_reason': json['reconnection_reason'],
        'requires_upgrade': json['requires_upgrade'],
    };
}

export function IntegrationSettingsToJSON(value?: IntegrationSettings | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'add_integration_url': value.add_integration_url,
        'available_catalog_types': ((value.available_catalog_types as Array<any>).map(AvailableCatalogTypeToJSON)),
        'installed': value.installed,
        'provider': value.provider,
        'provider_name': value.provider_name,
        'reconnection_reason': value.reconnection_reason,
        'requires_upgrade': value.requires_upgrade,
    };
}

