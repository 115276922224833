import { IncidentTimelineElementTypeEnum, TimelineGap } from "@incident-io/api";
import { EmptyState, IconEnum } from "@incident-ui";
import { format } from "date-fns";
import React from "react";
import { tcx } from "src/utils/tailwind-classes";

import { TimelineGap as TimelineGapComponent } from "./TimelineGap";
import { TimelineHeader } from "./TimelineHeader";
import { TimelineSpacer } from "./TimelineSpacer";

export type GroupedTimelineElement<
  ItemType extends GenericTimelineItem,
  AccessorKey extends string,
> = {
  date: Date;
  items: Array<TimelineElement<ItemType, AccessorKey>>;
};

type TimelineElement<
  ItemType extends GenericTimelineItem,
  AccessorKey extends string,
> = {
  timeline_gap?: TimelineGap;
  type: "time_gap" | AccessorKey;
} & {
  [key in AccessorKey]?: ItemType;
};

export type GenericTimelineItem = {
  id: string;
};

type TimeineProps<T extends GenericTimelineItem, AccessorKey extends string> = {
  items: GroupedTimelineElement<T, AccessorKey>[];
  accessorKey: AccessorKey;
  renderItem: ({
    item,
    hideSpacer,
    compact,
  }: {
    item: T;
    hideSpacer: boolean;
    compact?: boolean;
  }) => React.ReactNode;
  className?: string;
  minimizedItems: string[];
  setMinimizedItems: React.Dispatch<React.SetStateAction<string[]>>;
  hideHeader?: boolean;
  headerAccessory?: React.ReactNode;
  trailingItem?: React.ReactNode;
  supportsMinimizing?: boolean;
  renderCompactItems?: boolean;
};

export const Timeline = <
  T extends GenericTimelineItem,
  AccessorKey extends string = "timeline_item",
>({
  accessorKey,
  items,
  renderItem,
  className,
  minimizedItems,
  setMinimizedItems,
  hideHeader,
  headerAccessory,
  supportsMinimizing = true,
  trailingItem,
  renderCompactItems,
}: TimeineProps<T, AccessorKey>) => {
  if (items.length === 0) {
    return (
      <div className="flex flex-col gap-2">
        {!hideHeader && (
          <TimelineHeader
            minimizedItems={minimizedItems}
            setMinimizedItems={setMinimizedItems}
            timelineItems={items}
            headerAccessory={headerAccessory}
            supportsMinimizing={supportsMinimizing}
            accessorKey={accessorKey}
          />
        )}
        <EmptyState
          content="You have removed all of your items from the timeline."
          icon={IconEnum.Activity}
        />
      </div>
    );
  }

  return (
    <div className={className}>
      {items.map((groupedTimelineItem, groupIndex) => (
        <TimelineDateGroup
          key={groupedTimelineItem.date.toString()}
          date={groupedTimelineItem.date}
          className={tcx(groupIndex === 0 && "mb-4")}
          actions={
            !hideHeader && groupIndex === 0 ? (
              <TimelineHeader
                minimizedItems={minimizedItems}
                setMinimizedItems={setMinimizedItems}
                timelineItems={items}
                headerAccessory={headerAccessory}
                supportsMinimizing={supportsMinimizing}
                accessorKey={accessorKey}
              />
            ) : undefined
          }
        >
          {groupIndex > 0 && <TimelineSpacer />}
          {groupedTimelineItem.items.map((element, elementIndex) => {
            const isLast =
              elementIndex === groupedTimelineItem.items.length - 1 &&
              groupIndex === items.length - 1;

            switch (element.type) {
              case accessorKey: {
                const timelineItem = element?.[accessorKey];
                if (timelineItem === undefined) {
                  return null;
                }
                return renderItem({
                  item: timelineItem,
                  hideSpacer: isLast,
                  compact: renderCompactItems,
                });
              }
              case IncidentTimelineElementTypeEnum.TimeGap: {
                const gapItem = element.timeline_gap;
                if (gapItem === undefined) {
                  return null;
                }
                return (
                  <TimelineGapComponent
                    key={gapItem.duration}
                    duration={gapItem.duration}
                  />
                );
              }
              default:
                return null;
            }
          })}
          {groupIndex === items.length - 1 && trailingItem}
        </TimelineDateGroup>
      ))}
    </div>
  );
};

type TimelineDateGroupProps = {
  date: Date;
  children: React.ReactNode;
  actions?: React.ReactNode;
  className?: string;
  childrenClassName?: string;
};

export const TimelineDateGroup = ({
  date,
  children,
  actions,
  className,
  childrenClassName,
}: TimelineDateGroupProps) => {
  return (
    <div>
      <div className={tcx("md:flex justify-between items-center", className)}>
        <div className="text-xs-med text-content-tertiary flex-shrink-0">
          {format(date, "EEEE do MMMM")}
        </div>
        <div>{actions}</div>
      </div>
      <div className={childrenClassName}>{children}</div>
    </div>
  );
};
