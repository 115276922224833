import { Incident } from "@incident-io/api";
import { useAPI } from "src/utils/swr";

export const useIncident = (id: string | null) => {
  const { data, ...rest } = useAPI(id == null ? null : "incidentsShow", {
    id: id ?? "",
  });

  if (!data) {
    return { incident: null, applicableFields: null, ...rest };
  }

  return {
    incident: data.incident,
    applicableFields: data.applicable_fields,
    ...rest,
  };
};

export const useSlackTeamConfig = (incident: Incident | null) => {
  const {
    data: { slack_team_configs: slackTeamConfigs },
    ...rest
  } = useAPI("slackTeamConfigsList", undefined, {
    fallbackData: { slack_team_configs: [] },
  });

  // We only want to show Slack Team info if the organisation has multiple teams
  // (i.e. is part of an Enterprise Grid)
  const slackTeam =
    slackTeamConfigs.length > 1
      ? slackTeamConfigs.find(
          (config) => config.slack_team_id === incident?.slack_team_id,
        )
      : undefined;

  return { slackTeam, ...rest };
};

export const usePoliciesAndViolations = (incidentId: string | null) => {
  const {
    data: { policies },
    isLoading: policiesLoading,
  } = useAPI("policiesList", undefined, { fallbackData: { policies: [] } });

  const {
    data: { policy_violations: policyViolations },
    isLoading: violationsLoading,
    mutate: refetchPolicyViolations,
  } = useAPI(
    incidentId == null ? null : "policiesListViolations",
    { incidentIds: [incidentId ?? ""] },
    { fallbackData: { policy_violations: [], incidents: [] } },
  );

  return {
    policies,
    policyViolations,
    isLoading: policiesLoading || violationsLoading,
    refetchPolicyViolations,
  };
};

export const useStatusPageIncidents = (incidentId: string | null) => {
  const {
    data: { status_page_incidents: statusPageIncidents },
    ...rest
  } = useAPI(
    incidentId != null ? "statusPageListIncidents" : null,
    { responseIncidentId: incidentId ?? "" },
    { fallbackData: { status_page_incidents: [] } },
  );

  return { statusPageIncidents, ...rest };
};

export const useAttachments = (incidentId: string | null) => {
  const {
    data: { incident_attachments: attachments },
    ...rest
  } = useAPI(
    incidentId != null ? "incidentAttachmentsList" : null,
    { incidentId: incidentId ?? "" },
    { fallbackData: { incident_attachments: [] } },
  );

  return { attachments, ...rest };
};

export const useActions = (incidentId: string | null) => {
  const {
    data: { actions },
    ...rest
  } = useAPI(
    incidentId == null ? null : "actionsList",
    { incidentId: incidentId ?? "" },
    { fallbackData: { actions: [] } },
  );

  return { actions, ...rest };
};

export const useFollowUps = (incidentId: string | null) => {
  const {
    data: { follow_ups: followUps },
    ...rest
  } = useAPI(
    incidentId == null ? null : "followUpsList",
    { incidentId: incidentId ?? "" },
    { fallbackData: { follow_ups: [] } },
  );

  return { followUps, ...rest };
};

export const useActivityLogs = (incidentId: string | null) => {
  const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const {
    data: { activities },
    ...rest
  } = useAPI(
    incidentId == null ? null : "incidentTimelineListActivityLog",
    { incidentId: incidentId ?? "", timezone: localTimezone },
    { fallbackData: { activities: [] } },
  );

  return { activities, ...rest };
};

// This returns a flattened list of all activity on a timeline,
// with all gaps removed
export const useFlatActivityLogs = (incidentId: string | null) => {
  const { activities, ...rest } = useActivityLogs(incidentId);

  return {
    activities: activities.flatMap((activity) =>
      activity.items.filter((item) => !!item.activity_log),
    ),
    ...rest,
  };
};

export const useSuggestedFollowUps = (incidentId: string | null) => {
  const {
    data: { suggested_follow_ups: suggestedFollowUps },
    ...rest
  } = useAPI(
    incidentId == null ? null : "followUpsListSuggestedFollowUps",
    { incidentId: incidentId ?? "" },
    { fallbackData: { suggested_follow_ups: [] } },
  );

  return { suggestedFollowUps, ...rest };
};

export const useInternalId = (externalId: string) => {
  const { data, isLoading, error } = useAPI(
    externalId ? "incidentsShowInternalID" : null,
    {
      id: externalId as string,
    },
    {
      // Disable revalidations here: this is an immutable mapping
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  );

  return {
    internalId: data?.incident.id,
    isLoading,
    error,
  };
};
