import {
  Badge,
  BadgeTheme,
  ContentBox,
  IconEnum,
  OrgAwareLink,
} from "@incident-ui";
import { motion } from "framer-motion";
import _ from "lodash";
import React from "react";
import { createContext, useState } from "react";
import { AlertSourceBadge } from "src/components/alerts/common/AlertOverviewColumn";
import {
  EscalationPreview,
  EscalationStatusEnum,
} from "src/contexts/ClientContext";
import { useAPI } from "src/utils/swr";
import { tcx, TcxValue } from "src/utils/tailwind-classes";
import { usePrevious } from "use-hooks";

import { EscalationStatusIcon } from "../../on-call/escalations/EscalationStatusIcon";
import styles from "./EscalationCard.module.scss";
import { useShouldHighlightStateDiff } from "./useShouldHighlight";

export type EscalationCardContextType = {
  escalation: EscalationPreview;
  previousEscalationState: EscalationPreview;
  isGridFirstUpdate: boolean;
  showNewness: boolean;
};

export const EscalationCardContext =
  createContext<EscalationCardContextType | null>(null);

export const EscalationCard = React.memo(
  ({
    escalation,
    isGridFirstUpdate,
  }: {
    escalation: EscalationPreview;
    isGridFirstUpdate: boolean;
  }) => {
    const [showNewness, setShowNewness] = useState(!isGridFirstUpdate);
    const previousEscalationState = usePrevious(escalation) ?? escalation; // usePrevious will return undefined on first run
    return (
      <motion.div
        key={escalation.id}
        initial={{ scale: isGridFirstUpdate ? undefined : 0.95 }}
        animate={{ scale: 1 }}
        transition={{ duration: 0.2 }}
      >
        <EscalationCardContext.Provider
          value={{
            escalation,
            previousEscalationState,
            isGridFirstUpdate,
            showNewness,
          }}
        >
          <OrgAwareLink
            to={`/on-call/escalations/${escalation.id}`}
            className={"w-full h-full"}
            onMouseOver={() => setShowNewness(false)}
          >
            <EscalationCardContent
              key={escalation.id}
              escalation={escalation}
              showNewness={showNewness}
            />
          </OrgAwareLink>
        </EscalationCardContext.Provider>
      </motion.div>
    );
  },
  (prevProps, nextProps) => _.isEqual(prevProps, nextProps),
);
EscalationCard.displayName = "EscalationCard";

// IncidentCardContent
// upon mount:
// -> if isGridFirstUpdate = false; then highlight its borders for a few seconds
// -> if isGridFirstUpdate = true; then highlight its borders until user hovers over card
const EscalationCardContent = ({
  escalation,
  showNewness,
}: {
  escalation: EscalationPreview;
  showNewness: boolean;
}) => {
  const highlight = useShouldHighlightStateDiff(
    (lhs, rhs) => !_.isEqual(lhs, rhs),
  );

  return (
    <ContentBox
      className={tcx(
        "h-[140px] group flex flex-col justify-between py-6",
        styles.cardContainer,
        "hover:shadow-md hover:border-stroke-hover transition",
        {
          ["border-blue-400"]: showNewness,
          [styles.highlight]: highlight,
        },
      )}
    >
      {escalation.currently_paging && (
        <div
          className={tcx(
            "w-full px-5 flex gap-2 justify-between truncate pb-2 shrink-0",
          )}
        >
          <PagingIndicator escalation={escalation} />
        </div>
      )}
      <div className={tcx("px-6 w-full overflow-hidden flex flex-col flex-1")}>
        <div className={tcx("flex flex-1 overflow-hidden pb-2")}>
          <EscalationTitle key={escalation.title} escalation={escalation} />
        </div>
        {/* Badges */}
        <div className={tcx("flex gap-2 items-center w-full pt-2 shrink-0")}>
          <EscalationAlertSource escalation={escalation} />
          <EscalationPathName
            key={escalation.escalation_path_name}
            escalation={escalation}
          />
        </div>
      </div>
    </ContentBox>
  );
};

export const IconWithLabel = ({
  label,
  icon,
  className,
  overflowHidden = true,
}: {
  label: string;
  icon: IconEnum;
  className?: TcxValue;
  overflowHidden?: boolean;
}) => (
  <Badge
    theme={BadgeTheme.Secondary}
    icon={icon}
    className={tcx(
      "cursor-pointer",
      {
        "overflow-hidden": overflowHidden,
      },
      className,
    )}
  >
    <span className="truncate">{label}</span>
  </Badge>
);

const EscalationAlertSource = ({
  escalation,
}: {
  escalation: EscalationPreview;
}) => {
  const highlight = useShouldHighlightStateDiff(
    (lhs, rhs) => lhs.escalation_path_name !== rhs.escalation_path_name,
  );

  const { data } = useAPI(escalation?.alert_id ? "alertsShowAlert" : null, {
    id: escalation?.alert_id ?? "",
  });

  const highlightBackgroundCX = { [styles.highlightBackground]: highlight };
  return data ? (
    <Badge
      theme={BadgeTheme.Secondary}
      className={tcx("cursor-pointer", highlightBackgroundCX)}
    >
      <AlertSourceBadge
        showIcon
        alert={data?.alert}
        className={tcx("!text-sm !text-content-primary", highlightBackgroundCX)}
      />
    </Badge>
  ) : null;
};

const EscalationPathName = ({
  escalation,
}: {
  escalation: EscalationPreview;
}) => {
  const highlight = useShouldHighlightStateDiff(
    (lhs, rhs) => lhs.escalation_path_name !== rhs.escalation_path_name,
  );
  const highlightBackgroundCX = { [styles.highlightBackground]: highlight };
  return escalation.escalation_path_name ? (
    <IconWithLabel
      label={escalation.escalation_path_name}
      icon={IconEnum.EscalationPath}
      className={highlightBackgroundCX}
    />
  ) : null;
};

const PagingIndicator = ({ escalation }: { escalation: EscalationPreview }) => {
  const highlight = useShouldHighlightStateDiff(
    (lhs, rhs) => lhs.title !== rhs.title,
  );
  const highlightBackgroundCX = { [styles.highlightBackground]: highlight };

  if (!escalation.currently_paging) {
    return null;
  }

  return (
    <>
      <span
        className={tcx(
          "text-xs-med text-content-tertiary flex flex-row text-wrap",
          highlightBackgroundCX,
        )}
      >
        <div className="pr-2">
          <EscalationStatusIcon
            status={escalation.status as unknown as EscalationStatusEnum}
          />
        </div>
        Paging {formatPagingLabel(escalation.currently_paging)}
      </span>
    </>
  );
};

const EscalationTitle = ({ escalation }: { escalation: EscalationPreview }) => {
  const highlight = useShouldHighlightStateDiff(
    (lhs, rhs) => lhs.title !== rhs.title,
  );
  const highlightBackgroundCX = { [styles.highlightBackground]: highlight };
  return (
    <>
      <span
        className={tcx(
          "text-base font-semibold line-clamp-2",
          highlightBackgroundCX,
        )}
      >
        {escalation.title}
      </span>
    </>
  );
};

const formatPagingLabel = (targets: string[]) => {
  if (targets.length === 0) {
    return "";
  } else if (targets.length === 1) {
    return targets[0];
  } else if (targets.length === 2) {
    return `${targets[0]} and ${targets[1]}`;
  } else if (targets.length === 3) {
    return `${targets[0]}, ${targets[1]} and ${targets[2]}`;
  } else {
    return (
      `${targets[0]}, ${targets[1]}, ${targets[2]} and ${
        targets.length - 3
      } other` + (targets.length - 3 > 1 ? "s" : "")
    );
  }
};
