import {
  PostIncidentTask,
  PostIncidentTaskConfig,
  PostIncidentTaskConfigSlim,
  PostIncidentTaskConfigSlimTaskTypeEnum,
  PostIncidentTaskSlim,
} from "@incident-io/api";

// What a horrible way of convincing typescript that yes, indeed, the enums are the same.
export const taskToSlim = (task: PostIncidentTask): PostIncidentTaskSlim => ({
  ...task,
  config: taskConfigToSlim(task.config),
});

export const taskConfigToSlim = (
  config: PostIncidentTaskConfig,
): PostIncidentTaskConfigSlim => ({
  ...config,
  task_type:
    config.task_type as unknown as PostIncidentTaskConfigSlimTaskTypeEnum,
  uses_auto_assign:
    !!config.assignee?.value?.literal || !!config.assignee?.value?.reference,
});
