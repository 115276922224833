import { PageWidth, PageWrapper } from "@incident-shared/layout/PageWrapper";
import { IconEnum } from "@incident-ui";

import { AlertsListEmptyState } from "./AlertsListEmptyState";

export const AlertsGetStartedPage = () => {
  return (
    <PageWrapper
      icon={IconEnum.Alert}
      title="Alerts"
      width={PageWidth.Full}
      overflowY={false}
    >
      <div className="flex flex-col items-center grow">
        <AlertsListEmptyState />
      </div>
    </PageWrapper>
  );
};
