import { IncidentCallSettingsUpdateAutoCallRequestBody } from "@incident-io/api";
import { Product } from "@incident-shared/billing";
import { ProductRequiredMessage } from "@incident-shared/billing/ProductRequired/ProductRequiredMessage";
import {
  ConfigureDrawerProps,
  IntegrationConfigFor,
  IntegrationsListObject,
} from "@incident-shared/integrations";
import {
  Callout,
  CalloutTheme,
  ContentBox,
  LoadingBar,
  Toggle,
} from "@incident-ui";
import React from "react";
import {
  AutoCallConfigProviderEnum,
  IncidentCallSettings,
  IntegrationSettingsProviderEnum as IntegrationProvider,
  ScopeNameEnum,
  useIsAuthenticated,
} from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import {
  AutoSavingIndicator,
  useOptimisticAutoSave,
} from "src/hooks/useOptimisticAutoSave";
import { useProductAccess } from "src/hooks/useProductAccess";
import { useAPI, useAPIMutation } from "src/utils/swr";

import { GenericConfigureDrawerContents } from "../list/IntegrationDrawer";

export const ZoomOrGoogleMeetConfigureDrawer = (
  props: ConfigureDrawerProps,
): React.ReactElement | null => {
  const isAuthenticated = useIsAuthenticated();
  const { data, isLoading } = useAPI(
    isAuthenticated ? "incidentCallSettingsShow" : null,
    undefined,
  );

  return (
    <GenericConfigureDrawerContents {...props}>
      {isLoading || !data ? (
        <LoadingBar />
      ) : (
        <AutoCallSettings
          integration={props.integration}
          settings={data.settings}
        />
      )}
    </GenericConfigureDrawerContents>
  );
};

const AutoCallSettings = ({
  integration,
  settings,
}: {
  integration: IntegrationsListObject;
  settings: IncidentCallSettings;
}): React.ReactElement | null => {
  const { hasScope } = useIdentity();
  const canEditSettings = hasScope(ScopeNameEnum.OrganisationSettingsUpdate);

  const autoCallSettings = settings?.auto_call;

  const { hasResponse } = useProductAccess();

  const { trigger: onSubmit } = useAPIMutation(
    "incidentCallSettingsShow",
    undefined,
    async (apiClient, data: IncidentCallSettingsUpdateAutoCallRequestBody) => {
      if (data.auto_call?.enabled) {
        data.auto_call.config = {
          provider: integration.provider as AutoCallConfigProviderEnum,
        };
      }
      await apiClient.incidentCallSettingsUpdateAutoCall({
        updateAutoCallRequestBody: data,
      });
    },
  );

  const alreadyEnabledOnWrongProvider =
    autoCallSettings.enabled &&
    integration.provider.toString() !== autoCallSettings.config?.provider;

  const {
    setState,
    hasSaved,
    saving,
    state: enabled,
  } = useOptimisticAutoSave<boolean>({
    initialState: !alreadyEnabledOnWrongProvider && autoCallSettings.enabled,
    saveState: async (enabled: boolean) => {
      await onSubmit({ auto_call: { enabled } });
    },
  });

  const otherIntegrationConfig = autoCallSettings.config?.provider
    ? IntegrationConfigFor(
        autoCallSettings.config?.provider as unknown as IntegrationProvider,
      )
    : undefined;

  return (
    <>
      {alreadyEnabledOnWrongProvider && otherIntegrationConfig ? (
        <Callout theme={CalloutTheme.Warning}>
          This feature is already configured for {otherIntegrationConfig?.label}
          . If you&apos;d like to enable it here, you&apos;ll need to first
          disable it.
        </Callout>
      ) : null}
      <ContentBox className="p-4">
        <Toggle
          id="auto_call.enabled"
          on={enabled}
          disabled={
            !canEditSettings ||
            saving ||
            !hasResponse ||
            alreadyEnabledOnWrongProvider
          }
          isDisabledTooltipContent={
            !hasResponse ? (
              <ProductRequiredMessage requiredProduct={Product.Response} />
            ) : null
          }
          label={
            <div className="flex items-center gap-2">
              Automatically create incident call
              <AutoSavingIndicator saving={saving} hasSaved={hasSaved} />
            </div>
          }
          description={`When enabled, we'll use ${
            IntegrationConfigFor(integration.provider).label
          } to start a video conferencing call whenever we create an incident, and assign it as the incident call link.`}
          onToggle={() => {
            setState(!enabled);
          }}
        />
      </ContentBox>
    </>
  );
};
