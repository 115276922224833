import { PageWidth, PageWrapper } from "@incident-shared/layout/PageWrapper";
import { Button, Callout, CalloutTheme, IconEnum } from "@incident-ui";

import { AIInvestigationsList } from "./AIInvestigationsList";
import { AIRequestsList } from "./AIRequestsList";
import { AIThreadsList } from "./AIThreadsList";

export const AIEvalsIndexPage = () => {
  return (
    <PageWrapper icon={IconEnum.Robot} width={PageWidth.Wide} title="AI evals">
      <Callout
        theme={CalloutTheme.Info}
        title="Trends in response quality and performance now live in Omni."
        subtitle={
          <>
            Use drill-downs to explore the data and click on the ID of any
            interaction to view its details back in this dashboard.
          </>
        }
        cta={
          <Button
            href="https://incident.omniapp.co/dashboards/09028a79"
            analyticsTrackingId={null}
          >
            View trends and performance
          </Button>
        }
      ></Callout>
      <AIInvestigationsList />
      <AIThreadsList />
      <AIRequestsList />
    </PageWrapper>
  );
};
