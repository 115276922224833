import { PopoverSingleSelectV2 } from "@incident-shared/forms/v2/inputs/PopoverSelectV2";
import { ErrorMessage } from "@incident-ui";
import {
  Button,
  ButtonSize,
  ButtonTheme,
  Icon,
  IconEnum,
  IconSize,
} from "@incident-ui";
import { PopoverItem } from "@incident-ui/Popover/PopoverItem";
import { useFormContext } from "react-hook-form";
import { useNodeFormErrors } from "src/components/escalation-paths/nodes/useNodeFormErrors";
import {
  EscalationPathNodeTypeEnum as NodeTypes,
  EscalationPathTargetTypeEnum,
  EscalationPathTargetUrgencyEnum,
} from "src/contexts/ClientContext";
import { tcx } from "src/utils/tailwind-classes";

import {
  EscalationPathFormData,
  EscalationPathTimeToAckOption,
  NodeLevel as NodeLevelData,
  PathNode,
} from "../common/types";
import { deleteNode } from "../node-editor/helpers/deleteNode";
import { shouldDisplayWorkingHoursTimeToAck } from "../node-editor/helpers/displayWorkingHoursTimeToAck";
import { getNextNodeId } from "../node-editor/helpers/getNextNodeId";
import { getLevelCount } from "../node-editor/helpers/getNodeCount";
import { insertIfElseAboveNode } from "../node-editor/helpers/insertNode";
import {
  makeNotifyChannelNode,
  makeRepeatNode,
} from "../node-editor/helpers/makeNodes";
import { replaceNode } from "../node-editor/helpers/replaceNode";
import { EscalationPathTargetSelect } from "./EscalationPathTargetSelect";
import { NodeCard, NodeCardTitleDropdown } from "./NodeCard";
import { RoundRobinForm } from "./RoundRobinForm";
import { TimeToAckForm, timeToAckOptions } from "./TimeToAckForm";

// NodeLevel is used for all Level nodes in an escalation path, and is a wrapper around the box element.
export const NodeLevel = ({ id }: { id: string }) => {
  const formMethods = useFormContext<EscalationPathFormData>();

  const nodes = formMethods.watch("nodes");
  const firstNodeId = formMethods.watch("firstNodeId");

  const urgency = formMethods.watch(`nodes.${id}.data.level.urgency`);
  const targets = formMethods.watch(`nodes.${id}.data.level.targets`);

  const relatedErrors = useNodeFormErrors({
    formMethods,
    id,
    nodeType: NodeTypes.Level,
  });

  const levelCount = getLevelCount(nodes, firstNodeId, id);

  const icon = (
    <div
      className={tcx(
        "rounded-2 w-10 h-10 flex items-center justify-center",
        urgency === EscalationPathTargetUrgencyEnum.High
          ? "bg-alarmalade-50"
          : "bg-amber-50",
      )}
    >
      <Icon
        id={IconEnum.Siren}
        size={IconSize.Medium}
        className={
          urgency === EscalationPathTargetUrgencyEnum.High
            ? "fill-brand text-brand"
            : "fill-amber-500 text-amber-50"
        }
      />
    </div>
  );

  const shouldAllowWorkingHoursTimeToAck = shouldDisplayWorkingHoursTimeToAck(
    nodes,
    nodes[id],
    firstNodeId,
  );

  const urgencyOptionToDescription = {
    [EscalationPathTargetUrgencyEnum.Low]: "Send low urgency notifications",
    [EscalationPathTargetUrgencyEnum.High]: "Send high urgency notifications",
  };

  const urgencyOptionToLabel = {
    [EscalationPathTargetUrgencyEnum.Low]: "Low",
    [EscalationPathTargetUrgencyEnum.High]: "High",
  };

  const urgencyOptions = [
    EscalationPathTargetUrgencyEnum.Low,
    EscalationPathTargetUrgencyEnum.High,
  ].map((urgency) => ({
    value: urgency,
    label: urgencyOptionToLabel[urgency],
    render: ({ onClick }) => {
      return (
        <PopoverItem onClick={onClick}>
          <UrgencyIcon urgency={urgency} />
          <span>{urgencyOptionToDescription[urgency]}</span>
        </PopoverItem>
      );
    },
  }));

  const previousNotifiableNodeId = Object.values(nodes).find(
    (node) =>
      (node.data.nodeType === NodeTypes.Level &&
        node.data.level?.nextNodeId === id) ||
      (node.data.nodeType === NodeTypes.NotifyChannel &&
        node.data.notifyChannel?.nextNodeId === id),
  )?.id;

  // Mini-dropdown menu for the title, with "Level" option disabled and "Condition" option enabled.
  const title = (
    <NodeCardTitleDropdown
      title={`Level ${levelCount}`}
      onSelectCondition={() => {
        insertIfElseAboveNode({
          node: formMethods.getValues().nodes[id],
          nodes: formMethods.getValues().nodes,
          firstNodeId: formMethods.getValues().firstNodeId,
          updateNodes: (nodes: Record<string, PathNode>) => {
            formMethods.setValue<"nodes">("nodes", nodes);
          },
          updateFirstNodeId: (firstNodeId: string) => {
            formMethods.setValue<"firstNodeId">("firstNodeId", firstNodeId);
          },
        });
      }}
      onSelectNotifyChannel={() => {
        replaceNode({
          oldNode: formMethods.getValues().nodes[id],
          nodes: formMethods.getValues().nodes,
          firstNodeId: formMethods.getValues().firstNodeId,
          makeNewNode: (oldNodeId) =>
            makeNotifyChannelNode({ nextNodeId: oldNodeId }),
          updateNodes: (nodes: Record<string, PathNode>) => {
            formMethods.setValue<"nodes">("nodes", nodes);
          },
          updateFirstNodeId: (firstNodeId: string) => {
            formMethods.setValue<"firstNodeId">("firstNodeId", firstNodeId);
          },
        });
      }}
      onSelectRepeat={
        // A level can only be converted to a repeat if it's the last node in the path,
        // and it's also preceded by a level or notify channel node.
        nodes[id]?.data?.level?.nextNodeId === undefined &&
        previousNotifiableNodeId !== undefined
          ? () => {
              replaceNode({
                oldNode: formMethods.getValues().nodes[id],
                nodes: formMethods.getValues().nodes,
                firstNodeId: formMethods.getValues().firstNodeId,
                makeNewNode: () => makeRepeatNode({ toNodeId: firstNodeId }),
                updateNodes: (nodes: Record<string, PathNode>) => {
                  formMethods.setValue<"nodes">("nodes", nodes);
                },
                updateFirstNodeId: (firstNodeId: string) => {
                  formMethods.setValue<"firstNodeId">(
                    "firstNodeId",
                    firstNodeId,
                  );
                },
              });
            }
          : undefined
      }
    />
  );

  const onDeleteNode = () => {
    deleteNode({
      nodeId: id,
      nodes: formMethods.getValues().nodes,
      firstNodeId: formMethods.getValues().firstNodeId,
      updateNodes: (nodes: Record<string, PathNode>) => {
        formMethods.setValue<"nodes">("nodes", nodes);
      },
      updateFirstNodeId: (firstNodeId: string) => {
        formMethods.setValue<"firstNodeId">("firstNodeId", firstNodeId);
      },
    });
  };

  // We should only show the round robin config if you either have more than one
  // target or if you have a single target and it's set to escalate all users on
  // a schedule.
  const showRoundRobinConfig =
    targets.length > 1 ||
    (targets.length === 1 &&
      targets[0].type === EscalationPathTargetTypeEnum.Schedule);

  const level = formMethods.watch(`nodes.${id}.data.level`);

  const timeToAckMinutes = getTimeToAckMinutes(level);

  return (
    <NodeCard
      nodeId={id}
      title={title}
      subtitle="Notify"
      icon={icon}
      onDeleteNode={
        showDeleteButton(id, firstNodeId, nodes) ? onDeleteNode : undefined
      }
    >
      <div className="flex flex-col gap-4 mt-4">
        <EscalationPathTargetSelect nodeId={id} />

        <div className="flex flex-wrap gap-2 items-center">
          <PopoverSingleSelectV2
            options={urgencyOptions}
            sideOffset={1}
            name={`nodes.${id}.data.level.urgency`}
            formMethods={formMethods}
            tooltipContent="Notification urgency"
            tooltipSide="bottom"
            popoverClassName="min-w-[270px] max-w-[270px]"
            renderTriggerNode={({ onClick, selectedOption }) => {
              return (
                <Button
                  onClick={onClick}
                  theme={ButtonTheme.Tertiary}
                  analyticsTrackingId={null}
                  title={selectedOption?.label || "Select urgency"}
                  size={ButtonSize.Small}
                >
                  {urgency ? <UrgencyIcon urgency={urgency} /> : null}
                  {selectedOption?.label || "Select urgency"}
                </Button>
              );
            }}
          />
          <TimeToAckForm
            id={id}
            formMethods={formMethods}
            nodeType={"level"}
            shouldAllowWorkingHoursTimeToAck={shouldAllowWorkingHoursTimeToAck}
          />
          {showRoundRobinConfig ? (
            <RoundRobinForm
              key={timeToAckMinutes}
              timeToAckMinutes={timeToAckMinutes}
              id={id}
            />
          ) : null}
        </div>

        {relatedErrors.length > 0 ? (
          <ErrorMessage message={relatedErrors[0]} className={"text-xs"} />
        ) : null}
      </div>
    </NodeCard>
  );
};

function getTimeToAckMinutes(level: NodeLevelData | undefined) {
  let timeToAckMinutes: number | undefined;
  if (
    level &&
    level.time_to_ack_option !==
      EscalationPathTimeToAckOption.WorkingHoursInactive &&
    level.time_to_ack_option !==
      EscalationPathTimeToAckOption.WorkingHoursActive
  ) {
    if (
      level.time_to_ack_option === EscalationPathTimeToAckOption.MinutesCustom
    ) {
      if (level.time_to_ack_custom_minutes) {
        timeToAckMinutes = level.time_to_ack_custom_minutes;
      }
    } else if (level.time_to_ack_option) {
      timeToAckMinutes = timeToAckOptions[level.time_to_ack_option].numMinutes;
    }
  }

  return timeToAckMinutes;
}

const UrgencyIcon = ({
  urgency,
}: {
  urgency: EscalationPathTargetUrgencyEnum | EscalationPathTargetUrgencyEnum;
}) => {
  return (
    <div
      className={tcx(
        "rounded-full h-4 w-4 border-2 shrink-0",
        urgency === EscalationPathTargetUrgencyEnum.Low
          ? "bg-yellow-200 border-yellow-500"
          : "bg-alarmalade-200 border-alarmalade-600",
      )}
    />
  );
};

// showDeleteButton determines whether the delete button should be shown on a given non-condition
// node. This is based on the following conditions:
// 1. The level node is first in the tree and the next node is a repeat node.
// 2. The level node is first in a branch of an ifElse node and the next node is a repeat node.
export const showDeleteButton = (
  nodeId: string,
  firstNodeId: string,
  nodes: Record<string, PathNode>,
): boolean => {
  // Grab the node that we're operating on.
  const node = nodes[nodeId];

  // Grab the child node of the node we're on.
  const nextNodeId = getNextNodeId({ node });
  let childNode: PathNode | undefined;
  if (nextNodeId) {
    childNode = nodes[nextNodeId];
  }

  // If the current node is the first in the tree, and the next node is a repeat node, or there
  // is no next node, then don't allow the node to be deleted.
  if (
    node.id === firstNodeId &&
    (!childNode || childNode.data.nodeType === NodeTypes.Repeat)
  ) {
    return false;
  }

  // Find the parent node, so that we can check if it is an ifElse node.
  const parentNode = Object.values(nodes).find(
    (node) =>
      (node.data.nodeType === NodeTypes.Level &&
        node.data.level?.nextNodeId === nodeId) ||
      (node.data.nodeType === NodeTypes.NotifyChannel &&
        node.data.notifyChannel?.nextNodeId === nodeId) ||
      (node.data.nodeType === NodeTypes.IfElse &&
        (node.data.ifElse?.thenNodeId === nodeId ||
          node.data.ifElse?.elseNodeId === nodeId)),
  );

  // Extra validation to ensure that the parent node is found if the node is not the first node.
  if (node.id !== firstNodeId && !parentNode) {
    throw new Error("unreachable: non-first node must have a parent node");
  }

  // If the tree contains an ifElse node, and the parent node to the level node is an ifElse node,
  // and the next node is a repeat node or nothing, then don't allow the node to be deleted.
  if (
    parentNode &&
    parentNode.data.nodeType === NodeTypes.IfElse &&
    (!childNode || childNode.data.nodeType === NodeTypes.Repeat)
  ) {
    return false;
  }

  // Allow to be deleted.
  return true;
};
