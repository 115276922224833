import { Product } from "@incident-shared/billing";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import {
  Button,
  ButtonSize,
  ButtonTheme,
  GenericErrorMessage,
  IconEnum,
  IconSize,
} from "@incident-ui";
import { FullPageLoader } from "@incident-ui/Loader/Loader";
import { captureException } from "@sentry/react";
import { AnimatePresence } from "framer-motion";
import { sortBy } from "lodash";
import React, { useState } from "react";
import { useOutlet } from "react-router";
import graphic from "src/components/settings/banners/banner-custom-fields.svg";
import { Expression, ScopeNameEnum } from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useProductAccess } from "src/hooks/useProductAccess";
import { useAPI } from "src/utils/swr";

import { Plurality, ProductUpsellNotice } from "../ProductUpsellNotice";
import { SettingsHeading } from "../SettingsHeading";
import { SettingsSubPageWrapper } from "../SettingsRoute";
import { CustomFieldCreateModal } from "./create-edit/CustomFieldCreateModal";
import { CustomFieldList } from "./CustomFieldList";
import { CustomFieldOrderingList } from "./CustomFieldOrderingList";

export const CustomFieldPage = ({
  setExpression,
}: {
  setExpression: (expression: Expression | undefined) => void;
}): React.ReactElement => {
  const { hasProduct } = useProductAccess();
  const drawer = useOutlet();
  const { identity, hasScope } = useIdentity();
  const canEditSettings = hasScope(ScopeNameEnum.OrganisationSettingsUpdate);
  const {
    data: { catalog_types: catalogTypes },
    isLoading: catalogTypesLoading,
    error: catalogTypesError,
  } = useAPI("catalogListTypes", {}, { fallbackData: { catalog_types: [] } });

  const [showCreateModal, setShowCreateModal] = useState(false);
  const [isOrdering, setIsOrdering] = useState(false);

  const {
    data,
    error: customFieldError,
    isLoading,
  } = useAPI("customFieldsList", undefined);
  if (isLoading || !data || catalogTypesLoading) {
    return <FullPageLoader />;
  }

  if (customFieldError) {
    console.error(customFieldError);
    captureException(customFieldError);
    return <GenericErrorMessage error={customFieldError} />;
  }

  if (catalogTypesError) {
    console.error(catalogTypesError);
    captureException(catalogTypesError);
    return <GenericErrorMessage error={catalogTypesError} />;
  }

  const customFields = sortBy(data.custom_fields, (field) => field.rank);

  let orgCanCreateCustomFields = true;
  if (
    identity?.feature_gates.custom_field_count &&
    identity?.feature_gates.custom_field_count <= customFields.length
  ) {
    orgCanCreateCustomFields = false;
  }

  const toggleOrdering = () => {
    setIsOrdering(!isOrdering);
  };

  return (
    <SettingsSubPageWrapper
      accessory={
        <GatedButton
          onClick={() => setShowCreateModal(true)}
          requiredScope={ScopeNameEnum.OrganisationSettingsUpdate}
          requiredProduct={Product.Response}
          analyticsTrackingId="add-custom-field"
          upgradeRequired={!orgCanCreateCustomFields}
          upgradeRequiredProps={{
            gate: {
              type: "numeric",
              value: identity?.feature_gates.custom_field_count,
              featureNameSingular: "Custom Field",
            },
            featureName: "Custom Fields",
          }}
          icon={IconEnum.Add}
          theme={ButtonTheme.Primary}
        >
          Add custom field
        </GatedButton>
      }
    >
      <AnimatePresence>{drawer}</AnimatePresence>
      <SettingsHeading
        title="Tag incidents"
        subtitle="Create custom fields like Impacted Product or Affected Team to keep things organized."
        graphic={<img src={graphic} className="h-40" />}
        learnMoreURL="https://incident.io/guide/foundations/structured-data"
      />
      {hasProduct(Product.Response) ? (
        <div className="flex flex-col gap-4">
          <div className="flex flex-row justify-end gap-4">
            {isOrdering ? (
              <Button
                analyticsTrackingId="custom-fields-reorder"
                onClick={toggleOrdering}
                className={
                  "ml-auto font-sm text-content-tertiary hover:text-content-primary hover:!no-underline transition !py-1"
                }
                theme={ButtonTheme.Secondary}
                size={ButtonSize.Small}
                disabled={!canEditSettings}
              >
                Done
              </Button>
            ) : (
              <Button
                analyticsTrackingId="custom-fields-reorder"
                onClick={toggleOrdering}
                className={
                  "ml-auto font-sm text-content-tertiary hover:text-content-primary hover:!no-underline transition mt-2"
                }
                theme={ButtonTheme.Naked}
                icon={IconEnum.Rank}
                iconProps={{ size: IconSize.Medium, className: "!mr-0.5" }}
                disabled={!canEditSettings}
              >
                Reorder fields
              </Button>
            )}
          </div>

          {isOrdering ? (
            <CustomFieldOrderingList
              customFields={customFields}
              catalogTypes={catalogTypes}
            />
          ) : (
            <CustomFieldList
              customFields={customFields}
              canEditSettings={canEditSettings}
              catalogTypes={catalogTypes}
            />
          )}
        </div>
      ) : (
        <ProductUpsellNotice
          featureName="Custom Fields"
          plurality={Plurality.Plural}
          requiredProduct={Product.Response}
        />
      )}
      {showCreateModal && (
        <CustomFieldCreateModal
          setExpression={setExpression}
          onClose={() => setShowCreateModal(false)}
        />
      )}
    </SettingsSubPageWrapper>
  );
};
