/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EngineParamBindingPayload,
    EngineParamBindingPayloadFromJSON,
    EngineParamBindingPayloadFromJSONTyped,
    EngineParamBindingPayloadToJSON,
} from './EngineParamBindingPayload';

/**
 * 
 * @export
 * @interface AlertRouteIncidentTemplatePayload
 */
export interface AlertRouteIncidentTemplatePayload {
    /**
     * lookup of the priority options for each custom field in the template
     * @type {{ [key: string]: string; }}
     * @memberof AlertRouteIncidentTemplatePayload
     */
    custom_field_priorities: { [key: string]: string; };
    /**
     * Custom field keys mapped to values
     * @type {{ [key: string]: EngineParamBindingPayload; }}
     * @memberof AlertRouteIncidentTemplatePayload
     */
    custom_fields?: { [key: string]: EngineParamBindingPayload; };
    /**
     * 
     * @type {EngineParamBindingPayload}
     * @memberof AlertRouteIncidentTemplatePayload
     */
    incident_mode?: EngineParamBindingPayload;
    /**
     * 
     * @type {EngineParamBindingPayload}
     * @memberof AlertRouteIncidentTemplatePayload
     */
    incident_type?: EngineParamBindingPayload;
    /**
     * 
     * @type {EngineParamBindingPayload}
     * @memberof AlertRouteIncidentTemplatePayload
     */
    name?: EngineParamBindingPayload;
    /**
     * option defining whether to cause subsequent alerts to increase the severity
     * @type {string}
     * @memberof AlertRouteIncidentTemplatePayload
     */
    priority_severity: AlertRouteIncidentTemplatePayloadPrioritySeverityEnum;
    /**
     * 
     * @type {EngineParamBindingPayload}
     * @memberof AlertRouteIncidentTemplatePayload
     */
    severity?: EngineParamBindingPayload;
    /**
     * 
     * @type {EngineParamBindingPayload}
     * @memberof AlertRouteIncidentTemplatePayload
     */
    summary?: EngineParamBindingPayload;
    /**
     * 
     * @type {EngineParamBindingPayload}
     * @memberof AlertRouteIncidentTemplatePayload
     */
    workspace?: EngineParamBindingPayload;
}

/**
* @export
* @enum {string}
*/
export enum AlertRouteIncidentTemplatePayloadPrioritySeverityEnum {
    FirstWins = 'severity-first-wins',
    Max = 'severity-max'
}

export function AlertRouteIncidentTemplatePayloadFromJSON(json: any): AlertRouteIncidentTemplatePayload {
    return AlertRouteIncidentTemplatePayloadFromJSONTyped(json, false);
}

export function AlertRouteIncidentTemplatePayloadFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlertRouteIncidentTemplatePayload {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'custom_field_priorities': json['custom_field_priorities'],
        'custom_fields': !exists(json, 'custom_fields') ? undefined : (mapValues(json['custom_fields'], EngineParamBindingPayloadFromJSON)),
        'incident_mode': !exists(json, 'incident_mode') ? undefined : EngineParamBindingPayloadFromJSON(json['incident_mode']),
        'incident_type': !exists(json, 'incident_type') ? undefined : EngineParamBindingPayloadFromJSON(json['incident_type']),
        'name': !exists(json, 'name') ? undefined : EngineParamBindingPayloadFromJSON(json['name']),
        'priority_severity': json['priority_severity'],
        'severity': !exists(json, 'severity') ? undefined : EngineParamBindingPayloadFromJSON(json['severity']),
        'summary': !exists(json, 'summary') ? undefined : EngineParamBindingPayloadFromJSON(json['summary']),
        'workspace': !exists(json, 'workspace') ? undefined : EngineParamBindingPayloadFromJSON(json['workspace']),
    };
}

export function AlertRouteIncidentTemplatePayloadToJSON(value?: AlertRouteIncidentTemplatePayload | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'custom_field_priorities': value.custom_field_priorities,
        'custom_fields': value.custom_fields === undefined ? undefined : (mapValues(value.custom_fields, EngineParamBindingPayloadToJSON)),
        'incident_mode': EngineParamBindingPayloadToJSON(value.incident_mode),
        'incident_type': EngineParamBindingPayloadToJSON(value.incident_type),
        'name': EngineParamBindingPayloadToJSON(value.name),
        'priority_severity': value.priority_severity,
        'severity': EngineParamBindingPayloadToJSON(value.severity),
        'summary': EngineParamBindingPayloadToJSON(value.summary),
        'workspace': EngineParamBindingPayloadToJSON(value.workspace),
    };
}

