import { PanelVariable } from "@incident-io/api";
import {
  useFiltersContext,
  useGetContextForFilter,
} from "@incident-shared/filters";
import {
  Badge,
  BadgeSize,
  BadgeTheme,
  Button,
  ButtonSize,
  ButtonTheme,
  GenericErrorMessage,
  Heading,
  Icon,
  IconEnum,
  IconSize,
  Loader,
  Markdown,
} from "@incident-ui";
import { format, parseISO } from "date-fns";
import { uniq } from "lodash";
import React from "react";
import { useFieldArray, useFormContext } from "react-hook-form";

import {
  EditPanelTextModal,
  EditPanelTitleAndDescriptionForm,
} from "../create-edit/EditPanelTextModal";
import { calculatePreviousPeriod } from "./calculatePreviousPeriod";
import { PanelVariableInputs } from "./PanelVariables";
import {
  DashboardViewMode,
  EditDashboardFormData,
  useInsightsContext,
} from "./useInsightsContext";

export const PanelWrapper = ({
  title,
  description,
  variables,
  panelIdx,
  trends,
  children,
  noTitleAndDescription,
}: {
  title: string;
  description: string;
  variables: PanelVariable[];
  panelIdx: number;
  trends: React.ReactNode;
  children: React.ReactNode;
  noTitleAndDescription?: boolean;
}) => {
  const [showEditPanelTextModal, setShowEditPanelTextModal] =
    React.useState(false);

  const formMethods = useFormContext<EditDashboardFormData>();
  const { viewMode } = useInsightsContext();
  const { filters, deleteFilter } = useFiltersContext();

  const {
    isLoading: contextLoading,
    error: contextError,
    getContextForFilter,
  } = useGetContextForFilter();

  const panel = formMethods.watch(`panels.${panelIdx}`);

  const onSubmitEditPanelTextModal = (
    form: EditPanelTitleAndDescriptionForm,
  ) => {
    if (panel.explo_dashboard) {
      formMethods.setValue(
        `panels.${panelIdx}.explo_dashboard.title`,
        form.title,
      );
      formMethods.setValue(
        `panels.${panelIdx}.explo_dashboard.description`,
        form.description,
      );
    } else if (panel.explo_dashboard_with_drilldown) {
      formMethods.setValue(
        `panels.${panelIdx}.explo_dashboard_with_drilldown.title`,
        form.title,
      );
      formMethods.setValue(
        `panels.${panelIdx}.explo_dashboard_with_drilldown.description`,
        form.description,
      );
    }

    setShowEditPanelTextModal(false);
  };

  const {
    move,
    remove,
    fields: panels,
  } = useFieldArray({
    control: formMethods.control,
    name: "panels",
  });

  if (contextLoading || !getContextForFilter) {
    return <Loader />;
  }
  if (contextError) {
    return <GenericErrorMessage error={contextError} />;
  }

  const isFirst = panelIdx === 0;
  const isLast = panelIdx === panels.length - 1;
  const isOnly = panels.length === 1;
  const currentPanel = panels[panelIdx];

  const removeNonApplicableFilters = () => {
    // When we remove a panel, if it was the last panel using a particular filter context,
    // we need to remove those filters
    const filterContexts = uniq(
      panels.flatMap((panel) => {
        // We have to filter the current panel out of the list of panels because
        // it's still in there as we haven't re-rendered this component yet
        // with the new state, so the panels array still contains it
        if (panel.id !== currentPanel.id) {
          return panel.filter_contexts || [];
        }
        return [];
      }),
    );

    filters.forEach((filter) => {
      const context = getContextForFilter(filter.filter_id);
      if (!filterContexts.includes(context)) {
        deleteFilter(filter.filter_id);
      }
    });
  };

  return (
    <div className="flex flex-col gap-5">
      {/* Header section */}
      {viewMode === DashboardViewMode.EditDashboard && (
        <>
          <div className="flex justify-between">
            <div>
              <PanelVariableInputs
                variables={variables}
                panelIdx={panelIdx}
                editable={true}
              />
            </div>
            <div className="flex gap-2">
              <Button
                analyticsTrackingId={"insights-v3-move-panel-up"}
                theme={ButtonTheme.Tertiary}
                size={ButtonSize.Small}
                disabled={isOnly || isFirst}
                onClick={() => move(panelIdx, panelIdx - 1)}
              >
                <Icon id={IconEnum.ChevronUp} size={IconSize.Small} />
              </Button>
              <Button
                analyticsTrackingId={"insights-v3-move-panel-down"}
                theme={ButtonTheme.Tertiary}
                size={ButtonSize.Small}
                disabled={isOnly || isLast}
                onClick={() => move(panelIdx, panelIdx + 1)}
              >
                <Icon id={IconEnum.ChevronDown} size={IconSize.Small} />
              </Button>
              <Button
                analyticsTrackingId={"insights-v3-remove-panel"}
                theme={ButtonTheme.Tertiary}
                size={ButtonSize.Small}
                onClick={() => {
                  remove(panelIdx);
                  removeNonApplicableFilters();
                }}
              >
                <Icon id={IconEnum.Delete} size={IconSize.Small} />
              </Button>
            </div>
          </div>
          <div className="border-b border-dotted border-b-slate-100 mx-[-20px]" />
        </>
      )}
      {!noTitleAndDescription && (
        <div className="flex items-start gap-4">
          <div className="w-1/2">
            <div className="flex flex-col gap-2">
              <div className="flex  gap-2">
                <Heading
                  level={2}
                  size="small"
                  className="flex gap-2 text-base-bold"
                >
                  {title}
                  {viewMode === DashboardViewMode.EditDashboard && (
                    <Button
                      icon={IconEnum.Edit}
                      onClick={() => setShowEditPanelTextModal(true)}
                      title="Edit details"
                      analyticsTrackingId={null}
                      theme={ButtonTheme.Naked}
                    />
                  )}
                </Heading>
              </div>
              <p className="mb-4 text-slate-700 text-sm max-w-96">
                <Markdown>{description}</Markdown>
              </p>
              {(viewMode === DashboardViewMode.EditFiltersAndVariables ||
                viewMode === DashboardViewMode.View) && (
                <PanelVariableInputs
                  variables={variables}
                  panelIdx={panelIdx}
                  editable={
                    viewMode === DashboardViewMode.EditFiltersAndVariables
                  }
                />
              )}
            </div>
          </div>
          <div className="w-1/2">
            <div className="grid grid-cols-3 gap-4" dir="rtl">
              {trends}
            </div>
          </div>
        </div>
      )}
      {children}
      {showEditPanelTextModal && (
        <EditPanelTextModal
          initialData={{ title, description }}
          onClose={() => setShowEditPanelTextModal(false)}
          onSubmit={onSubmitEditPanelTextModal}
        />
      )}
    </div>
  );
};

export const ComparisonModeHeading = ({
  startDate,
  endDate,
}: {
  startDate: string;
  endDate: string;
}) => {
  const { prevEndDate, prevStartDate } = calculatePreviousPeriod(
    startDate,
    endDate,
  );

  return (
    <div className="flex flex-row gap-4 pt-4 w-full sticky top-0 bg-gradient-to-b from-surface-primary via-surface-primary via-75% to-90%">
      <div className="w-1/2">
        <Badge
          theme={BadgeTheme.Tertiary}
          className="w-full mb-4 justify-center"
          label={`${parseDateRange(prevStartDate, prevEndDate)}`}
          size={BadgeSize.Medium}
          icon={IconEnum.Compare}
        />
      </div>
      <div className="w-1/2">
        <Badge
          theme={BadgeTheme.Info}
          className="w-full mb-4 justify-center"
          label={`${parseDateRange(startDate, endDate)}`}
          size={BadgeSize.Medium}
          icon={IconEnum.Calendar}
        />
      </div>
    </div>
  );
};

// This parser expects from and to to be in ISO format with a timezone
export const parseDateRange = (from: string, to: string) => {
  // Parse the dates using parseISO
  const fromDate = parseISO(from);
  const toDate = parseISO(to);

  // Format the dates using format
  const dateFmtOptions = "d MMM yy";

  const fromDateString = format(fromDate, dateFmtOptions);
  const toDateString = format(toDate, dateFmtOptions);

  return `${fromDateString} - ${toDateString}`;
};
