import {
  CallProvidersProvidersEnum,
  Incident,
  IncidentCall,
  IncidentCallsCreateFromURLRequestBody,
} from "@incident-io/api";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import {
  Button,
  ButtonSize,
  ButtonTheme,
  GenericErrorMessage,
  LoadingModal,
  ModalFooter,
  ToastTheme,
} from "@incident-ui";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router";
import { Form } from "src/components/@shared/forms";
import { useAPI, useAPIMutation } from "src/utils/swr";

import { getProviderNameAndLogo } from "../../../incident-calls/helpers";

export const UpdateCallModal = ({
  incident,
  onClose,
}: {
  incident: Incident;
  onClose: () => void;
}) => {
  return <UpdateCallForm onClose={onClose} incidentId={incident.id} />;
};

export const UpdateStreamCallModal = ({
  streamId,
  onClose,
}: {
  streamId?: string;
  onClose: () => void;
}): React.ReactElement => {
  const { streamId: paramsStreamId } = useParams() as {
    streamId: string;
  };

  if (!streamId) {
    streamId = paramsStreamId;
  }

  const {
    data: { stream },
    isLoading,
    error,
  } = useAPI(
    streamId == null ? null : "streamsShow",
    {
      id: streamId ?? "",
    },
    { fallbackData: { stream: undefined } },
  );

  if (isLoading) {
    return <LoadingModal onClose={onClose} />;
  }

  if (error || !stream) {
    return <GenericErrorMessage error={error} />;
  }

  return <UpdateCallForm onClose={onClose} incidentId={stream.id} />;
};

const UpdateCallForm = ({
  onClose,
  incidentId,
}: {
  onClose: () => void;
  incidentId: string;
}) => {
  const { data: existingCallData, isLoading: existingCallIsLoading } = useAPI(
    "incidentCallsGetForLatestForIncident",
    {
      incidentId,
    },
  );
  const { data: providerData, isLoading: providersAreLoading } = useAPI(
    "incidentCallSettingsGetCallProviders",
    {},
  );

  const available_providers =
    providerData?.available_providers?.providers ?? [];

  if (existingCallIsLoading || providersAreLoading || !existingCallData) {
    return <LoadingModal onClose={onClose} />;
  }

  return (
    <UpdateCallFormInner
      existingCall={existingCallData.incident_call}
      availableProviders={available_providers}
      incidentId={incidentId}
      onClose={onClose}
    />
  );
};

const UpdateCallFormInner = ({
  existingCall,
  availableProviders,
  incidentId,
  onClose,
}: {
  existingCall?: IncidentCall;
  availableProviders: CallProvidersProvidersEnum[];
  incidentId: string;
  onClose: () => void;
}) => {
  const showToast = useToast();
  const [selectedProvider, setSelectedProvider] = useState(null);

  const formMethods = useForm<IncidentCallsCreateFromURLRequestBody>({
    defaultValues: {
      call_url: existingCall?.call_url ?? "",
      incident_id: incidentId,
    },
  });

  const { trigger, isMutating, genericError } = useAPIMutation(
    "incidentCallsGetForLatestForIncident",
    { incidentId: incidentId },
    async (apiClient, data) => {
      await apiClient.incidentCallsCreateFromURL({
        createFromURLRequestBody: data,
      });
    },
    {
      setError: formMethods.setError,
      onSuccess: onClose,
    },
  );

  const { trigger: createCall, isMutating: isCreatingCall } = useAPIMutation(
    "incidentCallsGetForLatestForIncident",
    { incidentId: incidentId },
    async (apiClient, data) => {
      setSelectedProvider(data.provider);
      await apiClient.incidentCallsCreate({
        createRequestBody: {
          incident_id: incidentId,
          external_provider: data.provider,
        },
      });
    },
    {
      onSuccess: () => {
        showToast({
          theme: ToastTheme.Success,
          title: "Call created",
        });
        onClose();
      },
      onError: () => {
        showToast({
          theme: ToastTheme.Error,
          title: "Failed to create call",
        });
      },
    },
  );

  return (
    <Form.Modal
      formMethods={formMethods}
      onClose={onClose}
      analyticsTrackingId="set-call-url"
      onSubmit={trigger}
      genericError={genericError}
      saving={isMutating}
      title="Edit call"
      footer={
        <ModalFooter
          confirmButtonText="Save"
          confirmButtonType="submit"
          onClose={onClose}
          saving={isMutating}
        />
      }
    >
      {availableProviders.length > 0 && (
        <div className="flex flex-col gap-2">
          <label className="font-medium text-content-primary text-sm">
            Click to create a new call
          </label>
          <div className="flex gap-2">
            {availableProviders.map((provider) => (
              <CreateCallForProviderButton
                provider={provider}
                key={provider}
                onClick={() => createCall({ provider: provider })}
                loading={isCreatingCall && selectedProvider === provider}
                disabled={
                  isMutating ||
                  (isCreatingCall && selectedProvider !== provider)
                }
              />
            ))}
          </div>
        </div>
      )}
      <InputV2
        formMethods={formMethods}
        required
        name="call_url"
        label="Update call link"
        placeholder="e.g. https://my.zoom.us/j/1653165"
      />
    </Form.Modal>
  );
};

const CreateCallForProviderButton = ({
  provider,
  onClick,
  loading,
  disabled,
}: {
  provider: CallProvidersProvidersEnum;
  onClick: (provider: CallProvidersProvidersEnum) => void;
  loading: boolean;
  disabled: boolean;
}): React.ReactElement => {
  const { name, icon } = getProviderNameAndLogo(provider);
  return (
    <Button
      key={provider}
      theme={ButtonTheme.Secondary}
      size={ButtonSize.Small}
      icon={icon}
      analyticsTrackingId={`create-call`}
      analyticsTrackingMetadata={{ provider }}
      onClick={() => onClick(provider)}
      loading={loading}
      disabled={disabled}
    >
      {name}
    </Button>
  );
};
