import { Alert, AlertSourceSourceTypeEnum } from "@incident-io/api";
import { IconSize, OrgAwareLink, Tooltip, Txt } from "@incident-ui";
import { LocalRelativeDateTime } from "@incident-ui/LocalDateTime/LocalRelativeDateTime";
import { useAPI } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";

import { CopyDebugID } from "../../../utils/ShowDebugIDProvider";
import { AlertSourceTypeIcon } from "./AlertSourceTypeConfigs";

const MAX_TEXT_WIDTH = "max-w-[40vw]";

export const AlertOverviewColumn = ({
  alert,
  showAlertSource = false,
}: {
  alert: Alert;
  showAlertSource?: boolean;
}) => {
  return (
    <div className={tcx("flex flex-col min-w-0 items-start my-auto")}>
      <Tooltip content={alert.title}>
        <span
          className={tcx(
            "font-medium text-sm text-pretty line-clamp-1",
            MAX_TEXT_WIDTH,
          )}
        >
          {alert.title}
        </span>
      </Tooltip>

      <CopyDebugID id={alert.id} />

      <div className="flex items-center space-x-1">
        {showAlertSource ? (
          <>
            <AlertSourceBadge alert={alert} />
            <Txt className="text-slate-600 text-sm">·</Txt>
          </>
        ) : null}
        <LocalRelativeDateTime
          date={alert.created_at}
          className={
            "hover:!no-underline whitespace-nowrap text-slate-600 text-xs"
          }
        />
      </div>
    </div>
  );
};

export const AlertSourceBadge = ({
  alert,
  showIcon = false,
  className,
}: {
  alert: Alert | undefined;
  showIcon?: boolean;
  className?: string;
}) => {
  const { data: alertSourceConfig } = useAPI("alertsShowSourceConfig", {
    id: alert?.alert_source_config_id || "",
  });

  if (!alert) {
    return null;
  }

  return (
    <OrgAwareLink
      to={`/alerts/sources/${alert.alert_source_config_id}/edit`}
      className={
        "flex items-center space-x-1.5 hover:text-content-primary hover:underline transition text-ellipsis"
      }
      onClick={(e) => e.stopPropagation()}
    >
      {showIcon ? (
        <AlertSourceTypeIcon
          size={IconSize.Small}
          sourceType={alert.source_type as unknown as AlertSourceSourceTypeEnum}
        />
      ) : null}
      <Txt className={tcx(className, "text-xs text-slate-600")}>
        {alertSourceConfig?.alert_source_config.name}
      </Txt>
    </OrgAwareLink>
  );
};
