import { PageWidth, PageWrapper } from "@incident-shared/layout/PageWrapper";
import {
  SecondaryNavHorizontal,
  SecondaryNavHorizontalItemType,
} from "@incident-shared/layout/SecondaryNavHorizontal";
import { SecondaryPageWrapper } from "@incident-shared/layout/SecondaryPageWrapper";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import { GenericErrorMessage, IconEnum } from "@incident-ui";
import { Outlet } from "react-router";
import { useAPI } from "src/utils/swr";

import { shouldShowAlertSourcesEmptyState as shouldShowAlertsEmptyState } from "./common/shouldShowAlertSourcesEmptyState";

export const AlertsPageWrapper = () => {
  const alertsPageGroups: SecondaryNavHorizontalItemType[] = [
    {
      label: "Alerts",
      slug: "recent",
    },
    {
      label: "Configuration",
      slug: "configuration",
    },
  ];

  const navigate = useOrgAwareNavigate();

  const {
    data: { alert_source_configs },
    isLoading: configsLoading,
    error: sourceConfigsError,
  } = useAPI("alertsListSourceConfigs", undefined, {
    fallbackData: { alert_source_configs: [] },
  });

  const {
    data: alertRoutesData,
    isLoading: alertRoutesLoading,
    error: alertRoutesError,
  } = useAPI("alertRoutesListAlertRoutes", undefined);

  if (configsLoading || alertRoutesLoading) {
    return null;
  }

  const error = sourceConfigsError || alertRoutesError;

  if (error) {
    return <GenericErrorMessage error={error} />;
  }

  const shouldShowEmptyState = shouldShowAlertsEmptyState(
    alert_source_configs,
    alertRoutesData?.alert_routes || [],
  );

  if (shouldShowEmptyState) {
    navigate("/alerts/get-started");
  }

  return (
    <PageWrapper
      width={PageWidth.Full}
      title="Alerts"
      icon={IconEnum.Alert}
      noPadding
      headerClassName={"border-0"}
      overflowY={false}
    >
      <div className="flex flex-col h-full">
        <div className={"bg-white sticky top-0 z-20"}>
          <SecondaryNavHorizontal
            items={alertsPageGroups}
            pathPrefix={"alerts"}
            className={"px-6"}
          />
        </div>
        <SecondaryPageWrapper className="h-full !pt-0 overflow-y-auto">
          <Outlet />
        </SecondaryPageWrapper>
      </div>
    </PageWrapper>
  );
};
