import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import {
  Button,
  ButtonSize,
  ButtonTheme,
  ContentBox,
  IconBadge,
  IconEnum,
  IconSize,
} from "@incident-ui";
import { tcx } from "src/utils/tailwind-classes";

export const PolicyEditableSection = ({
  onEdit,
  icon,
  title,
  bottomContext,
  children,
}: {
  icon: IconEnum;
  title: React.ReactNode;
  children: React.ReactNode;
  bottomContext?: React.ReactNode;
  onEdit?: () => void;
}) => {
  return (
    <ContentBox className="p-4 rounded-3 flex flex-col gap-4 w-full">
      <div className="flex justify-between gap-2 w-full">
        <div className="flex items-center gap-2 text-sm-bold text-content-primary">
          <IconBadge
            size={IconSize.Small}
            icon={icon}
            color={ColorPaletteEnum.Blue}
          />
          {title}
        </div>
        {onEdit && (
          <Button
            analyticsTrackingId={null}
            title=""
            size={ButtonSize.Small}
            theme={ButtonTheme.Tertiary}
            icon={IconEnum.Edit}
            onClick={onEdit}
          />
        )}
      </div>
      {children}
      {bottomContext && (
        <div className="text-xs-med text-content-secondary">
          {bottomContext}
        </div>
      )}
    </ContentBox>
  );
};

export const PolicyCapsTxt = ({
  className,
  children,
}: {
  className?: string;
  children: React.ReactNode;
}) => {
  return (
    <div
      className={tcx(
        "text-xs uppercase font-semibold text-content-secondary tracking-wide tabular-nums lining-nums",
        className,
      )}
    >
      {children}
    </div>
  );
};
