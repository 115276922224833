import {
  Button,
  ButtonSize,
  ButtonTheme,
  IconEnum,
  IconSize,
} from "@incident-ui";
import React from "react";
import { useLocalStorage } from "use-hooks";

import { tcx } from "./tailwind-classes";
import { useClipboard } from "./useClipboard";

const ShowDebugIDWindowKey = "incident-show-ids";

type ShowDebugIDContextType = {
  showDebugID: boolean;
  setShowDebugID: (show: boolean) => void;
};

const ShowDebugIDContext = React.createContext<ShowDebugIDContextType | null>(
  null,
);

export const useShowDebugID = (): ShowDebugIDContextType => {
  const context = React.useContext(ShowDebugIDContext);

  if (!context) {
    throw new Error("useShowDebugID must be used within a ShowDebugIDProvider");
  }

  return context;
};

export const ShowDebugIDProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [showDebugID, setShowDebugID] = useLocalStorage(
    ShowDebugIDWindowKey,
    false,
  );

  return (
    <ShowDebugIDContext.Provider
      value={{
        showDebugID,
        setShowDebugID,
      }}
    >
      {children}
    </ShowDebugIDContext.Provider>
  );
};

export const CopyDebugID = ({
  id,
  className,
}: {
  id: string | undefined;
  className?: string;
}) => {
  const { hasCopied, copyTextToClipboard } = useClipboard();

  const { showDebugID } = useShowDebugID();

  if (!id) {
    return null;
  }

  if (!showDebugID) {
    return null;
  }

  return (
    <Button
      className={tcx("shrink truncate", className)}
      theme={ButtonTheme.Naked}
      icon={hasCopied ? IconEnum.Success : IconEnum.Copy}
      analyticsTrackingId="copy-token"
      type="button"
      size={ButtonSize.Small}
      title="Copy to clipboard"
      iconProps={{
        className: hasCopied ? "text-green-content" : "text-slate-700",
        size: IconSize.XS,
      }}
      onClick={(event) => {
        event.preventDefault();
        event.stopPropagation();

        copyTextToClipboard(id);
      }}
    >
      {id}
    </Button>
  );
};
