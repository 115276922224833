import { tcx } from "src/utils/tailwind-classes";

export const LoadingTrendsTiles = ({
  numTiles: numTrends,
  smaller,
}: {
  numTiles: number;
  smaller?: boolean;
}) => {
  const colours = [
    { background: "bg-blue-50", gradient: "from-blue-100" },
    { background: "bg-red-50", gradient: "from-red-100" },
    { background: "bg-green-50", gradient: "from-green-100" },
  ];
  const minSize = smaller ? "min-h-24" : "min-h-28";
  return (
    <>
      {Array.from({ length: numTrends }, (_, i) => {
        const { background, gradient } = colours[i % colours.length];

        return (
          <div
            key={i}
            className={tcx(
              `flex flex-col rounded-lg p-4 gap-1 animate-pulse`,
              background,
              minSize,
            )}
          >
            <GradientBar background={background} gradient={gradient} />
            {!smaller && (
              <GradientBar
                background={background}
                gradient={gradient}
                stopPoint="to-50%"
              />
            )}
            <GradientBar
              background={background}
              gradient={gradient}
              taller
              stopPoint="to-80%"
            />
            <GradientBar
              background={background}
              gradient={gradient}
              stopPoint="to-70%"
            />
          </div>
        );
      })}
    </>
  );
};

const GradientBar = ({
  background,
  gradient,
  stopPoint,
  taller,
}: {
  background: string;
  gradient: string;
  stopPoint?: string;
  taller?: boolean;
}) => {
  return (
    <div
      className={tcx(
        "w-full rounded-1 bg-gradient-to-r",
        background,
        gradient,
        stopPoint,
        taller ? "h-8" : "h-4",
      )}
    ></div>
  );
};
