/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AITestPromptRequestBody
 */
export interface AITestPromptRequestBody {
    /**
     * Any messages or updates after this time will not be included in the prompt context.
     * @type {string}
     * @memberof AITestPromptRequestBody
     */
    ignore_after_timestamp?: string;
    /**
     * Incidents to test the prompt against.
     * @type {Array<string>}
     * @memberof AITestPromptRequestBody
     */
    incident_ids: Array<string>;
    /**
     * Prompt to test against the incidents.
     * @type {string}
     * @memberof AITestPromptRequestBody
     */
    prompt: string;
    /**
     * Return JSON response instead of text.
     * @type {boolean}
     * @memberof AITestPromptRequestBody
     */
    return_json: boolean;
}

export function AITestPromptRequestBodyFromJSON(json: any): AITestPromptRequestBody {
    return AITestPromptRequestBodyFromJSONTyped(json, false);
}

export function AITestPromptRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AITestPromptRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ignore_after_timestamp': !exists(json, 'ignore_after_timestamp') ? undefined : json['ignore_after_timestamp'],
        'incident_ids': json['incident_ids'],
        'prompt': json['prompt'],
        'return_json': json['return_json'],
    };
}

export function AITestPromptRequestBodyToJSON(value?: AITestPromptRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ignore_after_timestamp': value.ignore_after_timestamp,
        'incident_ids': value.incident_ids,
        'prompt': value.prompt,
        'return_json': value.return_json,
    };
}

