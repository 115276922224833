import {
  ExploDashboardInfo,
  ExploDashboardWithDrilldownInfo,
  Panel,
  PanelPanelTypeEnum,
} from "@incident-io/api";
import { ContentBox } from "@incident-ui";
import { useEffect, useRef } from "react";
import { useFormContext } from "react-hook-form";
import { useQueryParams } from "src/utils/query-params";

import { ExploDashboardPanel } from "./ExploDashboardPanel";
import { ExploDashboardWithDrilldownPanel } from "./ExploDashboardWithDrilldownPanel";
import { TextPanel } from "./TextPanel";
import { EditDashboardFormData } from "./useInsightsContext";
export const InsightsPanel = ({
  panel,
  panelIdx,
}: {
  panel: Panel;
  panelIdx: number;
}) => {
  const self = useRef<HTMLDivElement | null>(null);
  const queryParams = useQueryParams();
  const scrollTo = queryParams.get("scroll_to");

  const scrollToMatchesPanel =
    !!scrollTo &&
    (scrollTo === panel.name ||
      scrollTo === panel.explo_dashboard?.explo_dashboard_id);

  useEffect(() => {
    // We want to be able to link to particular panels, but we can't depend
    // on #fragment links as the page doesn't render immediately.
    if (!!self.current && scrollToMatchesPanel) {
      self.current.scrollIntoView();
    }
  }, [self, queryParams, scrollToMatchesPanel]);

  return (
    <ContentBox ref={self} className="bg-white p-5 flex flex-col gap-5">
      <InsightsPanelContent panel={panel} panelIdx={panelIdx} />
    </ContentBox>
  );
};

const InsightsPanelContent = ({
  panel,
  panelIdx,
}: {
  panel: Panel;
  panelIdx: number;
}) => {
  const formMethods = useFormContext<EditDashboardFormData>();
  const variableData = formMethods.watch(`panels.${panelIdx}.variables_data`);

  switch (panel.panel_type) {
    case PanelPanelTypeEnum.ExploDashboard:
      return (
        <ExploDashboardPanel
          exploDashboardInfo={panel.explo_dashboard as ExploDashboardInfo}
          panelName={panel.name}
          panelIdx={panelIdx}
          panelFilterContexts={panel.filter_contexts || []}
          variableData={variableData}
          variables={panel.variables}
        />
      );
    case PanelPanelTypeEnum.ExploDashboardWithDrilldown:
      return (
        <ExploDashboardWithDrilldownPanel
          drilldownInfo={
            panel.explo_dashboard_with_drilldown as ExploDashboardWithDrilldownInfo
          }
          panelIdx={panelIdx}
          variableData={variableData}
          panelName={panel.name}
          variables={panel.variables}
        />
      );
    case PanelPanelTypeEnum.Text:
      return <TextPanel panelIdx={panelIdx} />;
    default:
      return <div>Unsupported panel type</div>;
  }
};
