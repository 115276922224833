/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CatalogIntegrationInfo
 */
export interface CatalogIntegrationInfo {
    /**
     * Whether this integration has parameterised resources
     * @type {boolean}
     * @memberof CatalogIntegrationInfo
     */
    has_parameterised_resources: boolean;
    /**
     * Whether this integration is currently usable
     * @type {boolean}
     * @memberof CatalogIntegrationInfo
     */
    is_usable: boolean;
    /**
     * The label for this integration
     * @type {string}
     * @memberof CatalogIntegrationInfo
     */
    label: string;
    /**
     * The integration provider
     * @type {string}
     * @memberof CatalogIntegrationInfo
     */
    provider: CatalogIntegrationInfoProviderEnum;
}

/**
* @export
* @enum {string}
*/
export enum CatalogIntegrationInfoProviderEnum {
    Asana = 'asana',
    Statuspage = 'statuspage',
    ClickUp = 'click_up',
    Confluence = 'confluence',
    Cortex = 'cortex',
    Datadog = 'datadog',
    Github = 'github',
    Gitlab = 'gitlab',
    GoogleDocs = 'google_docs',
    GoogleMeet = 'google_meet',
    GoogleCalendar = 'google_calendar',
    GoogleWorkspace = 'google_workspace',
    Jira = 'jira',
    JiraServer = 'jira_server',
    Linear = 'linear',
    MicrosoftTeams = 'microsoft_teams',
    Notion = 'notion',
    Opsgenie = 'opsgenie',
    Opslevel = 'opslevel',
    Pagerduty = 'pagerduty',
    Salesforce = 'salesforce',
    Sentry = 'sentry',
    ServiceNow = 'service_now',
    Shortcut = 'shortcut',
    Scim = 'scim',
    Slack = 'slack',
    SplunkOnCall = 'splunk_on_call',
    IncidentIoStatusPages = 'incident_io_status_pages',
    IncidentIoOnCall = 'incident_io_on_call',
    Vanta = 'vanta',
    Webhooks = 'webhooks',
    Zendesk = 'zendesk',
    Zoom = 'zoom'
}

export function CatalogIntegrationInfoFromJSON(json: any): CatalogIntegrationInfo {
    return CatalogIntegrationInfoFromJSONTyped(json, false);
}

export function CatalogIntegrationInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CatalogIntegrationInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'has_parameterised_resources': json['has_parameterised_resources'],
        'is_usable': json['is_usable'],
        'label': json['label'],
        'provider': json['provider'],
    };
}

export function CatalogIntegrationInfoToJSON(value?: CatalogIntegrationInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'has_parameterised_resources': value.has_parameterised_resources,
        'is_usable': value.is_usable,
        'label': value.label,
        'provider': value.provider,
    };
}

