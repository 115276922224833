import {
  IncidentActivityLogEntry,
  IncidentTimelineItem,
} from "@incident-io/api";
import { Timeline } from "@incident-shared/timeline/Timeline";
import { TimelineItem } from "@incident-shared/timeline/TimelineItem";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import { Button, ButtonSize, ContentBox, IconEnum, Loader } from "@incident-ui";
import React from "react";
import { useIncident } from "src/components/legacy/incident/hooks";
import { useAPI, useAPIMutation } from "src/utils/swr";

import { useNavigateToModal } from "../../utils/query-params";
import { ActivityItemTypeToComponentMap } from "./activity-items/ActivityItem";
import {
  ActivityLogItemMode,
  ActivityLogItemUIWrapper,
} from "./ActivityLogItemUI";
import { TimelineItemCommentBox } from "./TimelineItemComments";

type IncidentTimelineProps = {
  incidentId: string | null;
  editing?: boolean;
  minimizedItems: string[];
  commentBoxOpen: string[];
  setMinimizedItems: React.Dispatch<React.SetStateAction<string[]>>;
  setCommentBoxOpen: React.Dispatch<React.SetStateAction<string[]>>;
  setEditing?: React.Dispatch<
    React.SetStateAction<IncidentTimelineItem | undefined>
  >;
  className?: string;
  hideHeader?: boolean;
};

export const IncidentTimeline = ({
  incidentId,
  editing,
  minimizedItems,
  setMinimizedItems,
  commentBoxOpen,
  setCommentBoxOpen,
  setEditing,
  className,
  hideHeader,
}: IncidentTimelineProps) => {
  const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const { incident } = useIncident(incidentId);
  const navigateToModal = useNavigateToModal();

  const { data: groupedTimelineItems, isLoading } = useAPI(
    incidentId ? "incidentTimelineListTimelineItems" : null,
    {
      incidentId: incidentId ?? "",
      timezone: localTimezone,
    },
    { fallbackData: { timeline_items: [] } },
  );

  const { trigger: deleteTimelineItem } = useAPIMutation(
    "incidentTimelineListTimelineItems",
    {
      incidentId: incidentId ?? "",
      timezone: localTimezone,
    },
    async (client, data: { incidentId: string; timelineItemId: string }) => {
      return await client.incidentTimelineDestroyTimelineItem({
        incidentId: data.incidentId,
        timelineItemId: data.timelineItemId,
      });
    },
  );

  const setItemExpanded = (itemId: string, minimized: boolean) => {
    setMinimizedItems((prevExpanded) =>
      minimized
        ? [...prevExpanded, itemId]
        : prevExpanded.filter((id) => id !== itemId),
    );
  };

  const setSingleCommentBoxOpen = (itemId: string, open: boolean) => {
    setCommentBoxOpen((prevOpen) =>
      open ? [...prevOpen, itemId] : prevOpen.filter((id) => id !== itemId),
    );
  };

  const onDelete = ({
    incidentId,
    itemId,
  }: {
    incidentId: string;
    itemId: string;
  }) => {
    deleteTimelineItem({ incidentId, timelineItemId: itemId });
  };

  const timelineItems = groupedTimelineItems?.timeline_items ?? [];

  if (!incident || isLoading) {
    return <Loader />;
  }

  const renderItem = ({
    item,
    hideSpacer,
  }: {
    item: IncidentTimelineItem;
    hideSpacer: boolean;
  }) => {
    const activityLogEntry = item.evidence?.find((e) =>
      Boolean(e.incident_activity_log),
    )?.incident_activity_log;

    let icon: IconEnum | undefined;
    let color: ColorPaletteEnum | undefined;
    if (activityLogEntry) {
      const activityProps =
        ActivityItemTypeToComponentMap[activityLogEntry.type]?.(
          activityLogEntry,
        );

      icon = activityProps?.icon;
      color = activityProps?.colour;
    }

    icon = icon ?? IconEnum.Bookmark;
    color = color ?? ColorPaletteEnum.Pink;

    return (
      <TimelineItem
        id={item.id}
        description={item.description}
        title={item.title}
        timestamp={item.timestamp}
        hasChildren={!!activityLogEntry}
        hideSpacer={hideSpacer}
        minimized={minimizedItems.includes(item.id)}
        setMinimized={(minimized) => setItemExpanded(item.id, minimized)}
        forceShowButtons
        onEdit={setEditing ? () => setEditing(item) : undefined}
        onDelete={
          editing
            ? () =>
                onDelete({
                  incidentId: item.incident_id,
                  itemId: item.id,
                })
            : undefined
        }
        icon={icon}
        color={color}
        allowCommenting={!editing}
        comments={item.comments}
        commentBoxOpen={commentBoxOpen.includes(item.id)}
        setCommentBoxOpen={(open) => setSingleCommentBoxOpen(item.id, open)}
        renderCommentBox={(id, comments) => (
          <TimelineItemCommentBox
            incidentId={incident.id}
            timelineItemId={id}
            comments={comments}
          />
        )}
      >
        <ActivityLogContent activityLog={activityLogEntry} />
      </TimelineItem>
    );
  };

  return (
    <Timeline<IncidentTimelineItem>
      accessorKey="timeline_item"
      items={timelineItems}
      hideHeader={editing || hideHeader}
      className={className}
      minimizedItems={minimizedItems}
      setMinimizedItems={setMinimizedItems}
      renderItem={renderItem}
      headerAccessory={
        <>
          <Button
            icon={IconEnum.Edit}
            size={ButtonSize.Small}
            analyticsTrackingId={"edit-timeline"}
            onClick={() => navigateToModal("edit-timeline")}
          >
            Edit
          </Button>
        </>
      }
    />
  );
};

export const ActivityLogContent = ({
  activityLog,
}: {
  activityLog?: IncidentActivityLogEntry;
}) => {
  if (!activityLog) {
    return null;
  }

  const ActivityLogItemComponent =
    ActivityItemTypeToComponentMap[activityLog.type];
  if (ActivityLogItemComponent) {
    const activityProps = ActivityLogItemComponent(activityLog);

    if (activityProps) {
      return (
        <ContentBox key={activityLog.id} className="p-1">
          <ActivityLogItemUIWrapper
            mode={ActivityLogItemMode.Timeline}
            item={activityLog}
          />
        </ContentBox>
      );
    }
  }

  return <></>;
};
