"use client";

import {
  InternalStatusPageIncident,
  StatusPageAffectedComponentStatusEnum,
  StatusPageContentIncident,
  StatusPageContentListComponentImpactsResponseBody,
  StatusPageContentStatusSummaryWorstComponentStatusEnum,
  StatusPageDisplayUptimeModeEnum as DisplayUptimeModeEnum,
} from "@incident-io/api";
import {
  formatTimestampLocale,
  hasSameDay,
  STATUS_SEVERITY,
  Tooltip,
  useNow,
  useParseTime,
  useTimeMaths,
} from "@incident-io/status-page-ui";
import cx from "classnames";
import _ from "lodash";
import { DateTime } from "luxon";

import { getMaintenanceImpactWindow } from "../../helpers";
import { useUIContext } from "../../UIContext";
import { useTranslations } from "../../use-translations";
import { ContentBox } from "../ContentBox";
import { ChevronIcon } from "../Icons/ChevronIcon";
import { InfoIcon } from "../Icons/InfoIcon";
import {
  incidentLinkClassNames,
  IncidentLinkContents,
} from "../Tooltip/DayOfIncidentsTooltipContents";
import { startAtFrom } from "./helpers";
import { ItemStatus } from "./ItemStatus";

export type ComponentStructureItem = {
  id: string;
  displayUptime: boolean;
  hidden: boolean;
  name: string;
  description?: string;
};

export type GroupStructureItem = {
  id: string;
  displayUptime: boolean;
  hidden: boolean;
  name: string;
  description?: string;
  components: ComponentStructureItem[];
};

export type GenericStructure = {
  items: GenericStructureItem[];
  display_uptime_mode: DisplayUptimeModeEnum;
};

export type GenericStructureItem =
  | {
      component: ComponentStructureItem;
      group?: never;
    }
  | {
      component?: never;
      group: GroupStructureItem;
    };

export type GenericAffectedComponent = {
  component_id: string;
  status: StatusPageAffectedComponentStatusEnum;
};

export const SystemStatus = ({
  componentImpacts,
  affectedComponents,
  structure,
  isLoading,
  endAt,
  setEndAt,
  dataAvailableSince,
  maintenances,
  withoutTooltips = false,
  autoExpandGroups = false,
  incidents,
}: {
  componentImpacts: StatusPageContentListComponentImpactsResponseBody;
  affectedComponents: GenericAffectedComponent[];
  structure: GenericStructure;
  isLoading: boolean;
  endAt: DateTime;
  setEndAt: (endAt: DateTime) => void;
  dataAvailableSince: DateTime;
  maintenances?: Array<StatusPageContentIncident>;
  withoutTooltips?: boolean;
  autoExpandGroups?: boolean;
  incidents: InternalStatusPageIncident[] | null;
}): React.ReactElement | null => {
  if (!structure) {
    throw new Error("structure is undefined");
  }
  if (!componentImpacts) {
    throw new Error("impact data is undefined");
  }
  // Don't render without any components, it's not really useful.
  if (structure.items.length === 0) {
    return null;
  }

  return (
    <SystemStatusRender
      affectedComponents={affectedComponents}
      data={componentImpacts}
      isLoading={isLoading}
      endAt={endAt}
      setEndAt={setEndAt}
      dataAvailableSince={dataAvailableSince}
      structure={structure}
      maintenances={maintenances}
      withoutTooltips={withoutTooltips}
      autoExpandGroups={autoExpandGroups}
      incidents={incidents}
    />
  );
};

export const SystemStatusRender = ({
  data,
  isLoading,
  endAt,
  setEndAt,
  dataAvailableSince,
  structure,
  affectedComponents,
  maintenances,
  withoutTooltips = false,
  autoExpandGroups = false,
  incidents,
}: {
  data: StatusPageContentListComponentImpactsResponseBody;
  affectedComponents: GenericAffectedComponent[];
  isLoading: boolean;
  endAt: DateTime;
  setEndAt: (endAt: DateTime) => void;
  dataAvailableSince: DateTime;
  structure: GenericStructure;
  maintenances?: Array<StatusPageContentIncident>;
  withoutTooltips?: boolean;
  autoExpandGroups?: boolean;
  incidents: InternalStatusPageIncident[] | null;
}): React.ReactElement | null => {
  // Avoid re-rendering due to a not-real change
  const startAt = useTimeMaths(startAtFrom(endAt));
  const now = useNow(10 * 1000, "minute");

  const currentComponentStatuses: Record<
    string,
    StatusPageAffectedComponentStatusEnum | undefined
  > = _.mapValues(
    _.groupBy(affectedComponents, ({ component_id }) => component_id),
    (affectedComponents) =>
      _.maxBy(
        affectedComponents.map(({ status }) => status),
        (status) => STATUS_SEVERITY[status],
      ),
  );

  const visibleStructure = getVisibleInternalStructure(structure);
  if (visibleStructure.length === 0) return null;

  const showingSomeUptime = visibleStructure.some((item) => {
    if (item.group) {
      // Aggreggated uptime is not relevant here: if it's on, it only takes
      // effect if there is data to aggregate; if it's off, you should still
      // be able to browse through data on individual components.
      return item.group.components.some((component) => component.displayUptime);
    } else {
      return item.component.displayUptime;
    }
  });

  const componentIncidents: {
    [componentId: string]: InternalStatusPageIncident[];
  } = {};

  if (incidents) {
    incidents.forEach((incident) => {
      incident.affected_components.forEach((component) => {
        if (!componentIncidents[component.id]) {
          componentIncidents[component.id] = [];
        }
        componentIncidents[component.id].push(incident);
      });
    });
  }

  return (
    <ContentBox
      title={
        <SystemStatusTimePicker
          startAt={startAt}
          endAt={endAt}
          setEndAt={setEndAt}
          now={now}
          dataAvailableSince={dataAvailableSince}
          maintenances={maintenances}
          showPicker={
            structure.display_uptime_mode !== DisplayUptimeModeEnum.Nothing &&
            showingSomeUptime
          }
        />
      }
      padded={false}
    >
      <div
        className={cx(
          "divide-y divide-solid text-sm",
          "divide-slate-100",
          "dark:divide-slate-700",
        )}
      >
        {visibleStructure.map((item) => {
          const id = item.group ? item.group.id : item.component.id;

          return (
            <div key={id} className={"p-4 md:pt-3 md:pb-3 text-sm"}>
              <ItemStatus
                showChart={
                  structure.display_uptime_mode !==
                  DisplayUptimeModeEnum.Nothing
                }
                currentComponentStatuses={currentComponentStatuses}
                item={item}
                data={data}
                isLoading={isLoading}
                startAt={startAt}
                endAt={endAt}
                withoutTooltips={withoutTooltips}
                autoExpandGroups={autoExpandGroups}
                incidents={componentIncidents}
              />
            </div>
          );
        })}
      </div>
    </ContentBox>
  );
};

const SystemStatusTimePicker = ({
  startAt,
  endAt,
  setEndAt,
  dataAvailableSince,
  now,
  maintenances,
  showPicker,
}: {
  startAt: DateTime;
  endAt: DateTime;
  setEndAt: (endAt: DateTime) => void;
  dataAvailableSince: DateTime;
  now: DateTime;
  maintenances?: Array<StatusPageContentIncident>;
  showPicker: boolean;
}): React.ReactElement => {
  const t = useTranslations("SystemStatus");

  const nowMonth = formatTimestampLocale(now, "month");
  const localMonthStart = formatTimestampLocale(startAt, "month");
  const localMonthEnd = formatTimestampLocale(endAt, "month");
  const { parse } = useParseTime();

  const upcomingMaintenances = maintenances?.filter((maintenance) => {
    const [start, end] = getMaintenanceImpactWindow(
      maintenance.component_impacts,
    );
    if (!start) {
      return false;
    }
    const windowStart = parse(start);
    const windowEnd = end && parse(end);
    if (!windowStart || (windowEnd && windowEnd < now)) {
      return false;
    }
    return true;
  });

  const { IncidentLink } = useUIContext();

  const canGoBack = dataAvailableSince < startAt;
  const canGoForward = localMonthEnd !== nowMonth;

  const chevronClasses = (enabled) =>
    cx(
      "w-4 h-4 font-semibold",
      enabled ? "cursor-pointer transition" : "cursor-not-allowed",
      enabled ? "text-slate-300 hover:text-slate-500" : "!text-slate-100",
      enabled
        ? "dark:text-content-tertiary dark:hover:text-slate-200"
        : "dark:!text-slate-700",
    );

  return (
    <div className="flex md:items-center justify-between md:flex-row flex-col md:gap-2 gap-4 items-start">
      <div className={"flex items-center space-x-2"}>
        <h2 className="text-content-primary dark:text-slate-50">
          {t("title")}
        </h2>
        {showPicker && (
          <div
            className={cx(
              "hidden md:flex items-center text-sm font-normal space-x-1 mt-[1px]",
              "text-slate-500",
            )}
          >
            <div
              onClick={
                canGoBack
                  ? () => setEndAt(endAt.minus({ days: 90 }))
                  : undefined
              }
            >
              <ChevronIcon
                flavour="left"
                className={chevronClasses(canGoBack)}
              />
            </div>

            <div
              className="select-none flex justify-center whitespace-nowrap text-content-tertiary dark:text-content-tertiary"
              suppressHydrationWarning
            >
              {localMonthStart}
              <span className="px-1">-</span>
              {localMonthEnd}
            </div>

            <div
              onClick={
                canGoForward
                  ? () => setEndAt(endAt.plus({ days: 90 }))
                  : undefined
              }
            >
              <ChevronIcon
                flavour="right"
                className={chevronClasses(canGoForward)}
              />
            </div>
          </div>
        )}
      </div>
      {upcomingMaintenances && upcomingMaintenances.length > 0 && (
        <Tooltip
          className={"w-full md:w-auto"}
          content={
            <div className="text-sm text-content-tertiary max-h-[500px] overflow-auto">
              {_.chain(upcomingMaintenances)
                .map((maintenance) => {
                  const [windowStart, windowEnd] = getMaintenanceImpactWindow(
                    maintenance.component_impacts,
                  );

                  return {
                    maintenance,
                    windowStart,
                    windowEnd,
                  };
                })
                .sortBy(({ windowStart }) => windowStart)
                .map(({ maintenance, windowStart, windowEnd }) => {
                  let intervalStr: string;

                  if (windowStart && windowEnd) {
                    const startDateTime = parse(windowStart);
                    const endDateTime = parse(windowEnd);

                    const startFormatted = formatTimestampLocale(
                      startDateTime,
                      "intervalFull",
                    );

                    // Include the day for the end date if the dates are not on the same day
                    const isSameDay = hasSameDay(startDateTime, endDateTime);
                    const endFormatted = formatTimestampLocale(
                      endDateTime,
                      isSameDay ? "intervalShort" : "intervalFull",
                    );

                    intervalStr = `${startFormatted} — ${endFormatted}`;
                  } else if (windowStart) {
                    intervalStr = formatTimestampLocale(parse(windowStart));
                  } else {
                    return null;
                  }

                  return (
                    <div className="flex flex-col gap-1" key={maintenance.id}>
                      <div
                        className="whitespace-nowrap text-left p-1 text-content-tertiary"
                        suppressHydrationWarning
                      >
                        {intervalStr}
                      </div>
                      <IncidentLink
                        className={incidentLinkClassNames(
                          StatusPageContentStatusSummaryWorstComponentStatusEnum.UnderMaintenance,
                        )}
                        incident={{
                          name: maintenance.name,
                          incident_id: maintenance.id,
                        }}
                        analyticsTrackingId="sp-calendar-incident-link"
                      >
                        <IncidentLinkContents
                          name={maintenance.name}
                          status={
                            StatusPageContentStatusSummaryWorstComponentStatusEnum.UnderMaintenance
                          }
                        />
                      </IncidentLink>
                    </div>
                  );
                })
                .value()}
            </div>
          }
        >
          <div
            className={cx(
              "flex flex-row items-center gap-1 h-full p-1.5 cursor-default",
              // Because this box is taller than the text it makes the overall
              // content box heading grow a bit. Negative margin prevents that
              // from happening.
              "-my-1.5",
              "text-sm font-normal",
              "rounded-lg border",
              "transition",
              "text-slate-700 border-stroke hover:bg-surface-secondary/50",
              "dark:text-slate-100 dark:hover:bg-[rgba(41,51,65,0.57)] dark:border-slate-700",
            )}
          >
            <InfoIcon className="flex-none text-content-tertiary dark:text-slate-500" />
            {t("upcoming_maintenance_scheduled")}
          </div>
        </Tooltip>
      )}
    </div>
  );
};

export const getVisibleInternalStructure = (
  structure: GenericStructure,
): GenericStructureItem[] =>
  _.compact(
    structure.items.map((item) => {
      if (item.group) {
        const visibleComponents = item.group.components.filter(
          (component) => !component.hidden,
        );

        return item.group.hidden || visibleComponents.length === 0
          ? undefined
          : {
              ...item,
              group: {
                ...item.group,
                components: visibleComponents,
              },
            };
      } else {
        return item.component.hidden ? undefined : item;
      }
    }),
  ) as GenericStructureItem[];
