import {
  Incident,
  IncidentPostmortemStatusEnum,
  PostmortemTemplate,
} from "@incident-io/api";
import { ExportPostmortemDrawer } from "@incident-shared/postmortems/ExportPostmortemDrawer";
import { getColorPalette } from "@incident-shared/utils/ColorPalettes";
import {
  Button,
  ButtonSize,
  ButtonTheme,
  DropdownMenu,
  DropdownMenuItem,
  GenericErrorMessage,
  IconEnum,
  IconSize,
  LoadingBar,
  ToastTheme,
} from "@incident-ui";
import { DropdownMenuSubItem } from "@incident-ui/DropdownMenu/DropdownMenu";
import { SelectOption } from "@incident-ui/Select/types";
import { ToastSideEnum } from "@incident-ui/Toast/Toast";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { useFlags } from "launchdarkly-react-client-sdk";
import React, { useState } from "react";
import { useAPI, useAPIMutation, useAPIRefetch } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";

import { Summary } from "../body/Summary";
import { useIncident } from "../hooks";
import { usePostmortem } from "../postincidentflow/usePostmortem";
import { getDocumentProvider } from "../sidebar/PostMortemPrompt";
import { PostmortemCustomSection } from "./PostmortemCustomSection";
import { PostmortemFollowups } from "./PostmortemFollowups";
import { PostmortemTimeline } from "./PostmortemTimeline";
import { StatusProps } from "./utils";

export const PostmortemTab = ({
  incidentId,
}: {
  incidentId: string | null;
}): React.ReactElement => {
  const { incident, isLoading: incidentLoading } = useIncident(incidentId);

  const {
    data: { postmortem_templates: templates },
    isLoading: loadingTemplates,
    error: errorTemplates,
  } = useAPI(
    "postmortemsListTemplates",
    {
      incidentId: incidentId ?? undefined,
    },
    {
      fallbackData: { postmortem_templates: [] },
    },
  );

  // Be extra sure we don't show this content unless the feature flag is enabled.
  const { postmortemsInHouse: featurePostmortemsInHouse } = useFlags();
  if (!featurePostmortemsInHouse) {
    return <></>;
  }

  if (errorTemplates) {
    return <GenericErrorMessage error={errorTemplates} />;
  }

  if (
    incidentId == null ||
    incident == null ||
    incidentLoading ||
    loadingTemplates
  ) {
    return <LoadingBar className="w-full h-28 mt-4" />;
  }

  return <PostmortemTabInner incident={incident} templates={templates} />;
};

const PostmortemTabInner = ({
  incident,
  templates,
}: {
  incident: Incident;
  templates: PostmortemTemplate[];
}): React.ReactElement => {
  const showToast = useToast();

  const [selectedTemplateId, setSelectedTemplateId] = useState<
    string | undefined
  >(
    // If the incident has a template set, use that. Otherwise, leverage the default
    // that has been evaluated on the backend.
    incident.postmortem_template_id
      ? incident.postmortem_template_id
      : templates.find((template) => template.is_default)?.id,
  );

  const { trigger: onUpdateTemplate, isMutating: savingTemplate } =
    useAPIMutation(
      "incidentsShow",
      { id: incident?.id ?? "" },
      async (apiClient, { postmortem_template_id }) => {
        await apiClient.postmortemsUpdateTemplateForIncident({
          incidentId: incident?.id ?? "",
          postmortemTemplateId: postmortem_template_id,
        });
      },
      {
        onSuccess: () => {
          showToast({
            theme: ToastTheme.Success,
            title:
              "We'll use this post-mortem template for the incident going forward.",
            toastSide: ToastSideEnum.TopRight,
          });
        },
      },
    );

  const { trigger: onUpdateStatus, isMutating: savingStatus } = useAPIMutation(
    "incidentsShow",
    { id: incident?.id ?? "" },
    async (apiClient, { status }) => {
      await apiClient.postmortemsUpdateStatus({
        updateStatusRequestBody: {
          incident_id: incident?.id ?? "",
          status: status,
        },
      });
    },
  );

  const setTemplate = async (templateId: string) => {
    await onUpdateTemplate({ postmortem_template_id: templateId });
    setSelectedTemplateId(templateId);
  };

  const setPostmortemStatus = async (status: string) => {
    await onUpdateStatus({ status });
  };

  const statusOptions: SelectOption[] = Object.values(
    IncidentPostmortemStatusEnum,
  ).map((status) => ({
    label: StatusProps[status].label,
    value: status,
  }));

  if (!selectedTemplateId) {
    // This isn't possible, because we don't let you delete the last template.
    return <GenericErrorMessage description={"No templates found"} />;
  }

  const currentStatusProps = StatusProps[incident.postmortem_status];
  const currentStatusColors = getColorPalette(currentStatusProps.colors);

  return (
    <div className="mt-4 space-y-4">
      <div className="flex justify-between">
        <div className="flex gap-2">
          {/* Status dropdown */}
          <DropdownMenu
            align="start"
            disabled={savingStatus}
            triggerButton={
              <Button
                theme={ButtonTheme.Ghost}
                analyticsTrackingId={"postmortem-change-status"}
                icon={IconEnum.Circle}
                className={tcx(
                  currentStatusColors.background,
                  currentStatusColors.hoverBg,
                  currentStatusColors.activeBg,
                  "text-content-primary",
                )}
                iconProps={{
                  size: IconSize.XS,
                  className: tcx("w-2 h-2", currentStatusColors.text),
                }}
                iconPosition={"left"}
                size={ButtonSize.Small}
              >
                {currentStatusProps.label}
              </Button>
            }
          >
            {statusOptions.map((option) => (
              <DropdownMenuItem
                key={option.value}
                analyticsTrackingId={null}
                label={option.label}
                onSelect={() => setPostmortemStatus(option.value)}
                disabled={option.value === incident.postmortem_status}
              />
            ))}
          </DropdownMenu>
        </div>
        {/* Export */}
        <ExportAndOverflow
          incident={incident}
          templateId={selectedTemplateId}
          templates={templates}
          setTemplate={setTemplate}
          savingTemplate={savingTemplate}
        />
      </div>
      {/* Sections */}
      <IncidentPostmortem incident={incident} templateId={selectedTemplateId} />
    </div>
  );
};

const ExportAndOverflow = ({
  incident,
  templateId,
  templates,
  setTemplate,
  savingTemplate,
}: {
  incident: Incident;
  templateId: string;
  templates: PostmortemTemplate[];
  setTemplate: (templateId: string) => void;
  savingTemplate: boolean;
}): React.ReactElement => {
  // TODO: once we have designs, we'll also:
  // - Show when a postmortem is being exported
  // - Show errors in the export
  // - Ability to retry failed export
  // - Option to share a postmortem
  // - Policy violations
  const refetchPostIncidentFlowTasks = useAPIRefetch(
    "postIncidentFlowListTasks",
    { incidentId: incident.id },
  );

  const [showExportDrawer, setShowExportDrawer] = useState<boolean>(false);

  const {
    trigger: destroyDocument,
    isMutating: destroying,
    genericError: destroyError,
  } = useAPIMutation(
    "postmortemsListDocuments",
    { incidentId: incident.id },
    async (apiClient, { id }: { id: string }) => {
      await apiClient.postmortemsDestroyDocument({ id });
      await refetchPostIncidentFlowTasks();
    },
  );

  const { availableDocument: document } = usePostmortem(incident);

  let documentProviderName: string | undefined;
  let icon: IconEnum | undefined;
  if (document) {
    const { providerName, providerIcon } = getDocumentProvider(document);
    [documentProviderName, icon] = [providerName, providerIcon];
  }

  if (destroyError) {
    return <GenericErrorMessage />;
  }

  const templateOptions: SelectOption[] = templates.map((template) => ({
    label: template.name,
    value: template.id,
  }));

  return (
    <div className="ml-2 flex gap-2">
      {/* Button to export or view */}
      {document ? (
        <Button
          href={document.permalink}
          analyticsTrackingId="view-postmortem"
          icon={icon ?? IconEnum.File}
          iconProps={{
            size: IconSize.Medium,
            className: "ml-0.5 text-content-tertiary",
          }}
          size={ButtonSize.Small}
          openInNewTab
        >
          {documentProviderName
            ? `View in ${documentProviderName}` // "View in Notion"
            : `View doc`}
        </Button>
      ) : (
        <Button
          analyticsTrackingId={"postmortem-export"}
          icon={IconEnum.Export}
          size={ButtonSize.Small}
          onClick={() => setShowExportDrawer(true)}
        >
          Export
        </Button>
      )}
      {/* Dropdown menu */}
      {document && !destroying && (
        <DropdownMenu
          triggerButton={
            <Button
              type="button"
              className="ml-auto"
              analyticsTrackingId="post-mortem-actions"
              icon={IconEnum.DotsHorizontal}
              iconProps={{ size: IconSize.Small }}
              title="post-mortem-options"
              size={ButtonSize.Small}
            />
          }
        >
          <DropdownMenuItem
            onSelect={() => destroyDocument({ id: document.id })}
            analyticsTrackingId={"delete-post-mortem"}
            label={"Unlink doc"}
            icon={IconEnum.LinkBreak}
          >
            {`Unlink doc`}
          </DropdownMenuItem>
        </DropdownMenu>
      )}
      <DropdownMenu
        align="end"
        triggerButton={
          <Button
            size={ButtonSize.Small}
            icon={IconEnum.DotsVertical}
            analyticsTrackingId={null}
            title="More"
          />
        }
      >
        <DropdownMenuSubItem
          trigger="Change template"
          icon={IconEnum.Doc}
          disabled={savingTemplate}
        >
          {templateOptions.map((option) => (
            <DropdownMenuItem
              key={option.value}
              analyticsTrackingId={null}
              label={option.label}
              onSelect={() => setTemplate(option.value)}
              disabled={option.value === templateId}
            />
          ))}
        </DropdownMenuSubItem>
      </DropdownMenu>
      {showExportDrawer && (
        <ExportPostmortemDrawer
          incident={incident}
          templateId={templateId}
          onClose={() => setShowExportDrawer(false)}
        />
      )}
    </div>
  );
};

const IncidentPostmortem = ({
  incident,
  templateId,
}: {
  incident: Incident;
  templateId: string;
}): React.ReactElement => {
  const {
    data: templateData,
    isLoading: loadingTemplate,
    error: errorTemplate,
  } = useAPI("postmortemsShowTemplate", {
    id: templateId,
    incidentId: incident.id, // We pass the incident here to resolve the variables in the template text.
  });

  // Also get responses for this template and incident.
  const {
    data: responseData,
    isLoading: loadingResponses,
    error: errorResponses,
  } = useAPI("postmortemsListTemplateResponses", {
    incidentId: incident.id,
    postmortemTemplateId: templateId,
  });

  // Get all custom fields.
  const { data: customFieldData, isLoading: loadingCustomFields } = useAPI(
    "customFieldsList",
    undefined,
    {
      fallbackData: { custom_fields: [] },
    },
  );

  if (loadingTemplate || loadingResponses || loadingCustomFields) {
    return <LoadingBar className="w-full h-28 mt-4" />;
  }

  if (errorTemplate) {
    return <GenericErrorMessage error={errorTemplate} />;
  }

  if (errorResponses) {
    return <GenericErrorMessage error={errorResponses} />;
  }

  if (!templateData) {
    return (
      <GenericErrorMessage
        description={"Could not find post-mortem template"}
      />
    );
  }

  const template = templateData.postmortem_template;

  const orderedSections = template.sections.sort((a, b) => a.rank - b.rank);
  // We also want to continue showing any existing responses for sections that have been deleted from
  // the template.
  const responsesForDeletedSections = responseData?.responses.filter(
    (r) =>
      !orderedSections.some((s) => s.id === r.postmortem_template_section_id),
  );

  return (
    <>
      {orderedSections.map((section) => {
        if (section.type === "summary") {
          return (
            <Summary
              id={section.id}
              key={section.id}
              incident={incident}
              enableExpansion
            />
          );
        }

        if (section.type === "timeline") {
          return (
            <PostmortemTimeline
              id={section.id}
              key={section.id}
              incident={incident}
            />
          );
        }

        if (section.type === "follow_ups") {
          return (
            <PostmortemFollowups
              id={section.id}
              key={section.id}
              incident={incident}
            />
          );
        }

        // Grab the response for this section.
        const existingResponse = responseData?.responses.find(
          (r) => r.postmortem_template_section_id === section.id,
        );

        return (
          <PostmortemCustomSection
            key={section.id}
            incident={incident}
            templateId={template.id}
            section={section}
            existingResponse={existingResponse}
            allCustomFields={customFieldData.custom_fields}
          />
        );
      })}
      {/* We'll show responses for deleted sections at the bottom, because we can no longer trust the rank
      associated with their section config. */}
      {responsesForDeletedSections &&
        responsesForDeletedSections.map((response) => {
          return (
            <PostmortemCustomSection
              key={response.id}
              incident={incident}
              templateId={template.id}
              section={response.section}
              existingResponse={response}
              allCustomFields={customFieldData.custom_fields}
            />
          );
        })}
    </>
  );
};
