import {
  TemplatedTextDisplay,
  TemplatedTextDisplayStyle,
} from "@incident-shared/forms/v1/TemplatedText";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import { STATUS_PAGE_INCIDENT_STATUS_NAME } from "@incident-shared/utils/StatusPages";
import { Button, ButtonSize, ButtonTheme, IconEnum } from "@incident-ui";

import {
  IncidentActivityLogEntry,
  StatusPageIncidentSlim,
  StatusPageIncidentSlimStatusEnum,
  StatusPageIncidentUpdate,
  StatusPageIncidentUpdateToStatusEnum,
} from "../../../contexts/ClientContext";
import { ActivityBadge } from "./ActivityBadge";
import { ActivityItemRenderProps } from "./ActivityItem";

export const ActivityItemStatusPageIncidentUpdated = (
  item: IncidentActivityLogEntry,
): ActivityItemRenderProps => {
  const update = item.content.status_page_incident_updated;
  if (!update) {
    throw new Error(
      "malformed timeline item: status_page_incident_updated was missing status_page_incident_updated field",
    );
  }

  const { status_page_incident: spInc, status_page_incident_update: spUpdate } =
    update;

  let title = "Status page incident updated";
  if (spUpdate.sort_key === 1) {
    title = "Status page incident created";
  } else if (
    spUpdate.to_status === StatusPageIncidentUpdateToStatusEnum.Resolved
  ) {
    title = "Status page incident resolved";
  }

  return {
    icon: IconEnum.StatusPage,
    colour: ColorPaletteEnum.Purple,
    actor: update.updater,
    title: title,
    quotedContent: (
      <StatusPageQuotedContent spInc={spInc} spUpdate={spUpdate} />
    ),
    unquotedContent: <StatusPageUnquotedContent spInc={spInc} />,
  };
};

export const StatusPageQuotedContent = ({
  spInc,
  spUpdate,
}: {
  spInc: StatusPageIncidentSlim;
  spUpdate?: StatusPageIncidentUpdate;
}): React.ReactElement => {
  return (
    <div className="space-y-2">
      <div className="font-semibold">{spInc.name}</div>
      {spUpdate && (
        <TemplatedTextDisplay
          value={spUpdate.message}
          style={TemplatedTextDisplayStyle.Compact}
        />
      )}
      {spUpdate && (
        <ActivityBadge
          icon={
            spInc.status === StatusPageIncidentSlimStatusEnum.Resolved
              ? IconEnum.Checkmark
              : IconEnum.Status
          }
          value={STATUS_PAGE_INCIDENT_STATUS_NAME[spUpdate.to_status]}
        />
      )}
    </div>
  );
};

export const StatusPageUnquotedContent = ({
  spInc,
}: {
  spInc: StatusPageIncidentSlim;
}): React.ReactElement | null => {
  if (!spInc.public_url) {
    return null;
  }

  return (
    <Button
      analyticsTrackingId="view-status-page-inc-from-timeline-item"
      href={spInc.public_url}
      icon={IconEnum.ExternalLink}
      theme={ButtonTheme.Naked}
      size={ButtonSize.Small}
      className={"text-sm-med"}
    >
      View Status Page incident
    </Button>
  );
};
