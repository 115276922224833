import { Incident, IncidentAttachment, Stream } from "@incident-io/api";
import {
  Button,
  ButtonTheme,
  ConfirmationDialog,
  ContentBox,
  IconEnum,
  LoadingBar,
  LoadingWrapper,
  StackedList,
} from "@incident-ui";
import {
  Drawer,
  DrawerBody,
  DrawerContents,
  DrawerTitle,
} from "@incident-ui/Drawer/Drawer";
import _ from "lodash";
import { useState } from "react";
import { useAPIMutation } from "src/utils/swr";

import {
  Attachment,
  AttachmentContexts,
  AttachmentTypeToRenderProps,
} from "../attachments/IncidentAttachment";
import { incidentInEditableStatus } from "../helpers";
import { useAttachments } from "../hooks";
import {
  IncidentSidebarList,
  IncidentSidebarListItemProps,
} from "./IncidentSidebarList";

export function IncidentAttachmentsSidebarList({
  incident,
}: {
  incident: Incident;
}): React.ReactElement | null {
  const { attachments } = useAttachments(incident.id);

  // We'll show up to 3 attachments here, so lets order so that the most recent are first
  const orderedAttachments = attachments.sort((a, b) => {
    return b.created_at.getTime() - a.created_at.getTime();
  });

  // Build the props for each item in the list
  const sidebarItems: IncidentSidebarListItemProps[] = [];

  orderedAttachments.forEach((attachment) => {
    const renderer =
      AttachmentTypeToRenderProps[attachment.resource.resource_type];
    if (!renderer) {
      return;
    }

    const { title, icon } = renderer(attachment.resource);

    sidebarItems.push({
      title: title,
      icon: icon,
      url: attachment.resource.permalink,
    });
  });

  return (
    <IncidentSidebarList
      title="Attachments"
      viewAllUrl={"attachments"}
      items={sidebarItems}
    />
  );
}

export const IncidentAttachmentsDrawer = ({
  incident,
  onClose,
}: {
  incident: Incident;
  onClose: () => void;
}): React.ReactElement | null => {
  const inEditableStatus = incidentInEditableStatus(incident);

  return (
    <>
      <Drawer onClose={onClose} width="medium">
        <DrawerContents>
          <DrawerTitle
            icon={IconEnum.Attachment}
            onClose={onClose}
            title={"Attachments"}
          />
          <DrawerBody className="overflow-y-auto">
            <IncidentAttachmentsInner
              incident={incident}
              inEditableStatus={inEditableStatus}
            />
          </DrawerBody>
        </DrawerContents>
      </Drawer>
    </>
  );
};

export const IncidentAttachmentsInner = ({
  incident,
  inEditableStatus,
}: {
  incident: Incident | Stream;
  inEditableStatus: boolean;
}): React.ReactElement => {
  const { attachments, isLoading: attachmentsLoading } = useAttachments(
    incident.id,
  );

  const [deletingAttachment, setDeletingAttachment] =
    useState<IncidentAttachment | null>(null);

  const { trigger: onRemove, isMutating: isDeleting } = useAPIMutation(
    "incidentAttachmentsList",
    { incidentId: incident.id },
    async (apiClient, attachment: IncidentAttachment) => {
      await apiClient.incidentAttachmentsRemove({ id: attachment.id });
    },
    {
      onSuccess: () => setDeletingAttachment(null),
    },
  );

  if (attachmentsLoading) {
    return <LoadingBar className="h-18" />;
  }

  const groupedAttachments = _.groupBy(
    attachments,
    (x) => x.resource.resource_type,
  );

  return (
    <LoadingWrapper loading={isDeleting} className="space-y-4">
      {Object.entries(groupedAttachments).map(
        ([resourceType, theseAttachments]) => (
          <StackedList key={resourceType}>
            {theseAttachments.map((attachment) => (
              <li className="p-4 flex-center-y" key={attachment.id}>
                <Attachment
                  resource={attachment.resource}
                  key={attachment.id}
                  className="grow"
                  context={AttachmentContexts.RealTime}
                />
                {incident && inEditableStatus ? (
                  <Button
                    title="Remove attachment"
                    analyticsTrackingId="remove-incident-attachment"
                    onClick={() => setDeletingAttachment(attachment)}
                    icon={IconEnum.LinkBreak}
                    theme={ButtonTheme.Tertiary}
                  />
                ) : null}
              </li>
            ))}
          </StackedList>
        ),
      )}
      {deletingAttachment != null && (
        <ConfirmationDialog
          title="Remove Attachment"
          analyticsTrackingId="remove-attachment"
          isOpen={!!deletingAttachment}
          onCancel={() => setDeletingAttachment(null)}
          onConfirm={() => onRemove(deletingAttachment)}
          confirmButtonText="Remove"
        >
          <>
            <span> Are you sure you want to remove this attachment?</span>
            <ContentBox className="p-4 !pt-0 mt-2">
              <Attachment
                resource={deletingAttachment.resource}
                key={deletingAttachment.id}
                className="mt-4"
                context={AttachmentContexts.RealTime}
              />
            </ContentBox>
          </>
        </ConfirmationDialog>
      )}
    </LoadingWrapper>
  );
};
