import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import { Button, ButtonTheme, Modal, ModalContent } from "@incident-ui";

import introducingAlertInsights from "../images/introducing-alert-insights.png";

export const SEEN_INTRODUCING_ALERT_INSIGHTS_MODAL_LOCAL_STORAGE_KEY =
  "seen-introducing-alert-insights-modal";

export const IntroducingAlertInsightsModal = ({
  onClose,
}: {
  onClose: () => void;
}) => {
  const navigate = useOrgAwareNavigate();

  // Set the local storage value to seen.
  window.localStorage.setItem(
    SEEN_INTRODUCING_ALERT_INSIGHTS_MODAL_LOCAL_STORAGE_KEY,
    "seen",
  );

  return (
    <Modal
      isOpen={true}
      analyticsTrackingId={`introducing-alert-insights-modal`}
      hideHeader
      onClose={onClose}
      title="Introducing: Alert Insights"
    >
      <div className={"flex flex-col"}>
        <ModalContent className="!p-0 !m-0">
          {/* Label */}
          <img className="w-full" src={introducingAlertInsights} />

          <div className="flex">
            <div className="mx-20 my-10 flex-col justify-center items-center gap-2 inline-flex">
              <div className="self-stretch text-center text-gray-800 text-2xl font-bold font-['Inter'] leading-9">
                Introducing: Alert Insights
              </div>
              <div className="self-stretch text-center text-sm text-content-primary font-normal font-['Inter']">
                You can now explore your alert data to identify noisy and flaky
                alerts in our new Alert Insights dashboard.
              </div>
            </div>
          </div>
        </ModalContent>
        <div className={"flex flex-center items-center gap-2 mb-6"}>
          <Button
            analyticsTrackingId={null}
            onClick={() => navigate(`/insights/dashboards/core/alerts`)}
            theme={ButtonTheme.Secondary}
          >
            Take me there
          </Button>
          <Button
            analyticsTrackingId={null}
            onClick={onClose}
            theme={ButtonTheme.Primary}
          >
            Continue
          </Button>
        </div>
      </div>
    </Modal>
  );
};
