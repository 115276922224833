import {
  IntegrationSettingsProviderEnum as IntegrationProviderEnum,
  ParameterisedResourceOption,
  ScopeNameEnum,
} from "@incident-io/api";
import { slugForCatalogType } from "@incident-shared/catalog/helpers";
import {
  GatedButton,
  GatedButtonProps,
} from "@incident-shared/gates/GatedButton/GatedButton";
import { IntegrationConfigFor } from "@incident-shared/integrations";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import { ButtonSize, ButtonTheme, IconEnum } from "@incident-ui";
import { SearchableDropdownEntry } from "@incident-ui/SearchableDropdown/SearchableDropdown";
import { SearchableDynamicDropdown } from "@incident-ui/SearchableDropdown/SearchableDynamicDropdown";
import { captureException } from "@sentry/react";
import { ClientType, useClient } from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPI, useAPIMutation } from "src/utils/swr";

import { useCanAddCustomCatalogType } from "../common/useCanAddCustomCatalogType";

export const CatalogHeaderButtons = ({ group }: { group: string }) => {
  const { identity, hasScope } = useIdentity();
  const {
    data: { integrations },
    isLoading: integrationsLoading,
  } = useAPI("catalogListIntegrations", undefined, {
    fallbackData: { integrations: [] },
  });

  const {
    data: { catalog_types: allTypes },
    isLoading: typesLoading,
  } = useAPI(
    "catalogListTypes",
    { includeCount: true },
    { fallbackData: { catalog_types: [] } },
  );

  const catalogTypes = allTypes.filter((type) => {
    return (
      type.mode === "manual" && // Only include manual types
      !type.source_repo_url && // That aren't already managed in GitHub
      !type.annotations["incident.io/catalog-importer/last-sync-at"] // And haven't been imported using the catalog importer
    );
  });

  const {
    upgradeRequired,
    upgradeRequiredProps,
    loading: canAddLoading,
  } = useCanAddCustomCatalogType();

  const hasPermissionsToManageExternally =
    hasScope(ScopeNameEnum.ApiKeysCreate) &&
    hasScope(ScopeNameEnum.CatalogTypesEdit) &&
    hasScope(ScopeNameEnum.CatalogTypesView);

  const navigate = useOrgAwareNavigate();

  const { trigger: onCreateParameterisedType } = useAPIMutation(
    "catalogListTypes",
    {},
    async (apiClient, data: ParameterisedResourceOption) => {
      const catalogType = await apiClient.catalogCreateParameterisedType({
        createParameterisedTypeRequestBody: {
          dynamic_resource_parameter: data.value,
          registry_type: data.registry_type,
        },
      });
      navigate(`/catalog/${slugForCatalogType(catalogType.catalog_type)}`);
    },
  );

  const apiClient = useClient();

  if (integrationsLoading || !identity || canAddLoading || typesLoading) {
    // Our parent should make sure these are loaded before we get rendered here, we don't want
    // to return a loader as there are tonnes of these so you'll get loader-geddon
    return null;
  }

  const disabledTooltipContent = !hasPermissionsToManageExternally
    ? "You don't have permission to manage API keys and catalog types"
    : catalogTypes.length === 0
    ? "All custom types are externally managed"
    : null;

  const hasParameterisedResource = integrations.find(
    (i) => i.provider === group,
  )?.has_parameterised_resources;

  const addButtonStyleProps: Omit<GatedButtonProps, "href"> = {
    size: ButtonSize.Medium,
    analyticsTrackingId: "catalog-add-type",
    requiredScope: ScopeNameEnum.CatalogTypesCreate,
    upgradeRequired,
    upgradeRequiredProps,
  };

  const manageButtonStyleProps: Omit<GatedButtonProps, "href"> = {
    analyticsTrackingId: "catalog-manage-external",
    size: ButtonSize.Medium,
  };

  if (group === "custom") {
    // If you're on a basic or team plan, we only let you create types through the wizards, not via
    // the web UI. We identify this based on the number of custom types being <=2
    const catalogTypeGate = identity.feature_gates.custom_catalog_types_count;
    const hideAddNewButton =
      catalogTypeGate !== undefined && catalogTypeGate <= 2;
    return (
      <div className="flex gap-2">
        <GatedButton
          href="/catalog/manage-in-github"
          {...manageButtonStyleProps}
          icon={IconEnum.Github}
          disabled={
            !hasPermissionsToManageExternally || catalogTypes.length === 0
          }
          theme={ButtonTheme.Secondary}
          disabledTooltipContent={disabledTooltipContent}
        >
          Manage in GitHub
        </GatedButton>
        {!hideAddNewButton && (
          <GatedButton
            href="/catalog/create"
            {...addButtonStyleProps}
            icon={IconEnum.Add}
            theme={ButtonTheme.Primary}
          >
            Add a custom type
          </GatedButton>
        )}
      </div>
    );
  }

  if (hasParameterisedResource) {
    return (
      <SearchableDynamicDropdown<ParameterisedResourceOption>
        loadEntries={getParameterisedResourceOptions({
          apiClient,
          integrationProvider: group,
        })}
        align="end"
        onSelectItem={(item) => onCreateParameterisedType(item)}
        renderTriggerButton={({ onClick }) => (
          <GatedButton
            {...addButtonStyleProps}
            onClick={onClick}
            icon={IconEnum.DownloadCloud}
          >
            Import another type
          </GatedButton>
        )}
        emptyText={`You have no available types to import from ${
          IntegrationConfigFor(group as unknown as IntegrationProviderEnum)
            .label
        }.`}
      />
    );
  }

  return null;
};

const getParameterisedResourceOptions = ({
  integrationProvider,
  apiClient,
}: {
  integrationProvider: string;
  apiClient: ClientType;
}) => {
  return async function (
    inputValue: string,
  ): Promise<SearchableDropdownEntry<ParameterisedResourceOption>[]> {
    try {
      const options = await apiClient.catalogListParameterisedResourceOptions({
        integrationProvider,
        query: inputValue,
      });

      return (
        options.resources.map((resource) => ({
          item: resource,
          label: resource.label,
          sortKey: resource.sort_key,
          // We're only ever going to have one group here, this is just an easy
          // way to display the header in the menu
          group: {
            label: integrationProvider,
            name: integrationProvider,
            sortKey: 0,
          },
        })) || []
      );
    } catch (error) {
      captureException(error);
      console.error(error);
    }
    return Promise.resolve([]);
  };
};
