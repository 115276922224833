import { AlertRoute, AlertSourceConfig, ScopeNameEnum } from "@incident-io/api";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import {
  ButtonSize,
  ButtonTheme,
  Icon,
  IconEnum,
  IconSize,
  SearchBar,
} from "@incident-ui";
import _ from "lodash";
import { useState } from "react";

import { AlertRouteCreateConnectConfirmationModal } from "./AlertRouteCreateConnectConfirmationModal";
import {
  AlertsConfigurationNodeData,
  AlertsConfigurationNodeType,
} from "./AlertsConfigurationNode";

export const AlertRoutesHeader = ({
  alertSources,
  alertRoutes,
  search,
  setSearch,
}: {
  alertSources: AlertSourceConfig[];
  alertRoutes: AlertRoute[];
  search: string;
  setSearch: (string) => void;
}) => {
  return (
    <div className="w-full">
      <div className="flex flex-row items-center justify-between">
        <div className="flex flex-row items-center">
          <Icon id={IconEnum.SplitArrow} size={IconSize.Small} />
          <div className="pl-2 text-base-bold">Routes</div>
        </div>
        <div className="flex flex-row space-x-2">
          <SearchBar
            id="search"
            value={search}
            onChange={setSearch}
            className="w-full"
            inputClassName={"h-7 rounded-md bg-surface-primary shadow-sm"}
            placeholder="Search"
            iconProps={{ size: IconSize.Small }}
          />
          <NewAlertRouteButton
            alertRoutes={alertRoutes}
            alertSources={alertSources}
          />
        </div>
      </div>
      <div className="border-t border-stroke-primary w-full mt-4" />
    </div>
  );
};

const NewAlertRouteButton = ({
  alertRoutes,
  alertSources,
}: {
  alertRoutes: AlertRoute[];
  alertSources: AlertSourceConfig[];
}) => {
  const [showConfirmation, setShowConfirmation] = useState(false);

  // Pick maximum 3 alert routes, ordered by the ones that were most recently active
  const routesToGroupWith = alertRoutes
    .filter((route) => {
      return route.alert_sources.length > 0;
    })
    .sort((a, b) => {
      const aMostRecent =
        _.max([
          a.most_recent_resources_created?.escalation?.created_at,
          a.most_recent_resources_created?.incident?.created_at,
        ]) || new Date(0);

      const bMostRecent =
        _.max([
          b.most_recent_resources_created?.escalation?.created_at,
          b.most_recent_resources_created?.incident?.created_at,
        ]) || new Date(0);

      return bMostRecent.getTime() - aMostRecent.getTime();
    })
    .slice(0, 3);

  const hasGroupableAlertRoutes =
    routesToGroupWith && routesToGroupWith.length > 0;

  const opts = hasGroupableAlertRoutes
    ? { onClick: () => setShowConfirmation(true) }
    : { href: "/alerts/routes/create" };

  return (
    <>
      <GatedButton
        theme={ButtonTheme.Secondary}
        analyticsTrackingId={"add-alert-route"}
        requiredScope={ScopeNameEnum.AlertRouteCreate}
        size={ButtonSize.Small}
        icon={IconEnum.Add}
        iconProps={{ size: IconSize.Small }}
        title=""
        {...opts}
      />
      {showConfirmation ? (
        <AlertRouteCreateConnectConfirmationModal
          onClose={() => setShowConfirmation(false)}
          alertRoutes={routesToGroupWith || []}
          alertSources={alertSources || []}
        />
      ) : null}
    </>
  );
};

export const makeRouteHeaderNodeData = (
  alertSources: AlertSourceConfig[],
  alertRoutes: AlertRoute[],
  searchRoutes: string,
  setSearchRoutes: (string) => void,
): AlertsConfigurationNodeData => {
  return {
    label: "Routes",
    nodeType: AlertsConfigurationNodeType.RoutesHeader,
    searchValue: searchRoutes,
    setSearchValue: setSearchRoutes,
    allAlertSources: alertSources,
    allAlertRoutes: alertRoutes,
  };
};
