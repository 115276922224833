import { Incident, IncidentStatusCategoryEnum, Stream } from "@incident-io/api";
import { numericGateLimitReached } from "@incident-shared/gates/gates";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import {
  Avatar,
  Badge,
  BadgeSize,
  BadgeTheme,
  Button,
  ButtonSize,
  ButtonTheme,
  Icon,
  IconEnum,
  IconSize,
  Tooltip,
} from "@incident-ui";
import { useState } from "react";
import { useIdentity } from "src/contexts/IdentityContext";
import { tcx } from "src/utils/tailwind-classes";

import { useNavigateToModal } from "../../../../../utils/query-params";

export const StreamsSummary = ({
  streams,
  addStream,
  parentIncident,
}: {
  streams: Array<Stream>;
  addStream: () => void;
  parentIncident: Incident;
}) => {
  const [showStreams, setShowStreams] = useState(streams.length < 9);
  const { identity } = useIdentity();

  const streamsEnabled = !numericGateLimitReached(
    identity.feature_gates.streams_per_incident_count,
    streams.length,
  );

  const incIsActive =
    parentIncident.incident_status.category ===
    IncidentStatusCategoryEnum.Active;

  return (
    <div className="space-y-2 flex-col">
      <div className="flex gap-2 items-center">
        {streams.length > 0 && (
          <Button
            theme={ButtonTheme.Unstyled}
            analyticsTrackingId={"toggle-streams-button"}
            className="w-fit"
            onClick={() => setShowStreams(!showStreams)}
          >
            <Icon
              id={showStreams ? IconEnum.ChevronDown : IconEnum.ChevronRight}
              size={IconSize.Small}
              className="text-primary-500"
            />
          </Button>
        )}
        <Button
          theme={ButtonTheme.Naked}
          analyticsTrackingId={"toggle-streams-button"}
          onClick={() => setShowStreams(!showStreams)}
          className="text-content-primary font-semibold"
        >
          Streams
        </Button>

        {showStreams && (
          <div className="flex grow">
            <Tooltip
              side="top"
              align="start"
              content={
                <div className="flex flex-col gap-2">
                  Manage multiple streams of work in separate channels
                </div>
              }
            />
          </div>
        )}
        {streams.length > 0 && !showStreams && (
          <div className="flex grow">
            <Badge
              theme={BadgeTheme.Tertiary}
              size={BadgeSize.ExtraSmall}
              className="w-5 justify-center font-semibold"
            >
              {streams.length}
            </Badge>
          </div>
        )}
        {streamsEnabled && incIsActive && (
          <Tooltip content="Create a new stream" delayDuration={0}>
            <Button
              theme={ButtonTheme.Tertiary}
              analyticsTrackingId={"add-stream-button"}
              onClick={addStream}
              size={ButtonSize.Small}
              icon={IconEnum.Add}
              iconProps={{
                size: IconSize.Small,
                className: "text-primary-300",
              }}
              title="Create a new stream"
            />
          </Tooltip>
        )}
      </div>
      {streams.length > 0 && showStreams && (
        <div className="flex-col border border-stroke rounded-2 divide-y">
          {streams.map((stream) => (
            <StreamSummaryRow
              key={stream.id}
              stream={stream}
              parentIncident={parentIncident}
            />
          ))}
        </div>
      )}
      {streams.length === 0 && (
        <div className="text-content-tertiary">
          There are no streams for this incident
        </div>
      )}
    </div>
  );
};

export const StreamSummaryRow = ({
  stream,
  parentIncident,
  useFullNavigate = false,
}: {
  stream: Stream;
  parentIncident: Incident;
  useFullNavigate?: boolean;
}) => {
  const incidentLead = stream.incident_role_assignments?.find(
    (r) => r.role.role_type === "lead",
  );
  // If we're in a modal, we can't use navigate to modal because the url is already a modal
  const navigate = useOrgAwareNavigate();
  const navigateToDrawer = useNavigateToModal();
  const navigateToStream = () => {
    if (useFullNavigate) {
      return navigate(
        `/incidents/${parentIncident.external_id}/streams/${stream.external_id}`,
      );
    } else {
      return navigateToDrawer(`streams/${stream.external_id}`);
    }
  };

  return (
    <div className="flex p-3 justify-between gap-2">
      <div className="flex items-center gap-1 min-w-0">
        <StreamStatus
          className="mx-2 shrink-0"
          active={stream.status.category === IncidentStatusCategoryEnum.Active}
        />

        <div className="flex min-w-0">
          <Button
            theme={ButtonTheme.Unstyled}
            className="min-w-0 shrink"
            onClick={() => navigateToStream()}
            analyticsTrackingId="view-stream"
          >
            <div
              className={tcx(
                "text-sm-normal truncate",
                stream.user_can_access
                  ? "text-content-primary"
                  : "text-content-tertiary",
              )}
            >
              {stream.name}
            </div>
            {!stream.user_can_access ? (
              <Tooltip
                key={stream.id}
                content="This stream is private, click to request access."
                delayDuration={0}
              >
                <Button
                  theme={ButtonTheme.Unstyled}
                  analyticsTrackingId={"stream-lock-icon"}
                >
                  <Icon
                    id={IconEnum.LockClosed}
                    size={IconSize.Small}
                    className="mx-1 shrink-0 self-center text-content-tertiary"
                  />
                </Button>
              </Tooltip>
            ) : null}
          </Button>
        </div>
      </div>
      <div className="flex gap-1 shrink-0">
        <Button
          theme={ButtonTheme.Secondary}
          analyticsTrackingId={`${stream.id}-slack-button`}
          href={stream.slack_channel_url}
          openInNewTab
          className="h-auto w-auto px-0 p-1 rounded-[6px] shrink-0"
        >
          <Icon
            id={IconEnum.Slack}
            size={IconSize.XS}
            className="text-primary-500"
          />
        </Button>
        <Tooltip
          key={incidentLead?.assignee?.id}
          content={incidentLead?.assignee?.name}
          delayDuration={0}
        >
          <Button
            className={tcx("border border-stroke rounded-full")}
            theme={ButtonTheme.Unstyled}
            analyticsTrackingId={"stream-lead-avatar"}
          >
            <Avatar
              size={IconSize.Medium}
              url={incidentLead?.assignee?.avatar_url}
            />
          </Button>
        </Tooltip>
      </div>
    </div>
  );
};

const StreamStatus = ({
  active,
  className,
  dotClassName,
}: {
  active?: boolean;
  className?: string;
  dotClassName?: string;
}) => {
  return (
    <Tooltip content={active ? "Stream open" : "Stream closed"}>
      <Button theme={ButtonTheme.Unstyled} analyticsTrackingId={null}>
        <div className={tcx(className, "flex relative w-[6px] h-[6px]")}>
          {/* If we're in the firing state, this creates the animated ping behind the solid dot */}
          {active ? (
            <div className="absolute inline-flex w-full h-full rounded-full bg-alarmalade-600 animate-ping-slow" />
          ) : null}
          <div
            className={tcx(
              dotClassName,
              "relative inline-flex w-full h-full rounded-full bg-alarmalade-600",
              {
                "bg-alarmalade-600": active,
                "bg-slate-500": !active,
              },
            )}
          />
        </div>
      </Button>
    </Tooltip>
  );
};
