import { EscalationPreview, EscalationStatusEnum } from "@incident-io/api";
import { prependSlugToPathIfNeeded } from "@incident-shared/org-aware";
import {
  Loader,
  LocalRelativeDateTime,
  StackedList,
  StackedListItem,
  Txt,
} from "@incident-ui";
import { useParams } from "react-router-dom";
import { EscalationStatusIcon } from "src/components/legacy/on-call/escalations/EscalationStatusIcon";

export const EscalationsSection = ({
  escalations,
  escalationsLoading,
}: {
  escalations?: EscalationPreview[];
  escalationsLoading: boolean;
}) => {
  const { slug } = useParams();

  return (
    <div className={"space-y-2"}>
      <Txt className={"font-medium"}>Related escalations</Txt>
      {escalationsLoading && (
        <div
          className={
            "border rounded-2 shadow-sm border-stroke flex items-center justify-center p-2 h-20"
          }
        >
          <Loader />
        </div>
      )}
      {escalations && escalations.length === 0 && (
        <div
          className={
            "border rounded-2 shadow-sm border-stroke flex items-center justify-center p-2 h-20"
          }
        >
          <Txt lightGrey>There are no related escalations</Txt>
        </div>
      )}

      {escalations && (
        <StackedList className={"!w-full"}>
          {escalations.map((escalation) => {
            let path = `/on-call/escalations/${escalation.id}`;
            if (slug) {
              path = prependSlugToPathIfNeeded(path, slug);
            }

            return (
              <StackedListItem
                key={escalation.id}
                rowHref={path}
                className={"truncate"}
                iconNode={
                  <EscalationStatusIcon
                    status={
                      escalation.status as unknown as EscalationStatusEnum
                    }
                    showTooltip={false}
                  />
                }
                title={escalation.title}
                description={
                  <LocalRelativeDateTime
                    date={escalation.created_at}
                    className={"text-slate-600 text-xs"}
                  />
                }
              />
            );
          })}
        </StackedList>
      )}
    </div>
  );
};
