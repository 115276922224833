/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Investigation,
    InvestigationFromJSON,
    InvestigationFromJSONTyped,
    InvestigationToJSON,
} from './Investigation';
import {
    PaginationMeta,
    PaginationMetaFromJSON,
    PaginationMetaFromJSONTyped,
    PaginationMetaToJSON,
} from './PaginationMeta';

/**
 * 
 * @export
 * @interface AIListInvestigationsResponseBody
 */
export interface AIListInvestigationsResponseBody {
    /**
     * 
     * @type {Array<Investigation>}
     * @memberof AIListInvestigationsResponseBody
     */
    investigations: Array<Investigation>;
    /**
     * 
     * @type {PaginationMeta}
     * @memberof AIListInvestigationsResponseBody
     */
    pagination_meta: PaginationMeta;
}

export function AIListInvestigationsResponseBodyFromJSON(json: any): AIListInvestigationsResponseBody {
    return AIListInvestigationsResponseBodyFromJSONTyped(json, false);
}

export function AIListInvestigationsResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AIListInvestigationsResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'investigations': ((json['investigations'] as Array<any>).map(InvestigationFromJSON)),
        'pagination_meta': PaginationMetaFromJSON(json['pagination_meta']),
    };
}

export function AIListInvestigationsResponseBodyToJSON(value?: AIListInvestigationsResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'investigations': ((value.investigations as Array<any>).map(InvestigationToJSON)),
        'pagination_meta': PaginationMetaToJSON(value.pagination_meta),
    };
}

