import {
  TemplatedTextDisplay,
  TemplatedTextDisplayStyle,
} from "@incident-shared/forms/v1/TemplatedText";
import {
  Checkbox,
  DeprecatedTable,
  Modal,
  ModalContent,
  ModalFooter,
} from "@incident-ui";
import {
  GenericStatusBadge,
  GenericStatusBadgeEnum,
} from "@incident-ui/Badge/GenericStatusBadge";
import { useRef, useState } from "react";
import {
  Incident,
  IncidentStatus,
  PostIncidentFlow,
  PostIncidentTaskSlim,
  Settings,
} from "src/contexts/ClientContext";

import { PostIncidentTableHeader } from "./PostIncidentTableHeader";
import { ActionButtons, AssigneeSection } from "./TaskButtons";
import { TaskDueDateBadge } from "./TaskDueDateBadge";

export const PostIncidentList = ({
  incidents,
  statuses,
  refetchIncidents,
  settings,
  flows,
  selectedTasks,
  setSelectedTasks,
}: {
  incidents: Incident[];
  statuses: IncidentStatus[];
  refetchIncidents: () => Promise<void>;
  settings: Settings;
  flows: PostIncidentFlow[];
  selectedTasks: PostIncidentTaskSlim[];
  setSelectedTasks: (taskIDs: PostIncidentTaskSlim[]) => void;
}): React.ReactElement => {
  return (
    <div className="space-y-6 divide-slate-200 mt-4 mb-10">
      {incidents.map((inc: Incident, idx) => {
        return (
          <PostIncidentListItem
            incident={inc}
            refetchIncidents={refetchIncidents}
            statuses={statuses}
            key={idx}
            settings={settings}
            flows={flows}
            selectedTasks={selectedTasks}
            setSelectedTasks={setSelectedTasks}
          />
        );
      })}
    </div>
  );
};

const PostIncidentListItem = ({
  incident,
  refetchIncidents,
  statuses,
  settings,
  flows,
  selectedTasks,
  setSelectedTasks,
}: {
  incident: Incident;
  refetchIncidents: () => Promise<void>;
  statuses: IncidentStatus[];
  settings: Settings;
  flows: PostIncidentFlow[];
  selectedTasks: PostIncidentTaskSlim[];
  setSelectedTasks: (taskIDs: PostIncidentTaskSlim[]) => void;
}) => {
  const tasks = incident.post_incident_tasks;
  if (!tasks) {
    // This shouldn't happen because we filtered out incidents without tasks in the parent component.
    return null;
  }

  // Sort the tasks by status and then by due date
  tasks.sort((a, b) => {
    const aRank = taskToRank(a);
    const bRank = taskToRank(b);
    if (
      aRank === bRank &&
      getTaskStatus(a) === GenericStatusBadgeEnum.Outstanding &&
      a.due_at &&
      b.due_at
    ) {
      return new Date(a.due_at).getTime() - new Date(b.due_at).getTime();
    }
    return aRank - bRank;
  });

  return (
    <DeprecatedTable className="border border-stroke shadow overflow-hidden rounded-2">
      {/* Header */}
      <PostIncidentTableHeader
        incident={incident}
        settings={settings}
        flows={flows}
        selectedTasks={selectedTasks}
        setSelectedTasks={setSelectedTasks}
      />
      {/* Body */}
      <tbody>
        {tasks.map((task, idx) => (
          <PostIncidentItemTask
            task={task}
            refetchIncidents={refetchIncidents}
            statuses={statuses}
            key={idx}
            isSelected={selectedTasks.some(
              (selected) => selected.id === task.id,
            )}
            setSelected={(isSelected: boolean) => {
              if (isSelected) {
                setSelectedTasks([...selectedTasks, task]);
              } else {
                setSelectedTasks(
                  selectedTasks.filter(({ id }) => id !== task.id),
                );
              }
            }}
          />
        ))}
      </tbody>
    </DeprecatedTable>
  );
};

const PostIncidentItemTask = ({
  task,
  refetchIncidents,
  statuses,
  isSelected,
  setSelected,
}: {
  task: PostIncidentTaskSlim;
  refetchIncidents: () => Promise<void>;
  statuses: IncidentStatus[];
  isSelected: boolean;
  setSelected: (isSelected: boolean) => void;
}) => {
  const status = getTaskStatus(task);
  const [showDetailsModal, setShowDetailsModal] = useState(false);

  return (
    <>
      <tr
        key={task.id}
        className="bg-white font-medium truncate grow hover:cursor-pointer hover:bg-slate-50 divide-y divide-slate-200 group h-10"
        onClick={() => {
          setShowDetailsModal(true);
        }}
      >
        <div className="flex items-center justify-between py-1 h-10">
          {/* Key details: task status, name and due date */}
          <td className="flex items-center !py-1 gap-2">
            <Checkbox
              id="select_all"
              checked={isSelected}
              onChange={() => setSelected(!isSelected)}
              disabled={status !== GenericStatusBadgeEnum.Outstanding}
              className="block mr-2"
            />
            <TaskNameAndStatus task={task} />
            <TaskDueDateBadge task={task} statuses={statuses} />
          </td>
          <span className="flex items-center">
            {/* Action buttons */}
            {status === GenericStatusBadgeEnum.Outstanding && (
              <ActionButtons task={task} refetchIncidents={refetchIncidents} />
            )}
            {/* Assignee */}
            <AssigneeSection task={task} refetchIncidents={refetchIncidents} />
          </span>
        </div>
      </tr>
      {showDetailsModal && (
        <TaskDetailsModal
          task={task}
          onClose={() => setShowDetailsModal(false)}
        />
      )}
    </>
  );
};

export const taskStatusToLabel = (value: GenericStatusBadgeEnum): string => {
  switch (value) {
    case GenericStatusBadgeEnum.Outstanding:
      return "Outstanding";
    case GenericStatusBadgeEnum.Completed:
      return "Completed";
    case GenericStatusBadgeEnum.NotDoing:
      return "Skipped";
    default:
      throw new Error("unreachable: unexpected status");
  }
};

export const getTaskStatus = (
  task: Pick<PostIncidentTaskSlim, "completed_at" | "rejected_at">,
): GenericStatusBadgeEnum => {
  if (task.completed_at) {
    return GenericStatusBadgeEnum.Completed;
  } else if (task.rejected_at) {
    return GenericStatusBadgeEnum.NotDoing;
  } else {
    return GenericStatusBadgeEnum.Outstanding;
  }
};

const taskToRank = (task: PostIncidentTaskSlim): number => {
  switch (getTaskStatus(task)) {
    case GenericStatusBadgeEnum.Outstanding:
      // If the due date is set, it should be at the top
      if (task.due_at) {
        return 0;
      }
      return 1;
    case GenericStatusBadgeEnum.Completed:
      return 2;
    case GenericStatusBadgeEnum.NotDoing:
      return 3;
    default:
      throw new Error("unreachable: unexpected status");
  }
};

const TaskNameAndStatus = ({
  task,
}: {
  task: PostIncidentTaskSlim;
}): React.ReactElement => {
  return (
    <div className="flex items-center">
      <GenericStatusBadge
        status={getTaskStatus(task)}
        withLabel
        label={taskStatusToLabel(getTaskStatus(task))}
      />
      <span className="ml-2 text-sm">{task.config.title}</span>
    </div>
  );
};

const TaskDetailsModal = ({
  task,
  onClose,
}: {
  task: PostIncidentTaskSlim;
  onClose: () => void;
}): React.ReactElement => {
  const textRef = useRef<HTMLDivElement>(null);

  return (
    <Modal
      isOpen
      title={task.config.title}
      onClose={onClose}
      analyticsTrackingId={"post-incident-list-view-task"}
    >
      <ModalContent className="mb-4">
        <label className={"font-medium text-content-primary text-sm"}>
          {"Instructions"}
        </label>
        <TemplatedTextDisplay
          ref={textRef}
          style={TemplatedTextDisplayStyle.Compact}
          value={task.config.description}
          className="mt-1 text-sm text-slate-700 overflow-hidden"
        />
      </ModalContent>
      <ModalFooter
        confirmButtonText={"View in incident"}
        confirmButtonType={"button"}
        onConfirm={() =>
          window.open(
            `/incidents/${task.incident_id}?tab=post-incident#task/${task.id}`,
            "_blank",
          )
        }
        onClose={onClose}
        hideConfirmButton={false}
        cancelButtonText="Close"
      />
    </Modal>
  );
};
