import { PostIncidentTask } from "@incident-io/api";
import { Button, ButtonTheme, IconEnum } from "@incident-ui";
import { useAPIMutation } from "src/utils/swr";

export const RejectPostIncidentTaskButton = ({
  incidentTask,
  onSuccess,
  onError,
}: {
  incidentTask: PostIncidentTask;
  onSuccess: (task: PostIncidentTask) => void;
  onError: (error: string) => void;
}): React.ReactElement | null => {
  const { trigger: rejectTask, isMutating: rejectingTaskStatus } =
    useAPIMutation(
      "postIncidentFlowListTasks",
      { incidentId: incidentTask.incident_id },
      async (apiClient) => {
        await apiClient.postIncidentFlowRejectTask({
          id: incidentTask.id,
        });
      },
      {
        onSuccess: (response) => {
          const rejectedTask = response.incident_tasks.find(
            (task) => task.id === incidentTask.id,
          );

          if (rejectedTask) {
            onSuccess(rejectedTask);
          }
        },
        onError: (error: Error) => {
          onError(error.message);
        },
      },
    );

  return (
    <Button
      theme={ButtonTheme.Naked}
      onClick={() => {
        rejectTask({});
      }}
      analyticsTrackingId="post-incident-task-reject"
      analyticsTrackingMetadata={{
        incident_task_id: incidentTask.id,
      }}
      loading={rejectingTaskStatus}
      icon={IconEnum.Close}
      // Make the icon a little closer to the text
      className="!gap-0.5"
    >
      Skip this step
    </Button>
  );
};
