import {
  AvailablePanelPanelTypeEnum,
  PanelPanelTypeEnum,
  ScopeNameEnum,
} from "@incident-io/api";
import { AppliedFiltersBanner, FilterPopover } from "@incident-shared/filters";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { PageWidth, PageWrapper } from "@incident-shared/layout/PageWrapper";
import { OrgAwareNavigate } from "@incident-shared/org-aware";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import { Prompt } from "@incident-shared/utils/Prompt";
import {
  Button,
  ButtonTheme,
  EmptyState,
  GenericErrorMessage,
  IconEnum,
  IconSize,
} from "@incident-ui";
import { FullPageLoader } from "@incident-ui/Loader/Loader";
import { AnimatePresence } from "framer-motion";
import { useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { useLocation, useParams } from "react-router-dom";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPI } from "src/utils/swr";

import { DeleteDashboardModal } from "../common/DeleteDashboardModal";
import { InsightsCustomDashboardEmptyState } from "../common/InsightsCustomDashboardEmptyState";
import { InsightsDatePicker } from "../common/InsightsDatePicker";
import { InsightsPanel } from "../common/InsightsPanel";
import { availablePanelToFormData } from "../common/marshall";
import {
  EditDashboardFormData,
  useInsightsContext,
} from "../common/useInsightsContext";
import { DashboardDetailsFormData } from "./CreateCustomDashboardModal";
import { CustomDashboardAddPanelDrawer } from "./CustomDashboardAddPanelDrawer";
import { EditCustomDashboardDetailsModal } from "./EditCustomDashboardDetailsModal";
import { InsertPanelTile } from "./InsertPanelTile";
import { InsightsEditDashboardProvider } from "./InsightsEditDashboardContext";

export const InsightsCustomDashboardEditRoute = () => {
  const { identity } = useIdentity();
  const insightsFeatureGate = identity?.feature_gates.advanced_insights;

  const { id } = useParams<{ id: string }>() as { id: string };

  const {
    data: dashboardData,
    error: dashboardError,
    isLoading: dashboardLoading,
  } = useAPI("insightsShowCustomDashboard", {
    id,
  });

  if (!insightsFeatureGate) {
    return <OrgAwareNavigate to="/insights" />;
  }

  const error = dashboardError;

  if (error) {
    return <GenericErrorMessage error={error} />;
  }

  if (dashboardLoading || !dashboardData) {
    return <FullPageLoader />;
  }

  return (
    <InsightsEditDashboardProvider dashboard={dashboardData.dashboard}>
      <InsightsCustomDashboardEditPage />
    </InsightsEditDashboardProvider>
  );
};

type AddPanelDrawerState = {
  show: boolean;
  insertPanelAtIdx?: number;
};

const InsightsCustomDashboardEditPage = () => {
  const location = useLocation();
  // This is so that we can pass the openAddDrawer state from InsightsCustomDashboardPage.
  // So as to open the add panel drawer right away when the user clicks on the empty state CTA.
  const initialShowAddPanelDrawer: boolean = location?.state?.openAddDrawer;

  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [addPanelDrawerState, setAddPanelDrawerState] =
    useState<AddPanelDrawerState>({ show: initialShowAddPanelDrawer });
  const [showDeleteDashboardModal, setShowDeleteDashboardModal] =
    useState(false);

  const { id } = useParams<{ id: string }>() as { id: string };

  const formMethods = useFormContext<EditDashboardFormData>();
  const { saving, filtersLoading, filtersError } = useInsightsContext();
  const [name, icon, color, panels] = formMethods.watch([
    "name",
    "icon",
    "color",
    "panels",
  ]);

  const { data: panelsData, error: panelsError } = useAPI(
    "insightsListAvailablePanels",
    undefined,
  );

  const textPanelPrototype = panelsData?.panels.find(
    (panel) => panel.panel_type === AvailablePanelPanelTypeEnum.Text,
  );

  const { insert: insertPanelHandler, append: appendPanelHandler } =
    useFieldArray({
      control: formMethods.control,
      name: "panels",
    });

  if (panelsError) {
    return <GenericErrorMessage description="Unable to fetch panels" />;
  }

  const panelsWithInsertPanelTiles: React.ReactElement[] = panels.flatMap(
    (panel, idx) => {
      return [
        <InsertPanelTile
          key={`insert-panel-tile-${panel.key}`}
          indexToInsert={idx}
          insertPanelHandler={insertPanelHandler}
          openPanelCreateDrawer={() =>
            setAddPanelDrawerState({ show: true, insertPanelAtIdx: idx })
          }
        />,
        <InsightsPanel key={panel.key} panel={panel} panelIdx={idx} />,
      ];
    },
  );

  const onSubmitDetailsModal = (data: DashboardDetailsFormData) => {
    formMethods.setValue<"name">("name", data.name, { shouldDirty: true });
    formMethods.setValue<"icon">("icon", data.icon, { shouldDirty: true });
    formMethods.setValue<"color">("color", data.color, { shouldDirty: true });
    setShowDetailsModal(false);
  };

  const noPanels = panels.length === 0;

  const hasNoFilterablePanel = !panels.some(
    (panel) =>
      panel.panel_type === PanelPanelTypeEnum.ExploDashboard ||
      panel.panel_type === PanelPanelTypeEnum.ExploDashboardWithDrilldown ||
      panel.panel_type === PanelPanelTypeEnum.Trends,
  );

  // Break the page if we fail to fetch the relevant filter fields
  if (filtersError) {
    return (
      <PageWrapper
        width={PageWidth.Wide}
        icon={icon}
        color={color as unknown as ColorPaletteEnum}
        title={name}
        className={noPanels ? "" : "bg-surface-secondary"}
        crumbs={[
          {
            title: "Insights",
            to: "/insights",
          },
        ]}
        backHref="/insights"
        noPadding
        titleAccessory={
          <Button
            icon={IconEnum.Edit}
            onClick={() => setShowDetailsModal(true)}
            title="Edit details"
            analyticsTrackingId={null}
            theme={ButtonTheme.Naked}
          />
        }
      >
        <GenericErrorMessage error={filtersError} />
      </PageWrapper>
    );
  }

  return (
    <>
      <Prompt
        when={
          formMethods.formState.isDirty && !formMethods.formState.isSubmitting
        }
        message={
          "Your changes to your custom dashboard haven't been saved. Are you sure you want to navigate away?"
        }
      />
      <PageWrapper
        dottedBackground={true}
        width={PageWidth.Wide}
        icon={icon}
        color={color as unknown as ColorPaletteEnum}
        title={name}
        className={noPanels ? "" : "bg-surface-secondary"}
        crumbs={[
          {
            title: "Insights",
            to: "/insights",
          },
        ]}
        backHref="/insights"
        noPadding
        titleAccessory={
          <Button
            icon={IconEnum.Edit}
            onClick={() => setShowDetailsModal(true)}
            title="Edit details"
            analyticsTrackingId={null}
            theme={ButtonTheme.Naked}
          />
        }
        accessory={
          <div className="flex items-center gap-2">
            <InsightsDatePicker />
            <FilterPopover
              renderTriggerButton={({ onClick }) => (
                <GatedButton
                  onClick={() => onClick()}
                  analyticsTrackingId={"insights-custom-dashboard-filter"}
                  icon={IconEnum.Filter}
                  iconProps={{
                    size: IconSize.Medium,
                  }}
                  loading={filtersLoading}
                  disabled={filtersError != null || hasNoFilterablePanel}
                  disabledTooltipContent={
                    "You can only add filters to dashboards with panels."
                  }
                >
                  Add filter
                </GatedButton>
              )}
            />
            <GatedButton
              theme={ButtonTheme.DestroySecondary}
              requiredScope={ScopeNameEnum.InsightsCustomDashboardsDestroy}
              analyticsTrackingId="insights-custom-dashboard-delete"
              icon={IconEnum.Delete}
              onClick={() => setShowDeleteDashboardModal(true)}
              title="Delete dashboard"
            />
            <GatedButton
              theme={ButtonTheme.Primary}
              requiredScope={ScopeNameEnum.InsightsCustomDashboardsUpdate}
              analyticsTrackingId="insights-custom-dashboard-save"
              icon={IconEnum.Tick}
              type="submit"
              loading={saving}
            >
              Save changes
            </GatedButton>
          </div>
        }
        banner={
          <AppliedFiltersBanner
            totalNumberOfItems={null}
            itemsLabel={null}
            style="wholeOfPage"
            badgeTheme="slate"
            className="bg-white border-b"
          />
        }
      >
        {noPanels ? (
          <InsightsCustomDashboardEmptyState
            openAddPanelDrawer={() => setAddPanelDrawerState({ show: true })}
          />
        ) : (
          <div className="flex flex-col grow gap-6 p-6">
            {panelsWithInsertPanelTiles}
            <EmptyState
              title={"Looking good!"}
              content={"Enrich your dashboard by adding more charts or text."}
              className="bg-transparent stroke-stroke-hover border-dashed"
              cta={
                <div className="flex gap-4">
                  <Button
                    onClick={() => setAddPanelDrawerState({ show: true })}
                    icon={IconEnum.BarChart}
                    theme={ButtonTheme.Primary}
                    analyticsTrackingId={null}
                  >
                    Add charts
                  </Button>
                  <Button
                    onClick={() =>
                      textPanelPrototype &&
                      appendPanelHandler(
                        availablePanelToFormData(textPanelPrototype),
                      )
                    }
                    icon={IconEnum.Text}
                    theme={ButtonTheme.Secondary}
                    analyticsTrackingId={null}
                  >
                    Add text
                  </Button>
                </div>
              }
            />
          </div>
        )}
      </PageWrapper>
      {showDetailsModal && (
        <EditCustomDashboardDetailsModal
          initialData={{ name, icon, color }}
          onClose={() => setShowDetailsModal(false)}
          onSubmit={onSubmitDetailsModal}
        />
      )}
      {showDeleteDashboardModal && (
        <DeleteDashboardModal
          id={id}
          name={name}
          onClose={() => setShowDeleteDashboardModal(false)}
        />
      )}
      <AnimatePresence>
        {addPanelDrawerState.show && (
          <CustomDashboardAddPanelDrawer
            insertAtIndex={addPanelDrawerState.insertPanelAtIdx}
            onClose={() =>
              setAddPanelDrawerState({
                show: false,
                insertPanelAtIdx: undefined,
              })
            }
          />
        )}
      </AnimatePresence>
    </>
  );
};
