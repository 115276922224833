import "reactflow/dist/style.css";

import { EscalationPathNodeTypeEnum as NodeTypes } from "@incident-io/api";
import { useResize } from "@incident-io/status-page-ui/use-resize";
import { Button, ButtonTheme, Icon, IconEnum, Tooltip } from "@incident-ui";
import _, { max } from "lodash";
import { MouseEvent, useCallback, useEffect, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import ReactFlow, {
  Background,
  BackgroundVariant,
  Controls,
  getNodesBounds,
  Node,
  useEdgesState,
  useNodesState,
  useOnViewportChange,
  useReactFlow,
  Viewport,
} from "reactflow";
import { useAPI } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";

import { EscalationPathFormData } from "../common/types";
import { CustomEdge } from "./CustomEdge";
import { CustomNode } from "./CustomNode";
import { drawEdges } from "./helpers/drawEdges";
import { drawNodes } from "./helpers/drawNodes";
import { useZoomContext } from "./ZoomContext";

const nodeTypes = { customNode: CustomNode };
const edgeTypes = { customEdge: CustomEdge };

const proOptions = { hideAttribution: true };

export const EscalationPathNodeEditor = () => {
  const formMethods = useFormContext<EscalationPathFormData>();
  const firstNodeId = formMethods.watch("firstNodeId");
  const nodes = formMethods.watch("nodes");
  const [reactFlowNodes, setReactFlowNodes, onReactFlowNodesChange] =
    useNodesState([]);
  const [reactFlowEdges, setReactFlowEdges, onReactFlowEdgesChange] =
    useEdgesState([]);

  // Listen for changes to the zoom level, and disable the node inputs if we're
  // zoomed in or out
  const { zoomLevel, setZoomLevel } = useZoomContext();
  useOnViewportChange({
    onEnd: (viewport: Viewport) => {
      setZoomLevel(viewport.zoom);
    },
  });

  const {
    data: { priorities },
  } = useAPI(
    "alertsListPriorities",
    {},
    {
      fallbackData: { priorities: [] },
    },
  );

  const [nodeHash, setNodeHash] = useState<string>(JSON.stringify(nodes));

  // Hash our nodes by just plain old stringifying them. We need to redraw our graph edges
  // every time that our nodes change, so that we update the content on the edge labels
  // when we change conditions. This wasn't happening before as our nodes are an object,
  // and useEffect doesn't deep compare objects - it just looks at whether the ref has
  // changed.
  if (nodeHash !== JSON.stringify(nodes)) {
    setNodeHash(JSON.stringify(nodes));
  }

  useEffect(() => {
    const rfNodes = drawNodes({ firstNodeId: firstNodeId, nodes });
    setReactFlowNodes(rfNodes);
    setReactFlowEdges(drawEdges({ nodes: rfNodes, priorities }));
    // zoomLevel is not a real dependency, but we want to re-render
    // the grid when it changes, to disable or enable the card inputs.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    nodes,
    firstNodeId,
    setReactFlowEdges,
    setReactFlowNodes,
    drawNodes,
    drawEdges,
    zoomLevel,
    nodeHash,
    priorities,
  ]);

  const ref = useRef<HTMLDivElement>(null);
  let { width: containerWidth, height: containerHeight } = useResize(ref);

  // Approximate the width of the container if it is not available.
  const CONTAINER_WIDTH_MULTIPLIER = 0.66;
  if (!containerWidth) {
    containerWidth = window.innerWidth * CONTAINER_WIDTH_MULTIPLIER;
  }

  // Approximate the height of the container if it is not available.
  const CONTAINER_HEIGHT_MULTIPLIER = 0.9;
  if (!containerHeight) {
    containerHeight = window.innerHeight * CONTAINER_HEIGHT_MULTIPLIER;
  }

  const NODE_WIDTH = 400;
  const NODE_HEIGHT = 200;
  const bounds = getNodesBounds(reactFlowNodes);

  // Makes sure that you can't pan the grid too far to the left/top
  // Subtract node size to account for the fact that the node position
  // is the top left hand corner
  const translateExtentTopLeft = {
    x: -(containerWidth / 2) - NODE_WIDTH,
    y: -(containerHeight / 2) - NODE_HEIGHT,
  };

  // If there is a condition node, we want to align the grid to the top left hand corner
  let conditionCount = 0;
  reactFlowNodes.forEach((node) => {
    if (node.data.nodeType === NodeTypes.IfElse) {
      conditionCount++;
    }
  });

  // We need to grow the size of the view downwards and to the right to make sure
  // you can navigate everywhere in the grid.
  const PADDING_MULTIPLIER = 1;

  // The number of condition nodes gives us a good estimate of how wide the grid will be
  // (will always be smaller than this value)
  const HORIZONTAL_EXTENSION =
    (conditionCount + 1) * NODE_WIDTH * PADDING_MULTIPLIER;

  // The bounds of the grid multiplied by 2 gives us a good estimate of
  // how tall the grid will be (will always be smaller than this value)
  const VERTICAL_PADDING = NODE_HEIGHT * PADDING_MULTIPLIER;
  const GRID_HEIGHT =
    (max([bounds.height * 2, containerHeight]) || 0) + NODE_HEIGHT * 2;

  const translateExtentBottomRight = {
    x: containerWidth / 2 + HORIZONTAL_EXTENSION,
    y: GRID_HEIGHT / 2 + VERTICAL_PADDING,
  };

  // Center the grid on the node we've clicked, if we're zoomed in or out
  const { setCenter } = useReactFlow();

  const onNodeClick = useCallback(
    (_: MouseEvent, node: Node) => {
      if (zoomLevel === 1) {
        return;
      }
      const { x, y } = node.position;
      setCenter(x, y, { zoom: 1, duration: 800 });
      setZoomLevel(1);
    },
    [setCenter, setZoomLevel, zoomLevel],
  );

  if (!priorities?.length) {
    return null;
  }

  // If we have an unbranched path, use fitView to easily centre our path.
  // If we have a branched one, instead use defaultViewport to put our start node in the
  // top left of the screen with some padding.
  const positionOptions =
    conditionCount > 0
      ? {
          defaultViewport: { x: 100, y: -25, zoom: 1 },
        }
      : {
          fitView: true,
          fitViewOptions: { minZoom: 1, maxZoom: 1, padding: 200 },
        };

  return (
    <div className="relative h-full w-full bg-slate-50 !mt-0" ref={ref}>
      <ReactFlow
        nodes={reactFlowNodes}
        onNodesChange={onReactFlowNodesChange}
        edges={reactFlowEdges}
        onEdgesChange={onReactFlowEdgesChange}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        defaultEdgeOptions={{
          focusable: false,
          style: {
            cursor: "arrow",
          },
          labelBgStyle: {
            cursor: "arrow",
          },
          labelStyle: {
            cursor: "arrow",
          },
        }}
        selectionKeyCode={null}
        proOptions={proOptions}
        edgesFocusable={false}
        nodesConnectable={false}
        panOnScroll
        panOnDrag
        nodesDraggable={false}
        onNodeClick={onNodeClick}
        translateExtent={[
          [translateExtentTopLeft.x, translateExtentTopLeft.y],
          [translateExtentBottomRight.x, translateExtentBottomRight.y],
        ]}
        {...positionOptions}
        onPaneClick={() => {
          //
        }}
      >
        <ZoomControls />
        <Background
          id="1"
          gap={15}
          size={2}
          color="#e1e6eb"
          variant={BackgroundVariant.Dots}
        />
      </ReactFlow>
    </div>
  );
};

const ZoomControls = () => {
  const { zoomLevel, updateZoom } = useZoomContext();
  const MIN_ZOOM = 0.6;
  const MAX_ZOOM = 2;

  return (
    <Controls
      // Hide all of the default controls, because we want to customise them
      // ourselves
      showFitView={false}
      showInteractive={false}
      showZoom={false}
      className="bg-white rounded-[6px] !p-0.5 flex items-center !b-4"
    >
      <Button
        onClick={() => updateZoom(zoomLevel - 0.1)}
        title="Zoom out"
        className={tcx(
          "bg-white border-none cursor-pointer hover:bg-surface-secondary !rounded-[5px]",
          {
            "hover:bg-white": zoomLevel < MIN_ZOOM,
          },
        )}
        analyticsTrackingId={"escalation-path-zoom-out"}
        theme={ButtonTheme.Unstyled}
        disabled={zoomLevel < MIN_ZOOM}
      >
        <Icon
          id={IconEnum.Minus}
          className={tcx("text-slate-600 w-5 h-5", {
            "text-slate-200": zoomLevel < MIN_ZOOM,
          })}
        />
      </Button>
      <Tooltip content="Reset zoom">
        <div
          onClick={() => updateZoom(1)}
          title="Zoom level"
          className="bg-white text-slate-600 font-medium text-sm w-10 text-center hover:bg-white cursor-pointer"
        >
          {_.round(zoomLevel * 100)}%
        </div>
      </Tooltip>
      <Button
        onClick={() => updateZoom(zoomLevel + 0.1)}
        title="Zoom in"
        className={tcx(
          "bg-white border-none cursor-pointer hover:bg-surface-secondary !rounded-[5px]",
          {
            "hover:bg-white": zoomLevel >= MAX_ZOOM,
          },
        )}
        theme={ButtonTheme.Unstyled}
        disabled={zoomLevel >= MAX_ZOOM}
        analyticsTrackingId={"escalation-path-zoom-in"}
      >
        <Icon
          id={IconEnum.Add}
          className={tcx("text-slate-600 w-5 h-5", {
            "text-slate-200": zoomLevel >= MAX_ZOOM,
          })}
        />
      </Button>
    </Controls>
  );
};
