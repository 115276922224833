import { IncidentStatus, IncidentStatusCategoryEnum } from "@incident-io/api";
import { OrgAwareLink } from "@incident-shared/org-aware";
import { Button, ButtonTheme, Txt } from "@incident-ui";
import { useState } from "react";
import { useAPI } from "src/utils/swr";

import { useIncident } from "../hooks";
import {
  findNextPostIncidentStatus,
  PostIncidentFlowDecision,
} from "../statuses/PostIncidentDecisionRadio";
import { MoveToNextStatusModal } from "./MoveToNextStatusModal";
import { findById } from "./usePostIncidentTaskSelection";

export const EmptyPostIncidentStatusDetails = ({
  statusId,
  incidentId,
  statuses,
}: {
  statusId: string;
  incidentId: string;
  statuses: IncidentStatus[];
}) => {
  const { incident } = useIncident(incidentId);
  const [isShowingModal, setIsShowingModal] = useState<boolean>(false);
  const currentIncidentStatus = incident?.incident_status;

  let status = findById(statuses, statusId);
  const {
    data: { incident_tasks: incidentTasks },
    isLoading: loadingIncidentTasks,
  } = useAPI(
    incident?.id === "" ? null : "postIncidentFlowListTasks",
    { incidentId: incident?.id ?? "" },
    { fallbackData: { incident_tasks: [] } },
  );

  if (loadingIncidentTasks || !incident || !currentIncidentStatus) {
    return null;
  }
  const currentStatusIsArchived = incident.incident_status.archived_at != null;
  if (currentStatusIsArchived && statusId === currentIncidentStatus.id) {
    status = currentIncidentStatus;
  }

  if (!status) {
    // This means the status isn't the current incident status, and isn't in out list of postIncidentFlow tasks
    // so we don't know what to do!
    return null;
  }

  const nextStatus = findNextPostIncidentStatus(
    PostIncidentFlowDecision.MoveOutOfCurrentStatus,
    incident.incident_status,
    statuses,
    incidentTasks,
  );

  const isAdvancable = status?.id === currentIncidentStatus.id;
  return (
    <div className="flex flex-row w-full overflow-hidden">
      <div className="flex flex-col w-full p-6 space-y-4">
        <div className="flex justify-between w-full">
          <div className="flex flex-wrap space-y-1">
            <h4 className="items-center font-semibold text-lg mr-2">
              {status.name}
            </h4>
          </div>
        </div>
        {currentIncidentStatus.category ===
        IncidentStatusCategoryEnum.Closed ? (
          <ClosedPostIncidentTabContent />
        ) : (
          <EmptyPostIncidentTabContent
            isAdvanceable={isAdvancable}
            nextStatus={nextStatus}
            currentStatusIsArchived={currentStatusIsArchived}
            setIsShowingModal={setIsShowingModal}
          />
        )}
        {isShowingModal && nextStatus && (
          <MoveToNextStatusModal
            statusToMoveTo={nextStatus}
            incidentId={incidentId}
            onClose={() => setIsShowingModal(false)}
          />
        )}
      </div>
    </div>
  );
};

const ClosedPostIncidentTabContent = () => {
  return <p>This incident has been closed.</p>;
};

const EmptyPostIncidentTabContent = ({
  isAdvanceable,
  nextStatus,
  setIsShowingModal,
  currentStatusIsArchived,
}: {
  isAdvanceable: boolean;
  nextStatus: IncidentStatus | null;
  setIsShowingModal: (val: boolean) => void;
  currentStatusIsArchived: boolean;
}) => {
  if (currentStatusIsArchived) {
    return (
      <>
        <p>
          This status has been deleted from the post-incident flow. When
          you&apos;re ready, click the button below to move the incident to the{" "}
          <Txt inline bold>
            {nextStatus?.name}
          </Txt>{" "}
          status.
        </p>

        <div className="flex gap-2 items-center">
          <Button
            theme={ButtonTheme.Secondary}
            analyticsTrackingId={"move-to-next-status"}
            onClick={() => setIsShowingModal(true)}
          >
            Continue
          </Button>
        </div>
      </>
    );
  }

  if (isAdvanceable) {
    return (
      <>
        <p>
          You haven&apos;t configured{" "}
          <OrgAwareLink
            to="/settings/lifecycle?tab=lifecycles"
            className="underline cursor-pointer font-bold"
          >
            any tasks
          </OrgAwareLink>{" "}
          for this post-incident status yet. When you&apos;re ready, click the
          button below to move the incident to the{" "}
          <Txt inline bold>
            {nextStatus?.name}
          </Txt>{" "}
          status.
        </p>

        <div className="flex gap-2 items-center">
          <Button
            analyticsTrackingId={"move-to-next-status"}
            onClick={() => setIsShowingModal(true)}
          >
            Continue
          </Button>
        </div>
      </>
    );
  }

  return (
    <p>
      This status did not have any tasks associated with it, and was manually
      marked as complete. You can{" "}
      <OrgAwareLink
        to="/settings/lifecycle?tab=post-incident"
        className="underline cursor-pointer font-bold"
      >
        configure tasks
      </OrgAwareLink>{" "}
      for this status in your lifecycle settings for future incidents.
    </p>
  );
};
