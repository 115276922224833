/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';

export interface IntegrationsGithubHandleInstallRequest {
    organisationId: string;
}

export interface IntegrationsGithubHandleRedirectRequest {
    installationId?: string;
    setupAction?: string;
    state?: string;
}

/**
 * 
 */
export class IntegrationsGithubApi extends runtime.BaseAPI {

    /**
     * Handle Github installation by redirecting to the Github install endpoint
     * HandleInstall Integrations - Github
     */
    async integrationsGithubHandleInstallRaw(requestParameters: IntegrationsGithubHandleInstallRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.organisationId === null || requestParameters.organisationId === undefined) {
            throw new runtime.RequiredError('organisationId','Required parameter requestParameters.organisationId was null or undefined when calling integrationsGithubHandleInstall.');
        }

        const queryParameters: any = {};

        if (requestParameters.organisationId !== undefined) {
            queryParameters['organisation_id'] = requestParameters.organisationId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/auth/github_install`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Handle Github installation by redirecting to the Github install endpoint
     * HandleInstall Integrations - Github
     */
    async integrationsGithubHandleInstall(requestParameters: IntegrationsGithubHandleInstallRequest, initOverrides?: RequestInit): Promise<void> {
        await this.integrationsGithubHandleInstallRaw(requestParameters, initOverrides);
    }

    /**
     * Handle Github redirect
     * HandleRedirect Integrations - Github
     */
    async integrationsGithubHandleRedirectRaw(requestParameters: IntegrationsGithubHandleRedirectRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.installationId !== undefined) {
            queryParameters['installation_id'] = requestParameters.installationId;
        }

        if (requestParameters.setupAction !== undefined) {
            queryParameters['setup_action'] = requestParameters.setupAction;
        }

        if (requestParameters.state !== undefined) {
            queryParameters['state'] = requestParameters.state;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/auth/github`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Handle Github redirect
     * HandleRedirect Integrations - Github
     */
    async integrationsGithubHandleRedirect(requestParameters: IntegrationsGithubHandleRedirectRequest, initOverrides?: RequestInit): Promise<void> {
        await this.integrationsGithubHandleRedirectRaw(requestParameters, initOverrides);
    }

    /**
     * Remove GitHub OAuth token
     * HandleUninstall Integrations - Github
     */
    async integrationsGithubHandleUninstallRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/auth/github`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Remove GitHub OAuth token
     * HandleUninstall Integrations - Github
     */
    async integrationsGithubHandleUninstall(initOverrides?: RequestInit): Promise<void> {
        await this.integrationsGithubHandleUninstallRaw(initOverrides);
    }

}
