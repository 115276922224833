import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import { Button, ButtonSize, ButtonTheme, IconEnum } from "@incident-ui";
import _ from "lodash";
import { IncidentActivityLogEntry } from "src/contexts/ClientContext";

import { ActivityItemRenderProps } from "./ActivityItem";

export const ActivityItemEscalationCreated = (
  item: IncidentActivityLogEntry,
): ActivityItemRenderProps => {
  if (!item.content.escalation_created) {
    throw new Error(
      "malformed timeline item: missing escalation_created field",
    );
  }

  const escalatedTo = item.content.escalation_created.escalated_to_users?.length
    ? _.uniq(
        item.content.escalation_created.escalated_to_users.map((u) => u.name),
      )
    : [];

  const escalationPath = item.content.escalation_created.escalation_path;

  let title = "Incident escalated";
  if (escalatedTo.length > 0) {
    title += " to " + escalatedTo.join(", ");
  }

  return {
    title: title,
    icon: IconEnum.Escalate,
    colour: ColorPaletteEnum.Purple,
    actor: item.content.escalation_created.creator,
    unquotedContent: escalationPath && (
      <Button
        analyticsTrackingId="view-escalation-path-from-timeline-item"
        href={`/on-call/escalation-paths/${escalationPath.id}`}
        icon={IconEnum.ExternalLink}
        theme={ButtonTheme.Naked}
        size={ButtonSize.Small}
      >
        View escalation path
      </Button>
    ),
  };
};
