import { Route, Routes } from "react-router";
import { AIEvalsIndexPage } from "src/components/ai-evals/AIEvalsIndexPage";
import { AIEvalsInvestigationPage } from "src/components/ai-evals/AIEvalsInvestigationPage";
import { AIEvalsThreadPage } from "src/components/ai-evals/AIEvalsThreadPage";
import { NotFoundPage } from "src/components/not-found/NotFoundPage";
import { useIdentity } from "src/contexts/IdentityContext";

export const AIEvalsRoute = () => {
  const { identity } = useIdentity();
  const perms = identity?.staff_permissions ?? [];

  if (perms.length === 0) {
    return <NotFoundPage />;
  }

  return (
    <Routes>
      <Route path="/" element={<AIEvalsIndexPage />} />
      <Route path="/threads/:threadId" element={<AIEvalsThreadPage />} />
      <Route
        path="/investigations/:investigationId"
        element={<AIEvalsInvestigationPage />}
      />
    </Routes>
  );
};
