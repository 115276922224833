import { TranscriptEntry } from "@incident-io/api";
import { Heading, Loader, LocalDateTime } from "@incident-ui";
import useInfiniteScroll from "react-infinite-scroll-hook";

export const CallTranscript = ({
  entries,
  isFullyLoaded,
  isLoading,
  onLoadMore,
}: {
  entries: TranscriptEntry[];
  isFullyLoaded: boolean;
  isLoading: boolean;
  onLoadMore: () => Promise<void>;
}) => {
  const [infiniteScrollRef] = useInfiniteScroll({
    loading: isLoading,
    hasNextPage: !isFullyLoaded,
    onLoadMore,
    // `rootMargin` is passed to `IntersectionObserver`.
    // We can use it to trigger 'onLoadMore' when the sentry gets close to
    // the viewport, instead of becoming fully visible on the screen.
    rootMargin: "0px 0px 100px 0px",
  });

  return (
    <div className="pt-6">
      <Heading level={2} size="medium" className="shrink-0">
        Full transcript
      </Heading>
      <ul className="border-b flex flex-col">
        {entries.map((entry, i) => (
          <TranscriptEntryItem entry={entry} key={i} />
        ))}
        {!isFullyLoaded && (
          <div ref={infiniteScrollRef}>
            <Loader />
          </div>
        )}
      </ul>
    </div>
  );
};

const TranscriptEntryItem = ({ entry }: { entry: TranscriptEntry }) => {
  return (
    <li className="flex flex-col py-4">
      <div className="flex items-center gap-2">
        <div className="text-sm-bold">{entry.speaker_name}</div>
        <LocalDateTime
          timestamp={entry.timestamp}
          format="HH:mm"
          className="text-sm-normal text-content-tertiary"
        />
      </div>
      <div className="text-sm-normal text-content-secondary">
        {entry.content}
      </div>
    </li>
  );
};
