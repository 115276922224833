import _ from "lodash";
import {
  IncidentVisibilityEnum,
  Stream,
  User,
} from "src/contexts/ClientContext";
import { useAPI } from "src/utils/swr";

import { useIncident } from "../hooks";
import { AvatarList, AvatarListClickableType, MaybeUser } from "./AvatarList";

type Participant = User & {
  isNonMember?: boolean;
};

type GroupedParticipants = {
  members: Participant[];
  nonMembers: Participant[];
};

const MAX_PARTICIPANTS_TO_SHOW = 5;

export const Participants = ({
  incidentId,
}: {
  incidentId: string;
}): React.ReactElement => {
  const { incident } = useIncident(incidentId);

  const { data } = useAPI("incidentParticipantsList", {
    incidentId,
  });

  const isPrivate = incident?.visibility === IncidentVisibilityEnum.Private;
  const {
    data: { incident_memberships: memberships },
  } = useAPI(
    isPrivate ? "incidentMembershipsList" : null,
    {
      incidentId,
    },
    { fallbackData: { incident_memberships: [] } },
  );

  let activeParticipants =
    data?.incident_participants.active ?? ([] as Participant[]);
  let observers = data?.incident_participants.passive ?? ([] as Participant[]);

  // If this is a private incident, group the members so non-members are at the bottom of each list
  if (isPrivate) {
    const memberIds = memberships.map((m) => m.user.id);
    activeParticipants = sortMembersAndNonMembers(
      activeParticipants,
      memberIds,
    );
    observers = sortMembersAndNonMembers(observers, memberIds);
  }

  return (
    <div className="flex flex-col space-y-2">
      {activeParticipants.length > 0 && (
        <ParticipantList
          title="Active participants"
          participants={activeParticipants}
        />
      )}
      {observers.length > 0 && (
        <ParticipantList title="Observers" participants={observers} />
      )}
    </div>
  );
};

export const StreamParticipants = ({
  stream,
}: {
  stream: Stream;
}): React.ReactElement => {
  const { data } = useAPI("incidentParticipantsList", {
    incidentId: stream.id,
  });

  const activeParticipants =
    data?.incident_participants.active ?? ([] as Participant[]);
  const observers =
    data?.incident_participants.passive ?? ([] as Participant[]);

  return (
    <div className="flex flex-col space-y-2">
      {activeParticipants.length > 0 && (
        <ParticipantList
          title="Active participants"
          participants={activeParticipants}
        />
      )}
      {observers.length > 0 && (
        <ParticipantList title="Observers" participants={observers} />
      )}
    </div>
  );
};

const ParticipantList = ({
  participants,
  title,
}: {
  participants: Participant[];
  title: string;
}): React.ReactElement => {
  participants = _.sortBy(participants, (p) => p.name);
  return (
    <div className="flex-center-y justify-between mt-1">
      <dt className="text-content-tertiary">{title}</dt>
      <AvatarList
        users={participants.map((p) => convertToMaybeUser(p))}
        modalTitle={title}
        maxToShow={MAX_PARTICIPANTS_TO_SHOW}
        clickableType={AvatarListClickableType.OnlyOnSeeMore}
      />
    </div>
  );
};

const convertToMaybeUser = (p: Participant): MaybeUser => {
  const maybeUser = {} as MaybeUser;
  maybeUser.user = p;
  maybeUser.isNonMember = p.isNonMember;
  return maybeUser;
};

const sortMembersAndNonMembers = (
  users: User[],
  memberIds: string[],
): Participant[] => {
  const { members, nonMembers } = users.reduce(
    (all: GroupedParticipants, user) => {
      if (memberIds.includes(user.id)) {
        all.members.push(user);
      } else {
        all.nonMembers.push({ ...user, isNonMember: true });
      }
      return all;
    },
    { members: [], nonMembers: [] },
  );
  return [...members, ...nonMembers];
};
