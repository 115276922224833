import { DropdownMenu } from "@incident-ui/DropdownMenu/DropdownMenu";
import { Icon, IconEnum } from "@incident-ui/Icon/Icon";
import { tcx } from "src/utils/tailwind-classes";

import {
  Button,
  ButtonSize,
  ButtonTheme,
  ThemeFor,
} from "../../../@ui/Button/Button";
import { GatedButton, GatedButtonProps } from "./GatedButton";

export const PrimaryGatedButtonWithDropdown = ({
  dropdownItems,
  disabled,
  className,
  ...buttonProps
}: {
  dropdownItems: React.ReactNode;
} & GatedButtonProps): React.ReactElement => {
  const theme = ThemeFor[ButtonTheme.Primary];

  return (
    <div
      className={tcx(
        buttonProps.size === ButtonSize.Small ? "rounded" : "rounded-2",
        "text-white flex text-sm",
        theme.button,
        disabled && "bg-slate-400",
      )}
    >
      <GatedButton
        disabled={disabled}
        {...buttonProps}
        theme={ButtonTheme.Primary}
        size={buttonProps.size}
        className={tcx(
          className,
          "shadow-none rounded-r-none bg-transparent pr-2",
        )}
      />
      <div className="py-2">
        <div
          className={tcx(
            "bg-slate-600 h-full w-[0.75px]",
            disabled && "bg-slate-300",
          )}
        />
      </div>
      <DropdownMenu
        align="end"
        triggerButton={
          <Button
            analyticsTrackingId={null}
            theme={ButtonTheme.Primary}
            disabled={disabled}
            size={buttonProps.size}
            className={tcx(
              className,
              "shadow-none px-1 rounded-l-none bg-transparent",
            )}
            title="more options"
          >
            <Icon id={IconEnum.ChevronDown} />
          </Button>
        }
      >
        {dropdownItems}
      </DropdownMenu>
    </div>
  );
};
