import { CatalogTypeCategoriesEnum as CategoriesEnum } from "@incident-io/api";
import { Button, ButtonSize, ButtonTheme, IconEnum } from "@incident-ui";
import { Path, useFormContext } from "react-hook-form";
import { Form } from "src/components/@shared/forms";

import { CatalogTypeCreateEditFormState } from "./types";

export const CATALOG_TYPE_CATEGORY_CONFIG: {
  [key in CategoriesEnum]: {
    label: string;
    icon: IconEnum;
  };
} = {
  [CategoriesEnum.Customer]: {
    label: "Customers",
    icon: IconEnum.Briefcase,
  },
  [CategoriesEnum.IssueTracker]: {
    label: "Issue trackers",
    icon: IconEnum.Action,
  },
  [CategoriesEnum.OnCall]: {
    label: "On-call",
    icon: IconEnum.OnCall,
  },
  [CategoriesEnum.ProductFeature]: {
    label: "Products & features",
    icon: IconEnum.Box,
  },
  [CategoriesEnum.Service]: {
    label: "Services",
    icon: IconEnum.Server,
  },
  [CategoriesEnum.Team]: {
    label: "Teams",
    icon: IconEnum.Users,
  },
  [CategoriesEnum.User]: {
    label: "Users",
    icon: IconEnum.User,
  },
};

export const CatalogTypeCategoryPicker = ({
  name,
}: {
  name: Path<CatalogTypeCreateEditFormState>;
}) => {
  const formMethods = useFormContext();
  const value = formMethods.watch(name) || [];

  const onClick = (category: CategoriesEnum) => {
    const isSelected = value?.includes(category);
    if (isSelected) {
      formMethods.setValue(
        name,
        value.filter((ea) => ea !== category),
      );
    } else {
      formMethods.setValue(name, [...value, category]);
    }
  };

  return (
    <div className="flex flex-col gap-2">
      <Form.Label htmlFor={name} required>
        Categories
      </Form.Label>
      <div className="flex items-center flex-wrap gap-2">
        {Object.entries(CATALOG_TYPE_CATEGORY_CONFIG).map(
          ([category, config]) => {
            const isSelected = value?.includes(category);

            return (
              <CatalogCategoryPill
                key={category}
                selected={isSelected}
                label={config.label}
                onClick={() => onClick(category as CategoriesEnum)}
              />
            );
          },
        )}
      </div>
    </div>
  );
};

const CatalogCategoryPill = ({
  selected,
  label,
  onClick,
}: {
  selected: boolean;
  label: string;
  onClick: () => void;
}) => {
  return (
    <Button
      size={ButtonSize.Small}
      icon={selected ? IconEnum.Tick : IconEnum.Add}
      theme={selected ? ButtonTheme.Primary : ButtonTheme.Tertiary}
      onClick={onClick}
      analyticsTrackingId={null}
    >
      {label}
    </Button>
  );
};
