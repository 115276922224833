import {
  StatusPage,
  StatusPageAffectedComponentPayloadStatusEnum as ComponentStatusEnum,
  StatusPagePageTypeEnum,
} from "@incident-io/api";
import { partition } from "lodash";

import { useAPI } from "../../../../utils/swr";
import { ComponentStatuses } from "../view/AffectedComponentsEditor";

export function useSubPageData(
  page: StatusPage,
  componentStatuses: ComponentStatuses,
  selectedSubPageIDs: string[],
) {
  const { data: affectedPagesData } = useAPI(
    page.page_type !== StatusPagePageTypeEnum.Standalone
      ? "statusPageListNewlyAffectedSubPages"
      : null,
    {
      listNewlyAffectedSubPagesRequestBody: {
        parent_status_page_id: page.id,
        affected_component_ids: Object.keys(componentStatuses).filter(
          (compId) =>
            componentStatuses[compId] !== ComponentStatusEnum.Operational,
        ),
      },
    },
  );
  const { data: availableSubPageData } = useAPI(
    page.page_type !== StatusPagePageTypeEnum.Standalone
      ? "statusPageListSubPages"
      : null,
    {
      parentStatusPageId: page.id,
    },
  );
  const availableSubPages = availableSubPageData?.status_page_sub_pages ?? [];
  const [selectedSubPages, notSelectedSubPages] = partition(
    availableSubPages,
    (page) => selectedSubPageIDs?.includes(page.id) ?? false,
  );

  const { data: catalogListTypes } = useAPI(
    page.split_by_catalog_type_id ? "catalogListTypes" : null,
    {},
  );
  const catalogType = catalogListTypes?.catalog_types.find(
    (type) => type.id === page.split_by_catalog_type_id,
  );

  const affectedPages = affectedPagesData?.status_page_sub_pages ?? [];
  return {
    selectedSubPageIDs,
    selectedSubPages,
    notSelectedSubPages,
    catalogType,
    affectedPages,
  };
}
