import { Icon, IconEnum, IconSize, IncidentStatusBadge } from "@incident-ui";
import { padStart, sortBy } from "lodash";
import React from "react";
import {
  Incident,
  IncidentStatus,
  IncidentStatusCategoryEnum as StatusCategoryEnum,
} from "src/contexts/ClientContext";
import { tcx } from "src/utils/tailwind-classes";

import { EditableBadge } from "./header/EditableBadges";

const CATEGORY_ORDER = [
  StatusCategoryEnum.Triage,
  StatusCategoryEnum.Active,
  StatusCategoryEnum.PostIncident,
  StatusCategoryEnum.Closed,
];

const getStatusState = (
  currentStatus: IncidentStatus,
  status: IncidentStatus,
): StatusState => {
  if (status.id === currentStatus.id) {
    return StatusState.Current;
  }

  // Sort by both category and rank within that category.
  const currentStatusSortKey = `${padStart(
    CATEGORY_ORDER.indexOf(currentStatus.category).toString(),
    6,
    "0",
  )}-${padStart(currentStatus.rank.toString(), 6, "0")}`;

  const statusSortKey = `${padStart(
    CATEGORY_ORDER.indexOf(status.category).toString(),
    6,
    "0",
  )}-${padStart(status.rank.toString(), 6, "0")}`;

  if (statusSortKey < currentStatusSortKey) {
    return StatusState.Past;
  }

  return StatusState.Future;
};

enum StatusState {
  Future,
  Current,
  Past,
}

export const IncidentStatusBar = ({
  incident,
  statuses,
  onEdit,
  className,
}: {
  incident: Incident;
  statuses: IncidentStatus[];
  onEdit?: () => void;
  className?: string;
}): React.ReactElement | null => {
  if (!incident.incident_status) {
    return null;
  }

  const statusCategoriesToShow = [
    StatusCategoryEnum.Active,
    StatusCategoryEnum.PostIncident,
    StatusCategoryEnum.Closed,
    StatusCategoryEnum.Paused,
  ];

  const filteredStatuses = statuses.filter((s) =>
    statusCategoriesToShow.includes(s.category),
  );
  const sortedStatuses = sortBy(filteredStatuses, (s) => [
    statusCategoriesToShow.indexOf(s.category),
    s.rank,
  ]);

  if (!statusCategoriesToShow.includes(incident.incident_status.category)) {
    return null;
  }

  const currentStatus = sortedStatuses.find(
    (x) => x.id === incident.incident_status.id,
  );
  if (!currentStatus) {
    // if you've archived a status, then for now we just render nothing.
    return null;
  }

  const isPaused = currentStatus.category === StatusCategoryEnum.Paused;
  let previousStatus: IncidentStatus | undefined;

  // If paused, show what status it was before pausing
  if (isPaused) {
    previousStatus = incident.paused_in_status;

    // If it's paused, but we can't find the previous status, then render nothing
    if (!previousStatus) {
      return null;
    }
  }

  const statusToDisplay = previousStatus || currentStatus;

  // We show what status an incident was paused from, so don't include paused in the panel
  const statusesToDisplay = sortedStatuses.filter(
    (s) => s.category !== StatusCategoryEnum.Paused,
  );

  // Show up to 5 statuses in a sliding window, not going over the start or end
  // of the array.
  const currentStatusIndex = statusesToDisplay.findIndex(
    (x) => x.id === statusToDisplay.id,
  );
  const lowerIndex = Math.max(currentStatusIndex - 5, 0);
  const upperIndex = Math.min(lowerIndex + 5, statusesToDisplay.length - 1);

  const visibleStatuses = statusesToDisplay.slice(lowerIndex, upperIndex + 1);
  const hasTrimmedStart = lowerIndex > 0;
  const hasTrimmedEnd = upperIndex < statusesToDisplay.length - 1;

  return (
    <EditableBadge onEdit={onEdit}>
      <div
        className={tcx(
          "flex border border-stroke bg-white rounded-[6px] relative h-7",
          className,
        )}
      >
        {hasTrimmedStart && <IncidentStatusEllipses isStart />}
        {visibleStatuses.map((status, index) => {
          return (
            <IncidentStatusItem
              key={status.id}
              status={status}
              hasTrimmedStart={hasTrimmedStart}
              hasTrimmedEnd={hasTrimmedEnd}
              incStatus={statusToDisplay || incident.incident_status}
              index={index}
              currentStatusIndex={currentStatusIndex}
              length={visibleStatuses.length}
              state={getStatusState(statusToDisplay, status)}
              isPaused={isPaused}
            />
          );
        })}
        {hasTrimmedEnd && <IncidentStatusEllipses isStart={false} />}
      </div>
    </EditableBadge>
  );
};

export const IncidentStatusItem = ({
  status,
  state,
  index,
  currentStatusIndex,
  incStatus,
  hasTrimmedStart,
  hasTrimmedEnd,
  length,
  isPaused,
}: {
  hasTrimmedStart: boolean;
  hasTrimmedEnd: boolean;
  status: IncidentStatus;
  state: StatusState;
  index: number;
  currentStatusIndex: number;
  length: number;
  incStatus: IncidentStatus;
  isPaused: boolean;
}): React.ReactElement => {
  const isLast = index === length - 1;
  const isFirst = index === 0;
  const isCurrent = status.id === incStatus.id;

  const distance = index - currentStatusIndex;

  const showEllipsesWhenSmall =
    (distance > 1 && !hasTrimmedEnd) || (distance < -1 && !hasTrimmedStart);
  const shouldHideWhenSmall =
    (distance > 1 || distance < -1) && !showEllipsesWhenSmall;

  const showChevron = !isLast || hasTrimmedEnd;

  return (
    <div
      className={tcx("flex items-center text-content-primary truncate", {
        "hidden lg:flex": shouldHideWhenSmall,
        "max-w-[180px]": !isCurrent,
      })}
    >
      <div
        className={tcx("flex-center-y", {
          "ml-4": isFirst && !hasTrimmedStart,
          "mr-4": isLast && !hasTrimmedEnd,
          "text-content-tertiary": !isCurrent,
          "font-medium": isCurrent,
        })}
      >
        {/* The actual status name */}
        <div
          className={tcx("flex items-center gap-1", {
            "hidden lg:!flex": showEllipsesWhenSmall,
          })}
        >
          <IncidentStatusIcon
            state={state}
            status={status}
            isPaused={isPaused}
          />
          <div
            className={
              isCurrent ? "max-w-[160px] truncate" : "max-w-[120px] truncate"
            }
          >
            {isPaused && isCurrent ? (
              <div>
                <span>Paused</span>{" "}
                <span className="font-normal text-content-tertiary">
                  in {status.name}
                </span>
              </div>
            ) : (
              <span>{status.name}</span>
            )}
          </div>
        </div>
        {/* The ellipses */}
        {showEllipsesWhenSmall && (
          <div className={"lg:hidden"}>
            <p className={"mx-1"}>...</p>
          </div>
        )}
      </div>
      {showChevron && (
        <Icon
          id={IconEnum.ChevronRight}
          size={IconSize.Small}
          className="text-content-tertiary"
        />
      )}
    </div>
  );
};

const IncidentStatusEllipses = ({ isStart }: { isStart: boolean }) => {
  return (
    <div className="flex items-center text-content-primary">
      <div
        className={tcx("flex items-center text-content-tertiary", {
          "ml-4": isStart,
          "mr-4": !isStart,
        })}
      >
        <p className={"mx-1"}>...</p>
      </div>
      {isStart && (
        <Icon
          id={IconEnum.ChevronRight}
          className="text-content-tertiary"
          size={IconSize.Small}
        />
      )}
    </div>
  );
};

const IncidentStatusIcon = ({
  state,
  status,
  isPaused,
}: {
  state: StatusState;
  status: IncidentStatus;
  isPaused?: boolean;
}) => {
  const activeStatusCategories = [
    StatusCategoryEnum.Triage,
    StatusCategoryEnum.Active,
  ];

  const categoryToShow = isPaused ? StatusCategoryEnum.Paused : status.category;

  switch (state) {
    case StatusState.Past:
      return <div />;
    case StatusState.Current:
      return (
        <IncidentStatusBadge
          status={{ ...status, category: categoryToShow }}
          naked
          iconOnly
          className={tcx(
            activeStatusCategories.includes(categoryToShow) && "animate-pulse",
          )}
        />
      );

    case StatusState.Future:
      return <div />;
    default:
      return <div />;
  }
};
