import {
  CopilotInteraction,
  CopilotRequest,
  useAiServiceAiListCopilotRequests,
} from "@incident-io/query-api";
import { PageWidth, PageWrapper } from "@incident-shared/layout/PageWrapper";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import {
  Avatar,
  EmptyState,
  Icon,
  IconEnum,
  IconSize,
  LoadingWrapper,
  LocalDateTime,
} from "@incident-ui";
import { StackedList } from "@incident-ui/StackedList/StackedList";
import _, { last } from "lodash";
import { useState } from "react";
import { useParams } from "react-router";
import { tcx } from "src/utils/tailwind-classes";

import { CopilotInteractionTrace } from "./CopilotInteractionTrace";

export const AIEvalsThreadPage = () => {
  const { threadId } = useParams<{ threadId: string }>();
  const navigate = useOrgAwareNavigate();
  if (!threadId) {
    navigate("/ai-evals");
    return null;
  }

  return (
    <PageWrapper
      icon={IconEnum.Robot}
      width={PageWidth.Wide}
      title="AI evals"
      backHref="/ai-evals"
    >
      <InteractionsList threadId={threadId} />
    </PageWrapper>
  );
};

const InteractionsList = ({ threadId }: { threadId: string }) => {
  const { data, isLoading } = useAiServiceAiListCopilotRequests({
    threadId,
  });

  const requests = data?.requests ?? [];

  const interactions = _.chain(requests)
    .groupBy((req) => req.copilot_interaction?.id)
    .entries()
    .flatMap(([_, requests]) => {
      const interaction = requests[0].copilot_interaction;
      if (!interaction) {
        // Impossible, but convince TypeScript
        return [];
      }

      return [
        {
          ...interaction,
          requests,
        },
      ];
    })
    .sortBy((interaction) => interaction.created_at)
    .value();

  const lastInteraction = last(interactions);

  return (
    <>
      <LoadingWrapper loading={isLoading}>
        {interactions.length === 0 ? (
          <EmptyState icon={IconEnum.Filter} content="No interactions found" />
        ) : (
          <>
            {lastInteraction && <Scoring interaction={lastInteraction} />}
            <StackedList>
              {interactions.map((interaction) => (
                <InteractionRow
                  key={interaction.id}
                  interaction={interaction}
                />
              ))}
            </StackedList>
          </>
        )}
      </LoadingWrapper>
    </>
  );
};

const Scoring = ({ interaction }: { interaction: CopilotInteraction }) => {
  if (!interaction.scores) return null;
  const reasons = interaction.score_reasoning ?? {};

  return (
    <div className="flex flex-wrap gap-2 py-4">
      {Object.entries(interaction.scores).map(
        ([axis, score]: [string, number]) =>
          score > 0 && (
            <div key={axis} className="max-w-prose">
              <div className="flex gap-1">
                <div
                  className={tcx("rounded-full px-1.5 text-white font-medium", {
                    "bg-green-600": score === 5,
                    "bg-green-300": score === 4,
                    "bg-yellow-400": score === 3,
                    "bg-red-400": score === 2,
                    "bg-red-600": score === 1,
                  })}
                >
                  {score.toLocaleString()}
                </div>
                <span className="font-medium capitalize">{axis}</span>
              </div>
              {reasons[axis] && <div>{reasons[axis]}</div>}
            </div>
          ),
      )}
    </div>
  );
};

const InteractionRow = ({
  interaction,
}: {
  interaction: CopilotInteraction & { requests: CopilotRequest[] };
}) => {
  const [expanded, setExpanded] = useState(false);
  const toggle = () => setExpanded((expanded) => !expanded);

  return (
    <div className="flex flex-col gap-4 px-4">
      <div
        className="flex flex-between gap-2 w-full cursor-pointer group"
        onClick={toggle}
      >
        <div className="flex gap-6 py-4">
          <LocalDateTime
            timestamp={interaction.created_at}
            className="font-medium"
          />
          <div className="flex">
            <div className="flex flex-col gap-1">
              <div className="flex">
                {/* Fix the width so it looks aligned without a user */}
                {interaction.from_user && (
                  <Avatar
                    url={interaction.from_user.avatar_url}
                    name={interaction.from_user.name}
                    className="w-6 h-6 mr-1"
                  />
                )}
                <div className="whitespace-pre-wrap">
                  {interaction.source_message}
                </div>
              </div>
              {interaction.response_message && (
                <div className="flex">
                  <Icon
                    id={IconEnum.Robot}
                    className="w-6 h-6 p-1 mr-1 rounded-full bg-slate-50  "
                  />
                  <div className="whitespace-pre-wrap">
                    {interaction.response_message}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="grow" />
        {!!interaction.latency_seconds && (
          <div className="flex items-center gap-0.5">
            <Icon id={IconEnum.Timer} size={IconSize.Small} />
            {interaction.latency_seconds.toFixed(2)}s
          </div>
        )}
        {!!interaction.overall_cost_cents && (
          <div className="flex items-center gap-0.5">
            <Icon id={IconEnum.PiggyBank} size={IconSize.Small} />$
            {(interaction.overall_cost_cents / 100.0).toFixed(2)}
          </div>
        )}
        <Icon
          id={expanded ? IconEnum.Collapse : IconEnum.Expand}
          className="text-slate-600 group-hover:text-slate-900 transition"
        />
      </div>
      {/* Expandable Section */}
      {expanded && <CopilotInteractionTrace requests={interaction.requests} />}
    </div>
  );
};
