import { EscalationPathNodeTypeEnum as NodeTypes } from "@incident-io/api";

import { PathNode } from "../../common/types";

export const getLevelCount = (nodes, firstNodeId, nodeId) => {
  const result = countFromNode(nodes, firstNodeId, nodeId, 0, 0);

  if (!result.found) {
    throw new Error("Unreachable: Node not found in path");
  }

  return result.levelCount;
};

export const getConditionCount = (
  nodes: Record<string, PathNode>,
  firstNodeId: string,
  nodeId: string,
) => {
  // If the first node is an IfElse node, we need to start our condition count at -1
  // as the first condition isn't counted since it's our start node
  const firstNode = nodes[firstNodeId];
  let conditionCount = 0;
  if (firstNode.data.nodeType === NodeTypes.IfElse) {
    conditionCount = -1;
  }

  const result = countFromNode(nodes, firstNodeId, nodeId, 0, conditionCount);

  if (!result.found) {
    throw new Error("Unreachable: Node not found in path");
  }

  return result.conditionCount;
};

// countFromNode returns the current level or condition number for a particular node. This
// is used by the node cards to display "Level 1" or "Condition 2".
// It works by traversing each node, counting levels until it finds the requeested node.
const countFromNode = (
  nodes: Record<string, PathNode>,
  currentNodeId: string,
  requestedNodeId: string,
  levelCount: number,
  conditionCount: number,
): { levelCount: number; conditionCount: number; found: boolean } => {
  const currentNode = nodes[currentNodeId];

  if (currentNode.id === requestedNodeId) {
    return {
      levelCount:
        currentNode.data.nodeType === NodeTypes.Level
          ? levelCount + 1
          : levelCount,
      conditionCount:
        currentNode.data.nodeType === NodeTypes.IfElse
          ? conditionCount + 1
          : conditionCount,
      found: true,
    };
  }

  switch (currentNode.data.nodeType) {
    case NodeTypes.Repeat:
      // we've reached the end of a branch and not found our node, so return false.
      return { levelCount, conditionCount, found: false };
    case NodeTypes.Level:
      if (!currentNode.data.level) {
        throw new Error("Unreachable: level node must have a level data field");
      }

      if (currentNode.data.level.nextNodeId === undefined) {
        return { levelCount, conditionCount, found: false };
      }

      return countFromNode(
        nodes,
        currentNode.data.level.nextNodeId,
        requestedNodeId,
        levelCount + 1,
        conditionCount,
      );
    case NodeTypes.NotifyChannel:
      if (!currentNode.data.notifyChannel) {
        throw new Error(
          "Unreachable: notify channel node must have a notify_channel data field",
        );
      }

      if (currentNode.data.notifyChannel.nextNodeId === undefined) {
        return { levelCount, conditionCount, found: false };
      }

      // We don't increment the level or condition count here.
      return countFromNode(
        nodes,
        currentNode.data.notifyChannel.nextNodeId,
        requestedNodeId,
        levelCount,
        conditionCount,
      );
    case NodeTypes.IfElse:
      if (!currentNode.data.ifElse) {
        throw new Error(
          "Unreachable: condition node must have a if_else data field",
        );
      }

      const thenCount = countFromNode(
        nodes,
        currentNode.data.ifElse.thenNodeId,
        requestedNodeId,
        levelCount,
        conditionCount + 1,
      );
      if (thenCount.found) {
        return thenCount;
      }

      const elseCount = countFromNode(
        nodes,
        currentNode.data.ifElse.elseNodeId,
        requestedNodeId,
        levelCount,
        thenCount.conditionCount,
      );

      if (elseCount.found) {
        return elseCount;
      }

      return {
        levelCount: elseCount.levelCount,
        conditionCount: elseCount.conditionCount,
        found: false,
      };
  }

  throw new Error("Unreachable: Unknown node type");
};
