import {
  FeatureGates,
  IdentityOrganisationAvailableProductsEnum,
  ScopeNameEnum,
  StaffShowOrganisationResponseBodyOrganisationAvailableProductsEnum as StaffProductsEnum,
  TrialStatusPlanNameEnum,
} from "@incident-io/api";
import {
  StaticMultiSelectV2,
  StaticSingleSelectV2,
} from "@incident-shared/forms/v2/inputs/StaticSelectV2";
import {
  Button,
  ButtonSize,
  ButtonTheme,
  Callout,
  CalloutTheme,
  ContentBox,
  IconEnum,
  Modal,
  ModalContent,
  ModalFooter,
  StackedList,
  StaticSingleSelect,
} from "@incident-ui";
import { capitalize } from "lodash";
import { Path, useForm } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import { IdentityOverrides, useIdentity } from "src/contexts/IdentityContext";

import {
  FeatureGateConfig,
  FeatureGateInput,
} from "../staff-room/FeatureGatesForm";

export const OverrideIdentityModal = ({ onClose }: { onClose: () => void }) => {
  const { identity, identityOverrides, setIdentityOverrides } = useIdentity();
  const formMethods = useForm<IdentityOverrides>({
    defaultValues: {
      gates: identityOverrides?.gates,
      products: identity?.organisation_available_products,
      removeScopes: identityOverrides?.removeScopes,
      planName: identityOverrides?.planName,
    },
  });
  const gateOverrides = formMethods.watch("gates");

  const onSubmit = (data) => {
    setIdentityOverrides(data);
    onClose();
  };

  const productOptions = Object.values(
    IdentityOrganisationAvailableProductsEnum,
  ).map((product) => ({
    label: capitalize(product),
    value: product,
  }));

  const scopeOptions = Object.values(ScopeNameEnum).map((scope) => ({
    label: scope,
    value: scope,
  }));

  const planNameOptions = Object.values(TrialStatusPlanNameEnum).map(
    (scope) => ({
      label: scope,
      value: scope,
    }),
  );

  const gateOptions = Object.keys(identity?.feature_gates || {}).map(
    (gate) => ({
      value: gate,
      label: FeatureGateConfig[gate].name,
    }),
  );

  const onAddOverride = (gate: string) => {
    formMethods.setValue("gates", {
      ...gateOverrides,
      [gate]: undefined,
    });
  };

  const onRemoveOverride = (gate: string) => {
    const newOverrides = { ...gateOverrides };
    delete newOverrides[gate];
    formMethods.setValue("gates", newOverrides);
  };

  //   Can't use FormModal as it breaks when combined with the inner form that's in the FeatureGateInput
  return (
    <Modal
      isOpen
      analyticsTrackingId={null}
      title="Override Identity"
      onClose={onClose}
    >
      <ModalContent>
        <Form.Root
          formMethods={formMethods}
          onSubmit={onSubmit}
          id="override-identity"
        >
          <Callout theme={CalloutTheme.Plain}>
            Use this to override parts of your identity for testing purposes -
            e.g. checking how something looks with different permissions or
            products. This will not make any database changes, and is only
            available in development.
          </Callout>
          <StaticMultiSelectV2
            formMethods={formMethods}
            name="products"
            label="Available Products"
            options={productOptions}
          />
          <StaticMultiSelectV2
            formMethods={formMethods}
            name="removeScopes"
            label="Remove scopes"
            helptext="We assume you're an owner with all scopes. Use this to test how the UI looks with fewer scopes."
            options={scopeOptions}
          />
          <StaticSingleSelectV2
            formMethods={formMethods}
            name="planName"
            label="Plan name"
            helptext="Change what identity.trial_status.plan_name returns, which we use in a few places."
            options={planNameOptions}
          />
          <hr />
          <div className="flex flex-col gap-4">
            <div className="text-base-bold">Feature gates</div>
            <Form.Helptext>
              You can temporarily override feature gates to see how the UI looks
              in different states.
            </Form.Helptext>
            <ContentBox className="p-4 bg-surface-secondary flex flex-col gap-4">
              <StackedList>
                {Object.keys(gateOverrides || {}).map((gate) => {
                  return (
                    <div
                      key={gate}
                      className="flex items-center justify-between gap-2 py-3 px-4"
                    >
                      <FeatureGateInput
                        formMethods={formMethods}
                        name={`gates.${gate}` as Path<IdentityOverrides>}
                        gate={gate as keyof FeatureGates}
                        availableProducts={[
                          // When overriding, pretend all products are available
                          StaffProductsEnum.Response,
                          StaffProductsEnum.OnCall,
                        ]}
                        isActive
                      />
                      <Button
                        analyticsTrackingId={null}
                        theme={ButtonTheme.Tertiary}
                        title=""
                        icon={IconEnum.Delete}
                        size={ButtonSize.Small}
                        onClick={() => onRemoveOverride(gate)}
                      />
                    </div>
                  );
                })}
                <div className="flex items-center gap-2 py-3 px-4">
                  <StaticSingleSelect
                    id="new-gate"
                    options={gateOptions}
                    value=""
                    placeholder="Override a gate..."
                    onChange={(g) => onAddOverride(g as string)}
                  />
                </div>
              </StackedList>
            </ContentBox>
          </div>
        </Form.Root>
      </ModalContent>
      <ModalFooter>
        <div className="flex items-center gap-2">
          <Button
            analyticsTrackingId={null}
            theme={ButtonTheme.Secondary}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            analyticsTrackingId={null}
            theme={ButtonTheme.Primary}
            type="submit"
            form="override-identity"
          >
            Save
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};
