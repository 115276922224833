import {
  EscalationPathNodeTypeEnum as NodeTypes,
  Priority,
} from "@incident-io/api";
import { Edge as ReactFlowEdge, Node as ReactFlowNode } from "reactflow";

import { ReactFlowNodeCustomType } from "../../common/types";
import { conditionToLabel } from "../../nodes/IfElseConditionPopover";

export const drawEdges = ({
  nodes,
  priorities,
}: {
  nodes: ReactFlowNode[];
  priorities: Priority[];
}) => {
  const edges: ReactFlowEdge[] = [];

  nodes.forEach((node: ReactFlowNode) => {
    switch (node.data.nodeType) {
      case NodeTypes.Repeat:
        // Repeat nodes don't have any children, so we don't need to do anything here.
        if (!node.data.repeat) {
          throw new Error(
            "Unreachable: repeat node must have a repeat data field",
          );
        }
        break;
      case NodeTypes.Level:
        // Level nodes have a single child, so we add an edge to that child.
        if (node.data.level) {
          edges.push(makeEdge(node, node.data.level.nextNodeId, "Then"));
        } else {
          throw new Error(
            "Unreachable: level node must have a level data field",
          );
        }
        break;
      case NodeTypes.NotifyChannel:
        // Channel nodes have a single child, so we add an edge to that child.
        if (node.data.notifyChannel) {
          edges.push(
            makeEdge(node, node.data.notifyChannel.nextNodeId, "Then"),
          );
        } else {
          throw new Error(
            "Unreachable: notifyChannel node must have a notifyChannel data field",
          );
        }
        break;
      case NodeTypes.IfElse:
        // IfElse nodes have two children so we need to add an edge to each of them
        if (node.data.ifElse) {
          // Add the 'if' branch edge.
          edges.push(
            makeEdge(
              node,
              node.data.ifElse.thenNodeId,
              conditionToLabel(
                node.data.ifElse.conditionType,
                node.data.ifElse.priorityIds,
                priorities,
                false,
              ),
              true,
            ),
          );

          // Add the 'else' branch edge.
          edges.push(
            makeEdge(
              node,
              node.data.ifElse.elseNodeId,
              conditionToLabel(
                node.data.ifElse.conditionType,
                node.data.ifElse.priorityIds,
                priorities,
                true,
              ),
              false,
            ),
          );
        } else {
          throw new Error(
            "Unreachable: condition node must have an if_else data field",
          );
        }
        break;
      case ReactFlowNodeCustomType.Start:
        if (!node.data.start) {
          throw new Error(
            "Unreachable: start node must have a start data field",
          );
        }
        edges.push(makeEdge(node, node.data.start.nextNodeId, "Then"));
        break;
    }
  });

  return edges;
};

const makeEdge = (
  source: ReactFlowNode,
  targetId: string,
  label: string,
  conditionResult?: boolean,
) => {
  return {
    id: `${source.id}-${targetId}`,
    type: "customEdge",
    source: source.id,
    target: targetId,
    data: {
      label: label,
      conditionResult,
    },
  };
};
