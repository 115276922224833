import {
  Button,
  ButtonTheme,
  DropdownMenu,
  DropdownMenuItem,
  Icon,
  IconEnum,
  IconSize,
  Tooltip,
} from "@incident-ui";
import { captureException } from "@sentry/react";
import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { Navigate } from "react-router";
import { tcx } from "src/utils/tailwind-classes";

import {
  CommsPlatform,
  usePrimaryCommsPlatform,
} from "../../../hooks/usePrimaryCommsPlatform";
import { EscalationPathFormData, PathNode } from "../common/types";
import {
  insertAboveNode,
  insertBelowNode,
} from "../node-editor/helpers/insertNode";

// NodeCard is a generic wrapper used to draw the different types of nodes in an escalation path.
export const NodeCard = ({
  nodeId,
  title,
  subtitle,
  icon,
  children,
  allowAddAbove = true,
  allowAddBelow = true,
  hideDeleteButton = false,
  onDeleteNode,
}: {
  nodeId: string;
  title: React.ReactNode;
  subtitle: string;
  icon: React.ReactNode;
  children?: React.ReactNode;
  allowAddAbove?: boolean;
  allowAddBelow?: boolean;
  hideDeleteButton?: boolean;
  onDeleteNode?: () => void;
}) => {
  const [hovering, setHovering] = useState(false);

  return (
    <div
      className="w-[400px] flex-col"
      onMouseEnter={() => {
        setHovering(true);
      }}
      onMouseLeave={() => {
        setHovering(false);
      }}
    >
      {allowAddAbove ? (
        <AddButton nodeId={nodeId} top={true} hovering={hovering} />
      ) : (
        <AddNodePadding top={true} />
      )}

      <div className="rounded-xl bg-white border border-border shadow-sm w-full p-4">
        <div className="flex justify-between items-start">
          <div className="flex gap-3 items-center">
            {icon}
            <div className="h-10 flex-col justify-start items-start gap-1 inline-flex">
              <div className="justify-start items-center gap-1 inline-flex">
                <div className="text-content-secondary text-xs font-medium leading-none">
                  {title}
                </div>
              </div>
              <div className="text-content-primary text-sm font-medium leading-tight">
                {subtitle}
              </div>
            </div>
          </div>
          {/*{onDeleteNode && (*/}
          {!hideDeleteButton ? (
            <Tooltip
              content={onDeleteNode ? null : "There must be at least one level"}
            >
              <Button
                theme={ButtonTheme.Naked}
                icon={IconEnum.Delete}
                iconProps={{
                  size: IconSize.Small,
                  className:
                    "hover:text-content-secondary text-content-tertiary ml-3 mb-2",
                }}
                className={tcx(
                  "h-8 w-8 rounded-[6px] transition-opacity duration-75",
                  hovering ? "opacity-100" : "opacity-0",
                )}
                disabled={!onDeleteNode}
                title="Delete node"
                analyticsTrackingId="delete-node-from-escalation-path"
                onClick={onDeleteNode}
              />
            </Tooltip>
          ) : null}
          {/*)}*/}
        </div>

        {children}
      </div>

      {allowAddBelow ? (
        <AddButton nodeId={nodeId} top={false} hovering={hovering} />
      ) : (
        <AddNodePadding top={false} />
      )}
    </div>
  );
};

const AddNodePadding = ({ top }: { top: boolean }) => (
  <div
    className={tcx("flex h-8 w-fit rounded-0.5 mx-auto", top ? "pb-2" : "pt-2")}
  >
    <div className="w-fit rounded-0.5 mx-auto h-6"></div>
  </div>
);

const AddButton = ({
  nodeId,
  top,
  hovering,
}: {
  nodeId: string;
  top: boolean;
  hovering: boolean;
}) => {
  const formMethods = useFormContext<EscalationPathFormData>();
  const onClick = top ? insertAboveNode : insertBelowNode;

  const nodes = formMethods.watch("nodes");
  const firstNodeId = formMethods.watch("firstNodeId");

  return (
    <div
      className={tcx("flex w-full h-6 justify-center", top ? "mb-2" : "mt-2")}
    >
      <AnimatePresence>
        {hovering ? (
          <motion.div
            initial={{ opacity: 0, translateY: top ? 8 : -8 }}
            animate={{ opacity: 1, translateY: 0 }}
            exit={{ opacity: 0, translateY: top ? 8 : -8 }}
            transition={{ duration: 0.1, ease: "easeInOut" }}
          >
            <Button
              theme={ButtonTheme.Unstyled}
              icon={top ? IconEnum.AddAbove : IconEnum.AddBelow}
              iconProps={{
                size: IconSize.Small,
                className: "fill-slate-500 text-content-tertiary mx-auto",
              }}
              className="bg-white hover:bg-surface-tertiary border-stroke border rounded-[6px] h-6 w-6"
              title={"Add level"}
              analyticsTrackingId={"add-level-to-escalation-path"}
              onClick={() => {
                onClick({
                  node: nodes[nodeId],
                  nodes: nodes,
                  originalFirstNodeId: firstNodeId,
                  updateNodes: (
                    nodes: Record<string, PathNode>,
                    newNodeData,
                  ) => {
                    formMethods.setValue<"nodes">("nodes", nodes);

                    if (newNodeData?.isFirst) {
                      formMethods.setValue<"firstNodeId">(
                        "firstNodeId",
                        newNodeData.node.id,
                      );
                    }
                  },
                });
              }}
            />
          </motion.div>
        ) : (
          <div className="h-full"></div>
        )}
      </AnimatePresence>
    </div>
  );
};

// NodeCardTitleDropdown is the mini-dropdown menu that appears at the top of some cards. It has
// options to convert a card in a level or a condition.
export const NodeCardTitleDropdown = ({
  title,
  onSelectCondition,
  onSelectLevel,
  onSelectRepeat,
  onSelectNotifyChannel,
}: {
  title: string;
  onSelectCondition?: () => void;
  onSelectLevel?: () => void;
  onSelectRepeat?: () => void;
  onSelectNotifyChannel?: () => void;
}) => {
  const primaryCommsPlatform = usePrimaryCommsPlatform();
  if (!primaryCommsPlatform) {
    captureException(
      new Error("No primary comms platform found from identity"),
    );
    return <Navigate to="/setup/login" replace />;
  }

  const doNothing = () => {
    return;
  };

  const onSelectBranch = onSelectCondition ? onSelectCondition : doNothing;

  return (
    <DropdownMenu
      sideOffset={-5}
      align="start"
      triggerButton={
        <Button
          analyticsTrackingId="follow-ups-bulk-actions-button"
          className="text-xs text-slate-600"
          theme={ButtonTheme.Unstyled}
        >
          {title}{" "}
          <Icon
            id={IconEnum.ChevronDown}
            size={IconSize.Small}
            className="-ml-1"
          />
        </Button>
      }
    >
      <DropdownMenuItem
        analyticsTrackingId="escalation-path-node-level-transform-to-level"
        onSelect={onSelectLevel ? onSelectLevel : doNothing}
        label="Level"
      >
        <Icon
          id={IconEnum.Siren}
          size={IconSize.Small}
          className={"fill-slate-900"}
        />{" "}
        <div className={"text-content-primary"}>Level</div>
      </DropdownMenuItem>
      <DropdownMenuItem
        analyticsTrackingId="escalation-path-node-level-transform-to-branch"
        onSelect={onSelectBranch}
        label="Branch"
        className="outline-none"
      >
        <Icon
          id={IconEnum.GitBranch}
          size={IconSize.Small}
          className={"fill-slate-900"}
        />{" "}
        <div className={"text-content-primary"}>Branch</div>
      </DropdownMenuItem>
      {primaryCommsPlatform === CommsPlatform.Slack && (
        <DropdownMenuItem
          analyticsTrackingId="escalation-path-node-transform-to-notify-channel"
          onSelect={onSelectNotifyChannel ? onSelectNotifyChannel : doNothing}
          label="Channel"
          className="outline-none"
        >
          <Icon
            id={IconEnum.SlackGreyscale}
            size={IconSize.Small}
            className={"fill-slate-900"}
          />{" "}
          <div className={"text-content-primary"}>Channel</div>
        </DropdownMenuItem>
      )}
      {onSelectRepeat && (
        <DropdownMenuItem
          analyticsTrackingId="escalation-path-node-level-transform-to-repeat"
          onSelect={onSelectRepeat}
          label="Repeat"
          className="outline-none"
        >
          <Icon
            id={IconEnum.Loop}
            size={IconSize.Small}
            className={"fill-slate-900"}
          />{" "}
          <div className={"text-content-primary"}>Retry</div>
        </DropdownMenuItem>
      )}
    </DropdownMenu>
  );
};

export const LoadingNodeCard = ({
  id,
  title,
  subtitle,
  icon,
  allowAddAbove,
}: {
  id: string;
  title: React.ReactNode;
  subtitle: string;
  icon: React.ReactNode;
  allowAddAbove: boolean;
}) => {
  return (
    <NodeCard
      nodeId={id}
      title={title}
      subtitle={subtitle}
      icon={icon}
      allowAddAbove={allowAddAbove}
      allowAddBelow={false}
    >
      <Button
        theme={ButtonTheme.Secondary}
        icon={IconEnum.Add}
        className="mt-5 !px-2 !h-7 !rounded-[6px] !border-slate-200 !hover:bg-white !hover:border-slate-300"
        title="Add condition"
        analyticsTrackingId={"add-level-to-escalation-path"}
        loading={true}
      >
        Add condition
      </Button>
    </NodeCard>
  );
};
