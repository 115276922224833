import { EnrichedImage } from "@incident-io/api";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import {
  Button,
  ButtonSize,
  ErrorMessage,
  Heading,
  Icon,
  IconEnum,
  LoadingBar,
} from "@incident-ui";
import { FieldValues, Path, PathValue, UseFormReturn } from "react-hook-form";

import { ImageUploadStatus, useImageUpload } from "./useImageUpload";

export const ImageUploader = <TFormType extends FieldValues>({
  formMethods,
  name,
  incidentId,
  imagePreviews,
  setImagePreviews,
  hiddenFileInputRef,
  emptyState,
}: {
  formMethods: UseFormReturn<TFormType>;
  name: Path<TFormType>;
  incidentId?: string;
  imagePreviews: EnrichedImage[];
  setImagePreviews: React.Dispatch<React.SetStateAction<EnrichedImage[]>>;
  hiddenFileInputRef: React.RefObject<HTMLInputElement>;
  emptyState?: (props: { isUploading: boolean }) => React.ReactElement;
}): React.ReactElement => {
  const { setValue, watch } = formMethods;

  const selectedImages = watch(name);
  // Clear out any undefined or non-object values
  const sanitisedImages = selectedImages.filter(
    (item) => item !== undefined && typeof item === "object",
  );

  const { uploadImage, uploadStatus } = useImageUpload({
    formMethods,
    name,
    incidentId,
    hiddenFileInputRef,
  });
  const isUploading = uploadStatus === ImageUploadStatus.Uploading;
  const isError = uploadStatus === ImageUploadStatus.Error;

  const onAddImage = () => {
    // Trigger the system dialog by clicking the hidden file input
    if (hiddenFileInputRef.current) {
      hiddenFileInputRef.current.click();
    }
  };

  const onFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) {
      return;
    }

    const imageId = await uploadImage(file);

    if (imageId) {
      // Add image preview
      setImagePreviews((prev) => [
        ...prev,
        { id: imageId, url: URL.createObjectURL(file), caption: undefined },
      ]);

      // Add the image onto the form
      setValue(
        `${name}.${sanitisedImages.length}.id` as Path<TFormType>,
        imageId as PathValue<TFormType, Path<TFormType>>,
      );
    }
  };

  const onDelete = (imageId: string) => {
    // Remove the image preview
    setImagePreviews((prev) => prev.filter((img) => img.id !== imageId));

    // Remove the image from the form
    setValue(
      name,
      selectedImages.filter((img) => img.id !== imageId),
    );
  };

  const hasImagePreviews = imagePreviews.length > 0;

  return (
    <>
      <div>
        {hasImagePreviews && (
          <div className="flex-center-y justify-between pb-3">
            <Heading level={3} size={"small"}>
              Images
            </Heading>
            <Button
              onClick={onAddImage}
              analyticsTrackingId={"upload-image"}
              icon={IconEnum.Add}
              size={ButtonSize.Small}
              disabled={isUploading}
            >
              Add new
            </Button>
          </div>
        )}
        {/* Hidden file input */}
        <input
          type="file"
          accept="image/*"
          ref={hiddenFileInputRef}
          onChange={onFileChange}
          style={{ display: "none" }}
        />
        {/* List of uploaded images */}
        <div className="space-y-3">
          {imagePreviews.map((img, i) => (
            <ImageRow
              key={img.id}
              image={img}
              index={i}
              formMethods={formMethods}
              imagesPath={name}
              onDelete={onDelete}
            />
          ))}
        </div>
        {isUploading && hasImagePreviews && (
          <LoadingBar className="h-10 mt-2" />
        )}
        {isError && (
          <ErrorMessage message={"Failed to upload image"} className="mt-2" />
        )}
      </div>
      {emptyState && !hasImagePreviews && emptyState({ isUploading })}
    </>
  );
};

const ImageRow = <TFormType extends FieldValues>({
  image,
  index,
  formMethods,
  imagesPath,
  onDelete,
}: {
  image: EnrichedImage;
  index: number;
  formMethods: UseFormReturn<TFormType>;
  imagesPath: Path<TFormType>;
  onDelete: (imageId: string) => void;
}) => {
  const captionPath = `${imagesPath}.${index}.caption` as Path<TFormType>;

  return (
    <div className="flex-center-y gap-3">
      <img
        src={image.url}
        className="w-10 h-10 rounded-2 border border-stroke shadow-button"
      />
      <InputV2
        formMethods={formMethods}
        name={captionPath}
        placeholder="Enter image caption"
        className="grow"
      />
      <Button
        onClick={() => onDelete(image.id)}
        analyticsTrackingId={"delete-image"}
        size={ButtonSize.Small}
        className="h-10 rounded-2"
      >
        <Icon id={IconEnum.Delete} />
      </Button>
    </div>
  );
};
