import {
  StatusPageCreateRequestBody,
  StatusPageDisplayUptimeModeEnum as DisplayUptimeModeEnum,
  StatusPageStructure,
} from "@incident-io/api";
import { Loader } from "@incident-ui";
import _ from "lodash";
import React from "react";
import { useFormContext } from "react-hook-form";
import { useAPI } from "src/utils/swr";

import { PreviewItem } from "../common/ComponentsEditor/utils";
import { BrandingForm, BrandingFormData } from "../settings/edit/BrandingForm";
import {
  AutoCreateComponentForm,
  CatalogImportComponentForm,
  PageWithSubPageCreationForm,
} from "../sub-pages/create/SubPageCreateForm";
export type ParentFormType = Pick<
  StatusPageCreateRequestBody,
  "name" | "subpath"
> &
  BrandingFormData &
  PageWithSubPageCreationForm;

export enum steps {
  BasicSettings = "basic-settings",
  Branding = "branding",
  SubPages = "sub-pages",
  Components = "components",
}

export const SubPageBrandingForm = (): React.ReactElement => {
  const formMethods = useFormContext<ParentFormType>();
  const { watch } = formMethods;
  const autoCreateCatalog = watch("mode");
  if (autoCreateCatalog === "auto_create") {
    return <SubPageAutoCreateBrandingForm />;
  }
  return <SubPageCatalogImportBrandingForm />;
};

const SubPageAutoCreateBrandingForm = (): React.ReactElement => {
  const parentFormMethods = useFormContext<ParentFormType>();
  const componentFormMethods = useFormContext<AutoCreateComponentForm>();
  const name = parentFormMethods.watch("name");
  const subpath = parentFormMethods.watch("subpath");
  const subPages = componentFormMethods.watch("auto_create_sub_pages");
  if (!subPages) {
    throw new Error("No sub pages");
  }
  const firstPageId = Object.keys(subPages)[0];
  const firstPage = subPages[firstPageId];
  const components = componentFormMethods.watch("auto_create_components");
  const componentsForPage = components.filter(
    (component) => firstPage?.components?.includes(component.id),
  );
  const transformComponents = componentsForPage.map((component) => {
    return {
      id: component.id,
      name: component.name,
      description: "",
      // Keep it simple
      displayUptimeChart: false,
      displayUptimePercentage: false,
      hidden: false,
    };
  });
  return (
    <BrandingForm
      name={name}
      subPageName={firstPage.name}
      subpath={subpath}
      previewItems={components ? transformComponents : []}
    />
  );
};

const SubPageCatalogImportBrandingForm = (): React.ReactElement => {
  const parentFormMethods = useFormContext<ParentFormType>();
  const componentFormMethods = useFormContext<CatalogImportComponentForm>();

  const name = parentFormMethods.watch("name");

  const [
    splitByCatalogTypeId,
    splitByComponentAttributeId,
    groupByAttributeId,
    subPages,
  ] = componentFormMethods.watch([
    "split_by_catalog_type_id",
    "components_defined_by_catalog_attribute_id",
    "group_by_defined_by_catalog_attribute_id",
    "catalog_import_sub_pages",
  ]);

  const firstPageCatalogEntryId = subPages
    ? Object.keys(subPages)[0]
    : undefined;
  const firstPage =
    subPages && firstPageCatalogEntryId
      ? subPages[firstPageCatalogEntryId]
      : undefined;

  const { data: catalogEntryData } = useAPI(
    firstPageCatalogEntryId ? "catalogShowEntry" : null,
    {
      id: firstPageCatalogEntryId as string,
    },
  );

  const { data: structureData, isLoading: structureIsLoading } = useAPI(
    firstPage && splitByCatalogTypeId && splitByComponentAttributeId
      ? "statusPageBuildSubPageStructure"
      : null,
    {
      parentSplitByCatalogTypeId: splitByCatalogTypeId as string,
      parentSplitByComponentAttributeId: splitByComponentAttributeId as string,
      parentGroupByCatalogId: groupByAttributeId || undefined,
      definedByCatalogEntryIds: [firstPageCatalogEntryId ?? ""],
    },
  );

  const structure =
    structureData?.sub_page_structures &&
    structureData?.sub_page_structures[firstPageCatalogEntryId ?? ""];

  const previewItems =
    !structureIsLoading && structure ? getParentPreviewItems(structure) : [];

  return structureIsLoading ? (
    <Loader />
  ) : (
    <BrandingForm
      name={name}
      subPageName={catalogEntryData?.catalog_entry.name}
      subpath={_.kebabCase(catalogEntryData?.catalog_entry.name.toLowerCase())}
      previewItems={previewItems}
    />
  );
};

export function getParentPreviewItems(
  structure?: StatusPageStructure,
  displayUptimeMode?: DisplayUptimeModeEnum,
) {
  const showChart = displayUptimeMode !== DisplayUptimeModeEnum.Nothing;
  const showPercentage =
    displayUptimeMode === DisplayUptimeModeEnum.ChartAndPercentage;
  const previewItems: PreviewItem[] = [];
  structure?.items.forEach(({ component, group }) => {
    if (group) {
      previewItems.push({
        id: group.id,
        displayUptimeChart: showChart,
        displayUptimePercentage: showPercentage,
        hidden: false,
        description: group.description || "",
        name: group.name,
        children: group?.components?.map((component) => {
          return {
            id: component.component_id,
            displayUptimeChart: showChart,
            displayUptimePercentage: showPercentage,
            hidden: false,
            name: component.name,
            description: component.description,
          };
        }),
      });
    } else if (component) {
      previewItems.push({
        id: component.component_id,
        displayUptimeChart: showChart,
        displayUptimePercentage: showPercentage,
        hidden: false,
        name: component.name,
        description: component.description,
      });
    }
  });
  return previewItems || [];
}
