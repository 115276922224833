import {
  Badge,
  BadgeTheme,
  Button,
  ButtonTheme,
  Icon,
  IconEnum,
  IconSize,
  Tooltip,
  Txt,
} from "@incident-ui";
import {
  GenericStatusBadge,
  GenericStatusBadgeEnum,
} from "@incident-ui/Badge/GenericStatusBadge";
import { useState } from "react";
import { DismissViolationConfirmationModal } from "src/components/settings/policy/common/PolicyDismissConfirmationModal";
import {
  Policy,
  PolicyViolationLevelEnum,
  ScopeNameEnum,
} from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { tcx } from "src/utils/tailwind-classes";

export const PolicyViolationNotificationBadge = (): React.ReactElement => {
  return (
    <div className="absolute mt-0.5 -right-2 text-xs h-4 w-4 flex rounded-full justify-center bg-alarmalade-600 text-white">
      <Icon size={IconSize.Small} id={IconEnum.ExclamationMark} />
    </div>
  );
};

export const PolicyViolationNotification = ({
  policy,
  resourceName,
  iconOnly = false,
  className,
  level,
  daysUntil,
  violationID,
}: {
  policy: Policy;
  resourceName: string;
  iconOnly?: boolean;
  className?: string;
  level: PolicyViolationLevelEnum;
  daysUntil?: number;
  violationID: string;
}): React.ReactElement => {
  const { hasScope } = useIdentity();
  const canDismissViolations = hasScope(ScopeNameEnum.PolicyViolationsDismiss);

  const [openDismissModal, setOpenDismissModal] = useState(false);

  let tooltipContent;

  if (level === PolicyViolationLevelEnum.Error) {
    tooltipContent = (
      <p className="text-xs-bold text-content-primary">
        This {resourceName} violates a policy
      </p>
    );
  }

  if (level === PolicyViolationLevelEnum.Warning && daysUntil) {
    tooltipContent = (
      <Txt className="!text-xs-bold text-content-primary">
        This {resourceName} will violate a policy in{" "}
        {-daysUntil === 1 ? "1 day" : `${-daysUntil} days`}:
      </Txt>
    );
  }

  const isError = level === PolicyViolationLevelEnum.Error;

  return (
    <>
      <Tooltip
        light
        analyticsTrackingId={null}
        content={
          <div className="p-1 max-w-md gap-2 flex flex-col w-full">
            {tooltipContent}
            <Button
              analyticsTrackingId={null}
              href={`/settings/policies/${policy.id}/view`}
              theme={ButtonTheme.Unstyled}
              className="w-full"
            >
              <Badge
                theme={isError ? BadgeTheme.Unstyled : BadgeTheme.Warning}
                className={tcx("!text-wrap w-full !h-fit p-2 cursor-pointer", {
                  "bg-alarmalade-surface text-alarmalade-600": isError,
                })}
              >
                {policy.name}
                <Icon id={IconEnum.ExternalLink} />
              </Badge>
            </Button>
            {/* Only display dismiss button if it's an error (non-warning) violation and if the user has the permission to do so. */}
            {canDismissViolations && isError && (
              <Button
                analyticsTrackingId={null}
                theme={ButtonTheme.Naked}
                onClick={() => setOpenDismissModal(true)}
              >
                <p className="text-xs-med">Dismiss policy violation</p>
              </Button>
            )}
          </div>
        }
        delayDuration={10}
      >
        {iconOnly ? (
          <GenericStatusBadge
            className={className}
            status={
              isError
                ? GenericStatusBadgeEnum.Violation
                : GenericStatusBadgeEnum.Warning
            }
          />
        ) : (
          <div>
            <Badge
              theme={isError ? BadgeTheme.Unstyled : BadgeTheme.Warning}
              className={tcx(
                isError ? "bg-alarmalade-600 text-white" : undefined,
                className,
              )}
            >
              <Txt>{isError ? "Policy violation" : "Policy warning"}</Txt>
              <Icon
                id={IconEnum.QuestionMark}
                size={IconSize.Small}
                className="ml-1.5"
              />
            </Badge>
          </div>
        )}
      </Tooltip>
      {openDismissModal && (
        <DismissViolationConfirmationModal
          violationId={violationID}
          onClose={() => setOpenDismissModal(false)}
        />
      )}
    </>
  );
};
