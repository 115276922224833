/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StatusPageIncidentUpdatePayload,
    StatusPageIncidentUpdatePayloadFromJSON,
    StatusPageIncidentUpdatePayloadFromJSONTyped,
    StatusPageIncidentUpdatePayloadToJSON,
} from './StatusPageIncidentUpdatePayload';

/**
 * 
 * @export
 * @interface StatusPageCreateRetrospectiveIncidentRequestBody
 */
export interface StatusPageCreateRetrospectiveIncidentRequestBody {
    /**
     * The ID of the template that was applied when sending this update
     * @type {string}
     * @memberof StatusPageCreateRetrospectiveIncidentRequestBody
     */
    created_from_template_id?: string;
    /**
     * A unique key to de-duplicate incidents
     * @type {string}
     * @memberof StatusPageCreateRetrospectiveIncidentRequestBody
     */
    idempotency_key?: string;
    /**
     * A title for the incident
     * @type {string}
     * @memberof StatusPageCreateRetrospectiveIncidentRequestBody
     */
    name: string;
    /**
     * Whether email subscribers should be notified of the incident
     * @type {boolean}
     * @memberof StatusPageCreateRetrospectiveIncidentRequestBody
     */
    notify_subscribers?: boolean;
    /**
     * The ID of the status page this incident is displayed on
     * @type {string}
     * @memberof StatusPageCreateRetrospectiveIncidentRequestBody
     */
    status_page_id: string;
    /**
     * A list of status updates, sorted in descending order by published_at timestamp
     * @type {Array<StatusPageIncidentUpdatePayload>}
     * @memberof StatusPageCreateRetrospectiveIncidentRequestBody
     */
    updates: Array<StatusPageIncidentUpdatePayload>;
}

export function StatusPageCreateRetrospectiveIncidentRequestBodyFromJSON(json: any): StatusPageCreateRetrospectiveIncidentRequestBody {
    return StatusPageCreateRetrospectiveIncidentRequestBodyFromJSONTyped(json, false);
}

export function StatusPageCreateRetrospectiveIncidentRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatusPageCreateRetrospectiveIncidentRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'created_from_template_id': !exists(json, 'created_from_template_id') ? undefined : json['created_from_template_id'],
        'idempotency_key': !exists(json, 'idempotency_key') ? undefined : json['idempotency_key'],
        'name': json['name'],
        'notify_subscribers': !exists(json, 'notify_subscribers') ? undefined : json['notify_subscribers'],
        'status_page_id': json['status_page_id'],
        'updates': ((json['updates'] as Array<any>).map(StatusPageIncidentUpdatePayloadFromJSON)),
    };
}

export function StatusPageCreateRetrospectiveIncidentRequestBodyToJSON(value?: StatusPageCreateRetrospectiveIncidentRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'created_from_template_id': value.created_from_template_id,
        'idempotency_key': value.idempotency_key,
        'name': value.name,
        'notify_subscribers': value.notify_subscribers,
        'status_page_id': value.status_page_id,
        'updates': ((value.updates as Array<any>).map(StatusPageIncidentUpdatePayloadToJSON)),
    };
}

