import {
  AvailablePanel,
  InsightsDateRange,
  InsightsFilter,
  InsightsFilterOperatorEnum,
  Panel,
  PanelFilterContextsEnum,
  PanelPanelTypeEnum,
} from "@incident-io/api";
import { ExtendedFormFieldValue } from "@incident-shared/filters";
import {
  DateRangePickerMode,
  DateRangePickerState,
  QUICK_SELECT_INTERVAL_CONFIG,
  QuickSelectInterval,
} from "@incident-shared/forms/v1/DateRangePicker";
import _ from "lodash";

import { InsightsDateRangeState, PanelFormData } from "./useInsightsContext";

export const panelToFormData = (panel: Panel): PanelFormData => {
  const variables = {};

  if (panel.variables) {
    panel.variables.forEach((variable) => {
      variables[variable.form_field.key] = variable.value;
    });
  }

  return {
    ...panel,
    key: panel.key || panel.name,
    variables_data: variables,
  };
};

export const availablePanelToFormData = (
  panel: AvailablePanel,
): PanelFormData => {
  const {
    explo_dashboard,
    explo_dashboard_with_drilldown,
    key,
    name,
    panel_type,
    text,
    variables,
    filter_contexts,
  } = panel;

  // Set the first option as the default value for each variable,
  // so that each variable in the panel has a chosen option
  const variablesData = {};
  variables.forEach((variable) => {
    if (variable.form_field?.select_config?.options) {
      const firstOption = variable.form_field?.select_config?.options[0];
      variablesData[variable.form_field.key] = {
        label: firstOption?.label,
        value: firstOption?.value,
      };
    }
  });

  return {
    explo_dashboard,
    explo_dashboard_with_drilldown,
    key,
    name,
    panel_type: panel_type as unknown as PanelPanelTypeEnum,
    text,
    variables,
    filter_contexts: _.map(
      filter_contexts,
      (f) => f as unknown as PanelFilterContextsEnum,
    ),
    variables_data: variablesData,
  };
};

export const dateRangeToFormState = (
  dateRange: InsightsDateRange,
): InsightsDateRangeState => {
  let mode = dateRange.mode as unknown as DateRangePickerMode;
  let quickSelect = QuickSelectInterval.Last4Weeks;

  // Is it a quick select range?
  if (dateRange.relative) {
    const data = dateRange.relative;
    Object.entries(QUICK_SELECT_INTERVAL_CONFIG).forEach(
      ([interval, { state }]) => {
        const numMatch = state.numberOfIntervals === data.number_of_intervals;
        const intervalMatch = state.interval === data.interval;
        const includeMatch =
          state.includeThisInterval === data.include_this_interval;

        if (numMatch && intervalMatch && includeMatch) {
          quickSelect = interval as QuickSelectInterval;
          mode = DateRangePickerMode.QuickSelect;
        }
      },
    );
  }

  return {
    is_comparison: dateRange.compare_previous_period,
    aggregation: dateRange.aggregation,
    range: {
      mode,
      quick_select: quickSelect,
      relative: dateRange.relative
        ? {
            interval: dateRange.relative.interval,
            numberOfIntervals: dateRange.relative.number_of_intervals,
            includeThisInterval: dateRange.relative.include_this_interval,
          }
        : undefined,
      absolute: dateRange.absolute,
    } as DateRangePickerState,
  };
};

export const formFieldValueToInsightsFilter = (
  val: ExtendedFormFieldValue,
): InsightsFilter => {
  let values: string[] = [];
  if (val.multiple_options_value) {
    values = val.multiple_options_value;
  } else if (val.single_option_value) {
    values = [val.single_option_value];
  } else if (val.string_value) {
    values = [val.string_value];
  }
  // bool_values is not relevant for Insights filters

  return {
    field_id: val.field_id || val.field_key,
    field_key: val.field_key,
    operator: val.operator as InsightsFilterOperatorEnum,
    values: values,
    typeahead_lookup_id: val.typeahead_lookup_id || undefined,
  };
};

export const insightsFilterToFormFieldValue = (
  filter: InsightsFilter,
): ExtendedFormFieldValue => {
  return {
    key: filter.field_key,
    field_id: filter.field_id,
    field_key: filter.field_key,
    operator: filter.operator,
    bool_value: undefined,
    multiple_options_value: filter.values,
    single_option_value: filter.values[0],
    string_value: filter.values[0],
    filter_id: filter.field_id,
    typeahead_lookup_id: filter.typeahead_lookup_id || filter.field_id,
  };
};
