import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import {
  Button,
  ButtonSize,
  ButtonTheme,
  IconBadge,
  IconEnum,
  IconSize,
} from "@incident-ui";

export type CatalogSetupWidgetProps = {
  title: string;
  description: string;
  learnMoreHref?: string;
  fixButton?: {
    title: string;
  } & (
    | {
        href: string;
        onClick?: never;
      }
    | {
        href?: never;
        onClick: () => void;
      }
  );
  extraNode?: React.ReactNode;
};

export const CatalogSetupWidget = ({
  title,
  description,
  fixButton,
  learnMoreHref,
  extraNode,
}: CatalogSetupWidgetProps) => {
  return (
    <div className="bg-purple-surface flex flex-col gap-5 p-6 rounded-lg w-full">
      <IconBadge
        icon={IconEnum.Book}
        size={IconSize.Large}
        color={ColorPaletteEnum.Purple}
        className="bg-purple-200 rounded-2"
      />
      <div className={"flex flex-col gap-2"}>
        <p className="text-base-bold">{title}</p>
        <p className="text-sm-med">{description}</p>
      </div>
      {extraNode}
      {(fixButton || learnMoreHref) && (
        <div className="flex flex-row justify-start gap-4">
          {fixButton ? (
            <Button
              theme={ButtonTheme.Secondary}
              size={ButtonSize.Medium}
              href={fixButton.href}
              onClick={fixButton.onClick}
              openInNewTab
              analyticsTrackingId="catalog-setup-widget-button"
            >
              {fixButton.title}
            </Button>
          ) : null}
          {learnMoreHref && (
            <Button
              theme={ButtonTheme.Unstyled}
              size={ButtonSize.Medium}
              className="text-purple-content"
              analyticsTrackingId="catalog-setup-widget-button"
              href={learnMoreHref}
            >
              Learn more
            </Button>
          )}
        </div>
      )}
    </div>
  );
};
