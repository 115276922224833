import { Button } from "@incident-ui/Button/Button";
import { ReactElement, ReactNode, useState } from "react";

export const ReadMore = ({
  shownText,
  hidden,
  defaultIsExpanded = false,
  onExpandClick,
  onHideClick,
}: {
  shownText: ReactNode;
  hidden: ReactNode;
  defaultIsExpanded?: boolean;
  onExpandClick?: () => void;
  onHideClick?: () => void;
}): ReactElement => {
  const [showReadMore, setReadMore] = useState(defaultIsExpanded);

  return (
    <div>
      <p>
        {shownText}&nbsp;&nbsp;
        {showReadMore ? null : (
          <Button
            onClick={() => {
              onExpandClick && onExpandClick();
              setReadMore(true);
            }}
            analyticsTrackingId={null}
            className="py-1.5 font-semibold !inline-block"
          >
            Read more ↓
          </Button>
        )}
      </p>

      {showReadMore ? (
        <div className="pt-4">
          {hidden}{" "}
          <Button
            onClick={() => {
              onHideClick && onHideClick();
              setReadMore(false);
            }}
            analyticsTrackingId={null}
            className="font-semibold mt-4 py-1.5"
          >
            Show less ↑
          </Button>
        </div>
      ) : null}
    </div>
  );
};
