import {
  Actor,
  AlertsListPrioritiesResponseBody,
  Escalation,
  EscalationNotification,
  EscalationNotificationNotificationTypeEnum,
  EscalationNotificationTransitionsToStateEnum,
  EscalationPath,
  EscalationPathNodeTypeEnum,
  EscalationTarget,
  EscalationTransition,
  EscalationTransitionReasonEnum,
  EscalationTransitionToStateEnum,
} from "@incident-io/api";
import {
  Badge,
  BadgeSize,
  BadgeTheme,
  ContentBox,
  Icon,
  IconEnum,
  IconSize,
  Link,
  LocalDateTime,
  OrgAwareLink,
  Txt,
} from "@incident-ui";
import _, { uniq } from "lodash";
import React from "react";

import { CopyDebugID } from "../../utils/ShowDebugIDProvider";
import { useAPI } from "../../utils/swr";
import { tcx } from "../../utils/tailwind-classes";
import { joinSpansWithCommasAndConnectorWord } from "../../utils/utils";
import { responseNodesToPathNodes } from "../escalation-paths/common/marshall";
import { EscalationPathConditionType } from "../escalation-paths/common/types";
import { ESCALATION_LABEL } from "../legacy/on-call/escalations/helpers";

type EscalationTimelineRow = {
  id: string;
  timestamp: Date;
  icon: React.ReactNode;
  message: React.ReactNode;
  notifiedTargets?: EscalationTarget[];
};
export const EscalationTimeline = ({
  escalation,
  escalationPath,
}: {
  escalation: Escalation;
  escalationPath?: EscalationPath;
}) => {
  return (
    <div className="flex flex-col gap-2">
      <span className="font-medium text-sm">Timeline</span>
      <ContentBox className="p-6">
        <EscalationTimelineInner
          escalation={escalation}
          escalationPath={escalationPath}
        />
      </ContentBox>
    </div>
  );
};

// This component only shows a notification that has been sent / pending / failed
// Notifications have additional states, which are used by other escalation
// transitions, like showing who ack'd when the escalation transitions
// to acknowledged.
const EscalationTargetComponent = ({
  target,
  isLastTargetOnLastTransition,
}: {
  target: EscalationTarget;
  // isLastTargetOnLastTransition indicates if this target is part of the last
  // transition, in which case we don't want the last bottom bit of divider.
  isLastTargetOnLastTransition: boolean;
}) => {
  if (target.notifications.length === 0) {
    return null;
  }

  // We only care about transitions that are sent, pending, or failed (others
  // display separately). We should _always_ have a pending transition for a
  // target, so we'll need to make sure we discard pending if we have sent
  // or failed. We create a map from state -> notification methods, so that
  // we can render all methods on one line, by state:
  // - Notified Leo via Slack, the app and Email
  // - Failed to notify Leo via SMS
  // - Phone notification to Leo pending

  const notificationMethods = target.notifications.reduce(
    (acc, notification) => {
      const relevantTransition = notification.transitions
        .filter((transition) =>
          [
            EscalationNotificationTransitionsToStateEnum.Sent,
            EscalationNotificationTransitionsToStateEnum.Failed,
            EscalationNotificationTransitionsToStateEnum.Pending,
          ].includes(transition.to_state),
        )
        // We'll sort by the transition timestamp, so that failed/sent transitions
        // take precedence over pending. This means that if our notification sent,
        // our array should end up looking like [sent, pending], but if it's
        // still pending, it will just be [pending], so we can then just grab [0].
        .sort((a, b) => {
          return b.created_at.getTime() - a.created_at.getTime();
        })?.[0];

      if (!relevantTransition) {
        console.error(
          "No relevant transition found for notification",
          notification,
        );
        return acc;
      }

      return {
        ...acc,
        [relevantTransition.to_state]: [
          ...(acc[relevantTransition.to_state] ?? []),
          notification,
        ],
      };
    },
    {} as Record<
      EscalationNotificationTransitionsToStateEnum,
      EscalationNotification[]
    >,
  );

  // We'll also create a map for the words to use for each state
  const ACTION_LABEL = {
    [EscalationNotificationTransitionsToStateEnum.Sent]: "Notified",
    [EscalationNotificationTransitionsToStateEnum.Failed]: "Failed to notify",
    [EscalationNotificationTransitionsToStateEnum.Pending]:
      "Notification pending for",
  };

  const Divider = () => <div className={tcx("flex-1 w-px bg-slate-200")} />;
  return (
    <>
      {
        // We'll render the notification methods in the order of sent, failed, pending
        [
          EscalationNotificationTransitionsToStateEnum.Sent,
          EscalationNotificationTransitionsToStateEnum.Failed,
          EscalationNotificationTransitionsToStateEnum.Pending,
        ].map((state, idx) => {
          if (!notificationMethods[state]) {
            return null;
          }

          return (
            <>
              <div
                key={idx}
                className="col-start-1 flex flex-col justify-center items-center"
              >
                <Divider />
                <div className="w-[7px] h-[7px] my-1 rounded-full bg-slate-100 border border-slate-200"></div>
                {!isLastTargetOnLastTransition && idx === 2 && <Divider />}
              </div>
              <p className="text-sm text-slate-700" key={state}>
                {ACTION_LABEL[state]}{" "}
                {target.user_id && (
                  <span>
                    {" "}
                    <UserLabel
                      userId={target.user_id}
                      className="text-slate-800 font-medium"
                    />{" "}
                    via{" "}
                    {joinSpansWithCommasAndConnectorWord(
                      notificationMethods[state]?.map((notification) => (
                        <span
                          key={notification.id}
                          className="text-slate-800 font-medium"
                        >
                          {notificationMethodLabel(
                            notification.notification_type,
                          )}
                        </span>
                      )),
                    )}
                    <CopyDebugID id={target.user_id} />
                  </span>
                )}
                {target.slack_channel_url && target.slack_channel_name && (
                  <span>
                    <SlackChannelLabel
                      slackChannelURL={target.slack_channel_url}
                      slackChannelName={target.slack_channel_name}
                    />
                  </span>
                )}
              </p>
            </>
          );
        })
      }
    </>
  );
};

const Divider = ({ condensed = false }: { condensed?: boolean }) => (
  <div
    className={tcx(
      "flex-1 col-start-1 w-px mx-auto bg-slate-200",
      condensed ? "min-h-3" : "min-h-8",
    )}
  />
);

const EscalationTimelineInner = ({
  escalation,
  escalationPath,
}: {
  escalation: Escalation;
  escalationPath?: EscalationPath;
}) => {
  const { data: priorities } = useAPI("alertsListPriorities", {});

  const timeline: EscalationTimelineRow[] = [];

  // If we have acked transitions, then we want to hide the resolved transitions
  const hasAcked =
    (escalation.transitions || [])?.filter((transition) => {
      return transition.to_state === EscalationTransitionToStateEnum.Acked;
    }).length > 0;

  escalation.transitions
    ?.filter((transition) => {
      return (
        transition.to_state !== EscalationTransitionToStateEnum.Resolved ||
        !hasAcked
      );
    })
    .forEach((transition) => {
      if (isTransitionVisible(transition)) {
        timeline.push({
          id: transition.id,
          timestamp: new Date(transition.occurred_at),
          icon: transitionIcon(transition),
          message: transitionMessage(
            escalation,
            transition,
            priorities?.priorities ?? [],
            escalationPath,
          ),
        });
      }

      // If the transition has any targets, it means the transition triggered notifications, so
      // we'll:
      // 1. Create a new timeline row for "notified level x"
      // 2. add the pending/sent notifications as `notifiedTargets` to the timeline item
      // 3. add the acked/nacked notifications as separate timeline items.
      if (transition.targets?.length) {
        timeline.push({
          id: `${transition.id}-notified`,
          timestamp: new Date(transition.occurred_at),
          icon: (
            <>
              {isTransitionForChannelTarget({ transition }) ? (
                <IconWithBackground
                  id={IconEnum.Slack}
                  iconSize={IconSize.Small}
                  iconClassName="fill-current text-brand"
                  className="bg-neutral-100"
                />
              ) : (
                <IconWithBackground
                  id={IconEnum.Siren}
                  iconSize={IconSize.Small}
                  iconClassName="fill-current text-brand"
                  className="bg-alarmalade-100"
                />
              )}
            </>
          ),
          message: (
            <MainTransitionMessage
              transition={transition}
              escalationPath={escalationPath}
            />
          ),
          notifiedTargets: transition.targets,
        });

        // We'll also add the acked/nacked notifications as separate timeline items
        transition.targets.forEach((target) => {
          target.notifications?.forEach((notification) => {
            notification.transitions.forEach((notificationTransition) => {
              // We can't have channel targets that have nacked, so we just check for user_id here.
              if (
                notificationTransition.to_state ===
                  EscalationNotificationTransitionsToStateEnum.Nacked &&
                target.user_id
              ) {
                timeline.push({
                  id: `${notification.id}-nacked`,
                  timestamp: new Date(notificationTransition.created_at),
                  icon: (
                    <IconWithBackground
                      id={IconEnum.CloseCircle}
                      iconSize={IconSize.Small}
                      iconClassName="text-red-500"
                      className="bg-red-50"
                    />
                  ),
                  message: (
                    <>
                      <UserLabel
                        userId={target.user_id}
                        className="text-slate-800 font-medium"
                      />{" "}
                      responded as unavailable
                    </>
                  ),
                });
              }
            });
          });
        });
      }
    });

  return (
    <div className="grid grid-cols-[auto_1fr] gap-x-4">
      {timeline.map((row, idx) => (
        <div
          className="grid grid-cols-subgrid col-span-2 items-center"
          key={row.id}
        >
          <div className="col-start-1 flex flex-col items-center py-1">
            {row.icon}
          </div>
          <Txt>
            {row.message}
            <LocalDateTime
              format="HH:mm"
              timestamp={row.timestamp}
              className="text-slate-500 ml-2 text-xs"
            />
          </Txt>
          {/* if this is not the last transition, we want to add a line on the next row, to connect the icons */}
          {idx !== (timeline?.length ?? 0) - 1 && <Divider />}
          {row.notifiedTargets?.map((target, targetIdx) => (
            <div key={target.id} className="grid grid-cols-subgrid col-span-2">
              <EscalationTargetComponent
                isLastTargetOnLastTransition={
                  idx === (timeline?.length ?? 0) - 1 &&
                  targetIdx === (row.notifiedTargets?.length ?? 0) - 1
                }
                target={target}
              />
              {/* if this is not the last transition and last target, we want to add a line on the next row, to connect the icons */}
              {(idx !== (timeline?.length ?? 0) - 1 ||
                targetIdx !== (row.notifiedTargets?.length ?? 0) - 1) && (
                <Divider
                  condensed={
                    targetIdx !== (row.notifiedTargets?.length ?? 0) - 1
                  }
                />
              )}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};
const IconWithBackground = ({
  id,
  iconSize,
  className,
  iconClassName,
}: {
  id: IconEnum;
  iconSize?: IconSize;
  className?: string;
  iconClassName?: string;
}) => {
  return (
    <div
      className={tcx(
        "rounded h-7 w-7 flex items-center justify-center",
        className,
      )}
    >
      <Icon
        id={id}
        size={iconSize ?? IconSize.Small}
        className={tcx("text-white", iconClassName)}
      />
    </div>
  );
};
const escalationTransitionIcon: Record<
  EscalationTransitionToStateEnum,
  React.ReactNode
> = {
  [EscalationTransitionToStateEnum.Pending]: (
    <IconWithBackground
      id={IconEnum.Timer}
      iconClassName="text-blue-500"
      className="bg-blue-surface"
    />
  ),
  [EscalationTransitionToStateEnum.Triggered]: (
    <IconWithBackground
      id={IconEnum.Flag}
      className="bg-blue-surface"
      iconClassName={"text-blue-500"}
    />
  ),
  [EscalationTransitionToStateEnum.Expired]: (
    <IconWithBackground
      id={IconEnum.CloseCircle}
      iconClassName="text-slate-500"
      className="bg-slate-50"
    />
  ),
  [EscalationTransitionToStateEnum.Cancelled]: (
    <IconWithBackground
      id={IconEnum.CloseCircle}
      iconClassName="text-slate-500"
      className="bg-slate-50"
    />
  ),
  [EscalationTransitionToStateEnum.Acked]: (
    <IconWithBackground
      id={IconEnum.Checkmark}
      iconSize={IconSize.Small}
      iconClassName="text-green-500"
      className="bg-green-surface"
    />
  ),
  [EscalationTransitionToStateEnum.Resolved]: (
    <IconWithBackground
      id={IconEnum.Checkmark}
      iconSize={IconSize.Small}
      iconClassName="text-green-500"
      className="bg-green-surface"
    />
  ),
};
const transitionMessage = (
  escalation: Escalation,
  transition: EscalationTransition,
  priorities: AlertsListPrioritiesResponseBody["priorities"],
  escalationPath?: EscalationPath,
): React.ReactElement => {
  const gracePeriodMinutes = escalation.grace_period_seconds / 60;
  const gracePeriodMessage = gracePeriodMinutes === 1 ? "minute" : "minutes";
  const flattenedNodes = responseNodesToPathNodes(
    escalation.path,
    escalation.path?.[0]?.id ?? "",
    [],
  );
  const node = transition.from_node_id
    ? flattenedNodes?.[transition.from_node_id]?.data
    : null;
  let conditionType: EscalationPathConditionType | undefined;
  let priorityIDs: string[] | undefined;
  if (node?.nodeType === EscalationPathNodeTypeEnum.IfElse) {
    conditionType = node?.ifElse?.conditionType;
    priorityIDs = node?.ifElse?.priorityIds;
  }

  const conditionString =
    conditionType === EscalationPathConditionType.Priority
      ? `Priority is ${priorities.find((p) => priorityIDs?.includes(p.id))
          ?.name}`
      : conditionType === EscalationPathConditionType.WorkingHoursActive
      ? "Working hours active"
      : "";

  if (
    transition.reason != null &&
    transition.to_state === EscalationTransitionToStateEnum.Triggered
  ) {
    const notificationRecipient = escalation.escalation_path_id ? (
      <>
        level {getNodeDepth({ transition })} of{" "}
        <OrgAwareLink
          to={`/on-call/escalation-paths/${escalationPath?.id}`}
          className="hover:underline"
        >
          <strong className="font-medium">{escalationPath?.name}</strong>
        </OrgAwareLink>
      </>
    ) : (
      <>users</>
    );

    switch (transition.reason) {
      case EscalationTransitionReasonEnum.LevelActive:
        return <>Notifying {notificationRecipient}</>;
      case EscalationTransitionReasonEnum.LevelTimeout:
        return (
          <>
            Notifying {notificationRecipient} as no one acknowledged the
            escalation in time
          </>
        );
      case EscalationTransitionReasonEnum.ConditionTrue:
        return (
          <>
            Condition{" "}
            <Badge size={BadgeSize.ExtraSmall} theme={BadgeTheme.Success}>
              met
            </Badge>{" "}
            <strong className="font-medium">{conditionString}</strong>
          </>
        );
      case EscalationTransitionReasonEnum.ConditionFalse:
        return (
          <>
            Condition{" "}
            <Badge size={BadgeSize.ExtraSmall} theme={BadgeTheme.Error}>
              not met
            </Badge>{" "}
            <strong className="font-medium">{conditionString}</strong>
          </>
        );
      case EscalationTransitionReasonEnum.ConditionFailed:
        return (
          <>
            Condition failed to evaluate, defaulting to{" "}
            <strong className="font-medium">{conditionString}</strong>
          </>
        );
      case EscalationTransitionReasonEnum.AllNacked:
        return <>Everyone has responded as unavailable</>;
      case EscalationTransitionReasonEnum.NoTargets:
        return (
          <>
            No one was on call for level {getNodeDepth({ transition })} of{" "}
            <OrgAwareLink
              to={`/on-call/escalation-paths/${escalationPath?.id}`}
              className="hover:underline"
            >
              <strong className="font-medium">{escalationPath?.name}</strong>
            </OrgAwareLink>
          </>
        );
      case EscalationTransitionReasonEnum.RepeatTriggered:
        return (
          <>
            Retrying{" "}
            <OrgAwareLink
              to={`/on-call/escalation-paths/${escalationPath?.id}`}
              className="hover:underline"
            >
              <strong className="font-medium">{escalationPath?.name}</strong>
            </OrgAwareLink>
          </>
        );
    }
  }

  switch (transition.to_state) {
    case EscalationTransitionToStateEnum.Pending:
      return (
        <>
          Escalation created by{" "}
          <ActorSource actor={escalation.creator} lowercase />, deferred
          notifications for {gracePeriodMinutes} {gracePeriodMessage}
        </>
      );
    case EscalationTransitionToStateEnum.Triggered:
      return (
        <>
          The escalation was triggered
          {escalationPath && (
            <>
              {" "}
              and sent to{" "}
              <OrgAwareLink
                to={`/on-call/escalation-paths/${escalationPath?.id}`}
                className="hover:underline"
              >
                <strong className="font-medium">{escalationPath?.name}</strong>
              </OrgAwareLink>
            </>
          )}
        </>
      );
    case EscalationTransitionToStateEnum.Expired:
      return <>The escalation has {ESCALATION_LABEL[transition.to_state]}</>;
    case EscalationTransitionToStateEnum.Cancelled:
      let cancelReason: string | null = null;

      switch (transition.reason) {
        case EscalationTransitionReasonEnum.AlertMarkedRelated:
          cancelReason =
            "the associated alert was marked as related to an incident";
          break;
        case EscalationTransitionReasonEnum.AlertResolved:
          cancelReason = "the associated alert was marked as resolved";
          break;
      }

      return (
        <>
          The escalation has been cancelled{" "}
          {cancelReason && `because ${cancelReason}`}
        </>
      );
    case EscalationTransitionToStateEnum.Acked:
    case EscalationTransitionToStateEnum.Resolved:
      const ackingTargets =
        escalation.targets?.filter(
          (target) =>
            target.notifications
              ?.flatMap((n) => n.transitions)
              .some(
                (t) =>
                  t.to_state ===
                    EscalationNotificationTransitionsToStateEnum.Acked ||
                  t.to_state ===
                    EscalationNotificationTransitionsToStateEnum.AckedElsewhere,
              ),
        ) ?? [];

      const ackingUserIDs = uniq(
        ackingTargets
          .map((target) => target.user_id)
          .filter((id) => id !== undefined) as string[],
      );
      // We're uniquing the slack channel names here because transitions seem to be deduplicated
      // per target.
      const ackingTargetsWithSlackChannelName = _.uniqBy(
        ackingTargets.filter(
          (target) => target.slack_channel_name !== undefined,
        ),
        (target) => target.slack_channel_name,
      );
      // For Slack channel targets, we have both the targets that give us the name of where we sent the
      // notifications, and the notification transitions which tell us who acked in that channel.
      const whoAckedSlackChannelNotifications =
        ackingTargetsWithSlackChannelName
          .flatMap((target) => {
            return target.notifications.map(
              (n) =>
                n.transitions.find(
                  (t) =>
                    t.to_state ===
                    EscalationNotificationTransitionsToStateEnum.Acked,
                )?.actor?.user?.name,
            );
          })
          .filter((u) => u !== undefined);

      return (
        <>
          The escalation has been acknowledged
          {ackingUserIDs.length > 0 && (
            <span>
              {" "}
              by{" "}
              {joinSpansWithCommasAndConnectorWord(
                ackingUserIDs.map((userID) => (
                  <>
                    <UserLabel
                      key={userID}
                      userId={userID} // Force TypeScript's hand.
                      className="text-slate-800 font-medium"
                    />
                  </>
                )),
              )}
            </span>
          )}
          {ackingUserIDs.length > 0 &&
            ackingTargetsWithSlackChannelName.length > 0 && (
              <span>{" and "}</span>
            )}
          {ackingTargetsWithSlackChannelName.length > 0 && (
            <span>
              {whoAckedSlackChannelNotifications.length > 0 && (
                <>
                  {" "}
                  by{" "}
                  {joinSpansWithCommasAndConnectorWord(
                    whoAckedSlackChannelNotifications.map((user) => (
                      <span key={user} className={"text-slate-800 font-medium"}>
                        {user}
                      </span>
                    )),
                  )}
                </>
              )}{" "}
              in{" "}
              {joinSpansWithCommasAndConnectorWord(
                ackingTargetsWithSlackChannelName.map((ackingTarget) => (
                  <>
                    <SlackChannelLabel
                      key={ackingTarget.slack_channel_name}
                      slackChannelName={
                        ackingTarget.slack_channel_name as string
                      } // Force TypeScript's hand.
                      slackChannelURL={ackingTarget.slack_channel_url as string}
                    />
                  </>
                )),
              )}
            </span>
          )}
        </>
      );
    default:
      return <></>;
  }
};

const isTransitionVisible = (transition: EscalationTransition): boolean => {
  return !(
    transition.to_state === EscalationTransitionToStateEnum.Triggered &&
    (transition.reason == null ||
      // we don't need to create a new row for "level_active", as we'll
      // already create a "notifying level" row when a transition
      // has targets.
      transition.reason === EscalationTransitionReasonEnum.LevelActive)
  );
};

const transitionIcon = (transition: EscalationTransition): React.ReactNode => {
  if (
    transition.reason != null &&
    transition.to_state === EscalationTransitionToStateEnum.Triggered
  ) {
    switch (transition.reason) {
      case EscalationTransitionReasonEnum.LevelTimeout:
      case EscalationTransitionReasonEnum.LevelActive:
        return (
          <IconWithBackground
            id={IconEnum.Siren}
            iconSize={IconSize.Small}
            className="bg-alarmalade-100"
            iconClassName="fill-current text-brand"
          />
        );
      case null:
      case EscalationTransitionReasonEnum.ConditionTrue:
      case EscalationTransitionReasonEnum.ConditionFalse:
      case EscalationTransitionReasonEnum.ConditionFailed:
        return (
          <IconWithBackground
            id={IconEnum.GitBranch}
            className="bg-blue-surface"
            iconClassName="text-blue-500"
          />
        );
      case EscalationTransitionReasonEnum.AllNacked:
        return (
          <IconWithBackground
            id={IconEnum.CloseCircle}
            iconClassName="text-red-500"
            className="bg-red-50"
          />
        );
      case EscalationTransitionReasonEnum.RepeatTriggered:
        return (
          <IconWithBackground
            id={IconEnum.Loop}
            iconClassName="text-purple-500"
            className="bg-purple-surface"
          />
        );
    }
  }

  return escalationTransitionIcon[transition.to_state] ?? <></>;
};

const UserLabel = ({
  userId,
  className,
}: {
  userId: string;
  className?: string;
}) => {
  const { data, isLoading, error } = useAPI("usersShow", { id: userId });

  if (isLoading) {
    return <></>;
  }
  if (error) {
    return <></>;
  }
  if (!data) {
    return <></>;
  }

  return <span className={className}>{data.user.name}</span>;
};

const SlackChannelLabel = ({
  slackChannelURL,
  slackChannelName,
}: {
  slackChannelURL: string;
  slackChannelName: string;
}) => {
  return (
    <span>
      <Link
        href={slackChannelURL}
        openInNewTab
        analyticsTrackingId={"escalation-timeline-click-slack-channel"}
        className={"hover:underline"}
        noUnderline
        noHoverColor
      >
        <span className={"font-medium"}>{` #${slackChannelName}`}</span>
      </Link>
    </span>
  );
};

const MainTransitionMessage = ({
  transition,
  escalationPath,
}: {
  transition: EscalationTransition;
  escalationPath?: EscalationPath;
}) => {
  if (isTransitionForChannelTarget({ transition })) {
    // There is at least one target with a non-null slack_user_id
    return (
      <span>
        Notifying <span className={"font-medium"}>Slack channels</span>
      </span>
    );
  }

  // If it's just a user escalation, don't show level info
  if (!escalationPath) {
    return (
      <>
        Notifying <strong className="font-medium">users</strong>
      </>
    );
  }

  return (
    <>
      Notifying level {getNodeDepth({ transition })} of{" "}
      <OrgAwareLink
        to={`/on-call/escalation-paths/${escalationPath?.id}`}
        className="hover:underline"
      >
        <strong className="font-medium">{escalationPath?.name}</strong>
      </OrgAwareLink>
    </>
  );
};

const isTransitionForChannelTarget = ({
  transition,
}: {
  transition: EscalationTransition;
}) => {
  return transition.targets?.some(
    (target) => target.slack_channel_name !== undefined,
  );
};

const notificationMethodLabel = (
  notificationMethod: EscalationNotificationNotificationTypeEnum,
): string => {
  switch (notificationMethod) {
    case EscalationNotificationNotificationTypeEnum.Email:
      return "email";
    case EscalationNotificationNotificationTypeEnum.Sms:
      return "SMS";
    case EscalationNotificationNotificationTypeEnum.Phone:
      return "phone";
    case EscalationNotificationNotificationTypeEnum.Slack:
      return "Slack";
    case EscalationNotificationNotificationTypeEnum.MicrosoftTeams:
      return "Microsoft Teams";
    case EscalationNotificationNotificationTypeEnum.App:
      return "the app";
    case EscalationNotificationNotificationTypeEnum.SlackChannel:
      return "Slack channel";
    default:
      return "";
  }
};

// getNodeDepth returns the level of the node we've hit in this transition.
// If we're going to a node, then we're at that depth minus.
// If we're coming from a node, then we're at that depth.
const getNodeDepth = ({
  transition,
}: {
  transition: EscalationTransition;
}): number | string => {
  if (transition.to_node_depth) {
    return transition.to_node_depth;
  }

  if (transition.from_node_depth) {
    return transition.from_node_depth;
  }

  // TickerV1 field that needs removed.
  if (transition.level) {
    return transition.level + 1;
  }

  return "";
};

const ActorSource = ({
  actor,
  lowercase,
}: {
  lowercase?: boolean;
  actor?: Actor;
}): React.ReactElement => {
  const content = actor?.workflow ? (
    <a href={`/workflows/${actor.workflow.id}`}>
      Workflow: {actor.workflow.name}
    </a>
  ) : actor?.user ? (
    <>{actor.user.name}</>
  ) : actor?.api_key ? (
    <OrgAwareLink
      to={"/settings/api-keys"}
      target="_blank"
      rel="noopener noreferrer"
    >
      API Key: {actor.api_key.name}
    </OrgAwareLink>
  ) : actor?.external_resource ? (
    <span>{lowercase ? "we" : "We"}</span>
  ) : actor?.alert ? (
    <OrgAwareLink
      to={`/alerts/sources`}
      target="_blank"
      rel="noopener noreferrer"
    >
      {lowercase ? "an alert" : "An alert"}
    </OrgAwareLink>
  ) : (
    // We have a tiny number of timeline items which have neither a user nor a
    // workflow, as they were created before we started attributing timeline
    // items properly. This is a hack so they render in an acceptable way.
    <>{lowercase ? "an" : "An"} automation</>
  );

  const isFound = actor?.workflow
    ? true
    : actor?.user
    ? true
    : actor?.api_key
    ? true
    : false;

  // We want non-bold if we're just returning "An automation" or "We"
  if (!isFound) return content;
  return <strong>{content}</strong>;
};
