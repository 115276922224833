import { tcx } from "src/utils/tailwind-classes";

// EditableSectionWrapper looks a bit like a content box, but with a hover/shadow effect
// which makes it look like a button. It's used to wrap sections that are editable.
export const EditableSectionWrapper = ({
  className,
  children,
}: {
  className?: string;
  children: React.ReactNode;
}) => (
  <div
    className={tcx(
      "transition duration-300",
      "shadow-sm hover:shadow-md",
      "border border-stroke-primary hover:border-stroke-hover",
      "rounded-lg flex flex-col gap-4",
      className,
    )}
  >
    {children}
  </div>
);
