import type { SVGProps } from "react";
import * as React from "react";
const SvgFakeCheckboxChecked = (
  props: SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="M14.25 3h-8.5A2.752 2.752 0 0 0 3 5.75v8.5A2.752 2.752 0 0 0 5.75 17h8.5A2.752 2.752 0 0 0 17 14.25v-8.5A2.752 2.752 0 0 0 14.25 3Zm-.407 4.708-4.25 5.5a.749.749 0 0 1-.565.291H9a.753.753 0 0 1-.558-.248l-2.25-2.5a.751.751 0 0 1 1.116-1.004l1.648 1.832 3.701-4.789a.75.75 0 0 1 1.187.917l-.001.001Z"
    />
  </svg>
);
export default SvgFakeCheckboxChecked;
