import { Plan } from "@incident-io/api";
import { BillingCreateCheckoutSessionRequestBodyBillingIntervalEnum as BillingIntervalEnum } from "@incident-io/api";
import { UseFormReturn } from "react-hook-form";
import { tcx } from "src/utils/tailwind-classes";

import { PurchaseFormData } from "./BillingSeatsEditModal";

export const PricingMaths = ({
  plan,
  responderCount,
  onCallCount,
  formMethods,
}: {
  responderCount: number;
  onCallCount: number;
  plan: Plan;
  formMethods: UseFormReturn<PurchaseFormData>;
}) => {
  const isAnnual =
    formMethods.watch("billing_interval") === BillingIntervalEnum.Annual;

  const priceInfo = isAnnual ? plan.annual_purchase : plan.monthly_purchase;

  // Stripe doesn't support 0 quantity on a line item, so we set a minimum of 1
  // of each.
  //
  // This is a bit of a skank experience - in future we should probably _ask_
  // how many you want to buy if you don't have any On-call responders yet.
  if (responderCount < 1) {
    responderCount = 1;
  }
  if (onCallCount < 1) {
    onCallCount = 1;
  }

  const {
    responder_price_in_cents: responderPrice,
    oncall_addon_price_in_cents: oncallAddonPrice,
  } = priceInfo;

  const totalCents =
    ((responderPrice ?? 0) * responderCount +
      (oncallAddonPrice ?? 0) * onCallCount) *
    (isAnnual ? 12 : 1);

  return (
    <div className={tcx("py-2 w-full text-sm grid grid-cols-3 gap-1")}>
      {responderPrice && (
        <PricingMathsRow
          isAnnual={isAnnual}
          seatName="responder"
          seatCount={responderCount}
          amountCents={responderPrice}
        />
      )}
      {oncallAddonPrice && (
        <>
          <PricingMathsRow
            withPlus
            isAnnual={isAnnual}
            seatName="on-call addon"
            seatCount={onCallCount}
            amountCents={oncallAddonPrice}
          />
          <div className="border-t border-stroke col-span-3 my-2" />
          <div className="col-start-3">
            = <strong>${(totalCents / 100).toLocaleString()}</strong> per{" "}
            {isAnnual ? "year" : "month"}
          </div>
        </>
      )}
    </div>
  );
};

const PricingMathsRow = ({
  isAnnual,
  seatCount,
  seatName,
  amountCents,
  withPlus = false,
}: {
  isAnnual: boolean;
  seatCount: number;
  seatName: string;
  amountCents: number;
  withPlus?: boolean;
}) => {
  if (isAnnual) {
    return (
      <>
        <div className={tcx("col-span-2 text-right", withPlus ? "" : "ml-3.5")}>
          {withPlus ? "+ " : ""}
          <strong>${(amountCents / 100).toLocaleString()}</strong> per{" "}
          {seatName} x <strong>{seatCount.toLocaleString()} </strong>x 12 months
        </div>
        <div>
          ={" "}
          <strong>
            ${((amountCents / 100) * seatCount * 12).toLocaleString()}
          </strong>{" "}
          per year
        </div>
      </>
    );
  }

  return (
    <>
      <div className={tcx("col-span-2 text-right", withPlus ? "" : "ml-3.5")}>
        {withPlus ? "+ " : ""}
        <strong>${(amountCents / 100).toLocaleString()}</strong> per {seatName}{" "}
        x <strong>{seatCount.toLocaleString()} </strong>
      </div>
      <div>
        = <strong>${((amountCents / 100) * seatCount).toLocaleString()}</strong>{" "}
        per month
      </div>
    </>
  );
};
