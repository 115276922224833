import {
  EscalationPath,
  EscalationPathTargetTypeEnum,
  ScopeNameEnum,
} from "@incident-io/api";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import {
  Avatar,
  ButtonTheme,
  DeprecatedTable,
  DeprecatedTableHeaderCell,
  DeprecatedTableHeaderRow,
  DropdownMenu,
  DropdownMenuItem,
  GenericErrorMessage,
  Icon,
  IconEnum,
  IconSize,
  Txt,
} from "@incident-ui";
import _ from "lodash";
import { useState } from "react";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPI, useAPIMutation } from "src/utils/swr";

import { CopyDebugID } from "../../../utils/ShowDebugIDProvider";
import { DeletionConfirmationModal } from "../../settings/DeletionConfirmationModal";

export const EscalationPathsList = ({
  firstLevelUserIDs,
  escalationPaths,
  showEmptyState,
}: {
  showEmptyState?: boolean;
  escalationPaths: EscalationPath[];
  firstLevelUserIDs: Record<string, string[]>;
}) => {
  return (
    <DeprecatedTable className="w-fit min-w-full">
      {/* Header */}
      <DeprecatedTableHeaderRow>
        <DeprecatedTableHeaderCell>
          <span>Name</span>
        </DeprecatedTableHeaderCell>
        <DeprecatedTableHeaderCell>
          <span>First point of contact</span>
        </DeprecatedTableHeaderCell>
        <DeprecatedTableHeaderCell className="w-[10px]">
          {/* Dropdown */}
          <span />
        </DeprecatedTableHeaderCell>
      </DeprecatedTableHeaderRow>

      {/* Body */}
      <tbody>
        {escalationPaths.map((escalationPath) => {
          return (
            <EscalationPathsListItem
              key={`escalation-path-${escalationPath.id}`}
              escalationPath={escalationPath}
              firstLevelUserIDs={firstLevelUserIDs[escalationPath.id] || []}
            />
          );
        })}

        {showEmptyState && escalationPaths.length === 0 && (
          <tr>
            <td colSpan={3}>
              <div className="flex items-center justify-center h-[32px]">
                <span>No results found</span>
              </div>
            </td>
          </tr>
        )}
      </tbody>
    </DeprecatedTable>
  );
};

function EscalationPathsListItem({
  escalationPath,
  firstLevelUserIDs,
}: {
  escalationPath: EscalationPath;
  firstLevelUserIDs: Array<string>;
}) {
  const navigate = useOrgAwareNavigate();
  const { hasScope } = useIdentity();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  return (
    <>
      <tr
        className="hover:bg-surface-secondary cursor-pointer"
        onClick={() => navigate(escalationPath.id)}
      >
        {/* Name */}
        <td>
          <span className="text-sm font-semibold block truncate">
            {escalationPath.name}
          </span>
          <CopyDebugID id={escalationPath.id} />
        </td>

        {/* First point of contact */}
        <td>
          <div className="grow flex gap-2">
            {firstLevelUserIDs.map((id) => {
              return (
                <TargetPreview
                  key={id}
                  targetType={EscalationPathTargetTypeEnum.User}
                  targetID={id}
                />
              );
            })}
          </div>
        </td>

        {/* Dropdown */}
        <td onClick={(e) => e.stopPropagation()}>
          <DropdownMenu
            analyticsTrackingId={"escalation-paths.dropdown-click"}
            menuClassName={"w-[120px] mr-5"}
            triggerButtonTheme={ButtonTheme.Unstyled}
            triggerIcon={IconEnum.DotsVertical}
            screenReaderText="Escalation path management options"
            side={"bottom"}
          >
            <DropdownMenuItem
              analyticsTrackingId={null}
              onSelect={() => navigate(`${escalationPath.id}/edit`)}
              label="Edit"
              icon={IconEnum.Edit}
            />
            <DropdownMenuItem
              analyticsTrackingId={null}
              onSelect={() =>
                navigate(
                  `/on-call/escalation-paths/create?clone=${escalationPath.id}`,
                )
              }
              label="Duplicate"
              icon={IconEnum.Copy}
            />
            <DropdownMenuItem
              analyticsTrackingId={null}
              onSelect={() => setDeleteModalOpen(true)}
              label="Delete"
              disabled={!hasScope(ScopeNameEnum.EscalationPathsDestroy)}
              tooltipContent={
                hasScope(ScopeNameEnum.EscalationPathsDestroy)
                  ? undefined
                  : "You do not have permission to delete escalation paths."
              }
              destructive
              icon={IconEnum.Delete}
              iconProps={{ className: "text-red-content" }}
            />
          </DropdownMenu>
        </td>
      </tr>

      {deleteModalOpen && escalationPath && (
        <ConfirmDeleteEscalationPolicyModal
          escalationPath={escalationPath}
          onConfirm={() => navigate("/on-call/escalation-paths")}
          onCancel={() => setDeleteModalOpen(false)}
        />
      )}
    </>
  );
}

const TargetPreview = ({
  targetType,
  targetID,
}: {
  targetType: EscalationPathTargetTypeEnum;
  targetID: string;
}) => {
  if (targetType === EscalationPathTargetTypeEnum.User) {
    return <TargetPreviewUser targetID={targetID} />;
  }

  if (targetType === EscalationPathTargetTypeEnum.Schedule) {
    return <TargetPreviewSchedule targetID={targetID} />;
  }

  throw new Error(`Unexpected TargetPreview.type: ${targetType}`);
};

const TargetPreviewSchedule = ({ targetID }: { targetID: string }) => {
  const { data, isLoading, error } = useAPI("schedulesList", undefined); // schedulesList is used rather than schedulesShow as it's a cheaper call

  const schedulesByID = _.keyBy(data?.schedules || [], "id");
  const schedule = schedulesByID[targetID];

  if (error) {
    return <GenericErrorMessage error={error} />;
  }

  return (
    <div className="flex items-center gap-1 pl-1 pr-2 py-1 rounded-[6px] bg-surface-secondary border border-stroke">
      <Icon id={IconEnum.Calendar} className="text-content-secondary" />
      {isLoading || !schedule ? (
        <span>Loading schedule</span>
      ) : (
        <Txt className="inline-block truncate">{schedule.name}</Txt>
      )}
    </div>
  );
};

const TargetPreviewUser = ({ targetID }: { targetID: string }) => {
  const { data: userResp, isLoading } = useAPI("usersShow", {
    id: targetID,
  });

  return (
    <div className="flex items-center gap-1 py-1 pl-1 pr-2 rounded-full border border-stroke">
      <Avatar
        size={IconSize.Large}
        url={userResp?.user.avatar_url}
        name={userResp?.user.name}
      />
      {isLoading ? (
        <span>Loading user</span>
      ) : (
        <Txt className="inline-block truncate">{userResp?.user.name}</Txt>
      )}
    </div>
  );
};

const ConfirmDeleteEscalationPolicyModal = ({
  onConfirm,
  onCancel,
  escalationPath,
}: {
  onConfirm: () => void;
  onCancel: () => void;
  escalationPath: EscalationPath;
}) => {
  const {
    trigger: onSubmit,
    isMutating: isDestroying,
    genericError: destroyError,
  } = useAPIMutation(
    "escalationPathsList",
    undefined,
    async (apiClient, { id }: { id: string }) => {
      await apiClient.escalationPathsDestroy({
        id,
      });
    },
    {
      onSuccess: onConfirm,
    },
  );

  const onDelete = () => {
    onSubmit({ id: escalationPath.id });
  };

  if (destroyError) {
    return <GenericErrorMessage />;
  }

  return (
    <DeletionConfirmationModal
      resourceTitle={escalationPath.name}
      title={"Delete escalation path"}
      onDelete={onDelete}
      isDeleting={isDestroying}
      isOpen={true}
      onClose={onCancel}
      deleteConfirmationContent={
        "Are you sure you want to delete this escalation path?"
      }
      analyticsTrackingId="delete-escalation-path"
      fetchDependentResources={[
        {
          resource_type: `CatalogEntry["EscalationPath"]`,
          id: escalationPath.id,
        },
      ]}
    />
  );
};
