import {
  Incident,
  IncidentsGetSummarySuggestionResponseBody,
} from "@incident-io/api";
import { Button, ButtonTheme, LoadingBar } from "@incident-ui";
import { EditableSectionWrapper } from "@incident-ui/EditableSectionWrapper/EditableSectionWrapper";
import { useEffect, useState } from "react";
import { useClient } from "src/contexts/ClientContext";
import { useAPI } from "src/utils/swr";

import { PendingSummarySuggestion } from "./PendingSummarySuggestion";
import { SectionWrapper } from "./SectionWrapper";
import { useSummaryContext } from "./SummaryContext";
import { SummaryDisplay } from "./SummaryDisplay";
import { SummaryEditor } from "./SummaryEditor";

type SummaryProps = {
  id?: string;
  incident: Incident;
  enableExpansion?: boolean;
};

export const Summary = ({
  id,
  incident,
  enableExpansion = false,
}: SummaryProps) => {
  const apiClient = useClient();

  // Default to not polling for suggestions
  const [refreshIntervalMs, setRefreshIntervalMs] = useState(0);

  const {
    data: suggestion,
    isLoading: suggestionLoading,
    mutate: refetchSuggestion,
  } = useAPI(
    "incidentsGetSummarySuggestion",
    {
      id: incident.id,
    },
    {
      refreshInterval: refreshIntervalMs,
    },
  );

  // If AI is available to you, and there is no suggestion: poll every 10 seconds
  useEffect(() => {
    if (
      suggestion &&
      suggestion.has_feature &&
      suggestion.has_suggestion === false
    ) {
      setRefreshIntervalMs(10_000);
    }
  }, [suggestion]);

  const { isEditingSummary, setIsEditingSummary } = useSummaryContext();

  const [acceptedSuggestion, setAcceptedSuggestion] =
    useState<IncidentsGetSummarySuggestionResponseBody>();

  // When editing is cancelled, revert back to the incident's current summary
  const onEditCancel = () => {
    setIsEditingSummary(false);
  };

  // When the suggestion is accepted, set the summary to the suggested one
  const onSuggestionAccept = (
    suggestionData: IncidentsGetSummarySuggestionResponseBody,
  ) => {
    setAcceptedSuggestion(suggestionData);
    setIsEditingSummary(true);
  };

  // When the suggestion is rejected, call the API to reject it
  const onSuggestionReject = async (
    suggestionData: IncidentsGetSummarySuggestionResponseBody,
  ) => {
    setAcceptedSuggestion(undefined);
    if (!incident || !suggestionData.suggestion) {
      return;
    }
    await apiClient.incidentsRejectSummarySuggestion({
      incidentId: incident.id,
      suggestionId: suggestionData.suggestion.id,
    });
    refetchSuggestion();
  };

  if (suggestionLoading) {
    return <LoadingBar className="w-full h-[28px]" />;
  }

  if (isEditingSummary) {
    return (
      <SectionWrapper id={id} sectionName="Summary">
        {!acceptedSuggestion && (
          <PendingSummarySuggestion
            suggestionData={suggestion}
            onAccept={onSuggestionAccept}
            onReject={onSuggestionReject}
          />
        )}
        <SummaryEditor
          alignButtons="right"
          incidentId={incident.id}
          summary={incident.summary}
          suggestion={acceptedSuggestion?.suggestion}
          onCancel={onEditCancel}
          onSave={async () => {
            await refetchSuggestion();
            acceptedSuggestion && setAcceptedSuggestion(undefined);
            setIsEditingSummary(false);
          }}
        />
      </SectionWrapper>
    );
  }

  return (
    <Button
      analyticsTrackingId={null}
      theme={ButtonTheme.Unstyled}
      onClick={() => {
        setIsEditingSummary(true);
      }}
      title="Edit summary"
      // We want this styled like a text input you can click
      className="text-left cursor-text w-full"
    >
      <EditableSectionWrapper className="w-full">
        <SectionWrapper
          hideBorder
          id={id}
          sectionName="Summary"
          enableExpansion={enableExpansion}
        >
          <PendingSummarySuggestion
            suggestionData={suggestion}
            onAccept={onSuggestionAccept}
            onReject={onSuggestionReject}
          />
          {incident.summary ? (
            <SummaryDisplay
              summary={incident.summary}
              onStartEditing={() => setIsEditingSummary(true)}
            />
          ) : (
            <div>Click to add summary</div>
          )}
        </SectionWrapper>
      </EditableSectionWrapper>
    </Button>
  );
};
