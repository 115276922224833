/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EscalationPath,
    EscalationPathFromJSON,
    EscalationPathFromJSONTyped,
    EscalationPathToJSON,
} from './EscalationPath';
import {
    ScheduleSlim,
    ScheduleSlimFromJSON,
    ScheduleSlimFromJSONTyped,
    ScheduleSlimToJSON,
} from './ScheduleSlim';

/**
 * 
 * @export
 * @interface UsersListOnCallResourcesResponseBody
 */
export interface UsersListOnCallResourcesResponseBody {
    /**
     * 
     * @type {Array<EscalationPath>}
     * @memberof UsersListOnCallResourcesResponseBody
     */
    escalation_paths?: Array<EscalationPath>;
    /**
     * 
     * @type {Array<ScheduleSlim>}
     * @memberof UsersListOnCallResourcesResponseBody
     */
    schedules?: Array<ScheduleSlim>;
}

export function UsersListOnCallResourcesResponseBodyFromJSON(json: any): UsersListOnCallResourcesResponseBody {
    return UsersListOnCallResourcesResponseBodyFromJSONTyped(json, false);
}

export function UsersListOnCallResourcesResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): UsersListOnCallResourcesResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'escalation_paths': !exists(json, 'escalation_paths') ? undefined : ((json['escalation_paths'] as Array<any>).map(EscalationPathFromJSON)),
        'schedules': !exists(json, 'schedules') ? undefined : ((json['schedules'] as Array<any>).map(ScheduleSlimFromJSON)),
    };
}

export function UsersListOnCallResourcesResponseBodyToJSON(value?: UsersListOnCallResourcesResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'escalation_paths': value.escalation_paths === undefined ? undefined : ((value.escalation_paths as Array<any>).map(EscalationPathToJSON)),
        'schedules': value.schedules === undefined ? undefined : ((value.schedules as Array<any>).map(ScheduleSlimToJSON)),
    };
}

