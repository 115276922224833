import { useEffect, useState } from "react";
import { tcx } from "src/utils/tailwind-classes";

import styles from "./ProgressBar.module.scss";

export const ProgressBar = ({
  numCompleted,
  numTotal,
  transitionCompletedFrom,
  barBackground,
  animateEmptyBar = false,
  barClassName,
}: {
  numCompleted: number;
  numTotal: number;
  transitionCompletedFrom?: number;
  barBackground?: string;
  animateEmptyBar?: boolean;
  barClassName?: string;
}): React.ReactElement => {
  const isComplete = numCompleted === numTotal;

  const [barSize, setBarSize] = useState(
    transitionCompletedFrom ?? numCompleted,
  );

  useEffect(() => {
    if (barSize !== numCompleted) {
      const timeout = setTimeout(() => setBarSize(numCompleted), 50);
      return () => clearTimeout(timeout);
    }
    return undefined;
  }, [barSize, numCompleted]);
  const scale = barSize / numTotal;

  return (
    <div
      className={tcx(
        "grow overflow-hidden h-2 text-xs flex rounded",
        isComplete
          ? "bg-green-600"
          : barBackground ||
              (animateEmptyBar && numCompleted === 0
                ? styles.loadingGradient
                : "bg-blue-200"),
      )}
    >
      <div
        style={{ transform: `scaleX(${scale})` }}
        className={tcx(
          "shadow-none flex flex-col origin-left whitespace-nowrap text-white w-full",
          isComplete ? "bg-green-600" : "bg-blue-400",
          transitionCompletedFrom &&
            "transition delay-100 duration-300 ease-out",
          barClassName,
        )}
      ></div>
    </div>
  );
};
