import {
  AlertAttribute,
  AlertSchema,
  AlertSourceAttributeSuggestion,
} from "@incident-io/api";
import { makeExpressionReference } from "@incident-shared/engine/expressions/addExpressionsToScope";
import { ExpressionFormData } from "@incident-shared/engine/expressions/expressionToPayload";
import _, { omit } from "lodash";
import { Dispatch, SetStateAction, useState } from "react";
import {
  useFieldArray,
  UseFieldArrayReturn,
  UseFormReturn,
} from "react-hook-form";

import { AttributesFormData } from "./configure/AlertSourceAttributes";

export type ExpressionDefaults = {
  path?: string;
  attribute?: AlertAttribute;
};

type FormData = AttributesFormData;

export type AddAcceptedSuggestionToFormState = (_: {
  attributeId: string;
  suggestion: AlertSourceAttributeSuggestion;
  updatedSchema: AlertSchema;
}) => void;

// useAttributeEditing contains all the hooks we use to dynamically create
// attributes via expressions in your alert schema.
export function useAttributeExpressions({
  formMethods,
}: {
  formMethods: UseFormReturn<FormData>;
}): {
  onDeleteExpression: (e: Pick<ExpressionFormData, "reference">) => void;
  onAddExpression: (
    e: ExpressionFormData & { hardcoded?: boolean },
    existingAttribute: AlertAttribute | undefined,
  ) => void;
  onEditExpression: (e: ExpressionFormData) => void;
  showExpressionsModal: boolean;
  openExpressionsModal: () => void;
  closeExpressionsModal: () => void;
  expressionsMethods: UseFieldArrayReturn<
    FormData,
    "template.expressions",
    "key"
  >;
  expressionDefaults: ExpressionDefaults;
  setExpressionDefaults: Dispatch<SetStateAction<ExpressionDefaults>>;
  initialExpression: ExpressionFormData | undefined;
  setInitialExpression: Dispatch<
    SetStateAction<ExpressionFormData | undefined>
  >;
  removeAttribute: (index?: number | number[] | undefined) => void;
  addAcceptedSuggestionToFormState: AddAcceptedSuggestionToFormState;
} {
  const [showExpressionsModal, setShowExpressionsModal] =
    useState<boolean>(false);

  const [expressionDefaults, setExpressionDefaults] =
    useState<ExpressionDefaults>({});

  const [initialExpression, setInitialExpression] =
    useState<ExpressionFormData>();

  const { append: appendAttributes, remove: removeAttribute } = useFieldArray({
    control: formMethods.control,
    name: "attributes",
    keyName: "key",
  });

  const expressionsMethods = useFieldArray({
    control: formMethods.control,
    name: "template.expressions",
    keyName: "key",
  });

  const {
    fields: expressions,
    append: appendExpression,
    update: updateExpression,
    remove: removeExpression,
  } = expressionsMethods;

  // Using these rather than directly exposing setShowExpressionsModal
  // means we will always clear the default state correctly
  const openExpressionsModal = () => {
    setShowExpressionsModal(true);
  };
  const closeExpressionsModal = () => {
    setExpressionDefaults({});
    setInitialExpression(undefined);
    setShowExpressionsModal(false);
  };

  const onDeleteExpression = (expr: Pick<ExpressionFormData, "reference">) => {
    const index = expressions.findIndex((e) => e.reference === expr.reference);
    if (index > -1) {
      removeExpression(index);
    }
  };

  const onAddExpression = (
    expr: ExpressionFormData & { hardcoded?: boolean },
    targetAttribute: AlertAttribute | undefined,
  ) => {
    // First, we need to create the attribute if it doesn't exist
    if (!targetAttribute) {
      const newAttribute = {
        id: expr.reference,
        array: expr.returns?.array || false,
        name: expr.label,
        type: expr.returns?.type,
      };
      appendAttributes([newAttribute]);
    }

    // If we're just creating a hardcoded attribute,
    // we can exit here without creating an expression at all
    if (expr.hardcoded) {
      setExpressionDefaults({});
      setShowExpressionsModal(false);
      return;
    }

    // Otherwise, we go ahead and add the expression
    const attributeId: string = targetAttribute
      ? targetAttribute.id
      : expr.reference;

    // Defining this here as without it, typescript is super slow
    // and eslint can't parse it inline for some reason
    type AttributeIDPathType =
      | `template.bindings.${typeof attributeId}.array_value.0`
      | `template.bindings.${typeof attributeId}.value`;

    // We can remove "hardcoded" from the payload now
    delete expr.hardcoded;
    appendExpression(expr as unknown as ExpressionFormData);
    formMethods.setValue?.<AttributeIDPathType>(
      expr.returns?.array
        ? `template.bindings.${attributeId}.array_value.0`
        : `template.bindings.${attributeId}.value`,
      {
        reference: makeExpressionReference(expr),
      },
    );
    setExpressionDefaults({});
    setShowExpressionsModal(false);
  };

  const onEditExpression = (expr: ExpressionFormData) => {
    const index = expressions.findIndex((e) => e.reference === expr.reference);
    if (index > -1) {
      updateExpression(
        index,
        omit(expr, "hardcoded") as unknown as ExpressionFormData,
      );
    }
    setExpressionDefaults({});
    setShowExpressionsModal(false);
  };

  const addAcceptedSuggestionToFormState: AddAcceptedSuggestionToFormState = ({
    suggestion,
    attributeId,
    updatedSchema,
  }: {
    attributeId: string;
    suggestion: AlertSourceAttributeSuggestion;
    updatedSchema: AlertSchema;
  }) => {
    type AttributeIDPathType =
      | `template.bindings.${string}.array_value`
      | `template.bindings.${string}.value`;

    const localAttrs = formMethods.getValues("attributes");

    // Combine the remote copy of attributes with the local, the remote supersedes local on any id match
    let mergedAttrs = localAttrs.map((localAttr) => {
      const remoteAttr = updatedSchema.attributes.find(
        (remoteAttribute) => remoteAttribute.id === localAttr.id,
      );
      return remoteAttr ?? localAttr;
    });
    const newRemoteAttrs = updatedSchema.attributes.filter(
      (remoteAttr) =>
        !mergedAttrs.some((mergedAttr) => mergedAttr.id === remoteAttr.id),
    );
    mergedAttrs.push(...newRemoteAttrs);

    // Just to be sure, lets unique the array too
    mergedAttrs = _.uniqBy(mergedAttrs, "id");

    formMethods.setValue("attributes", mergedAttrs);
    formMethods.setValue("version", updatedSchema.version);

    if (suggestion.attribute_payload.array) {
      if (suggestion.attribute_binding.array_value) {
        formMethods.setValue?.<AttributeIDPathType>(
          `template.bindings.${attributeId}.array_value`,
          [...suggestion.attribute_binding.array_value],
        );
      } else if (suggestion.attribute_binding.value) {
        formMethods.setValue?.<AttributeIDPathType>(
          `template.bindings.${attributeId}.array_value`,
          [suggestion.attribute_binding.value],
        );
      }
    } else {
      if ((suggestion.attribute_binding.array_value ?? []).length > 0) {
        // Wouldn't really expect to receive an array value here,
        // but let's handle it just in case!
        formMethods.setValue?.<AttributeIDPathType>(
          `template.bindings.${attributeId}.value`,
          suggestion.attribute_binding.array_value?.[0],
        );
      } else {
        formMethods.setValue?.<AttributeIDPathType>(
          `template.bindings.${attributeId}.value`,
          {
            ...suggestion.attribute_binding.value,
          },
        );
      }
    }

    if (!suggestion.attribute_payload.id) {
      appendAttributes([
        {
          id: attributeId,
          array: suggestion.attribute_payload.array,
          name: suggestion.attribute_payload.name,
          type: suggestion.attribute_payload.type,
        },
      ]);
    }
    if (suggestion.attribute_expression) {
      expressionsMethods.append([suggestion.attribute_expression]);
    }
  };

  return {
    onDeleteExpression,
    onAddExpression,
    onEditExpression,
    showExpressionsModal,
    openExpressionsModal,
    closeExpressionsModal,
    expressionsMethods,
    expressionDefaults,
    setExpressionDefaults,
    removeAttribute,
    initialExpression,
    setInitialExpression,
    addAcceptedSuggestionToFormState,
  };
}
