import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import { DynamicSingleSelectWithObj } from "@incident-ui/Select/DynamicSingleSelect";
import { SelectOption } from "@incident-ui/Select/types";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useAPI } from "src/utils/swr";

import { useOrganisationTypeahead } from "./useOrganisationTypeahead";

export const OrganisationSearch = () => {
  const { targetSlug } = useParams();
  const [orgOption, setOrgOption] = useState<SelectOption | null>(null);

  const navigate = useOrgAwareNavigate();

  // Keep the dropdown in sync with navigations
  const { data, isLoading } = useAPI(
    targetSlug ? "staffTypeaheadOrganisations" : null,
    {
      idList: targetSlug ? [targetSlug] : [],
    },
  );

  const { loadOptions } = useOrganisationTypeahead();

  useEffect(() => {
    if (targetSlug === undefined) {
      setOrgOption(null);
      return;
    }

    if (!data || data.options[0]?.value !== targetSlug) return;

    setOrgOption(data.options[0]);
  }, [data, targetSlug]);

  return (
    <DynamicSingleSelectWithObj
      isLoading={isLoading}
      placeholder="Select organisation"
      autoFocus={true}
      className="min-w-60"
      isClearable
      value={
        // I am lying to TypeScript here, to force the dropdown to clear when we
        // set it to null.
        orgOption as SelectOption | undefined
      }
      onChange={(newValue) => {
        setOrgOption(newValue);

        if (newValue) {
          navigate(`/staff-room/${newValue.value}`);
        } else {
          navigate(`/staff-room`);
        }
      }}
      loadOptions={loadOptions}
    />
  );
};
