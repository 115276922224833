import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { ToggleV2 } from "@incident-shared/forms/v2/inputs/ToggleV2";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import {
  ConfigureDrawerProps,
  IntegrationsListObject,
} from "@incident-shared/integrations";
import { ErrorMessage } from "@incident-ui";
import {
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  ConfirmationDialog,
  ContentBox,
  GenericErrorMessage,
  Icon,
  IconEnum,
  IconSize,
  Input,
  Link,
  ModalFooter,
} from "@incident-ui";
import { InputType } from "@incident-ui/Input/Input";
import React, { useState } from "react";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { useLocation } from "react-router";
import { Form } from "src/components/@shared/forms";
import {
  ErrorResponse,
  IntegrationsZendeskGenerateAPIKeyResponseBody,
  IntegrationsZendeskGetConfigResponseBody,
  ScopeNameEnum,
} from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useSettings } from "src/hooks/useSettings";
import { useAPI, useAPIMutation } from "src/utils/swr";

import { useClipboard } from "../../../../../utils/useClipboard";
import {
  GenericConfigureDrawerContents,
  IntegrationDrawerContentLoader,
} from "../IntegrationDrawer";
import ZendeskAppScreenshot from "./zendesk_app_screenshot.png";
import { getSubdomain, subdomainRegex } from "./zendeskSubdomainRegex";

type FormData = {
  subdomain: string;
  mute_attachments: boolean;
};

function useCanConfigureZendesk() {
  const { hasScope } = useIdentity();
  return hasScope(ScopeNameEnum.ApiKeysCreate);
}

// ConnectToZendeskModal warns that we're about to send the user to Zendesk, where
// they can install the app.
export const ConnectToZendeskModal = ({
  onClose,
}: {
  onClose: () => void;
}): React.ReactElement | null => {
  const { search } = useLocation();
  const { identity } = useIdentity();
  const formMethods = useForm<FormData>();
  const subdomain = formMethods.watch("subdomain");

  const onSubmit = (data: FormData) => {
    // The API will forward onto the Zendesk app installation page. After
    // installation, Zendesk will send the user back to the configured redirect
    // path, which is our integrations page.
    const subdomain = getSubdomain(data.subdomain);
    window.location.replace(
      `/auth/zendesk_install?subdomain=${subdomain}&organisation_id=${identity?.organisation_id}`,
    );
  };

  const isNotIncidentAdmin = !useCanConfigureZendesk();
  const isNotZendeskAdmin = search.includes("requires_admin");

  return (
    <Form.Modal
      formMethods={formMethods}
      onSubmit={onSubmit}
      analyticsTrackingId="connect-zendesk-integration"
      title="Connect to Zendesk"
      onClose={onClose}
      footer={
        <ModalFooter
          onClose={onClose}
          confirmButtonText="Go to Zendesk"
          confirmButtonType="submit"
          disabled={
            isNotZendeskAdmin || isNotIncidentAdmin || subdomain == null
          }
        />
      }
    >
      {isNotZendeskAdmin ? (
        <ErrorMessage
          className={"mt-2"}
          message={
            "You must be a Zendesk admin user to set up this integration."
          }
        />
      ) : isNotIncidentAdmin ? (
        <ErrorMessage
          className={"mt-2"}
          message={
            "You must be a incident.io admin user to set up this integration."
          }
        />
      ) : (
        <>
          <InputV2
            formMethods={formMethods}
            name="subdomain"
            insetSuffixNode={
              <span className="text-content-tertiary">.zendesk.com</span>
            }
            helptext="Your Zendesk subdomain can be identified from the URL you use for Zendesk. For example: https://yoursubdomain.zendesk.com"
            label="Zendesk subdomain"
            type={InputType.Text}
            required="Please provide a subdomain"
            rules={{
              minLength: 1,
              maxLength: 63,
              pattern: {
                value: subdomainRegex,
                message: "Please provide a valid subdomain",
              },
            }}
            autoComplete="none"
            placeholder={"yoursubdomain"}
          />
          <Form.Helptext>
            <>
              For more help, visit:{" "}
              <Link
                analyticsTrackingId="zendesk-subdomain-help-link"
                href={
                  "https://support.zendesk.com/hc/en-us/articles/4409381383578-Where-can-I-find-my-Zendesk-subdomain-"
                }
              >
                Zendesk - Where can I find my Subdomain?
              </Link>
            </>
          </Form.Helptext>
          <Callout theme={CalloutTheme.Plain}>
            You will then be sent to Zendesk, where you&apos;ll be asked to
            install the incident.io app.
          </Callout>
        </>
      )}
    </Form.Modal>
  );
};

export const ZendeskConfigureDrawer = (
  props: ConfigureDrawerProps,
): React.ReactElement | null => {
  const [generateAPIKeyResponse, setGenerateAPIKeyResponse] =
    useState<IntegrationsZendeskGenerateAPIKeyResponseBody | null>(null);
  const { trigger: generateAPIKey, isMutating: generateAPIKeySaving } =
    useAPIMutation(
      "integrationsZendeskGetConfig",
      undefined,
      async (apiClient) => {
        setGenerateAPIKeyResponse(
          await apiClient.integrationsZendeskGenerateAPIKey(),
        );
      },
    );

  const {
    data: configResponse,
    isLoading: getConfigLoading,
    error: getConfigError,
  } = useAPI("integrationsZendeskGetConfig", undefined, {
    onSuccess: (data) => {
      // If we get back a config without an API key, generate one
      if (data.config.api_key_id === undefined) {
        generateAPIKey({});
      }
    },
  });

  return (
    <GenericConfigureDrawerContents {...props}>
      {getConfigLoading ? (
        <IntegrationDrawerContentLoader />
      ) : (
        <ZendeskConfigureDrawerInner
          integration={props.integration}
          configResponse={configResponse || null}
          getConfigLoading={getConfigLoading}
          getConfigError={getConfigError || null}
          generateAPIKey={generateAPIKey}
          generateAPIKeyResponse={generateAPIKeyResponse}
          generateAPIKeySaving={generateAPIKeySaving}
        />
      )}
    </GenericConfigureDrawerContents>
  );
};

const ZendeskConfigureDrawerInner = (props: {
  integration: IntegrationsListObject;
  configResponse: IntegrationsZendeskGetConfigResponseBody | null;
  getConfigLoading: boolean;
  getConfigError: ErrorResponse | null;
  generateAPIKey: SubmitHandler<FieldValues>;
  generateAPIKeyResponse: IntegrationsZendeskGenerateAPIKeyResponseBody | null;
  generateAPIKeySaving: boolean;
}): React.ReactElement => {
  const [hasClickedToInstallZendeskApp, setHasClickToInstallZendeskApp] =
    useState(false);
  const [isShowingConfirmation, setIsShowingConfirmation] = useState(false);

  const zendeskConfig = props.configResponse
    ? props.configResponse.config
    : {
        api_key_id: null,
        subdomain: null,
        mute_attachments: false,
      };

  const formMethods = useForm<FormData>({
    defaultValues: {
      mute_attachments: zendeskConfig.mute_attachments,
    },
  });

  const { refetchSettings } = useSettings();
  const { trigger: onSubmit, isMutating: submitting } = useAPIMutation(
    "integrationsZendeskGetConfig",
    undefined,
    async (apiClient, data: FormData) => {
      const { zendesk_config } =
        await apiClient.integrationsZendeskUpdateConfig({
          updateConfigRequestBody: { mute_attachments: data.mute_attachments },
        });
      return { config: zendesk_config };
    },
    {
      onSuccess: async () => {
        await refetchSettings();
      },
      setError: formMethods.setError,
    },
  );

  const zendeskAppsUrl =
    "https://www.zendesk.co.uk/marketplace/apps/support/850653/incidentio/";
  const hasCreatedToken = !!props.generateAPIKeyResponse?.token;
  const disableSaveButton = hasCreatedToken && !hasClickedToInstallZendeskApp;

  return (
    <Form.Root formMethods={formMethods} onSubmit={onSubmit}>
      <ContentBox className="p-4">
        {props.getConfigError ? (
          <GenericErrorMessage error={props.getConfigError} />
        ) : (
          <>
            <div className={"w-full flex items-center"}>
              <div className="grow">
                <p className="text-sm text-slate-400">
                  Your Zendesk subdomain.
                </p>
                <Input
                  id="subdomain"
                  type={InputType.Text}
                  disabled
                  value={zendeskConfig.subdomain ?? ""}
                />
              </div>
              {hasCreatedToken && (
                <Icon id={IconEnum.Tick} className="text-green-content ml-2" />
              )}
            </div>
            <hr className={"my-5"} />
            {props.generateAPIKeyResponse?.token ? (
              <ShowAccessTokenContainer
                token={props.generateAPIKeyResponse?.token}
                zendeskAppsUrl={zendeskAppsUrl}
                setHasClickToInstallZendeskApp={setHasClickToInstallZendeskApp}
              />
            ) : (
              <ShowRedactedAccessTokenContainer
                zendeskAppsUrl={zendeskAppsUrl}
                isShowingConfirmation={setIsShowingConfirmation}
                saving={props.generateAPIKeySaving}
              />
            )}
            <RegenerateAPIKeyModal
              setIsShowingDeletionConfirmation={setIsShowingConfirmation}
              isShowingDeletionConfirmation={isShowingConfirmation}
              onConfirm={() => props.generateAPIKey({})}
            />
            <hr className={"my-5"} />
            <ToggleV2
              name={"mute_attachments"}
              label={`Mute activity on tickets`}
              description="When activity happens on a Zendesk ticket, we'll post the comments in the incident channels that this ticket is attached to. You can also disable this behaviour for all incidents by flipping the toggle."
              formMethods={formMethods}
            />
          </>
        )}
        <div className={"w-full flex items-center justify-between"}>
          <div className="flex gap-2">
            <GatedButton
              analyticsTrackingId="configure-integration-save"
              loading={props.generateAPIKeySaving || submitting}
              disabled={
                disableSaveButton || props.generateAPIKeySaving || submitting
              }
              type="submit"
              theme={ButtonTheme.Primary}
              requiredScope={ScopeNameEnum.OrganisationSettingsUpdate}
            >
              Save
            </GatedButton>
          </div>
        </div>
      </ContentBox>
    </Form.Root>
  );
};

function ShowAccessTokenContainer(props: {
  token: string;
  zendeskAppsUrl: string;
  setHasClickToInstallZendeskApp: (hasClick: boolean) => void;
}) {
  const { hasCopied, copyTextToClipboard } = useClipboard();

  return (
    <>
      <p className={"text-sm text-slate-700 mt-2"}>
        Next, you need to install the incident.io Zendesk app. This allows your
        Zendesk agents to view incidents and attach them to tickets.
      </p>
      <img
        src={ZendeskAppScreenshot}
        alt={"screenshot of the incident.io zendesk app"}
        className={"w-full h-auto my-4 mx-auto shadow-xl"}
      />
      <p className={"text-sm text-slate-700 mt-2"}>
        Click on the button below and follow the steps in Zendesk to install the
        app. When prompted, paste in the token we&apos;ve provided you here.
      </p>
      <ShowAccessToken
        token={props.token}
        hasCopied={hasCopied}
        copyTextToClipboard={copyTextToClipboard}
      />
      <Button
        theme={ButtonTheme.Primary}
        analyticsTrackingId="get-zendesk-app"
        onClick={() => {
          window.open(props.zendeskAppsUrl, "_blank");
          props.setHasClickToInstallZendeskApp(true);
        }}
        className="flex-center w-full my-2"
      >
        Install the Zendesk app
      </Button>
    </>
  );
}

function ShowRedactedAccessTokenContainer(props: {
  zendeskAppsUrl: string;
  isShowingConfirmation: (isShowing: boolean) => void;
  saving: boolean;
}) {
  const cannotConfigureZendesk = !useCanConfigureZendesk();
  return (
    <>
      <p className={"text-sm text-slate-700 mt-2"}>
        You&apos;re connected to the incident.io Zendesk app using an API key
        that we generated when you installed the integration.
      </p>
      <img
        src={ZendeskAppScreenshot}
        alt={"screenshot of the incident.io zendesk app"}
        className={"w-full h-auto my-4 mx-auto shadow-xl"}
      />
      <p className={"text-sm text-slate-700 my-2"}>
        If you have lost your API key, you can create a new one here. It should
        be entered into your{" "}
        <Link
          openInNewTab
          href={props.zendeskAppsUrl}
          analyticsTrackingId={null}
        >
          Zendesk admin console
        </Link>{" "}
        and shouldn&apos;t be shared with anyone.
      </p>
      <RedactedAccessToken
        setIsShowingConfirmation={props.isShowingConfirmation}
        createTokenDisabled={props.saving || cannotConfigureZendesk}
        createTokenDisabledReason={
          cannotConfigureZendesk
            ? "Only incident.io admins can reset Zendesk API keys"
            : undefined
        }
      />
    </>
  );
}

function ShowAccessToken(props: {
  token: string | undefined;
  hasCopied: boolean;
  copyTextToClipboard: (text: string) => void;
}) {
  return (
    <div className="w-full flex-between space-x-2 mb-2">
      <label htmlFor="API key" className="sr-only" />
      <Input id="API key" readOnly type={InputType.Text} value={props.token} />
      <Button
        theme={ButtonTheme.Naked}
        icon={props.hasCopied ? IconEnum.Success : IconEnum.Copy}
        analyticsTrackingId="copy-zendesk-token"
        type="button"
        title="Copy to clipboard"
        iconProps={{
          className: props.hasCopied ? "!text-green-content" : "",
          size: IconSize.Large,
        }}
        onClick={() => props.copyTextToClipboard(props.token ?? "")}
        className="!transition-none p-1"
      />
    </div>
  );
}

function RedactedAccessToken(props: {
  setIsShowingConfirmation: (isShowing: boolean) => void;
  createTokenDisabled: boolean;
  createTokenDisabledReason: string | undefined;
}) {
  return (
    <div className="w-full flex-between space-x-2 mb-2">
      <Input
        id="API key"
        readOnly
        disabled={true}
        type={InputType.Password}
        value={"000000000000000000000000000000000000000000000000000000000000"}
      />
      <GatedButton
        theme={ButtonTheme.DestroySecondary}
        analyticsTrackingId="regenerate-zendesk-token"
        type="button"
        iconProps={{
          className: "text-slate-700",
          size: IconSize.Large,
        }}
        onClick={() => props.setIsShowingConfirmation(true)}
        disabled={props.createTokenDisabled}
        className="p-1 rounded-2 hover:bg-slate-300 hover:bg-opacity-40"
        disabledTooltipContent={props.createTokenDisabledReason}
        requiredScope={ScopeNameEnum.OrganisationSettingsUpdate}
      >
        Recreate
      </GatedButton>
    </div>
  );
}

function RegenerateAPIKeyModal({
  onConfirm,
  setIsShowingDeletionConfirmation,
  isShowingDeletionConfirmation,
}: {
  onConfirm: () => Promise<unknown> | unknown;
  isShowingDeletionConfirmation: boolean;
  setIsShowingDeletionConfirmation: (isShowing: boolean) => void;
}) {
  if (!isShowingDeletionConfirmation) {
    return null;
  }
  return (
    <ConfirmationDialog
      isOpen={true}
      onCancel={() => setIsShowingDeletionConfirmation(false)}
      analyticsTrackingId="delete-api-key"
      title="Recreate API key"
      onConfirm={() => {
        setIsShowingDeletionConfirmation(false);
        onConfirm();
      }}
    >
      <p>Are you sure you want to recreate your Zendesk API key?</p>
      <p className="mb-4">
        Doing so will sign you out of the incident.io Zendesk Support App and
        you will need to re-enter the code into the app settings to sign in
        again.
      </p>
    </ConfirmationDialog>
  );
}
