import {
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  IconEnum,
  IconSize,
} from "@incident-ui";
import { LoadingWrapper } from "@incident-ui/LoadingWrapper/LoadingWrapper";
import { useState } from "react";
import {
  Action,
  ActionStatusEnum,
  IncidentVisibilityEnum,
} from "src/contexts/ClientContext";
import { useAPIMutation, useAPIRefetch } from "src/utils/swr";

import { BodyTabs } from "../body/IncidentBody";
import { useActions, useIncident } from "../hooks";
import { ActionDeleteModal } from "./ActionDeleteModal";
import { ActionEditModal } from "./ActionEditModal";
import { ActionsTable } from "./ActionsTable";

type Props = {
  incidentId: string | null;
  isClosed: boolean;
  onTabChange: (tab: BodyTabs) => void;
};

export const IncidentActions = ({
  incidentId,
  isClosed,
  onTabChange,
}: Props): React.ReactElement => {
  const { incident } = useIncident(incidentId);
  const { actions, isLoading } = useActions(incidentId);

  const [editingAction, setEditingAction] = useState<Action | null>(null);
  const [deletingAction, setDeletingAction] = useState<Action | null>(null);

  const isPrivate = incident?.visibility === IncidentVisibilityEnum.Private;

  const open = actions.filter(
    (action) => action.status === ActionStatusEnum.Outstanding,
  );

  const refetchActions = useAPIRefetch("actionsList", {
    incidentId: incidentId ?? "",
  });

  const { trigger: onClickConvertToFollowUps, isMutating: saving } =
    useAPIMutation(
      "followUpsList",
      { incidentId: incidentId ?? "" },
      async (apiClient, { actions }: { actions: Action[] }) => {
        await Promise.all(
          actions.map((action) => {
            if (action.status === ActionStatusEnum.Outstanding) {
              return apiClient.actionsConvertToFollowUp({
                id: action.id,
              });
            }
            return Promise.resolve();
          }),
        );
      },
      {
        onSuccess: async () => {
          await refetchActions();
          onTabChange(BodyTabs.FollowUps);
        },
      },
    );

  if (!isLoading && actions.length === 0) {
    return <></>;
  }

  return (
    <>
      {isClosed && open.length > 0 ? (
        <Callout
          theme={CalloutTheme.Warning}
          iconClassName="self-center"
          className="mb-4"
          cta={
            <Button
              analyticsTrackingId={`convert-all-to-follow-up`}
              type="button"
              onClick={() => onClickConvertToFollowUps({ actions })}
              theme={ButtonTheme.Secondary}
              icon={IconEnum.FastForward}
              iconProps={{ size: IconSize.Medium, className: "!mr-1" }}
              loading={saving}
            >
              <span className="mobile-hidden">
                Convert open actions to follow-ups
              </span>
            </Button>
          }
        >
          <div className="flex justify-between">
            <div>
              Closed incidents should not have open actions. Should these be
              follow-ups instead?
            </div>
          </div>
        </Callout>
      ) : null}
      <LoadingWrapper loading={isLoading}>
        <ActionsTable
          onEdit={(action) => setEditingAction(action)}
          onDelete={(action) => setDeletingAction(action)}
          onConvertToFollowUp={(action) =>
            onClickConvertToFollowUps({ actions: [action] })
          }
          actions={actions}
          roundedTop
        />
      </LoadingWrapper>
      {editingAction && (
        <ActionEditModal
          isPrivateIncident={isPrivate}
          action={editingAction}
          onClose={() => setEditingAction(null)}
        />
      )}
      {deletingAction && (
        <ActionDeleteModal
          action={deletingAction}
          onClose={() => setDeletingAction(null)}
        />
      )}
    </>
  );
};
