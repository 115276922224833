import { PostmortemsCreateTemplateRequestBody } from "@incident-io/api";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { StaticSingleSelectV2 } from "@incident-shared/forms/v2/inputs/StaticSelectV2";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import { LoadingModal, ModalFooter, RadioButtonGroup } from "@incident-ui";
import { ErrorModal } from "@incident-ui/ErrorModal/ErrorModal";
import { RadioButtonGroupOption } from "@incident-ui/RadioButtonGroup/RadioButtonGroup";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import { useAPI, useAPIMutation } from "src/utils/swr";

type FormData = PostmortemsCreateTemplateRequestBody;

export const CreatePostmortemTemplateModal = (): React.ReactElement => {
  const navigate = useOrgAwareNavigate();
  const onClose = () => navigate("/settings/post-mortem");

  const formMethods = useForm<FormData>({});

  const [mode, setMode] = useState("from_scratch");

  const {
    data: suggestedTemplatesResp,
    isLoading: loadingSuggestedTemplates,
    error: errorSuggestedTemplates,
  } = useAPI("postmortemsListSuggestedTemplates", {});

  const {
    data: { postmortem_templates: existingTemplates },
    isLoading: loadingExistingTemplates,
    error: errorExistingTemplates,
  } = useAPI(
    "postmortemsListTemplates",
    {},
    {
      fallbackData: { postmortem_templates: [] },
    },
  );

  const {
    trigger: onCreate,
    isMutating: isCreating,
    genericError: createError,
  } = useAPIMutation(
    "postmortemsListTemplates",
    {},
    async (apiClient, data) => {
      await apiClient.postmortemsCreateTemplate({
        createTemplateRequestBody: {
          name: data.name,
          // Only set the suggested template name if it's the right mode
          suggested_template_id:
            mode === "from_suggested" ? data.suggested_template_id : undefined,
          // Only set the existing template name if it's the right mode
          existing_template_id:
            mode === "from_existing" ? data.existing_template_id : undefined,
        },
      });
    },
    {
      onSuccess: onClose,
      setError: formMethods.setError,
    },
  );

  if (loadingSuggestedTemplates || loadingExistingTemplates) {
    return (
      <LoadingModal title="Create postmortem template" onClose={onClose} />
    );
  }

  if (
    errorSuggestedTemplates ||
    !suggestedTemplatesResp ||
    errorExistingTemplates
  ) {
    return (
      <ErrorModal
        error={errorSuggestedTemplates}
        description="Failed to load templates"
        onClose={onClose}
      />
    );
  }

  const options: RadioButtonGroupOption[] = [
    {
      label: "Start from scratch",
      value: "from_scratch",
    },
    {
      label: "Start from a suggested template",
      value: "from_suggested",
      renderWhenSelectedNode: () => (
        <StaticSingleSelectV2
          formMethods={formMethods}
          name="suggested_template_id"
          options={suggestedTemplatesResp.suggested_templates.map(
            (template) => ({
              label: template.label,
              value: template.id,
            }),
          )}
          required
        />
      ),
    },
  ];

  if (existingTemplates.length > 0) {
    options.push({
      label: "Start from an existing template",
      value: "from_existing",
      renderWhenSelectedNode: () => (
        <>
          <StaticSingleSelectV2
            formMethods={formMethods}
            name="existing_template_id"
            options={existingTemplates.map((template) => ({
              label: template.name,
              value: template.id,
            }))}
            required
          />
          <div className="mt-2 text-content-secondary">
            We&apos;ll copy the sections from one of the post-mortem templates
            you&apos;ve already set up, and you can iterate from there.
          </div>
        </>
      ),
    });
  }

  return (
    <Form.Modal
      analyticsTrackingId="create-postmortem-template"
      genericError={createError}
      saving={isCreating}
      formMethods={formMethods}
      onSubmit={onCreate}
      title="Create postmortem template"
      onClose={onClose}
      footer={
        <ModalFooter
          saving={isCreating}
          onClose={onClose}
          confirmButtonType="submit"
          confirmButtonText="Create"
        />
      }
    >
      <InputV2 formMethods={formMethods} name="name" label="Name" required />
      <RadioButtonGroup
        name="mode"
        boxed
        srLabel="Should we start from an existing template?"
        value={mode}
        onChange={(newVal) => setMode(newVal)}
        options={options}
      />
    </Form.Modal>
  );
};
