import {
  Avatar,
  Button,
  ButtonTheme,
  Icon,
  IconEnum,
  IconSize,
  Tooltip,
} from "@incident-ui";
import { useState } from "react";
import { SummaryContext } from "src/components/legacy/incident/body/SummaryContext";
import { PostIncidentTaskActionButton } from "src/components/legacy/incident/postincidentflow/PostIncidentTaskActionButton";
import { RemindPostIncidentTaskButton } from "src/components/legacy/incident/postincidentflow/RemindPostIncidentTaskButton";
import { TaskCanBeManuallyCompleted } from "src/components/legacy/incident/postincidentflow/taskCanBeManuallyCompleted";
import { PostIncidentTaskSlim, User } from "src/contexts/ClientContext";
import { formatDurationInSecondsShort } from "src/utils/datetime";
import { useAPIMutation } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";

import { EditPostIncidentTaskAssigneeModal } from "../../legacy/incident/postincidentflow/PostIncidentTaskAssignee";
import { TooltipForTaskRow } from "./TaskDueDateBadge";

export const ActionButtons = ({
  task,
  refetchIncidents,
}: {
  task: PostIncidentTaskSlim;
  refetchIncidents: () => Promise<void>;
}): React.ReactElement => {
  const [isEditingSummary, setIsEditingSummary] = useState(false);

  const { trigger: rejectTask, isMutating: rejectingTaskStatus } =
    useAPIMutation(
      "postIncidentFlowListTasks",
      { incidentId: task.incident_id },
      async (apiClient) => {
        await apiClient.postIncidentFlowRejectTask({
          id: task.id,
        });
      },
      {
        onSuccess: () => {
          refetchIncidents();
        },
      },
    );

  const { trigger: completeTask, isMutating: completingTaskStatus } =
    useAPIMutation(
      "postIncidentFlowListTasks",
      { incidentId: task.incident_id },
      async (apiClient) => {
        await apiClient.postIncidentFlowCompleteTask({
          id: task.id,
        });

        await refetchIncidents();
      },
      {
        onSuccess: () => {
          refetchIncidents();
        },
      },
    );

  const cannotSkip = task.config.unskippable;

  const buttonClassName = "!text-slate-600 py-1 px-2";

  return (
    <div className="hidden group-hover:block">
      <div className="flex gap-2 items-center">
        {/* Main action e.g. "create postmortem". Note that we can't build the "update summary" button without wrapping it in a summary context provider, even though we won't make use of the context */}
        <SummaryContext.Provider
          value={{ setIsEditingSummary, isEditingSummary }}
        >
          <PostIncidentTaskActionButton
            incidentId={task.incident_id}
            task={task.config}
            incidentTask={task}
            onTaskComplete={() => {
              refetchIncidents();
            }}
            onError={() => undefined}
            theme={ButtonTheme.Ghost}
            buttonClassName={buttonClassName}
            onClick={(e) => {
              e.stopPropagation();
            }}
            iconProps={{ className: "!text-slate-600" }}
          />
          <RemindPostIncidentTaskButton
            task={task}
            buttonClassName={buttonClassName}
            iconProps={{ className: "!text-slate-600" }}
          />
        </SummaryContext.Provider>
        {/* Complete */}
        {TaskCanBeManuallyCompleted(task.config) && (
          <Tooltip content="Complete">
            <Button
              theme={ButtonTheme.Ghost}
              onClick={(e) => {
                completeTask({});
                e.stopPropagation();
              }}
              analyticsTrackingId="post-incident-list-task-complete"
              analyticsTrackingMetadata={{
                incident_task_id: task.id,
              }}
              className={buttonClassName}
              loading={completingTaskStatus}
            >
              <Icon id={IconEnum.Tick} className="text-slate-600" />
            </Button>
          </Tooltip>
        )}
        {/* Skip */}
        <Tooltip
          content={task.config.unskippable ? "This task is required" : "Skip"}
        >
          <Button
            theme={ButtonTheme.Ghost}
            onClick={(e) => {
              rejectTask({});
              e.stopPropagation();
            }}
            analyticsTrackingId="post-incident-list-task-reject"
            analyticsTrackingMetadata={{
              incident_task_id: task.id,
            }}
            className={tcx(buttonClassName, {
              "!bg-surface-secondary": cannotSkip,
            })}
            loading={rejectingTaskStatus}
            disabled={cannotSkip}
          >
            <Icon id={IconEnum.Close} className="text-slate-600" />
          </Button>
        </Tooltip>
      </div>
    </div>
  );
};

export const AssigneeSection = ({
  task,
  refetchIncidents,
}: {
  task: PostIncidentTaskSlim;
  refetchIncidents: () => Promise<void>;
}): React.ReactElement => {
  const [isAssigning, setIsAssigning] = useState(false);

  if (task.completed_at || task.rejected_at) {
    return <CompleterOrRejectorSection task={task} />;
  }

  return (
    <>
      <td className="!py-0">
        <Button
          analyticsTrackingId={"post-incident-list-assign"}
          onClick={(e) => {
            setIsAssigning(true);
            e.stopPropagation();
          }}
          theme={ButtonTheme.Ghost}
        >
          <Avatar
            size={IconSize.Large}
            url={task?.assignee?.avatar_url}
            name={task?.assignee?.name}
          />
          <span className="text-right truncate">
            {task?.assignee?.name ? (
              <span>{task?.assignee?.name}</span>
            ) : (
              <span className="text-slate-600">{"Assign"}</span>
            )}
          </span>
        </Button>
      </td>
      {isAssigning && (
        <EditPostIncidentTaskAssigneeModal
          incidentId={task.incident_id}
          task={task}
          onClose={() => {
            setIsAssigning(false);
            refetchIncidents();
          }}
        />
      )}
    </>
  );
};

const CompleterOrRejectorSection = ({
  task,
}: {
  task: PostIncidentTaskSlim;
}): React.ReactElement => {
  let user: User | undefined;
  let tooltipContent = "";

  if (task.completed_at && task.completer) {
    user = task.completer.user;
    const completedAgo = formatDurationInSecondsShort(
      (new Date().getTime() - task.completed_at.getTime()) / 1000,
      { significantFigures: 1 },
    );
    tooltipContent = `Completed by ${task.completer.user?.name} ${completedAgo} ago`;
  } else if (task.rejected_at && task.rejecter) {
    user = task.rejecter.user;
    const rejectedAgo = formatDurationInSecondsShort(
      (new Date().getTime() - task.rejected_at.getTime()) / 1000,
      { significantFigures: 1 },
    );
    tooltipContent = `Skipped by ${task.rejecter.user?.name} ${rejectedAgo} ago`;
  }

  // If we haven't recorded a completer or rejector for some reason,
  // we'll just show an empty avatar.
  if (!user) {
    return <Avatar size={IconSize.Large} className="!mr-5" />;
  }

  return (
    <>
      <div className="flex mr-5 items-center font-normal gap-3">
        <TooltipForTaskRow
          content={<span className="whitespace-normal">{tooltipContent}</span>}
        >
          <div className="flex items-center gap-1.5">
            <Avatar
              size={IconSize.Large}
              url={user?.avatar_url}
              name={user?.name}
            />
            <span className="text-right truncate">
              <span>{user?.name}</span>
            </span>
          </div>
        </TooltipForTaskRow>
      </div>
    </>
  );
};
