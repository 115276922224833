import { Actor as ActorBody } from "@incident-io/api";
import { IconSize } from "@incident-ui";
import { ActorSource } from "@incident-ui/Actor/ActorSource";
import { ActorAvatar } from "@incident-ui/Avatar/ActorAvatar";

import { tcx } from "../../../utils/tailwind-classes";

export type ActorProps = {
  className?: string;
  actor: ActorBody;
  size?: IconSize.Small | IconSize.Medium | IconSize.Large;
};

export const Actor = ({
  actor,
  size = IconSize.Medium,
  className,
}: ActorProps) => {
  return (
    <span
      className={tcx(
        "inline-flex align-middle items-center flex-row gap-1",
        className,
      )}
    >
      <ActorAvatar
        actor={actor}
        size={size}
        className={"inset-0 rounded-full border border-black/10"}
      />
      <ActorSource actor={actor} />
    </span>
  );
};
