/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CopilotRequest,
    CopilotRequestFromJSON,
    CopilotRequestFromJSONTyped,
    CopilotRequestToJSON,
} from './CopilotRequest';

/**
 * 
 * @export
 * @interface AIListCopilotRequestsResponseBody
 */
export interface AIListCopilotRequestsResponseBody {
    /**
     * 
     * @type {Array<CopilotRequest>}
     * @memberof AIListCopilotRequestsResponseBody
     */
    requests: Array<CopilotRequest>;
}

export function AIListCopilotRequestsResponseBodyFromJSON(json: any): AIListCopilotRequestsResponseBody {
    return AIListCopilotRequestsResponseBodyFromJSONTyped(json, false);
}

export function AIListCopilotRequestsResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AIListCopilotRequestsResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'requests': ((json['requests'] as Array<any>).map(CopilotRequestFromJSON)),
    };
}

export function AIListCopilotRequestsResponseBodyToJSON(value?: AIListCopilotRequestsResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'requests': ((value.requests as Array<any>).map(CopilotRequestToJSON)),
    };
}

