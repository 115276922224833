import { StaticSingleSelectV2 } from "@incident-shared/forms/v2/inputs/StaticSelectV2";
import { TextareaV2 } from "@incident-shared/forms/v2/inputs/TextareaV2";
import {
  ButtonSize,
  GenericErrorMessage,
  LoadingModal,
  ModalFooter,
} from "@incident-ui";
import React, { createContext, useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router";
import { Form } from "src/components/@shared/forms";
import {
  Incident,
  IncidentStatusCategoryEnum,
  IncidentUpdatesRequestRequestBody,
  Stream,
  useClient,
} from "src/contexts/ClientContext";
import { useAPI } from "src/utils/swr";

import { useMutation } from "../../../../utils/fetchData";
import {
  ToggleSubscriptionButton,
  useIncidentHasSubscription,
} from "../ToggleSubscriptionButton";

type RequestUpdateContextT = {
  updateRequestedAt?: Date;
  setUpdateRequestedAt: (val: Date) => void;
  updateRequestedSinceMostRecentUpdate: (val: Date) => boolean;
  updateRequestedForIDs: Set<string>;
  setUpdateRequestedForIDs: (val: Set<string>) => void;
};

const RequestUpdateContext = createContext<RequestUpdateContextT>({
  updateRequestedForIDs: new Set(),
  setUpdateRequestedAt: () => void 0,
  updateRequestedSinceMostRecentUpdate: () => false,
  setUpdateRequestedForIDs: () => void 0,
});

export const useUpdateRequested = () => useContext(RequestUpdateContext);

export const RequestUpdateContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [updateRequestedAt, setUpdateRequestedAt] = useState<Date>();
  const [updateRequestedForIDs, setUpdateRequestedForIDs] = useState<
    Set<string>
  >(new Set());

  return (
    <RequestUpdateContext.Provider
      value={{
        updateRequestedAt,
        setUpdateRequestedAt: () => setUpdateRequestedAt(new Date()),
        updateRequestedSinceMostRecentUpdate: (mostRecentUpdateCreatedAt) => {
          if (!updateRequestedAt) return false;
          return updateRequestedAt > mostRecentUpdateCreatedAt;
        },
        updateRequestedForIDs: updateRequestedForIDs,
        setUpdateRequestedForIDs: (ids) => setUpdateRequestedForIDs(ids),
      }}
    >
      {children}
    </RequestUpdateContext.Provider>
  );
};

export const RequestStreamUpdateModal = ({
  streamId,
  onClose,
}: {
  streamId?: string;
  onClose: () => void;
}): React.ReactElement => {
  const { streamId: paramsStreamId } = useParams() as {
    streamId: string;
  };

  if (!streamId) {
    streamId = paramsStreamId;
  }

  const {
    data: { stream },
    isLoading,
    error,
  } = useAPI(
    streamId == null ? null : "streamsShow",
    {
      id: streamId ?? "",
    },
    { fallbackData: { stream: undefined } },
  );

  if (isLoading) {
    return <LoadingModal onClose={onClose} />;
  }

  if (error || !stream) {
    return <GenericErrorMessage error={error} />;
  }

  return <RequestUpdatesForm incident={stream} onClose={onClose} />;
};

export const RequestUpdateModal = ({
  incident,
  onClose,
}: {
  incident: Incident | Stream;
  onClose: () => void;
}) => {
  const {
    data: { streams },
    isLoading: streamsLoading,
  } = useAPI(
    "streamsList",
    { parentId: incident.id },
    { fallbackData: { streams: [] } },
  );

  const openStreamsWithUserAccess = streams.filter(
    (stream) =>
      stream.status.category === IncidentStatusCategoryEnum.Active &&
      stream.user_can_access,
  );

  const streamSelectOptions = openStreamsWithUserAccess.map((stream) => ({
    value: stream.id,
    label: stream.name,
  }));

  const incidentStreamGroups = [
    {
      label: "Incident",
      options: [
        {
          value: incident.id,
          label: incident.name,
        },
      ],
    },
    {
      label: "Streams",
      options: streamSelectOptions,
    },
  ];

  if (streamsLoading) {
    return <LoadingModal title="Request an update" onClose={onClose} />;
  }

  return (
    <RequestUpdatesForm
      incident={incident}
      onClose={onClose}
      selectOptions={incidentStreamGroups}
    />
  );
};

const RequestUpdatesForm = ({
  onClose,
  incident,
  selectOptions,
}: {
  onClose: () => void;
  incident: Incident | Stream;
  selectOptions?: {
    label: string;
    options: { value: string; label: string }[];
  }[];
}) => {
  const {
    setUpdateRequestedAt,
    setUpdateRequestedForIDs,
    updateRequestedForIDs,
  } = useUpdateRequested();

  const formMethods = useForm<IncidentUpdatesRequestRequestBody>({
    defaultValues: {
      incident_id: incident.id,
      message: "",
    },
  });

  // This doesn't use useAPIMutation because it doesn't actually mutate any
  // state we have loaded
  const apiClient = useClient();
  const [onSubmit, { saving, genericError }] = useMutation(
    async (data) => {
      await apiClient.incidentUpdatesRequest({
        requestRequestBody: {
          incident_id: data.incident_id,
          message: data?.message,
        },
      });
      setUpdateRequestedAt(new Date());
      setUpdateRequestedForIDs(
        new Set([...updateRequestedForIDs, data.incident_id]),
      );
      onClose();
    },
    { setError: formMethods.setError, onSuccess: onClose },
  );

  const { isSubscribed, loading } = useIncidentHasSubscription({
    incidentId: incident.id,
  });

  if (loading) {
    return <LoadingModal onClose={onClose} title="Request an update" />;
  }

  const hasStreams = selectOptions && selectOptions[1].options.length > 0;

  return (
    <Form.Modal
      formMethods={formMethods}
      onClose={onClose}
      analyticsTrackingId="request-update"
      onSubmit={onSubmit}
      genericError={genericError}
      saving={saving}
      title="Request an update"
      footer={
        <ModalFooter
          confirmButtonText="Send request"
          confirmButtonType="submit"
          onClose={onClose}
          saving={saving}
        />
      }
    >
      {selectOptions && hasStreams && (
        <StaticSingleSelectV2
          options={selectOptions}
          placeholder="Choose an incident or stream"
          isClearable={false}
          formMethods={formMethods}
          name="incident_id"
          required
          label="Choose an incident or stream"
        />
      )}
      <div className="text-sm">
        We&apos;ll send a message to the channel requesting an update on your
        behalf.
      </div>

      <TextareaV2
        formMethods={formMethods}
        label="Message"
        required={false}
        name="message"
      />
      {!isSubscribed && selectOptions && (
        <div className="text-sm flex items-center gap-2">
          You can subscribe to this incident to be notified about new updates.
          <ToggleSubscriptionButton
            incidentId={incident.id}
            size={ButtonSize.Small}
          />
        </div>
      )}
    </Form.Modal>
  );
};
