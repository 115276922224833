import {
  CatalogType,
  EngineScope,
  ExpressionSuggestion,
  Resource,
} from "@incident-io/api";
import {
  AddExpressionButton,
  isExpression,
  MenuPathItem,
  TruncatingReferenceLabel,
} from "@incident-shared/engine";
import { AddEditExpressionModal } from "@incident-shared/engine/expressions/AddEditExpressionModal";
import { makeExpressionReference } from "@incident-shared/engine/expressions/addExpressionsToScope";
import { useExpressionsMethods } from "@incident-shared/engine/expressions/ExpressionsMethodsProvider";
import {
  Button,
  ButtonSize,
  ButtonTheme,
  GenericErrorMessage,
  IconEnum,
  Spinner,
} from "@incident-ui";
import _ from "lodash";
import React, { useState } from "react";
import { useController, useFormContext } from "react-hook-form";

import { useAPI } from "../../../utils/swr";
import { tcx } from "../../../utils/tailwind-classes";
import { AlertRouteFormData } from "./types";

type ReferenceSuggestion = {
  type: "reference";
  key: string;
  label: string;
};

// We'll display either ExpressionSuggestions from the API
// or we'll display any applicable references already in the scope
type BindingSuggestion = ExpressionSuggestion | ReferenceSuggestion;

const isReferenceSuggestion = (
  suggestion: BindingSuggestion | undefined,
): suggestion is ReferenceSuggestion => {
  if (!suggestion) {
    return false;
  }

  return "type" in suggestion && suggestion.type === "reference";
};

export const EscalationBindingSuggestions = ({
  resources,
  scopeWithExpressions,
  suffixNode,
}: {
  scopeWithExpressions: EngineScope;
  resources: Resource[];
  suffixNode?: React.ReactNode;
}) => {
  const formMethods = useFormContext<AlertRouteFormData>();
  const controller = useController({
    name: "escalationBinding",
    control: formMethods.control,
  });

  const { expressionsMethods: expressionsMethods } = useExpressionsMethods();
  const [confirmExpressionModal, setConfirmExpressionModal] =
    useState<ExpressionSuggestion | null>(null);

  const {
    data: suggestionsData,
    isLoading: isLoadingSuggestions,
    error: suggestionsError,
  } = useAPI("catalogListExpressionSuggestions", {
    listExpressionSuggestionsRequestBody: {
      scope: scopeWithExpressions.references,
      target_type: "EscalationPath",
    },
  });

  const {
    data: { catalog_types: catalogTypes },
    isLoading: isLoadingCatalogTypes,
    error: catalogTypesError,
  } = useAPI("catalogListTypes", {}, { fallbackData: { catalog_types: [] } });

  // If you've not, rather than show a regular form element, we show some suggestions
  const resource = resources.find(
    (r) => r.type === 'CatalogEntry["EscalationPath"]',
  );

  if (!resource) {
    throw new Error("Resource not found");
  }

  const onConfirmSuggestion = (
    refKey: string,
    refLabel = "Escalation path",
  ) => {
    controller.field.onChange({
      value: null,
      array_value: [
        {
          reference: refKey,
          value: refKey,
          label: refLabel,
          sort_key: refLabel,
        },
      ],
    });
  };

  if (!expressionsMethods) {
    throw new Error("Expressions methods not found");
  }

  if (isLoadingSuggestions || isLoadingCatalogTypes) {
    return (
      <Spinner
        containerClassName={"w-full flex justify-center items-center py-4"}
      />
    );
  }

  const error = suggestionsError ?? catalogTypesError;
  if (error) {
    return <GenericErrorMessage error={error} />;
  }

  const referenceSuggestions: ReferenceSuggestion[] = _.chain(
    scopeWithExpressions.references,
  )
    .filter(
      (ref) =>
        ref.type === 'CatalogEntry["EscalationPath"]' && !isExpression(ref.key),
    )
    .map(
      (ref): ReferenceSuggestion => ({
        type: "reference",
        key: ref.key,
        label: ref.label,
      }),
    )
    .value();

  const expressionSuggestions = _.chain(
    suggestionsData?.expression_suggestions ?? [],
  )
    .sortBy((e) => e.expression?.operations.length)
    .take(3)
    .value();

  const suggestions: BindingSuggestion[] = [
    ...referenceSuggestions,
    ...expressionSuggestions,
  ];

  const fixedResult = {
    array: true,
    label: resource.type_label,
    type: resource.type,
    typeLabel: resource.type_label,
    typeIsAutocompletable: resource.autocompletable,
  };

  const onConfirmExpression = (expression) => {
    if (!confirmExpressionModal) {
      return;
    }

    setConfirmExpressionModal(null);
    expressionsMethods.append(expression);
    onConfirmSuggestion(
      makeExpressionReference(confirmExpressionModal?.expression),
    );
  };

  return (
    <div className={"flex flex-col w-full gap-4"}>
      <div className={tcx("flex gap-2 flex-wrap items-center justify-start")}>
        {suggestions.map((bindingSuggestion: BindingSuggestion, idx) => (
          <button
            onClick={(e) => {
              e.preventDefault();
              if (isReferenceSuggestion(bindingSuggestion)) {
                onConfirmSuggestion(
                  bindingSuggestion.key,
                  bindingSuggestion.label,
                );
                return;
              } else {
                setConfirmExpressionModal(bindingSuggestion);
              }
            }}
            key={idx}
          >
            <EscalationBindingSuggestionBadge
              bindingSuggestion={bindingSuggestion}
              catalogTypes={catalogTypes}
              scope={scopeWithExpressions}
              onClick={(e) => {
                e.preventDefault();
                if (isReferenceSuggestion(bindingSuggestion)) {
                  onConfirmSuggestion(
                    bindingSuggestion.key,
                    bindingSuggestion.label,
                  );
                  return;
                } else {
                  setConfirmExpressionModal(bindingSuggestion);
                }
              }}
            />
          </button>
        ))}
        <AddExpressionButton
          onAdd={(ref) => onConfirmSuggestion(ref.key)}
          scopeAndIsAlert={{
            scope: scopeWithExpressions,
            isAlertElement: false,
          }}
          elseBranchRequired={false}
          fixedResult={fixedResult}
          buttonIcon={IconEnum.Add}
          iconOnlyButton={suggestions.length !== 0}
        />
      </div>
      {suffixNode}
      {confirmExpressionModal && (
        <AddEditExpressionModal
          fixedResult={fixedResult}
          scope={scopeWithExpressions}
          resources={resources}
          initialExpression={confirmExpressionModal.expression}
          onAddExpression={onConfirmExpression}
          onEditExpression={onConfirmExpression}
          analyticsTrackingContext={"confirm-escalation-expression-suggestion"}
          existingExpressions={expressionsMethods.fields}
          onClose={() => setConfirmExpressionModal(null)}
        />
      )}
    </div>
  );
};

const EscalationBindingSuggestionBadge = ({
  bindingSuggestion,
  scope,
  catalogTypes,
  onClick,
}: {
  bindingSuggestion: BindingSuggestion;
  scope: EngineScope;
  catalogTypes: CatalogType[];
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}) => {
  let path: (MenuPathItem & { label: string | undefined })[];
  let rootCatalogType: CatalogType | undefined;

  // If it's just a reference, we can display a simpler badge
  if (isReferenceSuggestion(bindingSuggestion)) {
    const rootReference = scope.references.find(
      (r) => bindingSuggestion.key === r.key,
    );

    rootCatalogType = catalogTypes.find(
      (t) => t.engine_resource_type === rootReference?.type,
    );

    if (!rootCatalogType) {
      return null;
    }

    path = [
      {
        key: rootCatalogType.name,
        label: rootReference?.label,
      },
    ];
  } else {
    // If it's an expression suggestion, then we need to build the path
    const rootReference = scope.references.find(
      (r) => bindingSuggestion.expression?.root_reference === r.key,
    );

    rootCatalogType = catalogTypes.find(
      (t) => t.engine_resource_type === rootReference?.type,
    );

    if (!rootCatalogType) {
      return null;
    }

    path = [
      {
        key: rootCatalogType.name,
        label: rootReference?.label,
      },
      ...bindingSuggestion.path.map((pathItem) => ({
        key: pathItem.attribute_id,
        label: pathItem.attribute_name,
      })),
    ];
  }

  // We replace the initial 'Alert → Attributes' with just 'Alert' so it looks
  // a little less noisy when repeated for a few attributes.
  path = path.map((p) => ({
    ...p,
    label: p.label?.replaceAll("Alert → Attributes", "Alert"),
  }));

  return (
    <Button
      analyticsTrackingId={"suggested-escalation-binding"}
      icon={IconEnum.EscalationPath}
      iconProps={{
        className: "text-alarmalade-content",
      }}
      theme={ButtonTheme.Dashed}
      size={ButtonSize.Small}
      // I spent a good 10 minutes trying to type this correctly, sorry
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      onClick={onClick}
    >
      <div className="flex items-center truncate">
        <TruncatingReferenceLabel path={path} />
      </div>
    </Button>
  );
};
