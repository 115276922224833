import {
  IncidentPostmortemStatusEnum,
  PostmortemTemplateSection,
  PostmortemTemplateSectionTypeEnum,
} from "@incident-io/api";
import { assertUnreachable } from "@incident-io/status-page-ui";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";

export const getSectionTypeName = (
  type: PostmortemTemplateSectionTypeEnum,
): string => {
  let sectionTypeName = "";
  switch (type) {
    case PostmortemTemplateSectionTypeEnum.Custom:
      // We shouldn't hit here, but lets not explode if we do
      sectionTypeName = "Custom";
      break;
    case PostmortemTemplateSectionTypeEnum.FollowUps:
      sectionTypeName = "Follow-ups";
      break;
    case PostmortemTemplateSectionTypeEnum.Timeline:
      sectionTypeName = "Timeline";
      break;
    case PostmortemTemplateSectionTypeEnum.Summary:
      sectionTypeName = "Summary";
      break;
    case PostmortemTemplateSectionTypeEnum.KeyInformation:
      sectionTypeName = "Key Information";
      break;
    // This should only be on the backend for export
    case PostmortemTemplateSectionTypeEnum.Header:
      sectionTypeName = "Header";
      break;
    default:
      assertUnreachable(type);
  }

  return sectionTypeName;
};

export const getSectionName = (section: PostmortemTemplateSection): string => {
  if (section.type === PostmortemTemplateSectionTypeEnum.Custom) {
    if (section.config) {
      return section.config.name;
    }
  }
  return getSectionTypeName(section.type);
};

type StatusProp = {
  label: string;
  colors: ColorPaletteEnum;
};

export const StatusProps: Record<IncidentPostmortemStatusEnum, StatusProp> = {
  [IncidentPostmortemStatusEnum.NotStarted]: {
    label: "Not started",
    colors: ColorPaletteEnum.Slate,
  },
  [IncidentPostmortemStatusEnum.Created]: {
    label: "In progress",
    colors: ColorPaletteEnum.Red,
  },
  [IncidentPostmortemStatusEnum.Review]: {
    label: "In review",
    colors: ColorPaletteEnum.Yellow,
  },
  [IncidentPostmortemStatusEnum.Complete]: {
    label: "Completed",
    colors: ColorPaletteEnum.Green,
  },
};
