import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { SetupPageWrapper } from "@incident-shared/layout/SetupPageWrapper/SetupPageWrapper";
import { OrgAwareNavigate } from "@incident-shared/org-aware";
import { formatLoginWithRedirectURL } from "@incident-shared/utils/loginWithRedirect";
import {
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  IconEnum,
  Loader,
} from "@incident-ui";
import { InputType } from "@incident-ui/Input/Input";
import { AnimatePresence, motion } from "framer-motion";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
// eslint-disable-next-line no-restricted-imports
import { Navigate, useLocation } from "react-router";
import { Form } from "src/components/@shared/forms";
import { AnimatedIncidentAvatar } from "src/components/setup/common/IncidentFlameAvatar";
import {
  AuthShowWorkOSAuthURIRequest,
  AuthShowWorkOSAuthURIResponseBody,
  useClient,
  useIsAuthenticated,
} from "src/contexts/ClientContext";
import { useMutation } from "src/utils/fetchData";
import { useQueryParams } from "src/utils/query-params";
import { useAPI } from "src/utils/swr";
import validator from "validator";

import { ReactComponent as SlackLogo } from "./slack-logo.svg";

export type Location = {
  state?: {
    returnPath: string;
  };
};

export const LoginRoute = ({ slug }: { slug?: string }): React.ReactElement => {
  const location = useLocation();
  const isAuthenticated = useIsAuthenticated();
  const returnPath = location?.state?.returnPath || "/dashboard";
  const returnHost = location?.state?.returnHost || window.location.host;

  if (isAuthenticated) {
    return <OrgAwareNavigate to={"/dashboard"} replace />;
  }

  if (slug) {
    return (
      <OrgAwareLoginRoute
        returnPath={returnPath}
        returnHost={returnHost}
        organisationSlug={slug}
      />
    );
  }
  return (
    <AnonymousLoginRoute returnPath={returnPath} returnHost={returnHost} />
  );
};

export const OrgAwareLoginRoute = ({
  organisationSlug,
  returnPath,
  returnHost,
}: {
  organisationSlug: string;
  returnPath?: string;
  returnHost?: string;
}): React.ReactElement => {
  const {
    data: slugData,
    error: slugDataError,
    isLoading,
  } = useAPI("authShowPublicInformationForSlug", { slug: organisationSlug });

  if (slugDataError) {
    // bounce you to our top level login: perhaps the slug doesn't exist?
    return <Navigate to="/login" replace />;
  }

  if (isLoading || !slugData) {
    return <Loader />;
  }

  const canLoginWithoutSaml =
    !slugData.saml_enabled || slugData.saml_is_optional;

  return (
    <SetupPageWrapper footer={<TermsOfServiceNotice />}>
      <AnimatedIncidentAvatar />
      <div className="text-5xl-bold">Welcome back</div>
      <div className="bg-slate-800 rounded-3 p-6 w-[400px] flex flex-col gap-4 items-center">
        <div className="text-center text-sm text-slate-400">
          Sign in to access{" "}
          <div className="text-2xl-bold text-white truncate max-w-[320px]">
            {slugData.organisation_name}
          </div>
        </div>
        <div className="flex flex-col items-center gap-4 w-full">
          {slugData.saml_enabled && (
            <SamlLogin
              isExpanded
              setIsExpanded={() => false}
              returnPath={returnPath}
              returnHost={returnHost}
              showDivider={false}
              slug={organisationSlug}
            />
          )}
          {canLoginWithoutSaml &&
            (slugData.microsoft_signin_enabled ? (
              <MicrosoftLogin
                organisationSlug={organisationSlug}
                returnPath={returnPath}
              />
            ) : (
              <SlackLogin
                organisationSlug={organisationSlug}
                returnPath={returnPath}
                returnHost={returnHost}
              />
            ))}
        </div>
      </div>
    </SetupPageWrapper>
  );
};

const SlackLogin = ({
  organisationSlug,
  returnPath,
  returnHost,
}: {
  organisationSlug: string;
  returnPath?: string;
  returnHost?: string;
}) => {
  const loginURL = formatLoginWithRedirectURL({
    organisationSlug,
    returnPath,
    returnHost,
  });

  return (
    <Button
      analyticsTrackingId="login-with-slack"
      href={loginURL}
      className="inline-flex items-center cursor-pointer w-full !text-black hover:bg-surface-tertiary"
      data-testid="login-with-slack"
    >
      <SlackLogo className="size-[20px] mr-[12px]" />
      Sign in with Slack
    </Button>
  );
};

const MicrosoftLogin = ({
  organisationSlug,
  returnPath,
}: {
  organisationSlug: string;
  returnPath?: string;
}) => {
  const loginURL = formatLoginWithRedirectURL({
    loginURL: "/auth/microsoft_login",
    organisationSlug,
    returnPath,
  });

  return (
    <Button
      analyticsTrackingId="login-with-microsoft"
      href={loginURL}
      className="inline-flex items-center cursor-pointer"
      icon={IconEnum.Microsoft}
    >
      Sign in with Microsoft
    </Button>
  );
};

export const AnonymousLoginRoute = ({
  returnPath = "/dashboard",
  returnHost,
}: {
  returnPath?: string;
  returnHost?: string;
}): React.ReactElement => {
  const params = useQueryParams();

  let errorText: string | null = null;

  // If there's a known SAML error, map it to something readable.
  const samlErrParam = params.get("saml-err");
  if (samlErrParam && SAML_ERRORS[samlErrParam]) {
    errorText = SAML_ERRORS[samlErrParam];
  }

  // Do the same thing for Microsoft sign-in errors.
  const microsoftErrParam = params.get("microsoft_error");
  if (microsoftErrParam && MICROSOFT_ERRORS[microsoftErrParam]) {
    errorText = MICROSOFT_ERRORS[microsoftErrParam];
  }

  const queryReturnPath = params.get("return_path");
  if (returnPath === undefined && queryReturnPath) {
    returnPath = queryReturnPath;
  }
  const queryReturnHost = params.get("return_host");
  if (returnHost === undefined && queryReturnHost) {
    returnHost = queryReturnHost;
  }
  const [isSAMLExpanded, setIsSAMLExpanded] = useState(false);

  const slackLoginURL = formatLoginWithRedirectURL({
    returnPath,
    returnHost,
  });

  const microsoftLoginURL = formatLoginWithRedirectURL({
    returnPath,
    returnHost,
    loginURL: "/auth/microsoft_login",
  });

  return (
    <SetupPageWrapper
      footer={<TermsOfServiceNotice />}
      topRightAccessory={<CreateAccountButton />}
    >
      <AnimatedIncidentAvatar />
      <div className="text-5xl-bold">Welcome back</div>
      <motion.div
        key={`${isSAMLExpanded}`}
        layout="position"
        className="text-sm text-white !rounded-2 max-w-[400px] flex flex-col items-center z-10"
        initial={isSAMLExpanded ? "autoHeight" : ""}
        animate={isSAMLExpanded ? "autoHeight" : ""}
        transition={{ ease: "easeOut", layout: { duration: 0.25 } }}
        variants={{
          autoHeight: {
            height: "auto",
          },
        }}
      >
        {/* For screens wider than 640px (i.e. mobile), hardcode width to 400px */}
        <div className="text-center space-y-1 flex flex-col items-center w-[250px] sm:w-[400px]">
          <Button
            analyticsTrackingId="login-with-slack"
            href={slackLoginURL}
            className="inline-flex items-center cursor-pointer w-full !text-black hover:bg-surface-tertiary"
          >
            <SlackLogo className="size-[20px] mr-[12px]" />
            Sign in with Slack
          </Button>
          <Button
            analyticsTrackingId="login-with-microsoft"
            href={microsoftLoginURL}
            className="inline-flex items-center cursor-pointer w-full !text-black hover:bg-surface-tertiary"
            icon={IconEnum.Microsoft}
            iconProps={{ className: "size-[20px] mr-[12px]" }}
          >
            Sign in with Microsoft
          </Button>
          <SamlLogin
            isExpanded={isSAMLExpanded}
            setIsExpanded={setIsSAMLExpanded}
            returnPath={returnPath}
            returnHost={returnHost}
            showDivider={true}
          />
        </div>
        {errorText && (
          <Callout theme={CalloutTheme.Danger} className={"mt-3"}>
            {errorText}
          </Callout>
        )}
      </motion.div>
    </SetupPageWrapper>
  );
};

const CreateAccountButton = () => {
  return (
    <div className="flex items-center gap-3">
      <div className="mobile-hidden text-slate-400">New to incident.io?</div>
      <Button
        analyticsTrackingId="login-to-setup"
        href="/setup/login"
        className="bg-slate-700 hover:bg-slate-600 text-white"
        theme={ButtonTheme.UnstyledPill}
      >
        Create account
      </Button>
    </div>
  );
};

const SamlLogin = ({
  returnPath = "/dashboard",
  isExpanded,
  setIsExpanded,
  showDivider,
  slug: fixedSlug,
}: {
  isExpanded: boolean;
  setIsExpanded: React.Dispatch<React.SetStateAction<boolean>>;
  returnPath?: string;
  returnHost?: string;
  showDivider: boolean;
  slug?: string;
}): React.ReactElement | null => {
  const apiClient = useClient();
  const formMethods = useForm<AuthShowWorkOSAuthURIRequest>({
    defaultValues: { returnPath, slug: fixedSlug },
  });
  const [needsOrgSlug, setNeedsOrgSlug] = useState(false);
  const slug = formMethods.watch("slug");
  const [isRedirecting, setIsRedirecting] = useState(false);

  const [onSubmit, { saving, genericError }] = useMutation<
    AuthShowWorkOSAuthURIRequest,
    AuthShowWorkOSAuthURIResponseBody
  >(
    async (data) => {
      return apiClient.authShowWorkOSAuthURI(data);
    },
    {
      setError: formMethods.setError,
      onError: (_, fieldErrors) => {
        if (
          fieldErrors &&
          fieldErrors[0].name === "slug" &&
          fieldErrors[0].code === "is_required"
        ) {
          formMethods.clearErrors();
          setNeedsOrgSlug(true);
        }
      },
      onSuccess: (resp) => {
        // Keep the spinner spinning while WorkOS/SAML provider loads
        setIsRedirecting(true);
        window.location.href = resp.workos_auth_uri;
      },
    },
  );

  return (
    <Form.Root
      formMethods={formMethods}
      genericError={genericError}
      onSubmit={onSubmit}
      outerClassName="w-full"
    >
      <AnimatePresence>
        {isExpanded ? (
          <motion.div
            id="samlLogin"
            key="samlLogin"
            initial={{ translateY: -100, opacity: 0, height: 0 }}
            animate={{ translateY: 0, opacity: 1, height: "auto" }}
            transition={{ duration: 0.25 }}
          >
            <div className="space-y-2">
              {showDivider ? (
                <div className="border-t w-full border-stroke-invert" />
              ) : undefined}
              {fixedSlug === undefined ? (
                <InputV2
                  formMethods={formMethods}
                  name="email"
                  type={InputType.Text}
                  rules={{
                    validate: (v) =>
                      validator.isEmail(v)
                        ? undefined
                        : "Please enter a valid email address",
                  }}
                  autoComplete="email"
                  placeholder={"me@mycompany.com"}
                  disabled={needsOrgSlug}
                  errorClassName="!text-xs !mt-2 text-red-500"
                  inputClassName="bg-transparent text-white border-stroke-invert hover:border-stroke-invert-hover focus:border-stroke-invert-hover"
                />
              ) : null}
              {needsOrgSlug ? (
                <motion.div
                  id="samlLoginOrgInput"
                  key={`${needsOrgSlug}`}
                  initial={{ translateY: -50, opacity: 0, height: 0 }}
                  animate={{ translateY: 0, opacity: 1, height: "auto" }}
                  transition={{ duration: 0.25 }}
                >
                  <InputV2
                    formMethods={formMethods}
                    name="slug"
                    inputClassName="bg-transparent text-white border-stroke-invert hover:border-stroke-invert-hover focus:border-stroke-invert-hover"
                    className="text-left"
                    type={InputType.Text}
                    required="Please provide an ID"
                    placeholder={"Enter organization ID"}
                    errorClassName="!text-xs !mt-2 text-red-500"
                  />
                  <div className="mt-3 !mb-4 text-content-tertiary text-left text-xs">
                    e.g. app.incident.io/
                    <span className="font-medium text-content-primary">
                      {slug ? slug : "my-company"}
                    </span>
                  </div>
                </motion.div>
              ) : undefined}
              <Button
                analyticsTrackingId="confirm-login-with-saml"
                theme={ButtonTheme.UnstyledPill}
                className="bg-slate-700 hover:bg-slate-600 text-white w-full flex items-center justify-center"
                loading={saving || isRedirecting}
                type="submit"
              >
                Sign in with SAML SSO
              </Button>
            </div>
          </motion.div>
        ) : (
          <Button
            analyticsTrackingId="login-with-saml"
            theme={ButtonTheme.UnstyledPill}
            type={"button"}
            onClick={(e) => {
              e?.preventDefault();
              setIsExpanded(true);
            }}
            className="bg-slate-700 hover:bg-slate-600 text-white w-full flex items-center justify-center"
          >
            Sign in with SAML SSO
          </Button>
        )}
      </AnimatePresence>
    </Form.Root>
  );
};

const SAML_ERRORS: Record<string, string> = {
  "inactive-domain":
    "Your organisation has not finished setting up SAML, please contact your administrator.",
  "invalid-domain":
    "We don't have a matching email domain for your organisation, please contact your administrator.",
  "saml-failure":
    "SAML login failed, please try again or contact your administrator.",
};

export const MICROSOFT_ERRORS: Record<string, string> = {
  "invalid-tenant":
    "No incident.io account was found for that Microsoft account.",
  "login-failure":
    "Microsoft login failed, please try again or contact support.",
  "missing-email":
    "Microsoft user doesn't have an email address, so can't sign in.",
};

const TermsOfServiceNotice = () => {
  return (
    <div className="mt-3 text-content-tertiary text-center text-xs">
      By signing in, you are agreeing to our{" "}
      <a
        href="https://incident.io/terms"
        className="underline"
        rel="noopener noreferrer"
        target="_blank"
      >
        terms of service
      </a>
      .
    </div>
  );
};
