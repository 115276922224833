import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import { ContentBox, IconEnum } from "@incident-ui";
import React from "react";
import { ConditionGroup } from "src/contexts/ClientContext";
import { tcx } from "src/utils/tailwind-classes";

import {
  ConditionBadge,
  ConditionBadgeTheme,
} from "./ConditionBadge/ConditionBadge";
import { ConditionValue } from "./ConditionValue";

export type ExplanationStyle = "available" | "apply";

export const ConditionGroupsList = ({
  groups,
  boxless = false,
  iconless = false,
  mini = false,
  className,
  theme,
  noTooltip,
}: {
  groups: ConditionGroup[];
  boxless?: boolean;
  iconless?: boolean;
  mini?: boolean;
  className?: string;
  theme?: ConditionBadgeTheme;
  noTooltip?: boolean;
}): React.ReactElement => {
  return (
    <ContentBox
      className={tcx(
        "flex flex-col p-1 gap-2 bg-white",
        {
          "border-none shadow-none": boxless,
        },
        className,
      )}
      data-testid="conditions-list"
    >
      {groups.map((group, groupIdx) => (
        <GroupSection
          key={groupIdx}
          group={group}
          groupIdx={groupIdx}
          boxless={boxless}
          mini={mini}
          iconless={iconless}
          theme={theme}
          noTooltip={noTooltip}
        />
      ))}
    </ContentBox>
  );
};

const GroupSection = ({
  group,
  groupIdx,
  boxless,
  iconless,
  mini,
  theme,
  noTooltip,
}: {
  group: ConditionGroup;
  groupIdx: number;
  boxless: boolean;
  iconless: boolean;
  mini: boolean;
  theme?: ConditionBadgeTheme;
  noTooltip?: boolean;
}) => {
  const isNotFirstGroup = groupIdx !== 0;

  return (
    <div
      className={tcx(
        "flex flex-wrap gap-2",
        boxless ? "pt-1 pb-2 first-of-type:pt-0 last-of-type:pb-0" : "p-3",
        isNotFirstGroup && "border-t border-dotted border-stroke",
      )}
    >
      {/* Header section, if not the first */}
      {isNotFirstGroup && (
        <div className="font-mono text-xs-med text-content-tertiary w-full text-left">
          OR
        </div>
      )}
      {group.conditions.map((condition) => (
        <ConditionBadge
          key={condition.subject.reference}
          icon={
            iconless
              ? undefined
              : (condition.subject.icon as unknown as IconEnum)
          }
          color={condition.subject.color as unknown as ColorPaletteEnum}
          subject={condition.subject?.label ?? ""}
          operation={condition.operation.label}
          criteria={
            condition.param_bindings == null ||
            condition.param_bindings.length === 0 ? undefined : (
              <ConditionValue condition={condition} />
            )
          }
          mini={mini}
          theme={theme ? theme : "slate"}
          inline
          noTooltip={noTooltip}
        />
      ))}
    </div>
  );
};
