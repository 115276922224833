import {
  Avatar,
  Button,
  ButtonTheme,
  Heading,
  IconSize,
  Link,
} from "@incident-ui";
import { ActorAvatar } from "@incident-ui/Avatar/ActorAvatar";
import { LoadingBar } from "@incident-ui/LoadingBar/LoadingBar";
import _ from "lodash";
import {
  Actor,
  IncidentRoleWithoutConditionsRoleTypeEnum as RoleTypeEnum,
  Stream,
} from "src/contexts/ClientContext";

import { IncidentHeaderModal } from "../../../../routes/legacy/IncidentRoute";
import { useNavigateToModal } from "../../../../utils/query-params";
import { incidentInEditableStatus } from "../helpers";
import { useIncident } from "../hooks";
import { Participants, StreamParticipants } from "./Participants";

export function StreamRoleAssignments({
  stream,
}: {
  stream: Stream | null;
}): React.ReactElement {
  const assignments = _.sortBy(
    stream?.incident_role_assignments ?? [],
    // Lead, Reporter, the rest
    ({ role }, i) => {
      if (role.role_type === RoleTypeEnum.Lead) {
        return -2;
      }
      return i;
    },
  ).filter(
    // Only show assigned roles and don't show the reporter, the lead will always be assigned
    ({ role, assignee }) =>
      assignee && role.role_type !== RoleTypeEnum.Reporter,
  );

  const navigateToModal = useNavigateToModal();
  return (
    <div className="pb-4">
      <Heading level={3} size="small" className="mb-2">
        Assignees
      </Heading>
      {stream == null ? (
        <LoadingBar className="w-full h-14" />
      ) : (
        <div>
          {assignments.map(({ role, assignee }) => {
            if (role.role_type === RoleTypeEnum.Custom && !assignee)
              return null;
            return (
              <div
                className="flex-center-y text-sm justify-between"
                key={role.id}
              >
                <span className="text-content-tertiary mr-2 w-[40%]">
                  {role.stream_name}
                </span>
                <AssigneeWrapper
                  editable={true}
                  onEdit={() =>
                    navigateToModal(IncidentHeaderModal.EditRoleAssignments)
                  }
                  analyticsTrackingId={"edit-role"}
                >
                  <Avatar
                    size={IconSize.Large}
                    url={assignee?.avatar_url}
                    name={assignee?.name}
                  />
                  <span className="text-right truncate">
                    {assignee?.name || "Not assigned"}
                  </span>
                </AssigneeWrapper>
              </div>
            );
          })}
          <StreamParticipants stream={stream} />
        </div>
      )}
    </div>
  );
}

export function IncidentRoleAssignments({
  incidentId,
  onEdit,
}: {
  incidentId: string | null;
  onEdit: () => void;
}): React.ReactElement {
  const { incident } = useIncident(incidentId);
  const assignments = _.sortBy(
    incident?.incident_role_assignments ?? [],
    // Lead, Reporter, the rest
    ({ role }, i) => {
      if (role.role_type === RoleTypeEnum.Lead) {
        return -2;
      }
      return i;
    },
  ).filter(
    // Only show assigned roles, except the lead
    ({ role, assignee }) => role.role_type === RoleTypeEnum.Lead || assignee,
  );

  return (
    <div className="intercom-key-contacts pb-0.5">
      {incident == null ? (
        <LoadingBar className="w-full h-14" />
      ) : (
        <div>
          {assignments.map(({ role, assignee }) => {
            const editable =
              role.role_type !== RoleTypeEnum.Reporter &&
              incidentInEditableStatus(incident);
            if (role.role_type === RoleTypeEnum.Custom && !assignee)
              return null;
            return (
              <div
                className="flex-center-y text-sm justify-between"
                key={role.id}
              >
                <span className="text-content-tertiary mr-2 w-[40%]">
                  {role.name}
                </span>
                <AssigneeWrapper
                  editable={editable}
                  onEdit={onEdit}
                  analyticsTrackingId={"edit-role"}
                >
                  <Avatar
                    size={IconSize.Large}
                    url={assignee?.avatar_url}
                    name={assignee?.name}
                  />
                  <span className="text-right truncate">
                    {assignee?.name || "Not assigned"}
                  </span>
                </AssigneeWrapper>
              </div>
            );
          })}
          {!incident.creator.user && (
            <NonUserCreator creator={incident.creator} />
          )}
          <Participants incidentId={incident.id} />
        </div>
      )}
    </div>
  );
}

export const AssigneeWrapper = (
  props: React.PropsWithChildren<{
    editable: boolean;
    onEdit: () => void;
    analyticsTrackingId: string;
  }>,
) => {
  const wrapperStyle =
    "flex-center-y py-1 px-2 border border-transparent rounded-[6px] transition text-content-primary gap-2";

  return props.editable ? (
    <Button
      theme={ButtonTheme.Ghost}
      onClick={props.onEdit}
      analyticsTrackingId={props.analyticsTrackingId}
      className="py-1 gap-2"
    >
      {props.children}
    </Button>
  ) : (
    <div className={wrapperStyle}>{props.children}</div>
  );
};

const NonUserCreator = ({ creator }: { creator: Actor }) => {
  if (creator.api_key) {
    return (
      <div className="flex-center-y text-sm justify-between">
        <span className="text-content-tertiary mr-2 w-[40%]">
          Created via API Key
        </span>
        <div className="flex-center-y space-x-1.5 overflow-hidden">
          <ActorAvatar actor={creator} size={IconSize.Large} />
          <span className="text-right truncate">
            <Link
              analyticsTrackingId={"api-key"}
              href="/settings/api-keys"
              className="no-underline"
              openInNewTab
            >
              {creator.api_key.name}
            </Link>
          </span>
        </div>
      </div>
    );
  }
  if (creator.external_resource) {
    return (
      <div className="flex-center-y text-sm justify-between">
        <span className="text-content-tertiary mr-2 w-[40%]">Triggered by</span>
        <div className="flex-center-y p-1 space-x-1.5">
          <ActorAvatar actor={creator} size={IconSize.Large} />
          <span className="text-right truncate">
            <Link
              analyticsTrackingId={"external-resource"}
              href={creator.external_resource.permalink}
              className="no-underline"
              openInNewTab
            >
              {creator.external_resource.resource_type_label}
            </Link>
          </span>
        </div>
      </div>
    );
  }
  return null;
};
