import {
  ExpressionBranch,
  PostmortemSettings,
  PostmortemTemplate,
} from "@incident-io/api";
import { EngineLiteralBadge } from "@incident-shared/engine";
import { branchesOnlyExpressionToPayload } from "@incident-shared/engine/expressions/expressionToPayload";
import { Form } from "@incident-shared/forms";
import {
  Button,
  EmptyState,
  IconEnum,
  SharedToasts,
  StackedList,
} from "@incident-ui";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useFieldArray, useForm } from "react-hook-form";
import { useClient } from "src/contexts/ClientContext";
import { useAPIMutation } from "src/utils/swr";

import { SettingsSubHeading } from "../../../SettingsSubHeading";
import { FallbackDefaultPostmortemTemplate } from "./FallbackDefaultPostmortemTemplate";
import { EditBranchModal, EditElseBranchModal } from "./modals";
import { Branch } from "./PostmortemTemplateBranch";
import { PostmortemTemplateExpressionFormData } from "./types";

type PostmortemExpressionFormProps = {
  settings: PostmortemSettings;
  templates: PostmortemTemplate[];
};

export const PostmortemExpressionForm = ({
  settings,
  templates,
}: PostmortemExpressionFormProps) => {
  const showToast = useToast();
  const apiClient = useClient();
  const formMethods = useForm<PostmortemTemplateExpressionFormData>({
    defaultValues: {
      postmortem_template_expression:
        settings?.postmortem_template_expression || {
          branches: [],
        },
    },
  });

  const [addBranch, setAddBranch] = useState(false);
  const [editElseBranch, setEditElseBranch] = useState(false);
  const [editBranch, setEditBranch] = useState<
    { branch: ExpressionBranch & { id: string }; index: number } | undefined
  >();

  const { setError } = formMethods;

  const { trigger, isMutating } = useAPIMutation(
    "postmortemsSettingsShow",
    undefined,
    async () => {
      const data = formMethods.getValues();

      await apiClient.postmortemsSettingsUpdateTemplateExpression({
        updateTemplateExpressionRequestBody: {
          postmortem_template_expression: data.postmortem_template_expression
            ?.branches?.length
            ? branchesOnlyExpressionToPayload(
                data.postmortem_template_expression,
                false,
              )
            : undefined,
        },
      });
    },
    {
      onSuccess: () => {
        showToast(SharedToasts.SETTINGS_SAVED);
      },
      setError,
    },
  );

  const branchesFieldMethods = useFieldArray({
    control: formMethods.control,
    name: "postmortem_template_expression.branches",
  });
  const branches = branchesFieldMethods.fields;

  const elseBranch = formMethods.getValues(
    "postmortem_template_expression.else_branch",
  );
  const elseBranchTemplateID = elseBranch?.result.value?.literal;
  const elseBranchTemplate = elseBranchTemplateID
    ? templates.find((template) => template.id === elseBranchTemplateID)
    : undefined;

  const onRemoveBranch = (index: number) => {
    branchesFieldMethods.remove(index);
  };
  const onDragEnd = (result) => {
    // Only listen for drop events (ignore things like 'CANCEL' events, where
    // the user just cancelled/aborted)
    if (result.reason !== "DROP") {
      return;
    }

    // If we dropped it outside the list, no-op
    if (!result.destination) {
      return;
    }

    const fromIndex = result.source.index;
    const toIndex = result.destination.index;
    branchesFieldMethods.move(fromIndex, toIndex);
  };

  const firstTemplate = templates[0];

  return (
    <Form.Root onSubmit={trigger} formMethods={formMethods}>
      <>
        <SettingsSubHeading
          title={"Default template"}
          titleHeadingSize="small"
          explanation={`You can use conditions to set a default template for writing a post-mortem.`}
          accessory={
            branches.length > 0 && (
              <div className={`shrink-0`}>
                <Button
                  analyticsTrackingId={null}
                  icon={IconEnum.Add}
                  onClick={() => setAddBranch(true)}
                >
                  Add condition
                </Button>
              </div>
            )
          }
        />

        {branches.length > 0 && (
          <StackedList className="text-sm">
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="main_template">
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {branches.map((branch, index) => (
                      <Draggable
                        isDragDisabled={branches.length === 1}
                        key={branch.id}
                        draggableId={branch.id}
                        index={index}
                      >
                        {(provided) => (
                          <Branch
                            fieldName={`postmortem_template_expression.branches.${index}`}
                            key={branch.id}
                            isFirstItem={index === 0}
                            branch={branch}
                            prefix={index === 0 ? "If" : "Else if"}
                            templates={templates}
                            onEdit={() => setEditBranch({ branch, index })}
                            onDelete={() => onRemoveBranch(index)}
                            isOnlyBranch={branches.length === 1}
                            draggableProvided={provided}
                          />
                        )}
                      </Draggable>
                    ))}
                  </div>
                )}
              </Droppable>
            </DragDropContext>

            <FallbackDefaultPostmortemTemplate
              elseBranchTemplate={elseBranchTemplate ?? firstTemplate}
              setEditElseBranch={setEditElseBranch}
            />
          </StackedList>
        )}

        {branches.length === 0 && (
          <EmptyState
            icon={IconEnum.Doc}
            cta={
              <Button
                analyticsTrackingId={null}
                onClick={() => setAddBranch(true)}
                icon={IconEnum.Add}
              >
                Add condition
              </Button>
            }
            content={
              <>
                <div className="flex flex-wrap gap-1 items-center justify-center">
                  With this configuration, new post-mortems will be created with
                  the{" "}
                  <EngineLiteralBadge
                    icon={IconEnum.Doc}
                    label={firstTemplate.name}
                  />{" "}
                  template.
                </div>
              </>
            }
          />
        )}

        {editElseBranch && (
          <EditElseBranchModal
            templates={templates}
            onEditBranch={(elseBranch) => {
              formMethods.setValue(
                "postmortem_template_expression.else_branch",
                elseBranch,
              );
              formMethods.clearErrors();
              setEditElseBranch(false);
            }}
            onClose={() => setEditElseBranch(false)}
          />
        )}

        {editBranch && (
          <EditBranchModal
            templates={templates}
            onEditBranch={(newBranch) => {
              branchesFieldMethods.update(editBranch.index, newBranch);
              setEditBranch(undefined);
            }}
            branch={editBranch.branch}
            onClose={() => setEditBranch(undefined)}
          />
        )}

        {addBranch && (
          <EditBranchModal
            templates={templates}
            onEditBranch={(newBranch) => {
              branchesFieldMethods.append(newBranch);
              if (
                !formMethods.getValues<"postmortem_template_expression.else_branch">(
                  "postmortem_template_expression.else_branch",
                )
              ) {
                formMethods.setValue<"postmortem_template_expression.else_branch">(
                  "postmortem_template_expression.else_branch",
                  {
                    result: {},
                  },
                );
              }
              setAddBranch(false);
            }}
            onClose={() => setAddBranch(false)}
          />
        )}
        <div className="flex justify-end">
          <Button type="submit" analyticsTrackingId={null} loading={isMutating}>
            Save
          </Button>
        </div>
      </>
    </Form.Root>
  );
};
