/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CallParticipant,
    CallParticipantFromJSON,
    CallParticipantFromJSONTyped,
    CallParticipantToJSON,
} from './CallParticipant';

/**
 * 
 * @export
 * @interface CallSession
 */
export interface CallSession {
    /**
     * When the call session was ended
     * @type {Date}
     * @memberof CallSession
     */
    ended_at?: Date;
    /**
     * ID of the call session
     * @type {string}
     * @memberof CallSession
     */
    id: string;
    /**
     * Participants in the call session
     * @type {Array<CallParticipant>}
     * @memberof CallSession
     */
    participants?: Array<CallParticipant>;
    /**
     * When the call session was started
     * @type {Date}
     * @memberof CallSession
     */
    started_at: Date;
}

export function CallSessionFromJSON(json: any): CallSession {
    return CallSessionFromJSONTyped(json, false);
}

export function CallSessionFromJSONTyped(json: any, ignoreDiscriminator: boolean): CallSession {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ended_at': !exists(json, 'ended_at') ? undefined : (new Date(json['ended_at'])),
        'id': json['id'],
        'participants': !exists(json, 'participants') ? undefined : ((json['participants'] as Array<any>).map(CallParticipantFromJSON)),
        'started_at': (new Date(json['started_at'])),
    };
}

export function CallSessionToJSON(value?: CallSession | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ended_at': value.ended_at === undefined ? undefined : (value.ended_at.toISOString()),
        'id': value.id,
        'participants': value.participants === undefined ? undefined : ((value.participants as Array<any>).map(CallParticipantToJSON)),
        'started_at': (value.started_at.toISOString()),
    };
}

