import { Expression } from "@incident-io/api";
import { expressionToPayload } from "@incident-shared/engine/expressions/expressionToPayload";
import {
  ButtonTheme,
  Callout,
  CalloutTheme,
  Icon,
  IconEnum,
  IconSize,
} from "@incident-ui";
import { Button, ButtonSize } from "@incident-ui/Button/Button";
import { Loader } from "@incident-ui/Icon/icons";
import {
  PopoverDropdownMenu,
  PopoverDropdownMenuItem,
} from "@incident-ui/PopoverDropdownMenu/PopoverDropdownMenu";

import { useAPI, useAPIMutation } from "../../../../../utils/swr";
import { useRevalidate } from "../../../../../utils/use-revalidate";
import { stripInvalidBindings } from "../../stripInvalidBindings";
import { AlertSourceSetupProps } from "./types";

export const AlertSourceStatusPageViewsSetupInfo = ({
  alertSourceConfig,
  schema,
}: AlertSourceSetupProps) => {
  const refreshAlerts = useRevalidate(["alertsPreviewSourceConfigAlerts"]);

  const {
    data: { status_pages: pages },
    error: pagesError,
    isLoading: pagesLoading,
  } = useAPI("statusPageList", undefined, {
    fallbackData: { status_pages: [] },
  });

  const template = alertSourceConfig?.template;
  const {
    trigger: createAlert,
    isMutating: isCreating,
    genericError,
  } = useAPIMutation(
    "alertsPreviewSourceConfigAlerts",
    {
      id: alertSourceConfig.id,
      previewSourceConfigAlertsRequestBody: {
        template: stripInvalidBindings({
          expressions: template.expressions.map((e) =>
            expressionToPayload(e as unknown as Expression),
          ),
          title: template.title,
          description: template.description,
          bindings: template.bindings,
        }),
        attributes: schema.attributes,
        version: schema.version,
      },
    },
    async (apiClient, { id }) => {
      await apiClient.statusPageCreateTestAlert({
        id,
      });
    },
    {
      onSuccess: refreshAlerts,
    },
  );

  if (pagesLoading || pagesError) {
    <Loader />;
  }

  const statusPageEntries: PopoverDropdownMenuItem[] = pages.map((sp) => ({
    label: sp.name,
    value: sp.id,
    icon: IconEnum.StatusPage,
  }));

  return (
    <div className="bg-slate-50 border border-slate-100 rounded-2 p-5 flex flex-col gap-4">
      <div className="flex">
        <div className="flex-center rounded-full border border-green-600 w-4 h-4 mt-0.5 mr-3">
          <Icon
            id={IconEnum.Tick}
            size={IconSize.Small}
            className="text-green-600"
          />
        </div>
        <div>
          <strong>No further set up required</strong>
          <p>{`${alertSourceConfig.name} is already connected and sending alerts to incident.io. You may continue to the next step.`}</p>

          <div className="mt-4">
            {genericError ? (
              <Callout theme={CalloutTheme.Danger}>
                Error sending test alert
              </Callout>
            ) : (
              <PopoverDropdownMenu
                options={statusPageEntries}
                onSelect={(item) => createAlert({ id: item.value })}
                elementName="status pages"
                triggerButton={
                  <Button
                    theme={ButtonTheme.Secondary}
                    analyticsTrackingId={null}
                    size={ButtonSize.Small}
                    loading={isCreating}
                  >
                    Send test alert
                  </Button>
                }
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
