import { renderCustomFieldValues } from "@incident-shared/custom-fields/renderCustomFieldValues";
import { Button, ButtonTheme, Heading, IconEnum, IconSize } from "@incident-ui";
import _, { sortBy } from "lodash";
import React from "react";
import {
  CustomFieldEntry,
  CustomFieldTypeInfoFieldTypeEnum,
  Incident,
  IncidentApplicableFields,
} from "src/contexts/ClientContext";
import { useIsWithinMicrosoftTeamsTab } from "src/contexts/MicrosoftTeamsTabContext";

import { incidentInEditableStatus } from "../helpers";
import { IncidentSidebarEntry } from "./IncidentSidebarEntry";

type Props = {
  onEdit: () => void;
  entries: Array<CustomFieldEntry>;
  incident: Incident;
  applicableFields: IncidentApplicableFields;
};

export function CustomFieldEntries({
  entries,
  onEdit,
  incident,
  applicableFields,
}: Props): React.ReactElement | null {
  const isWithinTeamsTab = useIsWithinMicrosoftTeamsTab();
  const entriesToShow = entries
    // Exclude custom fields that haven't been set
    .filter(({ values }) => !_.isEmpty(values))
    // Exclude custom fields that aren't applicable to this incident, and haven't been manually edited
    .filter(({ custom_field }) => {
      if (applicableFields.custom_fields.includes(custom_field.id)) {
        return true;
      }

      const hasBeenManuallyEdited = incident.manual_edits?.some(
        (edit) => edit.custom_field_id === custom_field.id,
      );

      return hasBeenManuallyEdited;
    });

  if (
    entriesToShow.length === 0 &&
    applicableFields.custom_fields.length === 0
  ) {
    return null;
  }

  return (
    <>
      <div className="overflow-clip pb-1.5">
        <div className="flex mb-1 items-center gap-1">
          <Heading level={3} size="small">
            Custom fields
          </Heading>
          {incidentInEditableStatus(incident) ? (
            <Button
              analyticsTrackingId="edit-custom-field"
              theme={ButtonTheme.Naked}
              onClick={onEdit}
              icon={IconEnum.Edit}
              iconProps={{ size: IconSize.Medium }}
              title="edit"
            />
          ) : null}
        </div>
        {entriesToShow.length === 0 ? (
          <p className="text-content-tertiary">No custom fields set</p>
        ) : (
          <div className="space-y-2">
            {sortBy(entriesToShow, ({ custom_field }) => custom_field.rank).map(
              ({ custom_field: customField, values }) => {
                return (
                  <IncidentSidebarEntry
                    key={customField.id}
                    label={customField.name}
                    value={renderCustomFieldValues(
                      customField,
                      values,
                      isWithinTeamsTab,
                    )}
                    allowWrap={
                      customField.field_type ===
                      CustomFieldTypeInfoFieldTypeEnum.Text
                    }
                    align={customField.catalog_info ? "center" : "baseline"}
                  />
                );
              },
            )}
          </div>
        )}
      </div>
    </>
  );
}
