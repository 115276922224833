import {
  CatalogEntry,
  StatusPagePageTypeEnum,
  StatusPageParentPageOptions,
} from "@incident-io/api";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { SortableListV2 } from "@incident-shared/forms/v2/inputs/SortableListV2";
import { StaticSingleSelectV2 } from "@incident-shared/forms/v2/inputs/StaticSelectV2";
import {
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  IconEnum,
} from "@incident-ui";
import React, { ReactElement, useEffect, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { useIntercom } from "react-use-intercom";
import { useAPI } from "src/utils/swr";

import { TooltipButton } from "../../../legacy/status-page-config/shared/TooltipButton";
import { ParentFormType } from "../../create/BrandingForm";
import {
  AutoCreateComponentForm,
  CatalogImportComponentForm,
} from "../create/SubPageCreateForm";

export type SubPageComponentsFormType = Pick<
  StatusPageParentPageOptions,
  "split_by_component_attribute_id"
>;

export const SubPageComponentsForm = ({
  catalogEntries,
}: {
  catalogEntries?: CatalogEntry[];
}) => {
  const formMethods = useFormContext<ParentFormType>();
  const { watch } = formMethods;
  const autoCreateCatalog = watch("mode");
  if (autoCreateCatalog === "auto_create") {
    return <SubPageManualComponentsForm />;
  }
  return <SubPageCatalogImportComponentsForm catalogEntries={catalogEntries} />;
};

export const SubPageManualComponentsForm = (): React.ReactElement => {
  return (
    <div>
      <AutoCreateComponentsEditor />
    </div>
  );
};

export const SubPageCatalogImportComponentsForm = ({
  catalogEntries,
}: {
  catalogEntries?: CatalogEntry[];
}): React.ReactElement => {
  const parentFormMethods = useFormContext<ParentFormType>();
  const componentFormMethods = useFormContext<CatalogImportComponentForm>();

  const [
    splitByCatalogTypeId,
    splitByComponentAttributeId,
    groupByAttributeId,
    subPages,
  ] = componentFormMethods.watch([
    "split_by_catalog_type_id",
    "components_defined_by_catalog_attribute_id",
    "group_by_defined_by_catalog_attribute_id",
    "catalog_import_sub_pages",
  ]);

  const { data, isLoading } = useAPI(
    splitByCatalogTypeId ? "catalogListEntries" : null,
    {
      catalogTypeId: splitByCatalogTypeId as string,
      pageSize: 50,
      search: "",
      includeReferences: true,
    },
  );

  const { data: typeData } = useAPI("catalogListTypes", {});
  const attributes = data?.catalog_type.schema.attributes;
  const allowedAttributes = attributes?.filter((attribute) => attribute.array);
  const selectedType = typeData?.catalog_types.find((type) => {
    const selectedAttribute = allowedAttributes?.find(
      (attr) => attr.id === splitByComponentAttributeId,
    );
    return (
      splitByComponentAttributeId &&
      selectedAttribute &&
      type.type_name === selectedAttribute.type
    );
  });
  const { data: componentTypeData, error: componentTypeError } = useAPI(
    selectedType ? "catalogShowType" : null,
    {
      id: selectedType?.id ?? "",
    },
  );
  const attributeOptions = allowedAttributes?.map((attribute) => ({
    label: attribute.name,
    value: attribute.id,
  }));
  // Filter out array attributes (we can't use them) as we need to be able to resolve
  // a single group for each option.
  // Also Text attributes (they're a bad idea as they're rich text, likely to be
  // descriptions)
  //
  // Code lifted from src/components/settings/custom-fields/ConfigureCatalogTypeCustomField.tsx
  const allowedGroupAttributes =
    componentTypeData?.catalog_type.schema.attributes.filter(
      (a) => !a.array && !["Text", "Bool", "Number"].includes(a.type),
    );
  const allowedGroupAttributeOptions = allowedGroupAttributes?.map(
    (attribute) => ({
      label: attribute.name,
      value: attribute.id,
    }),
  );

  const allowedEmailAttributes = attributes?.filter((a) =>
    a.type.includes("String"),
  );

  const allowedEmailAttributeOptions = allowedEmailAttributes?.map(
    (attribute) => ({
      label: attribute.name,
      value: attribute.id,
    }),
  );

  const firstPageId = subPages ? Object.keys(subPages)[0] : undefined;
  const firstPage = subPages && firstPageId ? subPages[firstPageId] : undefined;

  const { data: structureData } = useAPI(
    firstPage && splitByCatalogTypeId && splitByComponentAttributeId
      ? "statusPageBuildSubPageStructure"
      : null,
    {
      parentSplitByCatalogTypeId: splitByCatalogTypeId,
      parentSplitByComponentAttributeId: splitByComponentAttributeId,
      parentSplitByComponentTypeId: splitByComponentAttributeId,
      parentGroupByCatalogId: groupByAttributeId,
      definedByCatalogEntryIds: firstPageId ? [firstPageId] : [],
      name: firstPage?.name,
      subpath: firstPage?.subpath,
    },
  );

  const { showArticle } = useIntercom();

  const pageType = parentFormMethods.watch("parent_page_type");

  // Disable splitting by catalog attribute if there are no catalog entries
  // or if there are too many for a sensible amount of sub-pages (25 for status page, 100 for customer pages)
  const catalogEntriesLength = catalogEntries?.length ?? 0;
  const componentsDefinedByCatalogAttributeDisabled =
    !splitByCatalogTypeId ||
    catalogEntriesLength === 0 ||
    (pageType === StatusPagePageTypeEnum.Customer
      ? catalogEntriesLength > 100
      : catalogEntriesLength > 25);

  return (
    <>
      <div className="space-y-4">
        <>
          <StaticSingleSelectV2
            noOptionsMessage={() => (
              <div>
                There are no valid attributes on this catalog type.{" "}
                <Button
                  theme={ButtonTheme.Link}
                  onClick={() => showArticle(8391002)}
                  analyticsTrackingId={
                    "status-page-sub-pages-select-component-attribute-invalid-help"
                  }
                >
                  Learn more
                </Button>
              </div>
            )}
            formMethods={parentFormMethods}
            label={"Which catalog attribute represents your components?"}
            disabled={componentsDefinedByCatalogAttributeDisabled}
            placeholder={"Select an attribute"}
            isLoading={isLoading}
            options={attributeOptions ?? []}
            name="components_defined_by_catalog_attribute_id"
            required={
              "You must select the attribute that defines which components are on each page"
            }
            isClearable={true}
          />
          {pageType === StatusPagePageTypeEnum.Customer && (
            <>
              <StaticSingleSelectV2
                disabled={allowedEmailAttributeOptions === undefined}
                formMethods={parentFormMethods}
                label={
                  "Which catalog attribute represents allowed email domains?"
                }
                placeholder={"Select an attribute"}
                isLoading={isLoading}
                options={allowedEmailAttributeOptions ?? []}
                name="email_whitelist_defined_by_attribute_id"
                isClearable={true}
                required={
                  "You must select an attribute to allow your customers to access their status page"
                }
              />
            </>
          )}
          {!componentTypeError && (
            <>
              <StaticSingleSelectV2
                disabled={allowedGroupAttributes === undefined}
                formMethods={componentFormMethods}
                label={"How would you like to group your components?"}
                placeholder={"Select an attribute"}
                isLoading={isLoading}
                options={allowedGroupAttributeOptions ?? []}
                name="group_by_defined_by_catalog_attribute_id"
                isClearable={true}
              />
            </>
          )}
          {componentTypeError ||
            (splitByComponentAttributeId &&
              !!structureData?.sub_page_structures.size && (
                <Callout theme={CalloutTheme.Danger}>
                  This catalog type appears to have no valid entries. Please
                  select a different one.
                </Callout>
              ))}
        </>
      </div>
    </>
  );
};

const AutoCreateComponentsEditor = (): ReactElement => {
  const componentFormMethods = useFormContext<AutoCreateComponentForm>();

  const arrayMethods = useFieldArray({
    control: componentFormMethods.control,
    name: "auto_create_components",
  });

  const components = componentFormMethods.watch("auto_create_components");

  const [componentsAdded, setComponentsAdded] = useState(0);
  const componentLength = components.length;

  const { setFocus } = componentFormMethods;

  useEffect(() => {
    if (componentsAdded > 0 && componentLength > 0) {
      setFocus(`auto_create_components.${componentLength - 1}.name`);
    }
  }, [componentsAdded, componentLength, setFocus]);

  return (
    <div className="space-y-4 mt-2">
      <div>
        <h3 className="font-medium">Components</h3>
      </div>
      <SortableListV2
        canEdit
        name={"auto_create_components"}
        formMethods={componentFormMethods}
        arrayMethods={arrayMethods}
        showErrorAboveComponent
        renderItem={({ index: itemIndex, dragHandle, deleteItem }) => {
          return (
            <AutoCreateComponentEditor
              index={itemIndex}
              dragHandle={dragHandle}
              deleteItem={deleteItem}
            />
          );
        }}
      />
      <Button
        onClick={() => {
          const id = `${Date.now()}`;
          componentFormMethods.setValue("auto_create_components", [
            ...components,
            {
              id,
              name: "",
            },
          ]);
          setComponentsAdded(componentsAdded + 1);
        }}
        icon={IconEnum.Add}
        theme={ButtonTheme.Secondary}
        analyticsTrackingId="status-page-sub-pages-add-component"
        title={"Add component"}
      >
        Add component
      </Button>
    </div>
  );
};

const AutoCreateComponentEditor = ({
  index,
  deleteItem,
  dragHandle,
}: {
  index: number;
  deleteItem: () => void;
  dragHandle: React.ReactNode;
}): ReactElement => {
  const formMethods = useFormContext<AutoCreateComponentForm>();
  const { setValue, watch } = formMethods;
  const components = watch("auto_create_components");

  return (
    <li className="w-full flex flex-row items-center space-x-2 pr-4">
      {dragHandle}
      <InputV2
        className="text-slate-900 flex items-center space-x-1 w-full mb-1 mt-1"
        inputClassName="border-none focus:!ring-1 focus:ring-slate-500"
        formMethods={formMethods}
        name={`auto_create_components.${index}.name`}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
            e.stopPropagation();
            const id = `${Date.now()}`;
            setValue("auto_create_components", [
              ...(components || []),
              {
                id,
                name: "",
              },
            ]);
          } else if (e.key === "Backspace" && e.currentTarget.value === "") {
            e.preventDefault();
            e.stopPropagation();
            deleteItem();
          }
        }}
      />
      <TooltipButton
        iconName={IconEnum.Delete2}
        title="Delete component"
        onClick={deleteItem}
      />
    </li>
  );
};
