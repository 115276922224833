import {
  FormCustomFieldEntries,
  marshallCustomFieldEntriesToRequestPayload,
} from "@incident-shared/forms/v2/CustomFieldFormElement";
import {
  marshallFormElementDataToIncidentForm,
  marshallIncidentResponseToFormElementData,
  marshallIncidentRolesToFormData,
  marshallTextDocumentPayload,
  SharedIncidentFormData,
  UpdateFormData,
} from "@incident-shared/incident-forms";
import {
  FormElements,
  useElementBindings,
} from "@incident-shared/incident-forms";
import { OrgAwareNavigate } from "@incident-shared/org-aware";
import { Callout, CalloutTheme, LoadingModal, ModalFooter } from "@incident-ui";
import React, { useState } from "react";
import { FieldNamesMarkedBoolean, useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { Form } from "src/components/@shared/forms";
import {
  CustomField,
  ErrorResponse,
  Incident,
  IncidentAlertStateEnum,
  IncidentFormLifecycleElementBinding,
  IncidentFormsGetLifecycleElementBindingsRequestBodyIncidentFormTypeEnum as IncidentFormType,
  IncidentRole,
  IncidentsResolveRequestBody,
  IncidentsResolveRequestBodyPostIncidentFlowDecisionEnum as PostIncidentFlowDecision,
  IncidentStatus,
  IncidentStatusCategoryEnum,
  IncidentTimestamp,
  PostIncidentFlow,
} from "src/contexts/ClientContext";
import { IncidentHeaderModal } from "src/routes/legacy/IncidentRoute";
import { useAPI, useAPIMutation, useAPIRefetch } from "src/utils/swr";

import {
  getCustomFieldErrorPath,
  isCustomFieldError,
} from "../ActiveIncidentCreateModal";
import { FormIncidentRoleAssignments } from "../IncidentRoleFormElement";
import { Category, isCategory } from "../statuses/status-utils";
import { OpenStreamsBlockResolvingModal } from "../streams/OpenStreamsWarning";
import {
  IncidentCrudResourceTypes,
  useIncidentCrudResources,
  useStatusesForIncident,
} from "../useIncidentCrudResources";
import {
  doPendingAlertsRequireAction,
  PendingAlertsBlockerWarning,
} from "./PendingAlertsNeedingUpdateModal";
import { PostIncidentFlowOptInModal } from "./PostIncidentFlowOptInModal";

export type ResolveFormData = SharedIncidentFormData & {
  post_incident_flow_decision: PostIncidentFlowDecision;
  name: string; // Not optional!
  severity_id: string; // Not optional!
} & Pick<UpdateFormData, "message">;

const convertIncidentResponseToFormData = (
  responseBody: Incident,
  ourCustomFields: CustomField[],
  ourIncidentRoles: IncidentRole[],
  ourTimestamps: IncidentTimestamp[],
  bufferedUpdate?: UpdateFormData,
): Partial<ResolveFormData> => {
  if (!responseBody.severity) {
    // We'll only see a null severity for triage incidents, and we can only decline them rather than resolve.
    throw new Error("Cannot resolve an incident without a severity");
  }
  const elementFormData = marshallIncidentResponseToFormElementData(
    ourCustomFields,
    ourTimestamps,
    ourIncidentRoles,
    responseBody,
  );

  return {
    ...elementFormData,
    name: responseBody.name,
    severity_id: responseBody.severity.id,
    post_incident_flow_decision: PostIncidentFlowDecision.None,
    summary: responseBody.summary,
    message: bufferedUpdate?.message,
  };
};

const resolveFormDataToRequestBody = (
  ourElementBindings: IncidentFormLifecycleElementBinding[],
  postIncidentDecision: PostIncidentDecisionType,
  formData: ResolveFormData,
  touchedFields: Partial<Readonly<FieldNamesMarkedBoolean<ResolveFormData>>>,
  bufferedUpdate?: UpdateFormData,
): IncidentsResolveRequestBody => {
  const incidentFormRequestBody = marshallFormElementDataToIncidentForm(
    ourElementBindings,
    formData,
    touchedFields,
  );

  return {
    ...formData,
    ...incidentFormRequestBody,
    post_incident_flow_decision: postIncidentDecision,
    summary: marshallTextDocumentPayload(formData.summary),
    message: marshallTextDocumentPayload(bufferedUpdate?.message),
    to_severity_id: formData.severity_id,
  };
};

export const ResolveIncidentModal = ({
  incident,
  onClose,
}: {
  incident: Incident;
  onClose: () => void;
}): React.ReactElement => {
  const { loading, ...resources } = useIncidentCrudResources();
  const { statusesLoading, statuses } = useStatusesForIncident({ incident });

  if (loading || statusesLoading) {
    return <LoadingModal onClose={onClose} />;
  }

  return (
    <IncidentResolveForm
      onClose={onClose}
      incident={incident}
      statuses={statuses}
      {...resources}
    />
  );
};

export const IncidentResolveForm = ({
  onClose,
  incident,
  incidentTimestamps,
  incidentRoles,
  statuses,
  customFields,
  bufferedUpdate,
}: {
  onClose: (inc?: Incident) => void;
  incident: Incident;
  statuses: IncidentStatus[];
  bufferedUpdate?: UpdateFormData;
} & IncidentCrudResourceTypes): React.ReactElement => {
  const [showPostIncidentFlowOptInModal, setShowPostIncidentFlowOptInModal] =
    useState(false);

  const closedStatus = statuses.find(isCategory(Category.Closed));

  const formMethods = useForm<ResolveFormData>({
    defaultValues: convertIncidentResponseToFormData(
      incident,
      customFields,
      incidentRoles,
      incidentTimestamps,
    ),
  });
  const { getValues, handleSubmit, watch, setError, reset, setValue } =
    formMethods;

  const [
    selectedSeverityID,
    selectedCustomFieldEntries,
    selectedIncidentRoleAssignments,
    selectedPostIncidentFlowDecision,
  ] = watch([
    "severity_id",
    "custom_field_entries",
    "incident_role_assignments",
    "post_incident_flow_decision",
  ]);

  const incidentType = incident.incident_type;

  const { data: listAlertsResponse, isLoading: isLoadingPendingAlerts } =
    useAPI("alertsListIncidentAlerts", { incidentId: incident.id }, {});
  const incidentAlerts = listAlertsResponse?.incident_alerts ?? [];
  const pendingAlerts = incidentAlerts.filter(
    (i) => i.state === IncidentAlertStateEnum.Pending,
  );
  const alertsRequireAction = doPendingAlertsRequireAction({
    pendingAlerts,
    selectedStatusID: statuses.find(isCategory(Category.Closed))?.id,
    statuses: statuses,
  });

  const {
    data: { streams },
    isLoading: streamsLoading,
  } = useAPI(
    "streamsList",
    { parentId: incident.id },
    { fallbackData: { streams: [] } },
  );
  const openStreams = streams.filter(
    (stream) => stream.status.category === IncidentStatusCategoryEnum.Active,
  );

  const {
    postIncidentFlow,
    postIncidentFlowDecision,
    isLoading: loadingPostIncidentFlow,
  } = usePostIncidentFlowDecision({
    selectedSeverityID,
    selectedIncidentTypeID: incidentType?.id,
    selectedIncidentMode: incident.mode,
    customFields,
    selectedCustomFieldEntries,
    incidentRoles,
    selectedIncidentRoleAssignments,
    touchedFields: formMethods.formState.touchedFields,
    incidentId: incident.id,
  });
  const hasPostIncidentStatuses = postIncidentFlow !== undefined;
  const showPostIncidentFlowOptInModalOnSubmit =
    !entersPostIncident(postIncidentFlowDecision) && hasPostIncidentStatuses;

  // Figure out which status we are about to move to!
  // By default assume that we're going to close the incident
  let toStatus = closedStatus;
  if (
    [
      PostIncidentFlowDecision.AutomaticOptIn,
      PostIncidentFlowDecision.OptIn,
    ].includes(selectedPostIncidentFlowDecision)
  ) {
    toStatus = postIncidentFlow?.incident_statuses[0];
  }

  const customFieldEntries = marshallCustomFieldEntriesToRequestPayload(
    customFields,
    formMethods.formState.touchedFields,
    selectedCustomFieldEntries,
  );

  const incidentRoleAssignments = marshallIncidentRolesToFormData({
    incidentRoles,
    formAssignments: selectedIncidentRoleAssignments,
  });

  const refetchIncident = useAPIRefetch("incidentsShow", { id: incident.id });
  const refetchPostIncidentTasks = useAPIRefetch("postIncidentFlowListTasks", {
    incidentId: incident.id,
  });
  const elementBindingsPayload = {
    incident_form_type: IncidentFormType.Resolve,
    incident_type_id: incidentType?.id,
    incident_status_id: toStatus?.id as string,
    severity_id: selectedSeverityID,
    custom_field_entries: customFieldEntries,
    incident_role_assignments: incidentRoleAssignments,
    show_all_elements_override: false,
    incident_id: incident.id,
  };
  const { elementBindings, isLoading: isLoadingElementBindings } =
    useElementBindings<ResolveFormData>({
      payload: elementBindingsPayload,
      setValue,
      initialValues: formMethods.formState.defaultValues,
      touchedFields: formMethods.formState.touchedFields,
      manualEdits: incident.manual_edits,
    });

  // If changing this, replicate changes in UpdateIncidentModal
  const {
    trigger: onSubmit,
    isMutating: saving,
    genericError,
  } = useAPIMutation(
    "incidentsShow",
    { id: incident.id },
    async (apiClient, formData: ResolveFormData) => {
      const resolveRequestBody = resolveFormDataToRequestBody(
        elementBindings,
        postIncidentFlowDecision,
        formData,
        formMethods.formState.touchedFields,
        bufferedUpdate,
      );
      await apiClient.incidentsResolve({
        incidentId: incident.id,
        resolveRequestBody,
      });

      await Promise.all([refetchIncident(), refetchPostIncidentTasks()]);
    },
    {
      onSuccess: () => {
        onClose();
        reset();
      },
      setError: (path, error) => {
        if (!isCustomFieldError(path)) {
          setError(path, error);
          return;
        }

        const resolveRequestBody = resolveFormDataToRequestBody(
          elementBindings,
          postIncidentFlowDecision,
          getValues(),
          formMethods.formState.touchedFields,
          bufferedUpdate,
        );
        setError(
          getCustomFieldErrorPath<ResolveFormData>(
            path,
            resolveRequestBody.custom_field_entries ?? [],
          ),
          error,
        );
      },
    },
  );

  if (streamsLoading) {
    return <LoadingModal onClose={onClose} />;
  }

  // If an incident is in the post-incident flow, redirect them to the opt out page
  if (
    incident.incident_status.category ===
    IncidentStatusCategoryEnum.PostIncident
  ) {
    return (
      <OrgAwareNavigate
        to={`/incidents/${incident.external_id}/${IncidentHeaderModal.OptOutOfPostIncident}`}
        replace
      />
    );
  }

  if (postIncidentFlow && showPostIncidentFlowOptInModal) {
    return (
      <PostIncidentFlowOptInModal
        onClose={onClose}
        incident={incident}
        flow={postIncidentFlow}
        resolveRequestBody={resolveFormDataToRequestBody(
          elementBindings,
          postIncidentFlowDecision,
          getValues(),
          formMethods.formState.touchedFields,
          bufferedUpdate,
        )}
      />
    );
  }

  if (openStreams.length > 0) {
    return (
      <OpenStreamsBlockResolvingModal
        openStreams={openStreams}
        incident={incident}
        onClose={onClose}
      />
    );
  }

  return (
    <Form.Modal
      formMethods={formMethods}
      title={"Resolve incident"}
      analyticsTrackingId="resolve-incident"
      disableQuickClose
      onClose={onClose}
      onSubmit={onSubmit}
      genericError={genericError}
      footer={
        <ModalFooter
          onClose={onClose}
          confirmButtonType="button"
          confirmButtonText={
            showPostIncidentFlowOptInModalOnSubmit ? "Next" : "Resolve incident"
          }
          onConfirm={handleSubmit((formData: ResolveFormData) => {
            if (showPostIncidentFlowOptInModalOnSubmit) {
              setShowPostIncidentFlowOptInModal(true);
              return;
            }

            onSubmit(formData);
          })}
          analyticsTrackingId="resolve-incident-submit"
          saving={saving}
          disabled={
            loadingPostIncidentFlow ||
            alertsRequireAction ||
            isLoadingPendingAlerts ||
            isLoadingElementBindings
          }
        />
      }
    >
      <PendingAlertsBlockerWarning
        alertsRequireAction={alertsRequireAction}
        pendingAlerts={pendingAlerts}
        incident={incident}
      />
      {/* Explanation */}
      {!hasPostIncidentStatuses ? (
        <Callout theme={CalloutTheme.Info}>
          <>
            Once you&apos;ve marked the incident as resolved, we&apos;ll close
            it automatically.
          </>
        </Callout>
      ) : postIncidentFlowDecision === PostIncidentDecision.AutomaticOptIn ? (
        <Callout theme={CalloutTheme.Info}>
          <>
            Once you&apos;ve marked the incident as resolved, it&apos;ll enter
            the{" "}
            <Link
              to={`/settings/lifecycle?tab=lifecycles`}
              className="underline"
            >
              post-incident flow
            </Link>
            .
          </>
        </Callout>
      ) : (
        <Callout theme={CalloutTheme.Info}>
          <>
            Once you&apos;ve marked the incident as resolved, you can close it
            or enter the{" "}
            <Link
              to={`/settings/lifecycle?tab=lifecycles`}
              className="underline"
            >
              post-incident flow
            </Link>
            .
          </>
        </Callout>
      )}
      {/* All form elements (name, summary, severity,...) */}
      <FormElements
        elementBindings={elementBindings}
        incident={incident}
        formMethods={formMethods}
        selectedIncidentType={incidentType}
        incidentFormType={IncidentFormType.Resolve}
        customFieldEntryPayloads={customFieldEntries}
        manualEdits={incident.manual_edits}
      />
    </Form.Modal>
  );
};

export const PostIncidentDecision = PostIncidentFlowDecision;
export type PostIncidentDecisionType = PostIncidentFlowDecision;
export const entersPostIncident = (d: PostIncidentDecisionType) =>
  d === PostIncidentDecision.AutomaticOptIn || d === PostIncidentDecision.OptIn;

const usePostIncidentFlowDecision = ({
  customFields,
  incidentRoles,
  touchedFields,
  selectedCustomFieldEntries,
  selectedIncidentRoleAssignments,
  selectedSeverityID,
  selectedIncidentTypeID,
  selectedIncidentMode,
  incidentId,
}: {
  customFields: CustomField[];
  incidentRoles: IncidentRole[];
  touchedFields: Partial<Readonly<FieldNamesMarkedBoolean<ResolveFormData>>>;
  selectedCustomFieldEntries: FormCustomFieldEntries;
  selectedIncidentRoleAssignments: FormIncidentRoleAssignments;
  selectedSeverityID: string;
  selectedIncidentTypeID: string | undefined;
  selectedIncidentMode: string;
  incidentId: string;
}): {
  postIncidentFlow: PostIncidentFlow | undefined;
  postIncidentFlowDecision: PostIncidentDecisionType;
  isLoading: boolean;
  error: ErrorResponse | undefined;
} => {
  const {
    data: postIncidentFlowData,
    isLoading,
    error,
  } = useAPI("postIncidentFlowEvaluateConditions", {
    evaluateConditionsRequestBody: {
      custom_field_entries: marshallCustomFieldEntriesToRequestPayload(
        customFields,
        touchedFields,
        selectedCustomFieldEntries,
      ),
      incident_role_assignments: marshallIncidentRolesToFormData({
        incidentRoles,
        formAssignments: selectedIncidentRoleAssignments,
      }),
      severity_id: selectedSeverityID,
      incident_type_id: selectedIncidentTypeID,
      mode: selectedIncidentMode,
      incident_id: incidentId,
    },
  });

  const postIncidentFlowConditionsMatch =
    postIncidentFlowData?.automatic_opt_in ?? false;

  return {
    postIncidentFlow: postIncidentFlowData?.post_incident_flow,
    postIncidentFlowDecision: postIncidentFlowConditionsMatch
      ? PostIncidentDecision.AutomaticOptIn
      : PostIncidentDecision.None,
    isLoading: isLoading,
    error,
  };
};
