import { EngineScope, Resource } from "@incident-io/api";
import { EngineLiteralBadge } from "@incident-shared/engine";
import { ReferenceSelectorPopover } from "@incident-shared/engine/ReferenceSelectorPopover/ReferenceSelectorPopover";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import { Button, ButtonSize, ButtonTheme, IconEnum } from "@incident-ui";
import { useCallback } from "react";
import { UseFieldArrayReturn } from "react-hook-form";
import { filterScope, lookupInScope } from "src/utils/scope";

import { CreateIncidentsFormData } from "./types";

type AlertRouteGroupBySelectorProps = {
  fieldMethods: UseFieldArrayReturn<
    CreateIncidentsFormData,
    "groupingKeys",
    "id"
  >;
  scope: EngineScope;
  resources: Resource[];
};

export const AlertRouteGroupBySelector = ({
  fieldMethods,
  scope,
  resources,
}: AlertRouteGroupBySelectorProps) => {
  const { fields, append, remove } = fieldMethods;

  const getRef = useCallback(
    (key) => {
      const ref = lookupInScope(scope, key);
      if (!ref) {
        throw new Error(`could not find ref with key "${ref}"`);
      }
      return ref;
    },
    [scope],
  );

  const getResourceIcon = useCallback(
    (type: string): IconEnum => {
      const resource = resources.find((resource) => resource.type === type);
      if (resource) {
        return resource.field_config.icon as unknown as IconEnum;
      } else {
        return IconEnum.Box;
      }
    },
    [resources],
  );

  // We don't allow to group by the result of expressions because that seems like an odd use case; you should have
  // what you need as a grouping attribute already.
  const availableFields = filterScope(
    scope,
    (x) =>
      !x.type.includes("TemplatedText") &&
      !x.key.startsWith("expressions") &&
      !fields.find((field) => field.key === x.key),
  );

  return (
    <div className="!w-full">
      <div className="w-full flex justify-between items-center gap-2 bg-white border border-stroke rounded-[6px] p-3">
        <div className="flex flex-col gap-2 justify-center">
          {fields.map((field, i) => {
            const ref = getRef(field.key);
            return (
              <EngineLiteralBadge
                className="w-fit"
                key={ref.key}
                label={ref.label}
                icon={
                  (ref.icon as unknown as IconEnum) || getResourceIcon(ref.type)
                }
                color={ref.color as unknown as ColorPaletteEnum}
                onDelete={() => remove(i)}
              />
            );
          })}
        </div>

        <ReferenceSelectorPopover
          scope={availableFields}
          allowExpressions={false}
          renderTriggerButton={({ onClick }) => (
            <Button
              theme={ButtonTheme.Secondary}
              size={ButtonSize.Small}
              onClick={onClick}
              analyticsTrackingId="alert-route-add-property"
              icon={IconEnum.Add}
              title="Add Property"
              className="self-end"
            />
          )}
          onSelectReference={(ref) => {
            append({ key: ref.key, label: ref.label });
          }}
        />
      </div>
    </div>
  );
};
