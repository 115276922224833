import type { SVGProps } from "react";
import * as React from "react";
const SvgStatusPageHifi = (
  props: SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 32 32"
    {...props}
  >
    <rect width={6.667} height={16} x={4} y={8} fill="#96D689" rx={3.333} />
    <rect
      width={6.667}
      height={16}
      x={12.668}
      y={8}
      fill="#96D689"
      rx={3.333}
    />
    <rect
      width={6.667}
      height={16}
      x={21.332}
      y={8}
      fill="#F25533"
      rx={3.333}
    />
  </svg>
);
export default SvgStatusPageHifi;
