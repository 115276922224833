import { IncidentCallSessionWithTranscript } from "@incident-io/query-api";
import {
  TemplatedTextDisplay,
  TemplatedTextDisplayStyle,
} from "@incident-shared/forms/v1/TemplatedText";
import { EmptyState, Heading } from "@incident-ui";

export const CallSummary = ({
  callSessionWithTranscript,
}: {
  callSessionWithTranscript: IncidentCallSessionWithTranscript;
}) => {
  if (!callSessionWithTranscript.summary) {
    return (
      <div className="py-4 border-stroke border-b">
        <EmptyState
          className="border-0"
          title={"No summary available"}
          content={
            <>
              Looks like we don&apos;t have a call transcript for this incident
              call. If transcription is enabled, incident.io will automatically
              summarise it for you.
            </>
          }
        />
      </div>
    );
  }

  return (
    <div className="border-b border-stroke py-6">
      <Heading level={2} size="medium" className="shrink-0">
        Summary
      </Heading>
      <TemplatedTextDisplay
        className="py-4"
        value={callSessionWithTranscript.summary.text_node}
        style={TemplatedTextDisplayStyle.Compact}
      />
    </div>
  );
};
