import {
  Escalation,
  EscalationPreview,
  EscalationStatusEnum,
} from "@incident-io/api";
import { EscalationTarget } from "@incident-io/query-api";
import { Icon, IconEnum, OrgAwareLink, StackedListItem } from "@incident-ui";
import _ from "lodash";

import {
  AvatarList,
  AvatarListClickableType,
  MaybeUser,
} from "../legacy/incident/sidebar/AvatarList";

const escalationTransitionIcon: Record<EscalationStatusEnum, React.ReactNode> =
  {
    [EscalationStatusEnum.Pending]: (
      <Icon id={IconEnum.Timer} className="text-blue-500" />
    ),
    [EscalationStatusEnum.Triggered]: (
      <Icon id={IconEnum.Flag} className={"text-blue-500"} />
    ),
    [EscalationStatusEnum.Expired]: (
      <Icon id={IconEnum.CloseCircle} className="text-slate-500" />
    ),
    [EscalationStatusEnum.Cancelled]: (
      <Icon id={IconEnum.CloseCircle} className="text-slate-500" />
    ),
    [EscalationStatusEnum.Acked]: (
      <Icon id={IconEnum.Checkmark} className="text-green-500" />
    ),
    [EscalationStatusEnum.Resolved]: (
      <Icon id={IconEnum.Checkmark} className="text-green-500" />
    ),
  };

export const EscalationStackedListItem = ({
  escalation,
  hrefTarget,
  description,
  className,
  hrefInTitle,
}: {
  escalation: Pick<
    Escalation | EscalationPreview,
    "id" | "title" | "targets" | "status"
  >;
  hrefTarget?: string;
  description?: React.ReactNode;
  className?: string;
  hrefInTitle?: boolean;
}) => {
  const targets = escalation.targets?.filter(Boolean) ?? [];
  return (
    <StackedListItem
      iconNode={escalationTransitionIcon[escalation.status]}
      title={
        hrefInTitle ? (
          <OrgAwareLink
            to={`/on-call/escalations/${escalation.id}`}
            target={hrefTarget}
            className={"hover:underline"}
          >
            <span className="font-medium">{escalation.title}</span>
          </OrgAwareLink>
        ) : (
          <span className="font-medium">{escalation.title}</span>
        )
      }
      description={description}
      hrefTarget={hrefTarget}
      rowHref={
        hrefInTitle ? undefined : `/on-call/escalations/${escalation.id}`
      }
      className={className}
      accessory={
        <AvatarList
          modalTitle={"Escalated users"}
          maxToShow={3}
          users={(
            _.uniqBy(targets, (t) => t.user_id || t.slack_channel_name) ?? []
          ).map(
            (t: EscalationTarget): MaybeUser => ({
              user: {
                id: t.id,
                name: t.slack_channel_name || t.user_name || "",
                avatar_url: t.image_url,
              },
            }),
          )}
          clickableType={AvatarListClickableType.Always}
        />
      }
    />
  );
};
