import {
  Incident,
  IncidentStatusCategoryEnum as StatusCategoryEnum,
} from "@incident-io/api";
import { Badge, BadgeSize, BadgeTheme, IconEnum } from "@incident-ui";
import { useState } from "react";
import { IncidentHeaderModal } from "src/routes/legacy/IncidentRoute";
import { formatDurationInSecondsShort } from "src/utils/datetime";
import { getDurationInSeconds } from "src/utils/presenters";
import { useInterval } from "usehooks-ts";

import {
  EditableIncidentTypesBadge,
  EditableSeverityBadge,
  EditableStatusBadge,
} from "../header/EditableBadges";
import { incidentInEditableStatus } from "../helpers";

type IncidentKeyDetailsProps = {
  incident: Incident | null;
  setModalOpen: (modal: IncidentHeaderModal) => void;
};

export const IncidentKeyDetails = ({
  incident,
  setModalOpen,
}: IncidentKeyDetailsProps) => {
  const incidentIsEditable = incident && incidentInEditableStatus(incident);
  const calculateDuration = () => {
    if (!incident) return null;

    const duration = getDurationInSeconds(incident);
    if (!duration) return null;

    return formatDurationInSecondsShort(duration);
  };
  const [duration, setDuration] = useState(calculateDuration);
  useInterval(() => setDuration(calculateDuration), 1 * 1000);

  if (!incident) {
    return null;
  }

  const components = [
    {
      label: "Status",
      component: (
        <EditableStatusBadge
          status={incident?.incident_status}
          onEdit={
            incidentIsEditable
              ? () => setModalOpen(IncidentHeaderModal.UpdateStatus)
              : undefined
          }
        />
      ),
    },
    {
      label: "Severity",
      component: (
        <EditableSeverityBadge
          severity={incident.severity}
          onEdit={
            incidentIsEditable
              ? () => setModalOpen(IncidentHeaderModal.UpdateSeverity)
              : undefined
          }
        />
      ),
    },
    {
      label: "Type",
      component: (
        <EditableIncidentTypesBadge
          incidentType={incident?.incident_type}
          onEdit={
            incidentIsEditable
              ? () => setModalOpen(IncidentHeaderModal.UpdateIncidentType)
              : undefined
          }
        />
      ),
    },
    {
      label: "Duration",
      component: duration ? (
        <IncidentDuration
          category={incident.incident_status.category}
          durationString={duration}
        />
      ) : null,
    },
  ];

  return (
    <div className="flex flex-col gap-2">
      {components.map(({ component, label }) => {
        if (!component) return null;

        return (
          <div key={label} className="flex-center-y text-sm justify-between">
            <span className="text-content-tertiary mr-2 w-[40%]">{label}</span>
            {component}
          </div>
        );
      })}
    </div>
  );
};

const IncidentDuration = ({
  category,
  durationString,
}: {
  category: StatusCategoryEnum;
  durationString: string;
}) => {
  let prefix = "";
  switch (category) {
    case StatusCategoryEnum.Declined:
    case StatusCategoryEnum.Canceled:
    case StatusCategoryEnum.Merged:
      // Don't show anything!
      return <></>;

    case StatusCategoryEnum.Closed:
    case StatusCategoryEnum.PostIncident:
      prefix = "Lasted";
      break;
    case StatusCategoryEnum.Triage:
      prefix = "In triage for";
      break;
    default:
      prefix = "Ongoing for";
  }

  return (
    <Badge
      size={BadgeSize.Medium}
      theme={BadgeTheme.Unstyled}
      icon={IconEnum.Clock}
    >
      {`${prefix} ${durationString}`}
    </Badge>
  );
};
