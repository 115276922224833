import { ScopeNameEnum } from "@incident-io/api";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import {
  ButtonSize,
  ButtonTheme,
  ContentBox,
  IconBadge,
  IconEnum,
  IconSize,
} from "@incident-ui";

// Provide a content box of controls that shows the currently set value for a
// section of alert source config and provides a button to edit that config.
//
// These are used for attribute and priority controls.
export const AlertSourceSection = ({
  title,
  icon,
  iconColor = ColorPaletteEnum.Blue,
  children,
  onEdit,
}: {
  title?: string;
  icon?: IconEnum;
  iconColor?: ColorPaletteEnum;
  children: React.ReactNode;
  onEdit: () => void;
}) => {
  if (!title) {
    return (
      <ContentBox className="flex items-start gap-2 p-4">
        {children}
        <GatedButton
          requiredScope={ScopeNameEnum.AlertSourceUpdate}
          analyticsTrackingId={null}
          icon={IconEnum.Edit}
          theme={ButtonTheme.Naked}
          onClick={onEdit}
          title="Edit"
          size={ButtonSize.Small}
        />
      </ContentBox>
    );
  }

  return (
    <ContentBox className="flex flex-col gap-4 p-4">
      <div className="flex items-start items-center">
        <div className="grow flex items-center gap-2">
          {icon ? (
            <IconBadge icon={icon} size={IconSize.Small} color={iconColor} />
          ) : null}
          <div className="text-sm-bold">{title}</div>
        </div>
        <GatedButton
          requiredScope={ScopeNameEnum.AlertSourceUpdate}
          analyticsTrackingId={null}
          icon={IconEnum.Edit}
          theme={ButtonTheme.Naked}
          onClick={onEdit}
          title="Edit"
          size={ButtonSize.Small}
        />
      </div>
      {children}
    </ContentBox>
  );
};
