/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PostmortemsCreateTemplateRequestBody
 */
export interface PostmortemsCreateTemplateRequestBody {
    /**
     * ID of the existing template to create the template from
     * @type {string}
     * @memberof PostmortemsCreateTemplateRequestBody
     */
    existing_template_id?: string;
    /**
     * Name of the postmortem template
     * @type {string}
     * @memberof PostmortemsCreateTemplateRequestBody
     */
    name: string;
    /**
     * ID of the suggested template to create the template from
     * @type {string}
     * @memberof PostmortemsCreateTemplateRequestBody
     */
    suggested_template_id?: PostmortemsCreateTemplateRequestBodySuggestedTemplateIdEnum;
}

/**
* @export
* @enum {string}
*/
export enum PostmortemsCreateTemplateRequestBodySuggestedTemplateIdEnum {
    Example = 'example'
}

export function PostmortemsCreateTemplateRequestBodyFromJSON(json: any): PostmortemsCreateTemplateRequestBody {
    return PostmortemsCreateTemplateRequestBodyFromJSONTyped(json, false);
}

export function PostmortemsCreateTemplateRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostmortemsCreateTemplateRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'existing_template_id': !exists(json, 'existing_template_id') ? undefined : json['existing_template_id'],
        'name': json['name'],
        'suggested_template_id': !exists(json, 'suggested_template_id') ? undefined : json['suggested_template_id'],
    };
}

export function PostmortemsCreateTemplateRequestBodyToJSON(value?: PostmortemsCreateTemplateRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'existing_template_id': value.existing_template_id,
        'name': value.name,
        'suggested_template_id': value.suggested_template_id,
    };
}

