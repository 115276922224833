import {
  PostIncidentTaskConfig,
  PostIncidentTaskConfigSlim,
  PostIncidentTaskConfigTaskTypeEnum,
} from "@incident-io/api";

// TaskTypeToManuallyCompletedMap returns true if we should show a `Complete` button for the task type.
const TaskTypeToManuallyCompletedMap: {
  [key in PostIncidentTaskConfigTaskTypeEnum]: boolean;
} = {
  // Tasks which must be manually checked off.
  [PostIncidentTaskConfigTaskTypeEnum.Custom]: true,
  [PostIncidentTaskConfigTaskTypeEnum.ReviewFollowups]: true,
  [PostIncidentTaskConfigTaskTypeEnum.UpdateTimestamps]: true,
  [PostIncidentTaskConfigTaskTypeEnum.ReviewTimeline]: true,
  [PostIncidentTaskConfigTaskTypeEnum.UpdateIncidentSummary]: true,
  [PostIncidentTaskConfigTaskTypeEnum.ScheduleDebrief]: true, // we'll mark this as complete when we detect it's been done, but we'll let people undo it too
  [PostIncidentTaskConfigTaskTypeEnum.SetTimestamps]: true, // We'll mark this as complete if there were timestamps to pick and they are complete, but you can manually confirm them too
  // Tasks which will only be checked off when we recognise they have been completed.
  [PostIncidentTaskConfigTaskTypeEnum.CreatePostmortem]: false,
  [PostIncidentTaskConfigTaskTypeEnum.CompletePostmortem]: false,
  [PostIncidentTaskConfigTaskTypeEnum.InReviewPostmortem]: false,
  [PostIncidentTaskConfigTaskTypeEnum.SharePostmortem]: false,
  [PostIncidentTaskConfigTaskTypeEnum.AssignRole]: false,
  [PostIncidentTaskConfigTaskTypeEnum.SetCustomFields]: false,
  [PostIncidentTaskConfigTaskTypeEnum.GiveShoutout]: false,
};

// TaskCanBeManuallyCompleted checks if you can manually complete a task
// using the map, with exceptions for certain tasks
export const TaskCanBeManuallyCompleted = (
  task: PostIncidentTaskConfig | PostIncidentTaskConfigSlim,
): boolean => {
  if (task.task_type === PostIncidentTaskConfigTaskTypeEnum.SetTimestamps) {
    // Depending on if the the tasks has timestamps set, we can manually complete it.
    if (task.timestamp_ids && task.timestamp_ids.length > 0) {
      return false;
    } else {
      return true;
    }
  }

  // Otherwise, use the map to determine if the task can be manually completed.
  return TaskTypeToManuallyCompletedMap[task.task_type];
};
