import {
  Avatar,
  Checkbox,
  Icon,
  IconEnum,
  IconSize,
  Link,
  LocalDateTime,
  Tooltip,
} from "@incident-ui";
import { GenericStatusBadgeEnum } from "@incident-ui/Badge/GenericStatusBadge";
import { IncidentStatusBar } from "src/components/legacy/incident/IncidentStatusBar";
import {
  Incident,
  IncidentRoleWithoutConditionsRoleTypeEnum as RoleTypeEnum,
  IncidentStatusCategoryEnum,
  IncidentTimestampTimestampTypeEnum,
  IncidentVisibilityEnum,
  PostIncidentFlow,
  PostIncidentTaskSlim,
  Settings,
} from "src/contexts/ClientContext";
import { formatDurationInSecondsShort } from "src/utils/datetime";
import { incidentTypesEnabled } from "src/utils/incident-types";
import { tcx } from "src/utils/tailwind-classes";

import { getTaskStatus } from "./PostIncidentList";

export const PostIncidentTableHeader = ({
  incident,
  settings,
  flows,
  selectedTasks,
  setSelectedTasks,
}: {
  incident: Incident;
  settings: Settings;
  flows: PostIncidentFlow[];
  selectedTasks: PostIncidentTaskSlim[];
  setSelectedTasks: (taskIDs: PostIncidentTaskSlim[]) => void;
}): React.ReactElement => {
  const resolvedAt = incident.incident_timestamps?.find(
    (t) =>
      t.timestamp.timestamp_type ===
      IncidentTimestampTimestampTypeEnum.ResolvedAt,
  );

  const showIncidentTypes = incidentTypesEnabled(settings);

  // Find the flow associated with this incident. Note that `incident.post_incident_flow_id` doesn't
  // always appear to be set so we have to check the statuses directly.
  const flow = flows.find((f) => {
    return f.incident_statuses
      .map((status) => status.id)
      .includes(incident.incident_status.id);
  });

  const statuses =
    flow?.incident_statuses.filter(
      (status) => status.category === IncidentStatusCategoryEnum.PostIncident,
    ) || [];

  const leadRole = incident.incident_role_assignments.find(
    ({ role }) => role.role_type === RoleTypeEnum.Lead,
  );
  const leadAlias = leadRole?.role?.name;
  const lead = leadRole?.assignee;

  const selectableTasks =
    incident.post_incident_tasks?.filter(
      (t) => getTaskStatus(t) === GenericStatusBadgeEnum.Outstanding,
    ) ?? [];

  const incidentTasksSelected =
    selectableTasks.length > 0 &&
    selectableTasks.every((task) =>
      selectedTasks.some((selected) => selected.id === task.id),
    );

  const toggleSelectionOfIncidentTasks = (e?: React.ChangeEvent) => {
    if (e !== undefined) {
      e.stopPropagation();
    }

    if (incidentTasksSelected) {
      setSelectedTasks(
        selectedTasks.filter((task) => task.incident_id !== incident.id),
      );
    } else {
      setSelectedTasks([...selectedTasks, ...selectableTasks]);
    }
  };

  const onRowClick = (e: React.MouseEvent<HTMLAnchorElement>): void => {
    // This is to help prevent mis-clicks when a row is selected. If the user clicks on the row
    // and any incidents are selected, we don't want to navigate to the incident, we want to toggle
    // the checkbox.
    if (selectedTasks.length > 0) {
      toggleSelectionOfIncidentTasks();
      e.preventDefault();
    }
  };

  return (
    <thead>
      <Link
        analyticsTrackingId={null}
        to={`/incidents/${incident.external_id}`}
        onClick={onRowClick}
        className="flex-center-y py-3 px-4 hover:cursor-pointer border-stroke bg-surface-secondary"
        noUnderline
      >
        <Checkbox
          id="select_all"
          checked={incidentTasksSelected}
          onChange={(e) => toggleSelectionOfIncidentTasks(e)}
          disabled={selectableTasks.length === 0}
          className="block -ml-1 mr-3"
        />
        <div className="items-center w-full text-sm space-x-3">
          {/* Inc reference and name */}
          <div className="font-regular items-center text-content-primary flex min-w-[10px] !ml-0.5">
            {incident.visibility === IncidentVisibilityEnum.Private && (
              <div className="-ml-0.5 mr-1">
                <Icon id={IconEnum.LockClosed} className="text-slate-600" />
              </div>
            )}
            <div className="xl:max-w-[95%] xl:truncate xl:line-clamp-none xl:!block line-clamp-1 mr-2">
              <span className="font-semibold"> INC-{incident.external_id}</span>{" "}
              {incident.name}{" "}
            </div>
          </div>
          <div className="flex flex-wrap !ml-0 gap-3 !mt-2 items-center">
            <IncidentStatusBar
              incident={incident}
              statuses={statuses}
              className="max-h-[26px] !shadow-none"
            />
            {resolvedAt && resolvedAt.value && resolvedAt.value.value && (
              <DetailItem
                icon={<Icon id={IconEnum.Calendar} />}
                content={
                  <LocalDateTime
                    timestamp={resolvedAt.value.value}
                    className="hover:!no-underline flex-row"
                  >
                    <span>
                      Resolved{" "}
                      {formatDurationInSecondsShort(
                        (new Date().getTime() -
                          resolvedAt.value.value.getTime()) /
                          1000,
                      )}{" "}
                      ago
                    </span>
                  </LocalDateTime>
                }
              />
            )}
            <div className="flex gap-3.5 items-center">
              {showIncidentTypes && incident.incident_type ? (
                <DetailItem
                  icon={
                    <Icon
                      id={IconEnum.IncidentType}
                      className="text-content-tertiary"
                    />
                  }
                  content={incident.incident_type.name}
                />
              ) : null}
              {incident.severity && (
                <DetailItem
                  icon={<Icon id={IconEnum.Severity} />}
                  content={incident.severity.name}
                />
              )}
              <DetailItem
                icon={
                  <Avatar
                    size={IconSize.Medium}
                    url={lead?.avatar_url}
                    name={lead?.name}
                    title={lead?.name ?? "Unassigned"}
                    className="mx-1"
                  />
                }
                content={
                  <Tooltip content={<>{leadAlias}</>}>
                    <div>{lead?.name ?? "Unassigned"}</div>
                  </Tooltip>
                }
              />
            </div>
          </div>
        </div>
      </Link>
    </thead>
  );
};

function DetailItem({
  icon,
  content,
  className,
}: {
  icon: React.ReactElement;
  content: React.ReactElement | string;
  className?: string;
}): React.ReactElement {
  return (
    <span
      className={tcx(
        className,
        "text-slate-600 flex-center-y gap-0.5 whitespace-nowrap",
      )}
    >
      {icon}
      {content}
    </span>
  );
}
