import { Button, ButtonSize, ButtonTheme } from "@incident-ui/Button/Button";
import { IconEnum, IconSize } from "@incident-ui/Icon/Icon";
import { Tooltip } from "@incident-ui/Tooltip/Tooltip";
import { tcx } from "src/utils/tailwind-classes";
import { useClipboard } from "src/utils/useClipboard";

export const CopiableElement = ({ content }: { content: string }) => {
  const { copyTextToClipboard, hasCopied } = useClipboard();

  return (
    <Tooltip content={hasCopied ? "Copied" : "Copy"}>
      <Button
        theme={ButtonTheme.Tertiary}
        icon={hasCopied ? IconEnum.Success : IconEnum.Copy}
        iconProps={{
          className: tcx(
            "text-content-tertiary group-hover:text-content-secondary",
            {
              "!text-green-content": hasCopied,
            },
          ),
          size: IconSize.Medium,
        }}
        iconPosition="right"
        size={ButtonSize.Small}
        analyticsTrackingId="catalog-entry-copy-external-id"
        className={tcx("text-content-secondary hover:text-content-primary")}
        onClick={() => copyTextToClipboard(content)}
      >
        {content}
      </Button>
    </Tooltip>
  );
};
