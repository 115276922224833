import { Incident } from "@incident-io/api";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import { LoadingModal } from "@incident-ui";
import { FunctionComponent } from "react";
import { Route, Routes, useOutlet } from "react-router";
import { useDisableNPS } from "src/contexts/SurvicateContext";
import {
  IncidentDrawer,
  IncidentHeaderModal,
} from "src/routes/legacy/IncidentRoute";

import { CancelIncidentModal } from "./header/CancelIncidentModal";
import { ChangePrivacyModal } from "./header/ChangePrivacyModal";
import { EditDurationTimestampsModal } from "./header/EditDurationTimestampsModal";
import { EditRoleAssignmentsModal } from "./header/EditRoleAssignmentsModal";
import { EditTimestampsModal } from "./header/EditTimestampsModal";
import { UpdateIsTestConfirmationModal } from "./header/IncidentHeaderModal";
import { IncidentManageAccessModal } from "./header/ManageAccessModal";
import { RenameIncidentModal } from "./header/RenameIncidentModal";
import { RequestUpdateContextProvider } from "./header/RequestUpdateModal";
import { ResolveIncidentModal } from "./header/ResolveIncidentModal";
import { UpdateIncidentModal } from "./header/UpdateIncidentModal";
import { UpdateSeverityModal } from "./header/UpdateSeverityModal";
import { UpdateStatusModal } from "./header/UpdateStatusModal";
import { useIncident } from "./hooks";
import { OnCallOnlyIncident } from "./IncidentOnCallOnly";
import { StreamsCreateDrawer } from "./streams/StreamsCreateDrawer";

type IncidentOnCallOnlyHeaderModal =
  | IncidentHeaderModal.Cancel
  | IncidentHeaderModal.ChangePrivacy
  | IncidentHeaderModal.EditTimestamps
  | IncidentHeaderModal.EditRoleAssignments
  | IncidentHeaderModal.ManageAccess
  | IncidentHeaderModal.RenameIncident
  | IncidentHeaderModal.Resolve
  | IncidentHeaderModal.UpdateIncident
  | IncidentHeaderModal.UpdateIsTest
  | IncidentHeaderModal.UpdateSeverity
  | IncidentHeaderModal.UpdateStatus;

export const IncidentOnCallOnlyDetailsPage = ({
  incidentId,
  externalId,
}: {
  incidentId: string | null;
  externalId: number;
}) => {
  const { incident } = useIncident(incidentId);

  // This page is a core part of incident response - we don't want to get in
  // the way here
  useDisableNPS();

  const navigate = useOrgAwareNavigate();
  const onCloseModal = (isModalInDrawer = false) => {
    if (isModalInDrawer) {
      navigate("..", { relative: "path", replace: true });
    } else {
      navigate(
        {
          pathname: `/incidents/${externalId}`,
          search: location.search,
        },
        { replace: true },
      );
    }
  };

  const Modals: {
    [key in IncidentOnCallOnlyHeaderModal]: FunctionComponent<{
      incident: Incident;
      onClose: () => void;
    }>;
  } = {
    [IncidentHeaderModal.Cancel]: CancelIncidentModal,
    [IncidentHeaderModal.ChangePrivacy]: ChangePrivacyModal,
    [IncidentHeaderModal.EditTimestamps]: EditTimestampsModal,
    [IncidentHeaderModal.EditRoleAssignments]: EditRoleAssignmentsModal,
    [IncidentHeaderModal.ManageAccess]: IncidentManageAccessModal,
    [IncidentHeaderModal.RenameIncident]: RenameIncidentModal,
    [IncidentHeaderModal.Resolve]: ResolveIncidentModal,
    [IncidentHeaderModal.UpdateIncident]: UpdateIncidentModal,
    [IncidentHeaderModal.UpdateIsTest]: UpdateIsTestConfirmationModal,
    [IncidentHeaderModal.UpdateSeverity]: UpdateSeverityModal,
    [IncidentHeaderModal.UpdateStatus]: UpdateStatusModal,
  };

  return (
    <RequestUpdateContextProvider>
      <Routes>
        <Route
          path="/"
          element={<IncidentWithOutlet incidentId={incidentId} />}
        >
          <Route
            path={`${IncidentDrawer.Streams}-create`}
            element={
              incidentId && (
                <StreamsCreateDrawer
                  onClose={() => onCloseModal(true)}
                  incidentId={incidentId}
                />
              )
            }
          />
          <Route
            path={`duration/:duration_id`}
            element={
              incident ? (
                <EditDurationTimestampsModal
                  onClose={() => onCloseModal(false)}
                  incident={incident}
                />
              ) : (
                <LoadingModal
                  title={"Loading"}
                  onClose={() => onCloseModal(false)}
                />
              )
            }
          />
          {Object.entries(Modals).map(([route, Modal]) => (
            <Route
              key={route}
              path={`${route}`}
              element={
                incident ? (
                  <Modal onClose={onCloseModal} incident={incident} />
                ) : (
                  <LoadingModal onClose={onCloseModal} />
                )
              }
            />
          ))}
        </Route>
      </Routes>
    </RequestUpdateContextProvider>
  );
};

const IncidentWithOutlet = ({ incidentId }: { incidentId: string | null }) => {
  // The outlet renders any modal or drawer that is open. We render it like this
  // so that it can be _directly_ wrapped in AnimatePresence, to give us exit
  // animations.
  const outlet = useOutlet();

  return (
    <>
      {outlet}
      <OnCallOnlyIncident incidentId={incidentId} />
    </>
  );
};
