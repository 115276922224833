import { GatedButtonSpecificProps } from "@incident-shared/gates/GatedButton/GatedButton";
import { UpgradeRequiredButtonProps } from "@incident-shared/gates/GatedButton/GatedButton";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { Button, ButtonTheme, Heading, Txt } from "@incident-ui";
import { ButtonProps } from "@incident-ui/Button/Button";
import { ImgWithLoadingSkeleton } from "@incident-ui/ImgLoadingSkeleton/ImgWithLoadingSkeleton";
import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import { tcx } from "src/utils/tailwind-classes";

type OptionalGating =
  | {
      isGated?: true;
      gatingProps?: GatedButtonSpecificProps & UpgradeRequiredButtonProps;
    }
  | { isGated?: false; gatingProps?: never };

export type EmptyStateProps = {
  copy: string;
  imageSrc: string;
  title: string;
  buttons: (ButtonProps & OptionalGating)[];
} & React.PropsWithChildren<unknown>;

export const EmptyState = ({
  buttons,
  copy,
  imageSrc,
  title,
  children,
}: EmptyStateProps): React.ReactElement => {
  return (
    <div className="h-full border-transparent border-double border-[1px] rounded-2xl [background-image:linear-gradient(white,white),linear-gradient(#eeeeee,#ffffff)] bg-origin-border [background-clip:padding-box,border-box] p-10 pt-12 flex flex-1 justify-around">
      <AnimatePresence>
        <motion.div
          className="flex-center flex-col items-center"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.3 }}
        >
          <ImgWithLoadingSkeleton
            className="max-h-[330px] mb-5"
            src={imageSrc}
            alt="Empty state hero"
          />
          <div className="max-w-[400px] flex flex-col items-center justify-center text-center">
            <Heading
              level={1}
              size={"2xl"}
              className="mb-2 font-bold tracking-tight"
            >
              {title}
            </Heading>
            <Txt className="mb-10 max-w-[80%]" grey>
              {copy}
            </Txt>
            <div className={"flex flex-row items-center space-x-2"}>
              {buttons.map((ctaButton) => {
                const Component = ctaButton.isGated ? GatedButton : Button;
                return (
                  <Component
                    key={ctaButton.analyticsTrackingId}
                    theme={ButtonTheme.Primary}
                    {...ctaButton}
                    className={tcx("px-4", ctaButton.className)}
                  />
                );
              })}
            </div>
          </div>
          {children}
        </motion.div>
      </AnimatePresence>
    </div>
  );
};
