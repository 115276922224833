import {
  Condition,
  ConditionSubjectIconEnum,
  EngineParamBinding,
  EngineScope,
  Resource,
  ResourceOperation,
} from "@incident-io/api";
import {
  Button,
  ButtonSize,
  ButtonTheme,
  IconEnum,
  StaticSingleSelect,
} from "@incident-ui";
import { PopoverTitleBar } from "@incident-ui/Popover/Popover";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { omitScopeReference } from "src/utils/scope";

import { EngineFormElement, isEmptyBindingValue } from "..";
import { isExpression } from "..";
import { ConditionMenuEntry } from "./AddConditionPopover";

export const ConditionOperationSelect = ({
  scope,
  resources,
  handleSubmit,
  selectedEntry,
  defaultValues,
  allowExpressions = false,
  showExpressionModal,
}: {
  scope: EngineScope;
  resources: Resource[];
  handleSubmit: (condition: Condition) => void;
  selectedEntry: ConditionMenuEntry;
  defaultValues?: Condition;
  allowExpressions?: boolean;
  showExpressionModal?: () => void;
}) => {
  const availableOperations = selectedEntry.array
    ? selectedEntry.resource.array_operations
    : selectedEntry.resource.operations;

  const operationsLookup = availableOperations.reduce((lookup, op) => {
    lookup[op.name] = op;
    return lookup;
  }, {});

  const [operation, setOperation] = useState<
    ResourceOperation & { value: string }
  >(
    defaultValues
      ? {
          label: defaultValues.operation.label,
          name: defaultValues.operation.value,
          value: defaultValues.operation.value,
          params: defaultValues.params,
        }
      : operationsLookup["one_of"] ||
          operationsLookup[Object.keys(operationsLookup)[0]],
  );

  const param = operation?.params[0];
  const dummyForm = useForm({
    defaultValues: { bindings: defaultValues?.param_bindings[0] },
  });
  const onSubmit = () => {
    const formData = dummyForm.getValues();
    if (!operation) {
      return;
    }

    if (
      !param ||
      validateParamBinding(
        param.optional,
        param.array,
        dummyForm.setError,
        formData.bindings,
      )
    ) {
      handleSubmit({
        operation: {
          label: operation.label,
          value: operation.name,
        },
        params: operation.params,
        param_bindings: formData.bindings ? [formData.bindings] : [],
        subject: {
          label: selectedEntry.label,
          icon: (selectedEntry.icon ||
            selectedEntry.resource.field_config
              .icon) as unknown as ConditionSubjectIconEnum,
          reference: selectedEntry.key,
        },
      });
    }
  };

  const isRefAnExpression = isExpression(selectedEntry.key);

  return (
    <>
      <PopoverTitleBar
        title={selectedEntry.label}
        action={
          showExpressionModal && isRefAnExpression
            ? {
                title: "Edit expression",
                icon: IconEnum.Edit,
                onClick: showExpressionModal,
              }
            : undefined
        }
        submitButton={
          <Button
            theme={ButtonTheme.Primary}
            analyticsTrackingId={"workflows-v2-conditions-menu-add-condition"}
            onClick={onSubmit}
            size={ButtonSize.Small}
          >
            Done
          </Button>
        }
        className="pb-1"
      />
      <FormProvider<{ bindings: EngineParamBinding | undefined }>
        {...dummyForm}
      >
        <form
          // This height is a gross number; the popover header section is 119px tall :(
          className="p-3 flex flex-col space-y-2 max-h-[281px] overflow-y-auto"
        >
          <StaticSingleSelect
            options={(selectedEntry.array
              ? selectedEntry.resource.array_operations
              : selectedEntry.resource.operations
            ).map((x) => {
              return { label: x.label, value: x.name };
            })}
            value={operation?.name}
            onChange={(v) => {
              setOperation(operationsLookup[v as unknown as string]);
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              dummyForm.reset({ bindings: null }); // needs to be set to null because the form element will not update otherwise
            }}
          />
          {param && (
            <EngineFormElement
              scope={omitScopeReference(scope, selectedEntry.key)}
              resources={resources}
              name="bindings"
              required
              array={!!param.array}
              showPlaceholder={true}
              resourceType={param.type}
              mode={
                allowExpressions
                  ? "variables_and_expressions"
                  : "variables_only"
              }
            />
          )}
        </form>
      </FormProvider>
    </>
  );
};

const validateParamBinding = (
  optional: boolean,
  array: boolean,
  setError: (
    key: "bindings.value" | "bindings.array_value",
    msg: { type: string; message: string },
  ) => void,
  data?: EngineParamBinding,
): boolean => {
  let valid = true;
  if (optional) {
    return valid;
  }

  const array_value = data?.array_value || [];
  if (array) {
    if (array_value.every(isEmptyBindingValue)) {
      setError("bindings.array_value", {
        type: "manual",
        message: "this field is required",
      });
      valid = false;
    } else if (array_value.some(isEmptyBindingValue)) {
      setError("bindings.array_value", {
        type: "manual",
        message: "you must provide a value for each item",
      });
      valid = false;
    }
  } else {
    if (!data?.value?.literal && !data?.value?.reference) {
      setError("bindings.value", {
        type: "manual",
        message: "this field is required",
      });
      valid = false;
    }
  }

  return valid;
};
