import {
  Alert,
  AlertAttributePayload,
  AlertSchema,
  AlertSourceConfig,
  CatalogResource,
  ErrorResponse,
} from "@incident-io/api";
import {
  Button,
  ButtonSize,
  ButtonTheme,
  DropdownMenu,
  DropdownMenuItem,
  EmptyState,
  Icon,
  IconEnum,
  IconSize,
  Loader,
} from "@incident-ui";
import { ButtonGroup } from "@incident-ui/ButtonGroup/ButtonGroup";
import { JSONPreview } from "@incident-ui/JSONPreview/JSONPreview";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { tcx } from "src/utils/tailwind-classes";

import { AlertsTable, AlertTableColumn } from "../../common/AlertsTable";
import { SingleAlertPreview } from "./SingleAlertPreview";

// Enum choosing between the different ways to view a preview.
export type PreviewView = "table" | "single-alert" | "payload";

// Shown on the right split of the alert source edit page, this component
// displays recent alerts in table and other formats to show how alerts would be
// parsed with existing configuration.
export const AlertsPreviewSplit = ({
  alerts,
  resources,
  existingSource,
  schema,
  isLoading,
  error,
  assignedAttributes,
}: {
  alerts: Alert[];
  resources: CatalogResource[];
  existingSource: AlertSourceConfig;
  schema: AlertSchema;
  isLoading: boolean;
  error?: ErrorResponse;
  assignedAttributes: AlertAttributePayload[];
}) => {
  // TODO: Unused right now, but we'll eventually switch between alerts nicely.
  const [alertId, setAlertId] = useState<string | null>(
    !isEmpty(alerts) ? alerts[0].id : null,
  );

  useEffect(() => {
    if (alertId == null && alerts.length > 0) {
      setAlertId(alerts[0].id);
    }
  }, [alerts, alertId, setAlertId]);

  const selectedAlert = alerts.find((alert) => alert.id === alertId);

  // By default show the table.
  const [previewView, setPreviewView] = useState<PreviewView>("table");

  return (
    <div
      className={tcx(
        // Layout
        "flex flex-col grow py-5 px-6 gap-5 h-full w-full overflow-auto",
        // Dotted background
        "bg-surface-secondary bg-[radial-gradient(#e5e7eb_1px,transparent_1px)] [background-size:16px_16px]",
      )}
    >
      {isLoading || !alerts ? (
        <Loader />
      ) : alerts.length === 0 ? (
        <AlertsPreviewEmptyState item={"alert"} error={error} />
      ) : (
        // Apply specific height to account for select alert dropdown
        <div className="flex flex-col grow gap-4">
          <div className="flex flex-row w-full justify-between items-center gap-4 h-[32px]">
            {/* Alert toolbar */}
            <div className="flex-grow">
              {previewView !== "table" && (
                <DropdownMenu
                  triggerButton={
                    <Button
                      theme={ButtonTheme.Secondary}
                      className="h-8 text-sm-med !rounded-[4px]"
                      analyticsTrackingId="alert-source-preview-select-alert"
                      size={ButtonSize.Medium}
                      icon={IconEnum.ChevronDown}
                      iconPosition="right"
                    >
                      {selectedAlert?.title || "Select alert"}
                    </Button>
                  }
                >
                  {alerts.map((alert) => (
                    <DropdownMenuItem
                      key={alert.id}
                      label={alert.title}
                      onSelect={() => {
                        setAlertId(alert.id);
                      }}
                      analyticsTrackingId={`alert-source-preview-select-alert-${alert.id}`}
                    />
                  ))}
                </DropdownMenu>
              )}
            </div>
            <div className="flex flex-row items-center gap-2">
              <p className="text-xs-med text-content-secondary">View by</p>
              <ButtonGroup
                analyticsTrackingId={"alert-source-preview-view"}
                value={previewView}
                className="border border-stroke h-[28px] flex flex-row !rounded-[4px]"
                buttonClassName="h-full flex items-center justify-center px-2 !rounded-[4px] m-[-1px] w-[28px] h-[28px]"
                onChange={(value) => setPreviewView(value as PreviewView)}
                options={[
                  {
                    value: "table",
                    label: <Icon id={IconEnum.Table} size={IconSize.Small} />,
                  },
                  {
                    value: "single-alert",
                    label: <Icon id={IconEnum.List} size={IconSize.Small} />,
                  },
                  {
                    value: "payload",
                    label: <Icon id={IconEnum.Code} size={IconSize.Small} />,
                  },
                ]}
              />
            </div>
          </div>

          {/* Alert preview */}
          <div className={"w-full grow"}>
            {previewView === "table" ? (
              <AlertsTable
                schema={schema}
                resources={resources}
                alertSourceConfigs={[existingSource]}
                alerts={alerts}
                allEntriesLoaded={true}
                enableSelection={false}
                // Only show the attributes that are assigned by this sources
                visibleColumns={[
                  {
                    type: "priority",
                  },
                  ...assignedAttributes.map((attribute): AlertTableColumn => {
                    return {
                      type: "attribute",
                      attribute: {
                        name: attribute.name,
                        type: attribute.type,
                        array: attribute.array,
                        id: attribute.id ?? "",
                      },
                    };
                  }),
                ]}
                maxNameWidth={"100%"}
                minColumnWidth={"80px"}
                wrappedInBox
              />
            ) : previewView === "single-alert" ? (
              <SingleAlertPreview
                alert={selectedAlert || alerts[0]}
                isLoading={isLoading}
                resources={resources}
                schema={schema}
              />
            ) : (
              <JSONPreview
                lightMode
                payload={JSON.parse(selectedAlert?.payload || "null")}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

const AlertsPreviewEmptyState = ({
  item,
  error,
}: {
  item: "alert" | "payload";
  error?: ErrorResponse;
}) => {
  return (
    <EmptyState
      className="grow bg-transparent border-0"
      icon={IconEnum.Alert}
      title={error ? "Preview unavailable" : `No ${item}s received yet`}
      content={
        error
          ? "We're having trouble getting your alert previews. Contact us if this issue persists."
          : "Connect your alert source to start receiving your alerts."
      }
    />
  );
};
