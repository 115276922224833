import { Toggle } from "@incident-ui";
import { v4 as uuid } from "uuid";

import { INCLUDE_PRIVATE } from "./constants";
import { useFiltersContext } from "./FiltersContext";

export const ToggleIncludePrivateIncidentsFilter = ({
  className,
}: {
  className?: string;
}) => {
  const { filters, addFilter, deleteFilter, availableFilterFields } =
    useFiltersContext();
  const includePrivateFilterAvailable = availableFilterFields.find(
    (f) => f.key === INCLUDE_PRIVATE,
  );

  const includePrivateIncidentsFilter = filters.find(
    (f) => f.field_key === INCLUDE_PRIVATE,
  );

  const onToggleIncludePrivateIncidents = () => {
    // if it's currently on, we need to remove the filter
    if (includePrivateIncidentsFilter) {
      deleteFilter(includePrivateIncidentsFilter.field_id);
    } else {
      // if it's currently off, we need to add a filter
      addFilter({
        key: uuid(),
        field_key: INCLUDE_PRIVATE,
        bool_value: true,
        operator: "is",
        field_id: INCLUDE_PRIVATE,
        filter_id: INCLUDE_PRIVATE,
      });
    }
  };

  if (!includePrivateFilterAvailable) {
    return null;
  }

  return (
    <div className={`hidden md:flex items-center mt-0 ${className}`}>
      <Toggle
        id={"show_private_incidents"}
        on={includePrivateIncidentsFilter != null}
        label="Include private incidents"
        onToggle={onToggleIncludePrivateIncidents}
        toggleLabelClassName="!text-slate-700 !mr-2 font-normal"
      />
    </div>
  );
};
