import {
  Badge,
  BadgeTheme,
  Button,
  ButtonTheme,
  Heading,
  Icon,
  IconEnum,
  IconSize,
  StackedList,
} from "@incident-ui";
import { useNavigateToModal } from "src/utils/query-params";
import { tcx } from "src/utils/tailwind-classes";

export type IncidentSidebarListItemProps = {
  icon: IconEnum;
  iconClassName?: string;
  title: string;
  url: string;
};

const maxItems = 3;

export const IncidentSidebarList = ({
  title,
  items,
  viewAllUrl,
  accessory,
}: {
  title: string;
  items: IncidentSidebarListItemProps[];
  viewAllUrl: string;
  accessory?: React.ReactNode;
}): React.ReactElement => {
  const navigateToDrawer = useNavigateToModal();

  // For the analytics tracking ids, convert the title to lowercase and replace spaces with hyphens
  const joinedTitle = title.toLowerCase().replace(/ /g, "-");

  const itemsToShow = items.slice(0, maxItems);

  const hasItems = items.length > 0;

  return (
    <div className="space-y-2">
      <div className="flex-center-y justify-between">
        <div className="flex gap-1 items-center">
          <Heading level={3} size="small">
            {title}
          </Heading>
          <Button
            analyticsTrackingId={`sidebar-view-all-${joinedTitle}`}
            onClick={() => navigateToDrawer(viewAllUrl)}
            theme={ButtonTheme.Naked}
            title="View all"
          >
            {hasItems && (
              <Badge
                theme={BadgeTheme.Secondary}
                className="!px-1 !py-0 cursor-pointer"
              >
                {items.length}
              </Badge>
            )}
          </Button>
        </div>
        {/* If there's an accessory, show that on the right hand side. If there's not, and there are no items, highlight that there are no items. */}
        {accessory ? (
          accessory
        ) : !hasItems ? (
          <span className="text-sm-normal text-content-tertiary">
            No {title.toLowerCase()} yet
          </span>
        ) : null}
      </div>
      {items.length > 0 && (
        <StackedList className="max-w-full">
          {itemsToShow.map((item, index) => (
            <Button
              analyticsTrackingId={`sidebar-view-single-${joinedTitle}`}
              href={item.url}
              openInNewTab
              theme={ButtonTheme.Naked}
              key={index}
              className="w-full hover:bg-surface-secondary flex-center-y p-3 gap-2"
              title={item.title}
            >
              <Icon
                id={item.icon}
                size={IconSize.Medium}
                className={tcx("shrink-0", item.iconClassName)}
              />
              <span className="text-sm-med text-content-primary truncate">
                {item.title}
              </span>
            </Button>
          ))}
          {items.length > maxItems && (
            <Button
              analyticsTrackingId={`sidebar-view-more-${joinedTitle}`}
              onClick={() => navigateToDrawer(viewAllUrl)}
              theme={ButtonTheme.Naked}
              key={"view-more"}
              className="w-full hover:bg-surface-secondary flex-center-y p-3 gap-2"
            >
              <div className="text-sm-med text-content-tertiary">
                +{items.length - maxItems} more
              </div>
            </Button>
          )}
        </StackedList>
      )}
    </div>
  );
};
