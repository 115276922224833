import {
  getTypeaheadOptions,
  hydrateInitialSelectOptions,
  TypeaheadTypeEnum,
} from "@incident-shared/forms/Typeahead";
import { DynamicSingleSelectV2 } from "@incident-shared/forms/v2/inputs/DynamicSelectV2";
import { Avatar, IconSize, ModalFooter } from "@incident-ui";
import { useFlags } from "launchdarkly-react-client-sdk";
import React from "react";
import { useForm } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import { PostIncidentTaskSlim, useClient } from "src/contexts/ClientContext";
import { useAPIMutation } from "src/utils/swr";

import { AssigneeWrapper } from "../sidebar/RoleAssignments";

export const PostIncidentTaskAssignee = ({
  incidentId,
  incidentTask,
}: {
  incidentId: string;
  incidentTask: PostIncidentTaskSlim;
}) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  return (
    <>
      <PostIncidentTaskAssigneeUI
        task={incidentTask}
        onEdit={() => setIsModalOpen(true)}
      />
      {isModalOpen && (
        <EditPostIncidentTaskAssigneeModal
          incidentId={incidentId}
          task={incidentTask}
          onClose={() => setIsModalOpen(false)}
        />
      )}
    </>
  );
};

export const PostIncidentTaskAssigneeUI = ({
  task,
  onEdit,
}: {
  task: PostIncidentTaskSlim;
  onEdit: () => void;
}) => {
  return (
    <AssigneeWrapper
      analyticsTrackingId={"post-incident-tasks-assignee"}
      editable
      onEdit={onEdit}
    >
      <Avatar
        size={IconSize.Large}
        url={task?.assignee?.avatar_url}
        name={task?.assignee?.name}
      />
      <span className="text-right truncate">
        {task?.assignee?.name ? (
          <span>{task?.assignee?.name}</span>
        ) : (
          <span className="text-slate-600">{"Assign"}</span>
        )}
      </span>
    </AssigneeWrapper>
  );
};

export const EditPostIncidentTaskAssigneeModal = ({
  incidentId,
  task,
  onClose,
}: {
  incidentId: string;
  task: PostIncidentTaskSlim;
  onClose: () => void;
}) => {
  const apiClient = useClient();
  const formMethods = useForm<{ assignee_id?: string }>({
    defaultValues: {
      assignee_id: task.assignee?.id,
    },
  });

  const {
    trigger: onSubmit,
    isMutating,
    genericError,
  } = useAPIMutation(
    "postIncidentFlowListTasks",
    { incidentId: incidentId },
    async (apiClient, formData) => {
      await apiClient.postIncidentFlowAssignTask({
        id: task.id,
        assignTaskRequestBody: {
          assignee_id: formData.assignee_id,
        },
      });
    },
    {
      onSuccess: onClose,
    },
  );

  return (
    <Form.Modal
      formMethods={formMethods}
      onSubmit={onSubmit}
      title={"Assign task"}
      saving={isMutating}
      analyticsTrackingId={`assign-incident-post-incident-task`}
      onClose={onClose}
      genericError={genericError}
      footer={
        <ModalFooter
          saving={isMutating}
          onClose={onClose}
          confirmButtonType="submit"
          confirmButtonText="Save"
          cancelButtonText="Cancel"
        />
      }
    >
      <DynamicSingleSelectV2
        formMethods={formMethods}
        name="assignee_id"
        autoFocus={true}
        label="Assignee"
        required={false}
        helptext={
          "Choose a user who should be responsible for this task and we'll notify them."
        }
        placeholder="Select a user"
        loadOptions={getTypeaheadOptions(apiClient, TypeaheadTypeEnum.User, {
          incidentId: incidentId,
        })}
        hydrateOptions={hydrateInitialSelectOptions(
          apiClient,
          TypeaheadTypeEnum.User,
        )}
      />
      <AssigneeContext task={task} />
    </Form.Modal>
  );
};

const AssigneeContext = ({ task }: { task: PostIncidentTaskSlim }) => {
  const { featureAutoAssign } = useFlags();

  if (!featureAutoAssign) {
    return null;
  }

  // If there isn't a default assignee, we don't need to show anything.
  if (!task.config.uses_auto_assign) {
    return null;
  }

  // If we don't know who made the last assignment, we can't show anything here.
  if (!task.assigner) {
    return null;
  }

  let text: string | null = null;

  if (task.assigner.user) {
    // If the task was last assigned by a user, we'll point out that we won't blat over it.
    if (task.assignee) {
      text =
        "This task was assigned manually, so we won't use the default assignee.";
    } else {
      text =
        "The assignee was cleared manaully, so we won't use the default assignee.";
    }
  } else if (task.assigner.integration) {
    text =
      "We've set this to be the default assignee, but you can manually override it.";
  }

  if (!text) {
    return null;
  }

  return <Form.Helptext className="text-xs !mt-2">{text}</Form.Helptext>;
};
