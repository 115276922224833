import { ALERT_SOURCE_TYPE_CONFIGS } from "@incident-shared/integrations";
import { HeaderBarTitle } from "@incident-shared/layout/HeaderBar/HeaderBar";
import { PageWidth, PageWrapper } from "@incident-shared/layout/PageWrapper";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import {
  Button,
  GenericErrorMessage,
  IconBadge,
  IconEnum,
  IconSize,
} from "@incident-ui";
import {
  Drawer,
  DrawerContents,
  DrawerTitle,
} from "@incident-ui/Drawer/Drawer";
import { FullPageLoader } from "@incident-ui/Loader/Loader";
import { AnimatePresence } from "framer-motion";
import { useRef, useState } from "react";
import { useParams } from "react-router";

import { AlertSourceConnectPage } from "./AlertSourceConnectPage";
import { AlertSourceConfigurePage } from "./configure/AlertSourceConfigurePage";
import { useAlertSourceConfigDeps } from "./useAlertSourceConfigDeps";

export const AlertSourceEditPage = () => {
  const params = useParams<{ id: string }>();
  const alertSourceConfigId = params.id as string;

  const [showConnectDrawer, setShowConnectDrawer] = useState(false);

  const titleInputRef = useRef<HTMLDivElement>(null);

  const { loading, error, alertSource, alertSourceConfig, sourceTypeConfig } =
    useAlertSourceConfigDeps({ alertSourceConfigId });

  if (error) {
    return <GenericErrorMessage error={error} />;
  }

  const alertSourceIcon = sourceTypeConfig?.icon || IconEnum.Alert;

  const crumbs = [
    {
      title: "Alerts",
      to: "/alerts",
    },
    {
      title: "Configuration",
      to: "/alerts/configuration",
    },
  ];
  return (
    <>
      <AnimatePresence>
        {showConnectDrawer && alertSourceConfig && (
          <AlertSourceConnectDrawer
            sourceType={alertSourceConfig.source_type}
            alertSourceConfigId={alertSourceConfig.id}
            onClose={() => setShowConnectDrawer(false)}
          />
        )}
      </AnimatePresence>
      <PageWrapper
        width={PageWidth.Full}
        noPadding
        title="Edit alert source"
        titleNode={
          <HeaderBarTitle
            crumbs={crumbs}
            title="Edit alert source"
            titleNode={
              <div
                className="flex flex-row items-baseline grow"
                ref={titleInputRef}
              />
            }
            isEditable
          />
        }
        icon={alertSourceIcon}
        iconSize={alertSource ? IconSize.XL : undefined}
        color={alertSource ? ColorPaletteEnum.SlateOnWhite : undefined}
        backHref={"/alerts/configuration"}
        overflowY={false}
        accessory={
          <Button
            analyticsTrackingId="alert-source-manage-connection"
            onClick={() => setShowConnectDrawer(true)}
          >
            Manage connection
          </Button>
        }
      >
        {loading || !alertSource || !alertSourceConfig ? (
          <FullPageLoader />
        ) : (
          <AlertSourceConfigurePage
            mode={"edit"}
            titleInputRef={titleInputRef}
            {...{ alertSource, alertSourceConfig }}
          />
        )}
      </PageWrapper>
    </>
  );
};

const AlertSourceConnectDrawer = ({
  sourceType,
  alertSourceConfigId,
  onClose,
}: {
  alertSourceConfigId: string;
  sourceType: string;
  onClose: () => void;
}) => {
  const config = ALERT_SOURCE_TYPE_CONFIGS[sourceType];

  return (
    <Drawer width="full" onClose={onClose}>
      <div className="flex flex-col h-full !overflow-hidden">
        <DrawerContents>
          <DrawerTitle
            title="Manage connection"
            hexColor={`${config.hexColor}`}
            className="text-white"
            onClose={onClose}
            titleAccessory={
              <IconBadge
                icon={config.icon}
                size={IconSize.Small}
                hexColor={config.hexColor}
                // In integrations, icons are either coloured logos or we should let people
                // configure it and align it to the rest of the drawer with hexColor.
                color={ColorPaletteEnum.SlateOnWhite}
              />
            }
          />
          <AlertSourceConnectPage
            alertSourceConfigId={alertSourceConfigId}
            mode={"drawer"}
          />
        </DrawerContents>
      </div>
    </Drawer>
  );
};
