import { createContext, useContext, useState } from "react";
import { useReactFlow } from "reactflow";

type ZoomContextProps = {
  zoomLevel: number;
  // setZoomLevel is used to update the zoom counter in our zoom controls. It's
  // used separately to updateZoom when we only want to update the counter but are
  // using ReactFlow's helpers for recentering the canvas
  setZoomLevel: (level: number) => void;
  // updateZoom is used to update the zoom level and zoom the canvas to the new
  // level
  updateZoom: (level: number) => void;
};

const ZoomContext = createContext<ZoomContextProps>({
  zoomLevel: 1,
  setZoomLevel: () => null,
  updateZoom: () => null,
});

export const useZoomContext = () => {
  const context = useContext(ZoomContext);

  if (!context) {
    throw new Error("useZoomContext must be used within a ZoomProvider");
  }

  return context;
};

export const ZoomProvider = ({ children }: { children: React.ReactNode }) => {
  const [zoomLevel, setZoomLevel] = useState(1);
  const { zoomTo } = useReactFlow();

  return (
    <ZoomContext.Provider
      value={{
        zoomLevel,
        setZoomLevel,
        updateZoom: (zoomLevel: number) => {
          zoomTo(zoomLevel, { duration: 800 });
          setZoomLevel(zoomLevel);
        },
      }}
    >
      {children}
    </ZoomContext.Provider>
  );
};
