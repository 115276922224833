import {
  EngineReferenceBadge,
  ReferenceSelectorPopover,
} from "@incident-shared/engine";
import {
  Button,
  ButtonSize,
  ButtonTheme,
  IconEnum,
  IconSize,
} from "@incident-ui";
import { Input, InputType } from "@incident-ui/Input/Input";
import { Mark, MarkType } from "prosemirror-model";
import { EditorState, Selection, TextSelection } from "prosemirror-state";
import { EditorView } from "prosemirror-view";
import { useEffect, useRef, useState } from "react";
import { getEmptyScope } from "src/utils/scope";

import { schema } from "./schema";
import { useEditorVariables } from "./TemplatedTextEditor";
import { toolbarButtonProps } from "./Toolbar";

export type LinkAttrProps =
  // First a totally fake link that is just to highlight the selection
  | { editing: true; href?: never; href_var?: never; href_var_label?: never }
  // A static link
  | {
      editing?: boolean;
      href: string;
      href_var?: never;
      href_var_label?: never;
    }
  // A variable link
  | {
      editing?: boolean;
      href?: never;
      href_var: string;
      href_var_label: string;
    };

export const LinkPopover = ({
  view,
  state,
}: {
  view: EditorView;
  state: EditorState;
}) => {
  const [selection, setSelection] = useState<null | Selection>(null);
  const viewRef = useRef(view);
  viewRef.current = view;
  const stateRef = useRef(state);
  stateRef.current = state;

  const selectedText = findSelectedText(state?.selection);
  const highlightedLinkMark = findCurrentLink(state?.selection);
  const hasSelection = selection != null;

  useEffect(() => {
    // If the view is unfocussed, don't do anything - we're probably in the link
    // editor view.
    if (!viewRef.current.hasFocus()) return;

    // If the cursor moves away from a link, close the editor
    if (!highlightedLinkMark) {
      setSelection(null);
      return;
    }

    // If the cursor is inside a link, start editing it
    const state = stateRef.current;
    const markPos = markPosition(
      state,
      state.selection.$anchor.pos,
      schema.marks.link,
    );
    if (!markPos) return;

    setSelection(
      new TextSelection(
        state.doc.resolve(markPos.from),
        state.doc.resolve(markPos.to),
      ),
    );
    return;
  }, [hasSelection, highlightedLinkMark]);

  if (selection != null) {
    return <ManageLinkToolbar view={view} selection={selection} />;
  }

  // Don't allow adding a link to something with no text
  const disabled = selectedText === "";

  // The styling here should be close to ToolbarButton
  return (
    <Button
      analyticsTrackingId="templatedtext-add-link"
      size={ButtonSize.Small}
      icon={IconEnum.Link}
      title={"Add link"}
      onClick={() => setSelection(state.selection)}
      disabled={disabled}
      {...toolbarButtonProps(false, disabled)}
    />
  );
};

const ManageLinkToolbar = ({
  view: viewProp,
  selection,
}: {
  view: EditorView;
  selection: Selection;
}) => {
  // Ref-ify these, so they don't cause loops on useEffect
  const viewRef = useRef(viewProp);
  viewRef.current = viewProp;

  const [linkMark, setLinkMark] = useState<{
    selection: Selection;
    mark: Mark;
  } | null>(null);

  // The selection object makes it hard to compare for useEffect, so just use
  // the from/to here.
  const { from: selectionFrom, to: selectionTo } = selection;

  // When we start linking, create a fake link mark to highlight the selection.
  useEffect(() => {
    // Build a selection object. This achieves two things:
    // 1. It allows the useEffect to only run if the selection actually moves; and
    // 2. It ensures we use the same selection object in the cleanup function.
    const selection = new TextSelection(
      viewRef.current.state.doc.resolve(selectionFrom),
      viewRef.current.state.doc.resolve(selectionTo),
    );

    // If there's a link mark on the selection, we want to reuse its attributes.
    const existingMark = findCurrentLink(selection);
    setLinkMark((prev) => {
      // If there's a mark stashed already, do nothing.
      if (prev) return prev;

      // Otherwise, add a mark to the selection. It has the `editing` flag set,
      // to highlight the text, and retains any existing attributes.
      return {
        selection,
        mark: addLink(viewRef.current, viewRef.current.state, selection, {
          ...(existingMark?.attrs ?? {}),
          editing: true,
        }),
      };
    });

    return () => {
      const view = viewRef.current;
      const state = view.state;

      // Clean up the 'editing' flag we added.
      setLinkMark((prev) => {
        // This should only happen in dev, where the useEffect runs twice.
        if (prev == null) return null;

        const attrs = prev.mark.attrs as LinkAttrProps;

        // No link was added, so remove the mark altogether.
        if (!attrs.href && !attrs.href_var) {
          view.dispatch(state.tr.removeMark(0, selection.to, prev.mark));
          return null;
        }

        // Remove the editing=true flag, but keep the other attributes
        addLink(view, state, selection, { ...attrs, editing: undefined });

        return null;
      });
    };
  }, [selectionFrom, selectionTo]);

  const { scope, includeVariables } = useEditorVariables();
  // This does two things:
  // 1. Updates the mark in the Prosemirror state
  // 2. Updates the mark stored in state, for cleanup when the selection moves.
  const updateLink = (data: LinkAttrProps) => {
    setLinkMark({
      selection,
      mark: addLink(viewRef.current, viewRef.current.state, selection, {
        ...data,
        editing: true,
      }),
    });
  };

  // Don't render anything until we've figured out what to render using the
  // `useEffect`
  if (!linkMark) return null;

  return (
    <div className="flex items-center absolute w-full top-0 left-0 p-1 z-10 bg-surface-secondary">
      {!linkMark.mark.attrs.href_var ? (
        <Input
          // Only auto-focus if there is no existing link
          autoFocus={!linkMark.mark.attrs.href}
          onChange={(ev) => updateLink({ href: ev.currentTarget.value })}
          value={linkMark.mark.attrs.href ?? ""}
          id="href"
          type={InputType.Url}
          className="grow mr-1"
          onKeyPress={(ev) => {
            // Prevent this propagating to submitting the whole form
            if (ev.key === "Enter") {
              ev.preventDefault();
            }
          }}
        />
      ) : (
        <EngineReferenceBadge
          className="grow mr-1 !bg-white"
          label={linkMark.mark.attrs.href_var_label}
        />
      )}
      {includeVariables && (
        <ReferenceSelectorPopover
          scope={scope ?? getEmptyScope()}
          isSelectable={(ref) =>
            ref.resource.type === "Link" ||
            ref.resource.cast_types.some((ct) => ct.type === "Link")
          }
          onSelectReference={(ref) =>
            updateLink({
              href_var: ref.key,
              href_var_label: ref.label,
            })
          }
          // Expressions are pretty awkward, so skip them
          allowExpressions={false}
          renderTriggerButton={({ onClick }) => (
            <Button
              analyticsTrackingId={null}
              theme={ButtonTheme.Naked}
              className={"px-2"}
              title={"Use a variable"}
              icon={IconEnum.Bolt}
              onClick={onClick}
            />
          )}
        />
      )}
      <Button
        analyticsTrackingId={null}
        theme={ButtonTheme.Naked}
        className={"px-2"}
        title={"Remove link"}
        icon={IconEnum.LinkBreak}
        iconProps={{ size: IconSize.Large }}
        onClick={() => {
          // This is a bit funky! First, remove the mark from Prosemirror
          removeLink(viewProp, viewProp.state, selection);
          // Remove this from the state, to stop rendering this bit of UI
          setLinkMark(null);
          // Move the focus back into the editor
          viewProp.focus();
        }}
      />
    </div>
  );
};

const addLink = (
  view: EditorView,
  state: EditorState,
  selection: Selection,
  data: LinkAttrProps,
) => {
  const mark = schema.marks.link.create(data);
  view.dispatch(state.tr.addMark(selection.from, selection.to, mark));
  return mark;
};

const removeLink = (
  view: EditorView,
  state: EditorState,
  selection: Selection,
) => {
  // Find the link at the current cursor
  const markPos = markPosition(state, selection.$anchor.pos, schema.marks.link);
  if (!markPos) return;

  // Remove that mark
  view.dispatch(state.tr.removeMark(markPos.from, markPos.to, markPos.mark));
};

// markPosition finds the from/to of a specific mark, which is very useful for
// updating it!
//
// Via: https://discuss.prosemirror.net/t/expanding-the-selection-to-the-active-mark/478/9
const markPosition = (state: EditorState, pos: number, markType: MarkType) => {
  const $pos = state.doc.resolve(pos);

  const { parent, parentOffset } = $pos;
  const start = parent.childAfter(parentOffset);
  if (!start.node) return undefined;

  const mark = start.node.marks.find((mark) => mark.type === markType);
  if (!mark) return undefined;

  let startIndex = $pos.index(); //2
  let from = $pos.start() + start.offset; //26
  let endIndex = startIndex + 1; //3
  let to = from + start.node.nodeSize; //26+6 = 32
  while (startIndex > 0 && mark.isInSet(parent.child(startIndex - 1).marks)) {
    startIndex -= 1; //2
    from -= parent.child(startIndex).nodeSize; //26
  }
  while (
    endIndex < parent.childCount &&
    mark.isInSet(parent.child(endIndex).marks)
  ) {
    to += parent.child(endIndex).nodeSize;
    endIndex += 1;
  }
  return { from, to, mark };
};

// findSelectedText converts a selection to the text within that selection.
const findSelectedText = (selection: Selection) => {
  if (!selection) return "";

  const selectedContent = selection.content().content;

  return selectedContent.textBetween(0, selectedContent.size - 2);
};

// findCurrentLink finds the link mark in the selection given.
const findCurrentLink = (selection: Selection) => {
  if (!selection) return undefined;

  // Traverse the selected content, to find any selected link marks
  let selectedMarks: Mark[] = [];
  selection.content().content.descendants((n) => {
    selectedMarks = selectedMarks.concat(n.marks);
  });

  const linkMark = [
    ...(selection.$from.marksAcross(selection.$to) || []),
    ...selectedMarks,
  ].find((mark) => mark.type === schema.marks.link);

  return linkMark;
};
