import { Product } from "@incident-shared/billing";
import {
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  ContentBox,
  GenericErrorMessage,
  Loader,
} from "@incident-ui";
import { useFlags } from "launchdarkly-react-client-sdk";
import React from "react";
import graphic from "src/components/settings/banners/banner-ai.svg";
import { useIdentity } from "src/contexts/IdentityContext";
import { AIFeatureEnum } from "src/hooks/useAI";
import { useSettings } from "src/hooks/useSettings";
import { useAPI } from "src/utils/swr";

import { SettingsHeading } from "../SettingsHeading";
import { SettingsSubPageWrapper } from "../SettingsRoute";
import { AIFeatureToggle } from "./AIFeatureToggle";
import { SummariseIncidentsToggle } from "./SummariseIncidentsToggle";

export const AIPage = (): React.ReactElement => {
  const { identity } = useIdentity();
  const canUseAI = identity?.feature_gates.ai_features;

  return (
    <SettingsSubPageWrapper>
      <SettingsHeading
        title="Activate AI features"
        subtitle="Enable AI to automate incident processes and allow responders to focus on critical tasks."
        planNameMustUpgradeTo="Team"
        requiresUpgrade={!identity?.feature_gates.ai_features}
        articleId={8556735}
        graphic={<img src={graphic} className="h-40" />}
      />
      {canUseAI ? <AIPageInner /> : <></>}
    </SettingsSubPageWrapper>
  );
};

const AIPageInner = (): React.ReactElement => {
  const {
    data: configResp,
    isLoading: configLoading,
    error: configError,
  } = useAPI("aIShowConfig", undefined);

  const { settings } = useSettings();
  const { identity } = useIdentity();
  const { featureLiveCallTranscription } = useFlags();

  if (!configResp || configLoading || !settings || !identity) {
    return <Loader />;
  }

  if (configError) {
    return (
      <ContentBox className="p-4">
        <GenericErrorMessage error={configError} />
      </ContentBox>
    );
  }

  const cfg = configResp.config;

  return (
    <>
      {!cfg.enabled && (
        <Callout theme={CalloutTheme.Danger}>
          You have opted out of all AI features. If you&apos;d like to change
          this decision, please{" "}
          <Button
            analyticsTrackingId={"openai-consent"}
            theme={ButtonTheme.Link}
            className="underline"
            href="mailto:support@incident.io"
          >
            talk to us
          </Button>
          .
        </Callout>
      )}

      {/* Feature toggles are automatically disabled if AI is disabled entirely. */}
      {/* Assistant. */}
      <AIFeatureToggle
        requiredProduct={Product.Response}
        config={cfg}
        feature={AIFeatureEnum.Assistant}
        title={"Assistant"}
        description={
          <>
            When toggled, you&rsquo;ll be able to ask the AI Assistant about
            your incidents to understand historical trends, identify patterns,
            and build your own charts.
          </>
        }
      />

      {/* Suggested Summaries. */}
      <SummariseIncidentsToggle config={cfg} />

      {/* Related Incidents. */}
      <AIFeatureToggle
        config={cfg}
        feature={AIFeatureEnum.IncidentSimilarity}
        title="Related incidents"
        description={
          <>
            When an active incident seems related to a resolved incident,
            we&rsquo;ll suggest inviting the resolved incident&rsquo;s lead to
            the incident channel to help out.
          </>
        }
        requiredProduct={Product.Response}
      />

      {/* Suggested Follow-ups. */}
      <AIFeatureToggle
        requiredProduct={Product.Response}
        config={cfg}
        feature={AIFeatureEnum.SuggestFollowups}
        title="Suggested follow-ups"
        description={
          <>
            When an incident is resolved, we will check if you are missing any
            follow-up items that could help you respond better in the future.
          </>
        }
      />

      {/* Catalog suggestions */}
      <AIFeatureToggle
        config={cfg}
        feature={AIFeatureEnum.CatalogSuggestions}
        title="Catalog suggestions"
        description={
          <>
            We&rsquo;ll help you build out your catalog by suggesting
            connections between your systems: for example matching all your
            Teams to your Github teams.
          </>
        }
      />

      {/* Call transcription */}
      {featureLiveCallTranscription && (
        <AIFeatureToggle
          config={cfg}
          requiredProduct={Product.Response}
          feature={AIFeatureEnum.CallTranscription}
          title="Call transcription"
          description={
            <>
              We&rsquo;ll transcribe your incident calls and provide a concise
              summary of the most critical points.
            </>
          }
          upgradeRequired={
            identity?.feature_gates.call_transcription_hours_per_month != null
          }
          upgradeRequiredProps={{
            featureName: "call transcription",
            gate: {
              type: "numeric",
              value: identity.feature_gates.call_transcription_hours_per_month,
              featureNameSingular: "call transcription hours",
            },
          }}
        />
      )}
    </>
  );
};
