import { EscalationPathNodeTypeEnum as NodeTypes } from "@incident-io/api";
import { ErrorMessage } from "@incident-ui";
import { Icon, IconEnum, IconSize } from "@incident-ui";
import { useFormContext } from "react-hook-form";
import { useNodeFormErrors } from "src/components/escalation-paths/nodes/useNodeFormErrors";
import { useAPI } from "src/utils/swr";

import { escalationPathWorkingHoursToPayload } from "../common/marshall";
import { EscalationPathFormData, PathNode } from "../common/types";
import { deleteNode } from "../node-editor/helpers/deleteNode";
import { getConditionCount } from "../node-editor/helpers/getNodeCount";
import { IfElseConditionPopover } from "./IfElseConditionPopover";
import { LoadingNodeCard, NodeCard } from "./NodeCard";

// NodeIfElse is used for all IfElse nodes in an escalation path except for the start node.
// Counter is the number next to the IfElse node header: 'Condition: X'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const NodeIfElse = ({ id }: { id: string }) => {
  const formMethods = useFormContext<EscalationPathFormData>();

  const nodes = formMethods.watch("nodes");
  const firstNodeId = formMethods.watch("firstNodeId");
  const workingHours = formMethods.watch("working_hours");

  const { data: scopeData } = useAPI(
    "escalationPathsBuildScope",
    {
      buildScopeRequestBody: {
        working_hours: escalationPathWorkingHoursToPayload(workingHours),
      },
    },
    {},
  );

  const relatedErrors = useNodeFormErrors({
    formMethods,
    id,
    nodeType: NodeTypes.IfElse,
  });

  const icon = (
    <div className="bg-blue-surface rounded-2 w-10 h-10 flex items-center justify-center">
      <Icon
        id={id === firstNodeId ? IconEnum.Flag : IconEnum.GitBranch}
        size={IconSize.Large}
        className="fill-blue-500 text-blue-500 bg-blue-surface"
      />
    </div>
  );

  const conditionCount = getConditionCount(nodes, firstNodeId, id);

  const titleCopy =
    id === firstNodeId ? "Start" : `Condition ${conditionCount}`;

  const title = <div className="text-xs text-slate-600">{titleCopy}</div>;

  if (!scopeData) {
    return (
      <LoadingNodeCard
        id={id}
        title={title}
        icon={icon}
        subtitle="If escalation matches these conditions"
        allowAddAbove={false}
      />
    );
  }

  const onDeleteNode = () => {
    deleteNode({
      nodeId: id,
      nodes: formMethods.getValues().nodes,
      firstNodeId: formMethods.getValues().firstNodeId,
      updateNodes: (nodes: Record<string, PathNode>) => {
        formMethods.setValue<"nodes">("nodes", nodes);
      },
      updateFirstNodeId: (firstNodeId: string) => {
        formMethods.setValue<"firstNodeId">("firstNodeId", firstNodeId);
      },
    });
  };

  return (
    <NodeCard
      nodeId={id}
      title={title}
      subtitle="If escalation matches these conditions"
      icon={icon}
      allowAddAbove={false}
      allowAddBelow={false}
      onDeleteNode={onDeleteNode}
    >
      <div className="pt-4">
        <IfElseConditionPopover nodeId={id} />
      </div>

      {relatedErrors.length > 0 ? (
        <ErrorMessage message={relatedErrors[0]} className={"text-xs"} />
      ) : null}
    </NodeCard>
  );
};
