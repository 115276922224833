import { Alert, AlertStatusEnum, Priority } from "@incident-io/api";
import {
  Badge,
  BadgeTheme,
  ContentBox,
  Link,
  LocalDateTime,
  OrgAwareLink,
  Txt,
} from "@incident-ui";
import { LocalRelativeDateTime } from "@incident-ui/LocalDateTime/LocalRelativeDateTime";

import { AlertSourceBadge } from "../common/AlertOverviewColumn";
import { AlertPriorityBadge } from "../common/AlertPriorityBadge";

export const DetailsSection = ({
  alert,
  priorities,
}: {
  alert: Alert;
  priorities: Priority[];
}) => {
  const priority = priorities.find((p) => p.id === alert.priority_id);

  return (
    <div className={"space-y-2"}>
      <Txt className={"font-medium"}>Details</Txt>
      <ContentBox className="p-4 flex flex-col gap-4">
        <div className="w-full flex items-center justify-between">
          <Txt lightGrey>Status</Txt>
          <AlertStatusBadge status={alert.status} />
        </div>

        <div className="w-full flex items-center justify-between">
          <Txt lightGrey>Source</Txt>
          <AlertSourceBadge
            showIcon
            alert={alert}
            className="!text-sm !text-content-primary text-right"
          />
        </div>

        <div className="w-full flex items-center justify-between">
          <Txt lightGrey>Created</Txt>
          <div className={"flex items-center gap-1"}>
            <LocalDateTime timestamp={alert.created_at} />
            <Txt>
              (<LocalRelativeDateTime date={alert.created_at} />)
            </Txt>
          </div>
        </div>

        {priority && (
          <div className="w-full flex items-center justify-between">
            <Txt lightGrey>Priority</Txt>
            <Txt className="hover:underline">
              <OrgAwareLink to={"/alerts/configuration/priorities"}>
                <AlertPriorityBadge priority={priority} />
              </OrgAwareLink>
            </Txt>
          </div>
        )}

        {alert.dashboard_url && (
          <div className="w-full flex items-center justify-between">
            <div className="text-content-secondary">Dashboard</div>
            <Link
              className={"text-right ml-8 max-w-full truncate"}
              href={alert.dashboard_url}
              analyticsTrackingId={"alert-dashboard"}
              openInNewTab
            >
              {alert.dashboard_url}
            </Link>
            )
          </div>
        )}

        {alert.resolved_at && (
          <div className="w-full flex items-center justify-between">
            <Txt lightGrey>Resolved</Txt>
            <LocalRelativeDateTime date={alert.resolved_at} />
          </div>
        )}
      </ContentBox>
    </div>
  );
};

const AlertStatusBadge = ({ status }: { status: AlertStatusEnum }) => {
  switch (status) {
    case AlertStatusEnum.Firing:
      return (
        <Badge
          theme={BadgeTheme.Warning}
          className="!bg-red-surface !text-red-content"
        >
          Triggered
        </Badge>
      );
    case AlertStatusEnum.Resolved:
      return <Badge theme={BadgeTheme.Success}>Resolved</Badge>;
    default:
      return (
        <Badge
          theme={BadgeTheme.Warning}
          className="!bg-red-surface !text-red-content"
        >
          Triggered
        </Badge>
      );
  }
};
