import { Priority } from "@incident-io/api";
import { PopoverSingleSelectV2 } from "@incident-shared/forms/v2/inputs/PopoverSelectV2";
import { StaticMultiSelectV2 } from "@incident-shared/forms/v2/inputs/StaticSelectV2";
import {
  Button,
  ButtonSize,
  ButtonTheme,
  IconEnum,
  Loader,
} from "@incident-ui";
import { PopoverTitleBar } from "@incident-ui/Popover/Popover";
import { SelectOption } from "@incident-ui/Select/types";
import { useForm, useFormContext } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import { useAPI } from "src/utils/swr";

import { usePrioritiesDrawer } from "../../alerts/priorities/PrioritiesCreateEditDrawer";
import {
  EscalationPathConditionType,
  EscalationPathFormData,
} from "../common/types";

export const IfElseConditionPopover = ({ nodeId }: { nodeId: string }) => {
  const formMethods = useFormContext<EscalationPathFormData>();

  const priorityIds = formMethods.watch(
    `nodes.${nodeId}.data.ifElse.priorityIds`,
  );

  const {
    data: { priorities },
  } = useAPI(
    "alertsListPriorities",
    {},
    {
      fallbackData: { priorities: [] },
    },
  );

  const options = [
    {
      value: EscalationPathConditionType.WorkingHoursActive,
      label: "Received within working hours",
      icon: IconEnum.WorkingHours,
    },
    {
      value: EscalationPathConditionType.Priority,
      label: "Priority",
      icon: IconEnum.Priority,
      onSelectRender: ({ onBack, onClose, onChange }) => {
        return (
          <PriorityForm
            onBack={onBack}
            onClose={onClose}
            onSubmit={(newPriorityIds: string[]) => {
              onChange(EscalationPathConditionType.Priority);
              formMethods.setValue<`nodes.${string}.data.ifElse.priorityIds`>(
                `nodes.${nodeId}.data.ifElse.priorityIds`,
                newPriorityIds,
              );
            }}
            defaultValue={priorityIds}
            priorities={priorities}
          />
        );
      },
    },
  ];

  return (
    <PopoverSingleSelectV2
      options={options}
      name={`nodes.${nodeId}.data.ifElse.conditionType`}
      required="Select a condition"
      formMethods={formMethods}
      popoverClassName="min-w-[260px] max-w-[260px]"
      renderTriggerNode={({ onClick, selectedOption }) => {
        return (
          <Button
            onClick={onClick}
            theme={ButtonTheme.Secondary}
            analyticsTrackingId={null}
            title={selectedOption ? selectedOption.label : "Add condition"}
            size={ButtonSize.Small}
            icon={selectedOption ? selectedOption.icon : IconEnum.Add}
          >
            {selectedOption
              ? conditionToLabel(
                  selectedOption.value as EscalationPathConditionType,
                  priorityIds || [],
                  priorities,
                )
              : "Add condition"}
          </Button>
        );
      }}
    />
  );
};

const PriorityForm = ({
  onBack,
  onClose,
  onSubmit,
  defaultValue,
  priorities,
}: {
  onBack: () => void;
  onClose: () => void;
  onSubmit: (ids: string[]) => void;
  defaultValue?: string[];
  priorities: Priority[];
}) => {
  const formMethods = useForm<{ priorityIds: string[] }>({
    defaultValues: { priorityIds: defaultValue },
  });

  const priorityIds = formMethods.watch("priorityIds");

  const priorityOptions = priorities
    .sort((a, b) => {
      return a.rank - b.rank;
    })
    .map((p): SelectOption => {
      return { label: p.name, value: p.id };
    });

  const { setOpen: setShowPrioritiesDrawer } = usePrioritiesDrawer();
  const openDrawer = () => {
    setShowPrioritiesDrawer(true);
    onClose();
  };

  if (priorities.length === 0) {
    return <Loader />;
  }

  return (
    <Form.Root
      formMethods={formMethods}
      onSubmit={(data) => {
        onSubmit(data.priorityIds);
        onClose();
      }}
      id="if-else-priority-condition-form"
    >
      <PopoverTitleBar title="Priority" handleBack={onBack} className="-m-1" />
      <div className="p-3 flex flex-col gap-y-2 !m-0">
        <StaticMultiSelectV2
          formMethods={formMethods}
          name="priorityIds"
          label="Is one of"
          placeholder={"Select priorities"}
          options={priorityOptions}
          required="Select at least one priority"
        />

        <Button
          analyticsTrackingId={"condition-popover--manage-alert-priorities"}
          theme={ButtonTheme.Naked}
          onClick={openDrawer}
        >
          Manage alert priorities
        </Button>

        <Button
          form="if-else-priority-condition-form"
          type="submit"
          theme={ButtonTheme.Primary}
          analyticsTrackingId={null}
          title="Save"
          disabled={!priorityIds?.length}
        >
          Save
        </Button>
      </div>
    </Form.Root>
  );
};

export const conditionToLabel = (
  conditionType: EscalationPathConditionType,
  priorityIds: string[],
  priorities: Priority[],
  reverseCondition?: boolean,
): string => {
  switch (conditionType) {
    case EscalationPathConditionType.Priority:
      if (!priorityIds?.length) {
        return "Priority is one of";
      }

      const priorityLabels = priorityIds.map(
        (id) => priorities.find((p) => p.id === id)?.name,
      );

      if (priorityLabels.length === 1) {
        return `Priority is ${reverseCondition ? " not " : ""}${
          priorityLabels[0]
        }`;
      }

      const lastPriority = priorityLabels.pop();
      const prioritiesList = priorityLabels.join(", ") + " or " + lastPriority;

      return `Priority is ${reverseCondition ? " not " : ""}${prioritiesList}`;
    case EscalationPathConditionType.WorkingHoursActive:
      return reverseCondition
        ? "Outside working hours"
        : "Within working hours";
  }

  return reverseCondition ? "Else" : "Then";
};
