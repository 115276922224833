/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InvestigationStep
 */
export interface InvestigationStep {
    /**
     * When the step was completed.
     * @type {Date}
     * @memberof InvestigationStep
     */
    completed_at?: Date;
    /**
     * Details of the step
     * @type {string}
     * @memberof InvestigationStep
     */
    details: string;
    /**
     * Unique ID of the step
     * @type {string}
     * @memberof InvestigationStep
     */
    id: string;
    /**
     * The type of step
     * @type {string}
     * @memberof InvestigationStep
     */
    step_type: string;
    /**
     * Title of the step
     * @type {string}
     * @memberof InvestigationStep
     */
    title: string;
}

export function InvestigationStepFromJSON(json: any): InvestigationStep {
    return InvestigationStepFromJSONTyped(json, false);
}

export function InvestigationStepFromJSONTyped(json: any, ignoreDiscriminator: boolean): InvestigationStep {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'completed_at': !exists(json, 'completed_at') ? undefined : (new Date(json['completed_at'])),
        'details': json['details'],
        'id': json['id'],
        'step_type': json['step_type'],
        'title': json['title'],
    };
}

export function InvestigationStepToJSON(value?: InvestigationStep | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'completed_at': value.completed_at === undefined ? undefined : (value.completed_at.toISOString()),
        'details': value.details,
        'id': value.id,
        'step_type': value.step_type,
        'title': value.title,
    };
}

