import { IncidentStatus } from "@incident-io/api";
import { Button, ButtonTheme, Icon, IconEnum, IconSize } from "@incident-ui";
import React from "react";
import { tcx } from "src/utils/tailwind-classes";

import { useIncident } from "../hooks";
import { PostIncidentTabSelection } from "./usePostIncidentTaskSelection";

export const EmptyPostIncidentStatus = ({
  status,
  incidentId,
  selection,
  setSelection,
}: {
  incidentId: string;
  status: IncidentStatus;
  selection: PostIncidentTabSelection;
  setSelection: (selection: PostIncidentTabSelection) => void;
}): React.ReactElement => {
  const { incident } = useIncident(incidentId);
  const isSelected =
    selection.type === "placeholder" && selection.statusId === status.id;

  // We're rendering one of several statuses the incident _could_ be in.
  // Here, we check if this status is the current one. If it is, we can
  // enable it and allow the user to click a button to advance to the next status.
  const isTheCurrentIncidentStatus = incident?.incident_status.id === status.id;
  // If the post-incident status is after an earlier post-incident status, it's implicitly completed
  // due to you moving through the flow in order of the statuses
  const isCompleted = status.rank < (incident?.incident_status?.rank ?? 0);

  // Enable the status if it's the current one, or it's completed
  // or if it's selected (which can happen if we race condition to auto-select it as the next one when the incident is updating)
  const isEnabled = isTheCurrentIncidentStatus || isCompleted || isSelected;
  return (
    <div>
      <div className="pl-4 py-2 text-slate-600 tracking-widest text-xs font-medium flex items-center">
        {!isEnabled && (
          <Icon
            id={IconEnum.LockClosed}
            size={IconSize.Small}
            className={"inline mr-0.5"}
          />
        )}{" "}
        {status.name.toUpperCase()}
      </div>
      <div
        className={tcx({
          "font-semibold bg-white": isSelected,
        })}
      >
        <Button
          disabled={!isEnabled}
          theme={ButtonTheme.Ghost}
          className={"w-full"}
          analyticsTrackingId={"incident-status-task-empty-state"}
          onClick={() => {
            setSelection({
              type: "placeholder",
              statusId: status.id,
            });
          }}
        >
          {isCompleted ? (
            <div className={"m-2 mx-4 flex items-center"}>
              <Icon
                id={IconEnum.Checkmark}
                className={
                  "!bg-green-surface !text-green-content inline rounded-full mr-1.5"
                }
              />
              Completed
            </div>
          ) : (
            <div
              className={
                "m-2 mx-3 p-3 border-2 border-dashed border-stroke text-center rounded-[6px] w-full"
              }
            >
              <span className={tcx("text-slate-600 text-sm")}>No tasks</span>
            </div>
          )}
        </Button>
      </div>
    </div>
  );
};
