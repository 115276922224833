import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { SettingsListItemButtonsProps } from "@incident-shared/settings/SettingsList/SettingsListButtons";
import { ButtonTheme, EmptyState, IconEnum, StackedList } from "@incident-ui";
import React from "react";
import { PostmortemTemplate, ScopeNameEnum } from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { usePostmortemName } from "src/utils/postmortem-name";
import { useAPIMutation } from "src/utils/swr";

import { SettingsListItem } from "../../../@shared/settings/SettingsList/SettingsListItem";
import { SettingsSubHeading } from "../../SettingsSubHeading";

export const PostmortemTemplateList = ({
  templates,
}: {
  templates: PostmortemTemplate[];
}): React.ReactElement => {
  const { identity } = useIdentity();

  const maxTemplatesAvailable =
    identity?.feature_gates.postmortem_templates_count ??
    Number.MAX_SAFE_INTEGER;
  const orgCanCreateTemplates = templates.length < maxTemplatesAvailable;

  // If the org only has 1 template left, do not let them delete it.
  const canDelete = templates.length > 1;

  return (
    <div>
      <SettingsSubHeading
        title="Templates"
        titleHeadingLevel={2}
        accessory={
          <AddTemplateButton orgCanCreateTemplates={orgCanCreateTemplates} />
        }
        explanation={
          <>
            Configure sections for your post-mortem. Responders will complete
            these sections when drafting the post-mortem on an incident
            homepage. They can then export the post-mortem to a destination.
          </>
        }
        className="!mb-4"
      />
      {templates.length > 0 ? (
        <StackedList>
          {templates.map((template, index) => (
            <PostmortemTemplateRow
              key={index}
              postMortemTemplate={template}
              canDelete={canDelete}
            />
          ))}
        </StackedList>
      ) : (
        <EmptyState
          icon={IconEnum.Doc}
          title={"You have no templates yet"}
          content="Get started with post-mortems by creating a template."
          cta={
            <AddTemplateButton orgCanCreateTemplates={orgCanCreateTemplates} />
          }
        />
      )}
    </div>
  );
};

const PostmortemTemplateRow = ({
  postMortemTemplate,
  canDelete,
}: {
  postMortemTemplate: PostmortemTemplate;
  canDelete: boolean;
}): React.ReactElement => {
  const { hasScope } = useIdentity();
  const { postmortemNameFormatted } = usePostmortemName(null);

  const { trigger: onDelete } = useAPIMutation(
    "postmortemsListTemplates",
    {},
    async (apiClient, { id }: { id: string }) => {
      await apiClient.postmortemsDestroyTemplate({ id });
    },
  );

  const buttons: SettingsListItemButtonsProps = {
    requiredScope: ScopeNameEnum.OrganisationSettingsUpdate,
    delete: {
      resourceTitle: postMortemTemplate.name,
      deleteConfirmationTitle: `Delete ${postmortemNameFormatted} template`,
      deleteConfirmationContent: (
        <div className="space-y-2">
          <div>
            Are you sure you want to delete the{" "}
            <span className="font-semibold">{postMortemTemplate.name}</span>{" "}
            template?
          </div>
          <div>
            If any post-mortems were written using this template, the
            post-mortem will still be viewable on the incident homepage.
          </div>
        </div>
      ),
      onDelete: () => onDelete({ id: postMortemTemplate.id }),
      disabled: !canDelete,
    },
    edit: {
      editHref: `/settings/post-mortem/templates-v2/${postMortemTemplate.id}/edit`,
    },
    view: {
      viewHref: `/settings/post-mortem/templates-v2/${postMortemTemplate.id}/edit`,
    },
  };

  if (hasScope(ScopeNameEnum.OrganisationSettingsUpdate)) {
    delete buttons.view;
  } else {
    delete buttons.edit;
  }

  return <SettingsListItem title={postMortemTemplate.name} buttons={buttons} />;
};

const AddTemplateButton = ({
  orgCanCreateTemplates,
}: {
  orgCanCreateTemplates: boolean;
}): React.ReactElement => {
  return (
    <GatedButton
      href="/settings/post-mortem/templates-v2/create"
      requiredScope={ScopeNameEnum.OrganisationSettingsUpdate}
      analyticsTrackingId="create-postmortem-template-v2"
      icon={IconEnum.Add}
      theme={ButtonTheme.Secondary}
      upgradeRequired={!orgCanCreateTemplates}
      upgradeRequiredProps={{
        gate: {
          type: "numeric",
          value: 5,
          featureNameSingular: `post-mortem template`,
        },
        featureName: `post-mortem templates`,
      }}
    >
      Add template
    </GatedButton>
  );
};
