/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ExploDashboardPayload,
    ExploDashboardPayloadFromJSON,
    ExploDashboardPayloadFromJSONTyped,
    ExploDashboardPayloadToJSON,
} from './ExploDashboardPayload';
import {
    TextInfo,
    TextInfoFromJSON,
    TextInfoFromJSONTyped,
    TextInfoToJSON,
} from './TextInfo';

/**
 * 
 * @export
 * @interface PanelPayload
 */
export interface PanelPayload {
    /**
     * 
     * @type {ExploDashboardPayload}
     * @memberof PanelPayload
     */
    explo_dashboard?: ExploDashboardPayload;
    /**
     * Unique identifier for this panel in this dashboard (only used for custom dashboards)
     * @type {string}
     * @memberof PanelPayload
     */
    key: string;
    /**
     * Unique computer-readable name of the panel
     * @type {string}
     * @memberof PanelPayload
     */
    name: string;
    /**
     * 
     * @type {TextInfo}
     * @memberof PanelPayload
     */
    text?: TextInfo;
    /**
     * Variables for the panel
     * @type {{ [key: string]: string; }}
     * @memberof PanelPayload
     */
    variables: { [key: string]: string; };
}

export function PanelPayloadFromJSON(json: any): PanelPayload {
    return PanelPayloadFromJSONTyped(json, false);
}

export function PanelPayloadFromJSONTyped(json: any, ignoreDiscriminator: boolean): PanelPayload {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'explo_dashboard': !exists(json, 'explo_dashboard') ? undefined : ExploDashboardPayloadFromJSON(json['explo_dashboard']),
        'key': json['key'],
        'name': json['name'],
        'text': !exists(json, 'text') ? undefined : TextInfoFromJSON(json['text']),
        'variables': json['variables'],
    };
}

export function PanelPayloadToJSON(value?: PanelPayload | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'explo_dashboard': ExploDashboardPayloadToJSON(value.explo_dashboard),
        'key': value.key,
        'name': value.name,
        'text': TextInfoToJSON(value.text),
        'variables': value.variables,
    };
}

