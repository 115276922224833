import { ExpandTimelineItemsButton } from "@incident-shared/timeline/TimelineHeader";
import {
  Button,
  ButtonSize,
  ButtonTheme,
  ContentBox,
  Heading,
  IconEnum,
} from "@incident-ui";
import {
  Drawer,
  DrawerBody,
  DrawerContents,
  DrawerFooter,
  DrawerTitle,
  DrawerTitleTheme,
} from "@incident-ui/Drawer/Drawer";
import { AnimatePresence } from "framer-motion";
import { useState } from "react";
import { Incident, IncidentTimelineItem } from "src/contexts/ClientContext";
import { useAPI } from "src/utils/swr";

import { ActivityLogPanelInEditor, SortOrder } from "./ActivityLog";
import { CreateEditTimelineItemDrawer } from "./CreateEditTimelineItemDrawer";
import { IncidentTimeline } from "./IncidentTimeline";

export const TimelineEditor = ({
  incident,
  onClose,
}: {
  incident: Incident;
  onClose: () => void;
}) => {
  const [timelineMinimized, setTimelineMinimized] = useState<string[]>([]);
  const [timelineCommentsOpen, setCommentsOpen] = useState<string[]>([]);
  const [itemEditing, setItemEditing] = useState<IncidentTimelineItem>();
  const [showAddCustomEventDrawer, setShowAddCustomEventDrawer] =
    useState(false);

  const { data: groupedTimelineItems } = useAPI(
    "incidentTimelineListTimelineItems",
    {
      incidentId: incident.id,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
  );

  const timelineItems = groupedTimelineItems?.timeline_items ?? [];

  return (
    <AnimatePresence>
      <Drawer
        onClose={onClose}
        width={"full"}
        isInBackground={!!itemEditing || showAddCustomEventDrawer}
      >
        <DrawerContents>
          <DrawerTitle
            title="Edit timeline"
            onClose={onClose}
            compact
            sticky
            theme={DrawerTitleTheme.Bordered}
          />
          <DrawerBody className="!p-0 overflow-y-hidden">
            <div className={"flex flex-col lg:flex-row h-full"}>
              <div
                className={
                  "flex flex-col p-6 overflow-y-auto h-full w-full lg:w-[50%]"
                }
              >
                <ActivityLogPanelInEditor
                  incidentId={incident.id}
                  sortOrder={SortOrder.OldestFirst}
                />
              </div>
              <div
                className={
                  "flex flex-col p-6 h-full overflow-y-auto w-full bg-surface-secondary lg:w-[50%] border-l pt-5"
                }
              >
                <div className="grow">
                  <div className="flex-center-y justify-between mb-2">
                    <div className="flex flex-col space-y-1">
                      <Heading level={2} size="small">
                        Timeline preview
                      </Heading>
                    </div>
                    <div className="flex gap-2">
                      <ExpandTimelineItemsButton
                        minimizedItems={timelineMinimized}
                        setMinimizedItems={setTimelineMinimized}
                        timelineItems={timelineItems}
                        accessorKey="timeline_item"
                      />
                      <Button
                        analyticsTrackingId={"add-custom-event"}
                        icon={IconEnum.Add}
                        onClick={() => setShowAddCustomEventDrawer(true)}
                        size={ButtonSize.Small}
                      >
                        Add custom event
                      </Button>
                    </div>
                  </div>
                  <ContentBox className="bg-white p-4 pb-0">
                    <IncidentTimeline
                      incidentId={incident.id}
                      editing
                      minimizedItems={timelineMinimized}
                      setMinimizedItems={setTimelineMinimized}
                      commentBoxOpen={timelineCommentsOpen}
                      setCommentBoxOpen={setCommentsOpen}
                      setEditing={setItemEditing}
                    />
                  </ContentBox>
                  {showAddCustomEventDrawer && (
                    <CreateEditTimelineItemDrawer
                      incident={incident}
                      onClose={() => setShowAddCustomEventDrawer(false)}
                    />
                  )}
                  {itemEditing && (
                    <CreateEditTimelineItemDrawer
                      incident={incident}
                      onClose={() => setItemEditing(undefined)}
                      existingItem={itemEditing}
                    />
                  )}
                </div>
              </div>
            </div>
          </DrawerBody>
          <DrawerFooter className="flex justify-end">
            <Button
              analyticsTrackingId={null}
              onClick={onClose}
              theme={ButtonTheme.Primary}
            >
              Done
            </Button>
          </DrawerFooter>
        </DrawerContents>
      </Drawer>
    </AnimatePresence>
  );
};
