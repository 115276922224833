import {
  Button,
  ButtonTheme,
  Heading,
  IconEnum,
  IconSize,
  LocalDateTime,
} from "@incident-ui";
import React from "react";
import {
  Incident,
  IncidentTimestampWithValue,
} from "src/contexts/ClientContext";

import { incidentInEditableStatus } from "../helpers";
import { IncidentSidebarEntry } from "./IncidentSidebarEntry";

type Props = {
  timestamps: IncidentTimestampWithValue[];
  incident: Incident;
  onEdit: () => void;
};

export function IncidentTimestamps({
  timestamps,
  incident,
  onEdit,
}: Props): React.ReactElement | null {
  // Filter for timestamps that have a value, then sort them
  timestamps = timestamps
    .filter(
      (timestamp) =>
        timestamp.value !== undefined && timestamp.value?.value !== undefined,
    )
    .sort((a, b): number => {
      // @ts-expect-error they are always defined because we've filtered first
      return a.value.value - b.value.value;
    });

  return (
    <div className="pb-1.5">
      <div className="flex gap-1 mb-1 items-center">
        <Heading level={3} size="small">
          Timestamps
        </Heading>
        {incidentInEditableStatus(incident) ? (
          <Button
            analyticsTrackingId="edit-timestamp"
            theme={ButtonTheme.Naked}
            onClick={onEdit}
            icon={IconEnum.Edit}
            iconProps={{ size: IconSize.Medium }}
            title="edit"
          />
        ) : null}
      </div>
      {timestamps.length === 0 ? (
        // As we force you to have a reported timestamp, this should basically never happen
        <div className="text-content-tertiary mt-2">
          There are no timestamps set for this incident.
        </div>
      ) : (
        <div className="space-y-2">
          {timestamps.map((timestamp) => {
            return (
              <IncidentSidebarEntry
                align="baseline"
                key={timestamp.timestamp.id}
                label={timestamp.timestamp.name}
                value={
                  (timestamp.value?.value && (
                    <LocalDateTime
                      className="tabular-nums"
                      timestamp={timestamp.value.value}
                    />
                  )) ||
                  "No value"
                }
              />
            );
          })}
        </div>
      )}
    </div>
  );
}
