import { StatusPage, StatusPageSubPageSlim } from "@incident-io/api";
import { CatalogEntryBadge } from "@incident-shared/attribute";
import {
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  IconEnum,
  Txt,
} from "@incident-ui";
import { useFormContext } from "react-hook-form";

import { ManualSubPageFormData } from "../../common/modals/PublishLiveIncidentModal";
import { useSubPageData } from "../hooks/use-sub-page-data";
import { AffectedComponentsFormData } from "../view/AffectedComponentsEditor";

export const SubPageEditor = ({ page }: { page: StatusPage }) => {
  const formMethods = useFormContext<
    AffectedComponentsFormData & ManualSubPageFormData
  >();
  const [selectedSubPageIDs, componentStatuses] = formMethods.watch([
    "manual_sub_page_ids",
    "component_statuses",
  ]);

  const { selectedSubPages, notSelectedSubPages, catalogType } = useSubPageData(
    page,
    componentStatuses,
    selectedSubPageIDs,
  );

  return (
    <>
      <div className="bg-white shadow rounded-2 divide-y divide-stroke border border-stroke">
        {selectedSubPages.length > 0 ? (
          selectedSubPages.map((page) => (
            <div className="p-2 flex justify-between" key={page.id}>
              <CatalogEntryBadge
                color={catalogType?.color}
                icon={catalogType?.icon}
                label={page.name}
              />
              <Button
                icon={IconEnum.Delete}
                theme={ButtonTheme.Naked}
                analyticsTrackingId={"remove-sub-page"}
                onClick={() => {
                  formMethods.setValue(`manual_sub_page_ids`, [
                    ...selectedSubPageIDs.filter((id) => id !== page.id),
                  ]);
                }}
                title={`Remove ${page.name}`}
              />
            </div>
          ))
        ) : (
          <div className="p-4 text-slate-600 text-sm">
            This incident will not be published on any sub-pages.
          </div>
        )}
      </div>

      <Txt bold className="!mt-4">
        Unaffected sub-pages
      </Txt>
      <div className="bg-white shadow-sm rounded-2 divide-y divide-stroke border border-stroke">
        {notSelectedSubPages.length > 0 ? (
          notSelectedSubPages.map((page) => (
            <div className="p-2 flex justify-between" key={page.id}>
              <CatalogEntryBadge
                color={catalogType?.color}
                icon={catalogType?.icon}
                label={page.name}
              />
              <Button
                icon={IconEnum.Add}
                theme={ButtonTheme.Naked}
                analyticsTrackingId={"add-sub-page"}
                onClick={() => {
                  formMethods.setValue(`manual_sub_page_ids`, [
                    ...selectedSubPageIDs,
                    page.id,
                  ]);
                }}
                title={`Add ${page.name}`}
              >
                Add
              </Button>
            </div>
          ))
        ) : (
          <div className="p-4 text-sm">
            This incident will be published on all sub-pages
          </div>
        )}
      </div>
    </>
  );
};

export const SubPageEditorWarning = ({
  affectedPages,
}: {
  affectedPages: StatusPageSubPageSlim[];
}) => {
  const formMethods = useFormContext<ManualSubPageFormData>();

  return (
    <Callout theme={CalloutTheme.Warning}>
      <div className="flex gap-2 items-center">
        <p>
          You&rsquo;ve chosen to manually manage your affected subpages for this
          incident. You will have to manually add or remove affected sub-pages
          for updates on this incident going forward.
        </p>
        <Button
          analyticsTrackingId={"reset-manual-sub-pages"}
          icon={IconEnum.Undo}
          theme={ButtonTheme.Secondary}
          onClick={() => {
            formMethods.setValue<"manual_sub_pages_enabled">(
              "manual_sub_pages_enabled",
              false,
            );
            formMethods.setValue(
              `manual_sub_page_ids`,
              affectedPages.map((page) => page.id),
            );
          }}
        >
          Reset
        </Button>
      </div>
    </Callout>
  );
};
