import { RainbowContainer } from "@incident-shared/aisuggestions/RainbowContainer";
import {
  TemplatedTextDisplay,
  TemplatedTextDisplayStyle,
} from "@incident-shared/forms/v1/TemplatedText";
import {
  Button,
  ButtonSize,
  ButtonTheme,
  Heading,
  Icon,
  IconEnum,
  IconSize,
} from "@incident-ui";
import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import {
  AssistantAvatar,
  AssistantAvatarBackground,
} from "src/components/insights/assistant/AssistantAvatar";
import { IncidentsGetSummarySuggestionResponseBody } from "src/contexts/ClientContext";
import { tcx } from "src/utils/tailwind-classes";

import styles from "./PendingSummarySuggestion.module.scss";

type Props = {
  suggestionData?: IncidentsGetSummarySuggestionResponseBody;
  onAccept: (suggestionData: IncidentsGetSummarySuggestionResponseBody) => void;
  onReject: (suggestionData: IncidentsGetSummarySuggestionResponseBody) => void;
};

export const PendingSummarySuggestion = ({
  suggestionData,
  onAccept,
  onReject,
}: Props) => {
  const [expanded, setExpanded] = useState(false);

  if (
    !suggestionData ||
    suggestionData.has_suggestion === false ||
    !suggestionData.suggestion
  ) {
    // In all of these cases, there's nothing to render.
    return null;
  }

  // This is very similar to a component in FollowUpSuggestions, should we share it?
  return (
    <RainbowContainer className="border border-purple-100 rounded-lg bg-clip-border overflow-hidden">
      <motion.div
        className="relative"
        initial={{ height: 128 }}
        animate={expanded ? "expanded" : "closed"}
        variants={{
          expanded: { height: "auto" },
          closed: { height: 128 },
        }}
      >
        <div className="flex flex-row flex-start gap-4 p-4">
          <div>
            <AssistantAvatar
              size={IconSize.Small}
              background={AssistantAvatarBackground.Purple}
              rounded={false}
            />
          </div>
          <div className="flex flex-col gap-3">
            <Heading level={3} size="small" className="pt-1.5">
              Suggested summary
            </Heading>
            <TemplatedTextDisplay
              style={TemplatedTextDisplayStyle.Naked}
              value={suggestionData.suggestion.suggestion.text_node}
              className="leading-5"
            />
            <div className="flex gap-3">
              <Button
                analyticsTrackingId="accept-summary-suggestion"
                onClick={() => onAccept(suggestionData)}
                theme={ButtonTheme.Primary}
                size={ButtonSize.Small}
                title="Accept: you can edit the summary before saving"
                icon={IconEnum.Tick}
              >
                Accept
              </Button>
              <Button
                analyticsTrackingId="reject-summary-suggestion"
                onClick={() => onReject(suggestionData)}
                theme={ButtonTheme.Secondary}
                size={ButtonSize.Small}
                title="Skip: we'll remove the suggestion"
                icon={IconEnum.Close}
              >
                Skip
              </Button>
            </div>
          </div>
        </div>
        <AnimatePresence>
          {!expanded && (
            <motion.div
              initial={{ opacity: 1 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <ViewSuggestionToggle onClick={() => setExpanded(true)} />
            </motion.div>
          )}
        </AnimatePresence>
      </motion.div>
    </RainbowContainer>
  );
};

const ViewSuggestionToggle = ({ onClick }: { onClick: () => void }) => {
  return (
    <div
      className={tcx(
        styles.background,
        "absolute bottom-0 w-full h-[86px] flex items-end",
      )}
    >
      <div className="flex p-4 flex-row justify-center items-center gap-1 flex-grow">
        <Button
          analyticsTrackingId="expand-summary-suggestion"
          theme={ButtonTheme.Naked}
          onClick={onClick}
        >
          <div className="text-sm-med">View suggestion</div>
          <Icon id={IconEnum.ChevronDown} />
        </Button>
      </div>
    </div>
  );
};
