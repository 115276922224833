import { Spinner } from "@incident-ui/Spinner/Spinner";
import { Tooltip } from "@incident-ui/Tooltip/Tooltip";
import React, { ForwardedRef, ReactElement } from "react";
import { useAnalytics } from "src/contexts/AnalyticsContext";
import { tcx } from "src/utils/tailwind-classes";

import styles from "./Toggle.module.scss";

export type ToggleProps = {
  /** A unique ID for the html element.*/
  id: string;
  /** What should happen when you toggle the toggle.*/
  onToggle: () => void;
  /** Whether or not the toggle is "switched on" (ie the thing is enabled).*/
  on: boolean;
  /** Whether or not the toggle can be changed*/
  disabled?: boolean;
  /** Whether to show the toggle on the left or right of the label. Default right */
  align?: "left" | "right";
  /** Text explaining the label further */
  description?: React.ReactNode;
  /** Whether description text should be rendered as tooltip */
  descriptionInTooltip?: boolean;
  /** Additional logic to run when toggle is toggled */
  onValueChange?: (toggled: boolean) => void;
  toggleClassName?: string;
  toggleLabelClassName?: string;
  isDisabledTooltipContent?: React.ReactNode;
  isLoading?: boolean;
  analyticsTrackingId?: string;
} & (
  | {
      label: React.ReactElement;
      labelledById?: never;
    }
  | {
      label: React.ReactNode;
      labelledById?: never;
    }
  | {
      labelledById: string;
      label?: never;
    }
);

export const Toggle = React.forwardRef(
  (
    {
      on,
      id,
      onToggle,
      disabled,
      label,
      align = "right",
      description,
      descriptionInTooltip,
      toggleClassName,
      toggleLabelClassName,
      labelledById,
      isDisabledTooltipContent,
      isLoading,
      analyticsTrackingId,
    }: ToggleProps,
    ref: ForwardedRef<HTMLInputElement>,
  ): ReactElement => {
    const analytics = useAnalytics();

    function LabelText() {
      return (
        <span
          className={tcx(
            "text-sm flex text-content-primary font-medium",
            toggleLabelClassName,
            {
              "mr-2.5": align === "right",
              "ml-2.5": align === "left",
            },
            descriptionInTooltip ? "gap-1" : "flex-col",
          )}
        >
          <span>{label}</span>
          {description && descriptionInTooltip ? (
            <Tooltip content={description} />
          ) : (
            <span className="text-content-secondary !font-normal mt-1">
              {description}
            </span>
          )}
        </span>
      );
    }
    const onChange = () => {
      if (analyticsTrackingId && analytics) {
        const action = on ? "disabled" : "enabled";
        analytics.track(`${analyticsTrackingId}.${action}`);
      }
      onToggle();
    };
    return (
      <label
        htmlFor={id}
        className={tcx(
          "flex-center-y",
          disabled ? "" : "cursor-pointer",
          toggleClassName,
        )}
      >
        {align === "right" && label && <LabelText />}
        <Tooltip
          bubbleProps={{ className: disabled ? "" : "hidden" }}
          content={isDisabledTooltipContent}
        >
          {isLoading ? (
            <Spinner />
          ) : (
            <span
              className={tcx(styles.toggle, "border shrink-0", {
                "bg-surface-invert border-slate-900": on,
                "bg-surface-tertiary border-stroke": !on,
                [styles.disabled]: disabled,
              })}
            >
              <input
                type="checkbox"
                id={id}
                ref={ref}
                checked={on}
                onChange={onChange}
                disabled={disabled}
                aria-labelledby={labelledById}
              />
              <span
                className={tcx(styles.slider, "bg-white border", {
                  "border-slate-900": on && !disabled,
                  "border-slate-500": !on || disabled,
                })}
                aria-hidden="true"
              ></span>
            </span>
          )}
        </Tooltip>
        {align === "left" && label && <LabelText />}
      </label>
    );
  },
);
Toggle.displayName = "Toggle";
