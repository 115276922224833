import { Incident, IncidentVisibilityEnum } from "@incident-io/api";
import { FollowUpCreateModal } from "@incident-shared/follow-ups";
import { Button, ButtonSize, IconEnum, LoadingBar } from "@incident-ui";
import { useState } from "react";
import { useQueryParams } from "src/utils/query-params";

import { SectionWrapper } from "../body/SectionWrapper";
import { FollowUpsTabTable } from "../followups/FollowUpsTabTable";
import { useFollowUps, useSuggestedFollowUps } from "../hooks";

type PostmortemFollowupsProps = {
  id: string;
  incident: Incident;
};

export const PostmortemFollowups = ({
  id,
  incident,
}: PostmortemFollowupsProps) => {
  const urlParams = useQueryParams();

  const { followUps, isLoading } = useFollowUps(incident.id);
  const { suggestedFollowUps, isLoading: isLoadingSuggestions } =
    useSuggestedFollowUps(incident.id);

  const [showCreateFollowUpModal, setShowCreateFollowUpModal] = useState(false);

  const editingFollowUpID = urlParams.get("follow_up_id") ?? null;
  const editingFollowUp = followUps.find(
    (followUp) => followUp.id === editingFollowUpID,
  );

  return (
    <SectionWrapper
      sectionName="Follow-ups"
      id={id}
      enableExpansion
      headerAccessory={
        <Button
          icon={IconEnum.Add}
          analyticsTrackingId="new-follow-up"
          onClick={() => setShowCreateFollowUpModal(true)}
          size={ButtonSize.Small}
        >
          Add follow-up
        </Button>
      }
    >
      {/* We wrap this component in a div so that the `gap` in the parent wrapper doesn't get applied. FollowUpsTabTable already has quite a bit of spacing! */}
      <div>
        {isLoading || isLoadingSuggestions || !incident ? (
          <LoadingBar className="mt-4 h-18" />
        ) : (
          <FollowUpsTabTable
            incident={incident}
            editingFollowUp={editingFollowUp}
            followUps={followUps}
            followUpSuggestions={suggestedFollowUps}
            className="mt-0"
          />
        )}
      </div>
      {showCreateFollowUpModal && (
        <FollowUpCreateModal
          incidentId={incident.id}
          onClose={() => setShowCreateFollowUpModal(false)}
          isPrivateIncident={
            incident.visibility === IncidentVisibilityEnum.Private
          }
        />
      )}
    </SectionWrapper>
  );
};
