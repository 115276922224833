import { useAPI } from "src/utils/swr";

import { ChattyProductMarketingBanner } from "./ChattyProductMarketingBanner";

export type CSMChattyProductMarketingBannerProps = {
  className?: string;
  graphic: React.ReactNode;
  cta?: React.ReactNode;
  secondaryCta?: React.ReactNode;
  ctaPosition?: "right" | "bottom";
  copy: React.ReactNode;
};

export function CSMChattyProductMarketingBanner({
  copy,
  className,
  cta,
  secondaryCta,
  graphic,
}: CSMChattyProductMarketingBannerProps): React.ReactElement {
  const { data } = useAPI("settingsSupport", undefined);

  return ChattyProductMarketingBanner({
    copy,
    className,
    cta,
    secondaryCta,
    graphic,
    // If there's an error, or we're still loading, we just pass in no CSM,
    // and the banner will render without a chat bubble.
    csm: data?.csm,
  });
}
