import { Button } from "@incident-ui";
import { StaffShowOrganisationResponseBody } from "src/contexts/ClientContext";

export const PRICING_DASHBOARD_ID = "MTM1RMrs";
export const UsefulLinks = ({
  data,
}: {
  data: StaffShowOrganisationResponseBody;
}) => {
  return (
    <>
      <h2 className="font-medium w-full">Useful links</h2>

      <Section>
        Omni dashboards:
        <div className="gap-2 flex">
          <OmniLink dashboardId="2ccb80cc" data={data}>
            📈 Customer health
          </OmniLink>
          <OmniLink dashboardId={PRICING_DASHBOARD_ID} data={data}>
            💰 On-call pricing
          </OmniLink>
        </div>
      </Section>

      <Section>
        Other tools:
        <div className="gap-2 flex">
          <StripeLink customerId={data.stripe_customer_id} />
          <SalesforceLink orgName={data.organisation_name} />

          <IntercomLink orgId={data.organisation_id} />
        </div>
      </Section>

      <Section>
        Engineering tools:
        <div className="gap-2 flex">
          <LogsLink orgId={data.organisation_id} />
          <SentryLink orgId={data.organisation_id} />
        </div>
      </Section>
    </>
  );
};

const Section = ({ children }: { children: React.ReactNode }) => (
  <div className="flex justify-between p-2 pl-4 text-sm rounded-2 bg-surface-secondary items-center">
    {children}
  </div>
);

export const omniLink = (
  dashboardId: string,
  org: StaffShowOrganisationResponseBody,
) => {
  const queryOrg = {
    kind: "EQUALS",
    type: "string",
    values: [`${org.organisation_name} (${org.organisation_id})`],
    is_negative: false,
  };

  const queryPaying = {
    type: "boolean",
    treat_nulls_as_false: false,
    is_negative: null,
  };

  const encodedQueryOrg = encodeURIComponent(JSON.stringify(queryOrg));
  const encodedQueryPaying = encodeURIComponent(JSON.stringify(queryPaying));

  return `https://incident.omniapp.co/dashboards/${dashboardId}?f--organisations.organisation_name_with_id=${encodedQueryOrg}&f--organisations.is_paying=${encodedQueryPaying}`;
};

const OmniLink = ({
  dashboardId,
  data,
  children,
}: {
  dashboardId: string;
  data: StaffShowOrganisationResponseBody;
  children: React.ReactNode;
}) => {
  return (
    <Button
      analyticsTrackingId={null}
      href={omniLink(dashboardId, data)}
      openInNewTab
    >
      {children}
    </Button>
  );
};

const StripeLink = ({ customerId }: { customerId?: string }) => {
  if (!customerId) {
    return null;
  }

  const href = `https://dashboard.stripe.com/customers/${customerId}`;

  return (
    <Button analyticsTrackingId={null} href={href} openInNewTab>
      💳 Stripe
    </Button>
  );
};

const SalesforceLink = ({ orgName }: { orgName: string }) => {
  const query = {
    componentDef: "forceSearch:searchPageDesktop",
    attributes: {
      term: stripNonAlphaNumeric(orgName),
      scopeMap: {
        type: "TOP_RESULTS",
      },
      context: {
        FILTERS: {},
        searchSource: "ASSISTANT_DIALOG",
        disableIntentQuery: false,
        disableSpellCorrection: false,
        searchDialogSessionId: "d860b4ce-e444-49e3-b104-a78983d6c577",
        debugInfo: {
          appName: "LightningSales",
          appType: "Standard",
          appNamespace: "standard",
          location: "forceSearch:searchPageDesktop",
        },
      },
      groupId: "DEFAULT",
    },
    state: {},
  };

  const href = `https://incident-io.lightning.force.com/one/one.app#${btoa(
    JSON.stringify(query),
  )}`;

  return (
    <Button analyticsTrackingId={null} href={href} openInNewTab>
      🔥 Salesforce
    </Button>
  );
};

const INTERCOM_SEARCH_URL =
  "https://app.intercom.com/a/apps/e2bwytl0/companies/segments/";

const IntercomLink = ({ orgId }: { orgId: string }) => {
  const searchData = {
    predicates: [
      {
        comparison: "eq",
        value: orgId,
        attribute: "company.remote_company_id",
        type: "string",
      },
    ],
  };

  const encodedSearch = btoa(JSON.stringify(searchData));

  const href = `${INTERCOM_SEARCH_URL}all:${encodedSearch}`;

  return (
    <Button analyticsTrackingId={null} href={href} openInNewTab>
      💬 Intercom
    </Button>
  );
};

const LogsLink = ({ orgId }: { orgId: string }) => {
  const href = `https://pineapples.grafana.net/d/fb01107f-ba4a-4144-a8d2-e33a7433f0ae?var-organisation=${orgId}`;

  return (
    <Button analyticsTrackingId={null} href={href} openInNewTab>
      🕵️ Logs
    </Button>
  );
};

const SentryLink = ({ orgId }: { orgId: string }) => {
  const href = `https://incident-io.sentry.io/issues/?query=is%3Aunresolved%20organisation_id%3A${orgId}`;

  return (
    <Button analyticsTrackingId={null} href={href} openInNewTab>
      🐛 Sentry
    </Button>
  );
};

const stripNonAlphaNumeric = (str: string) => str.replace(/\W/g, "");
