/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CopilotInteraction,
    CopilotInteractionFromJSON,
    CopilotInteractionFromJSONTyped,
    CopilotInteractionToJSON,
} from './CopilotInteraction';
import {
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface CopilotRequest
 */
export interface CopilotRequest {
    /**
     * 
     * @type {CopilotInteraction}
     * @memberof CopilotRequest
     */
    copilot_interaction?: CopilotInteraction;
    /**
     * The cost of running this prompt
     * @type {number}
     * @memberof CopilotRequest
     */
    cost_cents: number;
    /**
     * When the request was made.
     * @type {Date}
     * @memberof CopilotRequest
     */
    created_at: Date;
    /**
     * Number of seconds the request took
     * @type {number}
     * @memberof CopilotRequest
     */
    duration_seconds: number;
    /**
     * A copiable eval case
     * @type {string}
     * @memberof CopilotRequest
     */
    eval: string;
    /**
     * ID of the request
     * @type {string}
     * @memberof CopilotRequest
     */
    id: string;
    /**
     * The external ID of the incident this request is related to (if any)
     * @type {string}
     * @memberof CopilotRequest
     */
    incident?: string;
    /**
     * Which prompt is this request for
     * @type {string}
     * @memberof CopilotRequest
     */
    prompt: string;
    /**
     * YAML of the response from the LLM
     * @type {string}
     * @memberof CopilotRequest
     */
    response: string;
    /**
     * Which trace ID is this request for
     * @type {string}
     * @memberof CopilotRequest
     */
    trace: string;
    /**
     * 
     * @type {User}
     * @memberof CopilotRequest
     */
    user?: User;
}

export function CopilotRequestFromJSON(json: any): CopilotRequest {
    return CopilotRequestFromJSONTyped(json, false);
}

export function CopilotRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CopilotRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'copilot_interaction': !exists(json, 'copilot_interaction') ? undefined : CopilotInteractionFromJSON(json['copilot_interaction']),
        'cost_cents': json['cost_cents'],
        'created_at': (new Date(json['created_at'])),
        'duration_seconds': json['duration_seconds'],
        'eval': json['eval'],
        'id': json['id'],
        'incident': !exists(json, 'incident') ? undefined : json['incident'],
        'prompt': json['prompt'],
        'response': json['response'],
        'trace': json['trace'],
        'user': !exists(json, 'user') ? undefined : UserFromJSON(json['user']),
    };
}

export function CopilotRequestToJSON(value?: CopilotRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'copilot_interaction': CopilotInteractionToJSON(value.copilot_interaction),
        'cost_cents': value.cost_cents,
        'created_at': (value.created_at.toISOString()),
        'duration_seconds': value.duration_seconds,
        'eval': value.eval,
        'id': value.id,
        'incident': value.incident,
        'prompt': value.prompt,
        'response': value.response,
        'trace': value.trace,
        'user': UserToJSON(value.user),
    };
}

