/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    EscalationsCreateAutoAckRuleRequestBody,
    EscalationsCreateAutoAckRuleRequestBodyFromJSON,
    EscalationsCreateAutoAckRuleRequestBodyToJSON,
    EscalationsCreateAutoAckRuleResponseBody,
    EscalationsCreateAutoAckRuleResponseBodyFromJSON,
    EscalationsCreateAutoAckRuleResponseBodyToJSON,
    EscalationsCreateExternalRequestBody,
    EscalationsCreateExternalRequestBodyFromJSON,
    EscalationsCreateExternalRequestBodyToJSON,
    EscalationsCreateExternalResponseBody,
    EscalationsCreateExternalResponseBodyFromJSON,
    EscalationsCreateExternalResponseBodyToJSON,
    EscalationsCreateRequestBody,
    EscalationsCreateRequestBodyFromJSON,
    EscalationsCreateRequestBodyToJSON,
    EscalationsCreateResponseBody,
    EscalationsCreateResponseBodyFromJSON,
    EscalationsCreateResponseBodyToJSON,
    EscalationsListActiveResponseBody,
    EscalationsListActiveResponseBodyFromJSON,
    EscalationsListActiveResponseBodyToJSON,
    EscalationsListFilterFieldsResponseBody,
    EscalationsListFilterFieldsResponseBodyFromJSON,
    EscalationsListFilterFieldsResponseBodyToJSON,
    EscalationsListProvidersResponseBody,
    EscalationsListProvidersResponseBodyFromJSON,
    EscalationsListProvidersResponseBodyToJSON,
    EscalationsListResponseBody,
    EscalationsListResponseBodyFromJSON,
    EscalationsListResponseBodyToJSON,
    EscalationsPreviewRequestBody,
    EscalationsPreviewRequestBodyFromJSON,
    EscalationsPreviewRequestBodyToJSON,
    EscalationsPreviewResponseBody,
    EscalationsPreviewResponseBodyFromJSON,
    EscalationsPreviewResponseBodyToJSON,
    EscalationsRespondEscalationRequestBody,
    EscalationsRespondEscalationRequestBodyFromJSON,
    EscalationsRespondEscalationRequestBodyToJSON,
    EscalationsRespondEscalationResponseBody,
    EscalationsRespondEscalationResponseBodyFromJSON,
    EscalationsRespondEscalationResponseBodyToJSON,
    EscalationsRespondEscalationsAllRequestBody,
    EscalationsRespondEscalationsAllRequestBodyFromJSON,
    EscalationsRespondEscalationsAllRequestBodyToJSON,
    EscalationsRespondEscalationsAllResponseBody,
    EscalationsRespondEscalationsAllResponseBodyFromJSON,
    EscalationsRespondEscalationsAllResponseBodyToJSON,
    EscalationsRespondNotificationRequestBody,
    EscalationsRespondNotificationRequestBodyFromJSON,
    EscalationsRespondNotificationRequestBodyToJSON,
    EscalationsRespondNotificationResponseBody,
    EscalationsRespondNotificationResponseBodyFromJSON,
    EscalationsRespondNotificationResponseBodyToJSON,
    EscalationsShowNotificationResponseBody,
    EscalationsShowNotificationResponseBodyFromJSON,
    EscalationsShowNotificationResponseBodyToJSON,
    EscalationsShowResponseBody,
    EscalationsShowResponseBodyFromJSON,
    EscalationsShowResponseBodyToJSON,
} from '../models';

export interface EscalationsCreateRequest {
    createRequestBody: EscalationsCreateRequestBody;
}

export interface EscalationsCreateAutoAckRuleRequest {
    createAutoAckRuleRequestBody: EscalationsCreateAutoAckRuleRequestBody;
}

export interface EscalationsCreateExternalRequest {
    createExternalRequestBody: EscalationsCreateExternalRequestBody;
}

export interface EscalationsListRequest {
    pageSize?: number;
    after?: string;
    fullTextSearch?: any;
    escalationPath?: any;
    priority?: any;
    user?: any;
    alert?: any;
    status?: any;
    id?: any;
    incident?: any;
}

export interface EscalationsListActiveRequest {
    userId: string;
}

export interface EscalationsPreviewRequest {
    previewRequestBody: EscalationsPreviewRequestBody;
}

export interface EscalationsRespondEscalationRequest {
    escalationId: string;
    respondEscalationRequestBody: EscalationsRespondEscalationRequestBody;
}

export interface EscalationsRespondEscalationsAllRequest {
    id: string;
    respondEscalationsAllRequestBody: EscalationsRespondEscalationsAllRequestBody;
}

export interface EscalationsRespondNotificationRequest {
    notificationId: string;
    respondNotificationRequestBody: EscalationsRespondNotificationRequestBody;
}

export interface EscalationsShowRequest {
    escalationId: string;
}

export interface EscalationsShowNotificationRequest {
    notificationId: string;
}

/**
 * 
 */
export class EscalationsApi extends runtime.BaseAPI {

    /**
     * Create an escalation.
     * Create Escalations
     */
    async escalationsCreateRaw(requestParameters: EscalationsCreateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<EscalationsCreateResponseBody>> {
        if (requestParameters.createRequestBody === null || requestParameters.createRequestBody === undefined) {
            throw new runtime.RequiredError('createRequestBody','Required parameter requestParameters.createRequestBody was null or undefined when calling escalationsCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/escalations`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EscalationsCreateRequestBodyToJSON(requestParameters.createRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EscalationsCreateResponseBodyFromJSON(jsonValue));
    }

    /**
     * Create an escalation.
     * Create Escalations
     */
    async escalationsCreate(requestParameters: EscalationsCreateRequest, initOverrides?: RequestInit): Promise<EscalationsCreateResponseBody> {
        const response = await this.escalationsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create an escalation auto-acknowledge rule for a set period of time.
     * CreateAutoAckRule Escalations
     */
    async escalationsCreateAutoAckRuleRaw(requestParameters: EscalationsCreateAutoAckRuleRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<EscalationsCreateAutoAckRuleResponseBody>> {
        if (requestParameters.createAutoAckRuleRequestBody === null || requestParameters.createAutoAckRuleRequestBody === undefined) {
            throw new runtime.RequiredError('createAutoAckRuleRequestBody','Required parameter requestParameters.createAutoAckRuleRequestBody was null or undefined when calling escalationsCreateAutoAckRule.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/escalations/auto_ack_rules`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EscalationsCreateAutoAckRuleRequestBodyToJSON(requestParameters.createAutoAckRuleRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EscalationsCreateAutoAckRuleResponseBodyFromJSON(jsonValue));
    }

    /**
     * Create an escalation auto-acknowledge rule for a set period of time.
     * CreateAutoAckRule Escalations
     */
    async escalationsCreateAutoAckRule(requestParameters: EscalationsCreateAutoAckRuleRequest, initOverrides?: RequestInit): Promise<EscalationsCreateAutoAckRuleResponseBody> {
        const response = await this.escalationsCreateAutoAckRuleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create an escalation using an external escalator.
     * CreateExternal Escalations
     */
    async escalationsCreateExternalRaw(requestParameters: EscalationsCreateExternalRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<EscalationsCreateExternalResponseBody>> {
        if (requestParameters.createExternalRequestBody === null || requestParameters.createExternalRequestBody === undefined) {
            throw new runtime.RequiredError('createExternalRequestBody','Required parameter requestParameters.createExternalRequestBody was null or undefined when calling escalationsCreateExternal.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/external_escalations`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EscalationsCreateExternalRequestBodyToJSON(requestParameters.createExternalRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EscalationsCreateExternalResponseBodyFromJSON(jsonValue));
    }

    /**
     * Create an escalation using an external escalator.
     * CreateExternal Escalations
     */
    async escalationsCreateExternal(requestParameters: EscalationsCreateExternalRequest, initOverrides?: RequestInit): Promise<EscalationsCreateExternalResponseBody> {
        const response = await this.escalationsCreateExternalRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List escalations, optionally filtered by parameters.
     * List Escalations
     */
    async escalationsListRaw(requestParameters: EscalationsListRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<EscalationsListResponseBody>> {
        const queryParameters: any = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        if (requestParameters.after !== undefined) {
            queryParameters['after'] = requestParameters.after;
        }

        if (requestParameters.fullTextSearch !== undefined) {
            queryParameters['full_text_search'] = requestParameters.fullTextSearch;
        }

        if (requestParameters.escalationPath !== undefined) {
            queryParameters['escalation_path'] = requestParameters.escalationPath;
        }

        if (requestParameters.priority !== undefined) {
            queryParameters['priority'] = requestParameters.priority;
        }

        if (requestParameters.user !== undefined) {
            queryParameters['user'] = requestParameters.user;
        }

        if (requestParameters.alert !== undefined) {
            queryParameters['alert'] = requestParameters.alert;
        }

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.incident !== undefined) {
            queryParameters['incident'] = requestParameters.incident;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/escalations`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EscalationsListResponseBodyFromJSON(jsonValue));
    }

    /**
     * List escalations, optionally filtered by parameters.
     * List Escalations
     */
    async escalationsList(requestParameters: EscalationsListRequest, initOverrides?: RequestInit): Promise<EscalationsListResponseBody> {
        const response = await this.escalationsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List escalations to a user that they\'ve yet to ack. This includes escalations that other users might have already acked, but this user has not.
     * ListActive Escalations
     */
    async escalationsListActiveRaw(requestParameters: EscalationsListActiveRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<EscalationsListActiveResponseBody>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling escalationsListActive.');
        }

        const queryParameters: any = {};

        if (requestParameters.userId !== undefined) {
            queryParameters['user_id'] = requestParameters.userId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/active-escalations`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EscalationsListActiveResponseBodyFromJSON(jsonValue));
    }

    /**
     * List escalations to a user that they\'ve yet to ack. This includes escalations that other users might have already acked, but this user has not.
     * ListActive Escalations
     */
    async escalationsListActive(requestParameters: EscalationsListActiveRequest, initOverrides?: RequestInit): Promise<EscalationsListActiveResponseBody> {
        const response = await this.escalationsListActiveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List escalation filter fields
     * ListFilterFields Escalations
     */
    async escalationsListFilterFieldsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<EscalationsListFilterFieldsResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/escalation_filter_fields`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EscalationsListFilterFieldsResponseBodyFromJSON(jsonValue));
    }

    /**
     * List escalation filter fields
     * ListFilterFields Escalations
     */
    async escalationsListFilterFields(initOverrides?: RequestInit): Promise<EscalationsListFilterFieldsResponseBody> {
        const response = await this.escalationsListFilterFieldsRaw(initOverrides);
        return await response.value();
    }

    /**
     * List all available escalation providers
     * ListProviders Escalations
     */
    async escalationsListProvidersRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<EscalationsListProvidersResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/escalation_providers`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EscalationsListProvidersResponseBodyFromJSON(jsonValue));
    }

    /**
     * List all available escalation providers
     * ListProviders Escalations
     */
    async escalationsListProviders(initOverrides?: RequestInit): Promise<EscalationsListProvidersResponseBody> {
        const response = await this.escalationsListProvidersRaw(initOverrides);
        return await response.value();
    }

    /**
     * Preview an escalation, to see who will be notified
     * Preview Escalations
     */
    async escalationsPreviewRaw(requestParameters: EscalationsPreviewRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<EscalationsPreviewResponseBody>> {
        if (requestParameters.previewRequestBody === null || requestParameters.previewRequestBody === undefined) {
            throw new runtime.RequiredError('previewRequestBody','Required parameter requestParameters.previewRequestBody was null or undefined when calling escalationsPreview.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/escalations/preview`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EscalationsPreviewRequestBodyToJSON(requestParameters.previewRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EscalationsPreviewResponseBodyFromJSON(jsonValue));
    }

    /**
     * Preview an escalation, to see who will be notified
     * Preview Escalations
     */
    async escalationsPreview(requestParameters: EscalationsPreviewRequest, initOverrides?: RequestInit): Promise<EscalationsPreviewResponseBody> {
        const response = await this.escalationsPreviewRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Respond to an escalation notification.
     * RespondEscalation Escalations
     */
    async escalationsRespondEscalationRaw(requestParameters: EscalationsRespondEscalationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<EscalationsRespondEscalationResponseBody>> {
        if (requestParameters.escalationId === null || requestParameters.escalationId === undefined) {
            throw new runtime.RequiredError('escalationId','Required parameter requestParameters.escalationId was null or undefined when calling escalationsRespondEscalation.');
        }

        if (requestParameters.respondEscalationRequestBody === null || requestParameters.respondEscalationRequestBody === undefined) {
            throw new runtime.RequiredError('respondEscalationRequestBody','Required parameter requestParameters.respondEscalationRequestBody was null or undefined when calling escalationsRespondEscalation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/escalations/{escalation_id}/actions/respond`.replace(`{${"escalation_id"}}`, encodeURIComponent(String(requestParameters.escalationId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EscalationsRespondEscalationRequestBodyToJSON(requestParameters.respondEscalationRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EscalationsRespondEscalationResponseBodyFromJSON(jsonValue));
    }

    /**
     * Respond to an escalation notification.
     * RespondEscalation Escalations
     */
    async escalationsRespondEscalation(requestParameters: EscalationsRespondEscalationRequest, initOverrides?: RequestInit): Promise<EscalationsRespondEscalationResponseBody> {
        const response = await this.escalationsRespondEscalationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Respond to all escalations for this path that are currently assigned to a given user.
     * RespondEscalationsAll Escalations
     */
    async escalationsRespondEscalationsAllRaw(requestParameters: EscalationsRespondEscalationsAllRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<EscalationsRespondEscalationsAllResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling escalationsRespondEscalationsAll.');
        }

        if (requestParameters.respondEscalationsAllRequestBody === null || requestParameters.respondEscalationsAllRequestBody === undefined) {
            throw new runtime.RequiredError('respondEscalationsAllRequestBody','Required parameter requestParameters.respondEscalationsAllRequestBody was null or undefined when calling escalationsRespondEscalationsAll.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/escalation_paths/{id}/actions/respond`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EscalationsRespondEscalationsAllRequestBodyToJSON(requestParameters.respondEscalationsAllRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EscalationsRespondEscalationsAllResponseBodyFromJSON(jsonValue));
    }

    /**
     * Respond to all escalations for this path that are currently assigned to a given user.
     * RespondEscalationsAll Escalations
     */
    async escalationsRespondEscalationsAll(requestParameters: EscalationsRespondEscalationsAllRequest, initOverrides?: RequestInit): Promise<EscalationsRespondEscalationsAllResponseBody> {
        const response = await this.escalationsRespondEscalationsAllRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Respond to an escalation notification.
     * RespondNotification Escalations
     */
    async escalationsRespondNotificationRaw(requestParameters: EscalationsRespondNotificationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<EscalationsRespondNotificationResponseBody>> {
        if (requestParameters.notificationId === null || requestParameters.notificationId === undefined) {
            throw new runtime.RequiredError('notificationId','Required parameter requestParameters.notificationId was null or undefined when calling escalationsRespondNotification.');
        }

        if (requestParameters.respondNotificationRequestBody === null || requestParameters.respondNotificationRequestBody === undefined) {
            throw new runtime.RequiredError('respondNotificationRequestBody','Required parameter requestParameters.respondNotificationRequestBody was null or undefined when calling escalationsRespondNotification.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/escalation_notifications/{notification_id}/actions/respond`.replace(`{${"notification_id"}}`, encodeURIComponent(String(requestParameters.notificationId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EscalationsRespondNotificationRequestBodyToJSON(requestParameters.respondNotificationRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EscalationsRespondNotificationResponseBodyFromJSON(jsonValue));
    }

    /**
     * Respond to an escalation notification.
     * RespondNotification Escalations
     */
    async escalationsRespondNotification(requestParameters: EscalationsRespondNotificationRequest, initOverrides?: RequestInit): Promise<EscalationsRespondNotificationResponseBody> {
        const response = await this.escalationsRespondNotificationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get an escalation by ID.
     * Show Escalations
     */
    async escalationsShowRaw(requestParameters: EscalationsShowRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<EscalationsShowResponseBody>> {
        if (requestParameters.escalationId === null || requestParameters.escalationId === undefined) {
            throw new runtime.RequiredError('escalationId','Required parameter requestParameters.escalationId was null or undefined when calling escalationsShow.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/escalations/{escalation_id}`.replace(`{${"escalation_id"}}`, encodeURIComponent(String(requestParameters.escalationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EscalationsShowResponseBodyFromJSON(jsonValue));
    }

    /**
     * Get an escalation by ID.
     * Show Escalations
     */
    async escalationsShow(requestParameters: EscalationsShowRequest, initOverrides?: RequestInit): Promise<EscalationsShowResponseBody> {
        const response = await this.escalationsShowRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all data required to power the ack flow in response to an escalation notification.
     * ShowNotification Escalations
     */
    async escalationsShowNotificationRaw(requestParameters: EscalationsShowNotificationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<EscalationsShowNotificationResponseBody>> {
        if (requestParameters.notificationId === null || requestParameters.notificationId === undefined) {
            throw new runtime.RequiredError('notificationId','Required parameter requestParameters.notificationId was null or undefined when calling escalationsShowNotification.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/escalation_notifications/{notification_id}`.replace(`{${"notification_id"}}`, encodeURIComponent(String(requestParameters.notificationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EscalationsShowNotificationResponseBodyFromJSON(jsonValue));
    }

    /**
     * Get all data required to power the ack flow in response to an escalation notification.
     * ShowNotification Escalations
     */
    async escalationsShowNotification(requestParameters: EscalationsShowNotificationRequest, initOverrides?: RequestInit): Promise<EscalationsShowNotificationResponseBody> {
        const response = await this.escalationsShowNotificationRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
