import {
  Incident,
  IncidentModeEnum,
  IncidentStatusCategoryEnum,
} from "@incident-io/api";
import { Product } from "@incident-shared/billing";
import { TimelineTimezoneContext } from "@incident-shared/timeline/TimelineHeader";
import {
  Button,
  ButtonSize,
  DropdownMenu,
  DropdownMenuItem,
  IconEnum,
} from "@incident-ui";
import { useState } from "react";
import { IncidentHeaderModal } from "src/routes/legacy/IncidentRoute";
import { useNavigateToModal } from "src/utils/query-params";

import { IncidentActions } from "../actions/IncidentActions";
import { ProductAccessCondtionalComponent } from "../sidebar/IncidentSidebar";
import { ActivityLogMode, IncidentActivity } from "./IncidentActivity";
import { BodyTabs } from "./IncidentBody";
import { SectionWrapper } from "./SectionWrapper";
import { Summary } from "./Summary";

type OverviewProps = {
  incident: Incident | null;
  setShowCreateActionModal: (open: boolean) => void;
  onTabChange: (tab: BodyTabs) => void;
};

export const Overview = ({
  incident,
  setShowCreateActionModal,
  onTabChange,
}: OverviewProps) => {
  const navigateToModal = useNavigateToModal();
  const [activityLogMode, setActivityLogMode] =
    useState<ActivityLogMode>("highlights");

  if (!incident) {
    return null;
  }

  const isClosed = isIncidentClosed(incident);

  return (
    <div className="mt-4 flex flex-col gap-4">
      <Summary id="" incident={incident} />
      <ProductAccessCondtionalComponent requiredProduct={Product.Response}>
        <SectionWrapper
          sectionName="Actions"
          headerAccessory={
            !isClosed ? (
              <Button
                size={ButtonSize.Small}
                icon={IconEnum.Add}
                analyticsTrackingId={"add-action"}
                onClick={() => setShowCreateActionModal(true)}
              >
                Add action
              </Button>
            ) : undefined
          }
        >
          <IncidentActions
            incidentId={incident.id}
            isClosed={isClosed}
            onTabChange={onTabChange}
          />
        </SectionWrapper>
      </ProductAccessCondtionalComponent>
      <SectionWrapper
        sectionName="Activity"
        headerAccessory={
          <div className="flex gap-2 items-center">
            <TimelineTimezoneContext />
            <DropdownMenu
              triggerButton={
                <Button
                  size={ButtonSize.Small}
                  analyticsTrackingId={"activity-log-mode"}
                  icon={IconEnum.ChevronDown}
                  iconPosition="right"
                >
                  {activityLogMode === "highlights"
                    ? "Highlights"
                    : "All activity"}
                </Button>
              }
            >
              <DropdownMenuItem
                analyticsTrackingId={"activity-log-mode-highlights"}
                label="Highlights"
                onSelect={() => setActivityLogMode("highlights")}
              />
              <DropdownMenuItem
                analyticsTrackingId={"activity-log-mode-all"}
                label="All activity"
                onSelect={() => setActivityLogMode("all")}
              />
            </DropdownMenu>
            <Button
              size={ButtonSize.Small}
              icon={IconEnum.Add}
              analyticsTrackingId={"share-update"}
              onClick={() =>
                navigateToModal(IncidentHeaderModal.UpdateIncident)
              }
            >
              Share update
            </Button>
          </div>
        }
      >
        <IncidentActivity
          incidentId={incident.id}
          mode={activityLogMode}
          isClosed={isClosed}
        />
      </SectionWrapper>
    </div>
  );
};

const isIncidentClosed = (incident: Incident) => {
  return (
    [
      IncidentStatusCategoryEnum.Declined,
      IncidentStatusCategoryEnum.Merged,
      IncidentStatusCategoryEnum.Closed,
      IncidentStatusCategoryEnum.Canceled,
    ].includes(incident.incident_status.category) ||
    incident.mode === IncidentModeEnum.Retrospective
  );
};
