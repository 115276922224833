import { Button, ButtonSize, ButtonTheme } from "@incident-ui/Button/Button";
import { Icon, IconEnum, IconSize } from "@incident-ui/Icon/Icon";
import { PopoverBody, PopoverProps } from "@incident-ui/Popover/Popover";
import { Popover } from "@incident-ui/Popover/Popover";
import { PopoverSearch } from "@incident-ui/Popover/PopoverSearch";
import React from "react";
import { tcx } from "src/utils/tailwind-classes";

import { PopoverSelectBaseProps } from "./types";

export const PopoverSelectWrapper = ({
  align,
  icon,
  triggerClassName,
  popoverClassName,
  search,
  setSearch,
  selectedValue,
  isClearable,
  isSearchable,
  isValueSelected,
  onClear,
  isLoading,
  disabled,
  triggerNode,
  triggerStyle,
  setIsOpen,
  fullWidth = false,
  children,
  ...rest
}: Pick<
  PopoverSelectBaseProps,
  | "align"
  | "disabled"
  | "fullWidth"
  | "icon"
  | "isClearable"
  | "isSearchable"
  | "popoverClassName"
  | "triggerClassName"
  | "triggerStyle"
> & {
  search: string;
  setSearch: (str: string) => void;
  selectedValue: React.ReactNode;
  isValueSelected: boolean;
  onClear: () => void;
  isLoading?: boolean;
  triggerNode?: React.ReactElement;
  setIsOpen: (isOpen: boolean) => void;
  children: React.ReactNode;
} & PopoverProps) => {
  return (
    <Popover
      {...rest}
      align={align}
      className={tcx(
        // Fix the width of the popover
        "min-w-[230px] max-w-[230px]",
        popoverClassName,
      )}
      onOpenChange={(open) => {
        if (!open) {
          setSearch("");
        }
      }}
      disabled={disabled}
      trigger={
        triggerNode ? (
          triggerNode
        ) : (
          <SelectTriggerButton
            disabled={disabled}
            fullWidth={fullWidth}
            isClearable={isClearable}
            isLoading={isLoading}
            isValueSelected={isValueSelected}
            onClear={onClear}
            triggerClassName={triggerClassName}
            triggerStyle={triggerStyle}
            onClick={() => setIsOpen(true)}
          >
            {icon && (
              <Icon
                id={icon}
                size={IconSize.Small}
                className="text-content-primary"
              />
            )}
            {selectedValue}
          </SelectTriggerButton>
        )
      }
    >
      {isSearchable && <PopoverSearch value={search} onChange={setSearch} />}
      <PopoverBody scroll>{children}</PopoverBody>
    </Popover>
  );
};

type SelectTriggerButtonProps = Pick<
  PopoverSelectBaseProps,
  | "triggerStyle"
  | "fullWidth"
  | "disabled"
  | "triggerClassName"
  | "isClearable"
  | "isLoading"
> & {
  isValueSelected: boolean;
  onClear: () => void;
  onClick: () => void;
  children: React.ReactNode;
};

const SelectTriggerButton = React.forwardRef<
  HTMLButtonElement,
  SelectTriggerButtonProps
>(
  (
    {
      disabled,
      fullWidth,
      isClearable,
      isLoading,
      isValueSelected,
      onClear,
      triggerClassName,
      triggerStyle = "select",
      children,
      ...rest
    }: SelectTriggerButtonProps,
    ref: React.ForwardedRef<HTMLButtonElement>,
  ) => {
    if (triggerStyle === "select") {
      return (
        <button
          {...rest}
          ref={ref}
          className={tcx(
            "min-h-10",
            "rounded-2 border bg-surface-primary border-border hover:border-border-hover shadow-sm",
            "transition-all group",
            "px-3 py-2 flex items-center gap-2 text-content-secondary",
            fullWidth ? "w-full" : "w-max",
            disabled ? "bg-slate-50 text-content-tertiary" : "cursor-pointer",
            triggerClassName,
          )}
          disabled={disabled}
        >
          {children}
          <div className="flex-center-y gap-1 ml-auto">
            {isClearable && isValueSelected && (
              <Icon
                id={IconEnum.CloseCircle}
                className={tcx(
                  "text-content-tertiary transition-colors",
                  !disabled && "text-content-secondary",
                )}
                size={IconSize.Small}
                onClick={(e) => {
                  e.stopPropagation();
                  onClear();
                }}
              />
            )}
            {isLoading && (
              <Icon
                id={IconEnum.Loader}
                className="text-content-tertiary animate-spin"
              />
            )}
            <Icon
              id={IconEnum.ChevronDown}
              className={tcx(
                "text-content-tertiary transition-colors",
                !disabled && "group-hover:text-content-secondary",
              )}
            />
          </div>
        </button>
      );
    }

    if (triggerStyle === "small-select") {
      return (
        <Button
          {...rest}
          ref={ref}
          size={ButtonSize.Small}
          theme={ButtonTheme.Secondary}
          analyticsTrackingId={null}
          disabled={disabled}
        >
          <div className="flex items-center gap-1">
            {children}
            {!disabled && (
              <Icon
                id={IconEnum.Expand}
                className="!text-slate-600 !fill-slate-600"
              />
            )}
          </div>
        </Button>
      );
    }

    return (
      <Button
        {...rest}
        ref={ref}
        size={ButtonSize.Small}
        analyticsTrackingId={null}
        className={tcx(
          "!p-0.5 !font-normal rounded-[6px] border !border-stroke !bg-surface-secondary !text-content-primary",
          {
            "cursor-not-allowed": disabled,
            "!px-2": disabled,
            "!pl-2": !disabled,
          },
        )}
        disabled={disabled}
      >
        <div className="flex items-center gap-1">
          {children}
          {!disabled && (
            <Icon
              id={IconEnum.Expand}
              className="!text-slate-600 !fill-slate-600"
            />
          )}
        </div>
      </Button>
    );
  },
);

SelectTriggerButton.displayName = "SelectTriggerButton";
