import { CallProvidersProvidersEnum } from "@incident-io/api";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import { Button, ButtonTheme } from "@incident-ui";
import { ProductMarketingBanner } from "@incident-ui/ProductMarketingBanner/ProductMarketingBanner";

import bannerGraphic from "./banner-graphic.svg";
import { getProviderNameAndLogo } from "./helpers";
import { useShowRicherCallsBanner } from "./useShowBanner";

export const ReinstallGoogleMeetBanner = () => {
  const navigate = useOrgAwareNavigate();
  const { dismissRicherCallsBanner } = useShowRicherCallsBanner();

  const provider = CallProvidersProvidersEnum.GoogleMeet;
  const { name } = getProviderNameAndLogo(provider);

  return (
    <ProductMarketingBanner
      title={`Introducing our new ${name} integration`}
      subtitle={
        <p>
          Reconnect your integration to see who&apos;s active on the call from
          the incident channel, dashboard and timeline.
        </p>
      }
      className="bg-blue-surface"
      cta={
        <Button
          analyticsTrackingId="reconnect-call-provider"
          analyticsTrackingMetadata={{
            provider: provider,
          }}
          theme={ButtonTheme.Primary}
          onClick={() => navigate(`/settings/integrations/${provider}`)}
        >
          Reconnect {name}
        </Button>
      }
      secondaryCta={
        <Button
          analyticsTrackingId="dismiss-richer-calls-banner"
          theme={ButtonTheme.Naked}
          onClick={dismissRicherCallsBanner}
        >
          Dismiss
        </Button>
      }
      graphic={<img src={bannerGraphic} className="my-6" />}
    />
  );
};
