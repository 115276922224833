import {
  Alert,
  AlertSchema,
  AlertSourceConfig,
  AlertStatusEnum,
  CatalogResource,
  EscalationPreview,
  Priority,
} from "@incident-io/api";
import { AlertFiringIndicator } from "@incident-shared/alerts/AlertFiringIndicator";
import {
  TemplatedTextDisplay,
  TemplatedTextDisplayStyle,
} from "@incident-shared/forms/v1/TemplatedText";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { PageWidth, PageWrapper } from "@incident-shared/layout/PageWrapper";
import {
  Button,
  ButtonTheme,
  ContentBox,
  GenericErrorMessage,
  Icon,
  IconEnum,
  IconSize,
  ToastTheme,
  Txt,
} from "@incident-ui";
import { FullPageLoader } from "@incident-ui/Loader/Loader";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { captureException } from "@sentry/core";
import { AnimatePresence } from "framer-motion";
import { useState } from "react";
import { useParams } from "react-router";
import { useAlertSource } from "src/components/alerts/useAlertSource";
import { useAPI, useAPIMutation } from "src/utils/swr";

import { useClipboard } from "../../../utils/useClipboard";
import {
  AlertDebugDrawer,
  AlertEvaluationFailuresCallout,
} from "./AlertDebugDrawer";
import { AlertTimeline } from "./AlertTimeline";
import { AttributesSection } from "./AttributesSection";
import { DetailsSection } from "./DetailsSection";
import { EscalationsSection } from "./EscalationsSection";
import { IncidentAlertsSection } from "./IncidentAlertsSection";

export const AlertDetailsPage = () => {
  const { id: alertId } = useParams() as {
    id: string;
  };

  const {
    data: alertResp,
    isLoading: alertIsLoading,
    error: alertError,
  } = useAPI("alertsShowAlert", {
    id: alertId,
  });

  const {
    data: schemaResponse,
    isLoading: schemaIsLoading,
    error: schemaError,
  } = useAPI("alertsShowSchema", undefined);

  const {
    data: escalationsListResponse,
    isLoading: escalationsListLoading,
    error: escalationsListError,
  } = useAPI("escalationsList", {
    alert: {
      one_of: [alertId],
    },
  });

  const {
    data: resourcesListResp,
    isLoading: resourcesListIsLoading,
    error: resourcesListErr,
  } = useAPI("catalogListResources", undefined, {
    fallbackData: { resources: [] },
  });

  const {
    data: prioritiesResp,
    isLoading: prioritiesLoading,
    error: prioritiesError,
  } = useAPI(
    "alertsListPriorities",
    {},
    {
      fallbackData: { priorities: [] },
    },
  );

  const err =
    alertError ||
    schemaError ||
    resourcesListErr ||
    prioritiesError ||
    escalationsListError;
  if (err) {
    captureException(err);
    return <GenericErrorMessage error={err} />;
  }

  if (
    alertIsLoading ||
    !alertResp ||
    schemaIsLoading ||
    !schemaResponse ||
    resourcesListIsLoading ||
    !escalationsListResponse ||
    escalationsListLoading ||
    !prioritiesResp ||
    prioritiesLoading ||
    !resourcesListResp
  ) {
    return <FullPageLoader />;
  }

  return (
    <AlertDetailsInner
      alert={alertResp.alert}
      alertPriorities={prioritiesResp.priorities}
      resources={resourcesListResp.resources}
      escalations={escalationsListResponse.escalations || []}
      alertSchema={schemaResponse.alert_schema}
    />
  );
};

const AlertDetailsInner = ({
  alert,
  alertSchema,
  alertPriorities,
  escalations,
  resources,
}: {
  alert: Alert;
  alertSchema: AlertSchema;
  alertPriorities: Priority[];
  escalations: EscalationPreview[];
  resources: CatalogResource[];
}) => {
  const { copyTextToClipboard, hasCopied } = useClipboard();
  const [showDebugDrawer, setShowDebugDrawer] = useState(false);

  const {
    data: configResp,
    isLoading: configLoading,
    error: configError,
  } = useAPI("alertsShowSourceConfig", {
    id: alert.alert_source_config_id,
  });

  if (configError) {
    captureException(configError);
    return <GenericErrorMessage error={configError} />;
  }

  if (configLoading) {
    return <FullPageLoader />;
  }

  const alertSourceConfig = configResp?.alert_source_config;

  return (
    <PageWrapper
      width={PageWidth.Medium}
      icon={IconEnum.Alert}
      title={alert.title}
      backHref="/alerts/recent"
      crumbs={[
        // These point to the same but it gives a nice information architecture moment.
        { title: "Alerts", to: "/alerts" },
        { title: "All alerts", to: "/alerts/recent" },
      ]}
      accessory={
        <div className={"flex items-center space-x-2"}>
          <div className={"pr-3"}>
            <AlertFiringIndicator
              firing={alert.status === AlertStatusEnum.Firing}
            />
          </div>
          <Button
            analyticsTrackingId={"debug-alert"}
            onClick={() => setShowDebugDrawer(true)}
            icon={IconEnum.View}
          >
            Inspect
          </Button>
          {alert.silence_url ? (
            <Button
              analyticsTrackingId={"silence-alert"}
              href={alert.silence_url}
              openInNewTab
              icon={IconEnum.ExternalLink}
            >
              Silence
            </Button>
          ) : null}
          {alert.status === AlertStatusEnum.Firing && (
            <ResolveAlertButton
              alert={alert}
              externallyResolved={
                alertSourceConfig?.alert_source.externally_resolved || false
              }
            />
          )}
        </div>
      }
    >
      <div className={"flex flex-col lg:flex-row gap-6"}>
        {/* Left 2/3 side */}
        <div className="space-y-6 lg:w-[65%] grow">
          {/* Debug callout */}
          {alert.evaluation_failures &&
            Object.keys(alert.evaluation_failures).length > 0 && (
              <AlertEvaluationFailuresCallout
                showDebug={true}
                onClick={() => setShowDebugDrawer(true)}
              />
            )}

          {/* URL box */}
          {alert.source_url && (
            <div className={"space-y-2"}>
              <div className={"flex flex-row justify-between items-center"}>
                <Txt className={"font-medium"}>URL</Txt>
                {hasCopied ? (
                  <Txt lightGrey xs>
                    Copied
                  </Txt>
                ) : (
                  <div
                    className={"flex flex-row hover:cursor-pointer"}
                    onClick={() =>
                      copyTextToClipboard(alert.source_url as string)
                    }
                  >
                    <Icon
                      id={IconEnum.Copy}
                      className={"text-content-tertiary"}
                      size={IconSize.Small}
                    />
                    <Txt lightGrey xs>
                      Copy
                    </Txt>
                  </div>
                )}
              </div>
              <ContentBox className={"p-5 break-all"}>
                <Button
                  href={alert.source_url}
                  openInNewTab
                  theme={ButtonTheme.Naked}
                  analyticsTrackingId={"alert-source-url"}
                >
                  {alert.source_url}
                </Button>
              </ContentBox>
            </div>
          )}

          {/* Description */}
          {alert.description && alert.description.text_node && (
            <div className={"space-y-2"}>
              <Txt className={"font-medium"}>Description</Txt>
              <ContentBox className={"p-5"}>
                <TemplatedTextDisplay
                  className="font-normal text-sm flex flex-col gap-3 leading-6 grow"
                  value={alert.description.text_node}
                  style={TemplatedTextDisplayStyle.Compact}
                />
                {alert.image_urls && alert.image_urls.length > 0 ? (
                  <div className="image-grid pt-4">
                    {alert.image_urls.map((url, index) => (
                      <div key={index} className="image-item">
                        <img src={url} alt={`img-${index}`} />
                      </div>
                    ))}
                  </div>
                ) : (
                  <div></div>
                )}
              </ContentBox>
            </div>
          )}

          {/* Attributes */}
          <AttributesSection
            resources={resources}
            alert={alert}
            schema={alertSchema}
          />

          <AlertTimeline alertId={alert.id} />
        </div>

        {/* Right 1/3 side */}
        <div className={"space-y-6 lg:w-[35%] grow"}>
          {/* Details */}
          <DetailsSection alert={alert} priorities={alertPriorities} />

          {/* Incidents */}
          <IncidentAlertsSection alert={alert} />

          {/* Escalations */}
          <EscalationsSection
            escalations={escalations}
            escalationsLoading={false}
          />
        </div>
      </div>

      <AnimatePresence>
        {showDebugDrawer && (
          <AlertDebugDrawer
            onClose={() => setShowDebugDrawer(false)}
            alert={alert}
            schema={alertSchema}
            sourceConfig={alertSourceConfig as AlertSourceConfig}
          />
        )}
      </AnimatePresence>
    </PageWrapper>
  );
};

const ResolveAlertButton = ({
  alert,
  externallyResolved,
}: {
  alert: Alert;
  externallyResolved: boolean;
}) => {
  const showToast = useToast();

  const { alertSource } = useAlertSource(alert?.source_type);

  const { trigger, isMutating } = useAPIMutation(
    "alertsShowAlert",
    {
      id: alert.id,
    },
    async (apiClient, _) => {
      await apiClient.alertsResolve({
        id: alert.id,
      });
    },
    {
      onSuccess: () => {
        showToast({
          theme: ToastTheme.Success,
          title: "Alert resolved",
        });
      },
    },
  );

  return (
    <GatedButton
      analyticsTrackingId={"resolve-alert"}
      onClick={() => trigger({})}
      loading={isMutating}
      theme={ButtonTheme.Primary}
      disabled={externallyResolved}
      disabledTooltipContent={`This alert must be resolved in ${
        alertSource?.name ?? ""
      }.`}
    >
      Resolve alert
    </GatedButton>
  );
};
