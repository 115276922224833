import {
  Investigation,
  useAiServiceAiListInvestigations,
} from "@incident-io/query-api";
import {
  Button,
  ButtonTheme,
  Icon,
  IconEnum,
  IconSize,
  LoadingWrapper,
  LocalDateTime,
  StackedList,
} from "@incident-ui";

export const AIInvestigationsList = () => {
  const { data, isLoading } = useAiServiceAiListInvestigations();

  return (
    <>
      <h2 className="text-lg font-semibold mt-8">Recent investigations</h2>
      <LoadingWrapper loading={isLoading}>
        <StackedList>
          {data?.investigations.map((investigation) => (
            <InvestigationRender
              key={investigation.id}
              investigation={investigation}
            />
          ))}
        </StackedList>
      </LoadingWrapper>
    </>
  );
};

const InvestigationRender = ({
  investigation,
}: {
  investigation: Investigation;
}) => {
  return (
    <Button
      theme={ButtonTheme.Unstyled}
      analyticsTrackingId={null}
      className="flex flex-col gap-4 px-4 group"
      href={`/ai-evals/investigations/${investigation.id}`}
    >
      <div className="flex flex-between gap-2 w-full">
        <div className="flex gap-6 py-4">
          <LocalDateTime
            timestamp={investigation.created_at}
            className="font-medium"
          />
          {investigation.incident_external_id && (
            <Button
              theme={ButtonTheme.Naked}
              analyticsTrackingId={null}
              href={"/incidents/" + investigation.incident_external_id}
            >
              INC-{investigation.incident_external_id}
            </Button>
          )}
          {investigation.incident_name}
        </div>
        <div className="grow" />
        {!!investigation.total_cost_in_cents && (
          <div className="flex items-center justify-end gap-0.5">
            <Icon id={IconEnum.PiggyBank} size={IconSize.Small} />$
            {(investigation.total_cost_in_cents / 100.0).toFixed(2)}
          </div>
        )}
        <Button
          theme={ButtonTheme.Naked}
          icon={IconEnum.ArrowRight}
          href={`/ai-evals/investigations/${investigation.id}`}
          analyticsTrackingId={null}
          title="View investigation"
          className="justify-end py-4 group-hover:text-slate-900"
        />
      </div>
    </Button>
  );
};
