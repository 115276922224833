import {
  IncidentCallExternalProviderEnum,
  IncidentCallsCreateRequestBodyExternalProviderEnum,
} from "@incident-io/api";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import {
  Button,
  ButtonSize,
  ButtonTheme,
  DropdownMenu,
  DropdownMenuItem,
  IconEnum,
  IconSize,
  ToastTheme,
} from "@incident-ui";
import { DropdownMenuGroup } from "@incident-ui/DropdownMenu/DropdownMenu";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { useIsWithinMicrosoftTeamsTab } from "src/contexts/MicrosoftTeamsTabContext";

import { IncidentHeaderModal } from "../../routes/legacy/IncidentRoute";
import { useNavigateToModal } from "../../utils/query-params";
import { useAPI, useAPIMutation } from "../../utils/swr";
import { getProviderNameAndLogo } from "./helpers";

export const IncidentCallsCreateButton = ({
  incidentId,
}: {
  incidentId: string;
}) => {
  const navigate = useOrgAwareNavigate();
  const navigateToModal = useNavigateToModal();
  const showToast = useToast();
  const isWithinTeamsTab = useIsWithinMicrosoftTeamsTab();

  const { data, isLoading } = useAPI(
    "incidentCallSettingsGetCallProviders",
    {},
  );

  const available_providers = data?.available_providers?.providers ?? [];

  const { trigger, isMutating } = useAPIMutation(
    "incidentCallsGetForLatestForIncident",
    { incidentId: incidentId },
    async (apiClient, data) => {
      await apiClient.incidentCallsCreate({
        createRequestBody: {
          incident_id: incidentId,
          external_provider:
            data.provider as IncidentCallsCreateRequestBodyExternalProviderEnum,
        },
      });
    },
    {
      onSuccess: () => {
        showToast({
          theme: ToastTheme.Success,
          title: "Call created",
        });
      },
      onError: () => {
        showToast({
          theme: ToastTheme.Error,
          title: "Failed to create call",
        });
      },
    },
  );

  if (isLoading) {
    return null;
  }

  const sharedButtonProps = {
    theme: ButtonTheme.Tertiary,
    analyticsTrackingId: "add-call-button",
    size: ButtonSize.Small,
    icon: IconEnum.Add,
    iconProps: {
      size: IconSize.Small,
      className: "text-primary-300",
    },
    title: "Create call",
  };

  // When no providers are installed, show dropdown to install a provider
  // or add custom link
  if (available_providers.length === 0) {
    const supportedCallProviders = [
      IncidentCallExternalProviderEnum.Zoom,
      IncidentCallExternalProviderEnum.GoogleMeet,
    ].map((provider) => {
      const { name, icon } = getProviderNameAndLogo(provider);
      return {
        label: name,
        icon: icon,
        slug: provider,
      };
    });

    // We do not show the configuration option in MS Teams tab view
    // They have to go to the dashboard to set these things up.
    return (
      <DropdownMenu triggerButton={<Button {...sharedButtonProps} />}>
        {!isWithinTeamsTab && (
          <DropdownMenuGroup key="integrations" label="Integrations">
            {supportedCallProviders.map((provider) => (
              <DropdownMenuItem
                key={provider.slug}
                onSelect={() =>
                  navigate(`/settings/integrations/${provider.slug}`)
                }
                analyticsTrackingId={`incident-call-${provider.slug}`}
                label={provider.label}
                icon={provider.icon}
              />
            ))}
          </DropdownMenuGroup>
        )}
        <DropdownMenuGroup key="other" label="Other">
          <DropdownMenuItem
            onSelect={() => navigateToModal(IncidentHeaderModal.UpdateCall)}
            analyticsTrackingId={"custom-incident-call"}
            label="Custom"
            icon={IconEnum.Link}
          />
        </DropdownMenuGroup>
      </DropdownMenu>
    );
  }

  // If there is only one provider, show the button to directly create a call
  if (available_providers.length === 1) {
    return (
      <Button
        {...sharedButtonProps}
        onClick={() => trigger({ provider: available_providers[0] })}
        loading={isMutating}
      />
    );
  }

  // If there are multiple providers, show dropdown to select a provider
  return (
    <DropdownMenu triggerButton={<Button {...sharedButtonProps} />}>
      {available_providers.map((provider) => (
        <DropdownMenuItemForProvider
          provider={provider}
          key={provider}
          onSelect={(provider) => trigger({ provider: provider })}
        />
      ))}
    </DropdownMenu>
  );
};

const DropdownMenuItemForProvider = ({
  provider,
  onSelect,
}: {
  provider: string;
  onSelect: (provider: string) => void;
}) => {
  const { name, icon } = getProviderNameAndLogo(provider);

  return (
    <DropdownMenuItem
      key={provider}
      onSelect={() => onSelect(provider)}
      analyticsTrackingId={"custom-incident-call"}
      label={name}
      icon={icon}
    />
  );
};
