/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TemplateEligibilityInformation,
    TemplateEligibilityInformationFromJSON,
    TemplateEligibilityInformationFromJSONTyped,
    TemplateEligibilityInformationToJSON,
} from './TemplateEligibilityInformation';

/**
 * 
 * @export
 * @interface WorkflowTemplate
 */
export interface WorkflowTemplate {
    /**
     * Human readable description for the template
     * @type {string}
     * @memberof WorkflowTemplate
     */
    description: string;
    /**
     * 
     * @type {TemplateEligibilityInformation}
     * @memberof WorkflowTemplate
     */
    eligibility: TemplateEligibilityInformation;
    /**
     * Group to display the template in
     * @type {string}
     * @memberof WorkflowTemplate
     */
    group: string;
    /**
     * String telling the UI what icon to use for this template
     * @type {string}
     * @memberof WorkflowTemplate
     */
    icon: WorkflowTemplateIconEnum;
    /**
     * Human readable label for the template
     * @type {string}
     * @memberof WorkflowTemplate
     */
    label: string;
    /**
     * Unique identifier for the template
     * @type {string}
     * @memberof WorkflowTemplate
     */
    name: string;
}

/**
* @export
* @enum {string}
*/
export enum WorkflowTemplateIconEnum {
    Action = 'action',
    Alert = 'alert',
    Announcement = 'announcement',
    Bolt = 'bolt',
    ToggleLeft = 'toggle-left',
    Book = 'book',
    Box = 'box',
    Briefcase = 'briefcase',
    Browser = 'browser',
    Bulb = 'bulb',
    Calendar = 'calendar',
    Chart = 'chart',
    Checkmark = 'checkmark',
    Clipboard = 'clipboard',
    Clock = 'clock',
    Close = 'close',
    Cog = 'cog',
    Components = 'components',
    CustomField = 'custom_field',
    Database = 'database',
    Delete = 'delete',
    Doc = 'doc',
    Email = 'email',
    Escalate = 'escalate',
    EscalationPath = 'escalation-path',
    Exclamation = 'exclamation',
    Export = 'export',
    ExternalLink = 'external-link',
    Files = 'files',
    Flag = 'flag',
    Folder = 'folder',
    FollowUps = 'follow_ups',
    GitBranchNew = 'git-branch-new',
    Globe = 'globe',
    Incident = 'incident',
    IncidentType = 'incident_type',
    Key = 'key',
    Lock = 'lock',
    MsTeams = 'ms-teams',
    Merge = 'merge',
    Message = 'message',
    Money = 'money',
    Mug = 'mug',
    NumberInput = 'number-input',
    OnCall = 'on-call',
    Priority = 'priority',
    Private = 'private',
    TextAlignLeft = 'text-align-left',
    Search = 'search',
    Server = 'server',
    Severity = 'severity',
    Slack = 'slack',
    SlackGreyscale = 'slack-greyscale',
    SlackChannel = 'slack_channel',
    SlackTeam = 'slack_team',
    Star = 'star',
    Status = 'status',
    StatusPage = 'status-page',
    Store = 'store',
    Tag = 'tag',
    Test = 'test',
    Text = 'text',
    Timestamp = 'timestamp',
    Triage = 'triage',
    User = 'user',
    Users = 'users',
    Warning = 'warning'
}

export function WorkflowTemplateFromJSON(json: any): WorkflowTemplate {
    return WorkflowTemplateFromJSONTyped(json, false);
}

export function WorkflowTemplateFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkflowTemplate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'description': json['description'],
        'eligibility': TemplateEligibilityInformationFromJSON(json['eligibility']),
        'group': json['group'],
        'icon': json['icon'],
        'label': json['label'],
        'name': json['name'],
    };
}

export function WorkflowTemplateToJSON(value?: WorkflowTemplate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'description': value.description,
        'eligibility': TemplateEligibilityInformationToJSON(value.eligibility),
        'group': value.group,
        'icon': value.icon,
        'label': value.label,
        'name': value.name,
    };
}

