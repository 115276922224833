import {
  ApiError,
  CallParticipant,
  CallSession,
  IncidentCallSessionWithTranscript,
  SelectOption,
} from "@incident-io/query-api";
import { AlertFiringIndicator } from "@incident-shared/alerts/AlertFiringIndicator";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import {
  Badge,
  BadgeSize,
  BadgeTheme,
  Button,
  ButtonSize,
  ButtonTheme,
  EmptyState,
  GenericErrorMessage,
  Icon,
  IconEnum,
  IconSize,
  Loader,
  PopoverSingleSelect,
} from "@incident-ui";
import {
  Drawer,
  DrawerBody,
  DrawerContents,
  DrawerTitle,
} from "@incident-ui/Drawer/Drawer";
import { useState } from "react";
import { useParams } from "react-router";
import { formatTimestampLocale } from "src/utils/datetime";
import { useAPIInfinite } from "src/utils/swr";

import { useInternalId } from "../legacy/incident/hooks";
import {
  AvatarList,
  AvatarListClickableType,
  MaybeUser,
} from "../legacy/incident/sidebar/AvatarList";
import { CallSummary } from "./CallSummary";
import { CallTranscript } from "./CallTranscript";
import {
  useIncidentCallSessionsWithSummaries,
  useLatestIncidentCall,
} from "./hooks";

export const CallNotesDrawer = ({
  onClose,
  incidentId,
}: {
  onClose: () => void;
  incidentId: string;
}) => {
  const { streamId } = useParams() as {
    streamId: string;
  };

  const { callData } = useLatestIncidentCall(incidentId);

  return (
    <Drawer onClose={onClose} width="medium">
      <DrawerContents>
        <DrawerTitle
          title="Call notes"
          icon={IconEnum.Scribe}
          onClose={onClose}
          color={ColorPaletteEnum.Scribe}
          secondaryAccessory={
            callData?.incident_call.call_url && (
              <Button
                icon={IconEnum.Call}
                theme={ButtonTheme.Primary}
                size={ButtonSize.Small}
                analyticsTrackingId="close-call-notes-drawer"
                href={callData?.incident_call.call_url}
                openInNewTab
              >
                Join call
              </Button>
            )
          }
        />
        <DrawerBody className="flex grow p-6 gap-0">
          <CallNotesDrawerBody incidentId={incidentId} streamId={streamId} />
        </DrawerBody>
      </DrawerContents>
    </Drawer>
  );
};

const CallNotesDrawerBody = ({
  incidentId,
  streamId,
}: {
  incidentId: string;
  streamId: string;
}) => {
  const {
    internalId: streamInternalId,
    isLoading: isLoadingStreamId,
    error: streamIdError,
  } = useInternalId(streamId);

  const {
    callSessionsWithSummaries,
    isLoading: isSessionsLoading,
    error: sessionError,
  } = useIncidentCallSessionsWithSummaries(streamInternalId ?? incidentId);

  if (streamIdError) {
    return <GenericErrorMessage />;
  }

  if (isLoadingStreamId || isSessionsLoading) {
    return <Loader />;
  }

  // If it's a 404 we will render empty states below - otherwise show error
  if (
    sessionError &&
    (sessionError instanceof ApiError ? sessionError.status !== 404 : true)
  ) {
    return <GenericErrorMessage />;
  }

  // No call session? Not sure how you got here, but show an empty state
  if (!callSessionsWithSummaries || callSessionsWithSummaries.length === 0) {
    return <NoCallNotes />;
  }

  // Sort our sessions by started_at, and for now pick the latest one
  callSessionsWithSummaries.sort(
    (a, b) =>
      new Date(b.call_session.started_at).getTime() -
      new Date(a.call_session.started_at).getTime(),
  );

  return <CallNotesSessions callSessions={callSessionsWithSummaries} />;
};

const NoCallNotes = () => {
  return (
    <div className="py-8 flex flex-col flex-grow justify-center align-middle self-stretch">
      <EmptyState
        className="border-0"
        title={"No call notes available"}
        icon={IconEnum.Scribe}
        content={
          <>
            Looks like we don&apos;t have any call notes for this incident. Join
            the call, and incident.io will automatically transcribe it for you.
          </>
        }
      />
    </div>
  );
};

const CallNotesSessions = ({
  callSessions,
}: {
  callSessions: IncidentCallSessionWithTranscript[];
}) => {
  // We need to manage some state to keep track of the current session
  const [currentSessionCallSessionID, setCurrentSessionCallSessionID] =
    useState<string>(callSessions[0].call_session.id);

  const {
    responses,
    isLoading,
    isFullyLoaded,
    error,
    loadMore: onLoadMore,
  } = useAPIInfinite(
    "incidentCallTranscriptsListTranscriptEntriesForCallSession",
    {
      incidentCallSessionId: currentSessionCallSessionID,
      pageSize: 50,
    },
    {
      refreshInterval: 10000, // 10 seconds
      revalidateAll: true,
      revalidateOnFocus: true,
    },
  );

  const entries = responses.flatMap(({ entries }) => entries);

  if (error) {
    return <GenericErrorMessage />;
  }

  // Try to map the current session to the one we have in the list
  const currentSessionWithTranscript = callSessions.find(
    (s) => s.call_session.id === currentSessionCallSessionID,
  );

  if (!currentSessionWithTranscript) {
    return <GenericErrorMessage />;
  }

  return (
    <>
      <div className="flex flex-start gap-10 self-stretch pb-6 border-stroke border-b">
        <CallSessionSelector
          callSessions={callSessions}
          selectedSession={currentSessionWithTranscript.call_session}
          onSelectSession={(sessionId) => {
            setCurrentSessionCallSessionID(sessionId);
          }}
        />
        <CallSessionParticipants
          participants={currentSessionWithTranscript.call_session.participants}
        />
      </div>
      {!currentSessionWithTranscript.summary && entries.length === 0 ? (
        <NoCallNotes />
      ) : (
        <div>
          <CallSummary
            callSessionWithTranscript={currentSessionWithTranscript}
          />
          <CallTranscript
            entries={entries}
            isFullyLoaded={isFullyLoaded}
            isLoading={isLoading}
            onLoadMore={onLoadMore}
          />
        </div>
      )}
    </>
  );
};

const CallSessionSelector = ({
  callSessions,
  selectedSession,
  onSelectSession,
}: {
  callSessions: IncidentCallSessionWithTranscript[];
  selectedSession: CallSession;
  onSelectSession: (sessionId: string) => void;
}) => {
  // Build a list of call sessions into the popover format
  const callSessionOptions = callSessions.map((s, index): SelectOption => {
    // for each one build the label of the right dates
    const label = durationLabelForSession({ callSession: s.call_session });
    return {
      label,
      value: s.call_session.id,
      sort_key: `${index}`,
    };
  });

  return (
    <div className="flex flex-col items-start gap-1">
      <div className="text-content-secondary text-xs-med">Session</div>
      <div className="flex items-start gap-1">
        {callSessions.length > 1 ? (
          <PopoverSingleSelect
            options={callSessionOptions}
            value={selectedSession.id}
            isClearable={false}
            onChange={(sessionId) => onSelectSession(sessionId ?? "")}
            triggerClassName={"p-0 m-0"}
            renderTriggerNode={({ onClick, selectedOption }) => {
              return (
                <Button
                  onClick={onClick}
                  theme={ButtonTheme.Secondary}
                  analyticsTrackingId={null}
                  title={selectedOption?.label || "Choose"}
                  size={ButtonSize.Small}
                >
                  {selectedOption?.label || "Choose"}
                  <Icon id={IconEnum.ChevronDown} size={IconSize.Small} />
                </Button>
              );
            }}
          />
        ) : (
          <Badge theme={BadgeTheme.Secondary} size={BadgeSize.Medium}>
            {durationLabelForSession({ callSession: selectedSession })}
          </Badge>
        )}
        {selectedSession.started_at && !selectedSession.ended_at && (
          <Badge theme={BadgeTheme.Error} size={BadgeSize.Medium}>
            <AlertFiringIndicator firing={true} />
            Live
          </Badge>
        )}
      </div>
    </div>
  );
};

const CallSessionParticipants = ({
  participants,
}: {
  participants: CallParticipant[] | undefined;
}) => {
  return (
    <div className="flex flex-col gap-1">
      <div className="text-content-secondary text-xs-med">Participants</div>
      {!!participants && participants.length > 0 && (
        <dl className="flex-center-y justify-between">
          <AvatarList
            users={participants.map((p) => convertToMaybeUser(p))}
            modalTitle={"Call participants"}
            maxToShow={5}
            clickableType={AvatarListClickableType.OnlyOnSeeMore}
          />
        </dl>
      )}
    </div>
  );
};

const durationLabelForSession = ({
  callSession,
}: {
  callSession: CallSession;
}): string => {
  const localCallStartDate = formatTimestampLocale({
    timestamp: callSession.started_at,
    dateStyle: "short",
  });

  const localCallStartTime = formatTimestampLocale({
    timestamp: callSession.started_at,
    timeStyle: "short",
  });

  let timeString = `${localCallStartDate}: ${localCallStartTime}`;
  if (callSession.ended_at) {
    const localCallEndDate = formatTimestampLocale({
      timestamp: callSession.ended_at,
      dateStyle: "short",
    });

    const localCallEndTime = formatTimestampLocale({
      timestamp: callSession.ended_at,
      timeStyle: "short",
    });

    if (localCallStartDate === localCallEndDate) {
      timeString = `${localCallStartDate}: ${localCallStartTime} → ${localCallEndTime}`;
    } else {
      timeString = `${localCallStartDate}: ${localCallStartTime} → ${localCallEndDate}: ${localCallEndTime}`;
    }
  }

  return timeString;
};

const convertToMaybeUser = (participant: CallParticipant): MaybeUser => {
  const maybeUser = {} as MaybeUser;

  if (participant.user) {
    maybeUser.user = participant.user;
  } else {
    maybeUser.nonUserLabel = participant.email;
  }

  return maybeUser;
};
