import { NonPrimitiveEntryList } from "@incident-shared/attribute";
import { BadgeSize } from "@incident-ui";
import _ from "lodash";
import {
  CatalogResourceColorEnum,
  CatalogResourceIconEnum,
  CustomFieldOption,
  CustomFieldTypeInfo,
  CustomFieldTypeInfoFieldTypeEnum,
  CustomFieldValue,
  EngineParamBindingValue,
} from "src/contexts/ClientContext";
import { assertUnreachable } from "src/utils/utils";

interface CustomFieldCatalogValue extends CustomFieldValue {
  value_option: CustomFieldOption;
}

const isCatalogCustomField = (
  customField: CustomFieldTypeInfo,
  values: CustomFieldValue[],
): values is CustomFieldCatalogValue[] => {
  return values && !!customField.catalog_info;
};

export const renderCustomFieldValues = (
  customField: CustomFieldTypeInfo,
  values: CustomFieldValue[],
  isMSTeamsTabFriendlyView: boolean,
  catalogEntryBadgeSize?: BadgeSize.Small | BadgeSize.Medium,
) => {
  if (_.isEmpty(values)) {
    return "Not set";
  }

  switch (customField.field_type) {
    case CustomFieldTypeInfoFieldTypeEnum.SingleSelect:
    case CustomFieldTypeInfoFieldTypeEnum.MultiSelect: {
      if (
        customField.catalog_info &&
        isCatalogCustomField(customField, values)
      ) {
        // TS can't tell this isn't undefined until we assign it to
        // a variable, even though it's in the if above (?!)
        const catalogInfo = customField.catalog_info;
        return (
          <NonPrimitiveEntryList
            collapseList
            innerClassName="truncate max-w-full"
            key={customField.id}
            arrayValue={
              values.map(({ value_catalog_entry }, idx) => {
                return {
                  catalog_entry: {
                    ...value_catalog_entry,
                    catalog_entry_id: value_catalog_entry?.id,
                    archived_at: value_catalog_entry?.archived_at,
                  },
                  label: value_catalog_entry?.name,
                  sort_key: String(idx),
                };
              }) as EngineParamBindingValue[]
            }
            catalogResource={{
              icon: catalogInfo.CatalogTypeIcon as unknown as CatalogResourceIconEnum,
              color:
                catalogInfo.CatalogTypeColor as unknown as CatalogResourceColorEnum,
            }}
            clickable={!isMSTeamsTabFriendlyView}
            catalogEntryBadgeSize={catalogEntryBadgeSize}
          />
        );
      }
      return values
        .map(({ value_option }) => {
          if (!value_option) {
            return "";
          }
          if (value_option.archived_at) {
            return value_option.value + " (archived)";
          }
          return value_option.value;
        })
        .join(", ");
    }

    case CustomFieldTypeInfoFieldTypeEnum.Text: {
      return values.map(({ value_text }, i) => (
        <span key={`${customField.id}-${i}`} className="max-w-full">
          {value_text}
        </span>
      ));
    }

    case CustomFieldTypeInfoFieldTypeEnum.Link: {
      return values.flatMap(({ value_link }) => {
        if (value_link) {
          return (
            <a
              href={value_link}
              target="_blank"
              rel="noopener noreferrer"
              className="flex-grow text-content-tertiary underline hover:text-content-primary bg-opacity-40 text-ellipsis overflow-x-clip"
            >
              <span className="truncate block max-w-[12em]">
                {value_link.replace(/http[s]?:\/\//gi, "")}
              </span>
            </a>
          );
        }
        return <></>;
      });
    }

    case CustomFieldTypeInfoFieldTypeEnum.Numeric: {
      return values.map(({ value_numeric }) => value_numeric);
    }

    default:
      return assertUnreachable(customField.field_type);
  }
};
