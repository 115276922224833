import { IconSize, iconSizeToClassNames } from "@incident-ui/Icon/Icon";
import React from "react";
import { tcx } from "src/utils/tailwind-classes";
import { stringToHash } from "src/utils/utils";

export type AvatarProps = {
  url?: string;
  name?: string;
  className?: string;
  placeholderImg?: string;
  title?: string;
  size?: IconSize.Small | IconSize.Medium | IconSize.Large | IconSize.XL;
};

export const Avatar = ({
  url,
  className,
  name,
  size = IconSize.Medium,
  placeholderImg = "/avatar-placeholder.svg",
  title,
}: AvatarProps): React.ReactElement => {
  if (!url && name) {
    const colourClasses = coloursForName(name);
    return (
      <div
        className={tcx(
          "rounded-full shadow-button shrink-0",
          "flex items-center justify-center",
          iconSizeToClassNames[size],
          colourClasses,
          "font-semibold",
          size === IconSize.Large ? "text-sm" : "text-xs",
          className,
        )}
      >
        {name?.[0]?.toUpperCase()}
      </div>
    );
  }
  return (
    <img
      src={url || placeholderImg}
      alt={name}
      title={title || name}
      className={tcx(
        "rounded-full shadow-button",
        iconSizeToClassNames[size],
        className,
      )}
      onError={({ currentTarget }) => {
        currentTarget.onerror = null; // prevents looping
        currentTarget.src = placeholderImg;
        // Hack to add a border so the placeholder img doesn't look rubbish.
        currentTarget.style.border = "1px solid #E4E4E7";
      }}
    />
  );
};

const avatarPalettes = [
  "bg-blue-500 text-blue-100",
  "bg-pink-500 text-pink-100",
  "bg-purple-500 text-purple-100",
  "bg-green-500 text-green-100",
  "bg-amber-500 text-amber-100",
];

const coloursForName = (name: string): string => {
  // First hash the id by itself and try to find the unique colour for this ID
  const hashed = stringToHash(name);
  const paletteIndex = hashed % avatarPalettes.length;
  return avatarPalettes[paletteIndex];
};
