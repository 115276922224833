import {
  IdentityStaffPermissionsEnum,
  Session,
  SessionStaffPermissionsEnum,
} from "@incident-io/api";

export const sessionCanImpersonate = (session: Session) => {
  if (!session.organisation_is_staff) {
    return false;
  }

  const permissions = session.staff_permissions || [];

  return permissionsCanImpersonate(permissions);
};

export const sessionCanSeeStaffRoom = (session: Session) => {
  if (!session.organisation_is_staff) {
    return false;
  }

  const permissions = session.staff_permissions || [];

  return permissions.length > 0;
};

export const permissionsCanImpersonate = (
  permissions: (SessionStaffPermissionsEnum | IdentityStaffPermissionsEnum)[],
) => {
  return (
    permissions.includes(SessionStaffPermissionsEnum.Impersonate) ||
    permissions.includes(SessionStaffPermissionsEnum.ImpersonateTrialAndDemo)
  );
};
