/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    TelecomTwilioAnsweringMachineCallbackRequestBody,
    TelecomTwilioAnsweringMachineCallbackRequestBodyFromJSON,
    TelecomTwilioAnsweringMachineCallbackRequestBodyToJSON,
    TelecomTwilioErrorWebhookRequestBody,
    TelecomTwilioErrorWebhookRequestBodyFromJSON,
    TelecomTwilioErrorWebhookRequestBodyToJSON,
    TelecomTwilioMessageStatusRequestBody,
    TelecomTwilioMessageStatusRequestBodyFromJSON,
    TelecomTwilioMessageStatusRequestBodyToJSON,
    TelecomTwilioSMSRequestBody,
    TelecomTwilioSMSRequestBodyFromJSON,
    TelecomTwilioSMSRequestBodyToJSON,
    TelecomTwilioVoiceRequestBody,
    TelecomTwilioVoiceRequestBodyFromJSON,
    TelecomTwilioVoiceRequestBodyToJSON,
    TelecomTwilioVoiceStatusRequestBody,
    TelecomTwilioVoiceStatusRequestBodyFromJSON,
    TelecomTwilioVoiceStatusRequestBodyToJSON,
} from '../models';

export interface TelecomTwilioAnsweringMachineCallbackRequest {
    xTwilioSignature: string;
    twilioAnsweringMachineCallbackRequestBody: TelecomTwilioAnsweringMachineCallbackRequestBody;
}

export interface TelecomTwilioErrorWebhookRequest {
    xTwilioSignature: string;
    twilioErrorWebhookRequestBody: TelecomTwilioErrorWebhookRequestBody;
}

export interface TelecomTwilioMessageStatusRequest {
    xTwilioSignature: string;
    twilioMessageStatusRequestBody: TelecomTwilioMessageStatusRequestBody;
}

export interface TelecomTwilioSMSRequest {
    xTwilioSignature: string;
    twilioSMSRequestBody: TelecomTwilioSMSRequestBody;
}

export interface TelecomTwilioVoiceRequest {
    sourceType: string;
    sourceId: string;
    xTwilioSignature: string;
    twilioVoiceRequestBody: TelecomTwilioVoiceRequestBody;
    emptyResponseCount?: number;
}

export interface TelecomTwilioVoiceStatusRequest {
    xTwilioSignature: string;
    twilioVoiceStatusRequestBody: TelecomTwilioVoiceStatusRequestBody;
}

/**
 * 
 */
export class TelecomApi extends runtime.BaseAPI {

    /**
     * Callback for Twilio to tell us about the answering machine state of a call
     * TwilioAnsweringMachineCallback Telecom
     */
    async telecomTwilioAnsweringMachineCallbackRaw(requestParameters: TelecomTwilioAnsweringMachineCallbackRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.xTwilioSignature === null || requestParameters.xTwilioSignature === undefined) {
            throw new runtime.RequiredError('xTwilioSignature','Required parameter requestParameters.xTwilioSignature was null or undefined when calling telecomTwilioAnsweringMachineCallback.');
        }

        if (requestParameters.twilioAnsweringMachineCallbackRequestBody === null || requestParameters.twilioAnsweringMachineCallbackRequestBody === undefined) {
            throw new runtime.RequiredError('twilioAnsweringMachineCallbackRequestBody','Required parameter requestParameters.twilioAnsweringMachineCallbackRequestBody was null or undefined when calling telecomTwilioAnsweringMachineCallback.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xTwilioSignature !== undefined && requestParameters.xTwilioSignature !== null) {
            headerParameters['X-Twilio-Signature'] = String(requestParameters.xTwilioSignature);
        }

        const response = await this.request({
            path: `/telecom/webhooks/twilio/voice/answering_machine_status`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TelecomTwilioAnsweringMachineCallbackRequestBodyToJSON(requestParameters.twilioAnsweringMachineCallbackRequestBody),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Callback for Twilio to tell us about the answering machine state of a call
     * TwilioAnsweringMachineCallback Telecom
     */
    async telecomTwilioAnsweringMachineCallback(requestParameters: TelecomTwilioAnsweringMachineCallbackRequest, initOverrides?: RequestInit): Promise<void> {
        await this.telecomTwilioAnsweringMachineCallbackRaw(requestParameters, initOverrides);
    }

    /**
     * Webhook that Twilio triggers each time an error/warning occurs in our account.
     * TwilioErrorWebhook Telecom
     */
    async telecomTwilioErrorWebhookRaw(requestParameters: TelecomTwilioErrorWebhookRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.xTwilioSignature === null || requestParameters.xTwilioSignature === undefined) {
            throw new runtime.RequiredError('xTwilioSignature','Required parameter requestParameters.xTwilioSignature was null or undefined when calling telecomTwilioErrorWebhook.');
        }

        if (requestParameters.twilioErrorWebhookRequestBody === null || requestParameters.twilioErrorWebhookRequestBody === undefined) {
            throw new runtime.RequiredError('twilioErrorWebhookRequestBody','Required parameter requestParameters.twilioErrorWebhookRequestBody was null or undefined when calling telecomTwilioErrorWebhook.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xTwilioSignature !== undefined && requestParameters.xTwilioSignature !== null) {
            headerParameters['X-Twilio-Signature'] = String(requestParameters.xTwilioSignature);
        }

        const response = await this.request({
            path: `/telecom/webhooks/twilio/error`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TelecomTwilioErrorWebhookRequestBodyToJSON(requestParameters.twilioErrorWebhookRequestBody),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Webhook that Twilio triggers each time an error/warning occurs in our account.
     * TwilioErrorWebhook Telecom
     */
    async telecomTwilioErrorWebhook(requestParameters: TelecomTwilioErrorWebhookRequest, initOverrides?: RequestInit): Promise<void> {
        await this.telecomTwilioErrorWebhookRaw(requestParameters, initOverrides);
    }

    /**
     * Callback for Twilio message status updates
     * TwilioMessageStatus Telecom
     */
    async telecomTwilioMessageStatusRaw(requestParameters: TelecomTwilioMessageStatusRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.xTwilioSignature === null || requestParameters.xTwilioSignature === undefined) {
            throw new runtime.RequiredError('xTwilioSignature','Required parameter requestParameters.xTwilioSignature was null or undefined when calling telecomTwilioMessageStatus.');
        }

        if (requestParameters.twilioMessageStatusRequestBody === null || requestParameters.twilioMessageStatusRequestBody === undefined) {
            throw new runtime.RequiredError('twilioMessageStatusRequestBody','Required parameter requestParameters.twilioMessageStatusRequestBody was null or undefined when calling telecomTwilioMessageStatus.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xTwilioSignature !== undefined && requestParameters.xTwilioSignature !== null) {
            headerParameters['X-Twilio-Signature'] = String(requestParameters.xTwilioSignature);
        }

        const response = await this.request({
            path: `/telecom/webhooks/twilio/message/status`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TelecomTwilioMessageStatusRequestBodyToJSON(requestParameters.twilioMessageStatusRequestBody),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Callback for Twilio message status updates
     * TwilioMessageStatus Telecom
     */
    async telecomTwilioMessageStatus(requestParameters: TelecomTwilioMessageStatusRequest, initOverrides?: RequestInit): Promise<void> {
        await this.telecomTwilioMessageStatusRaw(requestParameters, initOverrides);
    }

    /**
     * SMS webhook from Twilio
     * TwilioSMS Telecom
     */
    async telecomTwilioSMSRaw(requestParameters: TelecomTwilioSMSRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.xTwilioSignature === null || requestParameters.xTwilioSignature === undefined) {
            throw new runtime.RequiredError('xTwilioSignature','Required parameter requestParameters.xTwilioSignature was null or undefined when calling telecomTwilioSMS.');
        }

        if (requestParameters.twilioSMSRequestBody === null || requestParameters.twilioSMSRequestBody === undefined) {
            throw new runtime.RequiredError('twilioSMSRequestBody','Required parameter requestParameters.twilioSMSRequestBody was null or undefined when calling telecomTwilioSMS.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xTwilioSignature !== undefined && requestParameters.xTwilioSignature !== null) {
            headerParameters['X-Twilio-Signature'] = String(requestParameters.xTwilioSignature);
        }

        const response = await this.request({
            path: `/telecom/webhooks/twilio/sms`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TelecomTwilioSMSRequestBodyToJSON(requestParameters.twilioSMSRequestBody),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * SMS webhook from Twilio
     * TwilioSMS Telecom
     */
    async telecomTwilioSMS(requestParameters: TelecomTwilioSMSRequest, initOverrides?: RequestInit): Promise<void> {
        await this.telecomTwilioSMSRaw(requestParameters, initOverrides);
    }

    /**
     * Default callback for Twilio voice calls
     * TwilioVoice Telecom
     */
    async telecomTwilioVoiceRaw(requestParameters: TelecomTwilioVoiceRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.sourceType === null || requestParameters.sourceType === undefined) {
            throw new runtime.RequiredError('sourceType','Required parameter requestParameters.sourceType was null or undefined when calling telecomTwilioVoice.');
        }

        if (requestParameters.sourceId === null || requestParameters.sourceId === undefined) {
            throw new runtime.RequiredError('sourceId','Required parameter requestParameters.sourceId was null or undefined when calling telecomTwilioVoice.');
        }

        if (requestParameters.xTwilioSignature === null || requestParameters.xTwilioSignature === undefined) {
            throw new runtime.RequiredError('xTwilioSignature','Required parameter requestParameters.xTwilioSignature was null or undefined when calling telecomTwilioVoice.');
        }

        if (requestParameters.twilioVoiceRequestBody === null || requestParameters.twilioVoiceRequestBody === undefined) {
            throw new runtime.RequiredError('twilioVoiceRequestBody','Required parameter requestParameters.twilioVoiceRequestBody was null or undefined when calling telecomTwilioVoice.');
        }

        const queryParameters: any = {};

        if (requestParameters.emptyResponseCount !== undefined) {
            queryParameters['empty_response_count'] = requestParameters.emptyResponseCount;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xTwilioSignature !== undefined && requestParameters.xTwilioSignature !== null) {
            headerParameters['X-Twilio-Signature'] = String(requestParameters.xTwilioSignature);
        }

        const response = await this.request({
            path: `/telecom/webhooks/twilio/voice/{source_type}/{source_id}`.replace(`{${"source_type"}}`, encodeURIComponent(String(requestParameters.sourceType))).replace(`{${"source_id"}}`, encodeURIComponent(String(requestParameters.sourceId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TelecomTwilioVoiceRequestBodyToJSON(requestParameters.twilioVoiceRequestBody),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Default callback for Twilio voice calls
     * TwilioVoice Telecom
     */
    async telecomTwilioVoice(requestParameters: TelecomTwilioVoiceRequest, initOverrides?: RequestInit): Promise<void> {
        await this.telecomTwilioVoiceRaw(requestParameters, initOverrides);
    }

    /**
     * Callback for Twilio voice call status updates
     * TwilioVoiceStatus Telecom
     */
    async telecomTwilioVoiceStatusRaw(requestParameters: TelecomTwilioVoiceStatusRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.xTwilioSignature === null || requestParameters.xTwilioSignature === undefined) {
            throw new runtime.RequiredError('xTwilioSignature','Required parameter requestParameters.xTwilioSignature was null or undefined when calling telecomTwilioVoiceStatus.');
        }

        if (requestParameters.twilioVoiceStatusRequestBody === null || requestParameters.twilioVoiceStatusRequestBody === undefined) {
            throw new runtime.RequiredError('twilioVoiceStatusRequestBody','Required parameter requestParameters.twilioVoiceStatusRequestBody was null or undefined when calling telecomTwilioVoiceStatus.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xTwilioSignature !== undefined && requestParameters.xTwilioSignature !== null) {
            headerParameters['X-Twilio-Signature'] = String(requestParameters.xTwilioSignature);
        }

        const response = await this.request({
            path: `/telecom/webhooks/twilio/voice/status`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TelecomTwilioVoiceStatusRequestBodyToJSON(requestParameters.twilioVoiceStatusRequestBody),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Callback for Twilio voice call status updates
     * TwilioVoiceStatus Telecom
     */
    async telecomTwilioVoiceStatus(requestParameters: TelecomTwilioVoiceStatusRequest, initOverrides?: RequestInit): Promise<void> {
        await this.telecomTwilioVoiceStatusRaw(requestParameters, initOverrides);
    }

}
