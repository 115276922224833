import { PopoverSingleSelectV2 } from "@incident-shared/forms/v2/inputs/PopoverSelectV2";
import { TimeZonePopoverSelectV2 } from "@incident-shared/forms/v2/inputs/TimeZoneSelectV2";
import {
  Button,
  ButtonSize,
  ButtonTheme,
  ContentBox,
  Icon,
  IconEnum,
  IconSize,
  Txt,
} from "@incident-ui";
import { SelectOption } from "@incident-ui/Select/types";
import { ReactNode } from "react";
import { UseFormReturn } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import { InsightsReportScheduleIntervalEnum } from "src/contexts/ClientContext";
import {
  formatDayOfMonth,
  monthDaySelectOptions,
  policyReportFrequencyOptions,
  weekdaySelectOptions,
} from "src/utils/frequencies";

import { InsightsScheduledReportFormData } from "./InsightsScheduledReportCreateEditForm";

// InsightsScheduledReportIntervalEditor is a near-identical copy of PolicyReportIntervalEditor
export const InsightsScheduledReportIntervalEditor = ({
  formMethods,
}: {
  formMethods: UseFormReturn<InsightsScheduledReportFormData>;
}) => {
  const [selectedDayOfTheMonth, selectedInterval] = formMethods.watch([
    "dayOfTheMonthString",
    "interval",
  ]);

  const renderTriggerNode = ({
    onClick,
    selectedOption,
  }: {
    selectedOption?: SelectOption;
    onClick?: () => void;
  }) => {
    return (
      <Button
        onClick={onClick}
        theme={ButtonTheme.Tertiary}
        analyticsTrackingId={null}
        title={selectedOption?.label || "Choose"}
        size={ButtonSize.Small}
      >
        {selectedOption?.label || "Choose"}
        <Icon id={IconEnum.ChevronDown} size={IconSize.Small} />
      </Button>
    );
  };

  return (
    <Form.InputWrapper
      name="timezone"
      className="bg-surface-secondary p-4 rounded-2"
    >
      <ContentBox className="p-3 rounded-2 flex items-center gap-2">
        <Caps>Every</Caps>
        <PopoverSingleSelectV2
          formMethods={formMethods}
          placeholder="select frequency"
          isClearable={false}
          options={policyReportFrequencyOptions}
          name="interval"
          renderTriggerNode={renderTriggerNode}
          required
        />
        {selectedInterval &&
          ![
            InsightsReportScheduleIntervalEnum.Daily,
            InsightsReportScheduleIntervalEnum.DailyOnWeekdays,
          ].includes(selectedInterval) && (
            <>
              {selectedInterval ===
              InsightsReportScheduleIntervalEnum.Weekly ? (
                <>
                  <Caps>on</Caps>
                  <PopoverSingleSelectV2
                    formMethods={formMethods}
                    key="day-of-the-week"
                    placeholder="nth"
                    isClearable={false}
                    options={weekdaySelectOptions}
                    name="dayOfTheWeek"
                    required
                    renderTriggerNode={renderTriggerNode}
                  />
                </>
              ) : (
                <>
                  <Caps>on the</Caps>
                  <PopoverSingleSelectV2
                    formMethods={formMethods}
                    key="day-of-the-month"
                    placeholder="n"
                    isClearable={false}
                    options={monthDaySelectOptions}
                    name="dayOfTheMonthString"
                    required
                    renderTriggerNode={renderTriggerNode}
                  />
                  <Txt inline grey className="ml-0.5">
                    {selectedDayOfTheMonth
                      ? `${formatDayOfMonth(
                          parseInt(selectedDayOfTheMonth),
                        ).slice(-2)}`
                      : "th"}
                  </Txt>
                </>
              )}
            </>
          )}
        <Caps>at</Caps>
        <PopoverSingleSelectV2
          formMethods={formMethods}
          placeholder="Time of day"
          isClearable={false}
          options={[...Array(24).keys()].map((num) => ({
            label: `${String(num).padStart(2, "0")}:00`,
            value: String(num),
          }))}
          name="hourString"
          required
          renderTriggerNode={renderTriggerNode}
        />
        <TimeZonePopoverSelectV2
          formMethods={formMethods}
          name="timezone"
          className="ml-2"
          renderTriggerNode={renderTriggerNode}
          required
        />
      </ContentBox>
    </Form.InputWrapper>
  );
};

const Caps = ({ children }: { children: ReactNode }) => (
  <div className="text-xs uppercase font-semibold text-content-secondary tracking-wide tabular-nums lining-nums">
    {children}
  </div>
);
