import {
  EscalationPathTargetTypeEnum,
  ExternalSchedule,
  ExternalScheduleExternalProviderEnum,
  ManagementMeta,
  Schedule,
  ScheduleConfigPayload,
  ScheduleEntry,
  ScheduleRotation,
  ScheduleRotationHandoverIntervalTypeEnum,
  ScheduleRotationPayload,
  SchedulesUpdateResponseBody,
} from "@incident-io/api";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { SingleTimezoneSelectV2 } from "@incident-shared/forms/v2/inputs/SingleTimezoneSelectV2";
import { isTerraform } from "@incident-shared/management-meta/utils";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import { ScheduleOverviewHeader } from "@incident-shared/schedules/ScheduleOverview/ScheduleOverviewHeader";
import {
  makeQueryParams,
  useScheduleTimeWindowReducer,
} from "@incident-shared/schedules/ScheduleOverview/scheduleTimeWindowReducer";
import { AutoSizer } from "@incident-shared/schedules/ScheduleOverview/TimelineSectionV2/CollapsableContainer";
import { TimelineSection } from "@incident-shared/schedules/ScheduleOverview/TimelineSectionV2/TimelineSectionV2";
import { endTimeForTimelinePeriod } from "@incident-shared/schedules/ScheduleOverview/types";
import {
  AddNewButton,
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  DropdownMenu,
  DropdownMenuItem,
  Heading,
  Icon,
  IconEnum,
  IconSize,
  Loader,
  TabPane,
  TabSection,
  Tooltip,
  Txt,
} from "@incident-ui";
import { useWarnOnDrawerClose } from "@incident-ui/Drawer/DrawerFormStateContext";
import { ToastSideEnum, ToastTheme } from "@incident-ui/Toast/Toast";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import _, { compact } from "lodash";
import { useEffect, useMemo, useRef, useState } from "react";
import {
  FieldPath,
  useFieldArray,
  useFormContext,
  UseFormReturn,
} from "react-hook-form";
import { ErrorOption } from "react-hook-form/dist/types/errors";
import { Form } from "src/components/@shared/forms";
import { EscalationPathUserTargetFormData } from "src/components/escalation-paths/common/types";
import styles from "src/components/insights/assistant/AssistantOverlay.module.scss";
import { HolidaysPublicConfigurationInput } from "src/components/legacy/on-call/schedules/schedule-create-edit-form/HolidaysPublicConfigurationInput";
import { useClient } from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPI, useAPIMutation } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";
import { useNow } from "src/utils/use-now";
import { useRevalidate } from "src/utils/use-revalidate";
import { useDebounce } from "use-debounce";
import { useLocalStorage } from "use-hooks";

import { isOnCallSeatCount } from "../../../../../hooks/useCanPromoteToOnCall";
import { isOnCallUser } from "../../../../settings/users/users/utils";
import {
  OnCallPromotionConfirmationModal,
  PromotionState,
} from "../../common/OnCallPromotionConfirmationModal";
import {
  buildRotationsWithEffectiveFrom,
  rotaFormDataToPayload,
  scheduleFormDataToCreatePayload,
  scheduleFormDataToUpdatePayload,
} from "../marshall";
import { RotaCreateEditForm, RotaFormData } from "../RotaCreateEditForm";
import { useHydratedUserCache } from "../useHydratedUserCache";
import {
  getCurrentlyActiveRotaConfigs,
  getUpcomingRotaConfigChanges,
} from "../util";

export type ScheduleFormData = {
  name: string;
  timezone: string;
  rotations: RotaFormData[];
  external_schedule_id?: string;
  holidays_public_config: {
    // ID here really just means the country code, but react-hook-form's useFieldArray
    // wants an object with an ID key, when I really just want to use a string.
    country_codes: { id: string }[];
  };
};

export const ScheduleCreateEditForm = ({
  initialData,
  managementMeta,
  initialDuplicateData,
  importExternalScheduleData,
  onScheduleSaved,
  initialTab,
  onClose,
  openTerraformDrawer,
  openCalendarFeedDrawer,
  formMethods,
  defaultValues,
  getNewRota,
}: {
  initialData?: Schedule;
  managementMeta: ManagementMeta;
  initialDuplicateData?: Schedule;
  importExternalScheduleData?: ExternalSchedule;
  onScheduleSaved?: (result: Schedule) => void;
  initialTab?: string;
  onClose: () => void;
  openTerraformDrawer: () => void;
  openCalendarFeedDrawer: () => void;
  formMethods: UseFormReturn<ScheduleFormData>;
  defaultValues: ScheduleFormData;
  getNewRota: (index: number) => RotaFormData;
}) => {
  const createMode = !initialData;
  const navigate = useOrgAwareNavigate();
  const showToast = useToast();
  const { identity } = useIdentity();
  const [genericError, setGenericError] = useState<string | null>(null);
  const client = useClient();
  const originalFormData = structuredClone(defaultValues);

  const [selectedTimezones, setSelectedTimezones] = useLocalStorage<string[]>(
    initialData?.timezone
      ? `${initialData.id}-selected-timezones`
      : "selected-timezones",
    [initialData?.timezone ?? defaultValues.timezone],
  );
  const [currentTab, setCurrentTab] = useState<string>(initialTab ?? "");
  const [promotionState, setPromotionState] =
    useState<PromotionState<ScheduleFormData>>(null);

  const {
    data: { seat_counts: seatCounts },
  } = useAPI("billingListSeatCounts", undefined, {
    fallbackData: { seat_counts: [] },
  });

  const currentSeatCount = seatCounts?.find(isOnCallSeatCount)?.used ?? 0;

  const onCallSeatCountLimit = seatCounts?.find(isOnCallSeatCount)?.limit;

  const revalidate = useRevalidate([
    "schedulesShow",
    "schedulesList",
    "schedulesListForUser",
    "schedulesListVersions",
    "usersTypeahead", // we might promote users!
  ]);

  const timezone: string = formMethods.watch("timezone");
  const now = useNow(timezone);

  const currentlyActiveRotas = getCurrentlyActiveRotaConfigs({
    rotas:
      initialData?.config?.rotations ??
      initialDuplicateData?.config?.rotations ??
      [],
    now: now,
  });

  // We track this so if you delete rotas, we just keep counting upwards
  const [rotaCounter, setRotaCounter] = useState(
    (currentlyActiveRotas.length ??
      importExternalScheduleData?.native_config?.rotations.length ??
      1) + 1,
  );

  const { isDirty, onCloseWithWarn } = useWarnOnDrawerClose(
    formMethods,
    onClose,
  );

  const rotations = formMethods.watch("rotations");

  const userCache = useHydratedUserCache(
    rotations.flatMap((r) => r.users).map((u) => u.id),
  );

  const { append: addRota, remove: deleteRota } = useFieldArray({
    name: "rotations",
    control: formMethods.control,
  });

  // If we've navigated to the form in order to create a new rota, we should do
  // that and activate the tab now.
  useEffect(() => {
    if (currentTab === "new") {
      const newRota = getNewRota(rotaCounter);
      addRota(newRota);
      setRotaCounter((prev) => prev + 1);
      setCurrentTab(newRota.id as string);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // setError wraps the formMethods.setError function to handle the fact that
  // the API returns paths starting with `config` but our form state doesn't
  const setError = (
    path: string,
    error: ErrorOption,
    options?: {
      shouldFocus: boolean;
    },
  ) => {
    // The API returns a path starting with `config` since that's what the
    // payload looks like, but our form state doesn't have that.
    path = path.replace("config.", "");

    // We need to switch to the tab where the errors occur
    const pathToTabIdxMatch = path.match(/rotations\.(\d+)/);
    if (pathToTabIdxMatch) {
      // Fetch the corresponding rota based on index
      const rota = rotations[Number(pathToTabIdxMatch[1])];
      setCurrentTab(rota.id as string);
    }

    let fieldPath: FieldPath<ScheduleFormData>;
    // The API returns the user path as `user_ids` but
    // our form state is called `users`
    if (path.search(/rotations\.\d+\.user_ids/g) !== -1) {
      fieldPath = path.replace(
        "user_ids",
        "users",
      ) as FieldPath<ScheduleFormData>;
    } else if (path.includes("working_intervals.")) {
      fieldPath = (path.split("working_intervals.")[0] +
        "working_intervals") as FieldPath<ScheduleFormData>;
      error.message = error.message?.slice(0, -4);
    } else {
      fieldPath = path as FieldPath<ScheduleFormData>;
    }

    formMethods.setError(fieldPath, error, options);
  };

  const { trigger: createSchedule, isMutating: createSaving } = useAPIMutation(
    "schedulesShow",
    // We don't actually want to send anything to the query cache,
    // we just want to pass the response to the onSuccess
    { id: "" },
    async (
      apiClient,
      data: ScheduleFormData & { userIdsToPromote: string[] },
    ) => {
      const res = await apiClient.schedulesCreate(
        scheduleFormDataToCreatePayload(data, data.userIdsToPromote),
      );
      userCache.removeFromCache(data.userIdsToPromote);
      return res;
    },
    {
      onSuccess: (res) => {
        showToast({
          title: `Schedule created`,
          theme: ToastTheme.Success,
          toastSide: ToastSideEnum.Bottom,
        });
        revalidate();
        if (onScheduleSaved) {
          onScheduleSaved(res.schedule);
        } else {
          navigate(`/on-call/schedules/${res.schedule.id}`);
        }
        onClose();
      },
      onError: (error, fieldErrors) => {
        showToast({
          title: "Failed to create schedule",
          theme: ToastTheme.Error,
          toastSide: ToastSideEnum.Bottom,
        });
        if (!fieldErrors) {
          setGenericError(error.message);
        }
      },
      setError,
    },
  );

  const { trigger: updateSchedule, isMutating: updateSaving } = useAPIMutation(
    "schedulesShow",
    { id: initialData?.id || "" }, // this will only ever get called when initialData is set
    async (
      apiClient,
      data: ScheduleFormData & { userIdsToPromote: string[] },
    ) => {
      if (!initialData) {
        throw new Error("initial data is null");
      }

      const res = await apiClient.schedulesUpdate(
        scheduleFormDataToUpdatePayload(
          initialData?.id || "",
          data,
          initialData,
          initialData?.config?.version || 0,
          data.userIdsToPromote,
          now,
        ),
      );
      userCache.removeFromCache(data.userIdsToPromote);
      return res;
    },
    {
      onSuccess: (res: SchedulesUpdateResponseBody) => {
        showToast({
          title: `Schedule updated`,
          theme: ToastTheme.Success,
          toastSide: ToastSideEnum.Bottom,
        });
        revalidate();
        if (onScheduleSaved) {
          onScheduleSaved(res.schedule);
        } else {
          navigate(`/on-call/schedules/${initialData?.id}`);
        }
        onClose();
      },
      onError: (error, fieldErrors) => {
        showToast({
          title: "Failed to update schedule",
          theme: ToastTheme.Error,
          toastSide: ToastSideEnum.Bottom,
        });
        if (!fieldErrors) {
          setGenericError(error.message);
        }
      },
      setError,
    },
  );

  const realSubmit = async (
    formData: ScheduleFormData,
    userIdsToPromote: string[] = [],
  ) => {
    if (initialData) {
      await updateSchedule({ ...formData, userIdsToPromote });
    } else {
      await createSchedule({ ...formData, userIdsToPromote });
    }
  };
  const handleSubmit = async (formData: ScheduleFormData) => {
    const valid = await formMethods.trigger();
    if (!valid) return;

    const usersToPromote = compact(
      formData.rotations
        .flatMap((rotation) => rotation.users)
        .map(
          ({ id }): EscalationPathUserTargetFormData =>
            ({
              ...userCache.getHydratedUser(id),
              type: EscalationPathTargetTypeEnum.User,
              value: id,
            }) as EscalationPathUserTargetFormData,
        ),
    ).filter((user) => user.value !== "NOBODY" && !isOnCallUser(user.state));

    if (usersToPromote.length > 0) {
      const { requires_billing_scope } =
        await client.billingRequiresBillingScopeToPromoteOnCallResponders({
          requiresBillingScopeToPromoteOnCallRespondersRequestBody: {
            number_of_promotions: usersToPromote.length,
          },
        });
      if (requires_billing_scope) {
        setPromotionState({
          formData,
          usersToPromote,
        });
        return;
      }

      if (
        onCallSeatCountLimit &&
        currentSeatCount + usersToPromote.length > onCallSeatCountLimit
      ) {
        setPromotionState({
          formData,
          usersToPromote,
          requiresSeatCountIncrease: true,
        });
        return;
      }
    }

    realSubmit(
      formData,
      usersToPromote.map((x) => x.value),
    );
  };

  const formData = formMethods.watch();

  const [debouncedFormData] = useDebounce(formData, 500, {
    equalityFn: _.isEqual,
  });

  const [timeWindowState, timeWindowDispatch] =
    useScheduleTimeWindowReducer(now);

  const currentEndTime = endTimeForTimelinePeriod({
    from: timeWindowState.startTime,
    timePeriod: timeWindowState.timePeriodOption,
  });

  const [from, until] = makeQueryParams(timeWindowState, timezone);
  const formRotasWithEffectiveFrom = buildRotationsWithEffectiveFrom(
    now,
    formData.rotations,
    initialData,
  );

  const buildSchedulePreviewRequestBody = (
    formData: ScheduleFormData,
    from: string,
    until: string,
    initialData?: Schedule,
  ) => {
    const rotas = buildRotationsWithEffectiveFrom(
      now,
      formData.rotations,
      initialData,
    );

    return {
      previewEntriesRequestBody: {
        schedule_id: initialData?.id,
        config: {
          rotations: rotas.map((rota) => {
            let existingRotation:
              | ScheduleRotation
              | ScheduleRotationPayload
              | null = null;

            if (initialData) {
              existingRotation =
                initialData?.config?.rotations.find((r) => r.id === rota.id) ??
                null;
            } else if (importExternalScheduleData?.native_config) {
              existingRotation =
                importExternalScheduleData.native_config.rotations.find(
                  (r) => r.id === rota.id,
                ) ?? null;
            }

            return rotaFormDataToPayload(rota, existingRotation);
          }),
        } as ScheduleConfigPayload,
        from,
        until,
        overrides: [],
        timezone: timezone,
      },
    };
  };

  // Need this to power the previews of the original and edited schedules
  const { data: originalEntriesResp, isLoading: originalEntriesIsLoading } =
    useAPI(
      createMode ? null : "schedulesListEntries", // Only fire this if we're in edit mode
      {
        scheduleIds: [initialData?.id ?? ""],
        from,
        until,
      },
    );

  const resizerRef = useRef<HTMLDivElement | null>(null);

  const { data: editedPreviewResp, isLoading: editedPreviewIsLoading } = useAPI(
    "schedulesPreviewEntries",
    buildSchedulePreviewRequestBody(
      debouncedFormData,
      from,
      until,
      initialData,
    ),
    {},
  );

  // Power our schedule preview
  const originalPreviewEntries = useMemo(
    () =>
      (originalEntriesResp?._final ?? []).map((e) => ({
        ...e,
        user_id: e.external_user_id,
      })),
    [originalEntriesResp?._final],
  );
  const originalPreviewRotas = buildPreviewRotas(
    originalPreviewEntries,
    originalFormData.rotations,
  );

  const editedPreviewEntries = useMemo(
    () =>
      (editedPreviewResp?._final ?? []).map((e) => ({
        ...e,
        user_id: e.external_user_id,
      })),
    [editedPreviewResp],
  );
  const editedPreviewRotas = buildPreviewRotas(editedPreviewEntries, rotations);

  // Find who's currently on call to power our handover bump if someone edits the rota
  const currentShiftsByRota = originalEntriesResp?.scheduled.reduce<
    Record<string, ScheduleEntry[]>
  >((acc, entry) => {
    if (
      entry.start_at < new Date() &&
      entry.end_at > new Date() &&
      entry.rotation_id &&
      !acc[entry.rotation_id] // we only want the first layer
    ) {
      if (acc[entry.rotation_id]) {
        acc[entry.rotation_id].push(entry);
      } else {
        acc[entry.rotation_id] = [entry];
      }
    }
    return acc;
  }, {});

  const onClickNewRota = () => {
    const newRota = getNewRota(rotaCounter);
    addRota(newRota);
    setRotaCounter((prev) => prev + 1);
    setCurrentTab(newRota.id as string);
  };

  const isDisabled = !!initialData;
  const saving = updateSaving || createSaving;
  const isManagedByTerraform = isTerraform(managementMeta);

  const countryCodes = formMethods.watch(
    "holidays_public_config.country_codes",
  );

  const activeUsersOnSchedules = useMemo(() => {
    return _.uniq([
      ...(getCurrentlyActiveRotaConfigs({
        rotas: editedPreviewRotas || [],
        now: now,
      })
        .map((r) => r.user_ids)
        .flat() ?? []),
      ...(editedPreviewEntries?.map((e) => e.external_user_id) ?? []),
    ]);
  }, [now, editedPreviewEntries, editedPreviewRotas]);

  const { data: holidaysResponse } = useAPI(
    "schedulesListHolidayEntries",
    {
      from,
      until,
      userIds: activeUsersOnSchedules,
      countryCodes: countryCodes?.map((c) => c.id) ?? [],
    },
    {
      fallbackData: {
        holiday_public_entries: [],
        holiday_user_entries: [],
      },
    },
  );

  const { data: entriesResp } = useAPI(
    initialData ? "schedulesListEntries" : null,
    {
      scheduleIds: initialData ? [initialData.id] : [],
      from,
      until: timeWindowState.startTime.plus({ weeks: 4 }).toISO(),
    },
  );

  if (!identity || userCache.hydrating) {
    return <Loader />;
  }

  return (
    <Form.Root
      onSubmit={handleSubmit}
      formMethods={formMethods}
      id="schedule-create-form"
      warnWhenDirty
      outerClassName="flex-1 min-h-0 overflow-y-hidden"
      innerClassName="h-full !space-y-0 flex flex-col min-h-0 overflow-y-hidden"
      loadingWrapperClassName="h-full"
      genericError={genericError}
    >
      <div className="outerbox flex flex-row min-h-0 overflow-y-hidden">
        {/* Left hand, inputs side of the form */}
        <div
          className={tcx(
            "leftsection overflow-auto flex flex-col items-stretch flex-[2] p-6 border-r border-stroke",
            styles.hideScrollbar,
          )}
        >
          <div className={"space-y-6"}>
            {/* Schedule Details */}
            <div className="flex flex-col space-y-2">
              <Heading level={2} size="medium">
                Schedule details
              </Heading>
              <Txt lightGrey className={"max-w-2xl"}>
                Name your schedule and set the base timezone.
              </Txt>
              <div className="flex flex-col space-y-6 pt-4">
                {isManagedByTerraform ? (
                  <Tooltip
                    content={
                      "You cannot edit a schedule that is managed by Terraform"
                    }
                    analyticsTrackingId={null}
                    bubbleProps={{ className: "!w-80" }}
                    side="bottom"
                  >
                    <div className="w-full">
                      <InputV2
                        formMethods={formMethods}
                        name="name"
                        label={
                          <div className="flex items-center space-x-0.5 mb-2">
                            <span>Schedule name</span>
                          </div>
                        }
                        placeholder="Enter your schedule name"
                        className="w-full"
                        required
                        disabled
                      />
                    </div>
                  </Tooltip>
                ) : (
                  <InputV2
                    formMethods={formMethods}
                    name="name"
                    label={"Schedule name"}
                    labelClassName={"!mb-0"}
                    labelAccessory={
                      <Tooltip
                        content={
                          "What is the name of the team or group that will be on this schedule?"
                        }
                        analyticsTrackingId={null}
                        bubbleProps={{
                          className: "max-w-[200px] font-normal",
                        }}
                        buttonClassName="flex-inline"
                        side="right"
                      />
                    }
                    placeholder="Enter your schedule name"
                    autoFocus={true}
                    className="w-full"
                    required
                    disabled={isManagedByTerraform}
                  />
                )}
                <div>
                  <SingleTimezoneSelectV2
                    label={"Timezone"}
                    labelClassName={"!mb-0"}
                    labelAccessory={
                      <Tooltip
                        content={
                          "This determines the timezone for viewing and configuring your schedule. It cannot be modified after creation."
                        }
                        analyticsTrackingId={null}
                        bubbleProps={{
                          className: "max-w-[200px] font-normal",
                        }}
                        buttonClassName="flex-inline"
                        side="right"
                      />
                    }
                    name={"timezone"}
                    fullWidth
                    required
                    formMethods={formMethods}
                    disabled={isManagedByTerraform || isDisabled}
                    disabledTooltipContent={
                      isManagedByTerraform
                        ? "This schedule is managed by Terraform"
                        : "A schedule's timezone cannot be changed after creation. You can view your schedule in different timezones from the 3-hour and 1-day views."
                    }
                  />
                  <HolidaysPublicConfigurationInput
                    formMethods={formMethods}
                    openCalendarFeedDrawer={openCalendarFeedDrawer}
                    disabled={isManagedByTerraform}
                    disabledTooltipContent={
                      isManagedByTerraform
                        ? "This schedule is managed by Terraform"
                        : undefined
                    }
                  />
                </div>
              </div>
              {importExternalScheduleData && (
                <div className={"pt-2"}>
                  <Callout theme={CalloutTheme.Warning}>
                    This configuration was imported from your{" "}
                    <strong>
                      {importExternalScheduleData.external_provider ===
                      ExternalScheduleExternalProviderEnum.Pagerduty
                        ? "PagerDuty"
                        : "Opsgenie"}
                    </strong>{" "}
                    schedule but does not include any overrides.
                  </Callout>
                </div>
              )}
              {initialDuplicateData && (
                <div className={"pt-2"}>
                  <Callout theme={CalloutTheme.Info}>
                    This is a copy of your{" "}
                    <strong>{initialDuplicateData.name}</strong> schedule,
                    overrides are not copied.
                  </Callout>
                </div>
              )}
            </div>

            <hr className="my-3" />

            {/* Rota Details */}
            <div className="flex flex-col space-y-2">
              <Heading level={2} size="medium">
                Rotation details
              </Heading>
              <Txt lightGrey className={"max-w-2xl"}>
                Define how your schedule will work, and who will be on it.
              </Txt>

              <TabSection
                withIndicator
                tabs={rotations.map((x, i) => {
                  return {
                    label: x.name || `Rota ${i + 1}`,
                    id: x.id as string,
                  };
                })}
                tabBarClassName={tcx("border-b border-stroke", {
                  hidden: rotations.length === 1,
                })}
                tabBarAccessory={
                  <AddNewButton
                    title="Add rota"
                    analyticsTrackingId="schedules-add-rota"
                    className="text-sm font-medium text-slate-400 !ml-2"
                    onClick={onClickNewRota}
                  />
                }
                value={
                  // Make sure the tab param is valid, otherwise default to the first tab
                  rotations.find((t) => currentTab === t.id)?.id ??
                  rotations[0].id
                }
                onTabChange={setCurrentTab}
              >
                {rotations.map((rota, i) => (
                  <TabPane tabId={rota.id as string} key={rota.id}>
                    <RotaCreateEditForm
                      key={`rotation-${i}`}
                      initialData={initialData}
                      formMethods={formMethods}
                      timezone={timezone}
                      index={i}
                      onDelete={
                        rotations.length > 1 // Allow deleting any rota except 1
                          ? () => {
                              deleteRota(i);
                              const nextTabIdx = i === 0 ? 1 : i - 1; // If we're deleting the first rota, switch to the next rather than previous.
                              setCurrentTab(rotations[nextTabIdx].id as string);
                            }
                          : undefined
                      }
                      hideNameInput={rotations.length === 1}
                      userCache={userCache}
                      isRotaCreateMode={
                        !initialData?.config?.rotations.find(
                          (r) => r.id === rota.id,
                        )
                      }
                      currentShifts={
                        rota.id ? currentShiftsByRota?.[rota.id] : undefined
                      }
                      scheduledEntries={entriesResp?.scheduled.filter(
                        (e) => e.rotation_id === rota.id,
                      )}
                      isManagedByTerraform={isManagedByTerraform}
                    />
                    {rotations.length === 1 && (
                      <div className="bg-slate-50 border border-slate-100 p-4 rounded-2 flex items-start space-x-4 w-full mt-4">
                        <div>
                          <Icon
                            id={IconEnum.CalendarPlus}
                            size={IconSize.XL}
                            className="text-slate-400"
                          />
                        </div>
                        <div className="flex flex-col space-y-2">
                          <div className="flex flex-wrap gap-4 items-center md:flex-nowrap">
                            <div>
                              <Txt bold>Need more advanced options?</Txt>
                              <Txt grey>
                                Most schedules will have just one rota, but you
                                can add more to support{" "}
                                <span
                                  className={"font-medium text-content-primary"}
                                >
                                  shadowing
                                </span>{" "}
                                or models like{" "}
                                <span
                                  className={"font-medium text-content-primary"}
                                >
                                  follow-the-sun
                                </span>{" "}
                                - where people provide cover at different times.
                              </Txt>
                            </div>
                            <Button
                              analyticsTrackingId="schedules-add-rota"
                              onClick={onClickNewRota}
                              icon={IconEnum.Add}
                              className="flex-none"
                              disabled={isManagedByTerraform}
                            >
                              Add rota
                            </Button>
                          </div>
                        </div>
                      </div>
                    )}
                  </TabPane>
                ))}
              </TabSection>
            </div>
          </div>
        </div>

        {/* Right hand, preview side of the form */}
        {/*Schedule preview*/}
        <div
          className={tcx(
            "rightsection overflow-auto flex flex-col items-stretch flex-[2] p-6 bg-slate-50",
            styles.hideScrollbar,
          )}
        >
          <div className="flex flex-col space-y-2 flex-1">
            <Heading level={2} size="medium">
              Preview
            </Heading>
            <Txt grey className={"max-w-2xl"}>
              How your schedule will look once{" "}
              {initialData ? "updated" : "created"}.
            </Txt>
            <AutoSizer ref={resizerRef}>
              {({ width }) => (
                <div className="flex flex-col space-y-2 mb-4 h-full">
                  <ScheduleOverviewHeader
                    className={"mb-3"}
                    timeWindowState={timeWindowState}
                    timeWindowDispatch={timeWindowDispatch}
                    scheduleDefaultTimezone={timezone}
                    showCalendarOption={false}
                    selectedTimezones={selectedTimezones}
                    setSelectedTimezones={setSelectedTimezones}
                  />
                  {/* existing - Only display in edit mode */}
                  {!createMode && (
                    <TimelineSection
                      title="Previous schedule"
                      width={width}
                      now={timeWindowState.now}
                      scheduleId={initialData?.id}
                      rotations={originalPreviewRotas}
                      isLoadingEntries={originalEntriesIsLoading}
                      timelineStartPoint={timeWindowState.startTime}
                      holidaysResponse={holidaysResponse}
                      timelineEndpoint={currentEndTime}
                      timePeriod={timeWindowState.timePeriodOption}
                      entries={originalPreviewEntries}
                      scheduleTimezone={timezone}
                      selectedTimezones={selectedTimezones}
                      upcomingRotaChanges={getUpcomingRotaConfigChanges(
                        initialData?.config?.rotations || [],
                        now,
                      )}
                      disableOverride
                      collapsable={true}
                      collapseByDefault={true}
                    />
                  )}
                  {/* new */}
                  <TimelineSection
                    title={createMode ? "Overview" : "Updated schedule"}
                    now={timeWindowState.now}
                    rotations={editedPreviewRotas}
                    isLoadingEntries={editedPreviewIsLoading}
                    timelineStartPoint={timeWindowState.startTime}
                    timelineEndpoint={currentEndTime}
                    timePeriod={timeWindowState.timePeriodOption}
                    scheduleId={initialData?.id}
                    holidaysResponse={holidaysResponse}
                    entries={editedPreviewEntries}
                    width={width}
                    scheduleTimezone={timezone}
                    selectedTimezones={selectedTimezones}
                    upcomingRotaChanges={getUpcomingRotaConfigChanges(
                      formRotasWithEffectiveFrom,
                      now,
                    )}
                    disableOverride
                    collapsable={false}
                  />
                </div>
              )}
            </AutoSizer>
          </div>
        </div>
      </div>

      <div className="flex gap-2 w-full sticky bottom-0 z-[50] py-4 bg-white border-t border-stroke items-center">
        <div className="grow" />
        <Button
          onClick={() => onCloseWithWarn(isDirty)}
          analyticsTrackingId={
            initialData ? "schedules-edit-cancel" : "schedules-create-cancel"
          }
          theme={ButtonTheme.Secondary}
        >
          Cancel
        </Button>
        {!createMode && isManagedByTerraform ? (
          <div className={"flex flex-center gap-0.5 mr-6"}>
            {/* Export to Terraform button */}
            <Button
              analyticsTrackingId="schedule-sync-with-terraform"
              icon={IconEnum.Terraform}
              iconProps={{
                className: "!text-terraform-purple-500",
              }}
              onClick={openTerraformDrawer}
            >
              Export
            </Button>
          </div>
        ) : (
          <ScheduleCreateEditSplitButton
            createMode={createMode}
            saving={saving}
            openTerraformDrawer={openTerraformDrawer}
            handleSubmit={() => handleSubmit(formData)}
          />
        )}
      </div>
      <OnCallPromotionConfirmationModal
        noun="schedule"
        state={promotionState}
        onClose={() => setPromotionState(null)}
        onSubmit={realSubmit}
      />
    </Form.Root>
  );
};

const ScheduleCreateEditSplitButton = ({
  createMode,
  saving,
  openTerraformDrawer,
  handleSubmit,
}: {
  createMode: boolean;
  saving: boolean;
  openTerraformDrawer: () => void;
  handleSubmit: () => void;
}) => {
  const { watch } = useFormContext<ScheduleFormData>();

  const scheduleName = watch("name");

  return (
    <div className={"flex flex-center mr-6 !max-h-8 gap-[1px]"}>
      <Button
        type="submit"
        form="schedule-create-form"
        analyticsTrackingId={
          createMode ? "schedules-create-schedule" : "schedules-update-schedule"
        }
        theme={ButtonTheme.Primary}
        className="ml-auto mr-auto !rounded-r-none"
        loading={saving}
      >
        {createMode ? "Create schedule" : "Save"}
      </Button>
      <DropdownMenu
        triggerButton={
          <Button
            theme={ButtonTheme.Primary}
            type="button"
            className="!px-2 !rounded-l-none"
            analyticsTrackingId="manage-schedule-options-click"
            icon={IconEnum.ChevronUp}
            iconProps={{
              size: IconSize.Medium,
              className: "text-content-white",
            }}
            title="Manage schedule options"
          />
        }
        align={"end"}
      >
        <DropdownMenuItem
          analyticsTrackingId={null}
          onSelect={handleSubmit}
          label={createMode ? "Create schedule" : "Save schedule"}
          icon={createMode ? IconEnum.Add : IconEnum.Checkmark}
          iconProps={{
            size: IconSize.Medium,
          }}
        />
        <DropdownMenuItem
          analyticsTrackingId={null}
          onSelect={openTerraformDrawer}
          disabled={scheduleName === ""}
          tooltipContent={
            scheduleName === ""
              ? createMode
                ? "Please enter a schedule name before creating in Terraform"
                : "Please enter a schedule name before exporting to Terraform"
              : undefined
          }
          icon={IconEnum.Terraform}
          label={createMode ? "Create in Terraform" : "Export to Terraform"}
          iconProps={{
            size: IconSize.Small,
          }}
        >
          {createMode ? "Create in Terraform" : "Export to Terraform"}
        </DropdownMenuItem>
      </DropdownMenu>
    </div>
  );
};

export const buildPreviewRotas = (
  entries: ScheduleEntry[],
  rotas: RotaFormData[],
): ScheduleRotation[] => {
  return rotas.map((rota): ScheduleRotation & { isDraft: boolean } => {
    return {
      name: rota.name,
      isDraft: true,
      layers: _.chain(entries)
        .filter((e) => e.rotation_id === rota.id)
        .map((e) => ({ id: e.layer_id ?? "" }))
        .uniqBy("id")
        .value(),
      id: rota.id as string,
      // None of the below matters for the preview, we've already got the entries
      handover_start_at: new Date(),
      working_intervals: [],
      effective_from: rota.effective_from,
      user_ids: [],
      handovers: [
        {
          interval: 1,
          interval_type: ScheduleRotationHandoverIntervalTypeEnum.Weekly,
        },
      ],
    };
  });
};
