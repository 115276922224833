import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { ErrorMessage } from "@incident-ui";
import { Button, ButtonTheme, LoadingBar, Tooltip } from "@incident-ui";
import { ToastTheme } from "@incident-ui/Toast/Toast";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import _, { trim } from "lodash";
import pluralize from "pluralize";
import React from "react";
import { useForm } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import {
  ScopeNameEnum,
  StatusPage,
  StatusPageConfigureSubPageUptimesRequestBodyDisplayUptimeModeEnum as RequestDisplayUptimeModeEnum,
  StatusPageDisplayUptimeModeEnum as DisplayUptimeModeEnum,
  StatusPagePageTypeEnum,
  StatusPageStructure,
  StatusPageThemeEnum,
} from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPI, useAPIMutation } from "src/utils/swr";

import { ChooseUptimeDisplayMode } from "../common/ComponentsEditor/ChooseUptimeDisplayMode";
import { Preview } from "../common/ComponentsEditor/Preview";
import { getParentPreviewItems } from "../create/BrandingForm";
import { SubPageViewComponentPreview } from "../sub-pages/view/SubPageViewComponentPreview";

type ConfigureUptimeData = {
  display_uptime_mode: DisplayUptimeModeEnum;
};

export const ParentStructureViewPage = ({
  page,
  structure,
}: {
  page: StatusPage;
  structure: StatusPageStructure;
}): React.ReactElement | null => {
  const {
    data: { catalog_types: catalogTypes },
    isLoading: isLoadingTypes,
  } = useAPI("catalogListTypes", {}, { fallbackData: { catalog_types: [] } });

  const splitByCatalogType = catalogTypes?.find(
    (type) => type.id === page.split_by_catalog_type_id,
  );

  const componentCatalogType = catalogTypes?.find(
    (type) => type.id === page.component_catalog_type_id,
  );

  const formMethods = useForm<ConfigureUptimeData>({
    defaultValues: {
      display_uptime_mode: page.display_uptime_mode,
    },
  });
  const displayUptimeMode = formMethods.watch("display_uptime_mode");

  const showToast = useToast();
  const { trigger, isMutating, genericError } = useAPIMutation(
    "statusPageShow",
    { id: page.id },
    async (apiClient, { display_uptime_mode }: ConfigureUptimeData) => {
      await apiClient.statusPageConfigureSubPageUptimes({
        id: page.id,
        configureSubPageUptimesRequestBody: {
          display_uptime_mode:
            display_uptime_mode as unknown as RequestDisplayUptimeModeEnum,
        },
      });

      // Trigger a revalidate
      return;
    },
    {
      setError: formMethods.setError,
      onSuccess: ({ status_page }) => {
        formMethods.reset({
          display_uptime_mode: status_page.display_uptime_mode,
        });

        showToast({
          theme: ToastTheme.Success,
          title: "Success",
          description: "Successfully saved component settings",
        });
      },
      onError: () => {
        showToast({
          theme: ToastTheme.Error,
          title: "Error",
          description: "Error saving component settings",
        });
      },
    },
  );

  const { data: subPageData } = useAPI("statusPageShow", {
    id: page.id,
  });

  const subPages = _.compact(
    subPageData?.subpages?.map((subpage) => ({
      name: subpage.name,
      defined_by_catalog_entry_id: subpage.defined_by_catalog_entry_id,
    })),
  );

  const previewItems = getParentPreviewItems(structure, displayUptimeMode);

  const { hasScope } = useIdentity();
  const missingPermission = !hasScope(ScopeNameEnum.StatusPagesConfigure);

  const isCustomerPage = page.page_type === StatusPagePageTypeEnum.Customer;

  return (
    <>
      <div className="bg-surface-secondary rounded-[6px] p-4 border border-stroke">
        <h3 className="font-medium mb-2">Components</h3>

        {isLoadingTypes ? (
          <LoadingBar />
        ) : (
          <>
            <Form.Helptext>
              Components help your customers understand what parts of your
              system are impacted. To update the components displayed and their
              order, edit the catalog type directly.
            </Form.Helptext>

            <div className="flex flex-wrap gap-2">
              <Tooltip
                content={
                  <>
                    Components are managed by Catalog. To add a new component,
                    create a new {componentCatalogType?.name} in the Catalog.
                  </>
                }
              >
                <Button
                  href={`/catalog/${componentCatalogType?.id}/create`}
                  className="mb-4 mt-2"
                  title="Edit catalog type"
                  analyticsTrackingId="status-page-catalog-type-internal-link"
                >
                  Add component
                </Button>
              </Tooltip>

              <Tooltip
                content={
                  <>
                    To change which components impact a{" "}
                    {splitByCatalogType?.name}, update the{" "}
                    {splitByCatalogType?.name} catalog type.
                  </>
                }
              >
                <Button
                  href={`/catalog/${splitByCatalogType?.id}`}
                  className="mb-4 mt-2"
                  title="Edit catalog type"
                  analyticsTrackingId="status-page-catalog-type-internal-link"
                >
                  Edit{" "}
                  {pluralize(
                    trim(splitByCatalogType?.name?.toLowerCase() ?? ""),
                  )}
                </Button>
              </Tooltip>
            </div>

            <div className="">
              <p className="font-medium text-sm mb-2">Preview</p>
              <SubPageViewComponentPreview
                subPageItems={subPages}
                catalogTypes={catalogTypes}
                splitByCatalogTypeId={page.split_by_catalog_type_id || ""}
                splitByAttributeId={page.split_by_component_attribute_id || ""}
                componentCatalogType={componentCatalogType}
              />
            </div>
          </>
        )}
      </div>

      <div className="bg-surface-secondary rounded-[6px] p-4 border border-stroke">
        <h3 className="font-medium mb-2">Component uptimes</h3>
        <Form.Helptext>
          Choose how you&apos;d like to display historical incident data and
          uptime figures for this status page. This decision will apply to all
          components across all of your{" "}
          {isCustomerPage ? "customer pages" : "sub-pages"}.
        </Form.Helptext>

        <Form.Root
          formMethods={formMethods}
          outerClassName="mb-4"
          onSubmit={trigger}
          saving={isMutating}
        >
          <ChooseUptimeDisplayMode
            formMethods={formMethods}
            name="display_uptime_mode"
            label=""
            helptext=""
          />
          <p className="font-medium text-sm mb-2">Preview</p>
          <div className="rounded-[6px] bg-surface-tertiary p-6">
            <Preview
              items={previewItems || []}
              theme={StatusPageThemeEnum.Light}
              showWarning
            />
          </div>
          <ErrorMessage message={genericError} />

          <GatedButton
            type="submit"
            analyticsTrackingId={null}
            className="mb-6 md:mb-0 mt-4 md:mt-0"
            theme={ButtonTheme.Primary}
            disabled={missingPermission || !formMethods.formState.isDirty}
            disabledTooltipContent={
              missingPermission
                ? "You do not have permission to configure this public status page"
                : undefined
            }
          >
            Save
          </GatedButton>
        </Form.Root>
      </div>
    </>
  );
};
