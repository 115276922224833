import { ExpressionFormData } from "@incident-shared/engine/expressions/expressionToPayload";
import { IconEnum } from "@incident-ui";
import { useMemo } from "react";
import { FieldValues, Path } from "react-hook-form";
import { EngineScope, Resource, useClient } from "src/contexts/ClientContext";
import {
  assertUnreachable,
  sendToSentry,
  uppercaseFirstLetterOnly,
} from "src/utils/utils";

import { getEngineTypeaheadOptions } from "../helpers";
import { MultiValueEngineFormElement } from "./MultiValueEngineFormElement";
import { SingleValueEngineFormElement } from "./SingleValueEngineFormElement";

type EngineFormElementMode =
  | "plain_input"
  | "variables_only"
  | "variables_and_expressions"
  | "expressions_only_no_static_value";

const shouldInclude = (
  mode: EngineFormElementMode,
): {
  includeStatic: boolean;
  includeVariables: boolean;
  includeExpressions: boolean;
} => {
  switch (mode) {
    case "plain_input":
      return {
        includeStatic: true,
        includeVariables: false,
        includeExpressions: false,
      };
    case "variables_only":
      return {
        includeStatic: true,
        includeVariables: true,
        includeExpressions: false,
      };
    case "variables_and_expressions":
      return {
        includeStatic: true,
        includeVariables: true,
        includeExpressions: true,
      };
    case "expressions_only_no_static_value":
      return {
        includeStatic: false,
        includeVariables: false,
        includeExpressions: true,
      };
    default:
      assertUnreachable(mode);
      return {
        includeStatic: true,
        includeVariables: false,
        includeExpressions: false,
      };
  }
};

export type EngineModeProps =
  | {
      mode:
        | "variables_only"
        | "variables_and_expressions"
        | "expressions_only_no_static_value";
      scope: EngineScope;
    }
  | {
      mode: "plain_input";
      scope?: never;
    };

export type EngineFormElementProps<FormType> = {
  name: Path<FormType>;
  label?: string;
  labelNode?: React.ReactNode;
  labelAccessory?: React.ReactNode;
  showPlaceholder?: boolean;
  description?: string;
  resourceType: string;
  array: boolean;
  resources: Resource[];
  required: boolean;
  disabled?: boolean;
  disabledTooltipContent?: React.ReactNode;
  suffixNode?: React.ReactNode;
  className?: string;
  isAlertElement?: boolean;
  onEditExpression?: (expression: ExpressionFormData) => void;
  onDeleteExpression?: (expression: ExpressionFormData) => void;
  onClickVariableButton?: () => void;
  expressionLabelOverride?: string;
  optionIconOverride?: IconEnum;
} & EngineModeProps;

export const EngineFormElement = <FormType extends FieldValues>({
  name,
  label,
  labelNode,
  labelAccessory,
  showPlaceholder,
  array,
  resourceType,
  description,
  scope,
  resources,
  required,
  disabled = false,
  disabledTooltipContent,
  mode,
  suffixNode,
  className,
  isAlertElement,
  onDeleteExpression,
  onEditExpression,
  onClickVariableButton,
  expressionLabelOverride,
  optionIconOverride,
}: EngineFormElementProps<FormType>): React.ReactElement => {
  const apiClient = useClient();
  const resource = resources.find((res) => res.type === resourceType);
  if (!resource) {
    throw sendToSentry(
      `unreachable: cannot render ConditionParamDynamicFormElement as resource config for ${resourceType} wasn't present.`,
      {
        resourceType,
        resources,
      },
    );
  }

  const { includeVariables, includeExpressions, includeStatic } =
    shouldInclude(mode);

  const scopeAndIsAlert = useMemo(
    () => ({
      scope,
      isAlertElement,
    }),
    [scope, isAlertElement],
  );

  const sharedProps = {
    includeVariables,
    includeExpressions,
    includeStatic,
    resources,
    resource,
    label,
    labelNode,
    labelAccessory,
    helptext: description,
    scopeAndIsAlert,
    required,
    disabled,
    disabledTooltipContent,
    className,
    suffixNode,
    expressionLabelOverride,
    optionIconOverride,
  };
  const loadTypeaheadOptions = useMemo(
    () => getEngineTypeaheadOptions(apiClient, resource.type),
    [apiClient, resource.type],
  );

  // This looks really bizarre, but it works! Without this, something inside
  // react thinks these two elements are the same, and they end up sharing state
  // in a really confusing way when we swap them out in the conditions form.
  // It ends up passing values between the form elements as we render different elements,
  // which is clearly undesirable (e.g. having a single select with multiple values).
  return (
    <>
      {array && (
        <MultiValueEngineFormElement<FormType>
          // ReactSelect has a nasty bug where it won't ever reload your options, even if the
          // loadOptions function changes.
          // This means that if we have an engine form element which is dynamically rerendered
          // based on a value in state, we need to make sure that the options are actually
          // reloaded, and the value is rerendered.
          // So, we hack this by setting a key based on the resource type and name, so when we
          // change this, we'll reload our options.
          key={resource.type + name + ".array_value"}
          formFieldType={resource.field_config.array_type}
          {...sharedProps}
          placeholder={
            showPlaceholder
              ? uppercaseFirstLetterOnly(
                  resource.field_config.array_placeholder,
                )
              : ""
          }
          // @ts-expect-error this is ok
          name={`${name}.array_value`}
          loadTypeaheadOptions={loadTypeaheadOptions}
          onEditExpression={onEditExpression}
          onDeleteExpression={onDeleteExpression}
          onClickVariableButton={onClickVariableButton}
        />
      )}
      {!array && (
        <SingleValueEngineFormElement<FormType>
          {...sharedProps}
          key={resource.type + name + ".value"}
          formFieldType={resource.field_config.type}
          // @ts-expect-error this is ok
          name={`${name}.value`}
          placeholder={
            showPlaceholder
              ? uppercaseFirstLetterOnly(resource.field_config.placeholder)
              : ""
          }
          onEditExpression={onEditExpression}
          onDeleteExpression={onDeleteExpression}
          loadTypeaheadOptions={loadTypeaheadOptions}
          onClickVariableButton={onClickVariableButton}
        />
      )}
    </>
  );
};
