import {
  StatusPage,
  StatusPageIncident,
  StatusPageStructure,
  StatusPageSubPageSlim,
} from "@incident-io/api";
import { SidebarDivider } from "src/components/legacy/incident/sidebar/IncidentSidebar";
import { tcx } from "src/utils/tailwind-classes";

import { AffectedComponentsOverview } from "./AffectedComponents";
import { AffectedSubpages } from "./AffectedSubPages";
import { LinkedInternalIncidents } from "./LinkedInternalIncidents";
import { PublicWriteupCreateModal } from "./PublicWriteupCreateModal";

export const IncidentSidebar = ({
  page,
  structure,
  incident,
  className,
}: {
  page: StatusPage;
  structure: StatusPageStructure;
  subPages?: StatusPageSubPageSlim[];
  incident: StatusPageIncident;
  className?: string;
}): React.ReactElement => {
  return (
    <aside className={tcx("w-80 min-w-80", className)}>
      <dl className="pb-16 space-y-4">
        {incident.affected_components.length ? (
          <>
            <AffectedComponentsOverview
              incident={incident}
              structure={structure}
            />
            <SidebarDivider />
          </>
        ) : null}

        {incident.sub_pages?.length ? (
          <>
            <AffectedSubpages pageType={page.page_type} incident={incident} />
            <SidebarDivider />
          </>
        ) : null}

        <LinkedInternalIncidents incident={incident} />

        <SidebarDivider />

        <PublicWriteupCreateModal incident={incident} />
      </dl>
    </aside>
  );
};
