import { Button, Heading, Txt } from "@incident-ui";
import React from "react";

export const NotFoundPage = ({
  cta,
}: {
  cta?: React.ReactElement;
}): React.ReactElement => {
  return (
    <div className="flex flex-col h-full min-h-full justify-center text-center gap-6">
      <div className="flex flex-col gap-2">
        <Txt bold className="text-brand">
          404
        </Txt>

        <Heading level={2} size="2xl">
          Page not found
        </Heading>

        <Txt grey>Sorry, we couldn&apos;t find what you&apos;re after.</Txt>
      </div>

      <div className="flex shrink justify-center">
        {cta ?? (
          <Button analyticsTrackingId="404-back-to-dashboard" href="/dashboard">
            Return to dashboard
          </Button>
        )}
      </div>
    </div>
  );
};
