import { EscalationPathNodeTypeEnum as NodeTypes } from "@incident-io/api";

import { PathNode } from "../../common/types";

// deleteNode is a helper function to delete a node from the tree, along with any downstream nodes
// that are no longer reachable (e.g. when deleting an if/else node, the else branch is also
// deleted).
export const deleteNode = ({
  nodeId,
  nodes,
  firstNodeId,
  updateNodes,
  updateFirstNodeId,
}: {
  nodeId: string;
  nodes: Record<string, PathNode>;
  firstNodeId: string;
  updateNodes: (nodes: Record<string, PathNode>) => void;
  updateFirstNodeId: (firstNodeId: string) => void;
}) => {
  const updatedNodes = { ...nodes };
  const deletedNode = updatedNodes[nodeId];

  // Remove the deleted node from the updated nodes object.
  delete updatedNodes[deletedNode.id];

  // If the deleted node is the current first node in the tree, then lets find the new first node
  // by looking at what the deleted node was pointing to. This is simple for level nodes, but for
  // if/else nodes we need to look at the thenNodeId.
  let newFirstNodeId = firstNodeId;
  if (deletedNode.id === firstNodeId) {
    switch (deletedNode.data.nodeType) {
      case NodeTypes.Level:
        if (!deletedNode.data.level || !deletedNode.data.level.nextNodeId) {
          throw new Error(
            "level node deleted, but level data or next node not found",
          );
        }
        newFirstNodeId = deletedNode.data.level.nextNodeId;
        break;

      case NodeTypes.NotifyChannel:
        if (
          !deletedNode.data.notifyChannel ||
          !deletedNode.data.notifyChannel.nextNodeId
        ) {
          throw new Error(
            "notifyChannel node deleted, but notifyChannel data or next node not found",
          );
        }
        newFirstNodeId = deletedNode.data.notifyChannel.nextNodeId;
        break;

      case NodeTypes.IfElse:
        if (!deletedNode.data.ifElse) {
          throw new Error("if/else node deleted, but if/else data not found");
        }
        newFirstNodeId = deletedNode.data.ifElse.thenNodeId;
        break;
    }
  }

  // We want to grab the child node that the deleted node is pointing to, so we can update any
  // references to the deleted node to point to the child node.
  let childNodeId: string | undefined;
  switch (deletedNode.data.nodeType) {
    case NodeTypes.Level:
      if (!deletedNode.data.level) {
        throw new Error("level node deleted, but level data not found");
      }
      childNodeId = deletedNode.data.level.nextNodeId;
      break;

    case NodeTypes.NotifyChannel:
      if (!deletedNode.data.notifyChannel) {
        throw new Error(
          "notifyChannel node deleted, but notifyChannel data not found",
        );
      }
      childNodeId = deletedNode.data.notifyChannel.nextNodeId;
      break;

    case NodeTypes.IfElse:
      if (!deletedNode.data.ifElse) {
        throw new Error("if/else node deleted, but if/else data not found");
      }
      childNodeId = deletedNode.data.ifElse.thenNodeId;
      break;
    case NodeTypes.Repeat:
      if (!deletedNode.data.repeat) {
        throw new Error("repeat node deleted, but repeat data not found");
      }
      break;
  }

  // If the deleted node is an if/else node, then delete all nodes in the else branch.
  if (
    deletedNode.data.nodeType === NodeTypes.IfElse &&
    deletedNode.data.ifElse
  ) {
    deleteBranch(deletedNode.data.ifElse.elseNodeId, updatedNodes);
  }

  // Update any nodes that reference the deleted node.
  Object.values(updatedNodes).forEach((node) => {
    // Level nodes → If a level node still present in the tree references the deleted node as it's
    // next node.
    if (
      node.data.nodeType === NodeTypes.Level &&
      node.data.level &&
      node.data.level.nextNodeId === deletedNode.id
    ) {
      // childNodeId can be undefined if the deleted node was the last node in the tree.
      node.data.level.nextNodeId = childNodeId;
    }

    // Channel nodes → If a channel node still present in the tree references the deleted node as it's
    // next node.
    if (
      node.data.nodeType === NodeTypes.NotifyChannel &&
      node.data.notifyChannel &&
      node.data.notifyChannel.nextNodeId === deletedNode.id
    ) {
      node.data.notifyChannel.nextNodeId = childNodeId;
    }

    // Repeat nodes → If a repeat node still present in the tree referenced the deleted node, as the
    // deleted node was the first in tree, then update the repeat node to reference the new first
    // node.
    if (
      node.data.nodeType === NodeTypes.Repeat &&
      node.data.repeat &&
      node.data.repeat.to_node === deletedNode.id &&
      newFirstNodeId
    ) {
      node.data.repeat.to_node = newFirstNodeId;
    }

    // If/Else nodes → If an if/else node still present in the tree referenced the deleted node,
    // then update the if/else node to reference the new first node within the relevant branch.
    if (
      node.data.nodeType === NodeTypes.IfElse &&
      node.data.ifElse &&
      childNodeId
    ) {
      if (node.data.ifElse.thenNodeId === deletedNode.id) {
        node.data.ifElse.thenNodeId = childNodeId;
      }

      if (node.data.ifElse.elseNodeId === deletedNode.id) {
        node.data.ifElse.elseNodeId = childNodeId;
      }
    }
  });

  // Update the firstNodeId in the form state, if it has changed.
  if (newFirstNodeId !== firstNodeId) {
    updateFirstNodeId(newFirstNodeId);
  }

  // Update the form state of the nodes in the tree via the provided function.
  updateNodes(updatedNodes);
};

// deleteBranch is a helper function to recursively delete all nodes in a branch.
const deleteBranch = (nodeId: string, nodes: Record<string, PathNode>) => {
  const node = nodes[nodeId];

  // If the node doesn't exist, return early.
  if (!node) {
    return;
  }

  // Delete the node from the nodes object.
  delete nodes[nodeId];

  // If the node is an if/else node, then delete all nodes in both the then and else branches.
  if (node.data.nodeType === NodeTypes.IfElse && node.data.ifElse) {
    deleteBranch(node.data.ifElse.thenNodeId, nodes);
    deleteBranch(node.data.ifElse.elseNodeId, nodes);
  }

  // If the node is a level node, then delete the next node in the branch.
  if (
    node.data.nodeType === NodeTypes.Level &&
    node.data.level &&
    node.data.level.nextNodeId
  ) {
    deleteBranch(node.data.level.nextNodeId, nodes);
  }

  // If the node is a channel node, then delete the next node in the branch.
  if (
    node.data.nodeType === NodeTypes.NotifyChannel &&
    node.data.notifyChannel &&
    node.data.notifyChannel.nextNodeId
  ) {
    deleteBranch(node.data.notifyChannel.nextNodeId, nodes);
  }
};
