import { EscalationPreview, Incident } from "@incident-io/api";
import {
  Button,
  ButtonTheme,
  Heading,
  IconEnum,
  IconSize,
  StackedList,
} from "@incident-ui";
import React, { useState } from "react";
import { useIsWithinMicrosoftTeamsTab } from "src/contexts/MicrosoftTeamsTabContext";
import { useAPI } from "src/utils/swr";

import { EscalationDrawer } from "../../../escalations/EscalateDrawer";
import { EscalationStackedListItem } from "../../../escalations/EscalationStackedListItem";

export function IncidentEscalations({
  incident,
}: {
  incident: Incident;
}): React.ReactElement | null {
  const [showEscalationModal, setShowEscalationModal] = useState(false);
  const withinTeamsTab = useIsWithinMicrosoftTeamsTab();
  const { data: providerData } = useAPI("escalationsListProviders", undefined, {
    fallbackData: {
      providers: [],
    },
  });

  const { data: escalationsListResponse, isLoading: escalationsListLoading } =
    useAPI("escalationsList", {
      incident: {
        one_of: [incident.id],
      },
    });

  const allEscalations = escalationsListResponse?.escalations ?? [];

  // Only show up to 5
  const maxEscalationsToShow = 5;
  const escalations = allEscalations.slice(0, maxEscalationsToShow);

  return (
    <div className="space-y-2">
      <div className="flex gap-2 items-center mb-2">
        <Heading level={3} size="small">
          Escalations
        </Heading>
      </div>

      {escalationsListLoading ? null : (
        <StackedList>
          {escalations.map((escalation: EscalationPreview) => (
            <EscalationStackedListItem
              key={escalation.id}
              escalation={escalation}
              hrefTarget={withinTeamsTab ? "_blank" : undefined}
            />
          ))}
        </StackedList>
      )}
      {
        // If you have more than 5, just say and 5 more
        allEscalations.length > maxEscalationsToShow && (
          <div className="flex justify-between">
            <span className="hover:!text-content-primary truncate mr-2">
              ...and {allEscalations.length - maxEscalationsToShow} more
            </span>
          </div>
        )
      }
      {providerData.providers.length > 0 && (
        <div>
          <Button
            icon={IconEnum.Escalate}
            iconProps={{
              size: IconSize.Medium,
            }}
            theme={ButtonTheme.Naked}
            onClick={() => {
              setShowEscalationModal(true);
            }}
            analyticsTrackingId="add-related-incident"
            className="-ml-0.5 mt-2"
          >
            Escalate to someone
          </Button>
        </div>
      )}
      {showEscalationModal && (
        <EscalationDrawer
          onClose={() => setShowEscalationModal(false)}
          incidentId={incident.id}
          showDeclareIncident={false}
          shouldWarnWhenDirty={true}
        />
      )}
    </div>
  );
}
