import {
  AlertSource,
  AlertSourceConfig,
  AlertsTransitionIncidentAlertRequestBodyStateEnum,
  Incident,
  IncidentAlert,
  IncidentAlertStateEnum,
} from "@incident-io/api";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { ALERT_SOURCE_TYPE_CONFIGS } from "@incident-shared/integrations";
import {
  Button,
  ButtonSize,
  ButtonTheme,
  Checkbox,
  GenericErrorMessage,
  Heading,
  Icon,
  IconEnum,
  OrgAwareLink,
  StackedList,
} from "@incident-ui";
import {
  Drawer,
  DrawerBody,
  DrawerContents,
  DrawerTitle,
} from "@incident-ui/Drawer/Drawer";
import { LoadingBar } from "@incident-ui/LoadingBar/LoadingBar";
import { LocalRelativeDateTime } from "@incident-ui/LocalDateTime/LocalRelativeDateTime";
import { ToastTheme } from "@incident-ui/Toast/Toast";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { captureException } from "@sentry/core";
import { motion } from "framer-motion";
import React, { useState } from "react";
import { AlertSourceDetails } from "src/components/alerts/common/AlertSourceDetails";
import { useNavigateToModal } from "src/utils/query-params";
import { useAPI, useAPIMutation } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";

import { useAlertResources } from "../../../alerts/common/useAlertResources";
import { useIncident } from "../hooks";
import {
  IncidentSidebarList,
  IncidentSidebarListItemProps,
} from "./IncidentSidebarList";

export function IncidentAlertsSidebarList({
  incident,
}: {
  incident: Incident;
}): React.ReactElement | null {
  const navigateToDrawer = useNavigateToModal();

  const {
    data: listAlertsResponse,
    isLoading: incidentAlertsLoading,
    error: listAlertsError,
  } = useAPI("alertsListIncidentAlerts", { incidentId: incident.id }, {});

  if (incidentAlertsLoading || !listAlertsResponse) {
    return <LoadingBar className="h-18" />;
  }

  if (listAlertsError) {
    captureException(listAlertsError);
    return <GenericErrorMessage error={listAlertsError} />;
  }

  const pendingAlerts = listAlertsResponse.incident_alerts.filter(
    (ia) => ia.state === IncidentAlertStateEnum.Pending,
  );

  const relatedAlerts = listAlertsResponse.incident_alerts.filter(
    (ia) => ia.state === IncidentAlertStateEnum.Related,
  );

  // Build the props for each item in the list
  const sidebarItems: IncidentSidebarListItemProps[] = [];

  relatedAlerts.forEach((incidentAlert) => {
    const config = ALERT_SOURCE_TYPE_CONFIGS[incidentAlert.alert.source_type];

    sidebarItems.push({
      title: incidentAlert.alert.title,
      icon: config.icon,
      iconClassName: config.className,
      url: `/alerts/${incidentAlert.alert.id}/details`,
    });
  });

  // If there's pending alerts, show a button to view them
  const viewPendingAlertsButton = pendingAlerts.length ? (
    <Button
      analyticsTrackingId="view-pending-alerts"
      icon={IconEnum.Alert}
      size={ButtonSize.Small}
      onClick={() => navigateToDrawer("alerts")}
    >
      View {pendingAlerts.length} pending alert
      {pendingAlerts.length > 1 ? "s" : ""}
    </Button>
  ) : undefined;

  return (
    <IncidentSidebarList
      title="Alerts"
      viewAllUrl={"alerts"}
      items={sidebarItems}
      accessory={viewPendingAlertsButton}
    />
  );
}

export const IncidentAlertsDrawer = ({
  incident,
  onClose,
}: {
  incident: Incident;
  onClose: () => void;
}): React.ReactElement | null => {
  const {
    error: resourcesError,
    isLoading: resourcesLoading,
    schemaResponse,
    sourcesResponse,
    configsResp,
    resourcesListResp,
  } = useAlertResources();

  const {
    data: listAlertsResponse,
    isLoading: incidentAlertsLoading,
    error: listAlertsError,
  } = useAPI("alertsListIncidentAlerts", { incidentId: incident.id }, {});

  const [selectedPendingIncidentAlertIDs, setSelectedPendingIncidentAlertIDs] =
    useState<string[]>([]);

  if (
    !incident ||
    incidentAlertsLoading ||
    resourcesLoading ||
    !listAlertsResponse ||
    !schemaResponse ||
    !sourcesResponse ||
    !resourcesListResp ||
    !configsResp
  ) {
    return <LoadingBar className="h-18" />;
  }

  const err = resourcesError || listAlertsError;
  if (err) {
    captureException(err);
    return <GenericErrorMessage error={err} />;
  }

  const pendingAlerts = listAlertsResponse.incident_alerts.filter(
    (ia) => ia.state === IncidentAlertStateEnum.Pending,
  );

  const relatedAlerts = listAlertsResponse.incident_alerts.filter(
    (ia) => ia.state === IncidentAlertStateEnum.Related,
  );

  return (
    <>
      <Drawer onClose={onClose} width="medium">
        <DrawerContents>
          <DrawerTitle
            icon={IconEnum.Alert}
            onClose={onClose}
            title={"Alerts"}
          />
          <DrawerBody className="overflow-y-auto space-y-4">
            {pendingAlerts.length > 0 && (
              <div className={"flex flex-col"}>
                <div className="flex flex-row">
                  <div className="text-content-tertiary text-sm pb-2">
                    These alerts have been grouped into this incident because of
                    alert routing. You should confirm that they are related, or
                    handle them in a separate incident.
                  </div>
                </div>
                <IncidentAlerts
                  linkToAlerts
                  context={"tab"}
                  kind={"pending"}
                  incidentId={incident.id}
                  alertSources={sourcesResponse.alert_sources}
                  alertSourceConfigs={configsResp.alert_source_configs}
                  incidentAlerts={pendingAlerts}
                  setSelectedIncidentAlertIDs={
                    setSelectedPendingIncidentAlertIDs
                  }
                  selectedIncidentAlertIDs={selectedPendingIncidentAlertIDs}
                />
              </div>
            )}
            <div>
              <Heading level={3} className={"mb-1"}>
                Attached alerts
              </Heading>
              <IncidentAlerts
                linkToAlerts
                context={"tab"}
                kind={"related"}
                incidentId={incident.id}
                alertSources={sourcesResponse.alert_sources}
                alertSourceConfigs={configsResp.alert_source_configs}
                incidentAlerts={relatedAlerts}
                setSelectedIncidentAlertIDs={setSelectedPendingIncidentAlertIDs}
                selectedIncidentAlertIDs={selectedPendingIncidentAlertIDs}
              />
            </div>
          </DrawerBody>
        </DrawerContents>
      </Drawer>
    </>
  );
};

export const IncidentAlerts = ({
  linkToAlerts = true,
  context,
  kind,
  incidentId,
  incidentAlerts,
  alertSources,
  alertSourceConfigs,
  selectedIncidentAlertIDs,
  setSelectedIncidentAlertIDs,
}: {
  linkToAlerts?: boolean;
  // context should be a unique context in which we display these alerts on screen at once.
  // we use it to determine whether alerts are HTML links, and to create unique IDs for checkboxes.
  context: "modal" | "tab";
  kind: "pending" | "related";
  incidentId: string | null;
  alertSourceConfigs: AlertSourceConfig[];
  alertSources: AlertSource[];
  incidentAlerts: IncidentAlert[];
  selectedIncidentAlertIDs: string[];
  setSelectedIncidentAlertIDs: (ids: string[]) => void;
}) => {
  const showToast = useToast();
  const { incident } = useIncident(incidentId);

  let transitionState =
    AlertsTransitionIncidentAlertRequestBodyStateEnum.Related;
  const { trigger: onTransition, isMutating: transitioningAlerts } =
    useAPIMutation(
      "alertsListIncidentAlerts",
      { incidentId: incident?.id ?? "" },
      async (
        apiClient,
        {
          alertIDs,
          state,
        }: {
          alertIDs: string[];
          state: AlertsTransitionIncidentAlertRequestBodyStateEnum;
        },
      ) => {
        transitionState = state;
        const selectedIncidentAlerts = incidentAlerts.filter((ia) =>
          alertIDs.includes(ia.id),
        );
        if (!selectedIncidentAlerts) {
          return;
        }
        for (const incidentAlert of selectedIncidentAlerts) {
          await apiClient.alertsTransitionIncidentAlert({
            id: incidentAlert.id,
            transitionIncidentAlertRequestBody: {
              state: state,
            },
          });
        }
      },
      {
        onSuccess: () => {
          setSelectedIncidentAlertIDs([]);
          showToast({
            theme: ToastTheme.Success,
            title: `Successfully marked alerts as ${transitionState}`,
          });
        },
        onError: () => {
          showToast({
            theme: ToastTheme.Error,
            title: `Could not mark alerts as ${transitionState}`,
          });
        },
      },
    );

  const onHandle = (
    state: AlertsTransitionIncidentAlertRequestBodyStateEnum,
  ) => {
    onTransition({ alertIDs: selectedIncidentAlertIDs, state });
  };

  const enableSelection = kind === "pending";

  if (!incident) {
    return null;
  }

  return (
    <div>
      <StackedList>
        {enableSelection && (
          <div
            className={
              "flex flex-row items-center bg-surface-secondary min-h-[50px]"
            }
          >
            <Checkbox
              id={"select-all-incident-alerts"}
              className={"px-2 mx-1 my-2 rounded z-50"}
              onChange={() => {
                if (selectedIncidentAlertIDs.length === incidentAlerts.length) {
                  setSelectedIncidentAlertIDs([]);
                } else {
                  setSelectedIncidentAlertIDs(
                    incidentAlerts.map((ia) => ia.id),
                  );
                }
              }}
              checked={
                selectedIncidentAlertIDs.length === incidentAlerts.length
              }
            />
            <div
              onClick={() => {
                if (selectedIncidentAlertIDs.length === incidentAlerts.length) {
                  setSelectedIncidentAlertIDs([]);
                } else {
                  setSelectedIncidentAlertIDs(
                    incidentAlerts.map((ia) => ia.id),
                  );
                }
              }}
            >
              <div className="font-semibold text-content-primary">
                Pending alerts
              </div>
            </div>
            <div className="ml-auto mr-2">
              {selectedIncidentAlertIDs.length > 0 ? (
                <IncidentAlertActions
                  incidentNumberID={incident.external_id}
                  onClick={onHandle}
                />
              ) : null}
            </div>
          </div>
        )}
        {transitioningAlerts ? (
          <LoadingBar className="h-18" />
        ) : (
          <div className={"overflow-auto"}>
            {incidentAlerts.map((ia) => {
              const alertSourceConfig = alertSourceConfigs.find(
                (c) => c.id === ia.alert.alert_source_config_id,
              );
              const alertSource = alertSources.find(
                (s) => s.source_type === alertSourceConfig?.source_type,
              );

              return (
                <IncidentAlertListItem
                  key={ia.id}
                  linkToAlert={linkToAlerts}
                  context={context}
                  incidentAlert={ia}
                  selectedIncidentAlertIDs={selectedIncidentAlertIDs}
                  setSelectedIncidentAlertIDs={setSelectedIncidentAlertIDs}
                  alertSource={alertSource}
                  alertSourceConfig={alertSourceConfig}
                  enableSelection={enableSelection}
                />
              );
            })}
          </div>
        )}
      </StackedList>
    </div>
  );
};

export const IncidentAlertListItem = ({
  context,
  linkToAlert = true,
  incidentAlert,
  alertSourceConfig,
  alertSource,
  selectedIncidentAlertIDs,
  setSelectedIncidentAlertIDs,
  enableSelection,
}: {
  context?: "modal" | "tab";
  linkToAlert?: boolean;
  allowOpeningAlerts?: boolean;
  incidentAlert: IncidentAlert;
  alertSourceConfig?: AlertSourceConfig;
  selectedIncidentAlertIDs: string[];
  setSelectedIncidentAlertIDs: (ids: string[]) => void;
  enableSelection?: boolean;
  alertSource: AlertSource | undefined;
}) => {
  const LinkWrapper = linkToAlert ? OrgAwareLink : "div";

  const alert = incidentAlert.alert;
  return (
    <li
      key={incidentAlert.id}
      className={tcx("list-none flex flex-row", "group/incident", {
        "bg-surface-secondary": selectedIncidentAlertIDs.includes(
          incidentAlert.id,
        ),
      })}
    >
      {enableSelection && (
        <span className="hidden h-full md:block my-auto">
          <Checkbox
            // We set the id to include the context, otherwise default HTML behavior means that if you click on the
            // label of the checkbox, it might not toggle the checkbox you want.
            id={`${incidentAlert.id}-${context}`}
            className={"px-2 mx-1 my-2 rounded py-5 z-50"}
            onChange={() => {
              if (selectedIncidentAlertIDs.includes(incidentAlert.id)) {
                setSelectedIncidentAlertIDs(
                  selectedIncidentAlertIDs.filter(
                    (id) => id !== incidentAlert.id,
                  ),
                );
              } else {
                setSelectedIncidentAlertIDs([
                  ...selectedIncidentAlertIDs,
                  incidentAlert.id,
                ]);
              }
            }}
            checked={selectedIncidentAlertIDs.includes(incidentAlert.id)}
          />
        </span>
      )}
      <div
        className={tcx(
          "w-full min-w-0 flex-center-y",
          {
            "pl-4 py-4": !enableSelection,
            "pl-4 md:pl-0": enableSelection,
          },
          "flex flex-row text-content-primary text-sm",
        )}
      >
        <div
          className={
            "w-full min-w-0 space-y-1 overflow-hidden text-ellipsis line-clamp-1"
          }
        >
          <LinkWrapper
            to={`/alerts/${alert.id}/details`}
            analyticsTrackingId={"click-alert-from-incident"}
            className={linkToAlert ? "hover:underline" : ""}
          >
            <div className="font-regular items-center text-content-primary flex min-w-0">
              <div className="xl:max-w-2xl l:truncate xl:line-clamp-none xl:!block line-clamp-1 font-semibold">
                {alert.title}
              </div>
            </div>
          </LinkWrapper>
          <div
            className={
              "flex gap-4 flex-row text-slate-600 text-sm items-center"
            }
          >
            {alertSourceConfig && alertSource && (
              <AlertSourceDetails
                sourceType={incidentAlert.alert.source_type}
                alertSource={alertSource}
                alert={incidentAlert.alert}
              />
            )}
            <div className="inline-flex gap-1">
              <Icon id={IconEnum.Clock} className={"text-slate-400"} />{" "}
              <LocalRelativeDateTime
                date={alert.created_at}
                className={"hover:!no-underline whitespace-nowrap"}
              />
            </div>
          </div>
        </div>
      </div>
    </li>
  );
};

export const IncidentAlertActions = ({
  incidentNumberID,
  onClick,
  disabled,
  loading,
}: {
  disabled?: boolean;
  loading?: boolean;
  incidentNumberID: number;
  onClick: (state: AlertsTransitionIncidentAlertRequestBodyStateEnum) => void;
}) => {
  return (
    <motion.div
      initial={{ x: "1%", opacity: 0.1 }}
      animate={{ x: "0px", opacity: 1 }}
      transition={{
        duration: 0.1,
      }}
    >
      <div className="flex flex-row gap-2">
        <GatedButton
          loading={loading}
          analyticsTrackingId="mark-alert-unrelated"
          title="Mark as unrelated"
          disabled={disabled}
          theme={ButtonTheme.Secondary}
          size={ButtonSize.Small}
          onClick={() =>
            onClick(AlertsTransitionIncidentAlertRequestBodyStateEnum.Unrelated)
          }
        >
          Mark as unrelated
        </GatedButton>
        <GatedButton
          loading={loading}
          analyticsTrackingId="mark-alert-related"
          title="Mark as related"
          theme={ButtonTheme.Primary}
          size={ButtonSize.Small}
          disabled={disabled}
          onClick={() =>
            onClick(AlertsTransitionIncidentAlertRequestBodyStateEnum.Related)
          }
        >
          Attach to INC-{incidentNumberID}
        </GatedButton>
      </div>
    </motion.div>
  );
};
