import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import {
  BadgeSize,
  BadgeTheme,
  Button,
  ButtonSize,
  ButtonTheme,
  EmptyState,
  IconEnum,
  StackedList,
  Txt,
} from "@incident-ui";
import { ProductMarketingBanner } from "@incident-ui/ProductMarketingBanner/ProductMarketingBanner";
import _, { uniq } from "lodash";
import React from "react";
import {
  PoliciesDestroyRequest,
  Policy,
  PolicyPolicyTypeEnum,
  PolicyReportSchedule,
  RequirementsTemplatesForType,
  ScopeNameEnum,
} from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPIMutation } from "src/utils/swr";

import { SettingsListItem } from "../../../@shared/settings/SettingsList/SettingsListItem";
import { PlanBadge } from "../../PlanBadge";
import { SettingsSubHeading } from "../../SettingsSubHeading";
import { useGetPolicyTypeConfig } from "../common/config";
import {
  PolicyChooseTypeModal,
  useAvailablePolicyTypes,
} from "../create-edit/PolicyChooseTypeModal";

export const PolicyList = ({
  policies,
  reportSchedules,
  requirementsTemplates,
}: {
  policies: Policy[];
  reportSchedules: PolicyReportSchedule[];
  requirementsTemplates: RequirementsTemplatesForType[];
}): React.ReactElement => {
  const { identity } = useIdentity();

  const maxPoliciesAvailable =
    identity?.feature_gates.policies_count ?? Number.MAX_SAFE_INTEGER;
  const orgCanCreatePolicies = policies.length < maxPoliciesAvailable;
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const sortedPolices = _.sortBy(policies, ["policy_type", "name"]);

  return (
    <div className="flex flex-col gap-4">
      {isModalOpen && (
        <PolicyChooseTypeModal onClose={() => setIsModalOpen(false)} />
      )}
      <SettingsSubHeading
        title="Your policies"
        titleHeadingLevel={2}
        accessory={
          sortedPolices.length > 0 ? (
            <NewPolicyButton
              orgCanCreatePolicies={orgCanCreatePolicies}
              onClick={() => setIsModalOpen(true)}
            />
          ) : undefined
        }
        className="mb-0"
      />
      {sortedPolices.length > 0 && (
        <StackedList>
          {sortedPolices.map((policy) => (
            <PolicyRow
              key={policy.id}
              policy={policy}
              dependentReports={reportSchedules
                .filter((s) => s.policy_ids.includes(policy.id))
                .map((s) => s.name)}
            />
          ))}
        </StackedList>
      )}
      {orgCanCreatePolicies ? (
        <PoliciesAddMoreEmptyState
          policies={sortedPolices}
          requirementsTemplates={requirementsTemplates}
          onClickAddNew={() => setIsModalOpen(true)}
        />
      ) : (
        <NoMorePoliciesCallout />
      )}
    </div>
  );
};

const PolicyRow = ({
  policy,
  dependentReports,
}: {
  policy: Policy;
  dependentReports: string[];
}) => {
  const { trigger: deletePolicy } = useAPIMutation(
    "policiesList",
    undefined,
    async (apiClient, data: PoliciesDestroyRequest) => {
      await apiClient.policiesDestroy(data);
    },
  );
  const config = useGetPolicyTypeConfig()(policy.policy_type);

  const deleteIsGatedText =
    dependentReports.length > 0 &&
    `This can't be deleted as it is being used by ${dependentReports
      .slice(0, 1)
      .join(", ")}${
      dependentReports.length > 1
        ? ` and ${dependentReports.length - 1} more...`
        : ""
    }`;

  return (
    <SettingsListItem
      title={policy.name}
      icon={config.icon}
      badgeProps={{
        label: config.label,
        theme: BadgeTheme.Info,
      }}
      description={policy.description}
      buttons={{
        view: {
          viewHref: `/settings/policies/${policy.id}`,
        },
        delete: {
          resourceTitle: policy.name,
          onDelete: () => deletePolicy({ id: policy.id }),
          isGatedText: deleteIsGatedText,
          requiredScope: ScopeNameEnum.PoliciesDestroy,
          deleteConfirmationTitle: "Delete policy",
          deleteConfirmationContent: (
            <Txt className="flex flex-wrap gap-1">
              Are you sure you want to delete the{" "}
              <Txt inline bold>
                {policy.name}
              </Txt>{" "}
              policy?
            </Txt>
          ),
        },
      }}
    />
  );
};

const NewPolicyButton = ({
  orgCanCreatePolicies,
  onClick,
}: {
  orgCanCreatePolicies: boolean;
  onClick?: () => void;
}): React.ReactElement => {
  const { identity } = useIdentity();

  return (
    <>
      <GatedButton
        theme={ButtonTheme.Secondary}
        onClick={onClick}
        requiredScope={ScopeNameEnum.PoliciesCreate}
        analyticsTrackingId="create-policy"
        upgradeRequired={!orgCanCreatePolicies}
        upgradeRequiredProps={{
          gate: {
            type: "numeric",
            value: identity?.feature_gates.policies_count,
            featureNameSingular: "policy",
          },
          featureName: "policies",
        }}
        icon={IconEnum.Add}
      >
        New policy
      </GatedButton>
    </>
  );
};

const PoliciesAddMoreEmptyState = ({
  policies,
  requirementsTemplates,
  onClickAddNew,
}: {
  policies: Policy[];
  requirementsTemplates: RequirementsTemplatesForType[];
  onClickAddNew: () => void;
}) => {
  const getConfig = useGetPolicyTypeConfig();

  // Figure out which policy types are not yet in use
  const policyTypesInUse = uniq(policies.map((p) => p.policy_type));
  const availablePolicyTypes = useAvailablePolicyTypes();
  if (policyTypesInUse.length >= availablePolicyTypes.length) {
    return null;
  }

  return (
    <EmptyState
      content="Select from a template or create one from scratch"
      className="[&>*]:max-w-none"
      cta={
        <div className="flex items-center gap-2 flex-wrap justify-center">
          {requirementsTemplates.map(({ policy_type, templates }) => {
            const policyType = policy_type as unknown as PolicyPolicyTypeEnum;
            const config = getConfig(policyType);
            const alreadyInUse = policyTypesInUse.includes(policyType);
            if (alreadyInUse) {
              return null;
            }
            if (!availablePolicyTypes.includes(policyType)) {
              return null;
            }
            return (
              <>
                {templates.map((template, idx) => (
                  <GatedButton
                    analyticsTrackingId="policies-quick-add"
                    theme={ButtonTheme.Dashed}
                    key={idx}
                    size={ButtonSize.Small}
                    icon={config.icon}
                    href={`/settings/policies/create?type=${policyType}&template=${template.label}`}
                    requiredScope={ScopeNameEnum.PoliciesCreate}
                  >
                    {config.label_plural} {template.label.toLowerCase()}
                  </GatedButton>
                ))}
              </>
            );
          })}
          <GatedButton
            theme={ButtonTheme.Secondary}
            size={ButtonSize.Small}
            onClick={onClickAddNew}
            requiredScope={ScopeNameEnum.PoliciesCreate}
            analyticsTrackingId="create-policy"
            icon={IconEnum.Add}
            title=""
          />
        </div>
      }
    />
  );
};

const NoMorePoliciesCallout = () => {
  return (
    <ProductMarketingBanner
      className="bg-surface-invert text-white"
      title="Unlock more policies"
      badge={<PlanBadge planName="enterprise" size={BadgeSize.ExtraSmall} />}
      subtitle="You've reached your policies limit, upgrade your plan to encourage responders to write post-mortems and schedule debriefs in a timely manner."
      ctaPosition="right"
      cta={
        <Button
          size={ButtonSize.Small}
          href="/settings/billing"
          analyticsTrackingId="integrations-view-plans"
          className="w-fit"
        >
          View plans
        </Button>
      }
    />
  );
};
