import {
  StatusPageAffectedComponentStatusEnum,
  StatusPageContentComponentImpactStatusEnum,
  StatusPageContentIncident,
  StatusPageContentIncidentStatusEnum,
  StatusPageContentIncidentUpdateToStatusEnum,
} from "@incident-io/api";
import _ from "lodash";

import { ContentBoxTheme } from "./components/ContentBox";

// Returns an ordinal for component status that ranks them, such that the worse status has
// the highest rank.
export const STATUS_SEVERITY: {
  [key in
    | StatusPageContentComponentImpactStatusEnum
    | StatusPageAffectedComponentStatusEnum]: number;
} = {
  [StatusPageContentComponentImpactStatusEnum.UnderMaintenance]: 2,
  [StatusPageContentComponentImpactStatusEnum.DegradedPerformance]: 3,
  [StatusPageContentComponentImpactStatusEnum.PartialOutage]: 4,
  [StatusPageContentComponentImpactStatusEnum.FullOutage]: 5,
  [StatusPageAffectedComponentStatusEnum.Operational]: 1,
};

// Returns the status for the component given the severity rank
export const STATUS_FROM_SEVERITY: {
  [key: number]: StatusPageContentComponentImpactStatusEnum;
} = {
  [2]: StatusPageContentComponentImpactStatusEnum.UnderMaintenance,
  [3]: StatusPageContentComponentImpactStatusEnum.DegradedPerformance,
  [4]: StatusPageContentComponentImpactStatusEnum.PartialOutage,
  [5]: StatusPageContentComponentImpactStatusEnum.FullOutage,
};

// Returns a colour for a given status severity
export const COLOUR_FROM_STATUS: {
  [key in StatusPageContentComponentImpactStatusEnum]: string;
} = {
  [StatusPageContentComponentImpactStatusEnum.UnderMaintenance]:
    "#438DBD",
  [StatusPageContentComponentImpactStatusEnum.DegradedPerformance]:
    "#F1C40F",
  [StatusPageContentComponentImpactStatusEnum.PartialOutage]:
    "#E67E22",
  [StatusPageContentComponentImpactStatusEnum.FullOutage]:
    "#C0392B",
};

export const THEME_FOR_STATUS: {
  [key in StatusPageAffectedComponentStatusEnum]: ContentBoxTheme;
} = {
  [StatusPageAffectedComponentStatusEnum.Operational]:
    ContentBoxTheme.Operational,
  [StatusPageAffectedComponentStatusEnum.UnderMaintenance]:
    ContentBoxTheme.UnderMaintenance,
  [StatusPageAffectedComponentStatusEnum.DegradedPerformance]:
    ContentBoxTheme.DegradedPerformance,
  [StatusPageAffectedComponentStatusEnum.PartialOutage]:
    ContentBoxTheme.PartialOutage,
  [StatusPageAffectedComponentStatusEnum.FullOutage]:
    ContentBoxTheme.FullOutage,
};

export const RESOLVED_STATUSES = [
  StatusPageContentIncidentStatusEnum.Resolved,
  StatusPageContentIncidentStatusEnum.MaintenanceComplete,
  StatusPageContentIncidentUpdateToStatusEnum.Resolved,
  StatusPageContentIncidentUpdateToStatusEnum.MaintenanceComplete,
];

export const isResolved = (
  status:
    | StatusPageContentIncidentStatusEnum
    | StatusPageContentIncidentUpdateToStatusEnum,
) => RESOLVED_STATUSES.includes(status);

export function assertUnreachable(x: never) {
  console.error(`Unreachable value: ${x}`);
  return null;
}

// This is helpful for debugging animations: slow them all down by increasing
// this value.
export const ANIMATION_MODIFIER = 1;

// This is only here for maintenance windows
// It works around the fact that maintenances are weird and don't have a start and an end timestamp
// We just have to calculate it from the component impacts
export const getMaintenanceImpactWindow = (
  impacts: StatusPageContentIncident["component_impacts"],
): [string | undefined, string | undefined] => {
  // get the min start of component impacts and max end.
  const start = _.minBy(impacts, (impact) => impact.start_at)?.start_at;

  const end = _.maxBy(impacts, (impact) => impact.end_at)?.end_at;

  return [start, end];
};
