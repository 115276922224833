import { GenericErrorMessage } from "@incident-ui";
import React from "react";
import { useEffect, useRef, useState } from "react";
import { DateAggregation } from "src/components/insights/v3/dashboards/common/types";
import { useIsSampleData } from "src/components/insights/v3/dashboards/common/useIsSampleData";
import { getEnvironment, isDevelopment } from "src/utils/environment";
import { useAPI } from "src/utils/swr";

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      "explo-dashboard": unknown;
    }
  }
}

export enum DemoVariable {
  CustomFieldID = "custom_field_id",
  DurationMetricID = "duration_metric_id",
  PINCFlowID = "post_incident_flow_id",
}

export type ExploDashboardProps = {
  dashboardEmbedID: string;
  dashboardVariables?: Record<string, string | undefined>;
  initialHeight: string;
};

export const useDashboardLoaded = (): boolean => {
  const [isDashboardLoaded, setDashboardLoaded] = useState(false);

  useEffect(() => {
    const listener = () => {
      setDashboardLoaded(true);
    };

    window.addEventListener("dashboardLoaded", listener);
    return () => {
      window.removeEventListener("dashboardLoaded", listener);
    };
  }, []);

  return isDashboardLoaded;
};

export const ExploDashboard = ({
  dashboardEmbedID,
  dashboardVariables = {},
  initialHeight,
}: ExploDashboardProps) => {
  const { isSampleData, prefix } = useIsSampleData();

  // If the dashboard is using demo data and is not in a development
  // environment, we need to attach DEMO- to some variables so that we find corresponding rows in the demo data
  function demoDataCleaner(key: string, value: string, prefix: string) {
    if (
      !isDevelopment() &&
      isSampleData &&
      Object.values(DemoVariable).includes(key as DemoVariable)
    ) {
      return prefix + value;
    }
    return value;
  }

  const stringifiedVariables = JSON.stringify(
    dashboardVariables,
    function (key, value) {
      return demoDataCleaner(key, value as string, prefix);
    },
  );

  const iFrameRef = React.useRef<HTMLIFrameElement>(null);
  const [iFrameHeight, setHeight] = React.useState(initialHeight);

  const { token, error: tokenError } = useExploToken(dashboardEmbedID);

  // These are used to generate the URL on the first render
  const [firstRenderVariables] = useState(dashboardVariables);
  // This state is used to differentiate between the current and previous render
  const [dashboardVars, setDashboardVars] = useState(dashboardVariables);
  const dashboardLoaded = useDashboardLoaded();
  const wrapperRef = useRef<HTMLDivElement>(null);

  const messageListener = (e) => {
    // Check origin of message before reading
    if (e.origin !== "https://app.explo.co") return;
    switch (e.data.event) {
      case "dashboardUpdated":
        if (e.data.detail.dashboardId === dashboardEmbedID) {
          if (e.data.detail.dashboardHeight !== 0) {
            setHeight(e.data.detail.dashboardHeight + "px");
          }
        }
        break;
    }
  };

  useEffect(() => {
    window.addEventListener("message", messageListener);
    return () => window.removeEventListener("message", messageListener);
  });

  useEffect(() => {
    if (wrapperRef.current) {
      const exploDashboard = document.getElementById(
        `dash-${dashboardEmbedID}`,
      );

      const parent = exploDashboard?.parentElement;
      const height = parent?.getBoundingClientRect().height;

      if (height) {
        // I'm sure this will be problematic - but we're not conditionally rendering
        // the charts so should be okay
        //
        // If we have graph height issues, it'll be because of here!
        wrapperRef.current.style.height = `${height}px`;

        setTimeout(() => {
          if (!wrapperRef.current) {
            return;
          }
          // We need to set this back to auto after a few seconds
          // so that the dashboard can resize itself if needed
          wrapperRef.current.style.height = "auto";
        }, 5000);
      }
    }

    // Find out which variables have changed between renders
    const changes = Object.entries(dashboardVariables).filter(
      ([key, value]) => dashboardVars[key] !== value,
    );
    setDashboardVars(dashboardVariables);

    changes.forEach(([key, value]) => {
      let cleanedValue: string | undefined = demoDataCleaner(
        key,
        value as string,
        prefix,
      );
      // Explo reads empty string values differently from undefined.
      // For group by variables, this leads to weird behaviour where filtering is also applying.
      // Reported to Explo: https://incident-io.slack.com/archives/C06HN99E5BP/p1727880978911239
      if (value === "") {
        cleanedValue = undefined;
      }
      iFrameRef.current?.contentWindow?.postMessage(
        {
          event: "updateExploDashboardVariable",
          detail: {
            varName: key,
            value: cleanedValue,
          },
        },
        "*",
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stringifiedVariables, dashboardLoaded, dashboardEmbedID]);

  if (tokenError) {
    return <GenericErrorMessage error={tokenError} />;
  }
  if (!token) {
    return null;
  }

  const urlParams = new URLSearchParams();
  // Need to add an id parameter so that we can uniquely identify each JS Event
  // message that we receive from Explo
  urlParams.append("id", `"${dashboardEmbedID}"`);
  let filterJsonValue = "";
  for (const [key, value] of Object.entries(firstRenderVariables)) {
    if (value) {
      // We need to stringify the filter_json again due to issues with unescaped
      // double quotes, causing nothing to be filtered. We also need to encode
      // this using encodeURIComponent, as URLSearchParams doesn't seem to escape
      // the double quotes properly
      if (key === "filter_json") {
        filterJsonValue = `&filter_json=${encodeURIComponent(
          JSON.stringify(value, function (key, value) {
            return demoDataCleaner(key, value as string, prefix);
          }),
        )}`;
      } else {
        // Send the correct value if the organisation is a demo org (DEMO-actualID)
        const cleanedValue = demoDataCleaner(key, value, prefix);
        urlParams.append(key, `"${cleanedValue}"`);
      }
    }
  }

  const exploIFrameURL = `https://app.explo.co/iframe/${token}/${getEnvironment()}?${urlParams.toString()}${filterJsonValue}`;

  return (
    <div className="-m-4 flex-wrap">
      <iframe
        ref={iFrameRef}
        // The loading prop must always be kept above src because of some weird bug
        // https://developer.mozilla.org/en-US/docs/Web/API/HTMLImageElement/loading#:~:text=window%27s%20visual%20viewport.-,Usage%20notes,effect%20(Firefox%20bug%201647077).
        loading="lazy"
        src={exploIFrameURL}
        style={{ width: "1px", minWidth: "100%" }}
        height={iFrameHeight}
      />
    </div>
  );
};

const useExploToken = (dashboardEmbedID: string) => {
  const { data, mutate, error } = useAPI(
    "insightsGenerateExploJWT",
    {
      generateExploJWTRequestBody: {
        dashboard_embed_id: dashboardEmbedID,
      },
    },
    {
      // Disable all auto-revalidation: changing the URL will cause it
      // to reload, which we do not want.
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      revalidateIfStale: false,
      // Except when we switch to this tab: switching to/from the tab should
      // generate a fresh token.
      revalidateOnMount: true,
    },
  );
  const token = data?.token;

  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  // When the token gets, set a timeout to refetch it 55 minutes later.
  // We do this rather than using SWR's refresh interval, since SWR's interval
  // only counts up while the page is open, and we need to include time when the
  // page was not in focus.
  useEffect(() => {
    if (!token) return;

    const refreshInterval = 1000 * 60 * 55; // 55 minutes

    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(mutate, refreshInterval);
  }, [token, mutate]);

  return { token, error };
};
export const DateAggregationMap: {
  [key in DateAggregation]: string;
} = {
  [DateAggregation.Day]: "DATE_DAY",
  [DateAggregation.Week]: "DATE_WEEK",
  [DateAggregation.Month]: "DATE_MONTH",
  [DateAggregation.Quarter]: "DATE_QUARTER",
};
