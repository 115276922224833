import { CodeBlock } from "@incident-ui/CodeBlock/CodeBlock";

import { publicApiUrl } from "../../../../../utils/environment";
import { SetupInfoNumberedList, SetupInfoSingleLineBlocks } from "./helpers";
import { AlertSourceSetupProps } from "./types";

export const AlertSourceDynatraceSetupInfo = ({
  alertSourceConfig,
}: AlertSourceSetupProps) => {
  return (
    <SetupInfoNumberedList>
      <div>
        Go to the <strong>Settings/Integrations/Problem Notifications</strong>{" "}
        section in Dynatrace
      </div>
      <div>
        Click <strong>Add notification</strong>
      </div>
      <div>
        Select <strong>Custom Integration</strong> as{" "}
        <strong>Notification type</strong>
      </div>
      <SetupInfoSingleLineBlocks
        intro={
          <div>
            Copy <strong>Name</strong> below and paste in the{" "}
            <strong>Display name</strong> field
          </div>
        }
        blocks={[
          {
            title: "Name",
            code: `${alertSourceConfig.name} (incident.io)`,
          },
        ]}
      />
      <div>
        Toggle <strong>Secret webhook URL</strong>
      </div>
      <SetupInfoSingleLineBlocks
        intro={
          <div>
            Copy <strong>URL</strong> below and paste it into{" "}
            <strong>Webhook URL</strong>
          </div>
        }
        blocks={[
          {
            title: "URL",
            code: `${publicApiUrl()}/v2/alert_events/dynatrace/${
              alertSourceConfig.id
            }?token=${alertSourceConfig.secret_token}`,
          },
        ]}
      />
      <div>
        Toggle <strong>Call webhook if problem is closed</strong>
      </div>
      <div className="space-y-4">
        <div>
          Copy <strong>Payload</strong> below and paste it into{" "}
          <strong>Webhook URL</strong>
        </div>
        <CodeBlock
          title={"Payload"}
          code={`
{
  "PID": "99999",
  "ProblemTitle": "Dynatrace problem notification test run",
  "State": "OPEN",
  "Metadata": {
    "ImpactedEntities": [
      {"type": "HOST", "name": "MyHost1", "entity": "HOST-XXXXXXXXXXXXX" },
      {"type": "SERVICE", "name": "MyService1", "entity": "SERVICE-XXXXXXXXXXXXX"}
    ],
    "ImpactedEntity": "MyHost1, MyService1",
    "ProblemDetailsHTML": "<h1>Dynatrace problem notification test run details</h1>",
    "ProblemDetailsJSONv2": {
      "impactLevel": "SERVICES",
      "severityLevel": "ERROR",
      "affectedEntities": [
        { "id": "123", "type": "web" },
        { "id": "456", "type": "database" }
      ],
      "startTime": 123123123   
    },
    "ProblemID": "999",
    "ProblemImpact": "INFRASTRUCTURE",
    "Problem URL": "https://example.com",
    "Tags": "test_tag_0, test_tag_1"
  }
}`}
        />
      </div>
      <div>
        Once you&rsquo;ve entered your payload you can click{" "}
        <strong>Send test notification</strong>
      </div>
    </SetupInfoNumberedList>
  );
};
