import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import {
  Button,
  ButtonSize,
  ButtonTheme,
  GenericErrorMessage,
  IconEnum,
} from "@incident-ui";
import {
  Drawer,
  DrawerBody,
  DrawerContents,
  DrawerContentsLoading,
  DrawerTitle,
} from "@incident-ui/Drawer/Drawer";
import { ErrorBoundary } from "@sentry/react";
import { AnimatePresence } from "framer-motion";
import React from "react";
import { useNotifications } from "src/components/notifications/useNotifications";
import { useEventListener } from "use-hooks";

import { NotificationList } from "./NotificationsList";

type Props = {
  isOpen: boolean;
  closeSidebar: () => void;
  children?: React.ReactNode;
};

export const NotificationsDrawer = ({
  isOpen,
  closeSidebar,
}: Props): React.ReactElement => {
  useEventListener("keydown", (e: KeyboardEvent) => {
    if (e.key === "Esc" || e.key === "Escape") {
      closeSidebar();
    }
  });

  return (
    <AnimatePresence>
      {isOpen && (
        <Drawer width="small" side="left" onClose={closeSidebar}>
          <NotificationContent closeSidebar={closeSidebar} />
        </Drawer>
      )}
    </AnimatePresence>
  );
};

export const NotificationContent = ({
  closeSidebar,
}: {
  closeSidebar: () => void;
}): React.ReactElement => {
  const {
    notifications,
    fetchMoreNotifications,
    loadingInitialNotifications,
    loadingMoreNotifications,
    hasReturnedAllNotifications,
  } = useNotifications();

  const navigate = useOrgAwareNavigate();

  if (loadingInitialNotifications) {
    return <DrawerContentsLoading />;
  }

  return (
    <DrawerContents>
      <DrawerTitle
        onClose={closeSidebar}
        title="Notifications"
        icon={IconEnum.BellRinging}
        secondaryAccessory={
          <Button
            analyticsTrackingId="notification-preferences"
            theme={ButtonTheme.Secondary}
            icon={IconEnum.Cog}
            size={ButtonSize.Small}
            onClick={() => {
              navigate("/user-preferences/notifications");
              closeSidebar();
            }}
          >
            Preferences
          </Button>
        }
      />
      <DrawerBody>
        <ErrorBoundary fallback={<GenericErrorMessage />}>
          <NotificationList
            notifications={notifications}
            fetchMoreNotifications={fetchMoreNotifications}
            loadingMoreNotifications={loadingMoreNotifications}
            hasReturnedAllNotifications={hasReturnedAllNotifications}
            closeSidebar={closeSidebar}
          />
        </ErrorBoundary>
      </DrawerBody>
    </DrawerContents>
  );
};
