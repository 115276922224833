import { PostIncidentTask } from "@incident-io/api";
import { Button, ButtonTheme, IconEnum } from "@incident-ui";
import { useAPIMutation } from "src/utils/swr";

export const UnresolvePostIncidentTaskButton = ({
  incidentTask,
  onSuccess,
  onError,
  text,
}: {
  incidentTask: PostIncidentTask;
  onSuccess: (task: PostIncidentTask) => void;
  onError: (error: string) => void;
  text: string;
}): React.ReactElement | null => {
  const { trigger: unresolveTask, isMutating: unresolvingTaskStatus } =
    useAPIMutation(
      "postIncidentFlowListTasks",
      { incidentId: incidentTask.incident_id },
      async (apiClient) => {
        await apiClient.postIncidentFlowUnresolveTask({
          id: incidentTask.id,
        });
      },
      {
        onSuccess: (response) => {
          const unresolvedTask = response.incident_tasks.find(
            (task) => task.id === incidentTask.id,
          );

          if (unresolvedTask) {
            onSuccess(unresolvedTask);
          }
        },
        onError: (error: Error) => {
          onError(error.message);
        },
      },
    );

  return (
    <Button
      theme={ButtonTheme.Naked}
      onClick={() => {
        unresolveTask({});
      }}
      analyticsTrackingId="post-incident-task-unresolve"
      analyticsTrackingMetadata={{
        incident_task_id: incidentTask.id,
      }}
      loading={unresolvingTaskStatus}
      icon={IconEnum.Undo}
    >
      {text}
    </Button>
  );
};
