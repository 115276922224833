/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BlamelessImportOptions,
    BlamelessImportOptionsFromJSON,
    BlamelessImportOptionsFromJSONTyped,
    BlamelessImportOptionsToJSON,
} from './BlamelessImportOptions';
import {
    CoupangJiraImportOptions,
    CoupangJiraImportOptionsFromJSON,
    CoupangJiraImportOptionsFromJSONTyped,
    CoupangJiraImportOptionsToJSON,
} from './CoupangJiraImportOptions';
import {
    FireHydrantImportOptions,
    FireHydrantImportOptionsFromJSON,
    FireHydrantImportOptionsFromJSONTyped,
    FireHydrantImportOptionsToJSON,
} from './FireHydrantImportOptions';

/**
 * 
 * @export
 * @interface CompetitorImportPayload
 */
export interface CompetitorImportPayload {
    /**
     * 
     * @type {BlamelessImportOptions}
     * @memberof CompetitorImportPayload
     */
    blameless_options?: BlamelessImportOptions;
    /**
     * The name of the competitor account you are importing from
     * @type {string}
     * @memberof CompetitorImportPayload
     */
    competitor_name: CompetitorImportPayloadCompetitorNameEnum;
    /**
     * 
     * @type {CoupangJiraImportOptions}
     * @memberof CompetitorImportPayload
     */
    coupang_jira_options?: CoupangJiraImportOptions;
    /**
     * 
     * @type {FireHydrantImportOptions}
     * @memberof CompetitorImportPayload
     */
    firehydrant_options?: FireHydrantImportOptions;
}

/**
* @export
* @enum {string}
*/
export enum CompetitorImportPayloadCompetitorNameEnum {
    Blameless = 'blameless',
    Firehydrant = 'firehydrant',
    CoupangJira = 'coupang_jira'
}

export function CompetitorImportPayloadFromJSON(json: any): CompetitorImportPayload {
    return CompetitorImportPayloadFromJSONTyped(json, false);
}

export function CompetitorImportPayloadFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompetitorImportPayload {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'blameless_options': !exists(json, 'blameless_options') ? undefined : BlamelessImportOptionsFromJSON(json['blameless_options']),
        'competitor_name': json['competitor_name'],
        'coupang_jira_options': !exists(json, 'coupang_jira_options') ? undefined : CoupangJiraImportOptionsFromJSON(json['coupang_jira_options']),
        'firehydrant_options': !exists(json, 'firehydrant_options') ? undefined : FireHydrantImportOptionsFromJSON(json['firehydrant_options']),
    };
}

export function CompetitorImportPayloadToJSON(value?: CompetitorImportPayload | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'blameless_options': BlamelessImportOptionsToJSON(value.blameless_options),
        'competitor_name': value.competitor_name,
        'coupang_jira_options': CoupangJiraImportOptionsToJSON(value.coupang_jira_options),
        'firehydrant_options': FireHydrantImportOptionsToJSON(value.firehydrant_options),
    };
}

