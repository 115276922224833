/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OnCallNotificationMethod,
    OnCallNotificationMethodFromJSON,
    OnCallNotificationMethodFromJSONTyped,
    OnCallNotificationMethodToJSON,
} from './OnCallNotificationMethod';

/**
 * 
 * @export
 * @interface OnCallNotificationRule
 */
export interface OnCallNotificationRule {
    /**
     * Delay in seconds before this rule activates
     * @type {number}
     * @memberof OnCallNotificationRule
     */
    delay_seconds: number;
    /**
     * Unique identifier for this on-call notification rule
     * @type {string}
     * @memberof OnCallNotificationRule
     */
    id: string;
    /**
     * 
     * @type {OnCallNotificationMethod}
     * @memberof OnCallNotificationRule
     */
    method: OnCallNotificationMethod;
    /**
     * Notice period in seconds before this rule activates
     * @type {number}
     * @memberof OnCallNotificationRule
     */
    notice_period_seconds?: number;
    /**
     * The type of escalation notification
     * @type {string}
     * @memberof OnCallNotificationRule
     */
    notification_type: OnCallNotificationRuleNotificationTypeEnum;
    /**
     * The type of notification rule
     * @type {string}
     * @memberof OnCallNotificationRule
     */
    rule_type: OnCallNotificationRuleRuleTypeEnum;
}

/**
* @export
* @enum {string}
*/
export enum OnCallNotificationRuleNotificationTypeEnum {
    Slack = 'slack',
    Email = 'email',
    App = 'app',
    Sms = 'sms',
    Phone = 'phone',
    SlackChannel = 'slack_channel',
    MicrosoftTeams = 'microsoft_teams'
}/**
* @export
* @enum {string}
*/
export enum OnCallNotificationRuleRuleTypeEnum {
    HighUrgency = 'high_urgency',
    LowUrgency = 'low_urgency',
    ShiftChanges = 'shift_changes'
}

export function OnCallNotificationRuleFromJSON(json: any): OnCallNotificationRule {
    return OnCallNotificationRuleFromJSONTyped(json, false);
}

export function OnCallNotificationRuleFromJSONTyped(json: any, ignoreDiscriminator: boolean): OnCallNotificationRule {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'delay_seconds': json['delay_seconds'],
        'id': json['id'],
        'method': OnCallNotificationMethodFromJSON(json['method']),
        'notice_period_seconds': !exists(json, 'notice_period_seconds') ? undefined : json['notice_period_seconds'],
        'notification_type': json['notification_type'],
        'rule_type': json['rule_type'],
    };
}

export function OnCallNotificationRuleToJSON(value?: OnCallNotificationRule | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'delay_seconds': value.delay_seconds,
        'id': value.id,
        'method': OnCallNotificationMethodToJSON(value.method),
        'notice_period_seconds': value.notice_period_seconds,
        'notification_type': value.notification_type,
        'rule_type': value.rule_type,
    };
}

