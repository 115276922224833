import { Heading, Tooltip } from "@incident-ui";
import { captureMessage } from "@sentry/react";
import React from "react";
import {
  IncidentDurationMetricWithValue,
  IncidentDurationMetricWithValueStatusEnum,
  IncidentTimestamp,
} from "src/contexts/ClientContext";

import { formatDurationInSeconds } from "../../../../utils/datetime";
import { useNavigateToModal } from "../../../../utils/query-params";
import { IncidentSidebarEntry } from "./IncidentSidebarEntry";

export function IncidentDurationMetrics({
  metrics,
  timestamps,
}: {
  metrics: IncidentDurationMetricWithValue[];
  timestamps: IncidentTimestamp[];
}): React.ReactElement | null {
  const navigateToModal = useNavigateToModal();

  return (
    <div className="py-1.5">
      <div className="flex mb-2 items-center">
        <Heading
          level={3}
          size="small"
          className="flex mr-1 gap-2 items-center"
        >
          Duration metrics
        </Heading>
        <Tooltip
          content={
            <DurationMetricsTooltipContent
              metrics={metrics}
              timestamps={timestamps}
            />
          }
        />
      </div>
      {metrics.length === 0 ? (
        // As there's an in-built duration metric, this should basically never happen
        <div className="text-content-tertiary mt-2">
          There are no metrics available for this incident.
        </div>
      ) : (
        <div className="space-y-2">
          {metrics.map((metric) => {
            const fromTimestamp = timestamps.find(
              (t) => t.id === metric.duration_metric.from_timestamp_id,
            );
            const toTimestamp = timestamps.find(
              (t) => t.id === metric.duration_metric.to_timestamp_id,
            );
            if (!fromTimestamp || !toTimestamp) {
              captureMessage(
                "metric pointing to timestamps that we don't have in our list",
                { extra: { metric, timestamps } },
              );
              return null;
            }

            const formattedValue =
              typeof metric.value_seconds === "number"
                ? formatDurationInSeconds(
                    metric.value_seconds,
                    undefined,
                    undefined,
                    true,
                  )
                : undefined;
            const invalid =
              typeof metric.value_seconds === "number" &&
              metric.value_seconds < 0;

            return (
              <IncidentSidebarEntry
                align={invalid ? "start" : "baseline"}
                key={metric.duration_metric.id}
                onClick={() =>
                  navigateToModal(`duration/${metric.duration_metric.id}`)
                }
                label={
                  <div className="flex items-center">
                    <span className="mr-1">{metric.duration_metric.name}</span>
                  </div>
                }
                value={
                  formattedValue ?? (
                    <MissingDurationExplanation
                      metric={metric}
                      timestamps={timestamps}
                    />
                  )
                }
                invalid={invalid}
              />
            );
          })}
        </div>
      )}
    </div>
  );
}

const MissingDurationExplanation = ({
  metric,
  timestamps,
}: {
  metric: IncidentDurationMetricWithValue;
  timestamps: IncidentTimestamp[];
}) => {
  const fromTimestamp = timestamps.find(
    (t) => t.id === metric.duration_metric.from_timestamp_id,
  );
  const toTimestamp = timestamps.find(
    (t) => t.id === metric.duration_metric.to_timestamp_id,
  );
  if (!fromTimestamp || !toTimestamp) {
    captureMessage(
      "metric pointing to timestamps that we don't have in our list",
      { extra: { metric, timestamps } },
    );
    return (
      <span className="text-right text-content-tertiary">Error occurred</span>
    );
  }

  return (
    <>
      {metric.status ===
      IncidentDurationMetricWithValueStatusEnum.Calculating ? (
        <Tooltip
          content={
            <div className="text-left">
              This metric is currently being calculated, please check back later
            </div>
          }
        >
          <span className="text-right text-content-tertiary">
            Calculating...
          </span>
        </Tooltip>
      ) : metric.status ===
        IncidentDurationMetricWithValueStatusEnum.InvalidTimestamps ? (
        <Tooltip
          content={
            <div className="text-left">
              <span className="font-semibold">{fromTimestamp.name}</span> must
              be before{" "}
              <span className="font-semibold">{toTimestamp.name}</span>
            </div>
          }
        >
          <span className="text-right">Invalid timestamps</span>
        </Tooltip>
      ) : (
        <span className="text-right">Missing timestamps</span>
      )}
    </>
  );
};

const DurationMetricsTooltipContent = ({
  metrics,
  timestamps,
}: {
  metrics: IncidentDurationMetricWithValue[];
  timestamps: IncidentTimestamp[];
}) => {
  return (
    <div className="flex flex-col gap-2">
      {metrics.map((metric) => {
        const fromTimestamp = timestamps.find(
          (t) => t.id === metric.duration_metric.from_timestamp_id,
        );
        const toTimestamp = timestamps.find(
          (t) => t.id === metric.duration_metric.to_timestamp_id,
        );
        if (!fromTimestamp || !toTimestamp) {
          captureMessage(
            "metric pointing to timestamps that we don't have in our list",
            { extra: { metric, timestamps } },
          );
          return "";
        }
        return (
          <div key={metric.duration_metric.id}>
            <span className="font-semibold">{metric.duration_metric.name}</span>
            <br />
            <span>
              {fromTimestamp.name} &rarr; {toTimestamp.name}
            </span>
          </div>
        );
      })}
    </div>
  );
};
