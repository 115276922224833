import {
  CopilotThread,
  useAiServiceAiListCopilotThreads,
} from "@incident-io/query-api";
import {
  Avatar,
  Button,
  ButtonTheme,
  Icon,
  IconEnum,
  IconSize,
  LoadingWrapper,
  LocalDateTime,
  StackedList,
  Tooltip,
} from "@incident-ui";
import { tcx } from "src/utils/tailwind-classes";

export const AIThreadsList = () => {
  const { data, isLoading } = useAiServiceAiListCopilotThreads();

  return (
    <>
      <h2 className="text-lg font-semibold mt-8">Recent threads</h2>
      <LoadingWrapper loading={isLoading}>
        <StackedList>
          {data?.threads.map((thread) => (
            <CopilotThreadRender key={thread.id} thread={thread} />
          ))}
        </StackedList>
      </LoadingWrapper>
    </>
  );
};

const CopilotThreadRender = ({ thread }: { thread: CopilotThread }) => {
  return (
    <Button
      theme={ButtonTheme.Unstyled}
      analyticsTrackingId={null}
      className="flex flex-col gap-4 px-4 group"
      href={`/ai-evals/threads/${thread.id}`}
    >
      <div className="flex flex-between gap-2 w-full">
        <div className="flex gap-6 py-4">
          <LocalDateTime
            timestamp={thread.created_at}
            className="font-medium"
          />
          {thread.incident_external_id && (
            <Button
              theme={ButtonTheme.Naked}
              analyticsTrackingId={null}
              href={"/incidents/" + thread.incident_external_id}
            >
              INC-{thread.incident_external_id}
            </Button>
          )}
          {/* Fix the width so it looks aligned without a user */}
          <div className="w-6">
            {thread.from_user && (
              <Avatar
                url={thread.from_user.avatar_url}
                name={thread.from_user.name}
              />
            )}
          </div>
          <div className="truncate">{thread.source_message}</div>
        </div>
        <div className="grow" />
        {thread.scores && (
          <div className="flex gap-1 py-4">
            <Icon id={IconEnum.Star} size={IconSize.Small} />
            {Object.entries(thread.scores).map(([axis, score]) => (
              <div
                key={axis}
                className={tcx("rounded-full px-1.5", {
                  "bg-green-600": score === 5,
                  "bg-green-300": score === 4,
                  "bg-yellow-400": score === 3,
                  "bg-red-400": score === 2,
                  "bg-red-600": score === 1,
                })}
              >
                <Tooltip content={axis}>
                  <button>{score > 0 ? score.toLocaleString() : "?"}</button>
                </Tooltip>
              </div>
            ))}
          </div>
        )}
        {!!thread.overall_cost_cents && (
          <div className="flex items-center justify-end gap-0.5">
            <Icon id={IconEnum.PiggyBank} size={IconSize.Small} />$
            {(thread.overall_cost_cents / 100.0).toFixed(2)}
          </div>
        )}
        <Button
          theme={ButtonTheme.Naked}
          icon={IconEnum.ArrowRight}
          href={`/ai-evals/threads/${thread.id}`}
          analyticsTrackingId={null}
          title="View thread"
          className="justify-end py-4 group-hover:text-slate-900"
        />
      </div>
    </Button>
  );
};
