import {
  Incident,
  IncidentStatus,
  IncidentStatusCategoryEnum,
  PostIncidentTask,
} from "@incident-io/api";
import _ from "lodash";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { usePrevious } from "use-hooks";

import { getAnchorId } from "../../../../utils/anchor";

export type StatusToTaskTuple = [IncidentStatus, PostIncidentTask[]];

export const groupTasksByStatuses = ({
  incidentTasks,
  statuses,
}: {
  incidentTasks: PostIncidentTask[];
  statuses: IncidentStatus[];
}): StatusToTaskTuple[] => {
  // Create a map of status -> incident tasks
  const statusMap = new Map<IncidentStatus, PostIncidentTask[]>();

  for (const s of statuses) {
    if (s.category !== IncidentStatusCategoryEnum.PostIncident) {
      continue;
    }

    const postIncidentTasks = incidentTasks.filter((task) => {
      return task.config.incident_status_id === s.id;
    });

    statusMap.set(s, postIncidentTasks);
  }

  return _.sortBy(Array.from(statusMap.entries()), ([status]) => status.rank);
};

export type PostIncidentTabSelection =
  | {
      type: "task";
      taskId: string;
      statusId: string;
    }
  | {
      type: "placeholder";
      statusId: string;
    };

/**
 * Custom hook to manage the currently selected task or status placeholder. This includes setting the
 * initial default and handling navigating to the next unresolved task after the selected task/status has been completed.
 */
export const usePostIncidentTabSelection = (
  incident: Incident,
  statusesToTasks: StatusToTaskTuple[],
): {
  selection: PostIncidentTabSelection | null;
  setSelection: Dispatch<SetStateAction<PostIncidentTabSelection | null>>;
} => {
  const postIncidentTasks = statusesToTasks.flatMap(([_, tasks]) => tasks);
  // Watch the incident and its status, so we can update if they change
  const currentStatus = incident?.incident_status;
  const previousIncident = usePrevious(incident);
  const previousTasks = usePrevious(postIncidentTasks);

  const isIncomplete = (t: PostIncidentTask) =>
    !t.completed_at && !t.rejected_at;

  // Create the selection state, defaulting to the first incomplete task or status
  const [selection, setSelection] = useState<PostIncidentTabSelection | null>(
    () => {
      if (statusesToTasks.length === 0) {
        return null;
      }

      // If the user has clicked on a notification, or we're linking from elsewhere in the app, just
      // use the ID in the URL.
      const anchorId = getAnchorId();
      if (anchorId) {
        // get the ID portion from task/01H3M4TFHB0KGNFDD873Z1SXHM
        const taskId = anchorId.split("/")[1];
        const allTasks = _.flatMap(statusesToTasks, ([_, tasks]) => tasks);
        const task = findById(allTasks, taskId);
        if (task) {
          return {
            type: "task",
            taskId: task.id,
            statusId: task.config.incident_status_id,
          };
        }
      }

      const [lastPostIncidentStatus] =
        statusesToTasks[statusesToTasks.length - 1];
      const isCurrentlyClosed =
        currentStatus?.category === IncidentStatusCategoryEnum.Closed;

      const defaultStatus = isCurrentlyClosed
        ? lastPostIncidentStatus
        : currentStatus;

      const statusToTasks = statusesToTasks.find(
        ([s, _]) => s.id === defaultStatus?.id,
      );

      if (!statusToTasks) {
        return null;
      }

      const [status, tasks] = statusToTasks;

      // If the status has no tasks, just use the placeholder
      if (tasks.length === 0) {
        return { type: "placeholder", statusId: status.id };
      }

      const nextIncompleteTask = tasks.find(isIncomplete);
      const lastTask = tasks[tasks.length - 1];
      return {
        type: "task",
        taskId: (nextIncompleteTask ?? lastTask).id,
        statusId: status.id,
      };
    },
  );

  // We use advanceToNext whenever the status progresses from the selected status, or the currently selected task is completed.
  const advanceToNext = () => {
    const selectedTaskId = selection?.type === "task" && selection.taskId;
    const selectedStatusId = selection?.statusId;

    const statusToTasks = statusesToTasks.find(
      ([s, _]) => s.id === selectedStatusId,
    );
    if (!statusToTasks) {
      return;
    }

    const [selectedStatus, _] = statusToTasks;

    // Iterate through all the statuses and their tasks, finding the next placeholder or incomplete task
    // after the current placeholder/task.
    for (const [candidateStatus, candidateTasks] of statusesToTasks) {
      if (candidateStatus.rank < selectedStatus.rank) {
        continue;
      }

      // If we're at the next status, and it has no tasks, just use the placeholder
      if (
        candidateStatus.rank > selectedStatus.rank &&
        candidateTasks.length === 0
      ) {
        setSelection({ type: "placeholder", statusId: candidateStatus.id });
        return;
      }

      const nextIncomplete = candidateTasks
        .filter((t) => t.id !== selectedTaskId)
        .find(isIncomplete);
      if (nextIncomplete) {
        setSelection({
          type: "task",
          taskId: nextIncomplete.id,
          statusId: selectedStatus.id,
        });
        return;
      }
    }
  };

  // Watch the post-incident tasks and check if the currently selected task has been completed
  useEffect(() => {
    if (!previousTasks || !postIncidentTasks) {
      return;
    }

    if (selection?.type === "task") {
      const previousTask = findById(previousTasks, selection.taskId);
      const currentTask = findById(postIncidentTasks, selection.taskId);

      if (!previousTask || !currentTask) {
        return;
      }

      const previouslyResolved = !isIncomplete(previousTask);
      const currentlyResolved = !isIncomplete(currentTask);
      if (!previouslyResolved && currentlyResolved) {
        advanceToNext();
      }
    }

    if (selection?.type === "placeholder" && postIncidentTasks.length > 0) {
      advanceToNext();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postIncidentTasks]);

  // Watch the incident status and check if the currently selected status has advanced
  useEffect(() => {
    if (selection?.type === "placeholder") {
      if (!incident?.incident_status || !previousIncident?.incident_status) {
        return;
      }

      const isCurrentlySelectingOldStatus =
        selection.statusId === previousIncident.incident_status.id;
      const statusHasChanged =
        incident.incident_status.rank > previousIncident.incident_status.rank;

      if (isCurrentlySelectingOldStatus && statusHasChanged) {
        advanceToNext();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [incident]);

  return {
    selection,
    setSelection,
  };
};

export function findById<T extends { id: string }>(
  items: T[],
  id: string,
): T | undefined {
  return items.find((item) => item.id === id);
}
