import {
  PostmortemsUpdateTemplateRequestBody,
  PostmortemTemplate,
} from "@incident-io/api";
import { Form } from "@incident-shared/forms";
import { ToggleV2 } from "@incident-shared/forms/v2/inputs/ToggleV2";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import {
  Button,
  ButtonTheme,
  IconBadge,
  IconEnum,
  IconSize,
} from "@incident-ui";
import {
  Drawer,
  DrawerBody,
  DrawerContents,
  DrawerFooter,
  DrawerTitle,
} from "@incident-ui/Drawer/Drawer";
import { AnimatePresence } from "framer-motion";
import { useForm } from "react-hook-form";
import { useAPIMutation } from "src/utils/swr";

type PostmortemTemplateFollowUpSectionEditProps = {
  onClose: () => void;
  template: PostmortemTemplate;
};

type FollowUpsFormData = Pick<
  PostmortemsUpdateTemplateRequestBody,
  "follow_ups_links_only"
>;

export const PostmortemTemplateFollowUpSectionDrawer = ({
  onClose,
  template,
}: PostmortemTemplateFollowUpSectionEditProps) => {
  const formMethods = useForm<FollowUpsFormData>({
    defaultValues: {
      follow_ups_links_only: template.follow_ups_links_only,
    },
  });
  const { isSubmitting } = formMethods.formState;

  const {
    trigger: onSubmit,
    isMutating: saving,
    genericError,
  } = useAPIMutation(
    "postmortemsShowTemplate",
    { id: template.id },
    async (apiClient, formData: FollowUpsFormData) => {
      await apiClient.postmortemsUpdateTemplate({
        id: template.id,
        updateTemplateRequestBody: {
          export_title: template.export_title,
          name: template.name,
          follow_ups_links_only: formData.follow_ups_links_only,
        },
      });
    },
    {
      onSuccess: () => {
        onClose();
      },
    },
  );

  return (
    <AnimatePresence>
      <Drawer onClose={onClose} width="medium" side="right">
        <DrawerContents>
          <DrawerTitle
            title={`Edit section`}
            onClose={onClose}
            titleAccessory={
              <IconBadge
                icon={IconEnum.Doc}
                size={IconSize.Small}
                color={ColorPaletteEnum.Red}
              />
            }
          />
          <DrawerBody className="h-full overflow-y-auto">
            <Form.Root
              id="edit-follow-up-section"
              formMethods={formMethods}
              onSubmit={onSubmit}
              saving={saving}
              genericError={genericError}
            >
              <ToggleV2
                formMethods={formMethods}
                name="follow_ups_links_only"
                description="By default, follow-ups are exported within a table. If you prefer to export them as links only, you can enable this option."
                label="Follow-ups as links only"
              />
            </Form.Root>
          </DrawerBody>
          <DrawerFooter className="flex justify-end">
            <Button
              analyticsTrackingId={null}
              onClick={onClose}
              theme={ButtonTheme.Secondary}
              className="mr-2"
            >
              Cancel
            </Button>
            <Button
              analyticsTrackingId={null}
              loading={isSubmitting}
              type="submit"
              form="edit-follow-up-section"
              theme={ButtonTheme.Primary}
            >
              Save
            </Button>
          </DrawerFooter>
        </DrawerContents>
      </Drawer>
    </AnimatePresence>
  );
};
