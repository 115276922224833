import { Heading, IconEnum } from "@incident-ui";
import { IconProps } from "@incident-ui/Icon/Icon";
import { tcx } from "src/utils/tailwind-classes";
import { assertUnreachable } from "src/utils/utils";

import { ExternalLink } from "./ExternalLink";

export type StatusPageLinkProps = {
  id: string;
  title: string;
  type: "internal" | "public" | "atlassian";
  iconProps?: Omit<IconProps, "id" | "size">;
} & ({ href: string; to?: never } | { href?: never; to: string });

export const StatusPageLinks = ({
  links,
}: {
  links: StatusPageLinkProps[];
}): React.ReactElement | null => {
  if (links.length === 0) {
    return null;
  }

  return (
    <div className="py-1.5">
      <div className="mb-2.5">
        <Heading level={3} size="small" className="">
          Status pages
        </Heading>
      </div>
      <div className="space-y-2">
        {links.map((link) => (
          <StatusPageLink key={link.id} {...link} />
        ))}
      </div>
    </div>
  );
};

const iconForType = (type: StatusPageLinkProps["type"]): IconEnum => {
  switch (type) {
    case "internal":
      return IconEnum.StatusPageHifi;
    case "public":
      return IconEnum.StatusPageHifi;
    case "atlassian":
      return IconEnum.AtlassianStatuspage;
    default:
      return assertUnreachable(type);
  }
};

export const StatusPageLink = ({
  href,
  to,
  title,
  type,
  iconProps = {},
}: StatusPageLinkProps) => (
  <ExternalLink
    analyticsTrackingId={null}
    href={href}
    to={to}
    label={title}
    icon={iconForType(type)}
    iconProps={{
      ...iconProps,
      className: tcx("flex-none", iconProps.className),
    }}
  />
);
