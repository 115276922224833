import { ExpressionBranch, PostmortemTemplate } from "@incident-io/api";
import { EngineLiteralBadge } from "@incident-shared/engine";
import {
  CommaSeparatedConditionGroups,
  SelectedCondition,
} from "@incident-shared/engine/conditions";
import { Form } from "@incident-shared/forms";
import { SettingsListItem } from "@incident-shared/settings/SettingsList/SettingsListItem";
import { Icon, IconEnum, IconSize } from "@incident-ui";
import { DraggableProvided } from "react-beautiful-dnd";
import { FieldPath } from "react-hook-form";
import { tcx } from "src/utils/tailwind-classes";

import { PostmortemTemplateExpressionFormData } from "./types";

export const Branch = ({
  fieldName,
  branch: { condition_groups, result },
  prefix,
  templates,
  onEdit,
  onDelete,
  isOnlyBranch,
  isFirstItem,
  draggableProvided,
}: {
  fieldName: FieldPath<PostmortemTemplateExpressionFormData>;
  branch: ExpressionBranch;
  prefix: string;
  templates: PostmortemTemplate[];
  onEdit: () => void;
  onDelete: () => void;
  isOnlyBranch?: boolean;
  isFirstItem?: boolean;
  draggableProvided: DraggableProvided;
}) => {
  const conditions = condition_groups?.[0]?.conditions || [];
  const template = templates.find(
    (template) => template.id === result.value?.literal,
  );
  return (
    <div
      ref={draggableProvided.innerRef}
      {...draggableProvided.draggableProps}
      className={tcx(
        "flex flex-wrap w-full",
        isFirstItem ? "" : "border-t border-stroke",
      )}
    >
      <SettingsListItem
        title={
          <Form.InputWrapper<PostmortemTemplateExpressionFormData>
            name={fieldName}
            className="font-normal"
          >
            <div className="flex gap-2 !font-normal items-center">
              <div
                className="-ml-2 mr-2 h-[16px]"
                {...draggableProvided.dragHandleProps}
              >
                <Icon id={IconEnum.Draggable} size={IconSize.Small} />
              </div>
              <div>
                <div>
                  {conditions.length > 0 ? (
                    <span className="flex-center-y gap-3 mb-3">
                      <span className="text-sm-med shrink-0">{prefix}</span>
                      <CommaSeparatedConditionGroups
                        groups={condition_groups}
                      />
                    </span>
                  ) : null}
                </div>
                <ul className={"flex items-center flex-wrap text-sm space-x-2"}>
                  <span className="text-sm font-medium flex-shrink-0">
                    then use
                  </span>
                  {template && (
                    <EngineLiteralBadge
                      icon={IconEnum.Doc}
                      label={template.name}
                    />
                  )}{" "}
                  <span className="text-sm shrink-0">
                    for writing the post-mortem
                  </span>
                </ul>
              </div>
            </div>
          </Form.InputWrapper>
        }
        buttons={{
          edit: {
            onEdit,
          },
          delete: {
            resourceTitle: "condition",
            onDelete,
            isGatedText: isOnlyBranch
              ? "Must have at least one set of conditions"
              : undefined,
            deleteConfirmationTitle: "Delete condition",
            deleteConfirmationContent: (
              <div className="space-y-2 text-sm">
                <span>
                  Are you sure you wish to delete this set of conditions?
                </span>
                <div>
                  {conditions.map((selectedCondition) => (
                    <SelectedCondition
                      className={"mr-2"}
                      key={selectedCondition.subject.reference}
                      condition={selectedCondition}
                      theme="slate"
                    />
                  ))}
                </div>
              </div>
            ),
          },
        }}
      />
    </div>
  );
};
