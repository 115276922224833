import {
  getTypeaheadOptions,
  hydrateInitialSelectOptions,
  TypeaheadTypeEnum,
} from "@incident-shared/forms/Typeahead";
import { DynamicSingleSelectV2 } from "@incident-shared/forms/v2/inputs/DynamicSelectV2";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { StaticSingleSelectV2 } from "@incident-shared/forms/v2/inputs/StaticSelectV2";
import { Button, ButtonTheme, IconEnum, ModalFooter } from "@incident-ui";
import { useForm } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import {
  Action,
  ActionStatusEnum as ActionStatus,
  ActionsUpdateRequestBody,
  ActionsUpdateRequestBodyStatusEnum,
  useClient,
} from "src/contexts/ClientContext";
import { useAPIMutation, useAPIRefetch } from "src/utils/swr";

import { stripCharacterEntities } from "../../../../utils/formatting";

type Props = {
  onClose: () => void;
  action: Action;
  isPrivateIncident: boolean;
};

export const ActionEditModal = ({
  onClose,
  isPrivateIncident,
  action,
}: Props): React.ReactElement => {
  const formMethods = useForm<ActionsUpdateRequestBody>({
    defaultValues: {
      ...action,
      assignee_id: action.assignee?.id,
      status: action.status as unknown as ActionsUpdateRequestBodyStatusEnum,
      description: stripCharacterEntities(action.description),
    },
  });
  const { setError } = formMethods;

  const {
    trigger: onSubmit,
    isMutating: saving,
    genericError,
  } = useAPIMutation(
    "actionsList",
    { incidentId: action.incident_id },
    async (apiClient, data: ActionsUpdateRequestBody) => {
      await apiClient.actionsUpdate({
        id: action.id,
        updateRequestBody: {
          ...data,
          status: data.status,
        },
      });
    },
    { onSuccess: onClose, setError },
  );

  const refetchActions = useAPIRefetch("actionsList", {
    incidentId: action.incident_id,
  });

  const { trigger: convertToFollowUp, isMutating: isConvertingToFollowUp } =
    useAPIMutation(
      "followUpsList",
      { incidentId: action.incident_id },
      async (apiClient, action: Action) => {
        await apiClient.actionsConvertToFollowUp({
          id: action.id,
        });
      },
      {
        onSuccess: async () => {
          await refetchActions();
          onClose();
        },
      },
    );

  const apiClient = useClient();
  return (
    <Form.Modal
      formMethods={formMethods}
      analyticsTrackingId={null}
      onClose={onClose}
      title="Edit action"
      onSubmit={onSubmit}
      genericError={genericError}
      footer={
        <ModalFooter
          saving={saving}
          onClose={onClose}
          confirmButtonType="submit"
          confirmButtonText="Save"
        />
      }
    >
      <InputV2
        formMethods={formMethods}
        label="Description"
        autoFocus
        name="description"
        required="Please enter a description"
      />
      {/* Assignee */}
      <DynamicSingleSelectV2
        formMethods={formMethods}
        name="assignee_id"
        label="Owner"
        required={false}
        helptext={
          isPrivateIncident
            ? "As this is a private incident, you can only assign actions to people with access."
            : undefined
        }
        placeholder="Select a user"
        loadOptions={getTypeaheadOptions(apiClient, TypeaheadTypeEnum.User, {
          incidentId: action?.incident_id,
        })}
        hydrateOptions={hydrateInitialSelectOptions(
          apiClient,
          TypeaheadTypeEnum.User,
        )}
      />

      {/* Status */}
      <StaticSingleSelectV2
        formMethods={formMethods}
        name="status"
        label="Status"
        placeholder="Select status"
        options={[
          {
            label: "Open",
            value: ActionStatus.Outstanding,
          },
          {
            label: "Completed",
            value: ActionStatus.Completed,
          },
          {
            label: "Not doing",
            value: ActionStatus.NotDoing,
          },
        ]}
      />

      {/* Mark as follow-up */}
      <Button
        theme={ButtonTheme.Secondary}
        icon={IconEnum.FastForward}
        analyticsTrackingId="mark-as-follow-up"
        onClick={() => convertToFollowUp(action)}
        loading={isConvertingToFollowUp}
      >
        Mark as follow-up
      </Button>
    </Form.Modal>
  );
};
