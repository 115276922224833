import {
  AlertSchema,
  AlertSourceConfig,
  AlertSourceSourceTypeEnum,
  Expression,
  ScopeNameEnum,
} from "@incident-io/api";
import { expressionToPayload } from "@incident-shared/engine/expressions/expressionToPayload";
import { FormDivider } from "@incident-shared/forms/v2/FormDivider";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { ALERT_SOURCE_TYPE_CONFIGS } from "@incident-shared/integrations/IntegrationConfig";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import {
  ButtonTheme,
  ConfirmationDialog,
  EmptyState,
  GenericErrorMessage,
  Icon,
  IconEnum,
  IconSize,
  Loader,
} from "@incident-ui";
import { ComponentType, useState } from "react";
import { useAPI } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";

import { useQueryParams } from "../../../utils/query-params";
import { AlertsTable } from "../common/AlertsTable";
import {
  AlertSourceCreateWizardSteps,
  AlertSourceStepEnum,
} from "./AlertSourceCreateWizardSteps";
import {
  AlertSourceFooter,
  AlertSourceGradientBox,
  AlertSourceSplitLayout,
} from "./AlertSourceLayout";
import { AlertSourceAlertmanagerSetupInfo } from "./configure/alert-source-setup-info/AlertSourceAlertmanagerSetupInfo";
import { AlertSourceAppOpticsSetupInfo } from "./configure/alert-source-setup-info/AlertSourceAppOpticsSetupInfo";
import { AlertSourceAzureMonitorSetupInfo } from "./configure/alert-source-setup-info/AlertSourceAzureMonitorSetupInfo";
import { AlertSourceBugSnagSetupInfo } from "./configure/alert-source-setup-info/AlertSourceBugSnagSetupInfo";
import { AlertSourceChecklySetupInfo } from "./configure/alert-source-setup-info/AlertSourceChecklySetupInfo";
import { AlertSourceChronosphereSetupInfo } from "./configure/alert-source-setup-info/AlertSourceChronosphereSetupInfo";
import { AlertSourceCloudflareSetupInfo } from "./configure/alert-source-setup-info/AlertSourceCloudflareSetupInfo";
import { AlertSourceCloudWatchSetupInfo } from "./configure/alert-source-setup-info/AlertSourceCloudWatchSetupInfo";
import { AlertSourceCronitorSetupInfo } from "./configure/alert-source-setup-info/AlertSourceCronitorSetupInfo";
import { AlertSourceCrowdstrikeFalconSetupInfo } from "./configure/alert-source-setup-info/AlertSourceCrowdstrikeFalconSetupInfo";
import { AlertSourceDatadogSetupInfo } from "./configure/alert-source-setup-info/AlertSourceDatadogSetupInfo";
import { AlertSourceDynatraceSetupInfo } from "./configure/alert-source-setup-info/AlertSourceDynatraceSetupInfo";
import { AlertSourceElasticSetupInfo } from "./configure/alert-source-setup-info/AlertSourceElasticSetupInfo";
import { AlertSourceEmailSetupInfo } from "./configure/alert-source-setup-info/AlertSourceEmailSetupInfo";
import { AlertSourceExpelSetupInfo } from "./configure/alert-source-setup-info/AlertSourceExpelSetupInfo";
import { AlertSourceGoogleCloudSetupInfo } from "./configure/alert-source-setup-info/AlertSourceGoogleCloudSetupInfo";
import { AlertSourceGrafanaSetupInfo } from "./configure/alert-source-setup-info/AlertSourceGrafanaSetupInfo";
import { AlertSourceHoneycombSetupInfo } from "./configure/alert-source-setup-info/AlertSourceHoneycombSetupInfo";
import { AlertSourceHTTPSetupInfo } from "./configure/alert-source-setup-info/AlertSourceHTTPSetupInfo";
import { AlertSourceJiraSetupInfo } from "./configure/alert-source-setup-info/AlertSourceJiraSetupInfo";
import { AlertSourceMonteCarloSetupInfo } from "./configure/alert-source-setup-info/AlertSourceMonteCarloSetupInfo";
import { AlertSourceNagiosSetupInfo } from "./configure/alert-source-setup-info/AlertSourceNagiosSetupInfo";
import { AlertSourceNewRelicSetupInfo } from "./configure/alert-source-setup-info/AlertSourceNewRelicSetupInfo";
import { AlertSourcePantherSetupInfo } from "./configure/alert-source-setup-info/AlertSourcePantherSetupInfo";
import { AlertSourcePingdomSetupInfo } from "./configure/alert-source-setup-info/AlertSourcePingdomSetupInfo";
import { AlertSourcePRTGSetupInfo } from "./configure/alert-source-setup-info/AlertSourcePRTGSetupInfo";
import { AlertSourceRunscopeSetupInfo } from "./configure/alert-source-setup-info/AlertSourceRunscopeSetupInfo";
import { AlertSourceSentrySetupInfo } from "./configure/alert-source-setup-info/AlertSourceSentrySetupInfo";
import { AlertSourceSNSSetupInfo } from "./configure/alert-source-setup-info/AlertSourceSNSSetupInfo";
import { AlertSourceSplunkSetupInfo } from "./configure/alert-source-setup-info/AlertSourceSplunkSetupInfo";
import { AlertSourceStatusCakeSetupInfo } from "./configure/alert-source-setup-info/AlertSourceStatusCakeSetupInfo";
import { AlertSourceStatusPageViewsSetupInfo } from "./configure/alert-source-setup-info/AlertSourceStatusPageViewsSetupInfo";
import { AlertSourceSumoLogicSetupInfo } from "./configure/alert-source-setup-info/AlertSourceSumoLogicSetupInfo";
import { AlertSourceUptimeSetupInfo } from "./configure/alert-source-setup-info/AlertSourceUptimeSetupInfo";
import { stripInvalidBindings } from "./stripInvalidBindings";

// Provides the left-right split of setup instructions and alert previews. Is
// rendered either in the create wizard or into the manage connection drawer.
export const AlertSourceConnectPage = ({
  alertSourceConfigId,
  mode,
}: {
  alertSourceConfigId: string;
  mode: "wizard" | "drawer";
}) => {
  const navigate = useOrgAwareNavigate();
  const queryParams = useQueryParams();

  const { data: alertSourceConfigResponse, error: alertSourceConfigError } =
    useAPI(
      alertSourceConfigId ? "alertsShowSourceConfig" : null,
      {
        id: alertSourceConfigId || "",
      },
      {
        refreshInterval: 1000,
        revalidateOnFocus: true,
      },
    );

  const { data: schemaData, error: schemaError } = useAPI(
    "alertsShowSchema",
    undefined,
  );

  const [hasTestAlertsReceived, setHasTestAlertsReceived] = useState(false);
  const [
    noTestAlertsReceivedWarningModalOpen,
    setNoTestAlertsReceivedWarningModalOpen,
  ] = useState(false);

  if (alertSourceConfigError || schemaError) {
    return (
      <GenericErrorMessage error={alertSourceConfigError ?? schemaError} />
    );
  }

  const alertSourceConfig = alertSourceConfigResponse?.alert_source_config;
  if (!alertSourceConfig || !schemaData) {
    return <Loader className={"h-full"} />;
  }

  const props = {
    alertSourceConfig: alertSourceConfigResponse.alert_source_config,
    schema: schemaData.alert_schema,
  };

  const alertSourceName =
    alertSourceConfig.source_type !== AlertSourceSourceTypeEnum.Http
      ? alertSourceConfig.alert_source.name
      : "your HTTP source";

  const navigateToConfigURL = `/alerts/sources/create?step=${
    AlertSourceStepEnum.Configure
  }&id=${alertSourceConfigId}${
    queryParams.get("from") === "alert-routes" ? "&from=alert-routes" : ""
  }`;

  // If we're in the wizard we need need to provide the connection page within a
  // gradient box with the wizard steps and a footer.
  return (
    <>
      <AlertSourceSplitLayout
        className={"grow"}
        header={
          mode === "wizard" ? (
            <AlertSourceGradientBox
              className={"grow gap-8 p-6"}
              sourceTypeConfig={
                ALERT_SOURCE_TYPE_CONFIGS[alertSourceConfig.source_type]
              }
            >
              <AlertSourceCreateWizardSteps
                step={AlertSourceStepEnum.Connect}
                sourceType={
                  alertSourceConfig.source_type as AlertSourceSourceTypeEnum
                }
                title={`Connect ${alertSourceName} to incident.io`}
                description={`Follow the steps to connect ${alertSourceName} to incident.io, and send some test alerts, as they’ll be helpful in the next step.`}
              />
            </AlertSourceGradientBox>
          ) : undefined
        }
        bordered
        left={
          <div className={"grow py-5 px-6"}>
            <SetupInstructionsLeft {...props} />
          </div>
        }
        right={
          <AlertsPreviewRight
            {...props}
            setHasTestAlertsReceived={setHasTestAlertsReceived}
          />
        }
        footer={
          mode === "wizard" ? (
            <>
              <NoTestAlertsReceivedWarningModal
                isOpen={noTestAlertsReceivedWarningModalOpen}
                onBack={() => setNoTestAlertsReceivedWarningModalOpen(false)}
                onContinue={() => {
                  navigate(navigateToConfigURL);
                }}
              />
              <AlertSourceFooter
                backHref={"/alerts/sources/create/"}
                continueButton={
                  <GatedButton
                    analyticsTrackingId="alert-source-connect-continue"
                    className="pt-2.5 pb-2.5"
                    theme={ButtonTheme.Primary}
                    requiredScope={ScopeNameEnum.AlertSourceCreate}
                    disabledTooltipContent={
                      "You do not have permission to do this"
                    }
                    onClick={() => {
                      if (hasTestAlertsReceived) {
                        navigate(navigateToConfigURL);
                      } else {
                        setNoTestAlertsReceivedWarningModalOpen(true);
                      }
                    }}
                  >
                    Continue
                  </GatedButton>
                }
              />
            </>
          ) : undefined
        }
      />
    </>
  );
};

// Show instructions about how to setup the alert source depending on the type
// of source on the left split.
const SetupInstructionsLeft = ({
  alertSourceConfig,
  schema,
}: {
  alertSourceConfig: AlertSourceConfig;
  schema: AlertSchema;
}) => {
  const SetupComponent =
    AlertSourceSetupComponent[alertSourceConfig.source_type];
  const requiresSetup = !!SetupComponent;

  return (
    <>
      {alertSourceConfig.alert_source.externally_resolved && (
        <>
          <div className="text-content-secondary">
            This alert source is set up to be externally resolved. In order to
            resolve an alert in incident.io, you will need to resolve it in{" "}
            {alertSourceConfig.alert_source.name}
          </div>
          <FormDivider className="my-8" />
        </>
      )}
      {requiresSetup ? (
        <div className="mb-4">
          <SetupComponent
            alertSourceConfig={alertSourceConfig}
            schema={schema}
          />
        </div>
      ) : (
        <div className="bg-slate-50 border border-slate-100 rounded-2 p-5 flex">
          <div className="flex-center rounded-full border border-green-600 w-4 h-4 mt-0.5 mr-3">
            <Icon
              id={IconEnum.Tick}
              size={IconSize.Small}
              className="text-green-600"
            />
          </div>
          <div>
            <div className={"text-content-primary-bold"}>
              No further set up required
            </div>
            <div className={"text-content-secondary"}>
              {`${alertSourceConfig.name} is already connected and sending alerts to incident.io. You may continue to the next step.`}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

// Preview the recently received alerts on the right split.
const AlertsPreviewRight = ({
  className,
  alertSourceConfig,
  schema,
  setHasTestAlertsReceived,
}: {
  className?: string;
  alertSourceConfig: AlertSourceConfig;
  schema: AlertSchema;
  setHasTestAlertsReceived: (hasTestAlertsReceived: boolean) => void;
}) => {
  const {
    data: alertSourcesData,
    isLoading: alertSourcesLoading,
    error: alertSourcesError,
  } = useAPI("alertsListSources", undefined);
  const {
    data: resourcesData,
    isLoading: resourcesLoading,
    error: resourcesError,
  } = useAPI("catalogListResources", undefined);

  const template = alertSourceConfig?.template;
  const { data: previewAlerts, isLoading: previewAlertsLoading } = useAPI(
    template ? "alertsPreviewSourceConfigAlerts" : null,
    {
      id: alertSourceConfig.id,
      previewSourceConfigAlertsRequestBody: {
        template: stripInvalidBindings({
          expressions: template.expressions.map((e) =>
            expressionToPayload(e as unknown as Expression),
          ),
          title: template.title,
          description: template.description,
          bindings: template.bindings,
        }),
        attributes: schema.attributes ?? [],
        version: schema.version ?? 0,
      },
    },
  );

  if (alertSourcesError || resourcesError) {
    return <GenericErrorMessage error={alertSourcesError ?? resourcesError} />;
  }

  if (
    alertSourcesLoading ||
    resourcesLoading ||
    previewAlertsLoading ||
    !alertSourcesData ||
    !resourcesData ||
    !previewAlerts
  ) {
    return <Loader />;
  }

  if (previewAlerts.alerts.length > 0) {
    setHasTestAlertsReceived(true);
  }

  return (
    <div
      className={tcx(
        "flex flex-col py-5 px-6 gap-4 bg-slate-50 h-full w-full overflow-y-auto",
        className,
      )}
    >
      {previewAlerts.alerts.length === 0 ? (
        <ConnectAlertSourceEmptyState />
      ) : (
        <AlertsTable
          schema={schema}
          resources={resourcesData.resources}
          alertSourceConfigs={[alertSourceConfig]}
          alerts={previewAlerts.alerts}
          allEntriesLoaded={true}
          enableSelection={false}
          isLoading={previewAlertsLoading}
          // We don't need to show any attributes as this is during connection,
          // before we've set aything up.
          visibleColumns={[]}
          maxNameWidth={"100%"}
          wrappedInBox
        />
      )}
    </div>
  );
};

const ConnectAlertSourceEmptyState = () => {
  return (
    <EmptyState
      className="bg-surface-secondary border-0"
      icon={IconEnum.Alert}
      title="No alerts received yet"
      content=" Follow the steps opposite to set up your alert source so that we can
          start receiving alerts."
    />
  );
};

const NoTestAlertsReceivedWarningModal = ({
  isOpen,
  onBack,
  onContinue,
}: {
  isOpen: boolean;
  onBack: () => void;
  onContinue: () => void;
}) => {
  if (!isOpen) {
    return null;
  }

  return (
    <ConfirmationDialog
      analyticsTrackingId={null}
      isOpen
      title="We’ve not received a test alert"
      confirmButtonText="Continue anyway"
      cancelButtonText="Back"
      onConfirm={() => {
        onContinue();
      }}
      onCancel={() => {
        onBack();
      }}
    >
      We recommend sending at least one test alert as it will help you when
      configuring your alert source in the next step.
    </ConfirmationDialog>
  );
};

export type AlertSourceSetupProps = {
  alertSourceConfig: AlertSourceConfig;
  schema: AlertSchema;
};

export const AlertSourceSetupComponent: Record<
  AlertSourceSourceTypeEnum,
  ComponentType<AlertSourceSetupProps> | null
> = {
  [AlertSourceSourceTypeEnum.Alertmanager]: AlertSourceAlertmanagerSetupInfo,
  [AlertSourceSourceTypeEnum.AppOptics]: AlertSourceAppOpticsSetupInfo,
  [AlertSourceSourceTypeEnum.AzureMonitor]: AlertSourceAzureMonitorSetupInfo,
  [AlertSourceSourceTypeEnum.Bugsnag]: AlertSourceBugSnagSetupInfo,
  [AlertSourceSourceTypeEnum.Checkly]: AlertSourceChecklySetupInfo,
  [AlertSourceSourceTypeEnum.Cloudflare]: AlertSourceCloudflareSetupInfo,
  [AlertSourceSourceTypeEnum.Cloudwatch]: AlertSourceCloudWatchSetupInfo,
  [AlertSourceSourceTypeEnum.Cronitor]: AlertSourceCronitorSetupInfo,
  [AlertSourceSourceTypeEnum.CrowdstrikeFalcon]:
    AlertSourceCrowdstrikeFalconSetupInfo,
  [AlertSourceSourceTypeEnum.Chronosphere]: AlertSourceChronosphereSetupInfo,
  [AlertSourceSourceTypeEnum.Datadog]: AlertSourceDatadogSetupInfo,
  [AlertSourceSourceTypeEnum.Dynatrace]: AlertSourceDynatraceSetupInfo,
  [AlertSourceSourceTypeEnum.Email]: AlertSourceEmailSetupInfo,
  [AlertSourceSourceTypeEnum.Elasticsearch]: AlertSourceElasticSetupInfo,
  [AlertSourceSourceTypeEnum.Expel]: AlertSourceExpelSetupInfo,
  [AlertSourceSourceTypeEnum.GithubIssue]: null,
  [AlertSourceSourceTypeEnum.GoogleCloud]: AlertSourceGoogleCloudSetupInfo,
  [AlertSourceSourceTypeEnum.Grafana]: AlertSourceGrafanaSetupInfo,
  [AlertSourceSourceTypeEnum.Honeycomb]: AlertSourceHoneycombSetupInfo,
  [AlertSourceSourceTypeEnum.Http]: AlertSourceHTTPSetupInfo,
  [AlertSourceSourceTypeEnum.Jira]: AlertSourceJiraSetupInfo,
  [AlertSourceSourceTypeEnum.MonteCarlo]: AlertSourceMonteCarloSetupInfo,
  [AlertSourceSourceTypeEnum.Prtg]: AlertSourcePRTGSetupInfo,
  [AlertSourceSourceTypeEnum.Nagios]: AlertSourceNagiosSetupInfo,
  [AlertSourceSourceTypeEnum.NewRelic]: AlertSourceNewRelicSetupInfo,
  [AlertSourceSourceTypeEnum.Panther]: AlertSourcePantherSetupInfo,
  [AlertSourceSourceTypeEnum.Opsgenie]: null,
  [AlertSourceSourceTypeEnum.PagerDuty]: null,
  [AlertSourceSourceTypeEnum.Pingdom]: AlertSourcePingdomSetupInfo,
  [AlertSourceSourceTypeEnum.Runscope]: AlertSourceRunscopeSetupInfo,
  [AlertSourceSourceTypeEnum.Sentry]: AlertSourceSentrySetupInfo,
  [AlertSourceSourceTypeEnum.SumoLogic]: AlertSourceSumoLogicSetupInfo,
  [AlertSourceSourceTypeEnum.StatusPageViews]:
    AlertSourceStatusPageViewsSetupInfo,
  [AlertSourceSourceTypeEnum.Sns]: AlertSourceSNSSetupInfo,
  [AlertSourceSourceTypeEnum.Splunk]: AlertSourceSplunkSetupInfo,
  [AlertSourceSourceTypeEnum.StatusCake]: AlertSourceStatusCakeSetupInfo,
  [AlertSourceSourceTypeEnum.Uptime]: AlertSourceUptimeSetupInfo,
  [AlertSourceSourceTypeEnum.Zendesk]: null,
};
