import { Actor, IntegrationActorNameEnum } from "@incident-io/api";
import React from "react";

interface ActorSourceProps {
  actor: Actor;
  useLowerCaseAutomation?: boolean;
}

export const ActorSource = ({
  actor,
  useLowerCaseAutomation = false,
}: ActorSourceProps): React.ReactElement => {
  if (actor.user) {
    return <>{actor.user.name}</>;
  }
  if (actor.workflow) {
    return (
      <a href={`/workflows/${actor.workflow.id}`}>
        Workflow: {actor.workflow.name}
      </a>
    );
  }
  if (actor.api_key) {
    return (
      <a href={"/settings/api-keys"} target="_blank" rel="noopener noreferrer">
        API Key: {actor.api_key.name}
      </a>
    );
  }
  if (actor.integration) {
    if (actor.integration.name === IntegrationActorNameEnum.IncidentBackfill) {
      return <>someone</>;
    }
  }

  if (useLowerCaseAutomation) {
    return <>an automation</>;
  }

  return <>An automation</>;
};
