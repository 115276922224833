import { Incident } from "@incident-io/api";
import { TimelineHeader } from "@incident-shared/timeline/TimelineHeader";
import {
  Button,
  ButtonSize,
  ButtonTheme,
  Icon,
  IconEnum,
  LoadingBar,
} from "@incident-ui";
import { useState } from "react";
import { IncidentTimeline } from "src/components/incident-timeline/IncidentTimeline";
import { useNavigateToModal } from "src/utils/query-params";
import { useAPI } from "src/utils/swr";

import { SectionWrapper } from "../body/SectionWrapper";

type PostmortemTimelineProps = {
  id: string;
  incident: Incident;
};

export const PostmortemTimeline = ({
  id,
  incident,
}: PostmortemTimelineProps) => {
  const [minimizedItems, setMinimizedItems] = useState<string[]>([]);
  const [timelineCommentsOpen, setTimelineCommentsOpen] = useState<string[]>(
    [],
  );
  const navigateToModal = useNavigateToModal();
  const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const { data: groupedTimelineItems, isLoading } = useAPI(
    "incidentTimelineListTimelineItems",
    {
      incidentId: incident.id,
      timezone: localTimezone,
    },
    { fallbackData: { timeline_items: [] } },
  );

  const timelineItems = groupedTimelineItems?.timeline_items ?? [];

  const headerAccessory = (
    <TimelineHeader
      minimizedItems={minimizedItems}
      setMinimizedItems={setMinimizedItems}
      timelineItems={timelineItems}
      accessorKey="timeline_item"
      headerAccessory={
        <Button
          size={ButtonSize.Small}
          analyticsTrackingId={"edit-timeline"}
          onClick={() => navigateToModal("edit-timeline")}
          theme={ButtonTheme.Naked}
          title="Edit timeline"
        >
          <Icon id={IconEnum.Edit} />
        </Button>
      }
      supportsMinimizing
      compact
    />
  );

  return (
    <SectionWrapper
      id={id}
      sectionName="Timeline"
      headerAccessory={headerAccessory}
      enableExpansion
    >
      {isLoading ? (
        <LoadingBar className="mt-2" />
      ) : (
        <IncidentTimeline
          incidentId={incident.id}
          minimizedItems={minimizedItems}
          setMinimizedItems={setMinimizedItems}
          commentBoxOpen={timelineCommentsOpen}
          setCommentBoxOpen={setTimelineCommentsOpen}
          className="pt-2"
          hideHeader={true}
        />
      )}
    </SectionWrapper>
  );
};
