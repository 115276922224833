import { EscalationPathNodeTypeEnum as NodeTypes } from "@incident-io/api";
import _ from "lodash";
import { Node as ReactFlowNode } from "reactflow";

import {
  EscalationPathTargetFormData,
  PathNode,
  ReactFlowNodeCustomType,
} from "../../common/types";

type NodeGrid = (PathNode | null)[][];

export const drawNodes = ({
  firstNodeId,
  nodes,
}: {
  firstNodeId: string;
  nodes: Record<string, PathNode>;
}) => {
  // Grid is a 2D array which maps nodes to their positions.
  const grid: NodeGrid = [];

  const shouldDrawStartNode =
    nodes[firstNodeId].data.nodeType === NodeTypes.Level ||
    nodes[firstNodeId].data.nodeType === NodeTypes.NotifyChannel;

  // The initial row is 0, unless the first node is a level node, in which case we want to
  // draw a start node at 0, 0 and draw the rest of the path below it.
  const initialRow = shouldDrawStartNode ? 1 : 0;

  // If the first node is a level node, then we need to add a start node to the grid
  if (shouldDrawStartNode) {
    grid[0] = [startNode(firstNodeId)];
  }

  // Start the recursive function by handling the root node and plotting it at 0,0
  // or below the start node
  drawNode(grid, nodes, firstNodeId, initialRow, 0);

  const rowHeights = plotRowHeights(grid);

  // now apply a position to all of our nodes
  const reactFlowGrid = _.compact(grid).map((row, i) => {
    return row.map((node, j) => {
      if (node) {
        return transformPathNodeToReactFlowNode(rowHeights, node, i, j);
      }
      return undefined;
    });
  });

  return _.compact(_.flatten(reactFlowGrid));
};

// drawNode is a recursive function which will insert the node into the grid at the correct
// column and row. Everytime we insert a node, we increment our row so the next node is
// placed below the current node.
// If we are handling an if/else node, we handle our then column, before incrementing our
// column and plotting our else nodes in the next column.
const drawNode = (
  grid: NodeGrid,
  nodes: Record<string, PathNode>,
  nodeID: string,
  row: number,
  column: number,
) => {
  const node = nodes[nodeID];

  // We update row and column so that all children of this node are placed either below
  // or to the right (rather than shifting back to the left when a parent node has been
  // moved to the right by insertNode)
  [row, column] = insertNode(grid, node, row, column);

  switch (node.data.nodeType) {
    case NodeTypes.Repeat:
      // We don't need to do anything here, as Repeat nodes don't have any children.
      if (!node.data.repeat) {
        throw new Error(
          "Unreachable: repeat node must have a repeat data field",
        );
      }
      break;

    // If this is a Level node, we want to recursively handle the next node.
    case NodeTypes.Level:
      if (!node.data.level) {
        throw new Error("Unreachable: level node must have a level data field");
      }

      // We want to recursively handle the next node in the path if
      // there is a next node.
      if (node.data.level.nextNodeId) {
        drawNode(grid, nodes, node.data.level.nextNodeId, row + 1, column);
      }

      break;

    // If this is a NotifyChannel node, we want to recursively handle the next node.
    case NodeTypes.NotifyChannel:
      if (!node.data.notifyChannel) {
        throw new Error(
          "Unreachable: notify channel node must have a notify_channel data field",
        );
      }

      if (node.data.notifyChannel.nextNodeId) {
        drawNode(
          grid,
          nodes,
          node.data.notifyChannel.nextNodeId,
          row + 1,
          column,
        );
      }

      break;

    // If this is an IfElse node, we want to recursively handle both the then and else branches.
    case NodeTypes.IfElse:
      if (!node.data.ifElse) {
        throw new Error(
          "Unreachable: condition node must have a if_else data field",
        );
      }

      // Recursively handle the 'then' branch.
      drawNode(grid, nodes, node.data.ifElse.thenNodeId, row + 1, column);

      // Then bump the column and handle the 'else' branch
      drawNode(grid, nodes, node.data.ifElse.elseNodeId, row + 1, column + 1);

      break;
  }
};

// insertNode is a helper function which will insert a node into the grid at the provided
// column/row, unless that cell is already occupied. If the cell is already occupied, it will
// repeatedly try to insert the node into thenext column.
const insertNode = (
  grid: NodeGrid,
  node: PathNode,
  row: number,
  column: number,
): [number, number] => {
  if (grid[row] && grid[row][column]) {
    return insertNode(grid, node, row, column + 1);
  } else {
    if (!grid[row]) {
      // Fill the gaps to the left of the node
      grid[row] = Array(column).fill(null);
      grid[row][column] = node;
    }

    grid[row][column] = node;
    return [row, column];
  }
};

const startNode = (firstNodeId: string): ReactFlowNode => {
  return {
    id: "start",
    position: { x: 0, y: 75 },
    data: {
      nodeType: ReactFlowNodeCustomType.Start,
      start: { nextNodeId: firstNodeId },
    },
  };
};

const transformPathNodeToReactFlowNode = (
  rowHeights: number[],
  node: PathNode,
  row: number,
  col: number,
): ReactFlowNode => {
  return {
    id: node.id,
    type: "customNode",
    position: { x: col * 500, y: rowHeights[row] },
    data: node.data,
  };
};

// plotRowHeights is a helper function which will calculate the height of each row in the
// grid, so we can pass sensible position values to our nodes when drawing them.
// It works by adding the following:
// Position of the previous row (this applies to the top left corner) +
// Size of the largest node in that row +
// 80px edge height between rows
const plotRowHeights = (grid: NodeGrid): number[] => {
  const initialNodeHeight = 75;
  const edgeHeight = 80;

  const rowHeights: number[] = [];

  grid.forEach((_, i) => {
    if (i === 0) {
      rowHeights.push(initialNodeHeight);
      return;
    }

    const previousRowNodeHeight = getRowNodeHeight(grid[i - 1]);

    rowHeights.push(previousRowNodeHeight + rowHeights[i - 1] + edgeHeight);
  });

  return rowHeights;
};

// getRowNodeHeight returns the height of the tallest node in a row
const getRowNodeHeight = (row: NodeGrid[number]): number => {
  if (!row) {
    return 0;
  }

  return (
    _.max(
      row
        .filter((row): row is PathNode => row != null)
        .map((node) => nodeHeight({ node })),
    ) || 0
  );
};

// Ideally, we'd be using a ResizeObserver on the nodes to be able to directly
// get their exact heights, but this works fine for now and is a bit simpler. We
// have to position the nodes before we actually render them, which is why we
// make this estimation here.
const nodeHeight = ({ node }: { node: PathNode }) => {
  // Available width for target badges is 300px. Each badge consists of
  // [33px <-> Label <-> 31px]. There is a 4px spacing between badges. So to
  // calculate the number of lines that the target badges take up, we calculate
  // a "total width" that the targets would take up if they were all on a single
  // line (each target is (33+31+4=72px) + (numCharacters * 6px)). We then divide
  // this by 300 and round up to get the number of lines over which the targets
  // will be spread. Each line has a height of ~30px.
  const heightFromTargets = ({
    targets,
  }: {
    targets: EscalationPathTargetFormData[];
  }) => {
    let totalWidth = 0;
    targets.forEach((target) => {
      totalWidth += 72;
      totalWidth += target.label.length * 6;
    });

    const numLines = Math.ceil(totalWidth / 300);

    return 146 + 30 * numLines;
  };

  switch (node.data.nodeType) {
    case NodeTypes.Level:
      if (!node.data.level) {
        throw new Error("Unreachable: level node must have a level data field");
      }

      if (!node.data.level.targets) {
        throw new Error("Unreachable: level node must have a targets defined");
      }

      return heightFromTargets({ targets: node.data.level.targets });
    case NodeTypes.NotifyChannel:
      if (!node.data.notifyChannel) {
        throw new Error("Unreachable: level node must have a level data field");
      }

      if (!node.data.notifyChannel.targets) {
        throw new Error("Unreachable: level node must have a targets defined");
      }

      return heightFromTargets({ targets: node.data.notifyChannel.targets });
    case NodeTypes.IfElse:
      return 122;
    case NodeTypes.Repeat:
      return 118;
    default:
      return 118;
  }
};
